import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'prequal-letter-wizard-get-started',
  templateUrl: './prequal-letter-wizard-get-started.component.html'
})
export class PrequalLetterWizardGetStartedComponent implements OnInit {

  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() next: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  nextClicked = () => {
    this.next.emit();
  }

  closeClicked = () => {
    this.close.emit();
  }

}
