import { NgModule } from "@angular/core";
import { EConsentComponent } from "./e-consent.component";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { SharedModule } from "projects/shared/shared.module";
import { BorrowerPortalService } from "../services/borrower-portal.service";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule
  ],
  declarations: [
    EConsentComponent
  ],
  exports: [
    EConsentComponent
  ],
  providers: [
    BorrowerPortalService
  ]
})
export class EConsentModule {
  constructor() {
  }
}
