import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoanApplication } from '../models/loan-info.model';
import { DataService } from './data.service';

@Injectable()
export class LoanService {
  constructor(
    private readonly _dataService: DataService
  ) { }

  getApplication = (applicationId: number): Observable<LoanApplication> => {
    const url = `api/loan/${applicationId}/get-application-model`;
    return this._dataService.get(url);
  };
}
