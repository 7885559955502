import { EventEmitter, Injectable, Output } from "@angular/core";
import { AlertType } from "../models/alert-type.enum";
import { Alert } from "../models/alert.model";

@Injectable()
export class AlertService {

  @Output()
  alertHappened: EventEmitter<Alert> = new EventEmitter<Alert>();

  @Output()
  alertCleared: EventEmitter<any> = new EventEmitter<any>();

  alert = (alert:Alert) => {
    this.alertHappened.emit(alert);
  }

  error = (title: string, message: string) => {
    this.doAlert(title, message, AlertType.Error);
  }

  warning = (title: string, message: string) => {
    this.doAlert(title, message, AlertType.Warning);
  }

  info = (title: string, message: string) => {
    this.doAlert(title, message, AlertType.Info);
  }

  success = (title: string, message: string) => {
    this.doAlert(title, message, AlertType.Success);
  }

  clear = () => {
    this.alertCleared.emit();
  }

  private doAlert = (title: string, message: string, type: AlertType) => {
    const alert = new Alert(title, message, type);
    this.alertHappened.emit(alert);
  }

}
