import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Asset } from '../../models/asset.model';

@Component({
  selector: 'asset-card',
  templateUrl: 'asset-card.component.html'
})
export class AssetCardComponent {

  @Input()
  assets!: Asset[];

  @Output()
  assetEdited: EventEmitter<Asset> = new EventEmitter<Asset>();

  @Output()
  assetDeleted: EventEmitter<number> = new EventEmitter<number>();

  constructor() { }

  onAssetEdited = (asset: Asset) => {
    this.assetEdited.emit(asset);
  }

  onAssetDeleted = (id: number) => {
    this.assetDeleted.emit(id);
  }
}
