import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Constants } from '../services/constants';
import { WizardFlowServiceBase } from '../services/wizard/wizard-flow-service.base';

@Component({
  selector: 'borrower-portal-entry',
  template: ''
})

export class BorrowerPortalEntryComponent implements OnInit {

  constructor(private readonly _activatedRoute: ActivatedRoute,
    private readonly _authService: AuthService,
    private readonly _wizardFlowService: WizardFlowServiceBase) {
    this._activatedRoute.queryParams.subscribe(params => {
      const authToken = params[Constants.authToken];
      const applicationId = params[Constants.applicationId];
      const companyGuid = params[Constants.companyGuid];
      const userCompanyGuid = params[Constants.userCompanyGuid];
      if (authToken) {
        this._authService.loginWithToken(authToken, companyGuid, userCompanyGuid).subscribe(() => {
          this._wizardFlowService.navigateToApplicationFlow(applicationId);
        });
      }
    });
  }

  ngOnInit() { }
}
