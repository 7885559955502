<!--address, city-->
<address #addressComponent [address]="address"></address>
<div class="row mb-8">
  <div class="col-md-6 fv-row" *ngIf="!isHidden('asset.disposition')">
    <label class="form-label mb-2" [ngClass]="{'required': isRequired('asset.disposition')}">{{getLabel('asset.disposition', 'Disposition')}}</label>
    <input class="form-control form-control-solid"
    [required]="isRequired('asset.disposition')"
    [ngClass]="{'is-invalid' : disposition && disposition.touched && disposition.invalid}"
    #disposition="ngModel" [(ngModel)]="asset!.disposition" placeholder="" name="disposition" />
  </div>
  <div class="col-md-6 fv-row" *ngIf="!isHidden('asset.amountOfMortgageLien')">
    <label class="form-label mb-2" [ngClass]="{'required': isRequired('asset.amountOfMortgageLien')}">{{getLabel('asset.amountOfMortgageLien', 'Amount Of Mortgage Lien')}}</label>
    <input class="form-control form-control-solid" mask="separator" thousandSeparator="," inputmode="decimal" prefix="$"
      [ngClass]="{'is-invalid' : amountOfMortageLien && amountOfMortageLien.touched && amountOfMortageLien.invalid}"
      [required]="isRequired('asset.amountOfMortgageLien')"
    #amountOfMortageLien="ngModel" [(ngModel)]="asset!.amountOfMortgageLien" placeholder="" name="amountOfMortageLien" />
    <div class="invalid-feedback">Amount of mortagage lien is required.</div>
  </div>
</div>
<div class="row mb-8">
  <div class="col-md-6 fv-row" *ngIf="!isHidden('asset.mortgagePayment')">
      <label class="form-label mb-2" [ngClass]="{'required': isRequired('asset.mortgagePayment')}">{{getLabel('asset.mortgagePayment', 'Mortgage Payment')}}</label>
      <input class="form-control form-control-solid" mask="separator" thousandSeparator="," inputmode="decimal" prefix="$"
        [required]="isRequired('asset.mortgagePayment')"
        [ngClass]="{'is-invalid' : mortgagePayment && mortgagePayment.touched && mortgagePayment.invalid}"
        #mortgagePayment="ngModel" [(ngModel)]="asset!.mortgagePayment" placeholder="" name="mortgagePayment" />
  </div>
  <div class="col-md-6 fv-row" *ngIf="!isHidden('asset.gross')">
      <label class="form-label mb-2" [ngClass]="{'required': isRequired('asset.gross')}">{{getLabel('asset.gross', 'Gross')}}</label>
      <input class="form-control form-control-solid" mask="separator" thousandSeparator="," inputmode="decimal" prefix="$"
      [required]="isRequired('asset.gross')"
      [ngClass]="{'is-invalid' : gross && gross.touched && gross.invalid}"
      #gross="ngModel" [(ngModel)]="asset!.gross" placeholder="" name="gross" />
  </div>
  </div>
  <div class="row mb-8">
    <div class="col-md-6 fv-row" *ngIf="!isHidden('asset.netRentalIncome')">
        <label class="form-label mb-2" [ngClass]="{'required': isRequired('asset.netRentalIncome')}">{{getLabel('asset.netRentalIncome', 'Net Rental Income')}}</label>
        <input class="form-control form-control-solid" mask="separator" thousandSeparator="," inputmode="decimal" prefix="$"
          [required]="isRequired('asset.netRentalIncome')"
          [ngClass]="{'is-invalid' : netRentalIncome && netRentalIncome.touched && netRentalIncome.invalid}"
          #netRentalIncome="ngModel" [(ngModel)]="asset!.netRentalIncome" placeholder="" name="netRentalIncome" />
    </div>
    <div class="col-md-6 fv-row" *ngIf="!isHidden('asset.insMainTaxMisc')">
        <label class="form-label mb-2" [ngClass]="{'required': isRequired('asset.insMainTaxMisc')}">{{getLabel('asset.insMainTaxMisc', 'Insurance Maintainance Taxes Misc')}}</label>
        <input class="form-control form-control-solid" mask="separator" thousandSeparator="," inputmode="decimal" prefix="$"
          [required]="isRequired('asset.insMainTaxMisc')"
          [ngClass]="{'is-invalid' : insMainTaxMisc && insMainTaxMisc.touched && insMainTaxMisc.invalid}"
          #insMainTaxMisc="ngModel" [(ngModel)]="asset!.insMainTaxMisc" placeholder="" name="insMainTaxMisc" />
    </div>
    </div>


