import { Component, Injector, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Borrower } from '../../../models/borrower-model';
import { Declarations } from '../../../models/declarations-model';
import { DeclarationsStep } from '../../../models/wizard/declarations-step.model';
import { EnumsService } from '../../../services/enums.service';
import { BorrowerDeclarationsComponent } from '../../borrower-declarations/borrower-declarations.component';
import { WizardStepComponentBase } from '../wizard-step-base.component';

@Component({
  selector: 'declarations',
  templateUrl: 'declarations-step.component.html'
})

export class DeclarationsStepComponent extends WizardStepComponentBase<DeclarationsStep> implements OnInit {

  declarations: Declarations = new Declarations();

  mainBorrower!: Borrower;
  coBorrowers: Borrower[] = [];

  @ViewChildren('borrowerDeclarations') borrowerDeclarationsComponents: QueryList<BorrowerDeclarationsComponent> | undefined;

  constructor(private readonly _injector: Injector,
    private readonly _enumsService: EnumsService) {
    super(_injector);
    this.saveMortgageApplicationBeforeNextStep = true;
    if (this.currentBorrower) {
      this.mainBorrower = this.currentBorrower;
      this.coBorrowers = this.mortgageApplication.borrowers.filter(b => b.primaryEmail.toLocaleLowerCase() !==
          this.mainBorrower.primaryEmail.toLocaleLowerCase());
    }
  }

  onNextClicked() {
    let valid = true;
    if (this.borrowerDeclarationsComponents) {
        for (let borrowerDeclaration of this.borrowerDeclarationsComponents){
            valid = borrowerDeclaration.validate() && valid;
        }
    }
    if (valid) {
      super.onNextClicked();
    }
  }

  public get propertyTypes() {
    return this._enumsService.propertyTypesForDeclarations;
  }

  public get bankruptcyTypes() {
    return this._enumsService.bankruptyTypes;
  }

  public get heldTitleHows() {
    return this._enumsService.heldTitleHows;
  }

  ngOnInit() { }
}
