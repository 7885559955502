import { Injectable } from "@angular/core";
import { ContextExtractor } from "projects/borrower-app/src/app/services/context-extractor";
import { UtilsService } from "projects/borrower-app/src/app/services/utils.service";
import { WizardFlowContext } from "../../../wizard-flow-context.model";
import { AddressStep } from "../../address-step.model";
import { WizardStepBase } from "../../wizard-step-base.model";
import { AddressStepConfig } from "../address-step-config.model";
import { StepConfig } from "../step-config.model";
import { NextStepDeciderFactory } from "./next-step-decider.factory";
import { StepFactoryBase } from "./step-factory-base";
import { ICreateStep } from "./step-factory.interface";

@Injectable()
export class AddressStepFactory extends StepFactoryBase<AddressStepConfig> implements ICreateStep {

  create(config: AddressStepConfig, wizardFlowContext: WizardFlowContext): WizardStepBase {
    const nextStepDecider = this._nextStepDeciderFactory.create(config.nextStepDeciderConfig, wizardFlowContext);
    const model = ContextExtractor.extractContext(wizardFlowContext, config.contextType);
    let addressStep = new AddressStep(config.id, config.name, config.path, nextStepDecider, config.addressFields, model);
    addressStep.title = config.title;
    addressStep.explanation = config.explanation;
    addressStep.heading = config.heading;
    return addressStep;
  }

  configure(step: WizardStepBase, config: StepConfig): void {
    config.explanation = step.explanation;
    config.title = step.title;
    config.heading = step.heading;
    config.groupId = step.groupId;
  }

  constructor(private readonly _nextStepDeciderFactory: NextStepDeciderFactory,
    private readonly _utilsService: UtilsService) {
      super();
  }
}
