import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from 'projects/shared/modal/modal.service';
import { RealEstateOwnedDialogComponent } from '../wizard-steps/reo-dialog/reo-dialog.component';
import { Liability, RealEstateOwned } from '../../models/mortgage.model';
import { WizardFlowServiceBase } from '../../services/wizard/wizard-flow-service.base';
import { NgxSpinnerService } from 'ngx-spinner';
import { formViewProvider } from '../../services/form-view.provider';

@Component({
  selector: 'liability-reo',
  templateUrl: 'liability-reo.component.html',
  viewProviders: [formViewProvider]
})
export class LiabilityReoComponent implements OnInit, OnDestroy {

  constructor(private readonly _modalService: ModalService,
    private readonly _wizardFlowService: WizardFlowServiceBase,
    private readonly _spinner: NgxSpinnerService) {
  }

  private _subscriptionToReoSavedForCreate: any;

  private modalOptions: NgbModalOptions = {
    size: "lg",
    backdrop: "static",
    container: "#reo-main"
  };

  @Input()
  liability!: Liability;

  @Input()
  set reos(resos: RealEstateOwned[]) {
    this.reoItems = resos.map(reo => {
      return {
        reoId: reo.reoId,
        address: reo.address1
      }
    });
  }

  protected reoItems: ReoAddress[] = [];

  onOptionsSelected(value: string) {
    if (value === "addReo") {
      this.showRealEstateOwnedPopup();
    } else if (value) {
      this.liability.reoId = Number(value);
    }
  }

  private showRealEstateOwnedPopup() {
    const modal = this._modalService.show(RealEstateOwnedDialogComponent, this.modalOptions);
    this._subscriptionToReoSavedForCreate = modal.componentInstance.saveClickedEmitterForCreate
      .subscribe((e: RealEstateOwned) => this.onSaveClickedOnReoModalForCreate(e));
    modal.result.then(() => { }, () => {
      this.liability.reoId = null
    });
  }

  private onSaveClickedOnReoModalForCreate = (reo: RealEstateOwned): void => {
    reo.dateAcquired = new Date(reo.dateAcquired).toISOString();
    this._wizardFlowService.context.mortgage.realEstateOwned.push(reo);
    this._spinner.show();
    this.liability.reoId = null;
    this._wizardFlowService.saveMortgage().subscribe(() => {
      const reoAdded = this._wizardFlowService.context.mortgage.realEstateOwned.find(r =>
        r.address1.toLocaleLowerCase() === reo.address1.toLowerCase() &&
        r.city.toLocaleLowerCase() === reo.city.toLowerCase() &&
        r.state.toLocaleLowerCase() === reo.state.toLowerCase() &&
        r.zipCode === reo.zipCode);
      this.liability = this._wizardFlowService.context.mortgage.liabilities.find(l => l.liabilityId === this.liability.liabilityId);
      if (reoAdded) {
        this.reoItems.push({ reoId: reoAdded.reoId, address: reo.address1 });
        this.liability.reoId = reoAdded.reoId;
      }
    }, err => {
    }).add(() => {
      this._spinner.hide();
    });
  }

  ngOnInit() {
    if (!this.liability.reoId) {
      this.liability.reoId = null;
    }
  }

  ngOnDestroy(): void {
    if (this._subscriptionToReoSavedForCreate) {
      this._subscriptionToReoSavedForCreate.unsubscribe();
    }
  }
}

export class ReoAddress {
  reoId: number;
  address: string;
}
