<div class="row d-flex justify-content-center full-height {{customClass}}">
  <div class="col-12 align-self-center">
      <div class="row">
          <div class="mx-auto my-auto">
              <div class="card no-border">
                  <div class="card-body">
                      <div class="ex-page-content text-center">
                        <div class="spinner-border thumb-lg text-primary" role="status"></div>
                        <h5 class="font-16 text-muted">{{loadingMessage}}</h5>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
