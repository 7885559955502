<div class="rounded border border-primary row py-2 m-2"
  [ngClass]="{'border-primary': !deleteAttempted && asset.isValid && !isReadOnly, 'border-danger' : (deleteAttempted || !asset.isValid) && !isReadOnly, 'border-dashed': !deleteAttempted && asset.isValid && !isReadOnly, 'readonly-item': isReadOnly  }">
  <div class="w-100 d-flex align-items-center justify-content-between hidden-when-mobile">
    <span class="svg-icon svg-icon-2x me-4 mt-2 duotone-home-svg-mobile" [ngClass]="{
      'svg-icon-primary': !deleteAttempted && !isReadOnly,
      'svg-icon-danger': deleteAttempted
    }">
      <ng-template svgIconHost></ng-template>
    </span>
    <div class="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap align-items-center">
      <span class="fs-6 text-gray-600 d-block fw-bold"><strong>{{ assetTypeName }}</strong></span>
      <div class="d-flex justify-content-end align-items-center">
        <label class="form-label line-item-text px-4 mt-3">Borrower: {{
          asset.assetOwners
          }}</label>
        <label class="form-label line-item-text px-4 mt-3">Value: {{ asset.value | currency }}</label>
        <ng-container *ngTemplateOutlet="actionButtons"></ng-container>
      </div>
    </div>
  </div>
  <div class="hidden-when-not-mobile w-100">
    <div class="w-100 d-flex align-items-center justify-content-between mb-3">
      <div class="text-gray-800 fw-bold">{{ assetTypeName }}</div>
      <ng-container *ngTemplateOutlet="actionButtons"></ng-container>
    </div>
    <div class="row mb-3 text-start">
      <div class="text-gray-400 fw-bold col-6">Borrower:</div>
      <div class="text-gray-800 fw-bold col-6">{{ asset.assetOwners }}</div>
    </div>
    <div class="row mb-3 text-start">
      <div class="text-gray-400 fw-bold col-6">Value:</div>
      <div class="text-gray-800 fw-bold col-6">
        {{ asset.value | currency }}
      </div>
    </div>
  </div>
</div>

<ng-template #actionButtons>
  <div class="align-self-center text-nowrap">
    <div class="d-flex" style="margin-top: 4px" *ngIf="deleteAttempted && !isReadOnly">
      <a href="javascript:void(0);" class="btn btn-icon btn-bg-light btn-active-color-success btn-sm me-1"
        (click)="onDeleteConfirmed($event)">
        <span class="svg-icon svg-icon-3">
          <stockholm-check-svg-icon></stockholm-check-svg-icon>
        </span>
      </a>
      <a href="javascript:void(0);" class="btn btn-icon btn-bg-light btn-active-color-danger btn-sm"
        (click)="onDeleteAborted($event)">
        <span class="svg-icon svg-icon-3">
          <stockholm-close-svg-icon></stockholm-close-svg-icon>
        </span>
      </a>
    </div>
    <div class="d-flex" style="margin-top: 4px" *ngIf="!deleteAttempted && !isReadOnly">
      <a href="javascript:void(0);" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" (click)="onEditClicked()">
        <span class="svg-icon svg-icon-3">
          <duotone-write-svg-icon></duotone-write-svg-icon>
        </span>
      </a>
      <a href="javascript:void(0);" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
        (click)="onDeleteClicked($event)">
        <span class="svg-icon svg-icon-3">
          <duotone-trash-svg-icon></duotone-trash-svg-icon>
        </span>
      </a>
    </div>
  </div>
</ng-template>
