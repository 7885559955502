import { CharacteristicMergeValue } from "./characteristic-merge-value.model";
import { ICharacteristic } from "./characteristic.interface";

export class BorrowerCharacteristic implements ICharacteristic {
    borrowerCharacteristicId: number;
    applicationId: number;
    borrowerId: number | undefined;
    characteristicId: number;
    characteristicMergeValues: Array<CharacteristicMergeValue>;
    companyId: number | undefined;
    insertedBy: string | undefined;
    dateInserted: string | undefined;
    updatedBy: string | undefined;
    dateUpdated: string | undefined;
    pendingDeletion: boolean = false;

    constructor(borrowerCharacteristicId: number, applicationId: number, characteristicId: number, borrowerId: number | undefined) {
      this.borrowerCharacteristicId = borrowerCharacteristicId;
      this.applicationId = applicationId;
      this.characteristicId = characteristicId;
      this.borrowerId = borrowerId;
      this.characteristicMergeValues = [];
    }
}
