<input
  [(ngModel)]="value"
  type="text"
  #control
  #model="ngModel"
  name="{{ name }}"
  id="{{ name }}"
  placeholder="{{ placeholder }}"
  [required]="required"
  [min]="min"
  [max]="max"
  [readonly]="readonly"
  (keydown)="onKeyDown($event)"
  (blur)="onBlurred($event)"
  (focus)="isEditActive = true; onFocused($event)"
  [disabled]="disabled"
  class="form-control form-control-solid"
  [ngClass]="{
    'is-invalid': model && model.touched && model.invalid,
    'marked-as-invalid': highlightAsInvalid
  }"
/>

<div
  class="invalid-feedback d-block"
  *ngIf="model.errors?.required"
>
  This field is required
</div>
<div class="invalid-feedback d-block" *ngIf="model.errors?.min">
  Value cannot be less than {{ min | currency }}
</div>
<div class="invalid-feedback d-block" *ngIf="model.errors?.max">
  Value cannot be greater than {{ max | currency }}
</div>
