<ng-template #reo>
  <div class="fv-row" id="reo-main">
    <div class="row mb-10">
      <div class="col-md-12">
        <reo-item #reoItem
          *ngFor="let reoItem of reoItems; let i = index"
          [reoItem]="reoItem"
          (editClicked)="onReoItemEditClicked($event)"
          (deleteClicked)="onReoItemDeleted($event)">
        </reo-item>
      </div>
      <div class="col-md-12">

            <div class="d-flex justify-content-center ">
              <label (click)="onAddRealEstateOwnedClicked()" class="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center">
                <span class="svg-icon svg-icon-2x me-5 duotone-home-svg-mobile" style="margin-top: -4px;">
                  <duotone-home-svg-icon></duotone-home-svg-icon>
                </span>
                <span class="d-block fw-bold text-start">
                  <span *ngIf="!inEditMode" class="text-dark fw-bolder d-block fs-5 mb-2">
                    {{ step.addRealEstateActionLabel || (reoItems.length > 0 ? 'Add Another Real Estate' : 'Add Real Estate')}}
                  </span>
                  <input *ngIf="inEditMode" name="addRealEstateActionLabel" type="text"
                    class="form-control form-control-solid fs-5 fw-bolder" [(ngModel)]="step.addRealEstateActionLabel" />
                  <span class="text-gray-400 fw-bold fs-6"></span>
                </span>
              </label>
            </div>
      </div>
    </div>
  </div>
</ng-template>

<wizard-step-template [stepMainTemplate]="reo" [step]="step" [stepComponent]="this"></wizard-step-template>

