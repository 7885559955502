import { ContextType } from "./context-type.enum";
import { NextStepDeciderConfig } from "./next-step-decider-config.model";
import { StepConfig } from "./step-config.model";
import { StepType } from "./step-type.enum";

export class PricingStepConfig extends StepConfig {

  noProductFoundMessageTitle: string = "No Available Products";

  noProductsFoundMessageText: string = "We were not able to find any available products based on your loan criteria.";

  constructor(id: string, name: string | undefined, path: string, groupId: number | undefined, nextStepDeciderConfig: NextStepDeciderConfig | undefined) {
    super(id, name, path, groupId, ContextType.None);
    this.stepType = StepType.PricingStep;
    this.nextStepDeciderConfig = nextStepDeciderConfig;
  }
}
