export type LogLevel = 'debug' | 'info' | 'warn' | 'error';

export interface IEnvironment {
  production: boolean;
  environment: string;

  // Enables use of ng.profiler.timeChangeDetection(); in browser console
  enableDebugTools: boolean;
  logLevel: LogLevel;
  apiInfo: ApiInfo;
  clientRoot: string;
  companyLogoUrl: string;
  branchLogoUrl: string;
  dnsBasedBranchLogoUrl: string;
  faviconUrl: string;
  borrowerPortalURI: string;
  scope?: string;
}

export class ApiInfo {
  apiBaseUrl: string | undefined;
  publicApiBaseUrl: string | undefined;
}
