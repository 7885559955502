<div class="d-flex">
  <div class="col-sm-3 col-form-label fw-bold fs-6 editor-label">Configure Address History</div>
  <div class="col-sm-2 form-check form-switch form-check-custom form-check-solid">
    <input class="form-check-input h-20px w-30px" type="checkbox" id="configuresAddressHistory"
    [ngModelOptions]="{standalone: true}" [(ngModel)]="step.configuresAddressHistory" />
    <label class="form-check-label" for="configuresAddressHistory">
      {{step.configuresAddressHistory ? "Yes" : "No"}}
    </label>
  </div>
</div>

<div class="d-flex mb-10">
  <div class="col-sm-3 col-form-label fw-bold fs-6 editor-label">Configure Co-borrowers</div>
  <div class="col-sm-2 form-check form-switch form-check-custom form-check-solid">
    <input class="form-check-input h-20px w-30px" type="checkbox" id="configuresCoborrowers"
    [ngModelOptions]="{standalone: true}" [(ngModel)]="step.configuresCoborrowers" />
    <label class="form-check-label" for="configuresCoborrowers">
      {{step.configuresCoborrowers ? "Yes" : "No"}}
    </label>
  </div>
</div>

<div class="d-flex mb-2">
  <label class="fs-6 col-sm-4 col-form-label fw-bold fs-6 editor-label">Restrict Middle Name/Initial to Single Character:</label>
  <div class="col-sm-2 form-check form-switch form-check-custom form-check-solid" style="margin-top: 8px;">
    <input class="form-check-input h-20px w-30px" type="checkbox" id="restrictMiddleNameToSingleCharacter"
      [ngModelOptions]="{standalone: true}" [(ngModel)]="step.restrictMiddleNameToSingleCharacter" />
    <label class="form-check-label" for="restrictMiddleNameToSingleCharacter">
      {{step.restrictMiddleNameToSingleCharacter ? "Yes" : "No"}}
    </label>
  </div>
</div>

<field-config-editor #borrowerFieldsConfigEditor [header]="'Borrower Field Configuration'"></field-config-editor>

<field-config-editor [hidden]="!step.configuresAddressHistory" #addressFieldsConfigEditor [header]="'Address Field Configuration'"></field-config-editor>
