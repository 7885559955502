import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { NgModel } from '@angular/forms';
import { AbstractValueAccessor, MakeProvider } from '../abstract-value-accessor';
import { formViewProvider } from 'projects/borrower-app/src/app/services/form-view.provider';

@Component({
  selector: 'currency-input',
  templateUrl: 'currency-input.component.html',
  styleUrls: ['currency-input.component.scss'],
  providers: [MakeProvider(CurrencyInputComponent)],
  viewProviders: [formViewProvider]
})
export class CurrencyInputComponent extends AbstractValueAccessor implements OnInit {

  @ViewChild('model') model: NgModel;

  @ViewChild('control') input: ElementRef<HTMLInputElement>;

  @Input()
  readonly: boolean;

  @Input()
  min: number;

  @Input()
  max: number;

  @Input()
  disabled: boolean;

  @Input()
  highlightAsInvalid: boolean = false;

  @Input()
  name: string;

  @Input()
  required: boolean;

  @Input()
  isZeroValid: boolean = true;

  @Input()
  placeholder: string = "$";

  id: string;

  @Output()
  change: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  blur: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  set allowNegative(allowNegative: boolean) {
    this._allowNegative = allowNegative;
    this.setRegexForValidation();
  }

  get allowNegative(): boolean {
    return this._allowNegative;
  }

  @Input()
  set allowDecimals(allowDecimals: boolean) {
    this._allowDecimals = allowDecimals;
    this.setRegexForValidation();
  }

  get allowDecimals(): boolean {
    return this._allowDecimals;
  }

  @Input()
  allowEmpty: boolean = true;

  isEditActive: boolean = false;

  private _isFocused: boolean = false;

  private _caretPositionToMoveToInEditMode: number = 0;

  private _negativeOrPositiveIntegersRegex = /^-?[0-9]\d*$/;

  private _negativeOrPositiveDecimalsRegex = /^[-]?[0-9]{1,9}(?:\.[0-9]{1,2})?$/;

  private _positiveDecimalsRegex = /^[0-9]{1,9}(?:\.[0-9]{1,2})?$/;

  private _positiveIntegersRegex = /^[0-9]\d*$/;

  private _regexForSanitation = /[^\d|\-+|\.+]/;

  private _regexForValidation: RegExp = null;

  private _allowNegative = true;

  private _allowDecimals = true;

  private _minValue = -99999999;

  private _maxValue = Number.MAX_SAFE_INTEGER;

  private _originalValue: any;

  constructor(private readonly _renderer2: Renderer2,
    private readonly _currencyPipe: CurrencyPipe) {
    super();
    this._regexForValidation = this._negativeOrPositiveDecimalsRegex;
  }

  override writeValue(value: any) {
    if (!this.isZeroValid && !isNaN(value)) {
      if (value === 0) {
        setTimeout(() => this.model.control.setErrors({ 'required': true }), 500);
      } else {
        setTimeout(() => this.model.control.setErrors(null), 500);
      }
    }
    if (this.min && value != null && !isNaN(value) && value < this.min) {
      setTimeout(() => this.model.control.setErrors({ 'min': true }), 500);
    }
    if (this.max && value != null && !isNaN(value) && value > this.max) {
      setTimeout(() => this.model.control.setErrors({ 'max': true }), 500);
    }
    this._value = value;
    this.onChange(value);
    this._originalValue = value;
    if (this.input && !this._isFocused) {
      const formattedValue = this._currencyPipe.transform(this._value, 'USD', 'symbol', '1.2-2');
      setTimeout(() => {
        this._renderer2.setProperty(this.input.nativeElement, 'value', formattedValue);
      })
    }
  }

  override registerOnChange(fn: (_: number | null) => void): void {
    this.onChange = (value) => {
      let numericValue: number | null = null;
      if (!value && value !== 0) {
        if (!this.allowEmpty) {
          numericValue = 0;
        }
      } else {
        numericValue = parseFloat(value);
      }
      fn(numericValue != 0 ? numericValue : value);
    };
  }

  onEditModeToggledOn = () => {
    this._originalValue = this._value;
    setTimeout(() => {
      this.input.nativeElement.focus();
    });
    this.isEditActive = true;
  }

  onKeyDown = (event: any) => {
    if (event.key === 'Tab' || event.key === 'Backspace' || event.key === 'Delete' || event.key === 'F12') {
      return;
    }
    const enteredValue = event.key ? event.key : "";
    let valueToTest = this._value != null ? this._value.toString() : '';
    if (document.activeElement) {
      const activeelement: any = document.activeElement;
      valueToTest = valueToTest.substr(0, activeelement.selectionStart) + valueToTest.substr(activeelement.selectionEnd);
      valueToTest = [valueToTest.slice(0, activeelement.selectionStart), enteredValue, valueToTest.slice(activeelement.selectionStart)].join('');
    }
    if (valueToTest === '-' || valueToTest === '0' || valueToTest.charAt(valueToTest.length - 1) === '.') {
      valueToTest += '1';
    }
    let passes = this._regexForValidation.test(Number(valueToTest).toString());
    if (!passes) {
      event.preventDefault();
    }
  }

  onFocused = (event: any) => {
    this._isFocused = true;
    let currentvalue = this._value != null ? this._value.toString() : '';

    if (!currentvalue && this.allowEmpty) {
      return;
    }

    let sanitized = currentvalue.replace(this._regexForSanitation, '');
    sanitized = currentvalue != 0 ? Number(sanitized).toFixed(2) : null;

    const formattedValue = this.input.nativeElement.value;

    setTimeout(() => {
      const activeelement: any = document.activeElement;
      if (activeelement) {
        const caretPositionWhenClicked = activeelement.selectionStart;
        this._caretPositionToMoveToInEditMode = caretPositionWhenClicked;
        for (let i = 0; i <= caretPositionWhenClicked - 1; i++) {
          if (['$', ','].includes(formattedValue.charAt(i))) {
            this._caretPositionToMoveToInEditMode -= 1;
          }
        }
      }

      this._renderer2.setProperty(this.input.nativeElement, 'value', sanitized);
      setTimeout(() => {
        this.moveCaret();
      })
    })
  }

  onBlurred = (event: any) => {
    if (Number(this._value) < this.min) {
      setTimeout(() => this.model.control.setErrors({ 'min': true }), 0);
    }
    if (Number(this._value) > this.max) {
      setTimeout(() => this.model.control.setErrors({ 'max': true }), 0);
    }
    this._isFocused = false;
    if (this.input) {
      let currentvalue = this._value != null ? this._value.toString() : '';
      let sanitized = currentvalue.replace(this._regexForSanitation, '');
      if (sanitized) {
        sanitized = Number(sanitized).toFixed(2);
        this._value = Number(sanitized);
      }
      setTimeout(() => {
        const formattedValue = this._currencyPipe.transform(this._value, 'USD', 'symbol', '1.2-2');
        this._renderer2.setProperty(this.input.nativeElement, 'value', formattedValue);
      })
    }
    this.blur.emit(event);
    this.apply();
  }

  ngOnInit(): void {
    this.name = this.name + Math.floor(Math.random() * Date.now());
    this.id = this.name;
    this.setRegexForValidation();
  }

  private moveCaret = () => {
    if (this.input.nativeElement.setSelectionRange) {
      this.input.nativeElement.setSelectionRange(this._caretPositionToMoveToInEditMode,
        this._caretPositionToMoveToInEditMode);
    }
  }

  private apply = () => {
    if (this.model.valid) {
      this._originalValue = this.value;
      this.isEditActive = false;
    }
  }

  private setRegexForValidation = () => {
    if (this._allowNegative) {
      if (this._allowDecimals) {
        this._regexForValidation = this._negativeOrPositiveDecimalsRegex;
      } else {
        this._regexForValidation = this._negativeOrPositiveIntegersRegex;
      }
    } else {
      if (this._allowDecimals) {
        this._regexForValidation = this._positiveDecimalsRegex;
      } else {
        this._regexForValidation = this._positiveIntegersRegex;
      }
    }
  }
}
