import { Component, Injector, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MilitaryServiceStep } from '../../models/wizard/military-service-step.model';
import { WizardStepComponentBase } from '../wizard-steps/wizard-step-base.component';
import { Borrower } from '../../models/borrower-model';
import { BorrowerMilitaryServiceComponent } from '../borrower-military-service/borrower-military-service.component';

@Component({
  selector: 'military-service-step',
  templateUrl: 'military-service-step.component.html'
})

export class MilitaryServiceStepComponent extends WizardStepComponentBase<MilitaryServiceStep> implements OnInit {

  mainBorrower!: Borrower;
  coBorrowers: Borrower[] = []

  @ViewChildren('borrowerMilitaryService')
  borrowerMilitaryComponents: QueryList<BorrowerMilitaryServiceComponent> | undefined;

  constructor(
    private readonly _injector: Injector) {
    super(_injector);
    this.saveMortgageApplicationBeforeNextStep = true;
    this.mainBorrower = this.currentBorrower;
    this.coBorrowers = this.mortgageApplication.borrowers.filter(b => b.primaryEmail.toLocaleLowerCase() !==
          this.mainBorrower.primaryEmail.toLocaleLowerCase());
  }

  onNextClicked(): void {
    let isValid = true;
    const borrowerMilitaryServiceConfigs: BorrowerMilitaryServiceComponent[] = this.borrowerMilitaryComponents.toArray();
    borrowerMilitaryServiceConfigs.forEach(c => {
      const isValidForBorrower = c.validate();
      isValid = isValid && isValidForBorrower;
    });
    if (isValid) {
      super.onNextClicked();
    }
  }
}
