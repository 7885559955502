import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Borrower } from '../../../models/borrower-model';
import { SubjectProperty } from '../../../models/subject-property.model';
import { BorrowerSummaryComponent } from '../../borrower-summary/borrower-summary.component';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { MortgageApplication } from '../../../models/mortgage-app.model';

@Component({
  selector: 'borrower-review-application',
  templateUrl: 'borrower-review-application.component.html',
  animations: [
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(90deg)' })),
      transition('rotated => default', animate('400ms ease-out')),
      transition('default => rotated', animate('400ms ease-in'))
    ])
  ]
})

export class BorrowerReviewApplicationComponent {

  constructor() { }

  @ViewChild(BorrowerSummaryComponent) borrowerSummary!: BorrowerSummaryComponent;

  @Input()
  borrower!: Borrower;

  @Output()
  borrowerChanged: EventEmitter<Borrower> = new EventEmitter<Borrower>();

  @Input()
  mortgage!: MortgageApplication;

  @Input()
  purchasePrice!: number;

  borrowerSummaryExpanded: boolean = false;

  showBorrowerDetails: boolean = false;

  state: string = 'default';

  onBorrowerSummaryToggled = () => {
    this.borrowerSummaryExpanded = !this.borrowerSummaryExpanded;
    this.borrowerSummary.detailsHidden = this.borrowerSummaryExpanded;
  }

  onBorrowerChanged = (borrower: Borrower) => {
    this.borrower = borrower;
    this.borrowerChanged.emit(this.borrower);
  }

  rotateStateChanged = () => {
    this.state = (this.state === 'default' ? 'rotated' : 'default');
  }
}
