import { IDecideNextStep } from "../../services/wizard/next-step-decider/next-step-decider.interface";
import { CurrentAddressHistoryComponent } from "../../wizard/wizard-steps/current-address-history/current-address-history.component";
import { WizardStepBase } from "./wizard-step-base.model";

export class AddressHistoryStep extends WizardStepBase {
  constructor(id: string, name: string, path: string, nextStepDecider?: IDecideNextStep) {
    super(id, name, path, nextStepDecider);
    this.component = CurrentAddressHistoryComponent;
  }
}
