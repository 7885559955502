import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MortgageCalculatedStats, PayoffType, UrlaMortgage } from '../models/urla-mortgage.model';
import { Constants } from './constants';
import { EnumsService } from './enums.service';
import { HousingExpense } from '../models/housing-expense.model';
import { EmploymentTypeEnum, Liability, MortgageBorrower } from '../models/mortgage.model';
import { ResidencyType } from '../models/enums/residency-type.enum';

@Injectable({
  providedIn: 'root'
})
export class MortgageCalculationsService {

  reoLiabilityAssociationChanged = new Subject<void>();

  liabilitySubject = new Subject<any>();

  otherMortgagesChanged = new Subject<any>();

  constructor(private readonly _enumsService: EnumsService) {
  }

  calculateMortgageStatistics = (mortgage: UrlaMortgage) => {
    if (!mortgage.calculatedStats)
      mortgage.calculatedStats = new MortgageCalculatedStats();

    const lenderCreditEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.PurchaseCreditType.LenderCredit);
    const lenderCredit = mortgage.transactionDetail?.purchaseCredits.find(p => p.purchaseCreditType == lenderCreditEnumValue);
    if (lenderCredit) {
      mortgage.calculatedStats.lenderCredit = lenderCredit.purchaseCreditAmount;
    }
    mortgage.calculatedStats.totalPaidOffForRefinance = this.calculateTotalPayOffForRefinance(mortgage);
    mortgage.calculatedStats.landValue = this.calculateLandValueChange(mortgage);
    mortgage.calculatedStats.estimatedClosingCostsAmount = this.calculateEstimatedClosingCosts(mortgage);
    mortgage.calculatedStats.financialPartialPayoffTotalAmount = this.calculateFinancialPartialPayoffTotalAmount(mortgage);
    mortgage.calculatedStats.totalDue = this.calculateTotalDue(mortgage);
    mortgage.calculatedStats.totalLoanOrDrawAmount = this.calculateTotalLoanOrDrawAmount(mortgage);
    mortgage.calculatedStats.totalMortgageLoans = this.calculateTotalMortgage(mortgage);
    mortgage.calculatedStats.totalOtherCredit = this.calculateTotalOtherCredit(mortgage);
    mortgage.calculatedStats.totalCredit = this.calculateTotalCredit(mortgage);
    mortgage.calculatedStats.totalMortgageLoansAndCredits = this.calculateTotalMortgageLoansAndCredits(mortgage);
    mortgage.calculatedStats.totalDueFromBorrowers = this.calculateTotalDueFromBorrowers(mortgage);
    mortgage.calculatedStats.cashFromOrToTheBorrower = this.calculateCashFromOrToTheBorrower(mortgage);
    if (mortgage.proposedHousingExpense)
      mortgage.proposedHousingExpense.firstMortgagePrincipalAndInterest = this.getFirstMortgagePrincipalAndInterest(mortgage) || 0;
    mortgage.calculatedStats.proposedMonthlyPaymentTotal = this.calculateProposedHousingExpense(mortgage.proposedHousingExpense);
    mortgage.calculatedStats.sourceOfFunds = this.calculateSourceOfFundsValue(mortgage);
  }

  isPurposeOfLoanRefinance = (mortgage: UrlaMortgage) => {
    return mortgage?.subjectProperty?.purposeOfLoan == this._enumsService.getEnumValue(Constants.enumerationValueNames.LoanPurposeType.Refinance);
  }

  isPurposeOfLoanPurchase = (mortgage: UrlaMortgage) => {
    return mortgage?.subjectProperty?.purposeOfLoan == this._enumsService.getEnumValue(Constants.enumerationValueNames.LoanPurposeType.Purchase);
  }

  calculateOtherPaidOffsTotal = (mortgage: UrlaMortgage) => {
    let subTotal = 0;
    mortgage.realEstateOwned?.forEach(reo => {
      if (reo.isSubjectProperty) {
        let filterReoOfLiabilities = mortgage.liabilities?.filter(l => l.reoId !== reo.reoId) || [];
        filterReoOfLiabilities.forEach(liability => {
          if (liability.payoffType === this._enumsService.getEnumValue(Constants.enumerationValueNames.PayoffType.Full)) {
            subTotal += Number(liability.unpaidBalance);
          }
          else if (liability.payoffType === this._enumsService.getEnumValue(Constants.enumerationValueNames.PayoffType.Partial)) {
            subTotal += Number(liability.partialPayoffAmount);
          }
        });
      }
    });
    return subTotal;
  }

  calculateOtherLiensOnSubjectProperty = (mortgage: UrlaMortgage): number => {
    const subjectPropertyReo = mortgage.realEstateOwned.find(reo => reo.isSubjectProperty);
    if (subjectPropertyReo) {
      const mortgageLiabilityTypes = [
        this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.MortgageLoan),
        this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.HELOC)
      ];

      const liabilitiesLinkedToSubjectPropertyReo = subjectPropertyReo.liabilities.filter(l =>
        mortgageLiabilityTypes.includes(l.typeOfLiability) &&
        !l.isExcluded &&
        l.payoffType !== PayoffType.Full
      );

      let mortgageLiabilities = mortgage.liabilities.filter(l =>
        mortgageLiabilityTypes.includes(l.typeOfLiability) &&
        l.reoId === subjectPropertyReo.reoId &&
        !l.isExcluded &&
        l.payoffType !== PayoffType.Full
      );
      mortgageLiabilities = mortgageLiabilities.concat(liabilitiesLinkedToSubjectPropertyReo);

      const unpaidBalanceOfMortgageLiabilities = mortgageLiabilities
        .map(l => l.unpaidBalance || 0)
        .reduce((accumulator, current) => accumulator + current, 0);

      return unpaidBalanceOfMortgageLiabilities
    }
    return 0;
  }

  calculateTotalMonthlyPaymentAndTotalLiensForOtherMortgages = (mortgage: UrlaMortgage): MortgageLienAndMonthlyPaymentAmount => {
    const subjectPropertyReo = mortgage.realEstateOwned.find(reo => reo.isSubjectProperty);
    const result: MortgageLienAndMonthlyPaymentAmount = {
      monthlyPayment: 0,
      lienAmount: 0
    }
    let unpaidBalanceOfMortgageLiabilities = 0;
    let monthlyPaymentTotalForMortgageLiabilities = 0;
    if (subjectPropertyReo) {
      const mortgageLiabilityTypes = [
        this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.MortgageLoan),
        this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.HELOC)
      ];

      const liabilitiesLinkedToSubjectPropertyReo = subjectPropertyReo.liabilities.filter(l =>
        mortgageLiabilityTypes.includes(l.typeOfLiability) &&
        !l.isExcluded &&
        l.payoffType !== PayoffType.Full
      );

      let mortgageLiabilities = mortgage.liabilities.filter(l =>
        mortgageLiabilityTypes.includes(l.typeOfLiability) &&
        l.reoId === subjectPropertyReo.reoId &&
        !l.isExcluded &&
        l.payoffType !== PayoffType.Full
      );

      mortgageLiabilities = mortgageLiabilities.concat(liabilitiesLinkedToSubjectPropertyReo);

      unpaidBalanceOfMortgageLiabilities = mortgageLiabilities
        .map(l => l.unpaidBalance || 0)
        .reduce((accumulator, current) => accumulator + current, 0);

      monthlyPaymentTotalForMortgageLiabilities = mortgageLiabilities
        .map(l => l.monthlyPayment || 0)
        .reduce((accumulator, current) => accumulator + current, 0);
    }

    const relatedMortgagesTotalUnpaidBalance = mortgage.relatedMortgages
      .map(l => l.loanOrDrawAmount || 0)
      .reduce((accumulator, current) => accumulator + current, 0);

    const relatedMortgagesMonthlyPaymentTotal = mortgage.relatedMortgages
      .map(l => l.monthlyPayment || 0)
      .reduce((accumulator, current) => accumulator + current, 0);

    result.monthlyPayment = monthlyPaymentTotalForMortgageLiabilities + relatedMortgagesMonthlyPaymentTotal;
    result.lienAmount = unpaidBalanceOfMortgageLiabilities + relatedMortgagesTotalUnpaidBalance;

    return result;
  }

  calculateTotalPayOffForRefinance = (mortgage: UrlaMortgage) => {
    let subTotal = 0;
    if (this.isPurposeOfLoanRefinance(mortgage)) {
      mortgage.realEstateOwned?.forEach(reo => {
        if (reo.isSubjectProperty) {
          reo.liabilities?.forEach(liability => {
            if (liability.payoffType === this._enumsService.getEnumValue(Constants.enumerationValueNames.PayoffType.Full)) {
              subTotal += Number((liability.unpaidBalance || 0));
            }
            else if (liability.payoffType === this._enumsService.getEnumValue(Constants.enumerationValueNames.PayoffType.Partial)) {
              subTotal += Number((liability.partialPayoffAmount || 0));
            }
          });

          let filterReoOfLiabilities = mortgage.liabilities?.filter(l => l.reoId == reo.reoId) || [];
          filterReoOfLiabilities.forEach(liability => {
            if (liability.payoffType === this._enumsService.getEnumValue(Constants.enumerationValueNames.PayoffType.Full)) {
              subTotal += Number((liability.unpaidBalance || 0));
            }
            else if (liability.payoffType === this._enumsService.getEnumValue(Constants.enumerationValueNames.PayoffType.Partial)) {
              subTotal += Number((liability.partialPayoffAmount || 0));
            }
          });
        }
      });
    }
    return subTotal;
  }

  calculateFinancialPartialPayoffTotalAmount = (mortgage: UrlaMortgage) => {
    let subTotal = 0;
    let subjProp = mortgage.realEstateOwned?.find(reo => reo.isSubjectProperty);
    let filterReoOfLiabilities = mortgage.liabilities?.filter(l => !l.reoId || (subjProp && l.reoId !== subjProp.reoId));
    filterReoOfLiabilities?.forEach(liability => {
      if (liability.payoffType === this._enumsService.getEnumValue(Constants.enumerationValueNames.PayoffType.Full)) {
        subTotal += Number((liability.unpaidBalance || 0));
      }
      else if (liability.payoffType === this._enumsService.getEnumValue(Constants.enumerationValueNames.PayoffType.Partial)) {
        subTotal += Number((liability.partialPayoffAmount || 0));
      }
    });
    return subTotal;
  }

  calculateLandValueChange = (mortgage: UrlaMortgage) => {
    if (!mortgage || !mortgage.subjectProperty)
      return 0;
    let landValue = 0;
    if (mortgage.subjectProperty.landValueType === this._enumsService.getEnumValue(Constants.enumerationValueNames.LandValueType.Appraised)) {
      landValue = Number(mortgage.subjectProperty.lotAppraisedValue);
    } else if (mortgage.subjectProperty.landValueType === this._enumsService.getEnumValue(Constants.enumerationValueNames.LandValueType.Original)) {
      landValue = Number(mortgage.subjectProperty.lotOriginalCost);
    } else {
      landValue = 0;
    }
    return landValue;
  }

  calculateEstimatedClosingCosts = (mortgage: UrlaMortgage) => {
    let subTotal = 0;
    if (mortgage.transactionDetail?.prepaidItemsEstimatedAmount) {
      subTotal += Number(mortgage.transactionDetail.prepaidItemsEstimatedAmount);
    }
    if (mortgage.transactionDetail?.prepaidEscrowsTotalAmount) {
      subTotal += Number(mortgage.transactionDetail.prepaidEscrowsTotalAmount);
    }
    if (mortgage.transactionDetail?.estimatedClosingCostsExcludingPrepaidsAmount) {
      subTotal += Number(mortgage.transactionDetail.estimatedClosingCostsExcludingPrepaidsAmount);
    }
    if (mortgage.mortgageInsuranceDetail?.miOrFundingFeeTotalAmount) {
      subTotal += Number(mortgage.mortgageInsuranceDetail.miOrFundingFeeTotalAmount);
    }

    return subTotal;
  }

  calculateTotalDue = (mortgage: UrlaMortgage) => {
    let subTotal = 0;
    if (mortgage.transactionDetail?.purchasePriceAmount) {
      subTotal += Number(mortgage.transactionDetail.purchasePriceAmount);
    }
    if (mortgage.transactionDetail?.alterationsImprovementsAndRepairsAmount) {
      subTotal += Number(mortgage.transactionDetail.alterationsImprovementsAndRepairsAmount);
    }

    if (mortgage.calculatedStats?.landValue) {
      subTotal += Number(mortgage.calculatedStats.landValue);
    }
    if (mortgage.calculatedStats?.financialPartialPayoffTotalAmount) {
      subTotal += Number(mortgage.calculatedStats.financialPartialPayoffTotalAmount);
    }
    if (mortgage.calculatedStats?.estimatedClosingCostsAmount) {
      subTotal += Number(mortgage.calculatedStats.estimatedClosingCostsAmount);
    }
    if (mortgage.transactionDetail?.borrowerPaidDiscountPointsTotalAmount) {
      subTotal += Number(mortgage.transactionDetail.borrowerPaidDiscountPointsTotalAmount);
    }
    if (mortgage.calculatedStats?.totalPaidOffForRefinance) {
      subTotal += Number(mortgage.calculatedStats.totalPaidOffForRefinance);
    }

    return subTotal;
  }

  calculateTotalLoanOrDrawAmount = (mortgage: UrlaMortgage) => {
    let total = 0;
    mortgage.relatedMortgages?.forEach(relatedMortgage => {
      if (relatedMortgage.loanOrDrawAmount) {
        total += Number(relatedMortgage.loanOrDrawAmount);
      }
    });
    return total;
  }

  calculateLtv = (
    mortgage: UrlaMortgage,
    newValues?: {
      newLoanAmount: number,
      newPresentValue: number,
      newPurchasePriceAmount: number,
      newProductType: string,
      newMiAndFundingFee: number
    }
  ): number => {
    let newLoanAmount: number;
    let newPresentValue: number;
    let newPurchasePriceAmount: number;
    let newProductType: string;
    let newMiAndFundingFee: number;

    if (newValues) {
      newLoanAmount = newValues.newLoanAmount;
      newPresentValue = newValues.newPresentValue;
      newPurchasePriceAmount = newValues.newPurchasePriceAmount;
      newProductType = newValues.newProductType;
      newMiAndFundingFee = newValues.newMiAndFundingFee;
    }

    const miOrFundingFeeFinancedAmount = newMiAndFundingFee ?? mortgage.mortgageInsuranceDetail?.miOrFundingFeeFinancedAmount ?? 0;
    const totalLoanAmount = newLoanAmount ?? (mortgage.mortgageTerm.totalLoanAmount || (mortgage.mortgageTerm.amount ?? 0 + miOrFundingFeeFinancedAmount));
    let presentValue = newPresentValue ?? (mortgage.subjectProperty.presentValue ?? 0);
    const purchasePriceAmount = newPurchasePriceAmount ?? mortgage.transactionDetail.purchasePriceAmount;

    if (!purchasePriceAmount) { // for Refinance
      if (presentValue == 0) {
        return 0
      } else {
        return totalLoanAmount / presentValue;
      }
    } else { // for Purchase
      if (
        purchasePriceAmount &&
        purchasePriceAmount > 0 &&
        !(presentValue && presentValue != 0 && purchasePriceAmount > presentValue)
      ) {
        presentValue = purchasePriceAmount;
      }

      if (presentValue == 0) {
        return 0;
      } else {
        return totalLoanAmount / presentValue;
      }
    }
  }

  calculateCltv = (
    mortgage: UrlaMortgage,
    newValues?: {
      newLoanAmount: number,
      newPresentValue: number,
      newPurchasePriceAmount: number,
      newProductType: string,
      newMiAndFundingFee: number
    }
  ): number => {
    let newLoanAmount: number;
    let newPresentValue: number;
    let newPurchasePriceAmount: number;
    let newProductType: string;
    let newMiAndFundingFee: number;

    if (newValues) {
      newLoanAmount = newValues.newLoanAmount;
      newPresentValue = newValues.newPresentValue;
      newPurchasePriceAmount = newValues.newPurchasePriceAmount;
      newProductType = newValues.newProductType;
      newMiAndFundingFee = newValues.newMiAndFundingFee;
    }

    const sub = mortgage.transactionDetail.subordinateLienAmount ?? 0;

    const miOrFundingFeeFinancedAmount = newMiAndFundingFee ?? mortgage.mortgageInsuranceDetail?.miOrFundingFeeFinancedAmount ?? 0;
    const totalLoanAmount = newLoanAmount ?? (mortgage.mortgageTerm.totalLoanAmount || (mortgage.mortgageTerm.amount ?? 0 + miOrFundingFeeFinancedAmount));
    let presentValue = newPresentValue ?? (mortgage.subjectProperty.presentValue ?? 0);
    const purchasePriceAmount = newPurchasePriceAmount ?? mortgage.transactionDetail.purchasePriceAmount;

    if (!purchasePriceAmount) { // for Refinance
      if (presentValue == 0) {
        return 0
      } else {
        return (totalLoanAmount + sub) / presentValue;
      }
    } else { // for Purchase
      if (
        purchasePriceAmount &&
        purchasePriceAmount > 0 &&
        !(presentValue && presentValue != 0 && purchasePriceAmount > presentValue)
      ) {
        presentValue = purchasePriceAmount;
      }

      if (presentValue == 0) {
        return 0
      } else {
        return (totalLoanAmount + sub) / presentValue;
      }
    }
  }

  calculateLtvAndCltv = (
    mortgage: UrlaMortgage,
    newValues?: {
      newLoanAmount: number,
      newPresentValue: number,
      newPurchasePriceAmount: number,
      newProductType: string,
      newMiAndFundingFee: number
    }
  ): { ltv: number, cltv: number } => {
    const ltv = this.calculateLtv(mortgage, newValues);
    const cltv = this.calculateCltv(mortgage, newValues);

    return { ltv, cltv };
  }

  calculateTotalMortgage = (mortgage: UrlaMortgage) => {
    let totalMortgage = 0;
    if (mortgage.mortgageTerm?.amount) {
      totalMortgage += Number(mortgage.mortgageTerm.amount);
    }
    if (mortgage.mortgageInsuranceDetail?.miOrFundingFeeFinancedAmount) {
      totalMortgage += Number(mortgage.mortgageInsuranceDetail.miOrFundingFeeFinancedAmount);
    }
    if (mortgage.calculatedStats?.totalLoanOrDrawAmount) {
      totalMortgage += Number(mortgage.calculatedStats.totalLoanOrDrawAmount);
    }
    // const otherLiensOnSubjectProperty = this.calculateOtherLiensOnSubjectProperty(mortgage);
    // totalMortgage += otherLiensOnSubjectProperty;

    return totalMortgage;
  }

  calculateTotalLenderCredit = (mortgage: UrlaMortgage) => {
    let subTotal = 0;
    let purchaseCredits = mortgage.transactionDetail?.purchaseCredits.filter(p => p.purchaseCreditType === this._enumsService.getEnumValue(Constants.enumerationValueNames.PurchaseCreditType.LenderCredit)
    );
    purchaseCredits?.forEach(purchaseCredit => {
      if (purchaseCredit?.purchaseCreditAmount) {
        subTotal += Number(purchaseCredit.purchaseCreditAmount);
      }
    });
    return subTotal;
  }

  calculateTotalOtherCredit = (mortgage: UrlaMortgage) => {
    let subTotal = 0;
    let purchaseCredits = mortgage.transactionDetail?.purchaseCredits.filter(p =>
      p.purchaseCreditType !== this._enumsService.getEnumValue(Constants.enumerationValueNames.PurchaseCreditType.LenderCredit)
    );
    purchaseCredits?.forEach(purchaseCredit => {
      if (purchaseCredit?.purchaseCreditAmount) {
        subTotal += Number(purchaseCredit.purchaseCreditAmount);
      }
    });
    return subTotal;
  }

  calculateTotalCredit = (mortgage: UrlaMortgage) => {
    let sellerPaidClosingCostsAmount = 0;
    if (mortgage.transactionDetail?.sellerPaidClosingCostsAmount) {
      sellerPaidClosingCostsAmount = Number(mortgage.transactionDetail.sellerPaidClosingCostsAmount);
    }
    return this.calculateTotalOtherCredit(mortgage) + this.calculateTotalLenderCredit(mortgage) + sellerPaidClosingCostsAmount;
  }

  calculateTotalDueFromBorrowers = (mortgage: UrlaMortgage) => {
    let totalDueFromBorrowers = (-1) * Number(mortgage.calculatedStats.totalMortgageLoans) +
      Number(mortgage.calculatedStats.totalCredit);
    return totalDueFromBorrowers;
  }

  calculateCashFromOrToTheBorrower = (mortgage: UrlaMortgage) => {
    let cashFromOrToTheBorrower = mortgage.calculatedStats.totalDue - mortgage.calculatedStats.totalMortgageLoansAndCredits;
    return cashFromOrToTheBorrower;
  }

  calculateTotalMortgageLoansAndCredits = (mortgage: UrlaMortgage) => {
    const totalMortgageLoansAndCredits = (mortgage.calculatedStats.totalMortgageLoans ?? 0) + (mortgage.calculatedStats.totalCredit ?? 0);
    return totalMortgageLoansAndCredits;
  }

  getFirstMortgagePrincipalAndInterest = (mortgage: UrlaMortgage) => {
    if (!mortgage.mortgageTerm?.interestRate) {
      return null;
    }
    let rate = Number(mortgage.mortgageTerm.interestRate);
    let term = Number(mortgage.mortgageTerm.noOfMonths);
    let balance = Number(mortgage.mortgageTerm.amount);
    if (mortgage.mortgageInsuranceDetail?.miOrFundingFeeFinancedAmount) {
      balance += Number(mortgage.mortgageInsuranceDetail.miOrFundingFeeFinancedAmount);
    }
    let princpicalAndInterest = 0;
    if (!mortgage.extension?.isInterestOnly) {
      princpicalAndInterest = (((rate / 1200) * Math.pow((1 + (rate / 1200)), term)) / (Math.pow((1 + (rate / 1200)), term) - 1)) * balance;
    } else {
      princpicalAndInterest = balance * (rate / 100) / 12;
    }
    return princpicalAndInterest;
  }

  calculateProposedHousingExpense = (proposedHousingExpense: HousingExpense) => {
    if (!proposedHousingExpense)
      return 0;
    let subProposedTotalValue = 0;
    subProposedTotalValue += Number(proposedHousingExpense.firstMortgagePrincipalAndInterest) || 0;
    subProposedTotalValue += Number(proposedHousingExpense.otherMortgageLoanPrincipalAndInterest) || 0;
    subProposedTotalValue += Number(proposedHousingExpense.homeownersInsurance) || 0;
    subProposedTotalValue += Number(proposedHousingExpense.supplementalPropertyInsurance) || 0;
    subProposedTotalValue += Number(proposedHousingExpense.realEstateTax) || 0;
    subProposedTotalValue += Number(proposedHousingExpense.mortgageInsurance) || 0;
    subProposedTotalValue += Number(proposedHousingExpense.homeownersAssociationDuesAndCondominiumFees) || 0;
    subProposedTotalValue += Number(proposedHousingExpense.otherHousingExpense) || 0;
    return subProposedTotalValue;
  }

  calculateSourceOfFundsValue = (mortgage: UrlaMortgage) => {
    let subSourceOfFundsValue = 0;
    mortgage.transactionDetail?.purchaseCredits.forEach(purchaseCredit => {
      subSourceOfFundsValue += Number(purchaseCredit.purchaseCreditAmount);
    });
    return subSourceOfFundsValue;
  }

  calculateBorrowerTotalMonthlyIncome = (borrower: MortgageBorrower) => {
    const currentEmployments = borrower?.employments.filter(e => e.employmentType === EmploymentTypeEnum.CurrentEmployer);
    let totalEmploymentIncome: number = 0;
    currentEmployments?.forEach(e => {
      let employmentIncome: number = 0;
      const notOmittedIncomes = e.incomes.filter(i => !i.isOmitted);
      if (notOmittedIncomes.length > 0) {
        employmentIncome = notOmittedIncomes.map(i => i.monthlyIncome).reduce(this.sum);
      }
      totalEmploymentIncome += employmentIncome;
    });
    let totalOtherIncome: number = 0;
    if (borrower.nonEmploymentIncomes.length != 0) {
      const notOmittedIncomes = borrower.nonEmploymentIncomes.filter(i => !i.isOmitted);
      if (notOmittedIncomes.length > 0) {
        totalOtherIncome = notOmittedIncomes.map(i => i.monthlyIncome).reduce(this.sum);
      }
    }
    let totalMonthlyIncome = totalOtherIncome + totalEmploymentIncome;
    return totalMonthlyIncome;
  }

  calculateBorrowerMonthlyIncome = (borrower: MortgageBorrower) => {
    const currentEmployments = borrower?.employments.filter(e => e.employmentType === EmploymentTypeEnum.CurrentEmployer);
    let totalEmploymentIncome: number = 0;
    currentEmployments?.forEach(e => {
      let employmentIncome: number = 0;
      const notOmittedIncomes = e.incomes.filter(i => !i.isOmitted);
      if (notOmittedIncomes.length > 0) {
        employmentIncome = notOmittedIncomes.map(i => i.monthlyIncome).reduce(this.sum);
      }
      totalEmploymentIncome += employmentIncome;
    });

    return totalEmploymentIncome;
  }

  calculateBorrowerMonthlyDebts = (mortgage: UrlaMortgage, filterTypeOfLiability?: string) => {
    let liabilities = mortgage?.liabilities.filter(e => e.isExcluded !== true && e.payoffType !== PayoffType.Full && e.monthlyPayment > 0);

    if (filterTypeOfLiability) {
      liabilities = liabilities?.filter(x => x.typeOfLiability == filterTypeOfLiability);
    }

    let totalExpense: number = 0;
    liabilities?.forEach(e => {
      totalExpense += (e.monthlyPayment ?? 0);
    });

    if (mortgage.subjectProperty.propertyWillBe != 'PrimaryResidence' && (!filterTypeOfLiability || filterTypeOfLiability == 'RentalExpense')) {
      mortgage.borrowers.forEach(b => totalExpense += (b.residencyAddresses.find(x => x.residencyType == ResidencyType.PresentAddress)?.rent ?? 0))
    }

    if (!filterTypeOfLiability) {
      totalExpense += this.calculateOtherREOLiabilities(mortgage);
    }

    return totalExpense;
  }

  calculateOtherREOLiabilities = (mortgage: UrlaMortgage) => {

    let totalExpense: number = 0;
    mortgage.realEstateOwned?.forEach(e => {
      if (
        !e.isSubjectProperty &&
        (
          e.includeTaxesInsuranceInDebtRatios ?? true
        ) &&
        (
          e.dispositionStatus == "Retained" ||
          e.dispositionStatus == "RetainForPrimaryOrSecondaryResidence" ||
          e.dispositionStatus == "RentalProperty"
        )
      ) {
        totalExpense += (e.monthlyMiscExpenses ?? 0);
      }
    });

    return totalExpense;
  }

  sendReoEvent() {
    this.reoLiabilityAssociationChanged.next();
  }

  sendLiabilityEvent() {
    this.liabilitySubject.next(null);
  }

  publishOtherMortgagesChangedEvent() {
    this.otherMortgagesChanged.next(null);
  }

  getFtcSectionAmounts = (mortgage: UrlaMortgage) => {

    this.calculateMortgageStatistics(mortgage);

    let ftcSectionAmounts = {
      A: mortgage.transactionDetail?.purchasePriceAmount ?? 0,
      B: mortgage.transactionDetail?.alterationsImprovementsAndRepairsAmount ?? 0,
      C: this.calculateLandValueChange(mortgage),
      D: this.calculateTotalPayOffForRefinance(mortgage),
      E: this.calculateFinancialPartialPayoffTotalAmount(mortgage),
      F: {
        estimated: mortgage.transactionDetail?.prepaidItemsEstimatedAmount ?? 0,
        escrow: mortgage.transactionDetail?.prepaidEscrowsTotalAmount ?? 0,
        estimatedClosingCosts: mortgage.transactionDetail?.estimatedClosingCostsExcludingPrepaidsAmount ?? 0,
        pmi: mortgage.mortgageInsuranceDetail ? mortgage.mortgageInsuranceDetail.miOrFundingFeeTotalAmount : 0,
        total: mortgage.transactionDetail?.estimatedClosingCostsAmount ?? 0,
      },
      G: mortgage.transactionDetail?.borrowerPaidDiscountPointsTotalAmount,
      H: 0,
      I: {
        nonFinancedAmount: mortgage.mortgageTerm?.amount,
        financedAmount: mortgage.mortgageInsuranceDetail?.miOrFundingFeeFinancedAmount ?? 0,
        total: 0
      },
      J: this.calculateTotalLoanOrDrawAmount(mortgage),
      K: 0,
      L: mortgage.transactionDetail?.sellerPaidClosingCostsAmount,
      M: {
        lender: this.calculateTotalLenderCredit(mortgage),
        other: this.calculateTotalOtherCredit(mortgage)
      },
      N: this.calculateTotalCredit(mortgage),
      allTotal: mortgage.calculatedStats.cashFromOrToTheBorrower ?? 0,
    };

    ftcSectionAmounts["I"]["total"] = (ftcSectionAmounts["I"]["nonFinancedAmount"] || 0) + (ftcSectionAmounts["I"]["financedAmount"] || 0);

    // total of A through G
    ftcSectionAmounts["H"] = (ftcSectionAmounts["A"] || 0) + (ftcSectionAmounts["B"] || 0) + (ftcSectionAmounts["C"] || 0) + (ftcSectionAmounts["D"] || 0)
      + (ftcSectionAmounts["E"] || 0) + (ftcSectionAmounts["F"]["total"] || 0) + (ftcSectionAmounts["G"] || 0);

    // total of I and J
    ftcSectionAmounts["K"] = (ftcSectionAmounts["I"]["total"] || 0) + (ftcSectionAmounts["J"] || 0);

    return ftcSectionAmounts;
  }

  getPiTiPaymentInfo = (mortgage: UrlaMortgage): { pi: number, ti: number, other: number } => {
    let paymentInfo: any = {
      pi: 0,
      ti: 0,
      other: 0
    };

    const proposedExpenses = mortgage.proposedHousingExpense ||
      (
        mortgage.borrowers && mortgage.borrowers[0] && mortgage.borrowers[0].currentHousingExpenses &&
          mortgage.borrowers[0].currentHousingExpenses.isCurrent === false ? mortgage.borrowers[0].currentHousingExpenses
          : undefined
      );

    if (proposedExpenses) {
      paymentInfo.pi = proposedExpenses.firstMortgagePrincipalAndInterest;
      paymentInfo.ti = proposedExpenses.homeownersInsurance + proposedExpenses.realEstateTax;
      paymentInfo.other =
        (proposedExpenses.otherMortgageLoanPrincipalAndInterest ?? 0) +
        (proposedExpenses.mortgageInsurance ?? 0) +
        (proposedExpenses.homeownersAssociationDuesAndCondominiumFees ?? 0) +
        (proposedExpenses.otherHousingExpense ?? 0) +
        (proposedExpenses.supplementalPropertyInsurance ?? 0) +
        (proposedExpenses.rent ?? 0);
    }

    return paymentInfo;
  }

  setMonthlyPaymentOptions = (liability: Liability) => {
    return [0.5, 1, 5].map(el => ({
      displayText: `${el}% of Unpaid Balance ($${parseFloat(((liability.unpaidBalance / 100) * el).toFixed(2))})`,
      value: el
    }));
  };

  setMonthlyPayment = (liability: Liability, percent: number) => {
    liability.monthlyPayment = parseFloat(((liability.unpaidBalance / 100) * percent).toFixed(2));
  }

  private sum = (a: number, b: number): number => {
    if (!a) a = 0;
    if (!b) b = 0;
    return a + b;
  }
}

export class MortgageLienAndMonthlyPaymentAmount {
  lienAmount: number;
  monthlyPayment: number;
}
