import { LoanPurpose } from "projects/borrower-app/src/app/models/enums/loan-purpose.enum";
import { Occupancy } from "projects/borrower-app/src/app/models/enums/occupancy.enum";
import { PropertyType } from "projects/borrower-app/src/app/models/enums/property-type.enum";

export class SubjectProperty {
  purposeOfLoan: LoanPurpose | undefined;
  propertyWillBe: Occupancy | undefined;
  propertyType: PropertyType | undefined;
  projectType: string | undefined;
  attachmentType: string | undefined;
  downPaymentSource: string | undefined | null = null;
  presentValue?: number | undefined | null;
  yearBuilt: number | undefined;
  noOfUnits: number | undefined;

  address1: string = "";
  address2: string = "";
  city: string = "";
  state: string = "";
  zipCode: string = "";
  county: string = "";

  constructor() {
    this.downPaymentSource = null;
  }
}

