import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MentionsUtils } from 'projects/shared/mentions.utils';
import { Mentionable } from 'projects/shared/message-editor-with-mentions/mentionable.model';
import { EnvironmentService } from 'projects/shared/services/environment.service';
import { ApplicationContext } from '../../models/application-context.model';
import { BorrowerAndAgentMessageModel } from '../../models/borrower-portal/borrower-and-agent-message-model';
import { BorrowerLoanMessageModel } from '../../models/borrower-portal/borrower-loan-message-model';
import { Company } from '../../models/company-model';
import { InternalContact } from '../../models/internal-contact.model';
import { LoanApplication } from '../../models/loan-info.model';
import { Role } from '../../models/role.model';
import { UserWithRoleName } from '../../models/user-with-role-name.model';
import { User } from '../../models/user.model';
import { ApplicationContextService } from '../../services/application-context.service';
import { BasePortalService } from '../../services/base-portal.service';
import { ChannelService } from '../../services/channel.service';
import { InternalContactsService } from '../../services/internal-contacts.service';
import { LoanService } from '../../services/loan.service';
import { NotificationService } from '../../services/notification.service';
import { TimerService } from '../../services/timer.service';

@Component({
  selector: 'loan-messages',
  templateUrl: './loan-messages.component.html',
  styleUrls: ['./loan-messages.component.scss']
})
export class LoanMessagesComponent implements OnInit, AfterViewChecked, OnDestroy {

  @ViewChild('messageEditor') messageEditorComponent: any;

  @Input() loanId: number;
  @Input() sendWhenHitEnter: boolean = false;

  userId: string;

  messages: BorrowerLoanMessageModel[] = [];

  messageCount: number = 0;
  messageText: string;
  newMessage: BorrowerAndAgentMessageModel = new BorrowerAndAgentMessageModel();

  isSaving: boolean = false;

  usersThatCanBeMentioned: Mentionable[] = [];
  allUsers: User[] = [];

  users: Array<UserWithRoleName>;

  contactRoles: Array<Role>;

  application: LoanApplication;

  private _internalContacts: InternalContact[] = [];

  constructor(
    private readonly _portalService: BasePortalService,
    private readonly _channelService: ChannelService,
    private readonly _loanService: LoanService,
    private readonly _internalContactsService: InternalContactsService,
    private readonly _timerService: TimerService,
    private readonly _notifyService: NotificationService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _environmentService: EnvironmentService,
    private readonly _applicationContextService: ApplicationContextService,
    private readonly _cdref: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this._applicationContextService.context.subscribe(context => {

      this._spinner.show();
      this._loanService.getApplication(this.loanId).subscribe({
        next: (appModel) => {
          this.application = appModel;
          this.initialize(context);
          this._spinner.hide();
        },
        error: (error) => {
          this._spinner.hide();
          this._notifyService.showError(error?.message || "Couldn't load application.", "Error!");
        }
      })
    })
  }

  ngAfterViewChecked() {
    this._cdref.detectChanges();
  }

  ngOnDestroy() {
    this._timerService.remove("messages-timer");
  }

  setRefreshingTimer = () => {
    const timer = this._timerService.start("messages-timer", 20, true, true);
    timer.ticked.subscribe((elapsedTimeMin) => {
      let sec = parseInt((elapsedTimeMin % 60).toString());
      if (sec % 10 == 0) { // for every 10 seconds
        this.loadMessages();
      }
    });
  }

  postMessage = () => {
    if (this.messageText.trim() == "") {
      return;
    }

    this.newMessage.messageText = this.messageText;

    this.isSaving = true;
    this._portalService.postMessage(this.newMessage)
      .subscribe(() => {
        this.messageText = "";
        this.messageEditorComponent.reset();
        this.loadMessages();
      })
      .add(() => {
        this.isSaving = false;
      })
  }

  onMessageChanged = (message: string) => {
    this.messageText = message;
  }

  onEnterHit = () => {
    if (this.sendWhenHitEnter) {
      this.postMessage();
    }
  }

  private initialize = (context: ApplicationContext) => {
    this.userId = context.userPermissions.userId;

    this.newMessage.applicationId = this.loanId;

    if (this.application.channel === 'Wholesale' || this.application.channel === 'Correspondent') {
      this.allUsers = context.globalConfig.usersAll.concat(context.globalConfig.tpoUsers.filter(u => u.branchId === this.application.branchId));
    } else {
      this.allUsers = context.globalConfig.usersAll;
    }

    this.users = context.globalConfig.users;
    this.contactRoles = context.globalConfig.roles;
    this.loadMessages();
    this.loadInternalContacts(context);
    this.setRefreshingTimer();
    this.messageText = "";
  }

  private initializeMentionables = (context: ApplicationContext) => {
    const usersThatCanBeMentioned = MentionsUtils.prepareUserMentionables(this._environmentService.apiInfo.apiBaseUrl, this.allUsers);
    const rolesThatCanBeMentioned = this.prepareContactRoleMentionables(
      this.application.channel,
      context.globalConfig.company[0],
      this._internalContacts, this.allUsers,
      context);
    this.usersThatCanBeMentioned = usersThatCanBeMentioned.concat(rolesThatCanBeMentioned);
    this.usersThatCanBeMentioned.push(MentionsUtils.prepareInternalContactsMentionable());
    this.usersThatCanBeMentioned.push(MentionsUtils.prepareHereMentionable());
  }

  private prepareContactRoleMentionables = (channelName: string, company: Company, internalContacts: InternalContact[], allUsers: User[], context: ApplicationContext): Mentionable[] => {
    let mentionables: Mentionable[] = [];

    const enabledChannels = this._channelService.getChannelsFromCommaDelimitedString(company.enabledChannels);
    const enabledChannel = enabledChannels.find(c => c.value === channelName);

    if (enabledChannel) {
      mentionables = MentionsUtils.prepareContactRoleMentionables(
        enabledChannel.value,
        context.globalConfig.channelRoles,
        internalContacts,
        allUsers);
    }
    return mentionables;
  }

  private loadMessages = () => {
    this._portalService.getMessagesForApplication(this.loanId).subscribe({
      next: (messages) => {
        this.messages = messages.sort((m1, m2) => m1.messageId - m2.messageId).map(message => {
          message["mentionableContent"] = MentionsUtils.generateDisplayHtmlWithMentions(message.content);
          return message
        });
        this.messageCount = messages.length;
      },
      error: (err) => {
        this._notifyService.showError(err?.message || "Couldn't load application messages.", "Error!");
      }
    });
  }

  loadInternalContacts = (context: ApplicationContext) => {
    this._spinner.show();
    this._internalContactsService.getInternalContacts(this.application.applicationId).subscribe({
      next: (internalContacts) => {
        if (internalContacts && internalContacts.length > 0) {
          this._internalContacts = internalContacts;
          this.initializeMentionables(context);
          this._spinner.hide();
        }
      },
      error: (err) => {
        this._spinner.hide();
        this._notifyService.showError(err?.message || "Couldn't load internal contacts.", "Error!");
      }
    });
    this.users.forEach(user => {
      let role = this.contactRoles.find(role => role.roleId == user.roleId);
      if (role && role.roleName) {
        user.roleName = role.roleName;
      }
    })
  }

}
