<form #addressForm="ngForm" novalidate id="addressForm" name="addressForm">

  <div class="d-flex mb-7 row">
    <div class="col-md-4">
      <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
        <span class="required">Address</span>
      </label>
      <address-autocomplete (setGooglePickedAddress)="setGooglePickedAddress($event)"
        (setManuallyEnteredAddress)="setManuallyEnteredAddress($event)"
        adressType="geocode"
        [autocompleteInput]="propertyInfo!.streetAddress1">
      </address-autocomplete>
    <!-- <div class="invalid-feedback">Address is required, please enter an address.</div> -->
    </div>

    <div class="col-md-3">
      <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
        <span class="required">City</span>
      </label>
      <div class="position-relative">
        <input type="text" class="form-control form-control-solid" [(ngModel)]="propertyInfo!.city" required placeholder="" name="city"
          [ngClass]="{'is-invalid' : city && city.touched && city.invalid}" #city="ngModel" #cityOfAddress />
        <!-- <div class="invalid-feedback">City is required, please enter a city.</div> -->
      </div>
    </div>

    <div class="col-md-1 fv-row">
      <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
        <span class="required">State</span>
      </label>

      <select name="state" required [ngClass]="{'is-invalid' : state && state.touched && state.invalid}"
        [(ngModel)]="propertyInfo!.state" class="form-select form-select-solid" #state="ngModel"
        data-placeholder="Select a State">
        <option></option>
        <option *ngFor="let state of states; let i = index" [ngValue]="state.value">
          {{state.name}}
        </option>
      </select>
      <!-- <div class="invalid-feedback">State is required, please select a state.</div> -->

    </div>

    <div class="col-md-2 fv-row">
      <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
        <span class="required">Zip Code</span>
      </label>
      <div class="position-relative">
        <input type="text" [(ngModel)]="propertyInfo!.zip" required [ngClass]="{'is-invalid' : zipCode && zipCode.touched && zipCode.invalid}"
          class="form-control form-control-solid" placeholder="" name="zipCode" #zipCode="ngModel" #zipCodeOfAddress />
        <!-- <div class="invalid-feedback">Zip code is required, please enter a zip code.</div> -->
      </div>
    </div>

    <div class="col-md-2 fv-row" *ngIf="showMonthsAtAddressField">
      <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
        <span class="required">Months at Address</span>
      </label>
      <div class="position-relative">
        <input type="number" min="0"
          [(ngModel)]="propertyInfo!.monthsAtAddress" required [ngClass]="{'is-invalid' : monthsAtAddress && monthsAtAddress.touched && monthsAtAddress.invalid}"
          class="form-control form-control-solid" placeholder="" name="monthsAtAddress" #monthsAtAddress="ngModel" />
        <!-- <div class="invalid-feedback">Zip code is required, please enter a zip code.</div> -->
      </div>
    </div>

  </div>
</form>
