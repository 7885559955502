import { Option } from "../../option.model";
import { IProvideOptions } from "./options-provider.interface";
import { Injectable } from "@angular/core";
import { Observable,  of } from "rxjs";

@Injectable()
export class DefaultPropertyTypeOptionsProvider implements IProvideOptions {

  constructor() {
  }

  getOptions(): Observable<Option[]> {
    const singleFamilyOption = new Option('singleFamily', 'Single Family', '', 'SFR', 'stockholm-home-heart');
    const townhouseOption = new Option('townhouse', 'Townhouse', '', 'Townhouse', 'duotone-building');
    const condoOption = new Option('condo', 'Condo', '', 'Condominium');
    condoOption.iconClass = 'fa fa-2x fa-building';
    condoOption.iconType = 'font-awesome';
    const multiUnitOption = new Option('multiUnit', 'Multi Unit', '', 'MultiFamilyResidence', 'duotone-building2');
    const manufacturedHomeOption = new Option('manufacturedHousing', 'Manufactured Home', '', 'ManufacturedHousing');
    manufacturedHomeOption.iconType = 'font-awesome';
    manufacturedHomeOption.iconClass = 'las la-tools fs-3x';
    const cooperativeOption = new Option('cooperative', 'Co-op', '', 'Cooperative');
    cooperativeOption.iconType = 'font-awesome';
    cooperativeOption.iconClass = 'las la-building fs-3x';
    return of([singleFamilyOption, townhouseOption, condoOption, multiUnitOption, manufacturedHomeOption, cooperativeOption]);
  }
}
