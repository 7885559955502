import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { EnumerationItem } from "projects/shared/models/enumeration-item.model";
import { Borrower } from "../../models/borrower-model";
import { EnumsService } from "../../services/enums.service";

@Component({
  selector: 'borrower-military-service',
  templateUrl: 'borrower-military-service.component.html',
  styleUrls: ['./borrower-military-service.component.scss']
})
export class BorrowerMilitaryServiceComponent implements OnInit {

  @ViewChild('militaryServiceForm') militaryServiceForm: NgForm | undefined;

  @Input()
  borrower!: Borrower;

  militaryStatus: string = "";

  militaryStatusTypes: EnumerationItem[] = [];

  militaryBranchOfServiceTypes: EnumerationItem[] = [];

  constructor(private readonly _enumsService: EnumsService) {
    this.militaryStatusTypes = this._enumsService.militaryStatusTypes;
    this.militaryBranchOfServiceTypes = this._enumsService.militaryBranchOfServiceTypes;
  }

  ngOnInit(): void {
    if (!this.borrower.branchOfService) {
      this.borrower.branchOfService = null;
    }
    if (this.borrower.isActiveDutyMilitary) {
      this.militaryStatus = 'isActiveDutyMilitary';
    }
    if (this.borrower.onlyNonActivatedReserveOrNationalGuard) {
      this.militaryStatus = 'onlyNonActivatedReserveOrNationalGuard';
    }
    if (this.borrower.isRetiredDischargedSeparatedFromMilitary) {
      this.militaryStatus = 'isRetiredDischargedSeparatedFromMilitary';
    }
  }

  onHasServedInMilitaryChanged = () => {
    if (!this.borrower.hasServedInMilitary) {
      this.militaryStatus = "";
      this.borrower.isActiveDutyMilitary = false;
      this.borrower.onlyNonActivatedReserveOrNationalGuard = false;
      this.borrower.isRetiredDischargedSeparatedFromMilitary = false;
      this.borrower.militaryTourEndDate = "";
      this.borrower.isFirstUseOfVaBenefit = false;
      this.borrower.isVaFundingFeeExempt = false;
      this.borrower.isSurvivingSpouseOfMilitary = false;
    }
  }

  onMilitaryStatusChanged = () => {
    this.borrower.isActiveDutyMilitary = false;
    this.borrower.onlyNonActivatedReserveOrNationalGuard = false;
    this.borrower.isRetiredDischargedSeparatedFromMilitary = false;

    if (this.militaryStatus === 'isActiveDutyMilitary') {
      this.borrower.isActiveDutyMilitary = true;
    } else {
      this.borrower.militaryTourEndDate = null;
    }
    if (this.militaryStatus === 'onlyNonActivatedReserveOrNationalGuard') {
      this.borrower.onlyNonActivatedReserveOrNationalGuard = true;
    }
    if (this.militaryStatus === 'isRetiredDischargedSeparatedFromMilitary') {
      this.borrower.isRetiredDischargedSeparatedFromMilitary = true;
    }
  }

  validate = () => {
    this.militaryServiceForm?.form.markAllAsTouched();
    if (this.militaryServiceForm?.form.valid) {
      return true;
    }
    return false;
  }
}
