

<div class="modal-header">

  <h2>Answer Question</h2>

  <!-- hack to fool ngbModal autofocus -->
  <input type="text" style="display:none" />

  <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" aria-label="Close"
    (click)="activeModal.dismiss()">
    <span class="svg-icon svg-icon-2x">
      <close-x-svg-icon></close-x-svg-icon>
    </span>
  </div>
</div>

<div class="modal-body py-10 px-lg-17">
  <h4 class="mb-4">{{task.description}}</h4>
  <form #answerForm="ngForm" class="form" novalidate id="answerForm" name="answerForm">
    <div class="form-group mb-5 col-md-12">
      <label class="form-label" for="answer">Please provide your answer below</label>
      <textarea required
        #borrowerAnswer="ngModel"
        class="form-control" rows="5"
        [ngClass]="{'is-invalid' : borrowerAnswer && borrowerAnswer.touched && borrowerAnswer.invalid}"
        [(ngModel)]="answer" name="answer">
      </textarea>
      <div class="invalid-feedback">Please enter your answer above.</div>
    </div>
  </form>
</div>

<div class="modal-footer flex-center">
  <button type="button" class="btn btn-white me-3" (click)="activeModal.dismiss()">Cancel</button>
  <button type="button" class="btn btn-primary" (click)="onOkClicked()">Done</button>
</div>




