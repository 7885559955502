import { IDecideNextStep } from "../../services/wizard/next-step-decider/next-step-decider.interface";
import { CreateAccountStepComponent } from "../../wizard/wizard-steps/create-account-step/create-account-step.component";
import { Borrower } from "../borrower-model";
import { WizardStepBase } from "./wizard-step-base.model";

export class CreateAccountStep extends WizardStepBase {

  borrower!: Borrower;

  configuresAddressHistory: boolean = true;

  userCreationAutoConfirmed: boolean = false;

  override get hasNextStep(): boolean {
    return true;
  }

  constructor(id: string, name: string | undefined, path: string, borrower: Borrower, nextStepDecider?: IDecideNextStep) {
    super(id, name, path, nextStepDecider);
    this.component = CreateAccountStepComponent;
    this.borrower = borrower;
  }
}
