import { Injectable } from "@angular/core";
import { ExpressionNextStepDecider } from "projects/borrower-app/src/app/services/wizard/next-step-decider/expression-next-step.decider";
import { WizardFlowContext } from "../../../wizard-flow-context.model";
import { ExpressionNextStepDeciderConfig } from "../expression-next-step-decider-config.model";

@Injectable()
export class ExpressionNextStepDeciderFactory {

    constructor() {
    }

    create = (config: ExpressionNextStepDeciderConfig, wizardFlowContext: WizardFlowContext): ExpressionNextStepDecider | undefined => {
        const decider = new ExpressionNextStepDecider(wizardFlowContext, config.ifStepExpression, config.elseIfStepExpressions,
          config.elseStepPath);
        return decider;
    }
}
