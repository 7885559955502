import { Component, Injector, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ModalService } from 'projects/shared/modal/modal.service';
import { forkJoin, Observable } from 'rxjs';
import { Borrower } from '../../../models/borrower-model';
import { ErrorMessage } from '../../../models/error-message.model';
import { MyInfoStep } from '../../../models/wizard/my-info-step.model';
import { BorrowerAddressHistoryComponent } from '../../borrower-address-history/borrower-address-history.component';
import { BorrowerInfoMiniComponent } from '../../borrower-info-mini/borrower-info-mini.component';
import { DependentsDialogComponent } from '../../dependents/dependents-dialog.component';
import { WizardStepComponentBase } from '../wizard-step-base.component';
import { EnumsService } from '../../../services/enums.service';
import { EnumerationItem } from 'projects/shared/models/enumeration-item.model';
import { Declarations } from '../../../models/declarations-model';

@Component({
  selector: 'my-info',
  templateUrl: 'my-info-step.component.html',
  styleUrls: ['my-info-step.component.scss']
})
export class MyInfoStepComponent extends WizardStepComponentBase<MyInfoStep> implements OnInit {

  @ViewChildren('borrowerInfo') borrowerInfoComponents: QueryList<BorrowerInfoMiniComponent> | undefined;
  @ViewChild('myInfoForm') myInfoForm: NgForm | undefined;
  @ViewChild(BorrowerAddressHistoryComponent) borrowerAddressHistoryComponent: BorrowerAddressHistoryComponent | undefined;

  public coBorrowerToDelete: Borrower | undefined = undefined;

  borrower!: Borrower;

  dependentAges: string = null;

  noOfDependents: number = 0;

  coBorrowers: Borrower[] = [];

  ssnPattern = { '0': { pattern: new RegExp('\\d'), symbol: 'X' } };

  borrowerSettings: Map<number, boolean> = new Map<number, boolean>();

  languagePreferenceOptions: EnumerationItem[] = [];

  constructor(
    private readonly _modalService: ModalService,
    private readonly _enumService: EnumsService,
    private readonly _injector: Injector) {
    super(_injector);
    this.saveMortgageApplicationBeforeNextStep = true;
    this.languagePreferenceOptions = this._enumService.languages;
  }

  private showDependentsPopup(dependentAges: number[]) {
    const modal = this._modalService.show(DependentsDialogComponent);
    modal.componentInstance.dependentAges = dependentAges;

    modal.result.then(dependentAges => {
      this.borrower.dependents = dependentAges;
      this.noOfDependents = dependentAges.length;
      this.dependentAges = this.borrower.dependents.join(', ');
    }, error => {
      this.noOfDependents = this.borrower.dependents.length;
      this.dependentAges = this.borrower.dependents.join(', ');
    })
  }

  private validate = (): boolean => {
    let valid = false;
    if (this.myInfoForm) {
      this.myInfoForm.form.markAllAsTouched();
      valid = this.myInfoForm.form.valid;
    }
    if (this.borrowerInfoComponents) {
      for (let borrowerinfo of this.borrowerInfoComponents) {
        valid = borrowerinfo.validate() && valid;
      }
    }
    if (this.step.configuresAddressHistory && this.borrowerAddressHistoryComponent) {
      valid = this.borrowerAddressHistoryComponent?.validateAddressHistory() && valid;
    }
    return valid;
  }

  onNextClicked() {
    let valid = this.validate();
    let firstInvalidOneId: string = null;
    if (!valid) {
      for (var key in this.myInfoForm.form.controls) {
        if (this.myInfoForm.form.controls.hasOwnProperty(key)) {
          if (this.myInfoForm.form.controls[key].status === 'INVALID') {
            firstInvalidOneId = key;
            break;
          }
        }
      }
    }
    if (firstInvalidOneId) {
      this.scrollToElement(firstInvalidOneId);
    }

    if (valid) {
      this.saveMortgageApplicationBeforeNextStep = false;
      if (this.wizardFlowService.isEditEnabled) {
        super.onNextClicked();
        return;
      }
      this.startSpinner();
      this.mortgageApplication.borrowers.forEach(b => {
        b.homePhone = b.mobilePhone;
      })
      this.wizardFlowService.saveMortgage().subscribe(result => {
        let calls: Observable<any>[] = [];
        if (this.borrowerInfoComponents) {
          for (let borrowerinfo of this.borrowerInfoComponents) {
            let bi = borrowerinfo.borrower;
            const insertedBorrower = result.borrowers.find((x: any) => x.firstName === bi.firstName && x.lastName === bi.lastName && x.primaryEmail === bi.primaryEmail);
            if (!borrowerinfo.mainBorrowerWillApplyForMe) {
              if (insertedBorrower) {
                borrowerinfo.setBorrowerIdContactId(insertedBorrower.borrowerId, insertedBorrower.contactId);
              }
              let borrowerInviteToCompleteAppCall = this.mortgageApplicationService.inviteToCompleteAppForCoborrower(this.mortgageApplication.mortgageId,
                this.mortgageApplication.applicationId, borrowerinfo.borrower.borrowerId!);
              calls.push(borrowerInviteToCompleteAppCall);
            } else {
              // Merge borrower needs to be called here for the borrower
              if (insertedBorrower) {
                let mergeBorrowerCall = this.mortgageApplicationService.mergeCoborrower(this.mortgageApplication.applicationId,
                  insertedBorrower.contactId);
                calls.push(mergeBorrowerCall);
              }
            }
          }
        }
        if (calls.length > 0) {
          forkJoin(calls).subscribe(
            (result) => {
              this.stopSpinner();
              super.onNextClicked();
            },
            (error) => {
              this.handleError(error);
            }
          );
        } else {
          this.stopSpinner();
          super.onNextClicked();
        }
      }, error => {
        this.handleError(error);
      });
    }
  }

  onNoOfDependentsChanged = () => {
    if (!this.noOfDependents) {
      this.borrower.dependents = [];
      this.dependentAges = "";
      return;
    }
    const dependents = [...this.borrower.dependents];
    const numberOfDependentsDiff = this.noOfDependents - dependents.length;
    if (numberOfDependentsDiff > 0) {
      for (let i = 1; i <= numberOfDependentsDiff; i++) {
        dependents.push(0);
      }
    } else if (numberOfDependentsDiff < 0) {
      const noOfDependents = dependents.length;
      for (let i = noOfDependents - 1; i > noOfDependents - 1 + numberOfDependentsDiff; i--) {
        dependents.splice(i, 1);
      }
    }
    this.showDependentsPopup(dependents);
  }

  onEditDependentsClicked = () => {
    const dependents = [...this.borrower.dependents];
    this.showDependentsPopup(dependents);
  }

  onAddCoBorrowerClicked = () => {
    const coBorrower = new Borrower();
    this.mortgageApplication.borrowers.push(coBorrower);
  }

  onCoBorrowerDeleteClicked = (coBorrower: Borrower) => {
    this.coBorrowerToDelete = coBorrower;
  }

  onCoBorrowerDeleteConfirmClicked = (coBorrower: Borrower) => {
    if (coBorrower.borrowerId && coBorrower.borrowerId > 0) {
      this.mortgageApplicationService.removeBorrower(this.mortgageApplication.applicationId, coBorrower.borrowerId).subscribe(() => {
        this.removeBorrowerFromMortgage(coBorrower);
      });
    } else {
      this.removeBorrowerFromMortgage(coBorrower);
    }
  }

  onLanguagePreferenceChanged = () => {
    if (this.borrower.languagePreference !== 'Other') {
      this.borrower.languagePreferenceOtherDescription = null;
    }
  }

  private removeBorrowerFromMortgage = (borrower: Borrower): void => {
    const index = this.mortgageApplication.borrowers.indexOf(borrower);
    if (index >= 0) {
      this.mortgageApplication.borrowers.splice(index, 1);
      this.coBorrowerToDelete = undefined;
    }
  }

  private handleError = (error: any) => {
    this.stopSpinner();
    let errorMessage = new ErrorMessage("An error occurred while saving the mortgage", error.error.message);
    this.showError(errorMessage);
    window.scroll(0, 0);
  }

  onCoBorrowerDeleteCancelClicked = (coBorrower: Borrower) => {
    this.coBorrowerToDelete = undefined;
  }

  ngOnInit() {
    this.borrowerSettings = this.wizardFlowService.context.borrowerSettings;
    if (this.mortgageApplication.borrowers && this.mortgageApplication.borrowers.length > 0) {
      if (this.currentBorrower) {
        this.borrower = this.currentBorrower;
        this.noOfDependents = this.borrower.dependents.length;
        this.dependentAges = this.borrower.dependents.join(', ');
        this.coBorrowers = this.mortgageApplication.borrowers.filter(b => b !== this.currentBorrower);
      }
    } else {
      this.borrower = new Borrower();
      this.mortgageApplication.borrowers.push(this.borrower);
    }
    this.fixUnsetValuesToNull();
  }

  scrollToElement = (name: string) => {
    const elements = document.getElementsByName(name);
    let element;
    if (elements) {
      element = elements[0]
    }
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
      }, 250);
    }
  }

  private fixUnsetValuesToNull = () => {
    if (!this.borrower.nameSuffix) {
      this.borrower.nameSuffix = null;
    }
    if (!this.borrower.languagePreference) {
      this.borrower.languagePreference = null;
    }
    if (!this.borrower.maritalStatus) {
      this.borrower.maritalStatus = null;
    }
    if (!this.borrower.declarations) {
      this.borrower.declarations = new Declarations();
    } else {
      if (!this.borrower.declarations.residenceStatus) {
        this.borrower.declarations.residenceStatus = null;
      }
    }
  }
}
