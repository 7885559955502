import { Injectable } from "@angular/core";
import { WizardFlowContext } from "../../../wizard-flow-context.model";
import { VerifyCreditStep } from "../../verify-credit-step.model";
import { VerifyCreditStepConfig } from "../verify-credit-step-config.model";
import { GenericStepFactory } from "./generic-step.factory";
import { NextStepDeciderFactory } from "./next-step-decider.factory";
import { ICreateStep } from "./step-factory.interface";

@Injectable()
export class VerifyCreditStepFactory extends GenericStepFactory<VerifyCreditStep> implements ICreateStep {

  constructor(nextStepDeciderFactory: NextStepDeciderFactory) {
    super(nextStepDeciderFactory, VerifyCreditStep);
  }

  create(config: VerifyCreditStepConfig, wizardFlowContext: WizardFlowContext): VerifyCreditStep {
    let verifyCreditStep = super.create(config, wizardFlowContext)
    verifyCreditStep.hideRealTimeCreditRunOption = config.hideRealTimeCreditRunOption;
    return verifyCreditStep;
  }

  configure(step: VerifyCreditStep, config: VerifyCreditStepConfig): void {
    super.configure(step, config);
    config.hideRealTimeCreditRunOption = step.hideRealTimeCreditRunOption;
  }
}
