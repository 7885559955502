import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GeneratePrequalLetterRequestModel } from 'projects/borrower-app/src/app/models/borrower-portal/generate-prequal-letter-request.model';

@Component({
  selector: 'prequal-letter-wizard-review-information',
  templateUrl: './prequal-letter-wizard-review-information.component.html'
})
export class PrequalLetterWizardReviewInformationComponent implements OnInit {

  @Input() request: GeneratePrequalLetterRequestModel;
  @Input() showAdditionalOptionsStep: boolean;

  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() next: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() goStep: EventEmitter<number> = new EventEmitter<number>();

  haveEmptySections: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  nextClicked = () => {
    this.haveEmptySections = !this.request.address || !this.request.purchasePrice;

    if (!this.haveEmptySections) {
      this.next.emit();
    }
  }

  closeClicked = () => {
    this.close.emit();
  }

  goToStep = (stepNumber: number) => {
    this.goStep.emit(stepNumber);
  }

}
