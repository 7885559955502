import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ResidencyAddress } from '../../models/residency-address.model';
import { AddressHistory2Component } from '../wizard-steps/address-history2/address-history2.component';

@Component({
  selector: 'address-history-dialog',
  templateUrl: 'address-history-dialog.component.html'
})
export class AddressHistoryDialogComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) {
  }

  private _residencyAddress!: ResidencyAddress;

  @ViewChild('addressHistoryForm') addressHistoryForm: NgForm | undefined;
  @ViewChild(AddressHistory2Component) addressComponent: AddressHistory2Component | undefined;

  public get header(): string {
    return "Address History";
  }

  @Input()
  public set residencyAddress(residencyAddress: ResidencyAddress) {
    this._residencyAddress = residencyAddress;
  }

  public get residencyAddress(): ResidencyAddress {
    return this._residencyAddress;
  }

  onSaveClicked = () => {
    this.addressHistoryForm?.form.markAllAsTouched();
    if (this.addressComponent?.validate() && this.addressHistoryForm?.form.valid) {
      this.activeModal.close(this._residencyAddress);
    }
  }

  ngOnInit() { }
}
