import { Component, OnInit, Input, forwardRef, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'checkbox-flags',
    templateUrl: 'checkbox-flags.component.html',
    providers: [
      {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => CheckboxFlagsComponent),
        multi: true
      }
    ]
})
export class CheckboxFlagsComponent implements OnInit, ControlValueAccessor {

    @Input() name: string | undefined;
    @Input() displayName: string | undefined;
    @Input() value: number | undefined = 0;

    @Output() checkStatusChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    private _modelValue: number = 0;

    checked: boolean = false;

    onChange: any = () => {};

    onTouch: any = () => {};

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    constructor() {
        this.value = 0;
    }

    ngOnInit() {
    }

    writeValue(modelValue: any) {
      this._modelValue = modelValue;
      const checked = modelValue & Number(this.value);
      this.checked = checked > 0;
    }

    onModelChange(checked: boolean) {
      this.checked = checked;

      if (this.value) {
        var flagSum = this._modelValue ^ this.value;
        this._modelValue = flagSum;
        this.onChange(flagSum);
        this.checkStatusChanged.emit(this.checked);
      }
    }
}
