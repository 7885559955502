import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from "@angular/forms";
import { EnumsService } from '../../../services/enums.service';
import { Asset } from '../../../models/asset.model';
import { EnumerationItem } from 'projects/shared/models/enumeration-item.model';
import { MultiCheckboxPickerComponent } from 'projects/shared/multi-checkbox-picker/multi-checkbox-picker.component';
import { Address } from '../../../models/address-model';
import { AssetPendingNetSaleProceedsComponent } from '../asset-pending-net-sale-proceeds/asset-pending-net-sale-proceeds.component';
import { Borrower } from '../../../models/borrower-model';
import { ConfigurableFieldHostComponent } from '../../configurable-field-host.component';
import { WizardFlowServiceBase } from '../../../services/wizard/wizard-flow-service.base';
import { UtilsService } from '../../../services/utils.service';

@Component({
  selector: 'asset-dialog',
  templateUrl: 'asset-dialog.component.html'
})

export class AssetDialogComponent extends ConfigurableFieldHostComponent implements OnInit, AfterViewInit {

  @ViewChild('assetInfoForm') assetInfoForm: NgForm | undefined;

  @ViewChild(MultiCheckboxPickerComponent)
  borrowerPicker!: MultiCheckboxPickerComponent;

  @ViewChild(AssetPendingNetSaleProceedsComponent)
  assetPendingNetSaleProceeds!: AssetPendingNetSaleProceedsComponent;

  private _asset: Asset;
  private _inEditMode: boolean = false;

  public address: Address = new Address();

  possibleBorrowers: EnumerationItem[] = [];
  disabledBorrowers: EnumerationItem[] = [];
  selectedBorrowers: EnumerationItem[] = [];

  inCoApplyFlow: boolean = false;

  public integerPattern = {
    'X': { pattern: new RegExp('[1-9]') },
    'Y': { pattern: new RegExp('[0-9]') }
  }

  constructor(public activeModal: NgbActiveModal,
    private readonly _utilsService: UtilsService,
    private readonly _enumsService: EnumsService,
    private readonly _wizardFlowService: WizardFlowServiceBase) {
      super();
      this.inCoApplyFlow = this._wizardFlowService.inCoApplyFlow;
      this._asset = new Asset();
      this._asset.assetId = this._utilsService.generateUniqueNumericId();
      const allBorrowers = this._wizardFlowService.context.mortgage.borrowers;
      this.possibleBorrowers = allBorrowers.map(b => new EnumerationItem(b.firstName + " " + b.lastName, b.borrowerId !== undefined ? b.borrowerId.toString() : (-1 * (new Date()).valueOf()).toString()));
  }

  @Output()
  saveClickedForCreate = new EventEmitter<Asset>();

  @Output()
  saveClickedForEdit = new EventEmitter<Asset>();

  @Input()
  public set asset(asset: Asset) {
    this._asset = asset;
    this.address.address1 = asset.holderAddress1;
    this.address.city = asset.holderCity;
    this.address.state = asset.holderState;
    this.address.zipCode = asset.holderZipCode;
    this._inEditMode = true;
  }

  public get assetTypes(): any[] {
    return this._enumsService.assetTypes;
  }

  public get asset(): Asset {
    return this._asset;
  }

  public get inEditMode(): boolean {
    return this._inEditMode;
  }

  public get header(): string {
    if (this._inEditMode) {
      return "Editing Asset";
    }
    return "Add Asset";
  }

  onAssetTypeSelected= (assetType: string) => {
    if (assetType != '0') {
      this.asset.makeModel = undefined;
      this.asset.year = undefined;
    }
  }

  onSaveClicked = () => {
    this.assetInfoForm?.form.markAllAsTouched();
    if ((this.assetPendingNetSaleProceeds && this.assetPendingNetSaleProceeds.isValid
      && this.assetInfoForm?.form.valid)
        || (!this.assetPendingNetSaleProceeds && this.assetInfoForm?.form.valid)) {
      this.activeModal.close('Save click');
      this.asset.holderAddress1 = this.address.address1;
      this.asset.holderCity = this.address.city;
      this.asset.holderZipCode = this.address.zipCode;
      this.asset.holderState = this.address.state;
      if (this.inCoApplyFlow) {
        this.asset.owningBorrowerIds = [<number>this._wizardFlowService.context.currentBorrower.borrowerId];
      } else {
        const owningBorrowerIds = this.borrowerPicker?.selectedItems.map(i => Number(i.value));
        this.asset.owningBorrowerIds = owningBorrowerIds;
      }

      if (this._inEditMode) {
        this.saveClickedForEdit.emit(this.asset);
      } else {
        this.saveClickedForCreate.emit(this.asset);
      }
    }
  }

  public get saveClickedEmitterForEdit(): EventEmitter<Asset> {
    return this.saveClickedForEdit;
  }

  public get saveClickedEmitterForCreate(): EventEmitter<Asset> {
    return this.saveClickedForCreate;
  }

  ngOnInit() {
    this.fieldsToConfig = this.fieldsToConfig ?? {};
  }

  private setBorrowerPickerValues() {
    if (!this.inEditMode) {
      return;
    }
    let owningBorrowerIds: number[] = this.asset.owningBorrowerIds;
    if (!owningBorrowerIds) {
      return;
    }
    let enumerationItems: EnumerationItem[] = [];
    for (let i=0; i < owningBorrowerIds.length; i++){
      let borrower: Borrower | undefined = this._wizardFlowService.context.mortgage.borrowers.find(b => b.borrowerId === owningBorrowerIds[i]);
      if (borrower) {
        let enumerationItem = new EnumerationItem(borrower.firstName + " " +  borrower.lastName, borrower.borrowerId!.toString());
        enumerationItems.push(enumerationItem);
      }
    }
    this.borrowerPicker.selectedItems = enumerationItems;
  }

  ngAfterViewInit() {
      this.setBorrowerPickerValues();
  }
}
