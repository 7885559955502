import { Component, Injector, QueryList, ViewChildren } from '@angular/core';
import { Borrower } from '../../../models/borrower-model';
import { WizardStepComponentBase } from '../wizard-step-base.component';
import { HomeownershipEducationStep } from '../../../models/wizard/homeownership-education-step.model';
import { BorrowerHomeownershipEducationComponent } from '../../borrower-homeownership-education/borrower-homeownership-education.component';

@Component({
  selector: 'homeownership-education-step',
  templateUrl: 'homeownership-education-step.component.html',
styleUrls: ['homeownership-education-step.component.scss']
})

export class HomeownershipEducationStepComponent extends WizardStepComponentBase<HomeownershipEducationStep> {

  @ViewChildren('borrowerHomeownershipEducation') borrowerHomeownershipEducationComponents: QueryList<BorrowerHomeownershipEducationComponent> | undefined;

  mainBorrower!: Borrower ;
  coBorrowers: Borrower[] = [];

  constructor(private readonly _injector: Injector) {
      super(_injector);
      this.saveMortgageApplicationBeforeNextStep = true;
      if (this.currentBorrower) {
        this.mainBorrower = this.currentBorrower;
        this.coBorrowers = this.mortgageApplication.borrowers.filter(b => b.primaryEmail.toLocaleLowerCase() !==
          this.mainBorrower.primaryEmail.toLocaleLowerCase());
      }
  }

  onNextClicked() {
    if (this.wizardFlowService.isEditEnabled) {
      super.onNextClicked();
      return;
    }
    let isFormValid = true;
    this.borrowerHomeownershipEducationComponents?.toArray().forEach(borrowerDemographicsComponent => {
      const isValidForBorrower = borrowerDemographicsComponent.validate();
      isFormValid = isFormValid && isValidForBorrower;
    });
    if (isFormValid) {
      super.onNextClicked();
    }
  }
}
