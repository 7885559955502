<ng-template #reviewCredit>
  <div id="reo-main">
    <div class="col-md-12 fv-row mb-5" *ngFor="let liability of liabilities; let i = index">
      <div class="card card-flush shadow-sm">
        <div class="card-body py-2">
         <liability-reo #liabilityInfo [reos]="reos" [liability]="liability"></liability-reo>
       </div>
      </div>
    </div>
</div>
</ng-template>

<wizard-step-template [stepMainTemplate]="reviewCredit" [stepComponent]="this" [step]="step"></wizard-step-template>
