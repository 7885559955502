import { Injectable, Type } from "@angular/core";
import { DuotoneBankSvgIconComponent } from "./duotone/bank/duotone-bank-svg-icon.component";
import { DuotoneBuildingSvgIconComponent } from "./duotone/building/duotone-building-svg-icon.component";
import { DuotoneBuilding2SvgIconComponent } from "./duotone/building_/duotone-building2-svg-icon.component";
import { DuotoneClipboardCheckSvgIconComponent } from "./duotone/clipboard-check/duotone-clipboard-check-svg-icon.component";
import { DuotoneCoinsSvgIconComponent } from "./duotone/coins/duotone-coins-svg-icon.component";
import { DuotoneCouponDiscountSvgIconComponent } from "./duotone/coupon-discount/duotone-coupon-discount-svg-icon.component";
import { DuotoneDollarSvgIconComponent } from "./duotone/dollar/duotone-dollar-svg-icon.component";
import { DuotoneHomeSvgIconComponent } from "./duotone/home/duotone-home-svg-icon.component";
import { DuotoneSafeSvgIconComponent } from "./duotone/safe/duotone-safe-svg-icon.component";
import { DuotoneShirtSvgIconComponent } from "./duotone/shirt/duotone-shirt-svg-icon.component";
import { DuotoneStarSvgIconComponent } from "./duotone/star/duotone-star-svg-icon.component";
import { DuotoneToolsSvgIconComponent } from "./duotone/tools/duotone-tools-svg-icon.component";
import { DuotoneWalletSvgIconComponent } from "./duotone/wallet/duotone-wallet-svg-icon.component";
import { ClipboardSvgIconComponent } from "./general/clipboard-svg-icon-component";
import { LaptopSvgIconComponent } from "./general/laptop-svg-icon.component";
import { UserSvgIconComponent } from "./general/user-svg-icon.component";
import { StockholmBankSvgIconComponent } from "./stockholm/bank/stockholm-bank-svg-icon.component";
import { StockholmHomeHeartSvgIconComponent } from "./stockholm/home-heart/stockholm-home-heart-svg-icon.component";
import { StockholmSunSvgIconComponent } from "./stockholm/sun/stockholm-sun-svg-icon.component";

@Injectable()
export class SvgIconComponentFactory {

    getSvIconComponent(name: string): Type<any> | undefined {
        if (name === "duotone-home") {
            return DuotoneHomeSvgIconComponent;
        } else if (name === "duotone-coins") {
            return DuotoneCoinsSvgIconComponent;
        } else if (name === "duotone-clipboard-check") {
            return DuotoneClipboardCheckSvgIconComponent;
        } else if (name === "duotone-coupon-discount") {
            return DuotoneCouponDiscountSvgIconComponent;
        } else if (name === "duotone-building") {
            return DuotoneBuildingSvgIconComponent;
        } else if (name === "duotone-building2") {
            return DuotoneBuilding2SvgIconComponent;
        } else if (name === "stockholm-home-heart") {
            return StockholmHomeHeartSvgIconComponent;
        } else if (name === "stockholm-sun") {
            return StockholmSunSvgIconComponent;
        } else if (name === "stockholm-bank") {
            return StockholmBankSvgIconComponent;
        } else if (name === "duotone-shirt") {
            return DuotoneShirtSvgIconComponent;
        } else if (name === "duotone-dollar") {
            return DuotoneDollarSvgIconComponent;
        } else if (name === "duotone-safe") {
            return DuotoneSafeSvgIconComponent;
        } else if (name === "duotone-bank") {
            return DuotoneBankSvgIconComponent;
        } else if (name === "duotone-tools") {
            return DuotoneToolsSvgIconComponent;
        } else if (name === "duotone-star") {
            return DuotoneStarSvgIconComponent;
        } else if (name === "duotone-wallet") {
            return DuotoneWalletSvgIconComponent;
        } else if (name === "laptop-svg-icon") {
            return LaptopSvgIconComponent;
        } else if (name === "clipboard-svg-icon") {
            return ClipboardSvgIconComponent;
        } else if (name === "user-svg-icon") {
            return UserSvgIconComponent;
        }
        return undefined;
    }

}
