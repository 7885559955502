import { IDecideNextStep } from "../../services/wizard/next-step-decider/next-step-decider.interface";
import { IncomeStepComponent } from "../../wizard/wizard-steps/income-step/income-step.component";
import { WizardStepBase } from "./wizard-step-base.model";

export class IncomeStep extends WizardStepBase {

  enforceAtLeast2YearsOfEmploymentHistory: boolean = false;

  w2EmploymentActionLabel: string;
  independentContractorActionLabel: string;
  militaryPayActionLabel: string;
  rentalIncomeActionLabel: string;
  socialSecurityIncomeActionLabel: string;
  pensionIncomeActionLabel: string;
  businessSelfEmploymentActionLabel: string;
  otherIncomeActionLabel: string;

  constructor(id: string, name: string, path: string, nextStepDecider?: IDecideNextStep) {
    super(id, name, path, nextStepDecider);
    this.component = IncomeStepComponent;
  }
}
