<div class="row mb-2">
  <income-item #incomeItem *ngFor="let income of incomes; let i = index" [income]="income"
    (editClicked)="onIncomeEditClicked($event)"
    (deleteClicked)="onIncomeDeleted($event)"
    (editIncomeItemsClicked)="onEditIncomeItemsClicked($event)">
  </income-item>
</div>
<div class="row" *ngIf="incomes.length > 0">
  <span style="text-align: right;" class="text-dark fw-bolder d-block fs-5 mb-4">Total Monthly Income: {{totalIncome | currency}}</span>
</div>
