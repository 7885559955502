

<div class="modal-header">

  <h2>There are asset verification tasks for multiple borrowers</h2>

  <!-- hack to fool ngbModal autofocus -->
  <input type="text" style="display:none" />

  <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" aria-label="Close"
       (click)="activeModal.dismiss('Cross click')">
      <span class="svg-icon svg-icon-2x">
        <close-x-svg-icon></close-x-svg-icon>
      </span>
  </div>

</div>

<div class="modal-body px-lg-17 py-10">
  <form #borrowerPickerForm="ngForm" class="form" novalidate id="borrowerPickerForm" name="borrowerPickerForm">
    <label class="form-label" for="answer">Please, pick a borrower</label>
    <select class="form-select form-select-solid" data-control="select2" name="borrowerId" [(ngModel)]="selectedBorrowerId"
      #modelValue="ngModel" [required]="true" [ngClass]="{'is-invalid' : modelValue && modelValue.touched && modelValue.invalid}">
      <option value="">Select one...</option>
      <option *ngFor="let item of borrowers" [ngValue]="item.id">
        {{item.name}}
    </select>
    <div class="invalid-feedback">Borrower is required.</div>
  </form>
</div>

<div class="modal-footer flex-center">
  <button type="button" class="btn btn-white me-3" (click)="activeModal.dismiss('Close click')">Close</button>
  <button type="button" class="btn btn-primary" (click)="onOkClicked()">OK</button>
</div>




