import { IDecideNextStep } from "../../services/wizard/next-step-decider/next-step-decider.interface";
import { DigitalAssetsStepComponent } from "../../wizard/wizard-steps/digital-assets-step/digital-assets-step.component";
import { WizardStepBase } from "./wizard-step-base.model";

export class DigitalAssetsStep extends WizardStepBase {

  vendorToUse: string = "FormFree";

  constructor(id: string, name: string, path: string, nextStepDecider?: IDecideNextStep) {
    super(id, name, path, nextStepDecider);
    this.component = DigitalAssetsStepComponent;
  }
}
