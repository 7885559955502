import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UtilsService } from '../services/utils.service';
import { Subscription } from 'rxjs';
import { Constants } from '../services/constants';

@Component({
	selector: 'external-auth-online-app-redirect',
	templateUrl: 'external-auth-online-app-redirect.component.html'
})

export class ExternalAuthOnlineAppRedirectComponent implements OnInit {

	private _activatedRouteQueryParamsSubscription: Subscription;
	private _activatedRouteParamsSubscription: Subscription;

	private _token: string;

	private _queryParams: string = "";

	private _returnUrl: string = "";

	constructor(private readonly _activatedRoute: ActivatedRoute,
		private readonly _utilsService: UtilsService
	) { }

	ngOnInit() {
		this._activatedRouteQueryParamsSubscription = this._activatedRoute.queryParams.subscribe((queryParams) => {
			this._activatedRouteParamsSubscription = this._activatedRoute.params.subscribe((routeParams) => {
				Object.keys(queryParams).forEach(key => {
					if (key !== 'token') {
						if (key !== 'r_url') {
							this._queryParams += `${key}=${queryParams[key]}&`;
						} else {
							this._returnUrl = queryParams[key];
						}
					} else {
						this._token = queryParams[key];
					}
				});
				this.persistAuthData();
				const urlToRedirectTo = this._returnUrl ? this._returnUrl : '/borrower-portal';
				setTimeout(() => {
					const applyUrl = `${window.location.protocol}//${window.location.host}${urlToRedirectTo}?${this._queryParams}`;
					window.location.href = applyUrl;
				}, 2000);
			});
		});
	}

	ngOnDestroy(): void {
		this._activatedRouteQueryParamsSubscription?.unsubscribe();
		this._activatedRouteParamsSubscription?.unsubscribe();
	}

	private persistAuthData = () => {
		let jwtPayload = this._utilsService.parseJwt(this._token);

		localStorage.setItem(Constants.authorization.authorizationDataKey, JSON.stringify({
			token: this._token,
			userName: jwtPayload['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'],
			userId: jwtPayload['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'],
			scope: 'Borrower',
			companyGuid: jwtPayload.CompanyGuid,
			expiresAt: this._utilsService.getTokenExpireDate(jwtPayload.exp)
		}));
	}
}