import { Directive, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from "@angular/forms";

export function minValueValidator(minValue: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const invalid = control.value < minValue;
    return invalid ? { minValue: { value: control.value } } : null;
  };
}

@Directive({
  selector: '[minValue]',
  providers: [{provide: NG_VALIDATORS, useExisting: MinValueValidatorDirective, multi: true}]
})
export class MinValueValidatorDirective implements Validator {

  @Input('minValue') minValue = -9999999;

  validate(control: AbstractControl): ValidationErrors | null {
    return this.minValue != null ? minValueValidator(this.minValue)(control) : null;
  }
}
