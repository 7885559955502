import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NextStepDeciderType } from '../../models/wizard/config/next-step-decider-type.enum';
import { StepConfig } from '../../models/wizard/config/step-config.model';

declare const KTMenu: any;

@Component({
  selector: 'flow-step',
  templateUrl: 'flow-step.component.html',
  styleUrls: ['flow-step.component.scss']
})

export class FlowStepComponent implements OnInit {

  @Output()
  stepSelected: EventEmitter<StepConfig> = new EventEmitter<StepConfig>();

  @Output()
  deleteStepFromFlowClicked: EventEmitter<StepConfig> = new EventEmitter<StepConfig>();

  @Output()
  deleteStepFromNextStepDestinationsClicked: EventEmitter<StepConfig> = new EventEmitter<StepConfig>();

  @Output()
  addStepClicked: EventEmitter<StepConfig> = new EventEmitter<StepConfig>();

  @Output()
  editStepClicked: EventEmitter<StepConfig> = new EventEmitter<StepConfig>();

  constructor() { }

  @Input()
  step!: StepConfig;

  @Input()
  condition: string = "";

  @Input()
  addEnabled: boolean = false;

  @Input()
  isSourceStep: boolean = false;

  @Input()
  nextStepDeciderType: NextStepDeciderType | undefined;

  onStepClicked = () => {
    this.stepSelected?.emit(this.step);
  }

  onDeleteStepFromFlowClicked = () => {
    this.deleteStepFromFlowClicked?.emit(this.step);
  }

  onDeleteStepFromNextStepDestinationsClicked = ($event: any) => {
    $event.stopPropagation();
    this.deleteStepFromNextStepDestinationsClicked?.emit(this.step);
  }

  onAddStepClicked = ($event: any) => {
    this.addStepClicked?.emit(this.step);
    $event.stopPropagation();
  }

  onEditStepClicked = ($event: any) => {
    this.editStepClicked?.emit(this.step);
    $event.stopPropagation();
  }

  ngOnInit() {
    KTMenu.createInstances();
  }
}
