
<ng-template #ownOrRent>
  <div class="fv-row">
    <div class="row">
      <div class="col-lg-4 pb-4">
        <input type="radio" [checked]="isOwned" class="btn-check" (click)="onOwnedSelected()" name="owned"
          value="isOwned" id="owned" />
        <label class="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center" for="owned">
          <!--begin::Svg Icon | path: icons/duotone/Contacts/User.svg-->
          <span class="svg-icon svg-icon-3x me-5">
            <!-- <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M21.4622 10.699C21.4618 10.6986 21.4613 10.6981 21.4609 10.6977L13.3016 2.53955C12.9538 2.19165 12.4914 2 11.9996 2C11.5078 2 11.0454 2.1915 10.6974 2.5394L2.54246 10.6934C2.53971 10.6961 2.53696 10.699 2.53422 10.7018C1.82003 11.42 1.82125 12.5853 2.53773 13.3017C2.86506 13.6292 3.29739 13.8188 3.75962 13.8387C3.77839 13.8405 3.79732 13.8414 3.81639 13.8414H4.14159V19.8453C4.14159 21.0334 5.10833 22 6.29681 22H9.48897C9.81249 22 10.075 21.7377 10.075 21.4141V16.707C10.075 16.1649 10.516 15.7239 11.0582 15.7239H12.941C13.4832 15.7239 13.9242 16.1649 13.9242 16.707V21.4141C13.9242 21.7377 14.1866 22 14.5102 22H17.7024C18.8909 22 19.8576 21.0334 19.8576 19.8453V13.8414H20.1592C20.6508 13.8414 21.1132 13.6499 21.4613 13.302C22.1786 12.5844 22.1789 11.4171 21.4622 10.699V10.699Z" fill="#00B2FF"/>
            </svg> -->
            <i class="fa fa-home fa-2x"></i>
          </span>
          <!--end::Svg Icon-->
          <span class="d-block fw-bold text-start" style="height: 60px; padding-top: 20px;">
            <span *ngIf="!inEditMode" class="text-dark fw-bolder d-block fs-4">{{step.ownActionLabel || 'Own'}}</span>
            <input *ngIf="inEditMode" name="ownActionLabel" type="text"
                  class="form-control form-control-solid fs-4 fw-bolder" [(ngModel)]="step.ownActionLabel" />
          </span>
        </label>
      </div>
      <div class="col-lg-4 pb-4">
        <input type="radio" class="btn-check" [checked]="isRent" (click)="onRentSelected()" name="rent"
          value="isRent" id="rent" />
        <label class="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center" for="rent">
          <!--begin::Svg Icon | path: icons/duotone/Business/Briefcase.svg-->
          <i class="far fa-building fa-2x"></i>

          <span class="d-block fw-bold text-start" style="height: 60px; padding-top: 15px;">
            <span *ngIf="!inEditMode" class="text-dark fw-bolder d-block fs-4 mb-2">{{step.rentActionLabel || 'Rent'}}</span>
            <input *ngIf="inEditMode" name="rentActionLabel" type="text"
                  class="form-control form-control-solid fs-4 fw-bolder" [(ngModel)]="step.rentActionLabel" />
              <span class="text-gray-400 fw-bold fs-6"></span>
          </span>
        </label>
      </div>
      <div class="col-lg-4 pb-4">
        <input type="radio" class="btn-check" [checked]="isRentFree" (click)="onRentFreeSelected()" name="rentFree" value="isRentFree" id="rentFree" />
        <label class="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center" for="rentFree">
          <span class="svg-icon svg-icon-3x me-5">
            <duotone-building2-svg-icon></duotone-building2-svg-icon>
          </span>
          <span class="d-block fw-bold text-start" style="height: 60px; padding-top: 20px;">
            <span *ngIf="!inEditMode" class="text-dark fw-bolder d-block fs-4">{{step.liveRentFreeActionLabel || 'Live Rent Free'}}</span>
            <input *ngIf="inEditMode" name="liveRentFreeActionLabel" type="text"
                  class="form-control form-control-solid fs-4 fw-bolder" [(ngModel)]="step.liveRentFreeActionLabel" />
          </span>
        </label>
      </div>
    </div>
  </div>
</ng-template>

<wizard-step-template [stepMainTemplate]="ownOrRent" [step]="step" [stepComponent]="this"></wizard-step-template>
