import { Injectable } from "@angular/core";
import { IDecideNextStep } from "projects/borrower-app/src/app/services/wizard/next-step-decider/next-step-decider.interface";
import { SimpleNextStepDecider } from "projects/borrower-app/src/app/services/wizard/next-step-decider/simple-next-step-decider";
import { SimpleNextStepDeciderConfig } from "../simple-next-step-decider-config.model";

@Injectable()
export class SimpleNextStepDeciderFactory {

    constructor() {
    }

    create = (config: SimpleNextStepDeciderConfig) : IDecideNextStep | undefined => {
      if (config.gotoStepPath) {
          const decider = new SimpleNextStepDecider(config.gotoStepPath);
          return decider;
       }
      return undefined;
    }
}
