<form #addressForm="ngForm" novalidate id="addressForm{{uniqueId}}" name="addressForm{{uniqueId}}">
  <div class="row mb-2" *ngIf="canSetTbd">
    <div class="form-check ms-2">
      <input class="form-check-input" [(ngModel)]="isTbd" (ngModelChange)="onIsTbdChanged()" type="checkbox" value=""
        id="isTbd" [disabled]="isTbdDisabled" name="isTbd">
      <label *ngIf="!inEditMode" class="form-check-label fs-6 fw-bold" for="isTbd">
        {{addressTbdLabel}}
      </label>
      <input *ngIf="inEditMode" name="tbdLabel" type="text" class="form-control  form-control-solid fw-bolder"
        [(ngModel)]="addressTbdLabel" (blur)="onAddressTbdLabelChanged()" />
    </div>
  </div>
  <div *ngIf="isTbd && !requireUserToEnterZipCodeWhenTbd" class="row mb-2 rounded" [ngClass]="{
              'is-invalid' : doYouKnowTheZipQuestion && doYouKnowTheZipQuestion.touched && doYouKnowTheZipQuestion.invalid,
              'border' : doYouKnowTheZipQuestion && doYouKnowTheZipQuestion.touched && doYouKnowTheZipQuestion.invalid,
              'p-4' : doYouKnowTheZipQuestion && doYouKnowTheZipQuestion.touched && doYouKnowTheZipQuestion.invalid,
              'border-danger' : doYouKnowTheZipQuestion && doYouKnowTheZipQuestion.touched && doYouKnowTheZipQuestion.invalid
            }">
    <div class="col-md-6 col-sm-12 fs-6 text-dark mb-3" style="padding-top: 4px;">Do you know the zip code where you are
      looking for a property?
    </div>
    <div class="col-md-2 col-sm-12 mb-3">
      <div class="d-flex">
        <div class="form-check form-check-custom form-check-solid">
          <input class="form-check-input" name="isZipKnownByTheBorrower" required [value]="true" type="radio"
            id="yesForZipCode" [(ngModel)]="isZipKnownByTheBorrower" #doYouKnowTheZipQuestion="ngModel"
            (ngModelChange)="onAnswerForZipcodeQuestionChanged()" />
          <label style="padding-right: 6px;" class="form-check-label" for="yesForZipCode">Yes</label>
        </div>
        <div class="form-check form-check-custom form-check-solid ms-8">
          <input class="form-check-input" name="isZipKnownByTheBorrower" required [value]="false" type="radio"
            id="noForZipCode" [(ngModel)]="isZipKnownByTheBorrower" #doYouKnowTheZipQuestion="ngModel"
            (ngModelChange)="onAnswerForZipcodeQuestionChanged()" />
          <label style="padding-right: 6px;" class="form-check-label" for="noForZipCode">No</label>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex mb-5 row" *ngIf="!isTbd || isZipKnownByTheBorrower || (requireUserToEnterZipCodeWhenTbd && isTbd)">
    <!-- Address Field (shown when !isTbd) -->
    <div *ngIf="!isTbd" class="col-md-{{showCounty ? '3' : '4'}}">
      <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
        <span [ngClass]="{'required': required && !isTbd}">Address</span>
      </label>
      <address-autocomplete (setGooglePickedAddress)="setGooglePickedAddress($event)"
        (setManuallyEnteredAddress)="setManuallyEnteredAddress($event)" adressType="geocode" [disabled]="isTbd"
        [required]="required" [name]="'address1' + uniqueId" [id]="'address1' + uniqueId"
        [isItAForeignCountry]="address.country && address.country != 'us'" [autocompleteInput]="address!.address1">
      </address-autocomplete>
      <!-- <div class="invalid-feedback">Address is required, please enter an address.</div> -->
    </div>

    <!-- Zip Code Field (shown when isTbd) -->
    <div *ngIf="isTbd && zipCodeLookupEnabled" class="col-md-3 fv-row">
      <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
        <span
          [ngClass]="{'required': required && ((isTbd && !requireUserToEnterZipCodeWhenTbd) || !isTbd) && (!isTbd || isZipKnownByTheBorrower) && !(address.country && address.country !== 'us')}">Zip
          Code</span>
      </label>
      <div class="input-group">
        <input type="text" #control [(ngModel)]="address!.zipCode"
          [mask]="mask" [placeholder]="placeHolder" [pattern]="pattern"
          [required]="required && ((isTbd && !requireUserToEnterZipCodeWhenTbd) || !isTbd) && (!isTbd || isZipKnownByTheBorrower) && !(address.country && address.country !== 'us')"
          [ngClass]="{'is-invalid' : zipCode && zipCode.touched && zipCode.invalid}" numeric inputmode="numeric"
          [disabled]="address.country && address.country !== 'us'" class="form-control form-control-solid"
          (keydown)="onKeyDownOnAddress($event)"
          (blur)="onAddressBlurred($event)"
          placeholder="" name="zipCode{{uniqueId}}" #zipCode="ngModel" #zipCodeOfAddress id="zipCode{{uniqueId}}" />
        <span class="input-group-append" style="z-index: 1;">
          <button class="btn btn-default border" type="button" id="search-zip-code-btn"
            (click)="searchZipcode(selectionsDropdown)">
            <span *ngIf="loadingZipcodes" class="spinner-border spinner-border-sm" role="status"
              aria-hidden="true"></span>

            <i class="fa fa-search" *ngIf="!loadingZipcodes"></i>
          </button>
        </span>
        <div class="invalid-feedback" *ngIf="zipCode.errors && zipCode.errors.pattern">
          This field is in invalid format
        </div>
      </div>
      <div ngbDropdown #selectionsDropdown="ngbDropdown" class="w-100">
        <a ngbDropdownToggle style="display: none"></a>
        <div ngbDropdownMenu class="w-100 overflow-auto">
          <span *ngIf="suggestions.length == 0" class="mx-2">No records found</span>
          <button ngbDropdownItem *ngFor="let suggestion of suggestions" type="button" class="px-1"
            (click)="selectZipcode(suggestion)">
            {{ suggestion.city }} / {{ suggestion.county }} , {{ suggestion.state }}
          </button>
        </div>
      </div>
    </div>

    <!-- City Field -->
    <div class="col-md-{{isTbd ? '4' : (showCounty ? '2' : '3')}}">
      <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
        <span [ngClass]="{'required': required && !isTbd}">City </span>
      </label>
      <div class="position-relative">
        <input type="text" class="form-control form-control-solid" [(ngModel)]="address!.city"
          (change)="onAddressChanged()" [required]="required && !isTbd" placeholder="" name="city{{uniqueId}}"
          [ngClass]="{'is-invalid' : city && city.touched && city.invalid}" #city="ngModel" #cityOfAddress
          id="city{{uniqueId}}" />
        <!-- <div class="invalid-feedback">City is required, please enter a city.</div> -->
      </div>
    </div>

    <!-- County Field -->
    <div class="col-md-{{isTbd ? '3' : '2'}}" *ngIf="showCounty">
      <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
        <span [ngClass]="{'required': required && !isTbd}">County </span>
      </label>
      <div class="position-relative">
        <input type="text" class="form-control form-control-solid" [(ngModel)]="address!.county"
          (change)="onAddressChanged()" [required]="required && !isTbd" placeholder="" name="county{{uniqueId}}"
          [ngClass]="{'is-invalid' : county && county.touched && county.invalid}" #county="ngModel" #countyOfAddress
          id="county{{uniqueId}}" />
      </div>
    </div>

    <!-- State Field -->
    <div class="col-md-2 fv-row" *ngIf="!isHidden(fieldConfigSettings.stateField)">
      <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
        <span
          [ngClass]="{'required': (required && isRequired(fieldConfigSettings.stateField)) && !(address.country && address.country !== 'us')}">
          {{getLabel(fieldConfigSettings.stateField, 'State')}}
        </span>
      </label>
      <select name="state{{uniqueId}}"
        [required]="(required && isRequired(fieldConfigSettings.stateField)) && !(address.country && address.country !== 'us')"
        [ngClass]="{'is-invalid' : state && state.touched && state.invalid}" [(ngModel)]="address!.state"
        (change)="onAddressChanged()" class="form-select form-select-solid" #state="ngModel" #stateOfAddress
        id="state{{uniqueId}}" data-placeholder="Select a State"
        [disabled]="address.country && address.country !== 'us'">
        <option [ngValue]="null">Select one...</option>
        <option *ngFor="let state of states; let i = index" value="{{state.value}}">
          {{state.name}}
        </option>
      </select>
      <!-- <div class="invalid-feedback">State is required, please select a state.</div> -->
    </div>

    <!-- Zip Code Field (shown when !isTbd && !zipCodeLookupEnabled) -->
    <div *ngIf="!isTbd && !zipCodeLookupEnabled" class="col-md-2 fv-row">
      <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
        <span
          [ngClass]="{'required': required && ((isTbd && !requireUserToEnterZipCodeWhenTbd) || !isTbd) && (!isTbd || isZipKnownByTheBorrower) && !(address.country && address.country !== 'us')}">Zip
          Code</span>
      </label>
      <div class="position-relative">
        <input type="text" [(ngModel)]="address!.zipCode" (change)="onAddressChanged()"
          [required]="required && ((isTbd && !requireUserToEnterZipCodeWhenTbd) || !isTbd) && (!isTbd || isZipKnownByTheBorrower) && !(address.country && address.country !== 'us')"
          [ngClass]="{'is-invalid' : zipCode && zipCode.touched && zipCode.invalid}" numeric inputmode="numeric"
          [disabled]="address.country && address.country !== 'us'" class="form-control form-control-solid"
          placeholder="" name="zipCode{{uniqueId}}" #zipCode="ngModel" #zipCodeOfAddress id="zipCode{{uniqueId}}" />
        <!-- <div class="invalid-feedback">Zip code is required, please enter a zip code.</div> -->
      </div>
    </div>

    <!-- Zip Code Field with (shown when !isTbd && zipCodeLookupEnabled) -->
    <div *ngIf="!isTbd && zipCodeLookupEnabled" class="col-md-3 fv-row">
      <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
        <span
          [ngClass]="{'required': required && ((isTbd && !requireUserToEnterZipCodeWhenTbd) || !isTbd) && (!isTbd || isZipKnownByTheBorrower) && !(address.country && address.country !== 'us')}">Zip
          Code</span>
      </label>
      <div class="input-group">
        <input type="text" #control [(ngModel)]="address!.zipCode"
          [mask]="mask" [placeholder]="placeHolder" [pattern]="pattern"
          [required]="required && ((isTbd && !requireUserToEnterZipCodeWhenTbd) || !isTbd) && (!isTbd || isZipKnownByTheBorrower) && !(address.country && address.country !== 'us')"
          [ngClass]="{'is-invalid' : zipCode && zipCode.touched && zipCode.invalid}" numeric inputmode="numeric"
          [disabled]="address.country && address.country !== 'us'" class="form-control form-control-solid"
          placeholder="" name="zipCode{{uniqueId}}" #zipCode="ngModel" #zipCodeOfAddress id="zipCode{{uniqueId}}" />
        <span class="input-group-append" style="z-index: 1;">
          <button class="btn btn-default border" type="button" id="search-zip-code-btn"
            (click)="searchZipcode(selectionsDropdown)">
            <span *ngIf="loadingZipcodes" class="spinner-border spinner-border-sm" role="status"
              aria-hidden="true"></span>

            <i class="fa fa-search" *ngIf="!loadingZipcodes"></i>
          </button>
        </span>
        <div class="invalid-feedback" *ngIf="zipCode.errors && zipCode.errors.pattern">
          This field is in invalid format
        </div>
      </div>
      <div ngbDropdown #selectionsDropdown="ngbDropdown" class="w-100">
        <a ngbDropdownToggle style="display: none"></a>
        <div ngbDropdownMenu class="w-100 overflow-auto">
          <span *ngIf="suggestions.length == 0" class="mx-2">No records found</span>
          <button ngbDropdownItem *ngFor="let suggestion of suggestions" type="button" class="px-1"
            (click)="selectZipcode(suggestion)">
            {{ suggestion.city }} / {{ suggestion.county }} , {{ suggestion.state }}
          </button>
        </div>
      </div>
    </div>


    <!-- Country Field -->
    <div class="col-md-3 fv-row mt-2" *ngIf="showCountry">
      <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
        <span [ngClass]="{'required': required && !isTbd}">Country</span>
      </label>
      <select name="country{{uniqueId}}" [required]="required && !isTbd"
        [ngClass]="{'is-invalid' : country && country.touched && country.invalid}" [(ngModel)]="address!.country"
        (change)="onCountryChanged()" class="form-select form-select-solid" #country="ngModel" #countryOfAddress
        id="country{{uniqueId}}" data-placeholder="Select a Country">
        <option [ngValue]="null">Select one...</option>
        <option *ngFor="let country of countries" value="{{country.alpha2}}">
          {{country.name}}
        </option>
      </select>
      <!-- <div class="invalid-feedback">Country is required, please select a country.</div> -->
    </div>
  </div>
</form>
