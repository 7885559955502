import { formatDate } from '@angular/common';
import { Component, Injector, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from 'projects/shared/modal/modal.service';
import { finalize } from 'rxjs/operators';
import { BorrowerCreditScores } from '../../../models/borrower-credit-scores.model';
import { Borrower } from '../../../models/borrower-model';
import { BorrowerStepStatus } from '../../../models/borrower-step-status.model';
import { ErrorMessage } from '../../../models/error-message.model';
import { WizardFlowCustomerPreferences } from '../../../models/wizard-flow-customer-preferences.model';
import { VerifyCreditStep } from '../../../models/wizard/verify-credit-step.model';
import { CreditService } from '../../../services/credit.service';
import { CreditCheckConsentDialogComponent } from '../../credit-check-consent-dialog/credit-check-consent-dialog.component';
import { BorrowerCreditMiniComponent } from '../borrower-credit-mini/borrower-credit-mini.component';
import { WizardStepComponentBase } from '../wizard-step-base.component';

declare const Swal: any;
@Component({
  selector: 'verify-credit-step',
  templateUrl: 'verify-credit-step.component.html'
})
export class VerifyCreditStepComponent extends WizardStepComponentBase<VerifyCreditStep> implements OnInit {

  @ViewChildren('borrowerCredit') borrowerCreditComponents: QueryList<BorrowerCreditMiniComponent> | undefined;

  private _allFieldsFilled: boolean = false;
  public isCheckingCreditHistory = false;
  public borrowerCreditScores: BorrowerCreditScores[] = [];

  private _creditAuthText: string | undefined = 'This is a test consent text.';
  private _creditAuthGenerationEnabled: boolean | undefined = false;
  private _creditWillBeRunOfflineLater: boolean = true;
  private _customerPreferences: WizardFlowCustomerPreferences;

  borrowers: Borrower[] = [];

  mainBorrower!: Borrower;
  coBorrowers: Borrower[] = [];

  canRunCredit: boolean = true;

  private modalOptions: NgbModalOptions = {
    size: 'lg',
    backdrop: 'static',
    container: 'body'
  };

  constructor(private readonly _injector: Injector, private readonly _creditService: CreditService,
    private readonly _modalService: ModalService) {
    super(_injector);
    this.borrowers = this.inCoApplyFlow ? [this.currentBorrower] : this.mortgageApplication.borrowers;
    this._customerPreferences = this.wizardFlowService.context.customerPreferences;
    if (this.currentBorrower) {
      this.mainBorrower = this.currentBorrower;
      this.coBorrowers = this.borrowers.filter(b => b.borrowerId !== this.mainBorrower.borrowerId);
      this.coBorrowers.forEach(c => {
        c.willBeEdited = this.borrowerSetting(c);
      });
    }
  }

  ngOnInit() {
    this.wizardFlowService.context.creditRunFailed = false;
    this._creditAuthGenerationEnabled = this.wizardFlowService.context.config?.creditAuthGenerationEnabled;
    this._creditWillBeRunOfflineLater = this.wizardFlowService.context.skipAutomatedCreditCheck;
    this._creditAuthText = this.wizardFlowService.context.config?.creditAuthText;
    this.canRunCredit = this.wizardFlowService.context.config?.creditPullEnabled &&
      !this.step.hideRealTimeCreditRunOption && !this._creditWillBeRunOfflineLater;
  }

  checkAllFilled(): boolean {
    let valid = true;
    if (this.borrowerCreditComponents) {
      for (const borrowerinfo of this.borrowerCreditComponents) {
        valid = borrowerinfo.validate() && valid;
      }
    }
    return (valid && this.allBorrowersCreditRunChecked);
  }

  onNextClicked() {
    if (this.wizardFlowService.context.creditRunFailed) {
      this.goToNextStep();
      return;
    }
    let valid = true;
    this.borrowerCreditComponents?.forEach(b => {
      valid = valid && b.validate();
    });
    if (!valid) {
      return;
    }
    if (!this._allFieldsFilled) {
      this._creditWillBeRunOfflineLater = true;
    }
    if (this.wizardFlowService.isEditEnabled) {
      if (this.borrowersToRunCreditReportFor().length != 0) {
        this.showCreditCheckConsentPopup();
      } else {
        this.goToNextStep();
      }
      return;
    }

    if (this.borrowersToRunCreditReportFor().length != 0) {
      if (!this.currentBorrower.authorizedCreditCheck) {
        if (this.wizardFlowService.context.config.showCreditAuthVerbiage) {
          this.showCreditCheckConsentPopup();
          return;
        } else if (!this._creditWillBeRunOfflineLater) {
          this.doRunCreditReport();
        } else {
          this.goToNextStep();
        }
      } else if (!this._creditWillBeRunOfflineLater) {
        this.doRunCreditReport();
      } else {
        this.goToNextStep();
      }
    } else {
      this.goToNextStep();
    }
  }

  goToNextStep = () => {
    this.wizardFlowService.context.skipAutomatedCreditCheck = this._creditWillBeRunOfflineLater;
    super.onNextClicked();
  }

  isCreditRunForBorrower(borrower: Borrower): boolean {
    let result = this.wizardFlowService.context.borrowerAppStatus.details.borrowerStepStatuses.find(c => c.borrowerId == borrower.borrowerId);
    if (!result) {
      return false;
    }
    return result.creditRun;
  }

  onBorrowersBirthDateOrSsnChanged = (): void => {
    this.setNextButtonText();
  }

  onPermissionToRunCreditChangedForBorrower = (e: any): void => {
    this.setNextButtonText();
  }

  private setNextButtonText = () => {
    setTimeout(() => {
      this._allFieldsFilled = this.checkAllFilled();
      if (this._allFieldsFilled || !this.canRunCredit) {
        this.nextButtonText = 'Continue';
      } else {
        this.nextButtonText = 'Continue without Credit';
      }
    });
  }

  private showCreditCheckConsentPopup = () => {
    const modal = this._modalService.show(CreditCheckConsentDialogComponent, this.modalOptions);
    modal.componentInstance.creditAuthText = this._creditAuthText;
    modal.componentInstance.creditWillBeRunLater = this._creditWillBeRunOfflineLater;
    modal.result.then(() => {
      this.onConsentReceivedForCreditCheck()
    }, error => { });
  }

  //you can only run credit for borrowers that you have selected to apply application for and
  //whose credit reports are not run before
  private borrowersToRunCreditReportFor(): Borrower[] {
    let borrowersToRunCreditReportFor: Borrower[] = [];
    if (!this.isCreditRunForBorrower(this.mainBorrower)) {
      borrowersToRunCreditReportFor.push(this.mainBorrower);
    }
    if (this.inCoApplyFlow) {
      return borrowersToRunCreditReportFor;
    }
    for (let b of this.coBorrowers) {
      if (this.borrowerSetting(b) && !this.isCreditRunForBorrower(b)) {
        borrowersToRunCreditReportFor.push(b);
      }
    }
    return borrowersToRunCreditReportFor;
  }

  private onConsentReceivedForCreditCheck = () => {

    if (this.wizardFlowService.isEditEnabled) {
      this.goToNextStep();
      return;
    }

    const today = formatDate(new Date(), 'MM/dd/yyyy', 'en-US');
    this.mainBorrower.authorizedCreditCheck = true;
    this.mainBorrower.dateAuthorizedCreditCheck = today;
    this.mainBorrower.authorizationMethod = "Internet";

    this.coBorrowers.forEach(coBorrower => {
      if (this.borrowerSetting(coBorrower)) {
        coBorrower.authorizedCreditCheck = true;
        coBorrower.dateAuthorizedCreditCheck = today;
        coBorrower.authorizationMethod = "Internet";
      }
    });

    this.startSpinner();
    this.wizardFlowService.saveMortgage().subscribe(result => {
      if (this._creditAuthGenerationEnabled) {
        this._creditService.createConsentReport(this.mortgageApplication.applicationId, this._creditAuthText).subscribe(() => {
          if (!this._creditWillBeRunOfflineLater) {
            this.doRunCreditReport();
          } else {
            this.stopSpinner();
            this.goToNextStep();
          }
        }, err => {
          //this.handleError("An error occurred while generating consent document for credit run.", err.error.message);
          this.stopSpinner();
          this.goToNextStep();
        })
      } else {
        if (!this._creditWillBeRunOfflineLater) {
          this.doRunCreditReport();
        } else {
          this.stopSpinner();
          this.goToNextStep();
        }
      }
    }, error => {
      this.handleError("An error occurred while saving the mortgage", error.error.message);
    });
  }

  private doRunCreditReport = () => {
    const borrowerContactIds = this.borrowersToRunCreditReportFor().map(b => b.contactId);
    const borrowerIds = this.borrowersToRunCreditReportFor().map(b => b.borrowerId);
    if (!borrowerIds.length) {
      return;
    }
    this._creditService.runCreditReports(this.mortgageApplication.applicationId, borrowerContactIds,
      this.wizardFlowService.context.config?.defaultCreditVendor, 
      this.wizardFlowService.context.config?.defaultReportType, 
      this.wizardFlowService.context.config?.pullEquifax, 
      this.wizardFlowService.context.config?.pullExperian, 
      this.wizardFlowService.context.config?.pullTransUnion).pipe(
        finalize(() => {
          this.stopSpinner();
          for (let i = 0; i < borrowerIds.length; i++) {
            let borrowerStepStatus = this.wizardFlowService.context.borrowerAppStatus.details.borrowerStepStatuses.find(b => b.borrowerId == borrowerIds[i]);
            if (!borrowerStepStatus) {
              borrowerStepStatus = new BorrowerStepStatus(borrowerIds[i]!);
              borrowerStepStatus.creditRun = true;
              this.wizardFlowService.context.borrowerAppStatus.details.borrowerStepStatuses.push(borrowerStepStatus);
            }
            else {
              borrowerStepStatus.creditRun = true;
            }
          }
        })
      )
      .subscribe(
        response => {
          this.borrowerCreditScores = response;
          if (response.success) {
            this._customerPreferences.doNotRunCredit = 0;
            this.startSpinner();
            this.mortgageApplicationService.getMortgage(this.mortgageApplication.applicationId).subscribe((mortgage) => {
              this.wizardFlowService.updateContext(mortgage);
              this.stopSpinner();
              this.goToNextStep();
            }, error => {
              this.stopSpinner();
              this.goToNextStep();
              // this.handleError("An error occurred.",
              //   "An error occurred while running credit. Please contact your administrator. You can click continue to skip this step.");
            });
          } else {
            this.goToNextStep();
            // this.handleError("An error occurred.",
            //   "An error occurred while running credit. Please contact your administrator. You can click continue to skip this step.");
          }
        },
        error => {
          // this.handleError("An error occurred.",
          //   "An error occurred while running credit. Please contact your administrator. You can click continue to skip this step.");
        }
      )
  }

  private handleError = (title: string, message: string) => {
    this.stopSpinner();
    let errorMessage = new ErrorMessage(title, message);
    this.showError(errorMessage);
    this.wizardFlowService.context.creditRunFailed = true;
    window.scroll(0, 0);
  }

  private get allBorrowersCreditRunChecked(): boolean {
    const borrowersToRunCredit = this.borrowersToRunCreditReportFor();
    for (let i = 0; i < borrowersToRunCredit.length; i++) {
      const borrowerFound = this.borrowers.find(b => b.borrowerId === borrowersToRunCredit[i].borrowerId);
      if (!borrowerFound || !borrowerFound.permissionToRunCredit) {
        return false;
      }
    }
    return true;
  }
}
