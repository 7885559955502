<ng-template #homeownershipEducation>
    <div class="card shadow-sm mb-5">
      <div class="card-header">
        <h3 class="card-title">{{mainBorrower.firstName + " " + mainBorrower.lastName}}</h3>
      </div>
      <div class="card-body">
        <borrower-homeownership-education #borrowerHomeownershipEducation [borrower]="mainBorrower"></borrower-homeownership-education>
      </div>
    </div>

    <ng-container *ngIf="!inCoApplyFlow">
      <ng-container *ngFor="let coBorrower of coBorrowers">
        <div *ngIf="borrowerSetting(coBorrower)" class="card shadow-sm mb-5">
          <div class="card-header">
            <h3 class="card-title">{{coBorrower.firstName + " " + coBorrower.lastName}}</h3>
          </div>
          <div class="card-body">
            <borrower-homeownership-education #borrowerHomeownershipEducation [borrower]="coBorrower"></borrower-homeownership-education>
          </div>
        </div>
      </ng-container>
    </ng-container>

  </ng-template>

  <wizard-step-template [stepMainTemplate]="homeownershipEducation" [step]="step" [stepComponent]="this"></wizard-step-template>
