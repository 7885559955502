<div class="wizard wizard-1 " data-wizard-state="step-first" data-wizard-clickable="false" id="online-application">
  <div class="wizard-nav border-bottom">
    <div class="wizard-steps p-4 p-lg-4" >
      <wizard-horizontal-nav-step *ngFor="let navGroup of navGroups; let i = index"
        [inEditMode]="inEditMode"
        class="wizard-step"
        (click)="onStepClicked(navGroup)"
        [title]="navGroup.title"
        [svgIconName]="navGroup.svgIconName"
        [iconClass]="navGroup.iconClass"
        [index]="i"
        [numberOfTotalSteps]="navGroups.length"
        [state]="getWizardState(navGroup)"
        (stepTitleChanged)="onStepTitleChanged($event)">
      </wizard-horizontal-nav-step>
    </div>
  </div>
  </div>
