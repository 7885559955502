<div class="modal-header">

    <h2>{{header}}</h2>

    <!-- hack to fool ngbModal autofocus -->
    <input type="text" style="display:none" />

    <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" aria-label="Close"
      (click)="activeModal.dismiss('Cross click')">
      <span class="svg-icon svg-icon-2x">
        <close-x-svg-icon></close-x-svg-icon>
      </span>
    </div>

  </div>

  <div class="modal-body py-10 px-lg-17">
    <form #addressHistoryForm="ngForm" class="form" novalidate id="addressHistoryForm" name="addressHistoryForm">
      <div class="me-n7 pe-7">
        <div class="row">
          <address-history2 [residencyAddress]="residencyAddress"></address-history2>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer flex-center">
    <button type="button" class="btn btn-white me-3" (click)="activeModal.close('Close click')">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="onSaveClicked()">Ok</button>
  </div>




