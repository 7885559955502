import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Employment } from '../../models/employment-model';

@Component({
  selector: 'employment-history-dialog',
  templateUrl: 'employment-history-dialog.component.html'
})

export class EmploymentHistoryDialogComponent implements OnInit {

  @ViewChild('employmentInfoForm') employmentInfoForm: NgForm | undefined;

  private _employment!: Employment;

  constructor(public activeModal: NgbActiveModal) { }

  @Input()
  public set employment(employment: Employment) {
    this._employment = employment;
  }

  public get employment(): Employment {
    return this._employment;
  }

  onSaveClicked = () => {
    this.employmentInfoForm?.form.markAllAsTouched();
    if (this.employmentInfoForm?.form.valid) {
      this.activeModal.close(this.employment);
    }
  }

  ngOnInit() { }

  public get header(): string {
    return "Employment History";
  }
}
