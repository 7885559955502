export class RealEstateOwnedItem {
  id: number | undefined;
  address: string | undefined;
  propertyType: string | undefined;
  propertyValue: number | undefined;
  reoOwners: string | undefined;
  isValid: boolean = false;
  isReadOnly: boolean = false;

  constructor(id: number, address: string, propertyType:string,
    propertyValue:number, reoOwners:string, isValid: boolean) {
    this.id = id;
    this.address = address;
    this.propertyType = propertyType;
    this.propertyValue = propertyValue;
    this.reoOwners = reoOwners;
    this.isValid = isValid;
  }
}
