import { IDecideNextStep } from "./next-step-decider.interface";

export class SimpleNextStepDecider implements IDecideNextStep {

    private readonly _nextStepPath: string;

    constructor(nextStepPath: string) {
        this._nextStepPath = nextStepPath;
    }

    nextStepPath = (): string => {
        return this._nextStepPath;
    }
}
