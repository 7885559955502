import { ChangeDetectorRef, Component, Input } from '@angular/core';

@Component({
  selector: 'logo',
  templateUrl: 'logo.component.html'
})
export class LogoComponent {

  @Input()
  logoUrlsToTry: string[] = [];

  @Input()
  height: number | undefined;

  constructor(private cdr: ChangeDetectorRef) {
  }

  private imageRetries: number = 0;

  private detach() {
    this.cdr.detach();
  }

  getImageUrl() {
    if (this.imageRetries > this.logoUrlsToTry.length - 1) {
      return '/assets/images/logo.png';
    }
    return this.logoUrlsToTry[this.imageRetries];
  }

  onImageLoaded() {
    this.detach();
  }

  onImageError() {
    this.imageRetries++;
  }
}
