import { Component, Input, OnInit } from '@angular/core';
import { Liability } from 'projects/borrower-app/src/app/models/mortgage.model';
import { Product, Quote } from 'projects/borrower-app/src/app/models/pricing/pricing-response.model';

@Component({
  selector: 'benefits',
  templateUrl: 'benefits.component.html',
  styleUrls: ['benefits.component.scss']
})
export class BenefitsComponent implements OnInit {

  @Input()
  set liabilitiesToPayOff (liabilities: Liability[]) {
    this._liaibilitiesToPayOff = liabilities;
    this.calculateBenefits();
  }

  @Input()
  set product(product: Product) {
    if (product && product.quotes?.length > 0) {
      this.quote = product.quotes[0];
      this.calculateBenefits();
    }
  }

  @Input()
  set loanAmount (loanAmount: number) {
    this._loanAmount = loanAmount;
    this.calculateBenefits();
  }

  get liabilitiesToPayOff(): Liability[] {
    return this._liaibilitiesToPayOff;
  }

  protected totalDebtToPayOff: number = 0;
  protected totalClosingCosts: number = 0;
  protected totalMonthlyPayment: number = 0;
  protected cashToBorrower: number = 0;
  protected savingsAfterRefinance: number = 0;
  protected quote: Quote;

  private _liaibilitiesToPayOff: Liability[] = [];
  private _loanAmount: number;

  constructor() { }

  ngOnInit() { }

  private calculateBenefits() {
    this.totalDebtToPayOff = this._liaibilitiesToPayOff.reduce((total, liability) => total + liability.unpaidBalance, 0);
    this.totalMonthlyPayment = this._liaibilitiesToPayOff.reduce((total, liability) => total + liability.monthlyPayment, 0);
    this.totalClosingCosts = this.quote?.totalFeesDollars || 0;
    this.cashToBorrower = this._loanAmount - this.totalDebtToPayOff - this.totalClosingCosts;
    this.savingsAfterRefinance = this.totalMonthlyPayment - this.quote?.principalAndInterest;
  }
}
