import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Asset } from '../../models/asset.model';
import { Borrower } from '../../models/borrower-model';
import { Employment } from '../../models/employment-model';
import { MortgageApplication } from '../../models/mortgage-app.model';
import { MortgageApplicationService } from '../../services/mortgage-application.service';

@Component({
  selector: 'borrower-details',
  templateUrl: 'borrower-details.component.html'
})
export class BorrowerDetailsComponent implements OnInit {

  private _assets!: Asset[];
  private _employments!: Employment[];

  private _mortgage: MortgageApplication;

  constructor(private readonly _mortgageApplicationService: MortgageApplicationService) {
    this._mortgage = this._mortgageApplicationService.mortgageApplication;
  }

  @Input()
  borrower!: Borrower;

  @Output()
  borrowerChanged: EventEmitter<Borrower> = new EventEmitter<Borrower>();

  public get assets(): Asset[] {
    return this._assets;
  }

  public get employments(): Employment[] {
    return this._employments;
  }

  ngOnInit() {
    this._assets = this._mortgageApplicationService.getAssetsByBorrowerId(this.borrower.borrowerId!);
    this._employments  = this.borrower.employments;
  }

  onBorrowerChanged = (borrower: Borrower) => {
    this.borrower = borrower;
    this.borrowerChanged.emit(this.borrower);
    this._assets = [...this._assets];
  }

  onAssetEdited = (asset: Asset) => {
    const assetThatChanged = this._mortgage.assets.find(a => a.assetId === asset.assetId);
    if (assetThatChanged) {
      const index = this._mortgage.assets.indexOf(assetThatChanged);
      if (index >= 0) {
        this._mortgage.assets[index] = asset;
      }
    }
  }

  onAssetDeleted = (id: number) => {
    const assetThatIsDeleted = this._mortgage.assets.find(a => a.assetId === id);
    if (assetThatIsDeleted) {
      const index = this._mortgage.assets.indexOf(assetThatIsDeleted);
      if (index >= 0) {
        this._mortgage.assets.splice(index, 1);
      }
    }
  }
}
