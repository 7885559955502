<input type="radio" class="radio-button" name="{{option.name}}" (change)="onOptionSelected(option)" value="{{option.value}}" id="{{option.name}}" />
<label id="label_{{option.name}}" class="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center option-text"
  [ngClass]="{'option-selected': option.selected}" for="{{option.name}}">
  <span class="svg-icon svg-icon-3x me-5">
    <ng-template svgIconHost></ng-template>
    <i *ngIf="option.iconType !== 'svg' && option.iconClass" class="{{option.iconClass}}"></i>
  </span>
  <span class="d-block fw-bold text-start align-self-center">
    <span *ngIf="!inEditMode" class="text-dark fw-bolder d-block fs-4">{{option.text}}</span>
    <input *ngIf="inEditMode" [name]="'option_' + option.name" type="text" class="form-control  form-control-solid fs-2 fw-bolder" [(ngModel)]="option.text" />
    <span *ngIf="!inEditMode" class="text-gray-400 fw-bold fs-6 mt-2" style="min-height: 60px;">{{option.explanation}}</span>
    <input *ngIf="inEditMode" [name]="'option_explanation_' + option.name" type="text"
      class="form-control form-control-solid fs-4 fw-bolder" [(ngModel)]="option.explanation" />
  </span>
</label>
