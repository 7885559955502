<div id="demographics-main">
  <div class="row rounded border mb-6 px-6 py-4" *ngIf="showOptionToNotProvideDemographicsInfo">
    <label class="form-check form-check-inline form-check-solid me-5">
      <input class="form-check-input" name="dontWantToProvideAnyOfTheInfo" type="checkbox"
        [(ngModel)]="dontWantToProvideAnyOfTheInfo" (ngModelChange)="dontWantToProvideAnyInfoCheckChanged()" />
      <span class="fw-bold ps-2 fs-6">I do not want to provide any of the information below.</span>
    </label>
  </div>

  <div class="row rounded border mb-6 px-6 py-4"
    [ngClass]="{'border-danger': !ethnicityHasValidSelection}">
    <label class="col-lg-12 col-form-label required fw-bold fs-4">Stated Ethnicity:</label>
    <div class="col-lg-12 fv-row mb-6">
      <multi-checkbox *ngIf="ethnicityOptions.length > 0" [name]="borrower.borrowerId + '_ethnicity'"
        name="borrower.borrowerId + '_ethnicity'" [options]="ethnicityOptions" [(ngModel)]="ethnicities"
        (change)="etnicityChanged()">
      </multi-checkbox>
    </div>
    <div class="rounded border mt-3 px-6 py-4" *ngIf="ethnicityOptions.length > 0 && isHispanicOrLatinoSelected()">
      <div class="col-lg-12 fv-row mb-4">
        <multi-checkbox *ngIf="hispanicOriginOptions.length > 0" [name]="borrower.borrowerId + '_ethnicityOrigin'"
          [options]="hispanicOriginOptions" [(ngModel)]="ethnicityOrigins" (change)="ethnicityOriginChanged()">
        </multi-checkbox>
      </div>
      <div class="col-lg-6 fv-row mb-6" *ngIf="isOtherSelectedForHispanicOrLatinoEthnicity()">
        <div class="position-relative">
          <input type="text" class="form-control form-control-solid"
            [(ngModel)]="borrower.governmentMonitors.ethnicityOriginOtherDescription" placeholder="Enter origin here"
            name="ethnicityOriginOtherDescription" />
        </div>
      </div>
    </div>
  </div>

  <div class="row rounded border mb-6 px-6 py-4"
    [ngClass]="{'border-danger': !sexHasValidSelection}">
    <label class="col-lg-12 col-form-label required fw-bold fs-4">Stated Sex:</label>
    <div class="col-lg-12 fv-row mb-6">
      <multi-checkbox *ngIf="sexOptions.length > 0" [name]="borrower.borrowerId + '_sex'" [options]="sexOptions"
        [(ngModel)]="sexes" (change)="onSexInformationChanged()">
      </multi-checkbox>
    </div>
  </div>

  <div class="row rounded border mb-6 px-6 py-4"
    [ngClass]="{'border-danger': !raceHasValidSelection}">
    <label class="col-lg-12 col-form-label required fw-bold fs-4">Stated Race:</label>
    <div class="col-lg-12 fv-row mb-6">
      <div class="mt-3">
        <div *ngFor="let race of raceOptions">

          <div class="form-check form-check-inline form-check-solid me-5 mb-5">
            <input id="{{borrower.borrowerId}}_race-{{race.name}}" class="form-check-input" [value]="race.value"
              type="checkbox" name="{{borrower.borrowerId}}_race-{{race.name}}"
              (change)="raceChanged($event, race.value)" [checked]="races.includes(race.value)">
            <label for="{{borrower.borrowerId}}_race-{{race.name}}" class="fw-bold ps-2 fs-6">
              {{race.name}}
            </label>
          </div>

          <div class="col-lg-6 fv-row mb-6" *ngIf="race.value == nativeAmericanRace && isNativeAmericanSelected()">
            <div class="position-relative">
              <input type="text" class="form-control form-control-solid"
                [(ngModel)]="borrower.governmentMonitors.nativeAmericanTribeName"
                title="Print name of enrolled or principal tribe"
                placeholder="Tribe name" name="'tribeName_' + borrower.borrowerId" />
            </div>
          </div>

          <div class="rounded border px-6 py-4 mb-5" *ngIf="race.value == asianRace && isAsianSelected()">
            <div class="col-lg-12 fv-row mb-4">
              <multi-checkbox [name]="borrower.borrowerId + '_asianRaceDesignation'"
                [options]="asianRaceDesignationOptions" [(ngModel)]="raceDesignations"
                (change)="raceDesignationChanged()" *ngIf="asianRaceDesignationOptions.length > 0">
              </multi-checkbox>
            </div>
            <div class="col-lg-6 fv-row mt-4 mb-5" *ngIf="isOtherSelectedForAsianRace()">
              <div class="position-relative">
                <input type="text" class="form-control form-control-solid"
                  [(ngModel)]="borrower.governmentMonitors.otherAsianRace" placeholder="Enter race"
                  name="'otherAsian_' + borrower.borrowerId" />
              </div>
            </div>
          </div>

          <div class="rounded border px-6 py-4 mb-5"
            *ngIf="race.value == pacificIslanderRace && isPacificIslanderSelected()">
            <div class="col-lg-12 fv-row mb-4">
              <multi-checkbox [name]="borrower.borrowerId + '_pacificIslanderRaceDesignation'"
                [options]="pacificIslanderRaceDesignationOptions" [(ngModel)]="raceDesignations"
                (change)="raceDesignationChanged()" *ngIf="pacificIslanderRaceDesignationOptions.length > 0">
              </multi-checkbox>
            </div>
            <div class="col-lg-6 fv-row mb-3 mt-4 mb-5" *ngIf="isOtherSelectedForPacificIslanderRace()">
              <div class="position-relative">
                <input type="text" class="form-control form-control-solid"
                  [(ngModel)]="borrower.governmentMonitors.otherPacificIslanderRace" placeholder="Enter race"
                  name="'otherPacificislander_' + borrower.borrowerId" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
