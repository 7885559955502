<div class="row">
  <div class="col-md-12">
    <ul class="nav nav-stretch nav-line-tabs nav-line-tabs border-transparent fs-5 fw-bold">
      <li class="nav-item mt-2">
        <a [ngClass]="selectedTab == 1 ? 'active' : ''" class="nav-link task-tab-link ms-0 me-10 py-2"
          href="javascript:void(0)" (click)="setSelectedApplicationTasks(1)">
          Outstanding
          <span class="badge rounded-pill bg-danger ms-2">
            {{ filteredApplicationTasks.requested.length }}
          </span>
        </a>
      </li>
      <li class="nav-item mt-2">
        <a [ngClass]="selectedTab == 2 ? 'active' : ''" class="nav-link task-tab-link ms-0 me-10 py-2"
          href="javascript:void(0)" (click)="setSelectedApplicationTasks(2)">
          Submitted
          <span class="badge rounded-pill bg-info ms-2">
            {{
            filteredApplicationTasks.submitted.length +
            filteredApplicationTasks.approved.length
            }}
          </span>
        </a>
      </li>
      <li class="nav-item mt-2">
        <a [ngClass]="selectedTab == 3 ? 'active' : ''" class="nav-link task-tab-link ms-0 me-10 py-2"
          href="javascript:void(0)" (click)="setSelectedApplicationTasks(3)">
          Document Locker
          <span class="badge rounded-pill bg-success ms-2">
            {{ documents.length }}
          </span>
        </a>
      </li>
      <li class="nav-item mt-2" *ngIf="!isAgent">
        <a [ngClass]="selectedTab == 4 ? 'active' : ''" class="nav-link task-tab-link ms-0 me-10 py-2"
          href="javascript:void(0)" (click)="setSelectedApplicationTasks(4)">
          Scenarios
          <span class="badge rounded-pill bg-primary ms-2">
            {{ scenarios.length }}
          </span>
        </a>
      </li>
      <li class="nav-item ms-auto">
        <div class="form-group ms-auto">
          <select [(ngModel)]="selectedBorrowerId" (ngModelChange)="onSelectedBorrowerChanged()" class="form-select">
            <option value="all">Show All Borrowers</option>
            <option [ngValue]="application.myDetails.borrowerId">
              {{ application.myDetails.borrowerName }}
            </option>
            <option [ngValue]="borrower.borrowerId" *ngFor="let borrower of application.otherBorrowersOnLoan">
              {{ borrower.borrowerName }}
            </option>
          </select>
        </div>
      </li>
    </ul>
  </div>
  <div class="col-md-12 mt-3">
    <ng-template #taskCardView class="container">
      <ng-container *ngIf="!isLoadingTasks; else loadingTasks">
        <ng-container *ngIf="selectedApplicationTasks">
          <ng-container *ngFor="let taskGroup of groupedTasks"
            [ngSwitch]="taskGroup[0]?.borrowerTaskType">
            <div *ngSwitchCase="'OnlineApplication'">
              <tasks-online-app [tasks]="taskGroup" [application]="application" [isAgent]="isAgent"></tasks-online-app>
            </div>

            <div *ngSwitchCase="'DigitalAssetVerification'">
              <tasks-assets 
                [tasks]="taskGroup" 
                [application]="application" 
                [showAssetsButton]="!isAgent"
                (openAssetsClicked)="startAssetsVerification($event)">
              </tasks-assets>
            </div>

            <div *ngSwitchCase="'DigitalIncomeEmploymentVerification'">
              <tasks-income-employment
                [tasks]="taskGroup" 
                [application]="application" 
                [showIncomeEmploymentVerificationButton]="!isAgent"
                (startIncomeEmploymentVerificationClicked)="startIncomeEmploymentVerification($event)">
              </tasks-income-employment>
            </div>

            <div *ngSwitchCase="'RequestHoi'">
              <tasks-hoi [tasks]="taskGroup" [application]="application"></tasks-hoi>
            </div>

            <ng-container *ngSwitchCase="'RequestInformation'">
              <tasks-answer-question
                *ngIf="!isAgent"
                [tasks]="taskGroup"
                [application]="application"
                (borrowerAnsweredAQuestion)="onBorrowerAnsweredAQuestion($event)">
              </tasks-answer-question>
            </ng-container>

            <div *ngSwitchDefault>
              <tasks-normal
                [tasks]="taskGroup"
                [application]="application"
                [isBorrowerPortal]="!isAgent"
                [enabledTaskIdForESign]="losEsignTaskIdToSignFirst"
                (openPaymentInfoClicked)="openPaymentInfo($event)"
                (openEsignDocumentClicked)="openEsignDocument($event)"
                (openAddFilesClicked)="openAddFiles($event)"
                (openAttachedDocumentsClicked)="openAttachedDocuments($event)"
                (taskMarkedAsNotApplicable)="onTaskMarkedAsNotApplicable($event)">
              </tasks-normal>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-template #loadingTasks>
        <progress-indicator [loadingMessage]="'Loading tasks...'"></progress-indicator>
      </ng-template>

      <ng-container *ngIf="!isLoadingDocs; else loadingDocs">
        <document-locker-table [documents]="documents" *ngIf="selectedTab === 3"></document-locker-table>
      </ng-container>

      <ng-template #loadingDocs>
        <progress-indicator [loadingMessage]="'Loading documents...'"></progress-indicator>
      </ng-template>

      <ng-container *ngIf="!isLoadingScenarios; else loadingScenarios">
        <scenarios [scenarios]="scenarios" *ngIf="selectedTab === 4"></scenarios>
      </ng-container>

      <ng-template #loadingScenarios>
        <progress-indicator [loadingMessage]="'Loading scenarios...'"></progress-indicator>
      </ng-template>

      <h3 class="text-center my-4" *ngIf="
          selectedTab < 3 &&
          selectedApplicationTasks &&
          selectedApplicationTasks.length === 0 &&
          !isLoadingTasks
        ">
          You have no tasks.
        </h3>
    </ng-template>

    <div *ngIf="selectDisplayMode == 'card'">
      <ng-template [ngTemplateOutlet]="taskCardView"></ng-template>
    </div>
  </div>
</div>
