import { Injectable } from "@angular/core";
import { AuthData } from "../models/auth/auth-data.model";
import { Constants } from "./constants";

const authorizationDataKey: string = Constants.authorization.authorizationDataKey;

@Injectable()
export class LocalStorageService {

  get refreshToken(): string | undefined {
    const authData = this.authorizationData;
    if (authData && authData.refreshToken) {
      return authData.refreshToken;
    }
    return undefined;
  }

  getItem<T>(key: string):T {
    const savedDataJson = localStorage.getItem(key);
    if (!savedDataJson) {
      return undefined;
    }
    return <T>JSON.parse(savedDataJson);
  }

  hasItem<T>(key: string): boolean {
    const savedDataJson = localStorage.getItem(key);
    return savedDataJson != null;
  }

  removeItem<T>(key: string) {
    localStorage.removeItem(key);
  }

  setItem<T>(key: string, item: T) {
    if (item == null || item == undefined) {
      localStorage.setItem(key, null);
      return;
    }
    localStorage.setItem(key, JSON.stringify(item));
  }

  set authorizationData(authData: AuthData) {
    this.setItem<AuthData>(authorizationDataKey, authData);
  }

  get authorizationData(): AuthData {
    return this.getItem<AuthData>(authorizationDataKey)
  }

}
