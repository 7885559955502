<input class="form-control form-control-solid" *ngIf="!isItAForeignCountry; else foreignCountryEditor"
        type="text"
        [ngClass]="{'is-invalid' : streetAddress && streetAddress.touched && streetAddress.invalid}"
        ngx-google-places-autocomplete [options]='options' #placesRef="ngx-places"
        [(ngModel)]="autocompleteInput"
        (onAddressChange)="handleAddressChange($event)"
        (blur)="onAddressChanged()"
        [disabled]="disabled"
        [name]="name"
        [id]="id"
        #streetAddress="ngModel"
        #addressText [required]="required" />
<!-- <div class="invalid-feedback">Address is required, please enter an address.</div> -->
<ng-template #foreignCountryEditor>
  <input class="form-control form-control-solid"
        type="text"
        [ngClass]="{'is-invalid' : streetAddress && streetAddress.touched && streetAddress.invalid}"
        [(ngModel)]="autocompleteInput"
        (blur)="onAddressChanged()"
        [disabled]="disabled"
        [name]="name"
        [id]="id"
        #streetAddress="ngModel"
        #addressText [required]="required" />
</ng-template>
