<div class="d-flex align-items-stretch" *ngFor="let task of tasks | filter: 'taskId'">
  <div class="card w-100 mb-4 p-2 border border-2">
    <div class="card-header py-4 w-100 d-flex align-items-center justify-content-start border-bottom-2">
      <h4 class="w-100 mb-0 d-flex justify-content-between align-items-center">
        <span>Your Homeowners Insurance Quotes</span>

        <div>
          <span class="badge rounded-pill bg-warning text-dark p-3 m-1 task-status-badge" *ngIf="
              task.borrowerFacingNote && task.borrowerTaskStatus == 'Rejected'
            ">Urgent Message</span>
          <span class="badge rounded-pill bg-light text-dark p-3 m-1 task-status-badge" *ngIf="
              task.borrowerFacingNote && task.borrowerTaskStatus != 'Rejected'
            ">New Message</span>
          <span class="badge rounded-pill bg-light text-dark p-3 m-1 task-status-badge"
            *ngIf="task.linkedDocuments && task.linkedDocuments.length">{{ task.linkedDocuments.length }}
            Documents</span>
          <span class="badge rounded-pill bg-light text-dark p-3 m-1 task-status-badge"
            *ngIf="task.borrowerTaskStatus == 'Pending'">Pending</span>
          <span class="badge rounded-pill bg-light text-dark p-3 m-1 task-status-badge"
            *ngIf="task.borrowerTaskStatus == 'Submitted'">Submitted</span>
          <span class="badge rounded-pill bg-warning text-dark p-3 m-1 task-status-badge"
            *ngIf="task.borrowerTaskStatus == 'Rejected'">Rejected</span>
          <span class="badge rounded-pill bg-light text-dark p-3 m-1 task-status-badge"
            *ngIf="task.borrowerTaskStatus == 'Completed'">Completed</span>

        </div>
      </h4>
    </div>
    <div class="card-body">
      <div class="row my-2">
        <div class="w-100 d-block" *ngIf="quotes?.length">
          <div class="border border-dashed border-gray-300 rounded px-7 py-3 mb-6" *ngFor="let quote of quotes">
            <div class="row">
              <div class="col-auto ps-0">
                <img [src]="quote?.logo" class="w-100px rounded" alt="Logo">
              </div>

              <div class="col">
                <div class="text-gray-800 fw-bold">
                  {{ quote.carrierName}}
                </div>
                <div class="text-gray-400 fw-bold">Premium Amount (Annual):
                  <span class="text-gray-900 fw-bold">
                    {{ quote.premiumAmountAnnual}}
                  </span>
                </div>
              </div>

              <div class="col-auto">
                <div class="text-center">
                  <label class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body me-1"
                    title="Choose" *ngIf="!chosenQuoteId" (click)="chooseQuote(quote.quoteID)">
                    <i class="bi bi-check2-square fs-6"></i>
                  </label>
                </div>
                <div>
                  <span class="badge badge-light-info" *ngIf="!chosenQuoteId || quote.quoteID != chosenQuoteId">Quote
                    ID:
                    {{quote.quoteID}}</span>
                  <span class="badge badge-light-info" *ngIf="quote.quoteID == chosenQuoteId">Chosen Quote ID:
                    {{chosenQuoteId}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-100 d-flex justify-content-center my-5" *ngIf="!quotes?.length">
          Quotes are being generated, please check back in a few minutes.
        </div>

      </div>
    </div>
  </div>
</div>
