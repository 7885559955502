import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Borrower } from '../../models/borrower-model';
import { EnumsService } from '../../services/enums.service';

@Component({
  selector: 'borrower-declarations',
  templateUrl: 'borrower-declarations.component.html',
  styleUrls: ['./borrower-declarations.component.scss']
})

export class BorrowerDeclarationsComponent implements OnInit {

  @Input()
  borrower!: Borrower;

  @ViewChild('declarationsForm') declarationsForm: NgForm | undefined;

  constructor(private readonly _enumsService: EnumsService) {
  }

  validate = () => {
    this.declarationsForm?.form.markAllAsTouched();
    if (this.declarationsForm?.form.valid) {
      return true;
    }
    return false;
  }

  public get propertyTypes() {
    return this._enumsService.propertyTypesForDeclarations;
  }

  public get bankruptcyTypes() {
    return this._enumsService.bankruptyTypes;
  }

  public get heldTitleHows() {
    return this._enumsService.heldTitleHows;
  }

  ngOnInit() { }

  occupyPropertyChange = (): void => {
    if (!this.borrower.declarations.occupyProperty) {
      this.borrower.declarations.havePropertiesOwnership = null;
      this.borrower.declarations.typeOfProperty = null;
      this.borrower.declarations.heldTitleHow = null;
    }
  }

  havePropertiesOwnershipChange = (): void => {
    if (!this.borrower.declarations.havePropertiesOwnership) {
      this.borrower.declarations.typeOfProperty = null;
      this.borrower.declarations.heldTitleHow = null;
    }
  }

  borrowedDownPaymentChange = (): void => {
    if (!this.borrower.declarations.borrowedDownPayment) {
      this.borrower.declarations.undisclosedMoneyAmount = null;
    }
  }

  declaredBankruptcyChange = (): void => {
    if (!this.borrower.declarations.declaredBankruptcy) {
      this.borrower.declarations.bankruptcyType = null;
    }
  }
}