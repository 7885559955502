import { ProductSearchRequest } from "./product-search-request-info.model";

export class PricingResponse {
  productSearchRequest: ProductSearchRequest;
  productSearchResult: ProductSearchResult;
}


export class ProductSearchResult {
  pricingVendor: string;
  searchId: string;
  searchTime: string;
  calculatedCltv: number;
  calculatedHcltv: number;
  calculatedLtv: number;
  calculatedTotalLoanAmount: number;
  calculatedUpfrontPmiMipFfGfAmount: number;
  calculatedUpfrontPmiMipFfGfFinancedAmount: number;
  calculatedUpfrontPmiMipFfGfPaidinCash: number;
  calculatedUpfrontPmiMipFfGfPercent: number;
  products: Array<Product>;
}

export class Product implements BaseProduct {
  productId: string;
  productCode: string;
  productName: string;
  investor: string;
  investorId: string;
  abbreviatedName: string;
  amortizationTerm: string;
  amortizationType: string;
  armFirstCap: number;
  armFixedTerm: string;
  armIndex: string;
  armMargin: number;
  armIndexValue: number;
  armLifetimeCap: number;
  armPeriodicCap: number;
  armRelativeCap: number;
  armSubsequentChangePeriod: string;
  atrqmRule: String[];
  description: string;
  documentAgencyNoteType: String[];
  expandedApprovalLevel: string;
  expandedGuidelines: string;
  interestOnly: boolean;
  interestOnlyTerm: number;
  investorProduct: string;
  loanTerm: string;
  loanType: string;
  miRequiredOver80Ltv: string;
  nonStandardLoanTermMinimumTermMonths: number;
  nonStandardLoanTermRequreFullYearTerm: string;
  nonStandardLoanTermSupport: string;
  productType: string;
  secondaryOnly: string;
  securityType: String[];
  quotes: Quote[];
  isEligible: boolean;
  ineligibleReason?: Array<string> = [];
  rateSheetDate: string;

  calculatedValues: ProductCalculatedValues;
  priceStatus: string;
}

export class PricedProduct implements BaseProduct {
  productId: string;
  productCode: string;
  productName: string;
  investor: string;
  investorId: string;
  abbreviatedName: string;
  amortizationTerm: string;
  amortizationType: string;
  armFirstCap: number;
  armFixedTerm: string;
  armIndex: string;
  armIndexValue: number;
  armMargin: number;
  armLifetimeCap: number;
  armPeriodicCap: number;
  armRelativeCap: number;
  armSubsequentChangePeriod: string;
  atrqmRule: Array<string> = [];
  description: string;
  documentAgencyNoteType: Array<string> = [];
  expandedApprovalLevel: string;
  expandedGuidelines: string;
  interestOnly: boolean;
  interestOnlyTerm: number;
  investorProduct: string;
  loanTerm: string;
  loanType: string;
  miRequiredOver80Ltv: string;
  nonStandardLoanTermMinimumTermMonths: number;
  nonStandardLoanTermRequreFullYearTerm: string;
  nonStandardLoanTermSupport: string;
  productType: string;
  secondaryOnly: string;
  securityType: Array<string> = [];
  quotes: Array<Quote>;
  parQuotes: Array<Quote>;
  notesAndAdvisories: Array<string> = [];
  rateSheetDate: string;

  calculatedValues: ProductCalculatedValues;
  priceStatus: string;
}

export interface BaseProduct {
  productId: string;
  productCode: string;
  productName: string;
  investor: string;
  investorId: string;
  abbreviatedName: string;
  amortizationTerm: string;
  amortizationType: string;
  armFirstCap: number;
  armFixedTerm: string;
  armIndex: string;
  armMargin: number;
  armIndexValue: number;
  armLifetimeCap: number;
  armPeriodicCap: number;
  armRelativeCap: number;
  armSubsequentChangePeriod: string;
  atrqmRule: String[];
  description: string;
  documentAgencyNoteType: String[];
  expandedApprovalLevel: string;
  expandedGuidelines: string;
  interestOnly: boolean;
  interestOnlyTerm: number;
  investorProduct: string;
  loanTerm: string;
  loanType: string;
  miRequiredOver80Ltv: string;
  nonStandardLoanTermMinimumTermMonths: number;
  nonStandardLoanTermRequreFullYearTerm: string;
  nonStandardLoanTermSupport: string;
  productType: string;
  secondaryOnly: string;
  securityType: String[];
  quotes: Array<Quote>;
  rateSheetDate: string;
  calculatedValues: ProductCalculatedValues;
  priceStatus: string;
}

export class ProductCalculatedValues {
  totalLoanAmount?: number | null | undefined;
  upfrontPmiMipFfGfAmount?: number | null | undefined;
  upfrontPmiMipFfGfFinancedAmount?: number | null | undefined;
  upfrontPmiMipFfGfPaidinCash?: number | null | undefined;
  upfrontPmiMipFfGfPercent?: number | null | undefined;
}

export class VendorFeatureRequest {
  optimalBlueOriginatorId: number;
  optimalBlueChannelId: number;
  applicationId: number;
  credentialId: number;
  pricingChannelId: string | number;
  encompassTpoOrgId: string;
  encompassBrokerPriceGroup: string;
  encompassDelegatedPriceGroup: string;
  encompassNonDelegatedPriceGroup: string;
}

export class Quote {
  lockPeriod: number;
  baseRate: number;
  basePrice: number;
  adjustedRate: number;
  adjustedPrice: number;
  totalPriceAdjustment: number;
  totalRateAdjustment: number;
  totalMarginAdjustment: number;
  totalSrpAdjustment: number;
  apr: number;
  pricingLastUpdated: string;
  lockExpirationDate: string;
  margin: number;
  principalAndInterest: number;
  monthlyMi: number;
  monthlyInsurance: number;
  monthlyTaxes: number;
  totalPayment: number;
  totalCredit: number;
  loCompensationDollars: number;
  loCompensationPercent: number;
  borrowerPaidClosingCostDollars: number;
  currentFeeCreditDollar: number;
  totalClosingCost: number;
  totalFeesDollars: number;
  totalFeesPercent: number;
  discountDollars: number;
  discountPercent: number;
  rebateDollars: number;
  rebatePercent: number;
  originationDollars: number;
  originationPercent: number;
  lenderFeesDollars: number;
  lenderFeesPercent: number;
  thirdPartyFeesDollars: number;
  thirdPartyFeesPercent: number;
  qmStatus: String[];
  adjustments: Adjustment[];
}

export class Adjustment {
  pricingAdjustmentId: number;
  reason: string;
  description: string;
  adjustmentType: AdjustmentType;
  valueType: AdjustmentValueType;
  isHidden: boolean;
  amount: number;
  applicationId: number;

  companyId: number;
  insertedBy: string;
  dateInserted: Date;
  updatedBy: string;
  dateUpdated: Date;
}


export enum AdjustmentValueType {
  Point = 'Point',
  Rate = 'Rate',
}

export enum AdjustmentType {
  ParameterValueAdjustor = 'ParameterValueAdjustor',
  ProgramNameModifier = 'ProgramNameModifier',
  RateGridModifier = 'RateGridModifier',
  LLPA = 'LLPA',
  Sub = 'Sub',
  Margin = 'Margin',
  MSR = 'MSR',
  TIER = 'TIER',
  OtherLLPA = 'OtherLLPA',
  AgencyLLPA = 'AgencyLLPA',
  AllPriceType = 'AllPriceType',
  CompPlan = 'CompPlan',
  ToFee = 'ToFee',
  PricingException = 'PricingException',
  LockExtension = 'LockExtension',
  CapAdjustment = 'CapAdjustment',
  RME = 'RME',
  Eligibility = 'Eligibility',
  PointRounding = 'PointRounding',
  DescrptiveAdjustment = 'DescrptiveAdjustment',
}
