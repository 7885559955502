import { Injectable } from "@angular/core";
import { ContextExtractor } from "projects/borrower-app/src/app/services/context-extractor";
import { IDecideNextStep } from "projects/borrower-app/src/app/services/wizard/next-step-decider/next-step-decider.interface";
import { WizardFlowContext } from "../../../wizard-flow-context.model";
import { CreateAccountStep } from "../../create-account-step.model";
import { ContextType } from "../context-type.enum";
import { CreateAccountStepConfig } from "../create-account-step-config.model";
import { NextStepDeciderFactory } from "./next-step-decider.factory";
import { StepFactoryBase } from "./step-factory-base";
import { ICreateStep } from "./step-factory.interface";

@Injectable()
export class CreateAccountStepFactory extends StepFactoryBase<CreateAccountStepConfig> implements ICreateStep {

  constructor(private readonly _nextStepDeciderFactory: NextStepDeciderFactory) {
      super();
  }
  configure(step: CreateAccountStep, config: CreateAccountStepConfig): void {
    config.explanation = step.explanation;
    config.title = step.title;
    config.heading = step.heading;
    config.groupId = step.groupId;
  }

  create(config: CreateAccountStepConfig, wizardFlowContext: WizardFlowContext): CreateAccountStep {
    let nextStepDecider: IDecideNextStep | undefined = undefined;
    if (config.nextStepDeciderConfig) {
        nextStepDecider = this._nextStepDeciderFactory.create(config.nextStepDeciderConfig, wizardFlowContext);
    }
    const mortgage = ContextExtractor.extractContext(wizardFlowContext, ContextType.Mortgage);
    const borrower = mortgage.borrowers[0];
    let createAccountStep = new CreateAccountStep(config.id, config.name, config.path, borrower, nextStepDecider);
    createAccountStep.title = config.title;
    createAccountStep.explanation = config.explanation;
    createAccountStep.heading = config.heading;
    return createAccountStep;
  }
}
