
<ng-template #asset>
  <div class="d-flex mb-5" *ngIf="inEditMode">
    <div class="col-sm-6 col-form-label fw-bold fs-6 editor-label">Don't Let User Skip w/o Assets</div>
    <div class="col-sm-2 form-check form-switch form-check-custom form-check-solid">
      <input class="form-check-input h-20px w-30px" type="checkbox"
        name="dontLetUserSkipWithoutAssets" id="dontLetUserSkipWithoutAssets"
        [(ngModel)]="step.dontLetUserSkipWithoutAssets" />
      <label class="form-check-label" for="dontLetUserSkipWithoutAssets">
        {{step.dontLetUserSkipWithoutAssets ? "Yes" : "No"}}
      </label>
    </div>
  </div>
  <div class="fv-row" id="assets-main">
    <asset-list
      [fieldConfig]="step.fieldConfig"
      [assets]="mortgage.assets">
    </asset-list>
    <div class="row">
      <div class="col-xl-12 mb-10">
        <div class="row g-9">
          <div class="d-flex justify-content-center ">
            <label (click)="onAddAssetClicked()" class="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center">
              <span class="svg-icon svg-icon-2x me-5 duotone-home-svg-mobile" style="margin-top: -4px;">
                <duotone-coins-svg-icon></duotone-coins-svg-icon>
              </span>
              <span class="d-block fw-bold text-start">
                <span *ngIf="!inEditMode" class="text-dark fw-bolder d-block fs-5 mb-2">
                  {{ step.addAssetActionLabel || (mortgage.assets.length > 0 ? 'Add Another Asset' : 'Add Asset')}}
                </span>
                <input *ngIf="inEditMode" name="addAssetActionLabel" type="text"
                  class="form-control form-control-solid fs-5 fw-bolder" [(ngModel)]="step.addAssetActionLabel" />
                <span class="text-gray-400 fw-bold fs-6"></span>
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<wizard-step-template [stepMainTemplate]="asset" [stepComponent]="this" [step]="step"></wizard-step-template>

