import { Component, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SubjectProperty } from '../../models/subject-property.model';
import { MortgageTerm } from '../../models/mortgage-term.model';
import { TransactionDetail } from '../../models/transaction-detail.model';
import { EnumsService } from '../../services/enums.service';
import * as _ from 'lodash';
import { ConfigurableFieldHostComponent } from '../configurable-field-host.component';

@Component({
  selector: 'loan-details-dialog',
  templateUrl: 'loan-summary-dialog.component.html'
})

export class LoanSummaryDialogComponent extends ConfigurableFieldHostComponent {

  @ViewChild('loanSummaryDialogForm') loanSummaryDialogForm: NgForm | undefined;

  @Input()
  set subjectProperty(subjectProperty: SubjectProperty) {
    this._subjectProperty = _.cloneDeep(subjectProperty);
  }

  get subjectProperty(): SubjectProperty {
    return this._subjectProperty;
  }

  @Input()
  set mortgageTerm(mortgageTerm: MortgageTerm) {
    this._mortgageTerm = _.cloneDeep(mortgageTerm);
  }

  get mortgageTerm(): MortgageTerm {
    return this._mortgageTerm;
  }

  @Input()
  set transactionDetail(transactionDetail: TransactionDetail) {
    this._transactionDetail = _.cloneDeep(transactionDetail);
  }

  get transactionDetail(): TransactionDetail {
    return this._transactionDetail;
  }

  private _subjectProperty!: SubjectProperty;
  private _mortgageTerm!: MortgageTerm;
  private _transactionDetail!: TransactionDetail

  public purchasePriceAmount!: number;

  constructor(public activeModal: NgbActiveModal,
    private readonly _enumsService: EnumsService) {
    super();
  }

  get loanPurposeTypes(): any[] {
    return this._enumsService.loanPurposeTypes;
  }

  get mortgageTypes(): any[] {
    return this._enumsService.mortgageTypes;
  }

  onSaveClicked = () => {
    this.loanSummaryDialogForm?.form.markAllAsTouched();
    if (this.loanSummaryDialogForm?.form.valid) {
      this.activeModal.close({ subjectProperty: this._subjectProperty, transactionDetail: this._transactionDetail, mortgageTerm: this._mortgageTerm });
    }
  }
}
