import { Type } from "@angular/core";

export class StepTypeConfig {

  path: string;
  component: Type<any>;

  constructor(path: string, component: Type<any>) {
    this.path = path;
    this.component = component;
  }
}
