import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PortalScope } from '../../login/login.component';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'confirm-registration',
  templateUrl: 'confirm-registration.component.html'
})
export class ConfirmRegistrationComponent implements OnInit {

  private _portalScopeSubscription: any;

  constructor(
    private readonly _navigationService: NavigationService,
    private readonly _activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this._portalScopeSubscription = this._activatedRoute.data.subscribe((data) => {
      let portalScope = data["scope"] as PortalScope;
      this._navigationService.navigateToPath("/confirm-account", true, { goToPortal: portalScope || PortalScope.Borrower });
    });
  }

  ngOnDestroy() {
    this._portalScopeSubscription.unsubscribe();
  }
}
