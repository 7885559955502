import { Component, OnInit, Input } from '@angular/core';
import { NavigationService } from '../services/navigation.service';
import { UtilsService } from '../services/utils.service';

@Component({
  selector: 'check-email-notice',
  templateUrl: 'check-email-notice.component.html',
  styleUrls: ['check-email-notice.component.scss']
})

export class CheckEmailNoticeComponent implements OnInit {

  email: string = "";

  possibleLogoUrls: string[] = [];

  constructor(private readonly _navigationService: NavigationService,
              private readonly _utilsService: UtilsService) {
    this.possibleLogoUrls = this._navigationService.possibleLogoUrls;
  }


  ngOnInit() {
    this.email = this._utilsService.getUrlParameter("email");
  }
}
