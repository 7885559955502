import { Component, Injector, OnInit, QueryList, ViewChildren } from '@angular/core';
import { BorrowerCreditScores } from '../../../models/borrower-credit-scores.model';
import { Borrower } from '../../../models/borrower-model';
import { CreditService } from '../../../services/credit.service';
import { BorrowerCreditInquiryComponent } from '../../borrower-credit-inquiry/borrower-credit-inquiry.component';
import { forkJoin, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { WizardStepComponentBase } from '../wizard-step-base.component';

@Component({
  selector: 'credit-inquiry-step',
  templateUrl: 'credit-inquiry-step.component.html'
})

export class CreditInquiryStepComponent extends WizardStepComponentBase<any> implements OnInit {

  @ViewChildren('borrowerCreditInquiry') borrowerComponents: QueryList<BorrowerCreditInquiryComponent> | undefined;

  mainBorrower!: Borrower;
  coBorrowers: Borrower[] = [];
  isLoading: boolean = false;

  constructor(private readonly _injector: Injector,
    private readonly _creditService: CreditService) {
    super(_injector);
    if (this.currentBorrower) {
      this.mainBorrower = this.currentBorrower;
      this.coBorrowers = this.mortgageApplication.borrowers.filter(b => b.primaryEmail.toLocaleLowerCase() !==
        this.mainBorrower.primaryEmail.toLocaleLowerCase());
    }
  }

  public get borrowers(): Borrower[] {
    return this.mortgageApplication.borrowers;
  }

  public borrowerSetting(borrower: Borrower): boolean {
    let result = this.wizardFlowService.context.borrowerSettings.get(borrower.borrowerId);
    if (result) {
      return true;
    } else {
      return false
    }
  }

  onNextClicked() {
    let valid = true;
    this.saveMortgageApplicationBeforeNextStep = false;
    if (this.wizardFlowService.isEditEnabled) {
      super.onNextClicked();
      return;
    }
    if (this.borrowerComponents) {
      for (let borrowerComponent of this.borrowerComponents) {
        valid = borrowerComponent.validate() && valid;
      }
    }
    if (valid) {
      // TODO: Here is the POST that Sean wants for document creation
      const borrowersToApplyFor = this.borrowersToApplyFor();
      let calls: Observable<any>[] = [];
      for (let i = 0; i <= borrowersToApplyFor.length - 1; i++) {
        const creditScore = this.wizardFlowService.creditScores.find(c => c && c.borrowerId == borrowersToApplyFor[i].contactId);
        if (creditScore) {
          let borrowerCreditInquirySaveCall = this._creditService.saveInquiries(borrowersToApplyFor[i].creditInquiry!);
          calls.push(borrowerCreditInquirySaveCall);
        }
      }
      if (calls.length) {
        forkJoin(calls).subscribe((results) => {

        });
      }

      const documentCreationUrl = "Sean to fill here!!";
      this.dataService.post(documentCreationUrl, {});
      super.onNextClicked();
    }
  }

  private getInquiries() {
    let calls: Observable<any>[] = [];
    for (let borrowerScore of this.wizardFlowService.creditScores) {
      if (borrowerScore && borrowerScore.creditID) {
        let getInquiriesCall = this._creditService.getInquiries(borrowerScore.creditID!);
        calls.push(getInquiriesCall);
      }
    }
    if (calls.length) {
      forkJoin(calls).subscribe((results) => {
        for (let i = 0; i <= this.borrowers.length - 1; i++) {
          const borrowerResult = results[i];
          if (borrowerResult) {
            this.borrowers[i].creditInquiry = borrowerResult;
          }
        }
        this.stopSpinner();
        this.isLoading = false;
      });
    }
  }

  ngOnInit() {
    if (this.wizardFlowService.isEditEnabled) {
      return;
    }
    this.startSpinner();
    this.isLoading = true;

    this._creditService.getCreditScores(this.mortgageApplication.applicationId).pipe(
      finalize(() => {
        this.stopSpinner();
      })
    ).subscribe(
      response => {
        let allBorrowerCreditScores: BorrowerCreditScores[] = [];
        let scores = response.filter(r => r.refNumber != null).sort((a, b) =>
          (a.refNumber && b.refNumber && a.refNumber < b.refNumber) ? 1 : -1);
        this.borrowers.forEach(borrower => {
          const borrowerScores = scores.filter(b => b.borrowerId === borrower.contactId);
          if (borrowerScores) {
            allBorrowerCreditScores.push(borrowerScores[0]);
          }
        });
        this.wizardFlowService.creditScores = allBorrowerCreditScores;
        this.getInquiries();
      },
      error => {
        this.isLoading = false;
      }
    );
  }
}
