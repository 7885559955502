<div class="border border-gray-300 border-dashed rounded py-0 px-4 me-2 mt-2 pt-xs-5 mobile-profile-container"
  *ngIf="agent">
  <div class="row">
    <div class="col-md-4 d-flex align-items-center justify-content-center">
      <person-circle-image [url]="agent.agentAvatarUrl" [size]="60"></person-circle-image>
    </div>
    <div class="col-md-8 p-2 text-xs-center">
      <span *ngIf="!!agent.firstName || !!agent.lastName" class="mb-1">
        {{agent?.firstName }}
        {{ agent?.lastName }}
      </span>
      <p class="mb-0 text-xs-center" *ngIf="agent.agentNMLS || agent.companyNMLS">
        <small>
          NMLS#: {{agent.agentNMLS || agent.companyNMLS}}
        </small>
      </p>
      <p class="mb-0 text-xs-center" *ngIf="agent.role">
        <small>Role: {{agent.role}}</small>
      </p>
    </div>
  </div>
  <div class="row text-xs-center">
    <div class="d-flex flex-column">
      <a class="nav-link d-inline pb-0" *ngIf="agent.phoneNumber" href="tel:{{ agent.phoneNumber }}">
        <i class="fa fa-phone me-2 display-unset"></i>{{ agent.phoneNumber | phone }}
      </a>
      <a class="nav-link d-inline pb-0" *ngIf="agent.faxNumber" href="tel:{{ agent.faxNumber }}">
        <i class="fa fa-fax me-2 display-unset"></i>{{ agent.faxNumber | phone }}
      </a>
      <a class="nav-link d-inline pb-0" *ngIf="agent.emailAddress" href="mailto: {{ agent.emailAddress }}">
        <i class="fa fa-envelope me-2 display-unset"></i>{{ agent.emailAddress }}
      </a>
      <div class="d-flex justify-content-{{agent.calendarLink ? 'between' : 'start'}}">
        <a *ngIf="agent.calendarLink" class="nav-link d-inline pb-0" [href]="agent.calendarLink" target="_blank"> <i
            class="fa fa-calendar me-2 display-unset"></i> View Calendar
        </a>
        <a class="nav-link d-inline pb-0" (click)="onAgentNameClicked()" target="_blank" title="Request a call this contact"><i
          class="fa fa-user me-2 display-unset"></i> Contact
        </a>
      </div>
    </div>
    <div class="opacity-80 d-flex align-items-center ps-7" *ngIf="!agentAddressHidden">
      <i class="fas fa-map-marker-alt me-2"></i>
      <span>
        {{ agent.address || "No Street Address" }}
        <span *ngIf="agent.city || agent.state || agent.zip">
          {{agent.city ? agent.city + "," : ""}}{{ agent.state }}{{ agent.zip }}
        </span>
      </span>
    </div>
  </div>
</div>
