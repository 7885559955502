import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { ApplicationForUser } from "../../models/borrower-portal/application-for-user-model";
import { LiveDataTotalModel } from '../../models/borrower-portal/live-data-total-model';
import { PortalContent } from '../../models/borrower-portal/portal-content-model-model';
import { FeatureFlags } from '../../models/feature-flags.model';
import { BasePortalService } from "../../services/base-portal.service";
import { Constants } from '../../services/constants';
import { MortgageApplicationService } from '../../services/mortgage-application.service';
import { NavigationService } from '../../services/navigation.service';
import { NotificationService } from '../../services/notification.service';
import { AgentContactInfo } from '../agent-contact-info/agent-contact-info.component';
import { EConsentDialogComponent } from '../task-information/e-consent-dialog/e-consent-dialog.component';
import * as _ from 'lodash';
import { load_map_and_street_view_from_address } from '../../services/utils';

@Component({
  selector: 'loan-info',
  templateUrl: './loan-info.component.html',
  styleUrls: ['./loan-info.component.scss']
})
export class LoanInfoComponent implements OnInit {

  @Input()
  portalContent: PortalContent;

  @Input()
  set application(application: ApplicationForUser) {
    this._application = application;
    this.initialize();
  }

  get application(): ApplicationForUser {
    return this._application;
  }

  @Input()
  showViewTasksButton: boolean;

  @Input()
  showBorrowerContactDetails: boolean;

  @Input()
  isAgent: boolean = false;

  totals: Record<number, LiveDataTotalModel> = {};

  agentAddressHidden: boolean = true;

  agentContactInfo: AgentContactInfo;

  haveEConsent: boolean = false;

  protected applicationTitle: string = '';

  protected subjectPropertyAddress: string = '';

  private _application: ApplicationForUser;

  constructor(
    private readonly _portalService: BasePortalService,
    private readonly _modalService: NgbModal,
    private readonly _spinner: NgxSpinnerService,
    private readonly _onlineAppService: MortgageApplicationService,
    private readonly _notifsService: NotificationService,
    private readonly _navigationService: NavigationService,
    private readonly _router: Router
  ) { }

  ngOnInit(): void {
    this.loadTotals();
  }

  onAgentNameClicked = () => {
    this._router.navigate([`${this.isAgent ? 'agent' : 'borrower'}-portal/request-a-call`], { queryParams: { loanId: this.application.applicationId } });
  }

  goToCompleteApplication = (app: ApplicationForUser) => {
    const routeToGo = app.onlineApplicationIsCoborrower ? 'co-apply' : 'apply';
    this._router.navigate(
      [routeToGo],
      {
        queryParams: {
          companyGuid: app.companyGuid,
          appId: app.applicationId,
          flowGuid: app.onlineApplicationVersion,
        },
        queryParamsHandling: 'merge'
      });
  }

  goToDetails = (app: ApplicationForUser) => {
    if (!app.forceFinishOnlineApp) {
      let companyGuid = this._navigationService.companyGuid;
      this._spinner.show();
      this._router.navigate([`${this.isAgent ? 'agent' : 'borrower'}-portal/loans`, app.applicationId], { queryParams: { companyGuid: companyGuid } });
    }
  }

  loadTotals = () => {
    this._portalService.getLiveData()
      .subscribe({
        next: (totals) => {
          this.totals = totals.applicationTotals as any;
        },
        error: (err) => {
          console.error(err);
        }
      })
  }

  badgeLabel = (count = 0): string => {
    if (!count) {
      return '';
    }
    return count > 9 ? '9+' : !count ? '' : String(count);
  }

  onHaveEConsentChanged = () => {
    if (this.haveEConsent) {
      this.generateEConsentDoc();
    }
    else {
      Swal.fire({
        title: 'Warning!',
        html: "You are about to revoke your e-consent that you've given earlier for us to process your loan application. If you do so, there might be delays in the processing of your loan application. Do you really want to do this?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        reverseButtons: true,
      }).then((modalResult: any) => {
        if (modalResult.value) {
          this.revokeEConsent();
        }
        else {
          this.haveEConsent = true;
        }
      });
    }
  }

  private generateEConsentDoc = () => {
    const appId = this.application.applicationId;

    this._onlineAppService.getConfig(appId)
      .subscribe({
        next: (config: FeatureFlags) => {
          const eConsentText = config.eConsentText;
          const borrowerId = this.application.myDetails.borrowerId;

          const modalRef = this._modalService.open(EConsentDialogComponent, Constants.modalOptions.large);
          modalRef.componentInstance.loanId = appId;
          modalRef.componentInstance.eConsentText = eConsentText;

          modalRef.result.then((res: boolean) => {
            if (!res) {
              this.haveEConsent = false;
              return alert('E-Consent authorization is required for e-signing your documents.');
            }

            this._spinner.show();

            this._portalService.acceptESignConsent(appId, borrowerId)
              .subscribe({
                next: (acceptConsentRes) => {

                  this._onlineAppService.generateEConsentDocument(appId, { eConsentText: eConsentText, borrowerId: borrowerId })
                    .subscribe({
                      next: (generateDocRes) => {
                        this._spinner.hide();
                        this._notifsService.showInfo("We've successfully received your e-consent.", "E-consent");
                      },
                      error: (error) => {
                        console.error(error.error.message ? error.error.message : 'Document generation failed');
                        this._spinner.hide();
                      }
                    })
                },
                error: (error) => {
                  console.error(error.error.message ? error.error.message : 'Accept E-Sign Consent failed');
                  this._spinner.hide();
                }
              });
          });

        },
        error: (err) => {
          console.error(err);
        }
      })
  }

  private revokeEConsent = () => {
    this._spinner.show();
    this._portalService.revokeEConsent(this.application.applicationId, this.application.myDetails.borrowerId).subscribe({
      next: () => {
        this._spinner.hide();
        this._notifsService.showInfo("Your e-consent has been successfully revoked.", "E-consent");
      },
      error: (error) => {
        console.error(error.error.message ? error.error.message : 'Revoke E-Consent failed');
        this._spinner.hide();
      }
    })
  }

  private loadContactInfo = (appId: number) => {
    if (appId) {
      this._portalService.getContactInfoForApplication(appId).subscribe({
        next: (contactInfo) => {
          if (contactInfo) {
            this.agentContactInfo = contactInfo as unknown as AgentContactInfo;
            this.agentContactInfo.calendarLink = this.agentContactInfo.calendarLink || this.application.contactCalendarLink;
          }
        },
        error: (err) => {
          console.error(err);
        }
      });
    }
  }

  private initialize = () => {
    this.haveEConsent = !!this.application?.myDetails?.acceptedEConsentDate;
    const loanNumber = this.application?.refNumber || this._application?.applicationId;
    this.applicationTitle = `File #${loanNumber}`;

    if (!this.isAgent) {
      if (this.application?.myDetails?.borrowerName) {
        this.applicationTitle += ` - ${this.application.myDetails.borrowerName}`;
      }
    } else {
      if (this.application?.otherBorrowersOnLoan && this.application.otherBorrowersOnLoan.length > 0) {
        const borrowers = _.orderBy(this.application?.otherBorrowersOnLoan, ['borrowerId', 'asc']);
        this.applicationTitle += ` - ${borrowers[0].borrowerName}`;
      }
    }

    this.subjectPropertyAddress = `${this.application?.address || 'No Street Address'} ${this.application?.city ? this.application?.city + "," : ""} ${this.application?.state || ''} ${this.application?.zip || ''}`;

    this.loadContactInfo(this.application.applicationId);

    // TODO: Kaan seems to have checked in accidentally
    //load_map_and_street_view_from_address(this.subjectPropertyAddress);
  }
}
