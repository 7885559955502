export class BorrowerStepStatus {
   borrowerId!: number;
   creditRun!: boolean;

   stepStatusValues: any = {}

   constructor(borrowerId: number) {
     this.borrowerId = borrowerId;
   }
}
