import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from 'projects/shared/modal/modal.service';
import { Borrower } from '../../models/borrower-model';
import { ResidencyAddress } from '../../models/residency-address.model';
import { EnumsService } from '../../services/enums.service';
import { AddressHistoryDialogComponent } from '../address-history-dialog/address-history-dialog.component';
import * as _ from 'lodash';

@Component({
  selector: 'address-history-card',
  templateUrl: 'address-history-card.component.html'
})
export class AddressHistoryCardComponent implements OnInit {
  constructor(private readonly _modalService: ModalService,
    private readonly _enumService: EnumsService) { }

  @Input()
  borrower!: Borrower;

  @Output()
  borrowerChanged: EventEmitter<Borrower> = new EventEmitter<Borrower>();

  private _addressCount!: number;

  private modalOptions: NgbModalOptions = {
    size: "lg",
    backdrop: "static",
    container: "#reviewApplication"
  };

  public get addressCount(): number {
    return this._addressCount;
  }

  onEditClicked = (residencyAddress: ResidencyAddress) => {
    this.showAddressHistoryPopup(residencyAddress);
  }

  public residencyBasisName(residencyAddress: ResidencyAddress) {
    return this._enumService.residencyBasisName(residencyAddress.residencyBasis);
  }

  private showAddressHistoryPopup(residencyAddress: ResidencyAddress) {
    const modal = this._modalService.show(AddressHistoryDialogComponent, this.modalOptions);
    let _residencyAddress = _.cloneDeep(residencyAddress);
    modal.componentInstance.residencyAddress = _residencyAddress;
    modal.result.then((r: ResidencyAddress) => {
      this.onSaveClickedOnAddressHistoryModalForEdit(r)
    }, error => { });
  }

  private onSaveClickedOnAddressHistoryModalForEdit(residencyAddress: ResidencyAddress) {
    let index: number = this.borrower.residencyAddresses.findIndex(a => a.residencyAddressId === residencyAddress.residencyAddressId)!;
    this.borrower.residencyAddresses[index] = residencyAddress;
    this.borrowerChanged.emit(this.borrower);
  }

  ngOnInit() {
    this._addressCount = this.borrower.residencyAddresses.length;
  }
}
