export class Demographics {
  sex: string | undefined ;
  race: string | undefined;
  raceDesignation: string | undefined;
  ethnicity: string | undefined;
  ethnicityOrigin: string | undefined;
  ethnicityOriginOtherDescription: string | undefined;
  otherAsianRace: string | undefined;
  otherPacificIslanderRace: string | undefined;
  nativeAmericanTribeName: string | undefined;
}
