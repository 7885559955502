import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'terms-and-conditions',
  templateUrl: 'terms-and-conditions.component.html'
})

export class TermsAndConditionsComponent implements OnInit {

  possibleLogUrls: string[] = [];

  privacyStatement: string = "";

  termsOfUse: string = "";

  constructor(private readonly _http: HttpClient,
    private readonly _window: Window) {
  }

  ngOnInit() {
    this.getPrivacyStatement();
    this.getTermsOfUse();
  }

  getPrivacyStatement = () => {
    const url = `./assets/privacy-statement.html`;
    this._http.get(url, { responseType: 'text' }).subscribe(data => {
      this.privacyStatement = data;
    });
  }

  getTermsOfUse = () => {
    const url = `./assets/terms-of-use.html`;
    this._http.get(url, { responseType: 'text' }).subscribe(data => {
      const hostName = this._window.location.hostname;
      this.termsOfUse = data.replace('[URL]', hostName);
    });
  }
}
