import { Component, Input } from "@angular/core";
import { ConfigurableFieldHost } from "../models/wizard/configurable-field-host.model";

@Component({
  template: ''
})
export class ConfigurableFieldHostComponent extends ConfigurableFieldHost {

  @Input()
  set fieldsToConfig(value: any) {
    this._fieldsToConfig = value;
  }

  get fieldsToConfig(): any {
    return this._fieldsToConfig;
  }
}
