import { HttpHeaders } from "@angular/common/http";
import { PortalScope } from "projects/borrower-app/src/app/login/login.component";

export class PageUtils {

  static addFavicon(faviconUrl: string, companyGuid?: string): void {
    const favicon: HTMLLinkElement | null = document.querySelector('#customerFavicon');
    if (favicon) {
      const links = Array.from(document.getElementsByClassName('dynamic-favicon'));
      links.forEach(link => link.remove());
      favicon.href = faviconUrl + (companyGuid || "");
    }
  }

  static fixLayoutMarkerBasedOnScope = (userScope: PortalScope) => {
    if (userScope) {
      let layoutPrefix = userScope == PortalScope.Borrower ? 'bp-' : 'ap-';
      let body: HTMLElement | null = document.querySelector('body');
      if (body) {
        body.classList.remove("ap-layout");
        body.classList.remove("bp-layout");
        body.classList.add(layoutPrefix + 'layout');
      }
    }
  }

  static sendHeadersToIFrame = (iframeUrl: string, headers: HttpHeaders, onReadyCallback: any) => {
    var xhr = new XMLHttpRequest();

    xhr.open('GET', iframeUrl);
    xhr.onreadystatechange = handler;
    xhr.responseType = 'blob';
    headers.keys().forEach(key => {
      xhr.setRequestHeader(key, headers.get(key));
    });

    xhr.send();

    function handler() {
      if (this.readyState === this.DONE) {
        if (this.status === 200) {
          // this.response is a Blob, because we set responseType above
          var data_url = URL.createObjectURL(this.response);
          onReadyCallback(data_url);
        } else {
          console.error('no pdf :(');
        }
      }
    }
  }
}
