import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { UserInfo } from 'projects/shared/models/user-info.model';
import { EnvironmentService } from 'projects/shared/services/environment.service';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { Constants } from '../../services/constants';
import { LocalStorageService } from '../../services/local-storage.service';
import { NavigationService } from '../../services/navigation.service';
import { UtilsService } from '../../services/utils.service';
import { WizardFlowServiceBase } from '../../services/wizard/wizard-flow-service.base';
import { MortgageApplicationService } from '../../services/mortgage-application.service';
import { ApplicationForUser } from '../../models/borrower-portal/application-for-user-model';
import { PageUtils } from 'projects/shared/utils/page.utils';

declare const KTMenu: any;

@Component({
  template: ''
})
export abstract class WizardMainBaseComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('template', { static: true }) _template: any;

  title = 'borrower-app';

  userInfo: UserInfo | undefined;

  isDefaultFlow: boolean = false;

  overrideCssUrl: string = null;

  protected industryAffiliations: string[] = [];

  private _routeSubscription: Subscription;
  private _loanInitialidedSubscription: Subscription;

  constructor(protected readonly wizardFlowService: WizardFlowServiceBase,
    protected readonly utilsService: UtilsService,
    protected readonly authService: AuthService,
    protected readonly environment: EnvironmentService,
    protected readonly viewContainerRef: ViewContainerRef,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _localStorageService: LocalStorageService,
    private readonly _navigationService: NavigationService,
    private readonly _mortgageApplicationService: MortgageApplicationService) {

    this._loanInitialidedSubscription = wizardFlowService.loanInitializedEvent.subscribe(loan => {
      this.populateIndustryAffiliations(loan);
    });

    if (this.wizardFlowService.context.applicationInfo) {
      this.populateIndustryAffiliations(this.wizardFlowService.context.applicationInfo);
    }

    this._routeSubscription = this._activatedRoute.paramMap.subscribe((params: ParamMap) => {

      this.initializeContextFromActivatedRouteParams();
      this.overrideCssUrl = this.environment.apiInfo.publicApiBaseUrl + `/api/PublicApi/custom-css/${this.wizardFlowService.companyGuid}/onlineapp`;

      if (this._localStorageService.authorizationData && this._localStorageService.authorizationData.token) {
        let jwtPayload = this.utilsService.parseJwt(this._localStorageService.authorizationData.token);
        if (this.wizardFlowService.companyGuid) {
          if (jwtPayload && jwtPayload.CompanyGuid !== this.wizardFlowService.companyGuid) {
            this.authService.logout();
            this.userInfo = undefined;
            this._navigationService.navigateToLogin(this.wizardFlowService.companyGuid);
            return;
          }
        }
      }
      this.userInfo = this.wizardFlowService.userInfo;
      if (this.isTheVeryBeginningOfTheFlow()) {
        this.initializeFlow();
      }
    })
  }

  ngOnInit() {
    this.viewContainerRef.createEmbeddedView(this._template);
  }

  ngAfterViewInit(): void {
    KTMenu.createInstances();
  }

  ngOnDestroy(): void {
    if (this._routeSubscription) {
      this._routeSubscription.unsubscribe();
    }
    this._loanInitialidedSubscription?.unsubscribe();
  }

  private populateIndustryAffiliations = (loan: ApplicationForUser) => {
    if (loan.industryAffiliations && loan.industryAffiliations !== "Disabled") {
      const affiliations = loan.industryAffiliations.split(',');
      affiliations.forEach(a => a.trim());
      this.industryAffiliations = affiliations;
    }
  }

  private initializeContextFromActivatedRouteParams = () => {
    const loanId = Number(this._activatedRoute.snapshot.queryParams['appId']);
    const companyGuid = this._activatedRoute.snapshot.queryParams['companyGuid'];
    const companyId = Number(this._activatedRoute.snapshot.queryParams['companyId']);
    const userGuid = this._activatedRoute.snapshot.queryParams['userGuid'];
    this._mortgageApplicationService.mortgageApplication.userGuid = userGuid || 'unassigned';

    if (this.wizardFlowService.context.applicationId && this.wizardFlowService.context.applicationId !== loanId) {
      this.wizardFlowService.resetFlows();
    }

    this.wizardFlowService.context.applicationId = loanId;
    this.wizardFlowService.context.companyGuid = companyGuid;
    this.wizardFlowService.context.companyId = companyId;
  }

  private initializeFlow = () => {
    let companyGuidOrId: string | number = "";
    if (this.wizardFlowService.companyGuid || this.wizardFlowService.companyId) {
      companyGuidOrId = this.wizardFlowService.companyGuid ? this.wizardFlowService.companyGuid : this.wizardFlowService.companyId;
    }
    PageUtils.addFavicon(this.environment.faviconUrl, companyGuidOrId.toString());
    var path = this.utilsService.getLastUrlSegment();
    if (path.length === 0) {
      return;
    }
    if (path === Constants.flowEndpoints.register || path === Constants.flowEndpoints.coApply || path === Constants.flowEndpoints.apply) {
      if (path === Constants.flowEndpoints.register && !this.wizardFlowService.isEditEnabled) {
        this.authService.logout();
        this.userInfo = undefined;
      }
      this.wizardFlowService.startFlow(path);
      this.isDefaultFlow = this.wizardFlowService.activeFlowConfig ? this.wizardFlowService.activeFlowConfig.isDefault : false;
    }
  }

  private isTheVeryBeginningOfTheFlow = (): boolean => {
    return ((this._activatedRoute.snapshot as any)?._urlSegment?.segments?.length === 1);
  }
}
