import { Component, Injector, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Borrower } from '../../../models/borrower-model';
import { WizardStepComponentBase } from '../wizard-step-base.component';
import * as _ from 'lodash';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BorrowerCharacteristicsStep } from '../../../models/wizard/borrower-characteristics-step.model';
import { CharacteristicsService } from '../../../services/characteristics.service';
import { TaskCategory } from '../../../models/characteristics/task-category.model';
import { ErrorMessage } from '../../../models/error-message.model';
import { BorrowerCharacteristic } from '../../../models/characteristics/borrower-characteristic.model';
import { LoanDocTask } from '../../../models/tasks/loan-doc-task.model';
import { CharacteristicsPreviewDialogComponent } from '../characteristics-preview-dialog/characteristics-preview-dialog.component';
import { Constants } from '../../../services/constants';
import { CharacteristicsPerBorrowerComponent } from './characteristics-per-borrower/characteristics-per-borrower.component';

@Component({
  selector: 'borrower-characteristics-step',
  templateUrl: 'borrower-characteristics-step.component.html'
})

export class BorrowerCharacteristicsStepComponent extends WizardStepComponentBase<BorrowerCharacteristicsStep> implements OnInit {

  @ViewChildren('characteristics') borrowerCharacteristicsComponents: QueryList<CharacteristicsPerBorrowerComponent> | undefined;

  categories: TaskCategory[] = [];

  isLoading: boolean = false;

  mainBorrower!: Borrower;
  mainBorrowerId: number = 0 ;
  coBorrowers: Borrower[] = [];
  otherBorrowersOnLoan: any[] = [];

  characteristicsPerBorrower: Map<number, BorrowerCharacteristic[]> = new Map<number, BorrowerCharacteristic[]>();

  applicationId: number = 0;

  getCharacteristics = (borrowerId: number | undefined): BorrowerCharacteristic[] => {
    if (!borrowerId) {
      return [];
    }
    const characteristics = this.characteristicsPerBorrower.get(borrowerId);
    if (!characteristics) {
      return [];
    }
    return characteristics;
  }

  constructor(private readonly _injector: Injector,
    private readonly _modalService: NgbModal,
    private readonly _characteristicsService: CharacteristicsService) {
      super(_injector);
      this.saveMortgageApplicationBeforeNextStep = true;
      if (this.currentBorrower) {
        this.mainBorrower = this.currentBorrower;
        if (this.applicationInfo) {
          this.mainBorrowerId = this.applicationInfo.myDetails.borrowerId;
          this.otherBorrowersOnLoan = this.applicationInfo.otherBorrowersOnLoan;
        }
      }
  }

  ngOnInit() {
    if (!this.wizardFlowService.isEditEnabled) {
      this.initializeInNonEditMode();
    } else {
      this.initializeInEditMode();
    }
  }

  onNextClicked = () => {
    if (this.wizardFlowService.isEditEnabled) {
      super.onNextClicked();
      return;
    }
    let characteristics: BorrowerCharacteristic[] = [];
    if (this.borrowerCharacteristicsComponents) {
      this.borrowerCharacteristicsComponents.toArray().forEach(c => {
        characteristics = characteristics.concat(c.characteristics);
      })
    }
    this.startSpinner();
    const onesToSave = characteristics.filter(c => !c.pendingDeletion);
    this._characteristicsService.saveBorrowerCharacteristicsPreview(this.mortgageApplication.applicationId, onesToSave).subscribe((loanDocTasks: LoanDocTask[]) => {
      if (loanDocTasks && loanDocTasks.length > 0) {
        this.showCharacteristicsPreviewDialog(onesToSave, loanDocTasks);
      } else {
        this._characteristicsService.saveBorrowerCharacteristics(this.mortgageApplication.applicationId, onesToSave, []).subscribe(() => {
          this.wizardFlowService.navigateForward();
        }, error => {
          const errorMessage = new ErrorMessage('Error - Borrower Characteristics!', error && error.error ? error.error.message : 'Unable to save borrower characteristics.');
          this.showError(errorMessage);
        }).add(() => {
          this.stopSpinner();
        });
      }
    }, error => {
      const errorMessage = new ErrorMessage('Error - Borrower Characteristics!', error && error.error ? error.error.message : 'Unable to save borrower characteristics preview.');
      this.showError(errorMessage);
    }).add(() => {
      this.stopSpinner();
    });
  }

  private initializeInNonEditMode = () => {
    this.applicationId = this.mortgageApplication.applicationId;
    this.startSpinner();
    this.isLoading = true;
    this._characteristicsService.getTaskCategories().subscribe(categories => {
      this.categories = categories.filter(c => c.characteristicType === "BorrowerCharacteristic" && c.questionText);
      if (!this.categories || !this.categories.length) {
        if (this.cameHereWithBackNavigation) {
          this.wizardFlowService.navigateBackward();
        } else {
          this.onNextClicked();
        }
        return;
      }
      this._characteristicsService.getBorrowerCharacteristics(this.mortgageApplication.applicationId).subscribe(characteristics => {
        this.isLoading = false;
        const borrowerCharacteristics = characteristics.filter(c => c.borrowerId === this.mainBorrowerId);
        this.characteristicsPerBorrower.set(this.mainBorrowerId, borrowerCharacteristics);
        this.otherBorrowersOnLoan.forEach(b => {
          const borrowerCharacteristics = characteristics.filter(c => c.borrowerId === b.borrowerId);
          this.characteristicsPerBorrower.set(b.borrowerId as number, borrowerCharacteristics);
        })
      }, error => {
        this.isLoading = false;
        this.showError(new ErrorMessage('Error - Borrower Characteristics!',
          error && error.error ? error.error.message : 'Unable to get borrower characteristics.')
        );
      });
    }, error => {
      this.showError(new ErrorMessage('Error - Borrower Characteristics!',
        error && error.error ? error.error.message : 'Unable to get borrower characteristics.')
      );
    }).add(() => {
      this.isLoading = false;
      this.stopSpinner();
    })
  }

  private initializeInEditMode = () => {
    const category1 = {onlineAppEnabled: true, questionText: 'Borrower Characteristic1'} as TaskCategory;
    const category2 = {onlineAppEnabled: true, questionText: 'Borrower Characteristic2'} as TaskCategory;
    const category3 = {onlineAppEnabled: true, questionText: 'Borrower Characteristic3'} as TaskCategory;
    this.categories = [category1, category2, category3];
  }

  private showCharacteristicsPreviewDialog = (characteristicsToSave: BorrowerCharacteristic[], tasks: LoanDocTask[]) => {
    const modalRef = this._modalService.open(CharacteristicsPreviewDialogComponent, Constants.modalOptions.xlarge);
    modalRef.componentInstance.borrowers = this.mortgageApplication.borrowers;
    modalRef.componentInstance.characteristicType = 2;
    modalRef.componentInstance.tasksPreview = tasks;
    modalRef.componentInstance.taskCategories = this.categories;

    modalRef.result.then((result) => {
      if (result !== 'cancel') {
        this.saveBorrowerCharacteristics(this.applicationId, characteristicsToSave, result);
      } else {
        // this._notificationService.showWarning(
        //   'Borrower characteristics save operation has been cancelled.',
        //   'Warning!'
        // );
      }
    }, (err) => {
      // this._notificationService.showWarning(
      //   'Borrower characteristics save operation has been cancelled.',
      //   'Warning!'
      // );
    });
  }

  private saveBorrowerCharacteristics = (applicationId: number, characteristics: BorrowerCharacteristic[], loanDocTasks: LoanDocTask[]) => {
    this._characteristicsService.saveBorrowerCharacteristics(applicationId, characteristics, loanDocTasks).subscribe(result => {
      this.wizardFlowService.navigateForward();
    }, error => {
      const errorMessage = new ErrorMessage('Error - Borrower Characteristics!',
      error ? error.message : 'Unable to save borrower characteristics.',);
      this.showError(errorMessage);
    });
  }
}
