import { Type } from "@angular/core";
import { ContextExtractor } from "../../services/context-extractor";
import { IDecideNextStep } from "../../services/wizard/next-step-decider/next-step-decider.interface";
import { SingleChoiceQuestionStepComponent } from "../../wizard/wizard-steps/single-choice-question-step/single-choice-question-step.component";
import { BorrowerStepStatus } from "../borrower-step-status.model";
import { WizardFlowContext } from "../wizard-flow-context.model";
import { ContextType } from "./config/context-type.enum";
import { Option } from "./option.model";
import { SingleChoiceQuestion } from "./single-choice-question.model";
import { WizardStepBase } from "./wizard-step-base.model";
import { OptionsProviderTypes } from "./config/options-providers/options-provider.factory";

export class SingleChoiceQuestionStep extends WizardStepBase {

  question: SingleChoiceQuestion | undefined;

  private readonly _fieldToSetWithAnswer: string;
  private readonly _wizardFlowContext: WizardFlowContext;
  private readonly _contextType: ContextType;

  private _selectedOption: Option | undefined;

  listOptionsVertically: boolean = false;

  optionsProviderType: OptionsProviderTypes | undefined | null;

  nameOfDefaultOptionToSetAndSkip: any | undefined | null = null;

  public get selectedOption(): Option | undefined {
    return this._selectedOption;
  }

  override get requiresNextButton(): boolean {
    return !!this._selectedOption;
  }

  setModelFieldValue = (answer: any) => {
    let model = ContextExtractor.extractContext(this._wizardFlowContext, this._contextType);
    model[this._fieldToSetWithAnswer] = answer;
    this.setBorrowerStatusForStep(answer);
    this.selectOption();
  }

  selectOptionByDefaultIfSetOnModel = () => {
    this.selectOption();
  }

  setModelFieldValueFromStepStatus = () => {
    // get the value from status
    let borrowerStepStatus = this._wizardFlowContext.borrowerAppStatus.details.borrowerStepStatuses.
      find(b => b.borrowerId == this._wizardFlowContext.currentBorrower.borrowerId);
    if (!borrowerStepStatus) {
      return;
    }
    if (borrowerStepStatus.stepStatusValues[this.id]) {
      if (borrowerStepStatus.stepStatusValues[this.id][this._fieldToSetWithAnswer]) {
        this.setModelFieldValue(borrowerStepStatus.stepStatusValues[this.id][this._fieldToSetWithAnswer]);
      }
    }
  }

  public get canMoveToNextStep(): boolean {
    return this._selectedOption !== undefined;
  }

  constructor(id: string, name: string | undefined, path: string, question: SingleChoiceQuestion | undefined,
    nextStepDecider: IDecideNextStep | undefined, fieldToSetWithAnswer: string,
    wizardFlowContext: WizardFlowContext, contextType: ContextType, component: Type<SingleChoiceQuestionStepComponent>) {
    super(id, name, path, nextStepDecider);
    this.component = component;
    this.question = question;
    this._fieldToSetWithAnswer = fieldToSetWithAnswer;
    this._wizardFlowContext = wizardFlowContext;
    this._contextType = contextType;
    this.selectOption();
  }

  private setBorrowerStatusForStep = (answer: any) => {
    let borrowerStepStatus = this._wizardFlowContext.borrowerAppStatus.details.borrowerStepStatuses.find(b => b.borrowerId ==
      this._wizardFlowContext.currentBorrower.borrowerId);
    if (!borrowerStepStatus) {
      borrowerStepStatus = new BorrowerStepStatus(this._wizardFlowContext.currentBorrower.borrowerId!);
      this._wizardFlowContext.borrowerAppStatus.details.borrowerStepStatuses.push(borrowerStepStatus);
    }
    if (!borrowerStepStatus.stepStatusValues) {
      borrowerStepStatus.stepStatusValues = {};
    }
    borrowerStepStatus.stepStatusValues[this.id] = {};
    borrowerStepStatus.stepStatusValues[this.id][this._fieldToSetWithAnswer] = answer;
  }

  private selectOption = () => {
    let model = ContextExtractor.extractContext(this._wizardFlowContext, this._contextType);
    if (!model) {
      return;
    }
    if (this.question) {
      for (let option of this.question.options) {
        option.selected = model[this._fieldToSetWithAnswer] == option.value;
        if (option.selected) {
          this._selectedOption = option;
        }
      }
    }
  }
}
