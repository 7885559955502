import { Injectable } from "@angular/core";
import { DateTime } from "luxon";
import { Subject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class TimerService {

  constructor() { }

  private _timersByName: Map<string, Timer> = new Map<string, Timer>()

  get = (name: string) => {
    return this._timersByName.get(name);
  }

  start = (name: string, expireInMinutes: number, restartIfRunning: boolean = false, restartTimerAfterExpired: boolean = false): Timer => {
    if (this._timersByName.has(name)) {
      const timer = this._timersByName.get(name);
      if (!timer.isRunning) {
        timer.expireInMinutes = expireInMinutes;
        timer.start();
        return timer;
      }
      if ((timer.isExpired && restartTimerAfterExpired) || (restartIfRunning && timer.isRunning)) {
        this.remove(name);
        return this.createAndStartTimer(name, expireInMinutes, restartTimerAfterExpired);
      }
      return timer;
    } else {
      return this.createAndStartTimer(name, expireInMinutes, restartTimerAfterExpired);
    }
  }

  stop = (name: string) => {
    const timer = this._timersByName.get(name);
    if (timer) {
      timer.stop();
    }
  }

  remove = (name: string) => {
    const timer = this._timersByName.get(name);
    if (timer) {
      timer.stop();
      this._timersByName.delete(name);
    }
  }

  createTimer = (name: string) => {
    const timer = new Timer(name, undefined, false);
    this._timersByName.set(name, timer);
    return timer;
  }

  private createAndStartTimer = (name: string, expireInMinutes: number, restartTimerAfterExpired: boolean = false): Timer => {
    const timer = new Timer(name, expireInMinutes, restartTimerAfterExpired);
    this._timersByName.set(name, timer);
    timer.start();
    return timer;
  }
}

export class Timer {

  get name(): string {
    return this._name;
  }

  get startedAt(): DateTime {
    return this._startedAt;
  }

  get isExpired(): boolean {
    return this._isExpired;
  }

  get isRunning(): boolean {
    return this._interval != null;
  }

  set expireInMinutes(value: number) {
    this._expireInMinutes = value;
  }

  ticked: Subject<number>;
  expired: Subject<any>;

  private _name: string;
  private _startedAt: DateTime;
  private _isExpired: boolean = false;
  private _interval: NodeJS.Timeout;
  private _expireInMinutes: number;


  private _restartTimerAfterExpired: boolean = false

  constructor(name: string, expireInMinutes: number, restartTimerAfterExpired: boolean = false) {
    this._name = name;
    this.ticked = new Subject<number>();
    this.expired = new Subject();
    this._restartTimerAfterExpired = restartTimerAfterExpired;
    this._expireInMinutes = expireInMinutes;
  }

  start = () => {
    let totalSecondsToExpiration = (this._expireInMinutes * 60);
    let counter = totalSecondsToExpiration;
    const self = this;
    this._startedAt = DateTime.fromJSDate(new Date());
    const intervalForTimer: NodeJS.Timeout = setInterval(() => {
      counter--;
      self.ticked.next(totalSecondsToExpiration - counter);
      if (counter <= 0) {
        self.expired.next(null);
        self._isExpired = true;
        if (!self._restartTimerAfterExpired) {
          self.stop();
        } else {
          self._isExpired = false;
          counter = totalSecondsToExpiration;
        }
      }
    }, 1000);
    this._interval = intervalForTimer;
  }

  stop = () => {
    if (this._interval) {
      clearInterval(this._interval);
    }
  }

}

