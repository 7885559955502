import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ContactInfoModel } from "../../models/borrower-portal/contact-info-model";
import { BasePortalService } from "../../services/base-portal.service";

@Injectable()
export class BorrowerPortalService extends BasePortalService {

  protected get baseUrl(): string {
    return "BorrowerPortal";
  }

  public getContactInfoForApplication(appId: number): Observable<ContactInfoModel> {
    return this._dataService.get(`api/${this.baseUrl}/GetBorrowerContactInfoForApplication/${appId}`);
  }
}
