import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApplicationTaskModel } from '../../../models/borrower-portal/application-task-model';
import { PaymentInformation } from '../../../models/payment-information.model';
import { PortalServiceFactory } from '../../../services/portal-service.factory';
import { PortalBaseComponent } from '../../portal-base.component';

@Component({
  selector: 'app-payment-info-dialog',
  templateUrl: './payment-info-dialog.component.html',
  styleUrls: ['./payment-info-dialog.component.scss']
})
export class PaymentInfoDialogComponent extends PortalBaseComponent implements OnInit {

  @ViewChild('paymentOrderForm') paymentOrderForm: NgForm | undefined;

  @Input() task: ApplicationTaskModel;

  paymentInfoDetails: PaymentInformation = new PaymentInformation();

  constructor(
    public activeModal: NgbActiveModal,
    private readonly portalServiceFactory: PortalServiceFactory,
    private readonly _spinner: NgxSpinnerService
  ) {
    super(portalServiceFactory);
   }

  ngOnInit(): void {
  }

  save = () => {
    this.paymentOrderForm.form.markAllAsTouched();
    if (!this.paymentOrderForm.form.valid) {
      return;
    }

    this._spinner.show();

    this.portalService.orderPayment(this.task.taskId, this.paymentInfoDetails)
    .subscribe({
      next: (res) => {
        this._spinner.hide();
        alert("Payment information saved successfully.");
        this.activeModal.close();
      },
      error: (err) => {
        console.error(err); // Unable to save payment information
        this._spinner.hide();
      }
    })



  }

}
