
import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { DataService } from './data.service';
import { Observable } from 'rxjs';
import { BorrowerCreditScores } from '../models/borrower-credit-scores.model';
import { Vendor } from '../models/vendor.model';
import { CreditInquiryDetails, CreditInquiryDetailsEntry } from '../models/credit-inquiry-details.model';
import { map } from 'rxjs/operators';
import { LookupEnumItem } from '../models/lookup-enum-item.model';

const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'auth-req': 'true', 'X-Requested-With': 'XMLHttpRequest' });

@Injectable()
export class CreditService {

  constructor(private readonly _dataService: DataService) {
  }

  public generateCreditAuthDocument = (applicationId: number): Observable<any> => {
    const url = `api/online-app/GenerateCreditAuthDocument/${applicationId}`;
    return this._dataService.post(url, {});
  }

  public getCreditScores = (applicationId: number): Observable<BorrowerCreditScores[]> => {
    const url = `api/online-app/credit/credit-history?applicationId=${applicationId}`;
    return this._dataService.get(url);
  }

  public getVendors = (channel: string = null): Observable<Vendor[]> => {
    let url = `api/online-app/credit/credit-vendors`;
    if (channel) {
      url = `${url}?channel=${channel}`;
    }
    return this._dataService.get(url);
  }

  public runCreditReports = (applicationId: number, borrowerIds: (number | undefined)[],
    vendor: string | undefined | null,
    reportType: string | undefined | null,
    pullEquifax: boolean | undefined | null,
    pullExperian: boolean | undefined | null,
    pullTransUnion: boolean | undefined | null): Observable<any> => {
    const url = `api/online-app/credit/pull-report`;
    const payLoad = {
      applicationId: applicationId,
      borrowerIds: borrowerIds,
      pullType: 'Individual',
      creditVendor: !vendor ? 'MeridianLinkSoftPull' : vendor,
      creditRequestType: 'Submit',
      reportType: !reportType ? null : reportType,
      equifax: pullEquifax,
      experian: pullExperian,
      transUnion: pullTransUnion
    };
    //temp pull all three until clients switch over
    if (!payLoad.experian && !payLoad.equifax && !payLoad.transUnion) {
      payLoad.experian = true;
      payLoad.equifax = true;
      payLoad.transUnion = true;
    }
    return this._dataService.post(url, payLoad);
  }

  public getInquiries = (creditId: number): Observable<CreditInquiryDetails> => {
    const url = `api/online-app/credit/${creditId}/inquiries`;
    return this._dataService.get(url);
  }

  public saveInquiries = (creditInquiries: CreditInquiryDetails) => {
    const url = `api/online-app/credit/inquiries?submitESignRequest=true`;
    return this._dataService.post(url, creditInquiries);
  }

  public createConsentReport = (applicationId: number, creditAuthText: string) => {
    const url = `api/online-app/GenerateCreditAuthDocument/${applicationId}`;
    return this._dataService.post(url, { creditAuthText: creditAuthText });
  }

  public generateEConsentDocument = (applicationId: number) => {
    const url = `api/online-app/GenerateEConsentDocument/${applicationId}`;
    return this._dataService.post(url, {});
  }

  public getCreditVendorEnumItems = (): Observable<LookupEnumItem[]> => {
    const url = `api/Lookup/enum/namespace/LE.Lodasoft.Common.Enums.Credit`;
    return this._dataService.post(url, {}).pipe(map((response: any) => {
      return response['lE.Lodasoft.Common.Enums.Credit.CreditVendor'];
    }));
  }
}
