<ng-template #demographics1>
  <div class="d-flex mb-5" *ngIf="inEditMode">
    <div class="col-sm-3 col-form-label fw-bold fs-6 editor-label">Show Option to Not Provide Demographics Info</div>
    <div class="col-sm-2 form-check form-switch form-check-custom form-check-solid">
      <input class="form-check-input h-20px w-30px" type="checkbox"
        name="showOptionToNotProvideDemographicsInfo" id="showOptionToNotProvideDemographicsInfo"
        [(ngModel)]="step.showOptionToNotProvideDemographicsInfo" />
      <label class="form-check-label" for="showOptionToNotProvideDemographicsInfo">
        {{step.showOptionToNotProvideDemographicsInfo ? "Yes" : "No"}}
      </label>
    </div>
  </div>
    <div class="card shadow-sm mb-5">
      <div class="card-header">
          <h3 class="card-title">{{mainBorrower.firstName + " " + mainBorrower.lastName}}</h3>
      </div>
      <div class="card-body">
        <borrower-demographics
          #borrowerDemographics
          [showOptionToNotProvideDemographicsInfo]="step.showOptionToNotProvideDemographicsInfo"
          [borrower]="mainBorrower">
        </borrower-demographics>
      </div>
    </div>

    <ng-container *ngIf="!inCoApplyFlow">
      <ng-container *ngFor="let borrower of coBorrowers">
        <div *ngIf="borrowerSetting(borrower)" class="card shadow-sm mb-5" >
            <div class="card-header">
              <h3 class="card-title">{{borrower.firstName + " " + borrower.lastName}}</h3>
            </div>
            <div class="card-body">
              <borrower-demographics
                [showOptionToNotProvideDemographicsInfo]="step.showOptionToNotProvideDemographicsInfo"
                #borrowerDemographics [borrower]="borrower">
              </borrower-demographics>
            </div>
        </div>
      </ng-container>
    </ng-container>

</ng-template>

<wizard-step-template [stepMainTemplate]="demographics1" [step]="step" [stepComponent]="this"></wizard-step-template>
