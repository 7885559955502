import { Address } from "./address-model";
import { Income } from "./income-model";
import { v4 as uuidv4 } from 'uuid';

export class Employment {
  employmentId: number = 0;
  borrowerId: number | undefined;
  addressId: number | undefined;
  employer: string = "";
  isPrimary: string = "true";
  position: string = "";
  employmentType: string | undefined;
  address: Address;
  employerPhone: string = "";
  isDigitallyVerified: boolean = false;
  yearsOnJob:number | undefined;
  monthsOnJob:number | undefined;
  yearsInLineOfWork: number | undefined;
  selfEmployed: boolean | undefined;
  borrowerOwnershipShare: string | undefined;
  selfEmploymentForm: string | undefined;
  startDate: string | undefined;
  endDate: string | undefined;
  theWorkNumberEmployerCode: number | undefined;
  selfEmploymentMonthlyIncomeOrLoss: number | undefined;
  modelGuid?: string = "";

  incomes: Income[] = [];

  monthlyIncome: number | undefined;
  businessType: string | undefined;

  constructor() {
    this.address = new Address();
    this.modelGuid = uuidv4();
  }
}
