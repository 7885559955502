import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { PasswordResetEmailNoticeComponent } from './auth/password-reset-email-notice/password-reset-email-notice.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { BorrowerPortalEntryComponent } from './borrower-portal-entry/borrower-portal-entry.component';
import { CheckEmailNoticeComponent } from './check-email-notice/check-email-notice.component';
import { CoMortgageApplicationComponent } from './co-mortgage-application/co-mortgage-application.component';
import { ConfirmAccountComponent } from './confirm-account/confirm-account.component';
import { ErrorComponent } from './error/error.component';
import { FlowEditorV2Component } from './flow-editor/flow-editor-main-v2/flow-editor-main-v2.component';
import { LoginComponent } from './login/login.component';
import { MortgageApplicationComponent } from './mortgage-application/mortgage-application.component';
import { ConfirmESignerComponent } from './portals-common/confirm-e-signer/confirm-e-signer.component';
import { RegistrationComponent } from './registration/registration.component';
import { Constants } from './services/constants';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { ApplicationSubmittedComponent } from './wizard/application-submitted/application-submitted.component';
import { ChecklistsStepComponent } from './wizard/wizard-steps/checklists-step/checklists-step.component';
import { EConsentComponent } from './borrower-portal/e-consent/e-consent.component';
import { ESignViaTokenComponent } from './portals-common/e-sign-via-token/e-sign-via-token.component';
import { ESignViaTokenConfirmationComponent } from './portals-common/e-sign-via-token-confirmation/e-sign-via-token-confirmation.component';
import { ShortLinkRedirectComponent } from './short-link-redirect/short-link-redirect.component';
import { CreateAccountExtAuthComponent } from './create-account-external-auth/create-account-external-auth.component';
import { ExternalAuthOnlineAppRedirectComponent } from './external-auth-online-app-redirect/external-auth-online-app-redirect.component';
import { OpenFromAdminComponent } from './portals-common/open-from-admin/open-from-admin.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'sl/:shortCode', component: ShortLinkRedirectComponent},
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'already-submitted', component: ApplicationSubmittedComponent },
  { path: 'error', component: ErrorComponent },
  { path: 'flow-editor-v2', component: FlowEditorV2Component },
  { path: 'checklists', component: ChecklistsStepComponent },
  { path: 'check-email-notice', component: CheckEmailNoticeComponent },
  { path: 'check-email-password-reset-notice', component: PasswordResetEmailNoticeComponent },
  { path: 'confirm-account', component: ConfirmAccountComponent },
  { path: 'create-account-ext-auth', component: CreateAccountExtAuthComponent },
  { path: 'ext-auth-oap-redirect', component: ExternalAuthOnlineAppRedirectComponent },
  { path: 'confirm-registration', component: ConfirmAccountComponent },
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
  { path: 'e-consent', component: EConsentComponent },
  { path: 'open-from-admin', component: OpenFromAdminComponent },
  { path: 'borrower-portal/esign-via-token', component: ESignViaTokenComponent },
  { path: 'borrower-portal/esign-via-token/:loanDocTaskId', component: ESignViaTokenComponent },
  { path: 'borrower-portal/esign-confirmation/:token/:taskNumber/:allTasksCount/:orderId', component: ESignViaTokenConfirmationComponent },
  { path: 'agent-portal/esign-confirmation/:token', component: ESignViaTokenConfirmationComponent },
  { path: 'agent-portal/esign-via-token/:loanDocTaskId', component: ESignViaTokenComponent },
  { path: Constants.flowEndpoints.borrowerPortalEntry, component: BorrowerPortalEntryComponent },
  {
    path: 'register',
    component: RegistrationComponent,
    children: [
    ]
  },
  {
    path: 'apply',
    component: MortgageApplicationComponent,
    children: [
    ]
  },
  {
    path: 'co-apply',
    component: CoMortgageApplicationComponent,
    children: [
    ]
  },
  {
    path: 'borrower-portal',
    loadChildren: () => import('./borrower-portal/borrower-portal.module').then((m) => m.BorrowerPortalModule)
  },
  {
    path: 'agent-portal',
    loadChildren: () => import('./agent-portal/agent-portal.module').then((m) => m.AgentPortalModule)
  },
  {
    path: 'e-sign-confirmation/:id',
    component: ConfirmESignerComponent
  },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '**', redirectTo: '/login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  // imports: [ RouterModule.forRoot(routes, { enableTracing: true }) ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
