

  <div class="modal-header">

    <h2>Personal Info</h2>

    <!-- hack to fool ngbModal autofocus -->
    <input type="text" style="display:none" />

    <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" aria-label="Close"
      (click)="activeModal.dismiss('Cross click')">
      <span class="svg-icon svg-icon-2x">
        <close-x-svg-icon></close-x-svg-icon>
      </span>
    </div>
  </div>

  <div class="modal-body py-10 px-lg-17">
    <form #personalInfoForm="ngForm" class="form" novalidate id="personalInfoForm" name="personalInfoForm">
      <div class="me-n7 pe-7">
        <div class="row mb-5">
          <div class="col-md-6 fv-row">
            <label class="required form-label mb-2">First Name</label>
            <input type="text" class="form-control form-control-solid"
              [ngClass]="{'is-invalid' : firstName && firstName.touched && firstName.invalid}" #firstName="ngModel"
              [(ngModel)]="borrower.firstName" placeholder="" name="firstName" required />
            <div class="invalid-feedback">First name is required.</div>
          </div>
          <div class="col-md-6 fv-row">
            <label class="required form-label mb-2">Last Name</label>
            <input type="text" class="form-control form-control-solid"
             [ngClass]="{'is-invalid' : lastName && lastName.touched && lastName.invalid}" #lastName="ngModel"
             [(ngModel)]="borrower.lastName" placeholder="" name="lastName" required />
           <div class="invalid-feedback">Last name is required.</div>
          </div>
        </div>

        <div class="row mb-5">
          <div class="col-md-6 fv-row">
            <label class="required form-label mb-2">Marital Status</label>
            <select class="form-select form-select-solid" data-control="select2" name="maritalStatus"
            [(ngModel)]="borrower.maritalStatus" #maritalStatus="ngModel" required
            [ngClass]="{'is-invalid' : maritalStatus && maritalStatus.touched && maritalStatus.invalid}">
            <option value="">Select one...</option>
            <option value="Single">Single</option>
            <option value="Married">Married</option>
            <option value="Separated">Separated</option>
            <option value="Other">Other</option>
             </select>
            <div class="invalid-feedback">Please select a value.</div>
          </div>
          <div class="col-md-6 fv-row">
            <label class="required form-label">Phone Number</label>
             <div class="position-relative d-flex align-items-center">
               <input class="form-control form-control-solid"  placeholder="" mask='(000) 000-0000'
                  #phoneNumber="ngModel" required
                  [(ngModel)]="borrower.mobilePhone" name="phoneNumber" ngControl="phoneNumber" id="phoneNumber"
                 [ngClass]="{'is-invalid' : phoneNumber && phoneNumber.touched && phoneNumber.invalid}" />
                 <div class="invalid-feedback">Phone# is required.</div>
            </div>
          </div>
        </div>

        <div class="row">
          <address [address]="address"></address>
        </div>

        <div class="row g-9 mb-5">
          <div class="col-md-6 fv-row">
            <label class="required form-label">Social Security Number</label>
            <div class="position-relative d-flex align-items-center">
              <input class="form-control form-control-solid" matInput mask="000-00-0000" [showMaskTyped]="true"
                [(ngModel)]="borrower.socialSecNum" #socialSecNum="ngModel" [specialCharacters]="[ '-' ]"
                [dropSpecialCharacters]="false" [patterns]="ssnPattern" placeholder="Social Security Number" name="ssn"
                required [ngClass]="{'is-invalid' : socialSecNum && socialSecNum.touched && socialSecNum.invalid}" />
                <div class="invalid-feedback">SSN is required.</div>
            </div>
          </div>
          <div class="col-md-6 fv-row">
            <label class="required form-label">Birth Date</label>
            <div class="position-relative d-flex align-items-center">
              <input class="form-control form-control-solid" matInput mask="M0/d0/0000" [showMaskTyped]="true" inputmode="numeric"
               [specialCharacters]="[ '/' ]" [dropSpecialCharacters]="false" datePicker #birthDate="ngModel" required
                [(ngModel)]="borrower.dateOfBirth" placeholder="" inputmode="numeric" name="birthDate" ngControl="birthDate" id="birthDate"
                [ngClass]="{'is-invalid' : birthDate && birthDate.touched && birthDate.invalid}" />
              <div class="invalid-feedback">Birth date is required.</div>
            </div>
          </div>
        </div>
        <div>
          <div class="row g-9 mb-5">
            <div class="col-md-6 fv-row">
              <label class="required form-label">Citizenship</label>
              <select class="form-select form-select-solid" required data-control="select2" name="citizenship"
                [(ngModel)]="borrower.declarations!.residenceStatus!" #residenceStatus="ngModel"
                 [ngClass]="{'is-invalid' : residenceStatus && residenceStatus.touched && residenceStatus.invalid}">
                 <option value="" disabled>Select one...</option>
                 <option value="USCitizen">US Citizen</option>
                 <option value="PermanentResident">Permanent Resident</option>
                 <option value="NonPermanentResidentAlien">Non-Permanent Resident Alien</option>
               </select>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer flex-center">
    <button type="button" class="btn btn-white me-3" (click)="activeModal.dismiss()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="onSaveClicked()">Ok</button>
  </div>




