<div style="min-height: 500px;">
  <div class="w-100">
    <div class="pb-10 pb-lg-15">
      <h2 class="fw-bolder d-flex align-items-center text-dark">Will you be filling out the application for them?
      </h2>
    </div>
    <div class="fv-row mb-10">
      <div class="row mb-2" data-kt-buttons="true">
        <div class="col">
          <label class="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4" [ngClass]="{'active': willFileForCoBorrowers}">
            <input type="radio" class="btn-check" name="yes" id="yes" (click)="onYesSelected()"/>
            <span class="fw-bolder fs-3">Yes</span>
          </label>
        </div>
        <div class="col">
          <label class="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4" [ngClass]="{'active': !willFileForCoBorrowers}">
            <input type="radio" class="btn-check" id="no" name="no" (click)="onNoSelected()"/>
            <span class="fw-bolder fs-3">No, send them a link</span>
          </label>
        </div>
      </div>
    </div>

    <wizard-button-group></wizard-button-group>
  </div>
</div>
