<div class="post d-flex flex-column-fluid">
  <div class="container">
    <div class="card" style="margin-top: 15px; height: 97vh" *ngIf="!readyToSign">
      <div class="card-body p-0 esign-container">
        <div class="card-px"
          [ngClass]="{'eConsent-container': !isLoading && needEconsent && !alert, 'eConsent-container-alert': !isLoading && needEconsent && alert}">
          <ng-container *ngIf="alert">
            <div *ngTemplateOutlet="alertTemplate"></div>
          </ng-container>
          <ng-container *ngIf="isLoading">
            <div *ngTemplateOutlet="progressTemplate"></div>
          </ng-container>
          <ng-container *ngIf="!isLoading && needToValidateLoan">
            <div *ngTemplateOutlet="askForInfoTemplate"></div>
          </ng-container>
          <ng-container *ngIf="!isLoading && needEconsent">
            <div *ngTemplateOutlet="econsentTemplate"></div>
          </ng-container>
        </div>
      </div>
    </div>
    <ng-container *ngIf="!isLoading && readyToSign">
      <div *ngTemplateOutlet="esignTemplate"></div>
    </ng-container>
  </div>
</div>

<ng-template #alertTemplate>
  <div class="alert bg-light-danger border border-danger border-dashed d-flex flex-column flex-sm-row w-100 p-5 mb-10">
    <i class="fas fa-exclamation-triangle fs-2hx text-danger me-4 mb-5 mb-sm-0"></i>
    <div class="d-flex flex-column pe-0 pe-sm-10">
      <h5 class="mb-1">{{ alert.title }}</h5>
      <span>{{ alert.message }}</span>
    </div>
  </div>
</ng-template>

<ng-template #progressTemplate>
  <div class="d-flex">
    <div class="spinner-border m-5 text-success" style="width: 2.75rem; height: 2.75rem" role="status"></div>
    <h4 style="margin-top: 24px">{{ loadingMessage }}</h4>
  </div>
</ng-template>

<ng-template #askForInfoTemplate>
  <form #loanValidationForm="ngForm" class="form" novalidate id="loanValidationForm" name="loanValidationForm">
    <div class="info-entry-container">
      <div class="fv-row mb-3">
        <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
          <span>Enter Property Zip:</span>
        </label>
        <div class="position-relative">
          <input style="width: 300px" #enteredZipCode="ngModel" type="text" [dropSpecialCharacters]="false"
            [validation]="false" [pattern]="zipCodePattern" [mask]="zipCodeMask" [placeholder]="zipCodePlaceHolder"
            [name]="'zipCode'" [id]="'zipCode'" [required]="isPropertyZipRequired" [(ngModel)]="subjectPropertyZipCode"
            (keydown.enter)="onSubmitClicked()" class="form-control form-control-solid" [ngClass]="{
            'is-invalid':
              enteredZipCode && enteredZipCode.touched && enteredZipCode.invalid
          }" />
          <div class="invalid-feedback">Please enter a zip code.</div>
        </div>
      </div>
      <div class="fv-row mb-3" *ngIf="userScope === 'Borrower' && isLast4DigitsOfSsnRequired">
        <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
          <span>Enter Last 4 Digits of SSN:</span>
        </label>
        <div class="position-relative">
          <input class="form-control form-control-solid" [showMaskTyped]="true" [(ngModel)]="borrowerSsn"
            #socialSecNum="ngModel" [dropSpecialCharacters]="false" [mask]="ssnMask" [pattern]="ssnPattern"
            placeholder="Last 4 of SSN" name="ssn" id="ssn" (keydown.enter)="onSubmitClicked()"
            [required]="isLast4DigitsOfSsnRequired" [ngClass]="{
            'is-invalid':
              socialSecNum && socialSecNum.touched && socialSecNum.invalid
          }" />
          <div class="invalid-feedback">
            Please enter last 4 digits of your SSN.
          </div>
        </div>
      </div>
      <div class="fv-row">
        <a (click)="onSubmitClicked()" class="btn btn-success">Submit</a>
      </div>
    </div>

  </form>
</ng-template>

<ng-template #esignTemplate>
  <div style="width: 100%; height: 100vh">
    <iframe *ngIf="
        signerViewUrl &&
        (!documentSigningVendor || documentSigningVendor === 'DocuSign')
      " [src]="signerViewUrl" width="100%" height="100%">
    </iframe>

    <iframe *ngIf="signerViewUrl && documentSigningVendor === 'DocMagic'"
      sandbox="allow-forms allow-scripts allow-same-origin allow-downloads allow-popups"
      allow="camera; microphone; display-capture; fullscreen" [src]="signerViewUrl" width="100%" height="100%">
    </iframe>
  </div>
</ng-template>

<ng-template #econsentTemplate>
  <iframe id="econsent-text-iframe" sandbox="allow-forms allow-scripts allow-same-origin allow-downloads allow-popups"
    allow="camera; microphone; display-capture; fullscreen" width="100%" height="100%">
  </iframe>
  <h2 class="anchor fw-bolder my-2" id="layout" style="margin-left: 29%;">
    <span> Consent to Do Business Electronically </span>
  </h2>
  <div class="col-lg-12 mb-3" style="margin-left: 29%;">
    <div class="d-flex align-items-center mt-3">
      <form #loanValidationForm="ngForm" class="form" novalidate id="loanValidationForm" name="loanValidationForm">
        <label class="form-check form-check-inline form-check-solid me-5">
          <input class="form-check-input" required name="consentToDoBusinessCheckbox" #consent="ngModel" [ngClass]="{
              'is-invalid': consent && consent.touched && consent.invalid
            }" [(ngModel)]="econsentGiven" type="checkbox" />
          <span class="fw-bold ps-2 fs-6 required">I/We Consent</span>
          <div class="invalid-feedback">Consent is required</div>
        </label>
      </form>
    </div>
  </div>
  <a (click)="onEconsentSubmitClicked()" class="btn btn-success" style="margin-left: 29%;">Submit</a>
</ng-template>