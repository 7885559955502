import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { SubjectProperty } from '../../models/subject-property.model';
import { EnumsService } from '../../services/enums.service';

@Component({
  selector: 'subject-property-summary',
  templateUrl: 'subject-property-summary.component.html'
})
export class SubjectPropertySummaryComponent implements OnInit {
  constructor(private readonly _enumsService: EnumsService) { }

  @Input()
  subjectProperty!: SubjectProperty;

  @Output()
  subjectPropertyDetailsClicked: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() { }

  onSubjectPropertyDetailsClicked = () => {
    this.subjectPropertyDetailsClicked.emit();
  }

  public get propertyType(): string {
    return this._enumsService.propertyTypeName(this.subjectProperty.propertyType);
  }

  public get propertyWillBe(): string {
    return this._enumsService.propertyWillBeTypeName(this.subjectProperty.propertyWillBe);
  }
}
