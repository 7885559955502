<div class="w-100">
  <h3 class="my-2 d-flex justify-content-center">
    <span style="max-width: 500px" class="text-gray-700 fs-4 fw-bold"> How much will your down payment be? </span>
  </h3>
  <div class="my-2 d-flex justify-content-center">
    <div style="max-width: 500px">
      <form #downPaymentForm="ngForm" novalidate class="form-horizontal">
        <div class="row p-4 mb-3">
          <div class="form-group mb-3 col-md-12">
            <currency-input
              #downPaymentNumberInput="ngModel"
              [(ngModel)]="request.downPayment"
              name="purchasePriceNumber"
              [allowDecimals]="false"
              [min]="minDownPaymentValue"
              [max]="maxDownPaymentValue"
              [placeholder]="'Purchase Price'"
            ></currency-input>

            <input
              type="range"
              class="form-control-range w-100"
              [(ngModel)]="request.downPayment"
              name="downPaymentRange"
              [min]="minDownPaymentValue"
              [max]="maxDownPaymentValue"
              step="5000"
              required
              #downPaymentRangeInput="ngModel"
              [ngClass]="{
                'is-invalid':
                  downPaymentRangeInput &&
                  downPaymentRangeInput.touched &&
                  downPaymentRangeInput.invalid
              }"
            />
          </div>
          <small class="text-center text-muted"
            >Use the slide to adjust down payment, or simply type in the
            value.</small
          >
        </div>
      </form>
    </div>
  </div>

  <div class="d-flex justify-content-between my-2">
    <span>
      <a
        href="javascript:void(0)"
        class="btn btn-light"
        (click)="closeClicked()"
      >
        Close
      </a>
    </span>
    <span>
      <a
        href="javascript:void(0)"
        class="btn bp-primary-bg-color me-2"
        (click)="backClicked()"
      >
        Back
      </a>
      <a
        href="javascript:void(0)"
        class="btn bp-primary-bg-color"
        (click)="nextClicked()"
      >
        Next
      </a>
    </span>
  </div>
</div>
