<div class="card card h-100 price-card" (click)="onCardClicked()" *ngIf="quote" style="border: 1px solid lightgray;"
  [ngClass]="{'inactive-box cursor-pointer': !isSelected, 'mb-3': isMobile}">
  <div class="card-body p-9">
    <div class="d-flex">
      <div class="fs-1 fw-bold flex-grow-1 mb-5 rate-apr">
        <span>{{quote.adjustedRate ? ((quote.adjustedRate / 100) |
          percent:'1.3-3'):
          'Unknown'}}
        </span>
      </div>
    </div>

    <div class="fs-6 d-flex justify-content-between my-4">
      <div class="fw-semibold">Points</div>
      <div class="d-flex fw-bold ps-6">
        <span *ngIf="quote.adjustedPrice >= 100.00" class="text-muted price-rebate">{{quote.rebatePercent}} /
          {{(quote.rebateDollars | currency)}}</span>
        <span *ngIf="quote.adjustedPrice < 100.00" class="text-muted price-discount">{{quote.discountPercent}} /
          {{(quote.discountDollars | currency)}}</span>
      </div>
    </div>

    <div class="separator separator-dashed"></div>

    <div class="fs-6 d-flex justify-content-between my-4">
      <div class="fw-semibold">Rate/APR</div>

      <div class="d-flex fw-bold">
        <span>{{quote.adjustedRate ? ((quote.adjustedRate / 100) |
          percent:'1.3-3'):
          'Unknown'}} / {{quote.apr ? ((quote.apr / 100) | percent:'1.3-3') : 'Unknown'}}
        </span>
      </div>
    </div>

    <div class="separator separator-dashed"></div>

    <div class="fs-6 d-flex justify-content-between mt-4">
      <div class="fw-semibold">Monthly P&I</div>

      <div class="d-flex fw-bold">
        <span>{{quote.principalAndInterest | currency: 'USD': 'symbol'}}</span>
      </div>
    </div>
  </div>
</div>