import { IDecideNextStep } from "../../services/wizard/next-step-decider/next-step-decider.interface";
import { AssetsStepComponent } from "../../wizard/wizard-steps/assets-step/assets-step.component";
import { WizardStepBase } from "./wizard-step-base.model";

export class AssetsStep extends WizardStepBase {

  dontLetUserSkipWithoutAssets: boolean = false;

  addAssetActionLabel: string;

  constructor(id: string, name: string, path: string, nextStepDecider?: IDecideNextStep) {
    super(id, name, path, nextStepDecider);
    this.component = AssetsStepComponent;
  }
}
