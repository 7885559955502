import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { PricingScenario } from '../../../models/pricing/pricing-scenario.model';
import { ScenarioCardComponent } from '../pinned-scenario-card/scenario-card.component';

@Component({
  selector: 'scenarios',
  templateUrl: 'scenarios.component.html',
  styleUrls: ['./scenarios.component.scss']
})
export class ScenariosComponent implements OnInit {

  @ViewChildren("scenarioCard")
  scenarioCards: QueryList<ScenarioCardComponent>;

  @Input()
  applicationId: number | null;

  @Input()
  set scenarios(scenarios: PricingScenario[]) {
    this._scenarios = scenarios;

    for (let i = 0; i < this.scenarios.length; i++) {
      this._scenarios[i]['index'] = i + 1;
    }
  }

  get scenarios(): PricingScenario[] {
    return this._scenarios;
  }

  private _scenarios: PricingScenario[] = [];

  constructor() {
  }

  ngOnInit() {
  }

}
