<ng-template #militaryService>
  <div class="card shadow-sm mb-5">
    <div class="card-header">
      <h3 class="card-title">{{mainBorrower.firstName + " " + mainBorrower.lastName}}</h3>
    </div>
    <div class="card-body">
      <borrower-military-service #borrowerMilitaryService [borrower]="mainBorrower"></borrower-military-service>
    </div>
  </div>

  <ng-container *ngIf="!inCoApplyFlow">
    <ng-container *ngFor="let coBorrower of coBorrowers">
      <div *ngIf="borrowerSetting(coBorrower)" class="card shadow-sm mb-5">
        <div class="card-header">
          <h3 class="card-title">{{coBorrower.firstName + " " + coBorrower.lastName}}</h3>
        </div>
        <div class="card-body">
          <borrower-military-service [borrower]="coBorrower"></borrower-military-service>
        </div>
      </div>
    </ng-container>
  </ng-container>

</ng-template>

<wizard-step-template [stepMainTemplate]="militaryService" [step]="step" [stepComponent]="this"></wizard-step-template>
