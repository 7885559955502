import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'footer',
  templateUrl: 'footer.component.html'
})

export class FooterComponent implements OnInit {

  @Input()
  isDefaultFlow: boolean = false;

  @Input()
  industryAffiliations: string[] = [];

  currentYear: number = new Date().getFullYear();

  constructor() { }

  ngOnInit() { }
}
