import { ApplicationContextService } from "projects/borrower-app/src/app/services/application-context.service";
import { Option } from "../../option.model";
import { IProvideOptions } from "./options-provider.interface";
import { Injectable } from "@angular/core";
import { Observable, Subscription, map } from "rxjs";

@Injectable()
export class LoanPurposeOptionsProvider implements IProvideOptions {

  private _appContextSubscription: Subscription;

  constructor(private readonly _applicationContextService: ApplicationContextService) {
  }

  getOptions(): Observable<Option[]> {
    return this._applicationContextService.context.pipe(map(context => {
      return context.globalConfig.loanPurpose.map(lp => {
        return new Option(lp.loanPurposeName, lp.loanPurposeName, "", lp.mortgageLoanPurpose || "", "");
      })
    }))
  }

  ngOnDestroy() {
    this._appContextSubscription?.unsubscribe();
  }
}
