import { Option } from "./option.model";

export class SingleChoiceQuestion {
    text: string = "";
    explanation: string = "";
    options: Option[] = [];

    constructor(text: string, explanation: string, options: Option[]) {
        this.text = text;
        this.explanation = explanation;
        this.options = options;
    }
}
