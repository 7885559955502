import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExpressionNextStepDeciderConfig } from '../../models/wizard/config/expression-next-step-decider-config.model';
import { Graph } from '../../models/wizard/config/graph.model';
import { NextStepDeciderType } from '../../models/wizard/config/next-step-decider-type.enum';
import { GraphNode } from '../../models/wizard/config/node.model';
import { SimpleNextStepDeciderConfig } from '../../models/wizard/config/simple-next-step-decider-config.model';
import { WizardFlowConfig } from '../../models/wizard/config/wizard-flow-config.model';
import { WizardFlowConfigServiceBase } from '../../services/wizard/wizard-flow-config-service.base';

declare const $: any;
import * as d3 from "d3";
import { StepConfig } from '../../models/wizard/config/step-config.model';
import { stepGroupColors } from '../step-group-colors';

const DEFAULT_COLOR: string = '#1f77b4';

@Component({
  selector: 'flow-summary',
  templateUrl: 'flow-summary.component.html'
})
export class FlowSummaryComponent implements OnInit {

  constructor(private readonly _flowConfigService: WizardFlowConfigServiceBase) { }

  private _flow: WizardFlowConfig | undefined;

  @Input()
  public set flow(value: WizardFlowConfig) {
    this._flow = value;
    let graph: Graph = this.generateGraphData(this._flow);
    this.renderFlowSummary(graph);
  }

  @Output()
  stepSelected: EventEmitter<StepConfig> | undefined = new EventEmitter<StepConfig>();

  redraw = (flow: WizardFlowConfig) => {
    $("#flow-summary-view").empty();
    this.flow = flow;
  }

  ngOnInit() {
  }

  private generateGraphData = (flow: WizardFlowConfig): Graph => {
    let graph: Graph = new Graph();
    let index = 0;
    if (!flow) {
      return graph;
    }
    flow.steps.forEach(s => {
      graph.nodes.push(new GraphNode(s.path!, s.groupId ? s.groupId.toString() : ""));
      if (s.nextStepDeciderConfig) {
        if (s.nextStepDeciderConfig.deciderType == NextStepDeciderType.Simple) {
          const decider = <SimpleNextStepDeciderConfig>s.nextStepDeciderConfig;
          if (decider.gotoStepPath) {
            const step = flow.steps.find(step => step.path === decider.gotoStepPath);
            if (step) {
              const stepIndex = flow.steps.indexOf(step);
              const link = { source: index, target: stepIndex };
              graph.links.push(link);
            }
          }
        } else {
          const decider = <ExpressionNextStepDeciderConfig>s.nextStepDeciderConfig;
          if (decider.ifStepExpression) {
            const step = flow.steps.find(s => s.path === decider.ifStepExpression.goToPath);
            if (step) {
              const stepIndex = flow.steps.indexOf(step);
              const link = { source: index, target: stepIndex };
              graph.links.push(link);
            }
            if (decider.elseIfStepExpressions) {
              decider.elseIfStepExpressions.forEach(e => {
                const elseIfStep = flow.steps.find(s => s.path === e.goToPath);
                if (elseIfStep) {
                  const stepIndex = flow.steps.indexOf(elseIfStep);
                  const link = { source: index, target: stepIndex };
                  graph.links.push(link);
                }
              });
            }
            const elseStep = flow.steps.find(s => s.path === decider.elseStepPath);
            if (elseStep) {
              const stepIndex = flow.steps.indexOf(elseStep);
              const link = { source: index, target: stepIndex };
              graph.links.push(link);
            }
          }
        }
      }
      index++;
    });
    return graph;
  }

  private renderFlowSummary = (graph: Graph) => {
    // set the dimensions and margins of the graph
    const margin = ({ top: 20, right: 10, bottom: 20, left: 100 });
    const width = 290 - margin.left - margin.right;
    const step = 40;
    const height = (graph.nodes.length - 1) * step + margin.top + margin.bottom

    // append the svg object to the body of the page
    const svg = d3.select("#flow-summary-view")
      .append("svg")
      .attr("viewBox", '0,0,' + (width + 90).toString() + ',' + (height + 80).toString())
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    svg.append("style").text(`
      .hover path {
        stroke: #ccc;
      }
      .hover text {
        fill: #ccc;
      }
      .hover g.primary text {
        fill: black;
        font-weight: bold;
      }
      .hover g.secondary text {
        fill: #333;
      }
      .hover path.primary {
        stroke: #333;
        stroke-opacity: 1;
      }
    `);

    // A color scale for groups:
    //const color = d3.scaleOrdinal(graph.nodes.map((d: any) => d.group).sort(d3.ascending), d3.schemeCategory10)

    const y = d3.scalePoint(graph.nodes.map((d: any) => d.name), [margin.top, height - margin.bottom])

    const label = svg.append("g")
      .attr("font-family", "sans-serif")
      .attr("font-size", 14)
      .attr("text-anchor", "end")
      .selectAll("g")
      .data(graph.nodes)
      .join("g")
      .attr("transform", (d: any) => {
        const y1 = y(d.name);
        return `translate(${margin.left},${d.y = y1})`
      })
      .call((g: any) => g.append("text")
        .attr("x", -16)
        .attr("dy", "0.35em")
        .attr("fill", (d: any) => d3.lab(stepGroupColors[d.group] || DEFAULT_COLOR).darker(2))
        .text((d: any) => d.name))
      .call(g => g.append("circle")
        .attr("r", 8)
        .attr("id", (d: any) => d.name)
        .attr("fill", (d: any) => stepGroupColors[d.group] || DEFAULT_COLOR));

    function arc(l: any) {
      const source = graph.nodes[l.source];
      const target = graph.nodes[l.target];
      let y1 = y(source.name);
      let y2 = y(target.name);
      if (!y2) y2 = 0;
      if (!y1) y1 = 0;
      const r = Math.abs(y2 - y1) / 2;
      return `M${margin.left},${y1}A${r},${r} 0,0,${y1 < y2 ? 1 : 0} ${margin.left},${y2}`;
    }

    const nodes = d3.selectAll('circle');

    const path = svg.insert("g", "*")
      .attr("fill", "none")
      .attr("stroke-opacity", 0.6)
      .attr("stroke-width", 1.5)
      .selectAll("path")
      .data(graph.links)
      .join("path")
      .attr("stroke", (l: any) => {
        const source = graph.nodes[l.source];
        const target = graph.nodes[l.target];
        return source.group === target.group ? (stepGroupColors[source.group] || DEFAULT_COLOR) : "#aaa"
      })
      .attr("d", arc);

    const overlay = svg.append("g")
      .attr("fill", "none")
      .attr("pointer-events", "all")
      .selectAll("rect")
      .data(graph.nodes)
      .join("rect")
      .attr("width", margin.left + 40)
      .attr("height", step)
      .attr("y", (d: any) => {
        const y1: number | undefined = y(d.name);
        if (y1) {
          return y1 - step / 2
        }
        return 0;
      })
      .on("click", (event, d: any) => {
        if (this._flow) {
          const step = this._flow.steps.find(s => s.path === d.name);
          if (step && this.stepSelected) {
            this.stepSelected.emit(step);
          }
        }
      })
      .on("mouseover", (event, d: any) => {
        nodes.style('opacity', .2)
        //d3.select(d).style('opacity', 1)
        d3.select("[id='" + d.name + "']").style('opacity', 1)
        svg.classed("hover", true);

        const indexOfD = graph.nodes.indexOf(d);
        var sources = graph.links.filter((l: any) => l.source === indexOfD);
        var targets = graph.links.filter((l: any) => l.target === indexOfD);

        sources.forEach((s: any) => {
          const source = graph.nodes[s.target];
          d3.select("[id='" + source.name + "']").style('opacity', 1)
        })

        label.classed("primary", n => n === d);
        label.classed("secondary", (n: any) => {
          const indexOfN = graph.nodes.indexOf(n);
          return targets.some((l: any) => l.source === indexOfN) || sources.some((l: any) => l.target === indexOfN)
        });
        path.classed("primary", (l: any) => {
          const indexOfD = graph.nodes.indexOf(d);
          return l.source === indexOfD /*|| l.target === indexOfD*/
        }).filter(".primary").raise();
      })
      .on("mouseout", d => {
        nodes.style('opacity', 1);
        svg.classed("hover", false);
        label.classed("primary", false);
        label.classed("secondary", false);
        path.classed("primary", false).order();
      });
  }
}
