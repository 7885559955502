<div class="w-100">
  <div class="my-2 d-flex justify-content-center">
    <div style="max-width: 500px">
      <h5 class="my-2">
        <span class="text-gray-700 fs-4 fw-bold">
          Would you like to include?
        </span>
      </h5>
      <form #additionalOptionsForm="ngForm" novalidate class="form-horizontal">
        <div class="row p-4 mb-3">
            <div class="form-check mb-3 col-md-12" *ngIf="details.duDocFileId || details.lpaDocFileId || details.duHistoryDocumentId || details.lpaHistoryDocumentId">
              <input id="includeAusFindings" name="includeAusFindings" class="form-check-input" type="checkbox" [(ngModel)]="request.includeAusFindings"/>
              <label for="includeAusFindings" class="form-check-label">AUS Findings</label>
            </div>
            <div class="form-check mb-3 col-md-12" *ngIf="details.proofOfFundsDocFileId">
              <input id="includeProofOfFunds" name="includeProofOfFunds" class="form-check-input" type="checkbox" [(ngModel)]="request.includeProofOfFunds"/>
              <label for="includeProofOfFunds" class="form-check-label">Proof of Funds</label>
            </div>
        </div>
      </form>
    </div>
  </div>

  <div class="d-flex justify-content-between my-2">
    <span>
      <a
        href="javascript:void(0)"
        class="btn btn-light"
        (click)="closeClicked()"
      >
        Close
      </a>
    </span>
    <span>
      <a
        href="javascript:void(0)"
        class="btn bp-primary-bg-color me-2"
        (click)="backClicked()"
      >
        Back
      </a>
      <a
        href="javascript:void(0)"
        class="btn bp-primary-bg-color"
        (click)="nextClicked()"
      >
        Next
      </a>
    </span>
  </div>
</div>

