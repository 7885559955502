import { Injectable } from "@angular/core";
import { WizardFlowContext } from "../../../wizard-flow-context.model";
import { GenericStepFactory } from "./generic-step.factory";
import { NextStepDeciderFactory } from "./next-step-decider.factory";
import { ICreateStep } from "./step-factory.interface";
import { ReviewApplicationStep } from "../../review-application.model";
import { ReviewApplicationStepConfig } from "../review-application-step-config.model";

@Injectable()
export class ReviewApplicationStepFactory extends GenericStepFactory<ReviewApplicationStep> implements ICreateStep {

  constructor(nextStepDeciderFactory: NextStepDeciderFactory) {
    super(nextStepDeciderFactory, ReviewApplicationStep);
  }

  create(config: ReviewApplicationStepConfig, wizardFlowContext: WizardFlowContext): ReviewApplicationStep {
    let reviewApplicationStep = super.create(config, wizardFlowContext);
    const allowEditingOfSubjectProperty = ((config.allowEditingOfSubjectProperty === undefined || config.allowEditingOfSubjectProperty === null) ?
      true : config.allowEditingOfSubjectProperty);
    const showSubjectPropertyDetails = ((config.showSubjectPropertyDetails === undefined || config.showSubjectPropertyDetails === null) ?
      true : config.showSubjectPropertyDetails);
    reviewApplicationStep.allowEditingOfSubjectProperty = allowEditingOfSubjectProperty;
    reviewApplicationStep.showSubjectPropertyDetails = showSubjectPropertyDetails;
    reviewApplicationStep.loanDetailsSectionTitle = config.loanDetailsSectionTitle || "Loan Details";
    return reviewApplicationStep;
  }

  configure(step: ReviewApplicationStep, config: ReviewApplicationStepConfig): void {
    super.configure(step, config);
    config.allowEditingOfSubjectProperty = step.allowEditingOfSubjectProperty;
    config.showSubjectPropertyDetails = step.showSubjectPropertyDetails;
    config.loanDetailsSectionTitle = step.loanDetailsSectionTitle;
  }
}
