import { Injectable } from "@angular/core";
import { Address } from "./address-model";
import { Asset } from "./asset.model";
import { Borrower } from "./borrower-model";
import { MaritalStatus } from "./enums/marital-status.enum";
import { SpouseTransactionInvolvement } from "./enums/spouse-txn-involvement.enum";
import { HousingExpense } from "./housing-expense.model";
import { MortgageTerm } from "./mortgage-term.model";
import { SubjectProperty } from "./subject-property.model";
import { TransactionDetail } from "./transaction-detail.model";
import { Liability, RealEstateOwned } from "./mortgage.model";

@Injectable()
export class MortgageApplication {
  applicationId: number = 0;
  userGuid: string = "unassigned";
  mortgageId: number = 0;

  channel: string = null;

  subjectProperty: SubjectProperty = new SubjectProperty();

  maritalStatus: MaritalStatus | undefined;
  spouseTransactionInvolvement: SpouseTransactionInvolvement | undefined;
  propertyAddress: Address | undefined;
  currentAddress: Address | undefined;
  borrowers: Borrower[] = [];
  assets: Asset[] = [];
  realEstateOwned: RealEstateOwned[] = [];
  liabilities: Liability[] = [];
  mortgageTerm: MortgageTerm = new MortgageTerm();
  transactionDetail: TransactionDetail = new TransactionDetail();
  proposedHousingExpense: HousingExpense = new HousingExpense();
  extension: MortgageApplicationExtension = new MortgageApplicationExtension();

  employmentAndIncomeGatheredDigitally: number | undefined;

  constructor() {
    this.borrowers = [];
    let defaultBorrower = new Borrower();
    this.borrowers.push(defaultBorrower);
  }
}

export class MortgageApplicationExtension {
  hasSignedPurchaseAgreement: boolean | undefined | null;
  purchaseAgreementContractSignedDate: string | undefined | null;
  purchaseAgreementExpectedCOEDate: string | undefined | null;
}

export class MortgageCalculationDetails {
  ltv: MortgageLtvCalculationDetails;
  dti: MortgageDtiCalculationDetails;
  fundsToClose: MortgageFundsToCloseCalculationDetails;
}


export class MortgageLtvCalculationDetails implements IHaveMortgageLtvCalculationDetails {
  baseLoanAmount: number;
  totalLoanAmount: number;
  subordinateLienAmount: number;
  subordinateLienCreditLimitTotal: number;
  collateralValue: number;
  ltv: number;
  cltv: number;
  hcltv: number;
}

export class MortgageDtiCalculationDetails {
  frontEndDti: number;
  backEndDti: number;
  frontEndDebt: number;
  frontEndDebtInfo: string | null;
  incomeMonthlyTotal: number;
  debtMonthlyTotal: number;
  incomeDetail: DtiIncome[] = [];
  debtDetail: DtiDebt[] = [];
}

export interface IHaveMortgageLtvCalculationDetails {
  ltv: number;
  cltv: number;
  hcltv: number;
}

export class MortgageFundsToCloseCalculationDetails {
  salesContractPrice_A?: number;
  improvementsRenovationsRepairs_B?: number;
  landValue_C?: number;
  totalPaidOffForRefinance_D?: number;
  debtsPaidOff_E?: number;
  borrowerClosingCostsTotal_F?: number;
  estimatedPrepaidItems?: number;
  estimatedEscrowItems?: number;
  estimatedClosingCosts?: number;
  pmiMipFundingFee?: number;
  discountPoints_G?: number;
  totalDueFromBorrowerAThroughG_H?: number;
  loanAmount_I?: number;
  loanAmountExcludingFinancedMip?: number;
  financedMip?: number;
  otherNewMortgageLoansOnSubjectProperty_J?: number;
  totalMortgageLoansIThroughJ_K?: number;
  sellerCredits_L?: number;
  otherCredits_M?: number;
  lenderCredits?: number;
  totalCreditsLThroughM_N?: number;
  totalMortgageLoansAndTotalCreditsNAndK?: number;
  cashFromToBorrower?: number;
}

export class DtiIncome {
  name: string;
  amount: number;
  info: string;
  childDetail: DtiIncome[] = [];
}

export class DtiDebt {
  name: string;
  amount: number;
  info: string;
  childDetail: DtiDebt[] = [];
}

