<table class="table bp-table fixTableHead" *ngIf="applicationTasks.length > 0">
  <thead>
    <tr>
      <th class="min-w-200px">Task</th>
      <th class="min-w-130px">Due Date</th>
      <th class="min-w-200px">Status</th>
      <th class="min-w-50px">Actions</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let task of applicationTasks" class="bp-table-row">
      <td class="min-w-200px">
        <span class="fw-bold">{{ task.docType || task.description }}</span>
      </td>
      <td class="min-w-130px">
        <span class="fw-bold">{{ task.dueDate | dateAgo }}</span>
      </td>
      <td class="min-w-200px">
        <span class="fw-bold">{{ task.borrowerTaskStatus }}</span>
      </td>
      <td>
        <div>
          <a
            *ngIf="task.templateDocumentUrl"
            title="View Template Doc"
            class="align-text-bottom"
            (click)="goToLink(task.templateDocumentUrl)"
          >
            <i class="far fa-eye"></i>
          </a>

          <a
            title="Add Files"
            class="mx-2 align-text-bottom"
            (click)="openAddFiles(task)"
          >
            <i class="fas fa-upload"></i>
          </a>

          <a
            title="Attached Documents"
            class="mx-2 align-text-bottom"
            (click)="openAttachedDocuments(task)"
          >
            <i class="far fa-paperclip"></i>
          </a>
        </div>
      </td>
    </tr>
  </tbody>
</table>

<h2 class="text-center my-4" *ngIf="applicationTasks.length === 0">
  No task found!
</h2>
