import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'html-viewer-dialog',
  templateUrl: 'html-viewer-dialog.component.html'
})
export class HtmlViewerDialogComponent implements OnInit {

  @Input()
  htmlContent: string;

  @Input()
  title: string;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    if (this.htmlContent) {
      this.loadEmailTemplatePreview();
    }
  }

  private loadEmailTemplatePreview = () => {
    setTimeout(() => {
      let iframe = document.getElementById(`htmlPreviewPanel`) as HTMLIFrameElement;
      if (!iframe) {
        return;
      }
      let doc = iframe.contentDocument;
      setTimeout(() => {
        doc.open();
        doc.write(this.htmlContent);
        doc.close();
      }, 100)
    });
  }
}
