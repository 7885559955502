import { Address } from "./address-model";
import { BorrowerType } from "./borrower-type.model";
import { ResidencyAddress } from "./residency-address.model";


export class Mortgage {
  mortgageId?: number;
  readonly applicationId?: null | number;
  subjectPropertyId?: null | number;
  readonly mortgageTermId?: null | number;
  readonly transactionDetailId?: null | number;
  subjectProperty?: null | SubjectProperty;
  mortgageTerm: MortgageTerm;
  originatorInformation: OriginatorInformation;
  relatedMortgages: Array<RelatedMortgage>;
  transactionDetail?: null | TransactionDetail;
  borrowers: Array<MortgageBorrower>;
  assets: Array<Asset>;
  liabilities: Array<Liability>;
  governmentLoanDetail: null | GovernmentLoanDetail;
  mortgageInsuranceDetail: null | MortgageInsuranceDetail;
  realEstateOwned: Array<RealEstateOwned>;
  readonly companyId?: null | number;
  readonly insertedBy?: null | string;
  readonly dateInserted?: null | string;
  readonly updatedBy?: null | string;
  readonly dateUpdated?: null | string;
  proposedHousingExpense?: null | HousingExpense;
  currentHousingExpense?: null | HousingExpense;
  extension?: null | Extension;
  ausData?: null | AusData;
  ausDataId: number;
}

export class Extension {
  extensionId: number;
  fannieMaeProjectClassificationType: string;
  isBalloonPaymentRequiredToPayOffLoan: boolean;
  willEscrowBeWaived: boolean;
  isLoanCoveredUnderHomeOwnershipEquityProtectionAct: boolean;
  isPreApprovalLoan: boolean;
  fannieMaeProductDesciptionType: string;
  productCode: string;
  fannieMaeAdjustableRateMortgagePlanNumber: string;
  isLoanAssumableByAnotherParty: boolean;
  fannieMaePaymentFrequencyType: string;
  isPenaltyChargedInEventOfPrepayment: boolean;
  isPrepaymentOfLoanRestricted: boolean;
  fannieMaeRepaymentType: string;
  buydownInterestRateChangeFrequencyInMonths: number;
  buydownInterestRateDurationInMonths: number;
  buydownInterestRateAdjustment: number;
  isLenderFundingInterestRateBuydown: boolean;
  fannieMaeBuydownBaseDateType: string;
  isBuydownPermanent: boolean;
  balloonTermMonths: number;
  isInterestOnly: boolean;
  interestOnlyTermMonths: number;
  hasNegativeAmortization: boolean;
  hasPrepaymentPenalty: boolean;
  prepaymentPenaltyTermMonths: number;
  hasBuydown: boolean;
  initialBuydownRate: number;
  otherLoanFeatures: string;
  belowMarketSubordinateFinancingProvided: boolean;
  companyId: number;
  insertedBy: string;
  dateInserted: string;
  updatedBy: string;
  dateUpdated: string;
}

export class AusData {
  ausDataId: number;
  buydownContributorType: string;
  armIndexCurrent: number;
  armIndexMargin: number;
  armIndexType: string;
  armIndexSourceType: string;
  armIndexSourceTypeOtherDescription: string;
  armQualifyingRate: number;
  armRateAdjustmentLifetimeCap: number;
  armMarginRate: number;
  armAdjustmentChangeIndexRate: number;
  firstPaymentAdjustmentMonths: number;
  paymentAdjustmentPeriodicCap: number;
  paymentAdjustmentPeriodNumber: number;
  subsequentPaymentAdjustmentMonths: number;
  firstRateAdjustmentMonths: number;
  rateAdjustmentPeriodNumber: number;
  rateAdjustmentPeriodicCap: number;
  subsequentRateAdjustmentMonths: number;
  lenderRegistrationIdentifier: string;
  helocMaximumBalanceAmount: number;
  subordinateLienHelocAmount: number;
  baloonLoanMaturityTermMonths: number;
  negativeAmortizationLimit: number;
  negativeAmortizationType: string;
  productName: string;
  vaResidualIncome: number;
  sectionOfActType: string;
  isPrimaryBorrowerMarriedToCoBorrower: boolean;
  refinanceProgramIdentifier: string;
  refinanceProgramIdentifierOtherDescription: string;
  loanProgramIdentifier: string;
  mortgageInsuranceCoveragePercent: number;
  hudIncomeLimitAdjustmentPercent: number;
  hmdaRateSpread: number;
  caseStateType: string;
  theWorkNumberReportId: string;
  companyId: number;
  insertedBy: string;
  dateInserted: string;
  updatedBy: string;
  dateUpdated: string;
}

export class RelatedMortgage {
  relatedMortgageId: number;
  mortgageId: number;
  creditorName: string;
  lienType: string;
  monthlyPayment: number;
  loanOrDrawAmount: number;
  creditLimit: number;
  isAffordableLoan: boolean;
  isPrincipalAndInterestDeferredFirstFiveYears: boolean;
  fundsSource: string;
  fundsSourceOtherDescription: string;
  companyId: number;
  insertedBy: string;
  dateInserted: string;
  updatedBy: string;
  dateUpdated: string;
}

export class SubjectProperty {
  subjectPropertyId: number;
  addressId: number;
  subjectPropertyValuationId: number;
  legalDescription: string;
  purposeOfLoan: string;
  loanPurposeOtherDescription: string;
  propertyWillBe: string;
  grossRent: number;
  occupancyPercent: number;
  apn: string;
  lot: string;
  tract: string;
  unit: string;
  noOfUnits: number;
  yearBuilt: number;
  propertyType: string;
  isCondominiumHotel: boolean;
  isPlannedUnitDevelopment: boolean;
  isPropertyNotInAProject: boolean;
  constructionPeriodMonths: number;
  presentValue: number;
  improvementCost: number;
  amountExistingLiens: number;
  buildingStatus: string;
  originalCostYear: number;
  originalCost: number;
  refiPurpose: string;
  refinancePrimaryPurpose: string;
  describeImprovement: string;
  improvementStatus: string;
  cost: number;
  refinanceWithOriginalCreditor: boolean;
  titleWillBeHeldNames: string;
  downPaymentSource: string;
  explain: string;
  manner: string;
  estate: string;
  leaseHoldExpirationDate: string;
  legalDescriptionType: string;
  nativeAmericanLandsType: string;
  isCommunityLandTrust: boolean;
  isSubjectToPropertyInclusionZoning: boolean;
  uniqueDwellingType: string;
  sellerFirstName: string;
  sellerLastName: string;
  sellerMiddleName: string;
  sellerNameSuffix: string;
  atLeastOneBorrowerLivesInCommunityPropertyState: boolean;
  propertyIsInCommunityPropertyState: boolean;
  communityLendingProductType: string;
  communitySecondsRepaymentType: string;
  isConversionOfContractForDeedOrLandContract: boolean;
  isRenovation: boolean;
  isConstructionConversionOrConstructionToPermanent: boolean;
  constructionToPermanentClosingType: string;
  projectType: string;
  projectDesignType: string;
  attachmentType: string;
  constructionMethod: string;
  constructionStatus: string;
  manufacturedHomeWidthType: string;
  mannerTitleWillBeHeld: string;
  mannerTitleWillBeHeldOtherDescription: string;
  trustClassification: string;
  mortgageWillFinanceEnergyRelatedImprovements: boolean;
  propertyHasLienWithCleanEnergyProgram: boolean;
  refinanceProgram: string;
  refinanceProgramOther: string;
  currentFirstMortgageHolderType: string;
  lotOriginalCost: number;
  lotAppraisedValue: number;
  landValueType: string;
  lotAcquiredDate: string;
  titleCurrentlyHeldInNames: string;
  isFhaSecondaryResidence: boolean;
  isMixedUseProperty: boolean;
  expectedGrossMonthlyRentalIncome: number;
  expectedNetMonthlyRentalIncome: number;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
  county: string;
  country: string;
  countyFips: string;
  stateFips: string;
  subjectPropertyValuation: SubjectPropertyValuation;
  companyId: number;
  insertedBy: string;
  dateInserted: string;
  updatedBy: string;
  dateUpdated: string;
}

export class SubjectPropertyValuation {
  subjectPropertyValuationId: number;
  appraisalIdentifier: string;
  appraisalFormType: string;
  appraisalMethodType: string;
  appraiserCompanyName: string;
  appraiserName: string;
  companyId: number;
  insertedBy: string;
  dateInserted: string;
  updatedBy: string;
  dateUpdated: string;
}

export class HousingExpense {
  expenseId: number;
  borrowerId: number;
  firstMortgagePrincipalAndInterest: number;
  homeownersInsurance: number;
  homeownersAssociationDuesAndCondominiumFees: number;
  condominiumAssociationDues: number;
  cooperativeAssociationDues: number;
  homeownersAssociationDues: number;
  mortgageInsurance: number;
  otherHousingExpense: number;
  otherHousingExpenseDescription: string;
  otherMortgageLoanPrincipalAndInterest: number;
  realEstateTax: number;
  statePropertyTax: number;
  countyPropertyTax: number;
  districtPropertyTax: number;
  boroughPropertyTax: number;
  cityPropertyTax: number;
  townPropertyTax: number;
  villagePropertyTax: number;
  schoolPropertyTax: number;
  supplementalPropertyInsurance: number;
  earthquakeInsurance: number;
  floodInsurance: number;
  hailInsurance: number;
  volcanoInsurance: number;
  windAndStormInsurance: number;
  otherSupplementalPropertyInsurance: number;
  rent: number;
  isCurrent: boolean;
  companyId: number;
  insertedBy: string;
  dateInserted: string;
  updatedBy: string;
  dateUpdated: string;
}

export class MortgageTerm {
  mortgageTermId?: null | number;
  mortgageAppliedFor?: null | string;
  loanTypeOtherDescription?: null | string;
  incomeAssetsOtherThanBorrowerWillBeUsed?: null | boolean;
  incomeAssetsOfSpouseNotUsed?: null | boolean;
  agencyCaseNumber?: null | string;
  lenderCaseNumber?: null | string;
  universalLoanNumber?: null | string;
  // universalLoanNumber?: null | number;
  mersMortgageIdentifier?: null | string;
  amount?: null | number;
  totalLoanAmount?: null | number;
  appraisedValue?: null | number;
  interestRate?: null | number;
  qualifyingRate?: null | number;
  noOfMonths?: null | number;
  amortizationType?: null | string;
  // amortizationType?: null | AmortizationTypeEnum;
  amortizationOtherDescription?: null | string;
  armTextualDescription?: null | string;
  lienPosition?: null | string;
  companyId?: null | number;
  insertedBy?: null | string;
  dateInserted?: null | string;
  updatedBy?: null | string;
  dateUpdated?: null | string;
}

export class OriginatorInformation {
  originatorInformationId?: number;
  name?: string;
  nmls?: string;
  stateLicense?: string;
  email?: string;
  phone?: string;
  organizationName?: string;
  organizationAddress1?: string;
  organizationAddress2?: string;
  organizationCity?: string;
  organizationState?: string;
  organizationZipCode?: string;
  organizationNmls?: string;
  organizationStateLicense?: string;
  organizationTaxpayerIdentifier?: string;
  originatorFhaLenderId?: string;
  originatorFhaSponsorId?: string;
  applicationTakenMethod?: string;
  applicationSignedDate?: string;
  companyId?: number;
  insertedBy?: string;
  dateInserted?: string;
  updatedBy?: string;
  dateUpdated?: string;
}

export class TransactionDetail {
  readonly transactionDetailId?: null | number;
  purchasePriceAmount?: null | number;
  readonly landAmount?: null | number;
  alterationsImprovementsAndRepairsAmount?: null | number;
  refinanceIncludingDebtsToBePaidOffAmount?: null | number;
  prepaidItemsEstimatedAmount?: null | number;
  prepaidEscrowsTotalAmount?: null | number;
  estimatedClosingCostsAmount?: null | number;
  estimatedClosingCostsExcludingPrepaidsAmount?: null | number;
  miAndFundingFeeTotalAmount?: null | number;
  borrowerPaidDiscountPointsTotalAmount?: null | number;
  subordinateLienAmount?: null | number;
  sellerPaidClosingCostsAmount?: null | number;
  miAndFundingFeeFinancedAmount?: null | number;
  cashFromToBorrowerAmount?: null | number;
  reserves?: null | number;
  salesConcessionTotal?: null | number;
  energyRelatedImprovementsAmount?: null | number;
  vaEntitlementAmount?: null | number;
  vaResidualIncomeAmount?: null | number;
  borrowerPaidFhaVaClosingCosts?: null | number;
  purchaseCredits?: null | PurchaseCredit[];
  readonly companyId?: null | number;
  readonly insertedBy?: null | string;
  readonly dateInserted?: null | string;
  readonly updatedBy?: null | string;
  readonly dateUpdated?: null | string;

  constructor() {
    this.purchaseCredits = [];
  }
}

export enum MaritalStatusTypeEnum {
  Single = "Single",
  Married = "Married",
  Separated = "Separated",
  NotProvided = "NotProvided",
  Other = "Other"
}

export enum DomesticRelationshipTypeEnum {
  CivilUnion = "CivilUnion",
  DomesticPartnership = "DomesticPartnership",
  RegisteredReciprocalBeneficiaryRelationship = "RegisteredReciprocalBeneficiaryRelationship",
  Other = "Other"
}

export class MortgageBorrower {
  borrowerId: number;
  mortgageId: number;
  contactId: number;
  printApplicationIndex: number;
  jointWithBorrowerSocialSecurityNumber: string;
  jointWithBorrowerId: number;
  firstName: string;
  middleName: string;
  lastName: string;
  nameSuffix: string;
  yearsOfSchool: number;
  authorizedCreditCheck: boolean;
  dateAuthorizedCreditCheck: string;
  authorizationMethod: string;
  socialSecNum: string;
  maritalStatus: MaritalStatusTypeEnum;
  dateOfBirth: string;
  acceptedEConsentDate: string;
  creditScore: number;
  creditScore_Equifax: number;
  creditScore_Experian: number;
  creditScore_TransUnion: number;
  creditRefNumber: string;
  homePhone: string;
  mobilePhone: string;
  workPhone: string;
  primaryEmail: string;
  secondaryEmail: string;
  typeOfBorrower: BorrowerType;
  rawSocialSecurityNumber: string;
  dependentCount: number;
  isMergedWithBorrower: boolean;
  mergedWithBorrowerId: number;
  hasServedInMilitary: boolean;
  isActiveDutyMilitary: boolean;
  militaryTourEndDate: string;
  isRetiredDischargedSeparatedFromMilitary: boolean;
  onlyNonActivatedReserveOrNationalGuard: boolean;
  isSurvivingSpouseOfMilitary: boolean;

  isFirstUseOfVaBenefit: boolean;
  isVaFundingFeeExempt: boolean;

  vaFederalTaxAmount: number;
  domesticRelationshipType: DomesticRelationshipTypeEnum;
  unmarriedRelationshipTypeOther: string;
  unmarriedRelationshipState: string;
  continuationSheetInformation: string;
  applicationSignedDate: string;
  authorizedTheWorkNumber: boolean;
  formFreeReportId: string;
  active: boolean;
  aliases: BorrowerAlias[] = [];
  //counselingEvents (Array[LE.Lodasoft.Common.Models.Mortgage.BorrowerCounselingEventViewModel], optional),
  currentHousingExpenses: HousingExpense;
  declarations: Declarations;
  dependents: number[] = [];
  employments: Employment[] = [];
  governmentMonitors: Demographics;
  nonEmploymentIncomes: Income[] = [];
  residencyAddresses: ResidencyAddress[] = [];
  declarationId?: number;
  governmentMonitorId?: number;
  companyId: number;
  insertedBy: string;
  dateInserted: string;
  updatedBy: string;
  dateUpdated: string;

  //calculatedStats: BorrowerCalculatedStats = new BorrowerCalculatedStats();
}

export class MortgageInsuranceDetail {
  miOrFundingFeeTotalAmount: number;
  miOrFundingFeeTotalPercent: number;
  miOrFundingFeeFinancedAmount: number;
  miOrFundingFeeUpFrontAmount: number;
  isMIPrepaid: boolean;
  monthsPrepaid: number;
  prepaidPremiumTotal: number;
  cancelAtPercent: number;
  level1MonthlyAmount: number;
  level1Percent: number;
  level1Months: number;
  level2MonthlyAmount: number;
  level2Percent: number;
  level2Months: number;
  miCompanyNameType: string;
  miCompanyNameTypeOtherDescription: string;
  miOrderedDate: string;
  miReceivedDate: string;
  miCaseNumber: string;
  mortgageInsuranceCoveragePercent: number;
  premiumRefundableType: string;
  premiumRefundableTypeOtherDescription: string;
  renewalCalculationType: string;
  renewalCalculationTypeOtherDescription: string;
  renewalType: string;
  premiumRatePlanType: string;
  paymentFrequency: string;
  paymentFrequencyOtherDescription: string;
  duration: string;
  premiumSource: string;
  calculationBasedOn: string;
  calculateBasedOnRemainingBalance: boolean;
  midpointPaymentCancellation: boolean;
  decliningRenewals: boolean;
  mortgageInsuranceDetailId: number;
  companyId: number;
  insertedBy: string;
  dateInserted: string;
  updatedBy: string;
  dateUpdated: string;
}

export class MortgageInsuranceCalculationResult {
  miOrFundingFeeTotalAmount: number;
  miOrFundingFeeTotalPercent: number;
  miOrFundingFeeFinancedAmount: number;
  totalLoanAmount: number;
  level1MonthlyAmount: number;
  level2MonthlyAmount: number;
  prepaidPremiumTotal: number;
}

export class GovernmentLoanDetail {
  borrowerFundingFeePercent: number;
  borrowerPaidFhaVaClosingCostsAmount: number;
  refundProratedUnearnedUpFront: boolean;
  roundToNearest50Dollars: boolean;
  insuranceChargesIncludedInLoanPayments: boolean;
  insuranceChargesCollectedAtClosing: boolean;
  lenderPaidMortgageInsurance: boolean;
  sectionOfActType: string;
  borrowerFinancedFhaDiscountPointsAmount: number;
  fhaMiPremiumRefundAmount: number;
  fhaAnnualPremiumPercent: number;
  fhaAssignmentDate: string;
  fhaEndorsementDate: string;
  fhaEnergyRelatedRepairsOrImprovementsAmount: number;
  fhaGeneralServicesAdministrationIdentifier: string;
  fhaInsuranceProgramType: string;
  fhaPremiumAnniversaryYearToDateRemittanceAmount: number;
  fhaRefinanceInterestOnExistingLienAmount: number;
  fhaRefinanceOriginalExistingFhaCaseIdentifier: string;
  fhaRefinanceOriginalExistingUpfrontMIPremiumAmount: number;
  fhaMIRenewalRatePercent: number;
  borrowerHasPositiveRentalHistory: boolean;
  vaEntitlementAmount: number;
  vaResidualIncomeAmount: number;
  vaEntitlementIdentifier: string;
  vaFundingFeeExemptionType: string;
  governmentLoanDetailId: number;
  companyId: number;
  insertedBy: string;
  dateInserted: string;
  updatedBy: string;
  dateUpdated: string;
}

export class Dependent {
  dependentId: number;
  borrowerId: number;
  age: number;
  companyId: number;
  insertedBy: string;
  dateInserted: string;
  updatedBy: string;
  dateUpdated: string;
}

export class BorrowerAlias {
  borrowerAliasId: number;
  borrowerId: number;
  firstName: string;
  middleName: string;
  lastName: string;
  nameSuffix: string;
  creditorName: string;
  accountNumber: string;
  companyId: number;
  insertedBy: string;
  dateInserted: string;
  updatedBy: string;
  dateUpdated: string;
}

export interface Expense {
  expenseId?: null | number;
  readonly borrowerId?: null | number;
  readonly monthlyAmount?: null | number;
  readonly expenseType?: null | ExpenseTypeEnum;
  readonly isCurrent?: null | boolean;
  readonly companyId?: null | number;
  readonly insertedBy?: null | string;
  readonly dateInserted?: null | string;
  readonly updatedBy?: null | string;
  readonly dateUpdated?: null | string;
}

export class Employment {
  employmentId: number;
  borrowerId: number;
  addressId: number;
  employer: string;
  employerEmail: string;
  employerPhone: string;
  employmentType: EmploymentTypeEnum;
  selfEmployed: boolean = null;
  isPrimary: boolean;
  position: string;
  specialRelationshipType: string;
  startDate: string;
  endDate: string;
  yearsOnJob: number;
  monthsOnJob: number;
  yearsInLineOfWork: number;
  fromCreditReport: boolean;
  monthsInLineOfWork: number;
  borrowerOwnershipShare: string = null;
  borrowerHasSpecialRelationshipWithEmployer: boolean;
  selfEmploymentMonthlyIncomeOrLoss: number;
  isDigitallyVerified: boolean;
  theWorkNumberEmployerCode: string;
  address: Address;
  incomes: Income[] = [];
  companyId: number;
  insertedBy: string;
  dateInserted: string;
  updatedBy: string;
  dateUpdated: string;
}

export class PurchaseCredit {
  purchaseCreditId: number;
  transactionDetailId: number;
  purchaseCreditType: string;
  purchaseCreditTypeOtherDescription: string;
  sourceType: string;
  purchaseCreditAmount: number;
  explanation: string;
  companyId?: number;
  insertedBy?: string;
  dateInserted?: string;
  updatedBy?: string;
  dateUpdated?: string;
}

export class Income {
  incomeId?: null | number;
  borrowerId?: null | number;
  monthlyIncome?: null | number;
  typeOfIncome?: null | string;
  typeOfIncomeOtherDescription: string | null;
  fromCreditReport?: null | boolean;
  companyId?: null | number;
  insertedBy?: null | string;
  dateInserted?: null | string;
  updatedBy?: null | string;
  dateUpdated?: null | string;
  categoryOfIncome?: null | CategoryOfIncomeEnum;
  yearlyIncome?: null | number;
  employmentModel?: null | Employment;
  reoModel?: null | Reo;
  description?: null | string;

  amount: number; // fakes
  payPeriod: string; //fake
  hoursPerWeek: number; //fake
  isOmitted: boolean; //fakse
}

export class Declarations {
  declarationId: number;
  haveJudgement: boolean;
  haveJudgementInfo: string;
  declaredBankruptcy: boolean;
  declaredBankruptcyInfo: string;
  beenForclosed: boolean;
  beenForclosedInfo: string;
  haveLawsuit: boolean;
  haveLawsuitInfo: string;
  haveLoanObligation: boolean;
  haveLoanObligationInfo: string;
  haveTaxDebt: boolean;
  haveTaxDebtInfo: string;
  haveChildSupport: boolean;
  haveChildSupportInfo: string;
  borrowedDownPayment: boolean;
  borrowedDownPaymentInfo: string;
  coSignedLoan: boolean;
  coSignedLoanInfo: string;
  isVeteran: boolean;
  residenceStatus: string;
  occupyProperty: boolean;
  havePropertiesOwnership: boolean;
  typeOfProperty: string;
  heldTitleHow: string;
  fromCreditReport: boolean;
  haveRelationshipWithSeller: boolean;
  undisclosedMoneyAmount: number;
  applyingForOtherMortgage: boolean;
  applyingForNonMortgageCredit: boolean;
  propertyHasLienWithCleanEnergyProgram: boolean;
  haveConveyedTitleInLieuOfForeclosure: boolean;
  haveCompletedShortSale: boolean;
  bankruptcyType: string;
  companyId: number;
  insertedBy: string;
  dateInserted: string;
  updatedBy: string;
  dateUpdated: string;
}

export class Asset {
  assetId: number;
  owningBorrowerIds: Array<string> = [];
  addressId: number;
  assetType: string;
  assetInsertionType: string;
  cashMarketValue: number;
  lifeInsuranceFaceValueAmount: number;
  accountNumber: string;
  depository: string;
  makeModel: string;
  year: number;
  disposition: string;
  amountOfMortgageLien: number;
  morgagePayment: number;
  gross: number;
  netRentalIncome: number;
  dividendIncome: number;
  insMainTaxMisc: number;
  stockBondMutualFundShareCount: number;
  otherAssetTypeDescription: string;
  isDeposited: boolean;
  giftOrGrantSource: string;
  holderAddress1: string;
  holderAddress2: string;
  holderCity: string;
  holderState: string;
  holderZipCode: string;
  holderCounty: string;
  holderCountry: string;
  companyId: number;
  insertedBy: string;
  dateInserted: string;
  updatedBy: string;
  dateUpdated: string;
}

export enum PayoffType {
  None = "None",
  Partial = "Partial",
  Full = "Full"
}

export class Liability {
  liabilityId: number;
  owningBorrowerIds: Array<number>;
  reoId: number;
  typeOfLiability: string;
  typeOfLiabilityOtherDescription: string;
  accountNumber: string;
  attention: string;
  description: string;
  monthlyPayment: number;
  monthsLeftToPay: number;
  unpaidBalance: number;
  creditLimit: number;
  interestRate: number;
  payoffType: PayoffType | string;
  partialPayoffAmount: number;
  isResubordinate: boolean;
  isExcluded: boolean;
  isSecondaryMortgageThatWillBeFinanced: boolean;
  isUwRequiredPayoff: boolean;
  lienPosition: string;
  holderName: string;
  holderAddress1: string;
  holderCity: string;
  holderState: string;
  holderZipCode: string;
  holderPhone: string;
  holderFax: string;
  holderComments: string;
  late30Days: number;
  late60Days: number;
  late90Days: number;
  late120Days: number;
  consumerDisputeIndicator: boolean;
  derogatoryDataIndicator: boolean;
  fromCreditReport: boolean;
  mortgageType: string;
  mortgageCreditLimit: number;
  mortgageMonthlyPaymentIncludesTaxesAndInsurance: boolean;
  companyId: number;
  insertedBy: string;
  dateInserted: string;
  updatedBy: string;
  dateUpdated: string;
  liabilityWillBePaidOffWithCurrentAssets: boolean;
}

export class RealEstateOwned {
  reoId: number;
  owningBorrowerIds: Array<number> = [];
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  typeOfProperty: string;
  dispositionStatus: string;
  marketValue: number;
  amountOfMortgage: number;
  percentOwned: number;
  vacancyFactor: number;
  grossRentalIncome: number;
  netRentalIncome: number;
  mortgagePayment: number;
  monthlyMiscExpenses: number;
  isCurrentResidence: boolean;
  isSharedCoborrowerReo: boolean;
  isSubjectProperty: boolean;
  omitPresentValueFromAssets: boolean;
  omitNetRentalIncome: boolean;
  includeTaxesInsuranceInDebtRatios: boolean;
  currentUsageType: string;
  intendedUsageType: string;
  unitCount: number;
  projectType: string;
  liabilities: Array<Liability> = [];
  companyId: number;
  insertedBy: string;
  dateInserted: string;
  updatedBy: string;
  dateUpdated: string;
  assetAccountId: string;

  otherFinance: number;
  hazardInsurance: number;
  propertyTax: number;
  mortgageInsurance: number;
  hoa: number;
  otherMonthlyExpense: number;
  purchasePrice: number | undefined;
  dateAcquired: string | undefined;
}

export class Demographics {
  governmentMonitorId: number | undefined;
  sex: string | undefined;
  race: string | undefined;
  raceDesignation: string | undefined;
  ethnicity: string | undefined;
  ethnicityOrigin: string | undefined;
  ethnicityOriginOtherDescription: string | undefined;
  otherAsianRace: string | undefined;
  otherPacificIslanderRace: string | undefined;
  nativeAmericanTribeName: string | undefined;
  applicationTakenMethod: string | undefined;
  isEthnicityBasedOnVisualOrSurname: boolean | undefined;
  isGenderBasedOnVisualOrSurname: boolean | undefined;
  isRaceBasedOnVisualOrSurname: boolean | undefined;
}

export interface ExtendedEmployment extends Employment {
  duration: string;
}

export interface ExtendedMortgageBorrower extends MortgageBorrower {
  last4Ssn: string;
  employments: Array<ExtendedEmployment>;
  digitallySourcedEmployments: Array<any>;
  shouldAccordionExpanded: boolean;
  form: any;
}

export interface Reo {
  readonly address1?: null | string;
  readonly address2?: null | string;
  readonly city?: null | string;
  readonly state?: null | string;
  readonly zipCode?: null | string;
  readonly country?: null | string;
  readonly amountOfMortgage?: null | number;
  readonly borrowerId?: null | number;
  readonly companyId?: null | number;
  readonly dateInserted?: null | string;
  readonly dateUpdated?: null | string;
  readonly dispositionStatus?: null | DispositionStatusEnum;
  readonly grossRentalIncome?: null | number;
  readonly insertedBy?: null | string;
  readonly isCurrentResidence?: null | boolean;
  readonly isSubjectProperty?: null | boolean;
  readonly marketValue?: null | number;
  readonly monthlyMiscExpenses?: null | number;
  readonly mortgagePayment?: null | number;
  readonly netRentalIncome?: null | number;
  readonly reoId?: null | number;
  readonly typeOfProperty?: null | TypeOfPropertyEnum;
  readonly updatedBy?: null | string;
}

//#region - enums
export enum EstateEnum {
  'Fee Simple' = 0,
  'Lease Hold' = 1,
}

export enum PropertyTypeEnum {
  'Single Family Residence - Detached' = 3,
  'Single Family Residence - Attached' = 0,
  'Condominium / Townhome' = 1,
  'Condominium - High Rise' = 4,
  'Condominium - Detached' = 10,
  'Cooperative' = 2,
  'Manufactured Housing' = 5,
  'PUD' = 7,
  // 'Modular' = 6,
  // 'Manufactured Housing Single Wide' = 8,
  // 'Manufactured Housing Double Wide' = 9,
  // 'Manufactured Home Condominium' = 11,
  // 'Manufactured Housing Multi Wide' = 12,
  // 'Manufactured Home Condominium Or PUD Or Cooperative' = 13,
  // 'Two To Four Unit Building' = 14,
  // 'Non Warranted Condo' = 15,
}

export enum LoanPurposeEnum {
  'Refinance' = 0,
  'Purchase' = 1,
  'Construction Only' = 2,
  'Construction To Permanent' = 3,
  'Other Loan Purpose' = 4,
  'Refinance Cash Out' = 5,
}

export enum PropertyWillBeEnum {
  'Primary Residence' = 0,
  'Secondary Residence' = 1,
  'Investment' = 2,
  // 'Short Term Rental' = 3,
}

export enum RefiPurposeEnum {
  'Purchase' = 0,
  'Refinance Cash Out' = 1,
  'Refinance No Cash Out' = 2,
  'Streamlined Refinance' = 3,
  'Construction' = 4,
}

export enum AmortizationTypeEnum {
  'Fixed Rate' = 0,
  'GEM' = 1,
  'GPM' = 2,
  'ARM' = 3,
  'Other Amortization' = 4,
}

export enum LevelOfEducationEnum {
  'Some High School' = 10,
  'High school diploma or GED' = 12,
  'Some college' = 13,
  "Associate's degree / Two-year" = 14,
  "Bachelor's degree" = 16,
  "Master's degree" = 17,
  'Doctoral degree' = 18,
  'None of the above' = 0,
}

export enum MaritalStatusEnum {
  'Single' = 0,
  'Married' = 1,
  'Separated' = 2,
}

export enum ExpenseTypeEnum {
  'First Mortgage Principal And Interest' = 0,
  'Hazard Insurance' = 1,
  'Homeowners Insurance' = 2,
  'Homeowners Association Dues And Condominium Fees' = 3,
  'Morgage Insurance' = 4,
  'Other Housing Expense' = 5,
  'Other Mortgage Loan Principal And Interest' = 6,
  'Real Estate Tax' = 7,
  'Rent' = 8,
}

export enum EmploymentTypeEnum {
  CurrentEmployer = 'CurrentEmployer',
  FormerEmployer = 'FormerEmployer',
}

export enum BusinessTypeEnum {
  'Sole Proprietor' = 0,
  'LLC' = 1,
  'S-Corp' = 2,
  'C-Corp' = 3,
  'Partnership' = 4,
}

export enum TypeOfIncomeEnum {
  'AlimonyChildSupport' = 'AlimonyChildSupport',
  'AutomobileExpenseAccount' = 'AutomobileExpenseAccount',
  'Base' = 'Base',
  'Bonus' = 'Bonus',
  'Commissions' = 'Commissions',
  'DividendsInterest' = 'DividendsInterest',
  'Employment Income' = 6,
  'FosterCare' = 'FosterCare',
  'NetRentalIncome' = 'NetRentalIncome',
  'Notes Receivable Installment' = 'NotesReceivableInstallment',
  'OtherTypesOfIncome' = 'OtherTypesOfIncome',
  'Overtime' = 'Overtime',
  'Pension' = 'Pension',
  'SocialSecurity' = 'SocialSecurity',
  'SubjectPropertyNetCashFlow' = 'SubjectPropertyNetCashFlow',
  'Trust' = 'Trust',
  'Unemployment' = 'Unemployment',
  'PublicAssistance' = 'PublicAssistance',
  'VABenefitsNonEducational' = 'VABenefitsNonEducational',
  'MortgageDifferentiall' = 'MortgageDifferential',
  'MilitaryBasePay' = 'MilitaryBasePay',
  'MilitaryRationsAllowance' = 'MilitaryRationsAllowance',
  'MilitaryFlightPayy' = 'MilitaryFlightPay',
  'MilitaryHazardPay' = 'MilitaryHazardPay',
  'MilitaryClothesAllowance' = 'MilitaryClothesAllowance',
  'MilitaryQuartersAllowance' = 'MilitaryQuartersAllowance',
  'MilitaryPropPay' = 'MilitaryPropPay',
  'MilitaryOverseasPay' = 'MilitaryOverseasPay',
  'MilitaryCombatPay' = 'MilitaryCombatPay',
  'MilitaryVariableHousingAllowance' = 'MilitaryVariableHousingAllowance',
}

export enum CategoryOfIncomeEnum {
  'Employment (W2)' = 0,
  'Indepedent Contractor' = 1,
  'Military Pay' = 2,
  'Rental' = 3,
  'Social Security' = 4,
  'Pension' = 5,
  'Business / Self Employment' = 6,
  'Other' = 7,
}

export enum DispositionStatusEnum {
  'Sold' = 0,
  'Pending Sale' = 1,
  'Retain For Primary Or Secondary Residence' = 2,
  'Rental Property' = 3,
}

export enum TypeOfPropertyEnum {
  'Single Family' = 0,
  'Condo' = 1,
  'Townhouse' = 2,
  'Coop' = 3,
  'Two To Four Unit Property' = 4,
  'Multifamily More Than Four Units' = 5,
  'Commercial Non Residential' = 6,
  'Mixed Use Residential' = 7,
  'Farm' = 8,
  'Home And Business Combined' = 9,
  'Land' = 10,
}

export type MortgageCalculationType =
  | 'AggregateAdjustment'
  | 'MonthlyPrincipalAndInterest'
  | 'MonthlyMortgageInsurance';
//#endregion
