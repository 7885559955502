export class ResetPasswordRequest {
  email: string;
  password: string;
  confirmPassword: string;
  code: string;
  userCompanyGuid: string;

  constructor(email: string, password: string, confirmedPassword: string, code: string, userCompanyGuid: string) {
    this.email = email;
    this.password = password;
    this.confirmPassword = confirmedPassword;
    this.code = code;
    this.userCompanyGuid = userCompanyGuid;
  }
}
