<div class="card">
  <div class="card-header d-flex align-items-center justify-content-center">
    <h5 class="opacity-80">People on this Loan</h5>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="row my-2" *ngFor="let borrower of loanBorrowers">
        <div
          class="col-4 d-flex align-items-center justify-content-center"
          *ngIf="borrower.borrowerId"
        >
          <span
            class="bp-contact-name-circle p-2 rounded-circle fw-bold d-flex align-items-center justify-content-center"
          >
            {{ borrower.borrowerName?.charAt(0) }}
          </span>
        </div>
        <div class="col-8">
          <div class="opacity-80 fw-bold">{{ borrower.borrowerName }}</div>
          <div *ngIf="borrower.borrowerId">
            <span class="me-4" >
              <a class="nav-link d-inline" href="tel:{{borrower.borrowerPhone}}"><i class="fa fa-phone"></i> Call</a>
            </span>
            <span>
              <a class="nav-link d-inline" href= "mailto: {{borrower.borrowerEmail}}"><i class="fa fa-envelope"></i> Email</a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

