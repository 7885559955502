

  <div class="modal-header">

    <h2>Dependents</h2>

    <!-- hack to fool ngbModal autofocus -->
    <input type="text" style="display:none" />

    <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" aria-label="Close"
      (click)="activeModal.dismiss('Cross click')">
      <span class="svg-icon svg-icon-2x">
        <close-x-svg-icon></close-x-svg-icon>
      </span>
    </div>
  </div>

  <div class="modal-body py-10 px-lg-17">
    <form #dependentsForm="ngForm" class="form" novalidate id="dependentsForm" name="dependentsForm">
      <div class="form-label mb-5">Please click below to add all your dependents and their ages to your application.</div>
      <div class="me-n7 pe-7">
        <div class="d-flex justify-content-center mb-4 mx-5" *ngFor="let dependent of dependents; let i = index">
          <div style="margin-left: 60px;">
            <label *ngIf="i === 0" class="required form-label">Age</label>
            <input class="form-control form-control-solid" #age="ngModel" name="age{{i}}" type="number" min="0" oninput="validity.valid||(value='');"
              [(ngModel)]="dependent.age" [ngClass]="{'is-invalid' : age && age.touched && age.invalid}" required />
          </div>

          <div class="mx-5" style="width: 80px;">
            <a *ngIf="indexToDelete !== i" href="javascript:void(0);" class="btn btn-icon btn-bg-light btn-active-color-danger btn-sm"
            [ngClass]="{'mt-9' : i === 0 }" (click)="onDeleteClicked(i)">
              <span class="svg-icon svg-icon-3">
                <duotone-trash-svg-icon></duotone-trash-svg-icon>
              </span>
            </a>
            <div class="d-flex" [ngClass]="{'mt-9' : i === 0 }" *ngIf="indexToDelete === i">
              <a href="javascript:void(0);" class="btn btn-icon btn-bg-light btn-active-color-success btn-sm me-1" (click)="onDeleteConfirmed(i)">
                <span class="svg-icon svg-icon-3">
                  <stockholm-check-svg-icon></stockholm-check-svg-icon>
                </span>
              </a>
              <a href="javascript:void(0);" class="btn btn-icon btn-bg-light btn-active-color-danger btn-sm" (click)="onDeleteAborted()">
                <span class="svg-icon svg-icon-3">
                  <stockholm-close-svg-icon></stockholm-close-svg-icon>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <a class="btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary mb-4" (click)="addDependentClicked()">
          {{dependents.length === 0 ? 'Add Dependent' : 'Add Another Dependent'}}
        </a>
      </div>
    </form>
  </div>

  <div class="modal-footer flex-center">
    <button type="button" class="btn btn-white me-3" (click)="activeModal.dismiss()">Close</button>
    <button type="button" class="btn btn-primary" (click)="onSaveClicked()">Ok</button>
  </div>




