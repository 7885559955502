import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "monthsToYearsAndMonths"
})
export class MonthsToYearsAndMonthsPipe implements PipeTransform {
  transform(value: number) {
    if (!value) return "0 year(s) 0 month(s)";
    const numberOfYears = Math.floor(value / 12);
    const numberOfMonths = value - numberOfYears * 12;
    return `${numberOfYears} year(s) ${numberOfMonths} month(s)`;
  }
}
