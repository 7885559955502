import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EnvironmentService } from 'projects/shared/services/environment.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { AuthService } from './services/auth.service';
import { Constants, ThirdPartyTrackingScriptConfig } from './services/constants';
import { GlobalConfigService } from './services/global-config.service';
import { NavigationService } from './services/navigation.service';
import { NotificationService } from './services/notification.service';
import { TimerService } from './services/timer.service';
import { UtilsService } from './services/utils.service';
import { PortalScope } from './login/login.component';
import { PageUtils } from 'projects/shared/utils/page.utils';

declare const KTApp: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  @ViewChild('template', { static: true }) _template: any;

  protected faviconUrl: string = '/assets/images/favicon.ico';
  protected companyGuid: string | undefined = undefined;

  private _contextSubscription: Subscription;
  private _activatedRouteSubscription: Subscription;
  private _timerSubscription: Subscription;

  constructor(private readonly _viewContainerRef: ViewContainerRef,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _configService: GlobalConfigService,
    private readonly _utilsService: UtilsService,
    private readonly _environment: EnvironmentService,
    private readonly _timerService: TimerService,
    private readonly _authService: AuthService,
    private readonly _navigationService: NavigationService,
    private readonly _notifyService: NotificationService
  ) {

    let autoLogoutTimerName = "auto-logout-timer";

    const timer = this._timerService.createTimer(autoLogoutTimerName);
    this._timerSubscription = timer.expired.subscribe(() => {
      this._authService.logout();
      this._navigationService.navigateToLogin();
      this._timerService.remove(autoLogoutTimerName);
      this._notifyService.showInfo("You have been logged out.", "Good Bye!")
    });
  }

  ngOnInit() {
    this._viewContainerRef.createEmbeddedView(this._template);
    this._configService.getClientIp().subscribe(res => {
      localStorage.setItem(Constants.clientIpKey, res ? JSON.stringify(res) : null);
    });
    this._activatedRouteSubscription = this._activatedRoute.queryParams.subscribe((queryParams) => {
      const companyGuid = queryParams.companyGuid;
      if (companyGuid) {
        if (this._environment.production) {
          this.injectThirdPartyTrackingScripts(companyGuid);
        }
      }
      this.companyGuid = companyGuid;
      const userScope = this.getUserScope();
      PageUtils.fixLayoutMarkerBasedOnScope(userScope);
      this.faviconUrl = this._environment.faviconUrl + (companyGuid || '');
      this.setPageTitle(userScope);
    });
  }

  ngOnDestroy(): void {
    this._contextSubscription?.unsubscribe();
    this._timerSubscription?.unsubscribe();
    this._activatedRouteSubscription?.unsubscribe();
  }

  private setPageTitle = (userScope: PortalScope) => {
    if (userScope) {
      document.title = userScope == 'Borrower' ? "Borrower Portal" : "Agent Portal";
    }
  }

  private injectThirdPartyTrackingScripts = (companyGuid: string) => {
    this._configService.getThirPartTrackingCodes(companyGuid).subscribe(codes => {
      if (codes.facebookTrackingCode) {
        this.injectTrackingCodeScripts(codes.facebookTrackingCode, Constants.thirdPartyTrackingScripts.facebook);
      }
      if (codes.googleTrackingCode) {
        this.injectTrackingCodeScripts(codes.googleTrackingCode, Constants.thirdPartyTrackingScripts.google);
      }
      if (codes.hubspotTrackingCode) {
        this.injectTrackingCodeScripts(codes.hubspotTrackingCode, Constants.thirdPartyTrackingScripts.hubSpot);
      }
    }, error => {
      // swallow
    });
  }

  private getUserScope = (): PortalScope => {
    let userScope = this._environment.scope as PortalScope;
    if (!userScope) {
      const authData = JSON.parse(localStorage.getItem(Constants.authorization.authorizationDataKey));
      if (authData) {
        userScope = authData.scope;
      }
    }
    return userScope;
  }

  private injectTrackingCodeScripts = (trackingCode: string, trackingScripts: ThirdPartyTrackingScriptConfig) => {
    if (trackingScripts.scriptLinks) {
      trackingScripts.scriptLinks.forEach(scriptLink => {
        const source = scriptLink.source.replace(trackingScripts.key, trackingCode);
        this._utilsService.injectJavaScriptAsLink(source, scriptLink.async, scriptLink.defer, scriptLink.id);
      });
    }
    if (trackingScripts.codeScripts) {
      trackingScripts.codeScripts.forEach(codeScript => {
        const code = codeScript.replace(trackingScripts.key, trackingCode);
        this._utilsService.injectJavaScriptAsCode(code);
      });
    }
    if (trackingScripts.noscriptPixelTrackers) {
      trackingScripts.noscriptPixelTrackers.forEach(source => {
        const noscriptToInject = source.replace(trackingScripts.key, trackingCode);
        this._utilsService.injectNoScriptPixelTracker(noscriptToInject);
      });
    }
  }
}



