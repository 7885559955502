<div *ngIf="checklists.length" class="row mb-4">
  <div class="checkbox mb-3" *ngFor="let checklist of checklists">
    <ng-container *ngIf="checklist.checkListName">
      <label class="form-check form-check-inline form-check-solid me-2" for="checklist_{{checklist.checklistId}}">
        <input
          id="checklist_{{checklist.checklistId}}"
          name="checklist_{{checklist.checklistId}}"
          type="checkbox"
          (change)="onCheckListCheckChanged(checklist.checklistId, $event)"
          [checked]="selectedChecklistIds.includes(checklist.checklistId)"
          class="form-check-input">
        <span class="fw-bold ps-2 fs-5">{{checklist.checkListName}}</span>
      </label>
    </ng-container>
  </div>
</div>
