import { AfterViewInit, Component, Injector, OnInit } from '@angular/core';
import { ModalService } from 'projects/shared/modal/modal.service';
import { Borrower } from '../../../models/borrower-model';
import { MortgageApplication } from '../../../models/mortgage-app.model';
import { LiabilitiesStep } from '../../../models/wizard/liabilites-step.model';
import { MortgageApplicationService } from '../../../services/mortgage-application.service';
import { WizardStepComponentBase } from '../wizard-step-base.component';

@Component({
  selector: 'liabilities-step',
  templateUrl: 'liabilities-step.component.html'
})

export class LiabilitiesStepComponent extends WizardStepComponentBase<LiabilitiesStep> implements OnInit, AfterViewInit {

  private _mortgageApplication: MortgageApplication;
  private _currentBorrower: Borrower;

  constructor(private readonly _modalService: ModalService,
    private readonly _mortgageApplicationService: MortgageApplicationService,
    private readonly _injector: Injector) {
    super(_injector);
    this._mortgageApplication = this._mortgageApplicationService.mortgageApplication;
    this._currentBorrower = this._mortgageApplication.borrowers[0];
    this.populateLiabilityItems();
  }

  ngOnInit() { }

  onAddLiabilityClicked = () => {

  }

  private populateLiabilityItems = () => {

  }
}
