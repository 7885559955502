export class BorrowerCounselingEvent {
  borrowerCounselingEventId: number;
  borrowerId: number;
  counselingAgencyName: string;
  counselingAgencyIdentifier: string;
  borrowerHasCompleted: boolean;
  completedDate: string;
  counselingType: string;
  format: string;
  formatOtherDescription: string;
  companyId: number;
  insertedBy: string;
  updatedBy: string;
  dateInserted: string;
  dateUpdated: string;
  counselingAgencyAddress1: string;
  counselingAgencyAddress2: string;
  counselingAgencyCity: string;
  counselingAgencyState: string;
  counselingAgencyZipCode: string;
}
