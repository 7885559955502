import { Injectable } from "@angular/core";
import { WizardFlowContext } from "../../../wizard-flow-context.model";
import { GenericStepFactory } from "./generic-step.factory";
import { NextStepDeciderFactory } from "./next-step-decider.factory";
import { ICreateStep } from "./step-factory.interface";
import { PurchaseCreditsStep } from "../../purchase-credits-step.model";
import { PurchaseCreditsStepConfig } from "../purchase-credits-step-config.model";

@Injectable()
export class PurchaseCreditsStepFactory extends GenericStepFactory<PurchaseCreditsStep> implements ICreateStep {

  constructor(nextStepDeciderFactory: NextStepDeciderFactory) {
    super(nextStepDeciderFactory, PurchaseCreditsStep);
  }

  create(config: PurchaseCreditsStepConfig, wizardFlowContext: WizardFlowContext): PurchaseCreditsStep {
    let purchaseCreditsStep = super.create(config, wizardFlowContext)
    purchaseCreditsStep.underContractActionLabel = config.underContractActionLabel || 'Under Contract';
    purchaseCreditsStep.lookingToGetPreApprovedActionLabel = config.lookingToGetPreApprovedActionLabel || 'Looking to Get Pre-approved';
    purchaseCreditsStep.subjectPropertyAddressTbdLabel = config.subjectPropertyAddressTbdLabel || 'TBD';
    purchaseCreditsStep.restrictStatesToLicenseStates =
      (config.restrictStatesToLicenseStates != null ? config.restrictStatesToLicenseStates : false);
    purchaseCreditsStep.requireUserToEnterZipcode =
      (config.requireUserToEnterZipcode != null ? config.requireUserToEnterZipcode : false);
    purchaseCreditsStep.autoSelectLookingToGetPreApproved = (config.autoSelectLookingToGetPreApproved != null ?
      config.autoSelectLookingToGetPreApproved: false);
    purchaseCreditsStep.autoSelectTbdForSubjectPropertyAddress = (config.autoSelectTbdForSubjectPropertyAddress != null ?
      config.autoSelectTbdForSubjectPropertyAddress : false);
    return purchaseCreditsStep;
  }

  configure(step: PurchaseCreditsStep, config: PurchaseCreditsStepConfig): void {
    super.configure(step, config);
    config.underContractActionLabel = step.underContractActionLabel;
    config.lookingToGetPreApprovedActionLabel = step.lookingToGetPreApprovedActionLabel;
    config.subjectPropertyAddressTbdLabel = step.subjectPropertyAddressTbdLabel;
    config.restrictStatesToLicenseStates = step.restrictStatesToLicenseStates;
    config.requireUserToEnterZipcode = step.requireUserToEnterZipcode;
    config.autoSelectLookingToGetPreApproved = step.autoSelectLookingToGetPreApproved;
    config.autoSelectTbdForSubjectPropertyAddress = step.autoSelectTbdForSubjectPropertyAddress;
  }
}
