import { Component, Input, OnInit } from '@angular/core';
import { ChecklistQuestion } from 'projects/borrower-app/src/app/models/checklists/borrower-flow/checklist-question.model';
import { QuestionSummary } from 'projects/borrower-app/src/app/models/checklists/config/checklist-config.model';

@Component({
  selector: 'ask-question',
  templateUrl: 'ask-question.component.html'
})

export class AskQuestionComponent implements OnInit {

  @Input()
  allQuestionsMetaData: QuestionSummary[] = [];

  @Input()
  question: ChecklistQuestion;

  @Input()
  questionMetaData: QuestionSummary;

  questionMetaDataByQuestionId: Map<number, QuestionSummary> = new Map<number, QuestionSummary>();

  multipleAnswersGivenToQuestion: number[] = [];
  singleAnswerGivenToQuestion: string;

  constructor() { }

  ngOnInit() {
    this.allQuestionsMetaData.forEach(q => {
      this.questionMetaDataByQuestionId.set(q.questionId, q);
    })
  }

  onAnswerChanged = () => {
    const x = this.multipleAnswersGivenToQuestion;
  }
}
