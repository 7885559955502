import { Component, Injector, OnInit } from '@angular/core';
import { LoanCharacteristic } from '../../../models/characteristics/loan-characteristic.model';
import { TaskCategory } from '../../../models/characteristics/task-category.model';
import { LoanCharacteristicsStep } from '../../../models/wizard/loan-characteristics-step.model';
import { CharacteristicsService } from '../../../services/characteristics.service';
import { WizardStepComponentBase } from '../wizard-step-base.component';
import * as _ from 'lodash';
import { ErrorMessage } from '../../../models/error-message.model';
import { LoanDocTask } from '../../../models/tasks/loan-doc-task.model';
import { CharacteristicMergeValue } from '../../../models/characteristics/characteristic-merge-value.model';
import { CharacteristicsQuantitiesDialogComponent } from '../../characteristics-quantities-dialog/characteristics-quantities-dialog.component';
import { LoanCharacteristicFactory } from '../../characteristics-quantities-dialog/loan-characteristic.factory';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from '../../../services/constants';
import { CharacteristicsPreviewDialogComponent } from '../characteristics-preview-dialog/characteristics-preview-dialog.component';

@Component({
  selector: 'loan-characteristics-step',
  templateUrl: 'loan-characteristics-step.component.html'
})
export class LoanCharacteristicsStepComponent extends WizardStepComponentBase<LoanCharacteristicsStep> implements OnInit {

  categories: TaskCategory[] = [];

  isLoading: boolean = false;

  private _characteristics: LoanCharacteristic[] = [];

  private _nextCharacteristicId: number = -1;

  constructor(private readonly _modalService: NgbModal,
    private readonly _characteristicsService: CharacteristicsService,
    private readonly _injector: Injector) {
    super(_injector);
  }

  ngOnInit() {
    if (!this.wizardFlowService.isEditEnabled) {
      this.initializeInNonEditMode();
    } else {
      this.initializeInEditMode();
    }
  }

  onCharacteristicCheckChanged = (characteristicId: number) => {
    let index = [];
    for (let i = 0; i < this._characteristics.length; i++) {
      if (this._characteristics[i].characteristicId === characteristicId) {
        index.push(i);
      }
    }
    if (index.length === 0) {
      const newOne = new LoanCharacteristic(this._nextCharacteristicId--, this.mortgageApplication.applicationId, characteristicId);
      this._characteristics.push(newOne);

      if (this.categories.filter(x => x.taskCategoryId == characteristicId)[0].taskOption === 'RequestQuantity')
        this.openEditCharacteristicDialog(characteristicId, true);
    }
    else {
      index.forEach(i => {
        this._characteristics[i].pendingDeletion = this._characteristics[i].pendingDeletion === true ? false : true;
      });
    }
  }

  onNextClicked = () => {
    if (this.wizardFlowService.isEditEnabled) {
      super.onNextClicked();
      return;
    }
    this.startSpinner();
    const onesToSave = this._characteristics.filter(c => !c.pendingDeletion);
    this._characteristicsService.saveLoanCharacteristicsPreview(this.mortgageApplication.applicationId, onesToSave).subscribe((loanDocTasks: LoanDocTask[]) => {
      if (loanDocTasks && loanDocTasks.length > 0) {
        this.showCharacteristicsPreviewDialog(onesToSave, loanDocTasks);
      } else {
        this._characteristicsService.saveLoanCharacteristics(this.mortgageApplication.applicationId, onesToSave, []).subscribe(() => {
          this.wizardFlowService.navigateForward();
        }, error => {
          const errorMessage = new ErrorMessage('Error - Loan Characteristics!', error ? error.message : 'Unable to save loan characteristics.');
          this.showError(errorMessage);
        }).add(() => {
          this.stopSpinner();
        });
      }
    }, error => {
      const errorMessage = new ErrorMessage('Error - Loan Characteristics!', error ? error.message : 'Unable to save loan characteristics preview.');
      this.showError(errorMessage);
    }).add(() => {
      this.stopSpinner();
    });
  }

  openEditCharacteristicDialog = (characteristicId: number, addingForTheFirstTime: boolean) => {
    const category = this.categories.find(c => c.taskCategoryId == characteristicId);
    let existingCharacteristics = this._characteristics.filter(x => x.characteristicId == characteristicId);
    if (existingCharacteristics.length === 0) {
      let newOne = new LoanCharacteristic(this._nextCharacteristicId--, this.mortgageApplication.applicationId, characteristicId);
      this._characteristics.push(newOne);
      existingCharacteristics = this._characteristics.filter(x => x.characteristicId == characteristicId);
      addingForTheFirstTime = true;
    }
    existingCharacteristics.forEach(c => {
      if (!c.characteristicMergeValues || c.characteristicMergeValues.length === 0) {
        c.characteristicMergeValues = [];
        if (category && category.fields) {
          category.fields.forEach(f => {
          c.characteristicMergeValues.push(new CharacteristicMergeValue(f.characteristicFieldId));
        });
       }
      }
    });

    const modalRef = this._modalService.open(CharacteristicsQuantitiesDialogComponent, Constants.modalOptions.large);
    modalRef.componentInstance.taskCategory = this.categories.find(c => c.taskCategoryId === characteristicId);
    modalRef.componentInstance.nextCharacteristicId = this._nextCharacteristicId;
    modalRef.componentInstance.characteristics = existingCharacteristics;
    modalRef.componentInstance.characteristicFactory = new LoanCharacteristicFactory();
    modalRef.componentInstance.applicationId = this.mortgageApplication.applicationId;

    modalRef.result.then((result) => {
      if (result !== 'cancel') {
        let remaining = this._characteristics.filter(x => x.characteristicId !== characteristicId);
        if (result != undefined) {
          result.forEach((characteristic: LoanCharacteristic) => {
            remaining.push(characteristic);
          });
        }
        this._characteristics = remaining;
      } else {
        if (addingForTheFirstTime) {
          this._characteristics = this._characteristics.filter(c => c.characteristicId !== characteristicId);
        }
      }
    }, (err) => {
      if (addingForTheFirstTime) {
        this._characteristics = this._characteristics.filter(c => c.characteristicId !== characteristicId);
      }
    });
  }

  isCharacteristicCheckedForLoan = (characteristicId: number): boolean => {
    const quantity = this.getCharacteristicQuantity(characteristicId);
    return quantity > 0;
  }

  getCharacteristicQuantity = (characteristicId: number): number => {
    var bcs = this._characteristics.filter(c => c.characteristicId == characteristicId && !c.pendingDeletion);
    if (bcs && bcs.length > 0) {
        return bcs.length;
    }
    return 0;
  }

  private initializeInNonEditMode = () => {
    this.startSpinner();
    this.isLoading = true;
    this._characteristicsService.getTaskCategories().subscribe(categories => {
      this.categories = categories.filter(c => c.characteristicType === "LoanCharacteristic" && c.questionText);
      if (!this.categories || !this.categories.length) {
        if (this.cameHereWithBackNavigation) {
          this.wizardFlowService.navigateBackward();
        } else {
          this.onNextClicked();
        }
        return;
      }
      this._characteristicsService.getLoanCharacteristics(this.mortgageApplication.applicationId).subscribe(characteristics => {
        this._characteristics = characteristics;
        this.isLoading = false;
      }, error => {
        this.isLoading = false;
        this.showError(new ErrorMessage('Error - Loan Characteristics!',
          error ? error.message : 'Unable to get loan characteristics.')
        );
      });
    }, error => {
      this.showError(new ErrorMessage('Error - Loan Characteristics!',
        error ? error.message : 'Unable to get loan characteristics.')
      );
    }).add(() => {
      this.isLoading = false;
      this.stopSpinner();
    })
  }

  private initializeInEditMode = () => {
    const category1 = {onlineAppEnabled: true, questionText: 'Test Category'} as TaskCategory;
    const category2 = {onlineAppEnabled: true, questionText: 'Test Category2'} as TaskCategory;
    const category3 = {onlineAppEnabled: true, questionText: 'Test Category3'} as TaskCategory;
    this.categories = [category1, category2, category3];
    const characteristic1 = new LoanCharacteristic(-1, -1, -1);
    this._characteristics = [characteristic1];
  }

  private showCharacteristicsPreviewDialog = (characteristicsToSave: LoanCharacteristic[], tasks: LoanDocTask[]) => {
    const modalRef = this._modalService.open(CharacteristicsPreviewDialogComponent, Constants.modalOptions.xlarge);
    modalRef.componentInstance.characteristicType = 2;
    modalRef.componentInstance.tasksPreview = tasks;
    modalRef.componentInstance.taskCategories = this.categories;

    modalRef.result.then((result) => {
      if (result !== 'cancel') {
        this.saveLoanCharacteristics(characteristicsToSave, result);
      } else {
        const errorMessage = new ErrorMessage('Warning - Loan Characteristics!', 'Loan characteristics save operation has been cancelled.');
        this.showError(errorMessage);
      }
    }, (error) => {
      this.showError(new ErrorMessage('Warning!', 'Loan characteristics save operation has been cancelled.')
      );
    });
  }

  private saveLoanCharacteristics = (characteristics: LoanCharacteristic[], tasks: LoanDocTask[]) => {
    this._characteristicsService.saveLoanCharacteristics(this.mortgageApplication.applicationId, characteristics, tasks).subscribe(result => {
      this.wizardFlowService.navigateForward();
    }, error => {
      const errorMessage = new ErrorMessage('Error - Loan Characteristics!',
      error ? error.message : 'Unable to save loan characteristics.',);
      this.showError(errorMessage);
    });
  }
}
