import { AbstractValueAccessor } from "projects/shared/abstract-value-accessor";
import { FieldConfig } from "./config/field-config-model";

export class ConfigurableFieldHost extends AbstractValueAccessor {

  hasCustomFieldConfigEditor: boolean = false;

  protected _fieldsToConfig: any = {};

  set fieldConfig(value: any) {
    this._fieldsToConfig = value;
  }

  get fieldConfig(): any {
    return this._fieldsToConfig;
  }

  isHidden = (fieldName: string): boolean => {
    if (this.fieldConfig && this.fieldConfig[fieldName]) {
      return this.fieldConfig[fieldName].isHidden;
    }
    return false;
  }

  isRequired = (fieldName: string): boolean => {
    if (this.fieldConfig && this.fieldConfig[fieldName]) {
      return this.fieldConfig[fieldName].isRequired;
    }
    return true;
  }

  getLabel = (fieldName: string, defaultLabel: string): string => {
    if (this.fieldConfig && this.fieldConfig[fieldName]) {
      return this.fieldConfig[fieldName].displayName;
    }
    return defaultLabel;
  }

  getFieldsToConfig = (fieldConfig: any): FieldConfig[] => {
    let result: FieldConfig[] = [];
    Object.entries(fieldConfig).forEach(
      ([key, value]) => result.push(<FieldConfig>value));
    return result;
  }
}
