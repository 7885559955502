import { AfterViewInit, Component, Injector, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from 'projects/shared/modal/modal.service';
import { Borrower } from '../../../models/borrower-model';
import * as _ from 'lodash';
import { RealEstateOwnedStep } from '../../../models/wizard/reo-step.model';
import { RealEstateOwnedDialogComponent } from '../reo-dialog/reo-dialog.component';
import { RealEstateOwnedItem } from '../../../models/real-estate-owned-item-model';
import { RealEstateOwnedItemComponent } from '../reo-item/reo-item.component';
import { EnumerationItem } from 'projects/shared/models/enumeration-item.model';
import { EnumsService } from '../../../services/enums.service';
import { WizardFlowServiceBase } from '../../../services/wizard/wizard-flow-service.base';
import { WizardStepComponentBase } from '../wizard-step-base.component';
import { RealEstateOwned } from '../../../models/mortgage.model';

declare const Swal: any;

@Component({
  selector: 'reo-step',
  templateUrl: 'reo-step.component.html'
})
export class RealEstateOwnedStepComponent extends WizardStepComponentBase<RealEstateOwnedStep> implements OnInit, AfterViewInit, OnDestroy {

  @ViewChildren('reoItem') reoItemComponents: QueryList<RealEstateOwnedItemComponent> | undefined;

  private modalOptions: NgbModalOptions = {
    size: "lg",
    backdrop: "static",
    container: "#reo-main"
  };

  reoItems: RealEstateOwnedItem[] = [];

  private _isValid = true;
  private _subscriptionToReoSavedForCreate: any;
  private _subscriptionToReoSavedForEdit: any;

  constructor(private readonly _modalService: ModalService,
    private readonly _injector: Injector,
    private readonly _enumService: EnumsService,
    private readonly _wizardFlowService: WizardFlowServiceBase) {
    super(_injector);
    this.saveMortgageApplicationBeforeNextStep = true;
    this.populateReoItems();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this._subscriptionToReoSavedForCreate) {
      this._subscriptionToReoSavedForCreate.unsubscribe();
    }
    if (this._subscriptionToReoSavedForEdit) {
      this._subscriptionToReoSavedForEdit.unsubscribe();
    }
  }

  onAddRealEstateOwnedClicked = () => {
    if (!this.inEditMode) {
      this.showRealEstateOwnedPopup();
    }
  }

  onReoItemDeleted = (reoItem: RealEstateOwnedItem) => {
    const index = this.reoItems.indexOf(reoItem);
    const reos = this.mortgageApplication.realEstateOwned;
    if (index >= 0) {
      this.reoItems.splice(index, 1);
    }
    if (!reos) {
      return;
    }
    const reoToDelete = reos.find(i => i.reoId === reoItem.id);
    if (reoToDelete) {
      const index = reos?.indexOf(reoToDelete);
      reos.splice(index, 1);
    }
    const relatedLiabilities = this.mortgageApplication.liabilities?.filter(l => l.reoId === reoItem.id);
    relatedLiabilities.forEach(liability => {
      liability.reoId = null;
    });
    this.checkReoItemsValid();
  }

  onReoItemEditClicked = (reoItem: RealEstateOwnedItem) => {
    const reoItemToEdit = this.reoItems.find(i => i.id === reoItem.id);
    if (!reoItemToEdit) {
      return;
    }
    const reoToEdit = this.mortgageApplication.realEstateOwned?.find(e => e.reoId === reoItem.id);
    if (reoToEdit) {
      this.showRealEstateOwnedPopup(_.cloneDeep(reoToEdit));
    }
  }

  onNextClicked() {
    if (!this._isValid) {
      let invalidItem: RealEstateOwnedItem;
      this.reoItems.forEach(reoItem => {
        if (!reoItem.isValid) {
          invalidItem = reoItem;
        }
      });
      const reoToEdit = this.mortgageApplication.realEstateOwned?.find(e => e.reoId === invalidItem.id);
      if (reoToEdit) {
        this.showRealEstateOwnedPopup(_.cloneDeep(reoToEdit));
        return;
      }
    } else {
      super.onNextClicked();
    }
  }

  private checkReoItemsValid() {
    for (let i = 0; i < this.reoItems.length; i++) {
      if (!this.reoItems[i].isValid) {
        this._isValid = false;
        return;
      }
    }
    this._isValid = true;
  }

  private populateReoItems() {
    this.mortgageApplication.realEstateOwned?.forEach(reo => {
      let reoItem = this.toReoItem(reo);
      if (reoItem) {
        this.reoItems.push(reoItem)
      }
    });
    this.checkReoItemsValid();
  }

  private showRealEstateOwnedPopup(reo?: RealEstateOwned) {
    const modal = this._modalService.show(RealEstateOwnedDialogComponent, this.modalOptions);
    if (reo) {
      modal.componentInstance.reo = reo;
    } else {
      modal.componentInstance.selectedBorrowers = [new EnumerationItem(this.currentBorrower.firstName,
        <string>this.currentBorrower.borrowerId?.toString(),)]
    }
    modal.componentInstance.inCoApplyFlow = this.wizardFlowService.inCoApplyFlow;
    modal.componentInstance.fieldsToConfig = this.step.fieldConfig;
    this._subscriptionToReoSavedForCreate = modal.componentInstance.saveClickedEmitterForCreate
      .subscribe((e: RealEstateOwned) => this.onSaveClickedOnReoModalForCreate(e));
    this._subscriptionToReoSavedForEdit = modal.componentInstance.saveClickedEmitterForEdit
      .subscribe((e: RealEstateOwned) => this.onSaveClickedOnReoModalForEdit(e));
  }

  private isReoValid(reo: RealEstateOwned): boolean {
    // reoStepFieldConfig["reo.typeOfProperty"] = new FieldConfig("reo.typeOfProperty", "Property Type");
    // reoStepFieldConfig["reo.marketValue"] = new FieldConfig("reo.marketValue", "Property Value");
    // reoStepFieldConfig["reo.currentUsageType"] = new FieldConfig("reo.currentUsageType", "How do you currently use this property?");
    // reoStepFieldConfig["reo.intendedUsageType"] = new FieldConfig("reo.intendedUsageType", "How do you plan on using this property?");
    // reoStepFieldConfig["reo.dispositionStatus"] = new FieldConfig("reo.dispositionStatus", "What are your plans for this property?");
    // reoStepFieldConfig["reo.amountOfMortgage"] = new FieldConfig("reo.amountOfMortgage", "Current balance of existing liens on this property?");
    // reoStepFieldConfig["reo.mortgagePayment"] = new FieldConfig("reo.mortgagePayment", "Monthly Payment");
    // reoStepFieldConfig["reo.monthlyMiscExpenses"] = new FieldConfig("reo.monthlyMiscExpenses", "Insurance, HOA, Tax");
    // reoStepFieldConfig["reo.purchasePrice"] = new FieldConfig("reo.purchasePrice", "Purchase Price");
    // reoStepFieldConfig["reo.dateAcquired"] = new FieldConfig("reo.dateAcquired", "Date Acquired");
    if ((!reo.address1 || reo.address1.length === 0) && (!reo.address2 || reo.address2.length === 0)) {
      return false;
    }
    if (!reo.city || reo.city.length === 0) {
      return false;
    }
    if (!reo.state || reo.state.length === 0) {
      return false;
    }
    if (!reo.zipCode || reo.zipCode.length === 0) {
      return false;
    }
    if (!this.isHidden("reo.typeOfProperty")) {
      if (this.isRequired("reo.typeOfProperty")) {
        if (reo.typeOfProperty == null || reo.typeOfProperty.length === 0) {
          return false;
        }
        if (!this._enumService.reoPropertyTypes.find(p => p.value == reo.typeOfProperty)) {
          return false;
        }
      }
    }
    if (!this.isHidden("reo.marketValue") && this.isRequired("reo.marketValue")) {
      if (reo.marketValue == null) {
        return false;
      }
    }
    if (!this.isHidden("reo.purchasePrice") && this.isRequired("reo.purchasePrice")) {
      if (reo.purchasePrice == null) {
        return false;
      }
    }
    if (!this.isHidden("reo.dateAcquired") && this.isRequired("reo.dateAcquired")) {
      if (reo.dateAcquired == null) {
        return false;
      }
    }
    if (!this.isHidden("reo.currentUsageType")) {
      if (this.isRequired("reo.currentUsageType")) {
        if (reo.currentUsageType == null || reo.currentUsageType.length === 0) {
          return false;
        }
        if (!this._enumService.usageTypes.find(u => u.value == reo.currentUsageType)) {
          return false;
        }
      }
    }

    if (!this.isHidden("reo.intendedUsageType")) {
      if (this.isRequired("reo.intendedUsageType") && (reo.dispositionStatus !== 'PendingSale')) {
        if (reo.intendedUsageType == null || reo.intendedUsageType.length === 0) {
          return false;
        }
        if (!this._enumService.usageTypes.find(u => u.value == reo.intendedUsageType)) {
          return false;
        }
      }
    }

    if (!this.isHidden("reo.dispositionStatus")) {
      if (this.isRequired("reo.dispositionStatus")) {
        if (reo.dispositionStatus == null || reo.dispositionStatus.length === 0) {
          return false;
        }
        if (!this._enumService.reoStatusTypes.find(r => r.value == reo.dispositionStatus)) {
          return false;
        }
      }
    }
    if (!this.isHidden("reo.amountOfMortgage") && this.isRequired("reo.amountOfMortgage")) {
      if (reo.amountOfMortgage == null) {
        return false;
      }
    }
    if (!reo.owningBorrowerIds || reo.owningBorrowerIds.length === 0) {
      return false;
    }
    return true;
  }

  private toReoItem = (reo: RealEstateOwned): RealEstateOwnedItem | undefined => {
    let owningBorrowerIds: number[] = reo.owningBorrowerIds;
    if (this._wizardFlowService.inCoApplyFlow) {
      // In co-apply flow, you're not supposed to see assets that you do not have ownership on
      if (!reo.owningBorrowerIds.includes(this._wizardFlowService.context.currentBorrower.borrowerId!)) {
        return undefined;
      }
    }
    let owners: string = "";
    if (!owningBorrowerIds) {
      return new RealEstateOwnedItem(reo.reoId!, reo.address1!, reo.typeOfProperty!,
        reo.marketValue!, owners, this.isReoValid(reo));
    }

    for (let i = 0; i < owningBorrowerIds.length; i++) {
      let borrower: Borrower | undefined = this.mortgageApplication.borrowers.find(b => b.borrowerId === owningBorrowerIds[i]);
      if (borrower) {
        owners = owners + " " + borrower.firstName + " " + borrower.lastName;
      }
      if (i != owningBorrowerIds.length - 1) {
        owners = owners + ",";
      }
    }

    let reoItem = new RealEstateOwnedItem(reo.reoId!, reo.address1!, reo.typeOfProperty!,
      reo.marketValue!, owners, this.isReoValid(reo));
    if (this._wizardFlowService.inCoApplyFlow) {
      reoItem.isReadOnly = reo.owningBorrowerIds.length > 1;
    }
    return reoItem;
  }

  private onSaveClickedOnReoModalForCreate = (reo: RealEstateOwned): void => {
    this.mortgageApplication.realEstateOwned?.push(reo);
    if (reo.dateAcquired) {
      reo.dateAcquired = new Date(reo.dateAcquired).toISOString();
    }

    const reoItem = this.toReoItem(reo);
    if (reoItem) {
      this.reoItems.push(reoItem);
      this.checkReoItemsValid();
    }
  }

  private onSaveClickedOnReoModalForEdit = (reo: RealEstateOwned): void => {
    if (reo.dateAcquired) {
      reo.dateAcquired = new Date(reo.dateAcquired).toISOString();
    }
    const reos = this.mortgageApplication.realEstateOwned;
    if (reos) {
      const existingReo = reos?.find(e => e.reoId === reo.reoId);
      if (existingReo) {
        const index = reos?.indexOf(existingReo);
        reos[index] = reo;
      }
    }
    var reoItem = this.reoItems.find(i => i.id === reo.reoId);
    if (reoItem) {
      const indexItem = this.reoItems.indexOf(reoItem);
      let updatedReoItem = this.toReoItem(reo);
      if (updatedReoItem) {
        updatedReoItem.isValid = this.isReoValid(reo);
        this.reoItems[indexItem] = updatedReoItem;
      }
      this.checkReoItemsValid();
    }
  }
}
