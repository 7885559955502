
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from './data.service';
import { BorrowerVerificationOrderHistory } from '../models/borrower-verification-order-history.model';

const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'auth-req': 'true', 'X-Requested-With': 'XMLHttpRequest' });

@Injectable()
export class BorrowerVerificationService {

  constructor(private readonly _dataService: DataService) {
  }

  public markBorrowerVerificationTaskComplete = (loanDocTaskId: number): Observable<any> => {
    const url = `api/online-app/borrower-verification/task/${loanDocTaskId}/mark-complete`;
    return this._dataService.post(url, {});
  }

  public processEvents = (borrowerVerificationOrderId: number): Observable<BorrowerVerificationOrderHistory> => {
    const url = `api/online-app/borrower-verification/orders/${borrowerVerificationOrderId}/process-events`;
    return this._dataService.post(url, {});
  }
}
