import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Address } from '../../../models/address-model';
import { AddressStep } from '../../../models/wizard/address-step.model';
import { WizardFlowServiceBase } from '../../../services/wizard/wizard-flow-service.base';
import { AddressComponent } from '../../address/address.component';
import { WizardStepComponentBase } from '../wizard-step-base.component';

@Component({
  selector: 'address-step',
  templateUrl: 'address-step.component.html'
})
export class AddressStepComponent extends WizardStepComponentBase<AddressStep> implements OnInit {

  @ViewChild(AddressComponent) addressComponent: AddressComponent | undefined;

  public address: Address = new Address();

  constructor(private readonly _injector: Injector, private readonly _wizardFlowService: WizardFlowServiceBase) {
    super(_injector);
  }

  onNextClicked() {
    if (this.addressComponent?.validate()) {
      this.step.setAddress(this.address);
      super.onNextClicked();
    }
  }

  ngOnInit() {
    super.ngOnInit();
    this.address = this.step.address;
  }
}
