import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as FileSaver from "file-saver";
import { ApplicationTaskModel } from '../../../models/borrower-portal/application-task-model';

@Component({
  selector: 'app-attached-documents-dialog',
  templateUrl: './attached-documents-dialog.component.html',
  styleUrls: ['./attached-documents-dialog.component.scss']
})
export class AttachedDocumentsDialogComponent implements OnInit {

  @Input() task: ApplicationTaskModel;

  constructor( public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  goToFileLink(url: string){
    window.open(url.replace("httsp://", "https://"), "_blank");
  }

  downloadLink(url: string, fileName: string) {
    FileSaver.saveAs(url.replace("httsp://", "https://"), fileName);
  }

}
