<div class="row mb-4">
  <div class="checkbox mb-2" *ngFor="let category of categories">
    <ng-container *ngIf="category.onlineAppEnabled">
      <label class="form-check form-check-inline form-check-solid me-2" for="char_{{category.taskCategoryId}}">
        <input id="char_{{category.taskCategoryId}}" name="char_{{category.taskCategoryId}}" type="checkbox"
          (change)="onCharacteristicCheckChanged(category.taskCategoryId, borrowerId)"
          [checked]="isCharacteristicCheckedForLoan(category.taskCategoryId)" class="form-check-input">
        <span class="fw-bold ps-2 fs-5">{{category.questionText}}</span>
      </label>
      <span *ngIf="category.taskOption === 'RequestQuantity'">
        <a class="text-primary" (click)="openEditCharacteristicDialog(category, borrowerId, false)"> {{'(' +
          getCharacteristicQuantity(category.taskCategoryId) + ')'}}</a>
      </span>
    </ng-container>
  </div>
</div>
