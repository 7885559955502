<div class="w-100">
  <h5 class="my-2 d-flex justify-content-center">
    <span style="max-width: 500px" class="text-gray-700 fs-4 fw-bold">
      First of all, what is the address of the property you're looking to
      prequalify for?
    </span>
  </h5>
  <form #propertyAddressForm="ngForm" novalidate class="form-horizontal">
    <div class="row p-4 mb-3">
      <div class="form-group mb-3 col-md-12">
        <label class="form-label" for="address">Street Address</label>
        <input
          type="text"
          class="form-control"
          [(ngModel)]="request.address"
          name="address"
          required
          #addressInput="ngModel"
          [ngClass]="{
            'is-invalid':
              addressInput && addressInput.touched && addressInput.invalid
          }"
        />
        <div class="invalid-feedback">This field is required</div>
      </div>
      <div class="form-group mb-3 col-md-6">
        <label class="form-label" for="city">City</label>
        <input
          type="text"
          class="form-control"
          [(ngModel)]="request.city"
          name="city"
          [required]="false"
          #cityInput="ngModel"
          [ngClass]="{
            'is-invalid': cityInput && cityInput.touched && cityInput.invalid
          }"
        />
        <div class="invalid-feedback">This field is required</div>
      </div>
      <div class="form-group mb-3 col-md-3">
        <label class="form-label" for="state">State</label>
        <select
          class="form-select"
          [(ngModel)]="request.state"
          [disabled]="!stateChangingEnabled"
          name="state"
          required
          #stateInput="ngModel"
          [ngClass]="{
            'is-invalid': stateInput && stateInput.touched && stateInput.invalid
          }"
        >
          <option *ngFor="let s of states" [ngValue]="s.value">
            {{ s.name }}
          </option>
        </select>
        <div class="invalid-feedback">This field is required</div>
      </div>
      <div class="form-group mb-3 col-md-3">
        <label class="form-label" for="zipCode">Zip Code</label>
        <input
          type="text"
          class="form-control"
          [(ngModel)]="request.zip"
          name="zipCode"
          required
          #zipCodeInput="ngModel"
          [ngClass]="{
            'is-invalid':
              zipCodeInput && zipCodeInput.touched && zipCodeInput.invalid
          }"
        />
        <div class="invalid-feedback">This field is required</div>
      </div>
    </div>
  </form>
  <div class="d-flex justify-content-between my-2">
    <span>
      <a
        href="javascript:void(0)"
        class="btn btn-light"
        (click)="closeClicked()"
      >
        Close
      </a>
    </span>
    <span>
      <a
        href="javascript:void(0)"
        class="btn bp-primary-bg-color me-2"
        (click)="backClicked()"
      >
        Back
      </a>
      <a
        href="javascript:void(0)"
        class="btn bp-primary-bg-color"
        (click)="nextClicked()"
      >
        Next
      </a>
    </span>
  </div>
</div>
