import { Injectable } from "@angular/core";
import { UtilsService } from "projects/borrower-app/src/app/services/utils.service";
import { IDecideNextStep } from "projects/borrower-app/src/app/services/wizard/next-step-decider/next-step-decider.interface";
import { WizardFlowContext } from "../../../wizard-flow-context.model";
import { CreditScoresStep } from "../../credit-scores-step.model";
import { CreditScoresStepConfig } from "../credit-scores-step.config.model";
import { NextStepDeciderFactory } from "./next-step-decider.factory";
import { StepFactoryBase } from "./step-factory-base";
import { ICreateStep } from "./step-factory.interface";

@Injectable()
export class CreditScoresStepFactory extends StepFactoryBase<CreditScoresStepConfig> implements ICreateStep {

  constructor(private readonly _nextStepDeciderFactory: NextStepDeciderFactory,
    private readonly _utilsService: UtilsService) {
      super();
  }

  configure(step: CreditScoresStep, config: CreditScoresStepConfig): void {
    config.title = step.title;
    config.explanation = step.explanation;
    config.heading = step.heading;
    config.useScoreIndicatorIcons = step.useScoreIndicatorIcons;
  }

  create(config: CreditScoresStepConfig, wizardFlowContext: WizardFlowContext): CreditScoresStep {
    let nextStepDecider: IDecideNextStep | undefined = undefined;
    if (config.nextStepDeciderConfig) {
        nextStepDecider = this._nextStepDeciderFactory.create(config.nextStepDeciderConfig, wizardFlowContext);
    }
    let creditScoresStep = new CreditScoresStep(config.id, config.name, config.path, nextStepDecider);
    creditScoresStep.title = config.title;
    creditScoresStep.explanation = config.explanation;
    creditScoresStep.heading = config.heading;
    creditScoresStep.useScoreIndicatorIcons = config.useScoreIndicatorIcons;
    creditScoresStep.groupId = config.groupId;

    return creditScoresStep;
  }
}
