<table class="table bp-table fixTableHead" *ngIf="applicationTasks.length > 0">
  <thead>
    <tr>
      <th width="30%">Task</th>
      <th width="20%">Due Date</th>
      <th width="10%">Status</th>
      <th width="30%">Complete</th>
      <th width="10%">Actions</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let task of applicationTasks" class="bp-table-row">
      <td width="30%">
        <span class="fw-bold">{{ task.docType }}</span>
      </td>
      <td width="20%">
        <span class="fw-bold">{{ task.dueDate | dateAgo }}</span>
      </td>
      <td width="10%">
        <span class="fw-bold">{{ task.borrowerTaskStatus }}</span>
      </td>
      <td width="30%">
        <span class="fw-bold">{{ task.description }}</span>
      </td>
      <td width="10%">
        <div>
          <a
            *ngIf="task.templateDocumentUrl"
            title="View Template Doc"
            class="align-text-bottom"
            (click)="goToLink(task.templateDocumentUrl)"
          >
            <i class="far fa-eye"></i>
          </a>

          <a
            title="Add Files"
            class="mx-2 align-text-bottom"
            (click)="openAddFiles(task)"
          >
            <i class="fas fa-upload"></i>
          </a>
        </div>
      </td>
    </tr>
  </tbody>
</table>

<h2 class="text-center my-4" *ngIf="applicationTasks.length === 0">
  No task found!
</h2>
