
import { WizardFlowContext } from "../../../models/wizard-flow-context.model";
import { StepExpression } from "../../../models/wizard/step-expression.model";
import { ContextExtractor } from "../../context-extractor";
import { IDecideNextStep } from "./next-step-decider.interface";

declare var require: any

export class ExpressionNextStepDecider implements IDecideNextStep {

    private readonly _ifStepExpression: StepExpression;
    private readonly _elseIfStepExpressions: StepExpression[] | undefined = [];
    private readonly _elseStepPath: string |  undefined;

    private _wizardFlowContext: WizardFlowContext;

    constructor(wizardFlowContext: WizardFlowContext,
       ifStepExpression: StepExpression, elseIfStepExpressions: StepExpression[] | undefined, elseStepPath: string | undefined) {
        this._ifStepExpression = ifStepExpression;
        this._elseIfStepExpressions = elseIfStepExpressions;
        this._elseStepPath = elseStepPath;
        this._wizardFlowContext = wizardFlowContext;
    }

    nextStepPath = (): string | undefined => {
        const context = ContextExtractor.prepareDecisionContext(this._wizardFlowContext);
        var expressions = require("angular-expressions");

        const ifCondition = expressions.compile(this._ifStepExpression.ifCondition);

        if (ifCondition(context)) {
            return this._ifStepExpression.goToPath;
        }
        if (this._elseIfStepExpressions) {
          for (let elseIf of this._elseIfStepExpressions) {
              let elseIfCondition = expressions.compile(elseIf.ifCondition);
            if (elseIfCondition(context)) {
                  return elseIf.goToPath;
            }
          }
        }
        if (this._elseStepPath)
          return this._elseStepPath;
        return undefined
    }

    private isFunction = (functionToCheck: any) => {
      return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
    }
}


