export class Address {
  addressId: number;
  address1: string | undefined = "";
  city: string | undefined = "";
  state: string | undefined = "";
  zipCode: string | undefined = "";
  presentValue?: number | undefined = 0;
  county?: string | undefined = "";
  country?: string | undefined = "us";
}
