import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Quote } from 'projects/borrower-app/src/app/models/pricing/pricing-response.model';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'price-option-card',
  templateUrl: 'price-option-card.component.html',
  styleUrls: ['./price-option-card.component.scss']
})
export class PriceOptionCardComponent implements OnInit {

  @Input()
  quote: Quote;

  @Input()
  isSelected: boolean = false;

  @Input()
  isMobile: boolean = false;

  @Output()
  priceOptionSelected: EventEmitter<Quote> = new EventEmitter<Quote>();

  constructor() { }

  ngOnInit() {
    this.quote['uniqueId'] = uuidv4();
  }

  onCardClicked = () => {
    this.isSelected = true;
    this.priceOptionSelected.emit(this.quote);
  }
}
