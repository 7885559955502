

<div class="modal-header">

  <h2>Save Flow Configuration</h2>

  <!-- hack to fool ngbModal autofocus -->
  <input type="text" style="display:none" />

  <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" aria-label="Close"
       (click)="activeModal.dismiss('Cross click')">
      <span class="svg-icon svg-icon-2x">
        <close-x-svg-icon></close-x-svg-icon>
      </span>
  </div>

</div>

<div class="modal-body py-10 px-lg-17">
  <form #configDialogForm="ngForm" class="form" novalidate id="configDialogForm" name="configDialogForm">
    <div class="me-n7 pe-7">
      <div class="row mb-5">
        <div class="col-md-12 fv-row">
          <div class="form-check form-check-custom form-check-solid">
            <input type="radio" [value]="true" class="form-check-input" value="" id="createNew"
              [(ngModel)]="createNew" [ngModelOptions]="{standalone: true}" [disabled]="!guid"/>
            <label class="form-check-label fs-6" for="createNew">
                Create New
            </label>
          </div>
        </div>
      </div>
      <div class="row mb-5" *ngIf="guid">
        <div class="col-md-12 fv-row">
          <div class="form-check form-check-custom form-check-solid">
            <input type="radio" [value]="false" class="form-check-input" value="" id="saveExisting"
              [(ngModel)]="createNew" [ngModelOptions]="{standalone: true}"/>
            <label class="form-check-label fs-6" for="saveExisting">
                Save existing with Guid: {{guid}}
            </label>
          </div>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-md-6 fv-row">
          <label class="required form-label mb-2">Title</label>
          <input class="form-control form-control-solid"
                 [ngClass]="{'is-invalid' : titleField &&  titleField.touched && titleField.invalid}"
                 #titleField="ngModel" required [(ngModel)]="title" placeholder="" name="title" />
          <div class="invalid-feedback">Title is required.</div>
        </div>
        <div class="col-md-6 fv-row">
          <label class="form-label mb-2 required">Comment</label>
          <input class="form-control form-control-solid" required
            [ngClass]="{'is-invalid' : commentField &&  commentField.touched && commentField.invalid}"
            #commentField="ngModel"
                [(ngModel)]="comment" placeholder="" name="comment" />
          <div class="invalid-feedback">Comment is required.</div>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer flex-center">
  <button type="button" class="btn btn-white me-3" (click)="activeModal.close('Close click')">Cancel</button>
  <button type="button" class="btn btn-primary" (click)="onSaveClicked()">Ok</button>

</div>




