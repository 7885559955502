<div class="modal-header">
  <h5 class="modal-title"> {{title}} </h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body" style="height: 500px;">
  <div class="row" style="height: 100%;">
    <iframe style="width:100%; height: 100%; border:1px solid #e3ebf6; border-radius: 0.25rem;"
      id="htmlPreviewPanel"></iframe>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="activeModal.close()" data-bs-dismiss="modal"> Close
  </button>
</div>
