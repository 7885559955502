<div class="w-100">
  <h5 class="mt-2 mb-4 d-flex justify-content-center">
    <span style="max-width: 500px" class="text-gray-700 fs-4 fw-bold">
      And finally, please review the information below before generating your
      document.
    </span>
  </h5>

  <div class="my-2 d-flex justify-content-center">
    <table class="table" style="max-width: 500px">
      <tbody>
        <tr>
          <td><strong>Property Address</strong></td>
          <td>
            {{ request.address }} <span *ngIf="request.city || request.state && request.zip">,</span> {{ request.city }} {{ request.state }}
            {{ request.zip }}
          </td>
          <td>
            <a href="javascript:void(0)" class="nav-link" (click)="goToStep(2)">
              Edit
            </a>
          </td>
        </tr>
        <tr>
          <td><strong>Purchase Price</strong></td>
          <td>
            {{ request.purchasePrice | currency }}
          </td>
          <td>
            <a href="javascript:void(0)" class="nav-link" (click)="goToStep(3)">
              Edit
            </a>
          </td>
        </tr>
        <tr>
          <td><strong>Down Payment</strong></td>
          <td>
            {{ request.downPayment | currency }}
          </td>
          <td>
            <a href="javascript:void(0)" class="nav-link" (click)="goToStep(4)">
              Edit
            </a>
          </td>
        </tr>
        <tr *ngIf="showAdditionalOptionsStep">
          <td><strong>Additional Options</strong></td>
          <td> {{ request.includeAusFindings || request.includeProofOfFunds  ? 'Includes: ' : ''}} {{ request.includeAusFindings ? 'AUS Findings' : ''}}
            {{ request.includeProofOfFunds ? (request.includeAusFindings ? ', ' : '') + 'Proof Of Funds' : '' }}
          </td>
          <td>
            <a href="javascript:void(0)" class="nav-link" (click)="goToStep(5)">
              Edit
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="my-2 d-flex justify-content-center" *ngIf="haveEmptySections">
    <span style="max-width: 500px" class="text-danger">
      Please fill empty sections first.
    </span>
  </div>

  <div class="d-flex justify-content-between my-2">
    <a href="javascript:void(0)" class="btn btn-light" (click)="closeClicked()">
      Close
    </a>
    <a href="javascript:void(0)" class="btn bp-primary-bg-color" (click)="nextClicked()">
      Generate Preapproval Letter
    </a>
  </div>
</div>

<style>
  td {
    text-align: start;
    vertical-align: middle;
  }
</style>
