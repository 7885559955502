import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { GlobalConfig, ThirdPartTrackingCodes } from "../models/global-config.model";
import { DataService } from "./data.service";

@Injectable()
export class GlobalConfigService {

  constructor(private readonly _dataService: DataService) {
  }

  getConfig = (companyId: number): Observable<GlobalConfig> => {
    const url = `api/Admin/PageResource/BORROWER_AGENT_OA`;
    return this._dataService.get(url);
  }

  getThirPartTrackingCodes = (companyGuid: string): Observable<ThirdPartTrackingCodes> => {
    const url = `api/online-app/3rd-party-tracking-codes/${companyGuid}`;
    return this._dataService.getWithoutAuth(url);
  }

  getClientIp(): Observable<{ ip: string; }> {
    return this._dataService.getExternalApi('https://api.ipify.org?format=json');
  }
}
