import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { NgxSpinnerService } from 'ngx-spinner';
import { DocumentSigningOrder } from '../../models/e-sign/document-signing-recipient.model';
import { OpenSignerViewRequest } from '../../models/open-signer-view-request.model';
import { BasePortalService } from "../../services/base-portal.service";
import { NavigationService } from "../../services/navigation.service";
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'e-sign',
  templateUrl: './e-sign.component.html'
})
export class EsignComponent implements OnInit, AfterViewInit {

  appId: number;

  eSignOrder: DocumentSigningOrder = new DocumentSigningOrder();

  signerViewUrl: SafeResourceUrl;

  isLoading: boolean = false;
  isAgent: boolean = false;

  constructor(private readonly _portalService: BasePortalService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _navigationService: NavigationService,
    private readonly _notifsService: NotificationService,
    private readonly _domSanitizer: DomSanitizer,
    private readonly _router: Router,
    private readonly _route: ActivatedRoute,
    private readonly _elementRef: ElementRef,) {
    this._route.paramMap.subscribe((params: ParamMap) => {
      this.appId = +params.get('appId');
      const taskId = +params.get('taskId');
      if (taskId && !isNaN(Number(taskId))) {
        this.openSignerView(Number(taskId));
      }
    });
  }

  ngOnInit() {
    this._route.data.subscribe((data) => {
      this.isAgent = data["isAgent"] as boolean;
    });
  }

  ngAfterViewInit(): void {
    const thisElement = this._elementRef.nativeElement;
    const parentElement = thisElement.parentElement;
    const layoutEl = parentElement.parentElement.parentElement;

    const makeFullHeight = (el: HTMLElement | undefined) => {
      if (el) {
        el.style.height = '100%';
      }
    };

    [parentElement, layoutEl].forEach(makeFullHeight);
  }

  private openSignerView = (taskId: number) => {
    this._spinner.show();

    const returnUrl = `${window.location.protocol}//${window.location.host}/e-sign-confirmation/${this.appId}`;

    let request = new OpenSignerViewRequest();
    request.returnUrl = returnUrl;
    request.loanDocTaskId = taskId;

    this.isLoading = true;
    this._portalService.getSigningOrder(taskId).subscribe({
      next: (signingOrderResponse) => {
        this._portalService.openSignerView(taskId, request).subscribe({
          next: (response) => {
            this.eSignOrder = signingOrderResponse && signingOrderResponse[taskId] && signingOrderResponse[taskId].length > 0 ? signingOrderResponse[taskId][0] : null;

            this.signerViewUrl = this._domSanitizer.bypassSecurityTrustResourceUrl(response.signerViewUrl);

            this.isLoading = false;
            this._spinner.hide();
            window.addEventListener('message', event => {
              if (event.data.shouldCloseEsignDocIframe) {
                setTimeout(() => {
                  this._router.navigate([`${this.isAgent ? 'agent' : 'borrower'}-portal/loans`, this.appId], { queryParams: { companyGuid: this._navigationService.companyGuid } });
                }, 2000)
              }
            }, false);
          },
          error: (err) => {
            this.onOpenSignerViewErrored(err);
          }
        })
      },
      error: (err) => {
        this.onOpenSignerViewErrored(err);
      }
    });
  }

  private onOpenSignerViewErrored = (err: any) => {
    this.isLoading = false;
    this._spinner.hide();
    this._notifsService.showError(err && err.error ? err.error.message || err.message : "Couldn't open signer view.", 'Error!');
    this._router.navigate([`${this.isAgent ? 'agent' : 'borrower'}-portal/loans`], { queryParams: { companyGuid: this._navigationService.companyGuid } });
  }
}
