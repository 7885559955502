import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { PortalScope } from '../login/login.component';
import { AuthenticationRequest } from '../models/auth/auth-request.model';
import { LoginStatus } from '../models/auth/login-status.enum';
import { AuthService } from '../services/auth.service';
import { NavigationService } from '../services/navigation.service';
import { EnvironmentService } from 'projects/shared/services/environment.service';
import { Constants } from '../services/constants';
import { Utils } from '../services/utils';
import { Observer } from 'rxjs';
declare const $: any;
declare const Swal: any;

@Component({
  selector: 'confirm-account',
  templateUrl: 'confirm-account.component.html',
  styleUrls: ['confirm-account.component.scss'],
})
export class ConfirmAccountComponent implements OnInit, AfterViewInit {
  @ViewChild('createAccountForm') createAccountForm: NgForm | undefined;

  userName: string = '';

  password: string = '';
  confirmedPassword: string = '';

  tokenConfirmed: boolean = false;
  accountConfirmed: boolean = false;
  errorMessage: string | undefined = undefined;

  spinnerVisible: boolean = false;

  tocAccepted: boolean = false;

  possibleLogoUrls: string[] = [];

  gotoPortalScope: PortalScope = null;

  protected passwordIsMinimum8Characters: boolean = false;
  protected passwordContainsDigit: boolean = false;
  protected passwordContainsUppercase: boolean = false;
  protected passwordContainsLowercase: boolean = false;
  protected passwordContainsSpecialCharacter: boolean = false;

  private _token: string = '';
  private _companyGuid: string = '';

  constructor(
    private readonly _authService: AuthService,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _router: Router,
    private readonly _navigationService: NavigationService,
    private readonly _environment: EnvironmentService
  ) {
    this.possibleLogoUrls = this._navigationService.possibleLogoUrls;
    this._activatedRoute.queryParams.subscribe((params) => {
      this.userName = params['username'];
      this._token = params['token'];
      this._companyGuid = params['companyGuid'];
    });
    let userScopeFromPath = null;
    const state = this._router.getCurrentNavigation().extras.state;
    if (state && state.gotoPortal) {
      userScopeFromPath = state.goToPortal;
    }
    this.gotoPortalScope = this._environment.scope || userScopeFromPath;
  }

  ngOnInit() {
    const observer: Observer<any> = {
      next: (response: any) => {
        this.tokenConfirmed = true;
        this.errorMessage = undefined;
        if (response.isConfirmed) {
          const self = this;
          Swal.fire(
            'Success!',
            'Your account is already confirmed. You will be redirected to the login page. Please wait…',
            'success'
          ).then(function (modalResult: any) {
            if (modalResult.value) {
              self._navigationService.navigateToLogin(undefined, false);
            }
          });
          setTimeout(() => {
            Swal.close();
            self._navigationService.navigateToLogin(undefined, false);
          }, 3000);
        }
      },
      error: (err: any) => {
        this.errorMessage = err.error;
      },
      complete: () => { },
    };

    this.spinnerVisible = true;
    this._authService
      .confirmRegistrationToken(this.userName, this._token, this._companyGuid)
      .subscribe(observer)
      .add(() => {
        this.spinnerVisible = false;
      });
  }

  ngAfterViewInit(): void {
    const eyeIcon = $('.bi-eye');
    const eyeSlashIcon = $('.bi-eye-slash');

    eyeIcon.bind('touchend', function (event) {
      if (eyeSlashIcon.hasClass('d-none')) {
        eyeSlashIcon.removeClass('d-none');
        eyeIcon.addClass('d-none');
        const passwordElement = document.getElementById('password');
        if (passwordElement.getAttribute('type') === 'text') {
          passwordElement.setAttribute('type', 'password');
        }
      }
    });

    eyeSlashIcon.bind('touchend', function (event) {
      if (eyeIcon.hasClass('d-none')) {
        eyeIcon.removeClass('d-none');
        eyeSlashIcon.addClass('d-none');
        const passwordElement = document.getElementById('password');
        if (passwordElement.getAttribute('type') === 'password') {
          passwordElement.setAttribute('type', 'text');
        }
      }
    });
  }

  onPasswordChanged = () => {
    this.passwordContainsDigit = Utils.containsDigit(this.password);
    this.passwordIsMinimum8Characters = this.password.trim().length >= 8;
    this.passwordContainsUppercase = Utils.containsUppercase(this.password);
    this.passwordContainsLowercase = Utils.containsLowercase(this.password);
    this.passwordContainsSpecialCharacter = Utils.containsSpecialCharacter(
      this.password
    );
  };

  onTermsAndConditionsClicked = () => {
    window.open(
      'terms-and-conditions?companyGuid=' + this._companyGuid,
      '_blank'
    );
  };

  onCreatePasswordClicked = () => {
    this.createAccountForm?.form.markAllAsTouched();
    const valid = this.createAccountForm?.form.valid;
    if (this.tokenConfirmed && valid) {
      this.spinnerVisible = true;
      if (this.accountConfirmed) {
        this.login();
      } else {
        this._authService
          .confirmRegistration(
            this.userName,
            this._token,
            this.password,
            this.confirmedPassword,
            this._companyGuid
          )
          .pipe(
            finalize(() => {
              this.spinnerVisible = false;
            })
          )
          .subscribe(
            (response) => {
              this.accountConfirmed = true;
              this.errorMessage = undefined;

              this.login(response.scope);
            },
            (error) => {
              this.handleCreatePasswordError(error);
            }
          );
      }
    }
  };

  private login = (scope?: PortalScope) => {
    this.spinnerVisible = true;
    if (!this._companyGuid) {
      return;
    }
    const loginRequest: AuthenticationRequest = {
      companyGuid: this._companyGuid,
      username: this.userName,
      password: this.password,
      rememberMe: false,
      scope: scope || this.gotoPortalScope || PortalScope.AgentAndBorrower,
    };
    this._authService.signIn(loginRequest).subscribe(
      (response) => {
        this.spinnerVisible = false;
        if (response.loginStatus === LoginStatus.Error) {
          this.errorMessage = response.errorMessage;
          return;
        } else if (response.loginStatus === LoginStatus.Success) {
          const authData = JSON.parse(
            localStorage.getItem(Constants.authorization.authorizationDataKey)
          );
          if (authData) {
            this.gotoPortalScope = authData.scope;
          }
        } else if (
          response.loginStatus === LoginStatus.ProperScopeSelectionRequired
        ) {
          //this.onRoleSelectionRequired(response.availableCompanies);
          this.gotoPortalScope = PortalScope.Borrower;
          return;
        }
        let portalRoute =
          !this.gotoPortalScope || this.gotoPortalScope == PortalScope.Borrower
            ? 'e-consent'
            : 'agent-portal';
        this._navigationService.navigateToPath(`/${portalRoute}`);
      },
      (error) => {
        this.errorMessage = error.error_descripton;
        this.spinnerVisible = false;
      }
    );
  };

  private handleCreatePasswordError = (error: any) => {
    if (error.error === 'User is already registered. Please log in.') {
      this.login();
    } else {
      this.errorMessage = error.error.message;
    }
  };
}
