import { AssetType } from "./enums/asset-type-enum";

export class AssetItem {
  id: number | undefined;
  type: AssetType | undefined;
  value: number | undefined;
  assetOwners: string | undefined;
  isValid: boolean = false;
  isReadOnly: boolean = false;

  constructor(id: number, type: AssetType, value: number, assetOwners: string, isValid: boolean) {
    this.id = id;
    this.type = type;
    this.value = value;
    this.assetOwners = assetOwners;
    this.isValid = isValid;
  }
}
