import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Borrower } from '../../../models/borrower-model';

@Component({
  selector: 'borrower-digital-income',
  templateUrl: 'borrower-digital-income.component.html'
})

export class BorrowerDigitalIncomeComponent implements OnInit {

  @ViewChild('borrowerInfoForm') borrowerInfoForm: NgForm | undefined;

  @Output() checkStatusChanged: EventEmitter<any> = new EventEmitter<any>();

  @Output() textChanged: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  borrowerIndex!: number;

  @Input()
  borrower!: Borrower;

  constructor() { }

  checked: boolean = false;

  onModelChange(checked: boolean) {
    this.checkStatusChanged.emit({borrowerIndex: this.borrowerIndex, checked: checked});
  }

  onTextChange(event: any) {
    this.textChanged.emit();
  }

  validate = () => {
    if (this.borrowerInfoForm?.form.valid) {
        return true;
    }
    return false;
  }

  ngOnInit() { }
}
