import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'duotone-briefcase-svg-icon',
  template: ``
})

export class DuotoneBriefcaseSvgIconComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
