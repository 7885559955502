import { AfterViewInit, Component, Injector, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Option } from '../../../models/wizard/option.model';
import { SingleChoiceQuestionStep } from '../../../models/wizard/single-choice-question-step.model';
import { AnswerOptionComponent } from '../answer-option/answer-option.component';
import { WizardStepComponentBase } from '../wizard-step-base.component';
import { OptionsProviderFactory, OptionsProviderTypes } from '../../../models/wizard/config/options-providers/options-provider.factory';
import { EnumsService } from '../../../services/enums.service';
import { EnumerationItem } from 'projects/shared/models/enumeration-item.model';

@Component({
  selector: 'single-choice-question-step',
  templateUrl: 'single-choice-question-step.component.html'
})
export class SingleChoiceQuestionStepComponent extends WizardStepComponentBase<SingleChoiceQuestionStep> implements OnInit, AfterViewInit {

  @ViewChildren('answerOption') answerOptionComponents: QueryList<AnswerOptionComponent> | undefined;

  colWidth: number = 12;
  lastColWidth: number = 0;

  protected availableOptions: Option[] = [];

  protected optionProviders: EnumerationItem[] = [];

  protected valuesOfOptionsSelectedToBePresentedToUserAsChoices: string[] = [];

  protected setDefaultAndSkipToNextChecked: boolean = false;

  constructor(private readonly _injector: Injector,
    private readonly _optionsProviderFactory: OptionsProviderFactory,
    private readonly _enumsService: EnumsService) {
    super(_injector);

    this.optionProviders = this._enumsService.singleChoiceOptionsProviders;
  }

  onOptionProviderChanged = () => {
    this.populateAvailableOptions(this.step.optionsProviderType);
  }

  onOptionSelected(option: Option) {
    this.step.setModelFieldValue(option.value);
    if (this.step.question) {
      for (let answerOption of this.step.question.options) {
        answerOption.selected = (answerOption === option);
      }
    }
    if (!this.wizardFlowService.inPreviewMode) {
      this.wizardFlowService.navigateForward();
    }
  }

  ngAfterViewInit() {
    if (!this.answerOptionComponents) {
      return;
    }
    for (let answerOptionComponent of this.answerOptionComponents) {
      answerOptionComponent.getOptionSelectedEmitter().subscribe(e => this.onOptionSelected(e));
    }
    super.ngAfterViewInit();
  }

  public get canMoveToNextStep(): boolean {
    return this.step.canMoveToNextStep && !this.wizardFlowService.inPreviewMode;
  }

  ngOnInit() {
    super.ngOnInit();
    if (!this.step.question) {
      return;
    }

    if (!this.wizardFlowService.isEditEnabled) {
      this.setDefaultValueAndSkipIfConfiguredThatWay();
      this.step.selectOptionByDefaultIfSetOnModel();
    } else {
      this.setDefaultAndSkipToNextChecked = !!this.step.nameOfDefaultOptionToSetAndSkip;
      this.populateAvailableOptions(this.step.optionsProviderType);
    }

    this.step.heading = this.step.heading || this.step.question.text;
    this.arrangeOptionBoxesOnScreen();
    this.step.setModelFieldValueFromStepStatus();
  }

  onSetDefaultValueCheckChanged = () => {
    if (!this.setDefaultAndSkipToNextChecked) {
      this.step.nameOfDefaultOptionToSetAndSkip = null;
    }
  }

  optionsSelectedToPresentToUserChanged = (option: Option, e: any) => {
    if (e.target.checked) {
      if (!this.step.question.options.find(o => o.name.toLowerCase() === option.name.toLowerCase())) {
        this.step.question.options.push(option);
      }
    } else {
      const index = this.step.question.options.findIndex(o => o.name.toLowerCase() === option.name.toLowerCase())
      if (index >= 0) {
        this.step.question.options.splice(index, 1);
      }
    }
    this.arrangeOptionBoxesOnScreen();
  }

  private populateAvailableOptions = (optionsProviderType: OptionsProviderTypes) => {
    if (optionsProviderType) {
      const optionProvider = this._optionsProviderFactory.create(optionsProviderType);
      if (optionProvider) {
        this.startSpinner();
        optionProvider.getOptions().subscribe(options => {
          this.availableOptions = options;
          this.valuesOfOptionsSelectedToBePresentedToUserAsChoices = this.step.question.options.map(o => o.name.toLowerCase());
        }).add(() => this.stopSpinner())
      }
    } else {
      this.availableOptions = [...this.step.question.options];
      this.valuesOfOptionsSelectedToBePresentedToUserAsChoices = this.step.question.options.map(o => o.name.toLowerCase());
    }
  }

  private setDefaultValueAndSkipIfConfiguredThatWay = () => {
    if (this.step.nameOfDefaultOptionToSetAndSkip) {
      const optionToSetByDefault = this.step.question.options.find(o => o.name.toLowerCase() === this.step.nameOfDefaultOptionToSetAndSkip.toLowerCase());
      if (optionToSetByDefault) {
        this.step.setModelFieldValue(optionToSetByDefault.value);
        if (this.cameHereWithBackNavigation) {
          if (!this.wizardFlowService.isFirstStep(this.step)) {
            this.wizardFlowService.navigateBackward();
          } else {
            this.wizardFlowService.navigateForward();
          }
        } else {
          this.wizardFlowService.navigateForward();
        }
      }
    }
  }

  private arrangeOptionBoxesOnScreen = () => {
    if (this.step.question.options.length > 0 && !this.step.listOptionsVertically) {
      this.colWidth = Math.floor(12 / this.step.question.options.length);
      this.lastColWidth = 12 - ((this.step?.question?.options.length - 1) * this.colWidth);
    }
  }
}
