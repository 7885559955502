import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wizard',
  templateUrl: 'wizard.component.html'
})

export class WizardComponent implements OnInit {
  constructor() { }

  ngOnInit() {
      console.log("wizard is hit");
   }
}
