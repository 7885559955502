import {LosPortalUserStatusModel} from "./los-portal-user-status-model";

export class ApplicationDetailModel {
  borrowerId:number | undefined;
  borrowerName: string | undefined;
  borrowerPhone: string | undefined;
  borrowerEmail: string | undefined;
  losPortalUserStatus: LosPortalUserStatusModel | undefined;
  acceptedEConsentDate: Date | undefined;

  constructor() {
  }
}
