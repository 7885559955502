

<div class="modal-header">

  <h2>My Flows</h2>

  <!-- hack to fool ngbModal autofocus -->
  <input type="text" style="display:none" />

  <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" aria-label="Close"
       (click)="activeModal.dismiss('Cross click')">
      <span class="svg-icon svg-icon-2x">
        <close-x-svg-icon></close-x-svg-icon>
      </span>
  </div>

</div>

<div class="modal-body px-lg-17 py-0">
  <div class="card mb-5 mb-xl-8">
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-3 mb-1">Flow Configurations</span>
        <span class="text-muted mt-1 fw-bold fs-7">{{flows.length}} flow(s)</span>
      </h3>
    </div>
    <div class="card-body py-3 text-center">
      <i class="fas fa-circle-notch fa-4x fa-spin" *ngIf="loading"></i>
      <div class="table-responsive" *ngIf="!loading">
        <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
          <thead>
            <tr class="fw-bolder text-muted">
              <th class="w-25px">
                <div class="form-check form-check-sm form-check-custom form-check-solid">
                  <input class="form-check-input" type="checkbox" value="1" data-kt-check="true" data-kt-check-target=".widget-9-check" />
                </div>
              </th>
              <th class="min-w-150px">Flows</th>
              <th class="min-w-200px">Comment</th>
              <th class="min-w-60px">Created</th>
              <th class="min-w-100px text-end">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let flow of flows;">
              <td>
                <div class="form-check form-check-sm form-check-custom form-check-solid">
                  <input class="form-check-input widget-9-check" type="checkbox" value="1" />
                </div>
              </td>
              <td>
                <div class="d-flex align-items-center">
                  <div class="d-flex justify-content-start flex-column">
                    <a href="javascript:void(0);" (click)="onFlowClicked(flow)" class="text-dark fw-bolder text-hover-primary fs-6">{{flow.title}}</a>
                    <span class="text-muted fw-bold text-muted d-block fs-7">{{flow.flowGuid}}</span>
                  </div>
                </div>
              </td>
              <td>
                <span class="text-dark fw-bolder d-block fs-6">{{flow.comment}}</span>
                <span class="text-muted fw-bold text-muted d-block fs-7"></span>
              </td>
              <td>
                <span class="text-muted fw-bold text-muted d-block fs-7">{{flow.dateInserted | date: 'short'}}</span>
              </td>

              <td class="text-end">
                <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                  <span class="svg-icon svg-icon-3">
                    <duotone-pencil-svg-icon></duotone-pencil-svg-icon>
                  </span>
                </a>
                <a (click)="onDeleteFlowClicked(flow)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                  <span class="svg-icon svg-icon-3">
                    <duotone-trash-svg-icon></duotone-trash-svg-icon>
                  </span>
                </a>
              </td>
            </tr>

          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer flex-center">
  <button type="button" class="btn btn-white me-3" (click)="activeModal.close('Close click')">Close</button>
</div>




