import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EnvironmentService } from 'projects/shared/services/environment.service';
import { AuthService } from '../services/auth.service';
import { LocalStorageService } from '../services/local-storage.service';
import { NavigationService } from '../services/navigation.service';
import { UtilsService } from '../services/utils.service';
import { WizardFlowServiceBase } from '../services/wizard/wizard-flow-service.base';
import { WizardMainBaseComponent } from '../wizard/wizard-main/wizard-main-base.component';
import { MortgageApplicationService } from '../services/mortgage-application.service';

@Component({
  selector: 'registration',
  templateUrl: '../wizard/wizard-main/wizard-main.component.html'
})

export class RegistrationComponent extends WizardMainBaseComponent implements OnInit {

  constructor(private readonly _wizardFlowService: WizardFlowServiceBase,
    private readonly _utilsService: UtilsService,
    private readonly _authService: AuthService,
    private readonly _environment: EnvironmentService,
    private readonly _viewContainerRef: ViewContainerRef,
    private readonly activatedRoute: ActivatedRoute,
    private readonly localStorageService: LocalStorageService,
    private readonly navigationService: NavigationService,
    private readonly mortgageApplicationService: MortgageApplicationService) {
    super(_wizardFlowService, _utilsService, _authService, _environment, _viewContainerRef,
      activatedRoute, localStorageService, navigationService, mortgageApplicationService);
  }
}
