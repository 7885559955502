import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'agent-contact-info',
  templateUrl: 'agent-contact-info.component.html',
  styleUrls: ['./agent-contact-info.component.scss']
})
export class AgentContactInfoComponent implements OnInit {

  @Input()
  agent: AgentContactInfo;

  @Input()
  agentAddressHidden: boolean = true;

  @Output()
  agentNameClicked: EventEmitter<AgentContactInfo> = new EventEmitter<AgentContactInfo>();

  constructor() { }

  ngOnInit() { }

  onAgentNameClicked = () => {
    this.agentNameClicked.emit(this.agent);
  }
}

export class AgentContactInfo {
  agentAvatarUrl: string;
  firstName: string;
  lastName: string;
  agentNMLS: string;
  companyNMLS: string;
  phoneNumber: string;
  faxNumber: string;
  emailAddress: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  role: string;
  calendarLink: string | undefined;
}
