<address #addressComponent [address]="residencyAddress!.address!" (addressChanged)="onAddressChanged($event)"></address>

<form #addressRelatedInfoForm="ngForm" novalidate id="addressRelatedInfoForm{{uniqueId}}" name="addressRelatedInfoForm{{uniqueId}}">
  <div class="row g-9 mb-8">
    <div class="col-md-2 fv-row" [ngClass]="{'mt-3': isMobile}" style="padding-top: 35px;" *ngIf="!isHidden('residencyAddress.durationMonths') || !isHidden('residencyAddress.durationYears')">
      <label class="fs-6 fw-bold mb-2" style="margin-top: -8px">How long have you lived here</label>
    </div>
    <div class="col-md-2 fv-row" [ngClass]="{'mt-2': isMobile}" *ngIf="!isHidden('residencyAddress.durationYears')">
      <label class="fs-6 fw-bold mb-2" [ngClass]="{'required': isRequired('residencyAddress.durationYears')}">{{getLabel('residencyAddress.durationYears', 'Year(s)')}}</label>
      <div class="position-relative d-flex align-items-center">
        <input class="form-control form-control-solid"
          name="yearsAtAddress{{uniqueId}}"
          id="yearsAtAddress{{uniqueId}}" (blur)="onMonthsOrYearsBlurred($event)"
          [(ngModel)]="residencyAddress!.durationYears" #yearsAtAddress="ngModel" type="number" min="0"
          [required]="isRequired('residencyAddress.durationYears')"
          [ngClass]="{'is-invalid' : yearsAtAddress && yearsAtAddress.touched && yearsAtAddress.invalid}"/>
      </div>
    </div>
    <div class="col-md-2 fv-row" [ngClass]="{'mt-2': isMobile}" *ngIf="!isHidden('residencyAddress.durationMonths')">
      <label class="fs-6 fw-bold mb-2" [ngClass]="{'required': isRequired('residencyAddress.durationMonths')}">{{getLabel('residencyAddress.durationMonths', 'Month(s)')}}</label>
      <div class="position-relative d-flex align-items-center">
        <input class="form-control form-control-solid" name="monthsAtAddress{{uniqueId}}"
        id="monthsAtAddress{{uniqueId}}" (blur)="onMonthsOrYearsBlurred($event)"
        [(ngModel)]="residencyAddress!.durationMonths" type="number" min="0"
        [required]="isRequired('residencyAddress.durationMonths')"
        #monthsAtAddress="ngModel" [ngClass]="{'is-invalid' : monthsAtAddress && monthsAtAddress.touched && monthsAtAddress.invalid}"/>
      </div>
    </div>
    <div class="col-md-3 fv-row" [ngClass]="{'mt-2': isMobile}" *ngIf="!isHidden('residencyAddress.residencyBasis')">
      <label class="fs-6 fw-bold mb-2" [ngClass]="{'required': isRequired('residencyAddress.residencyBasis')}">{{getLabel('residencyAddress.residencyBasis', 'Ownership Status')}}</label>
      <select class="form-select form-select-solid" data-control="select2" name="ownershipStatus{{uniqueId}}" id="ownershipStatus{{uniqueId}}"
        [(ngModel)]="residencyAddress!.residencyBasis" #residencyBasis="ngModel"
        (ngModelChange)="onOwnershipStatusChanged()"
        [required]="isRequired('residencyAddress.residencyBasis')"
          [ngClass]="{'is-invalid' : residencyBasis && residencyBasis.touched && residencyBasis.invalid}">
        <option [ngValue]="null">Select one...</option>
        <option value="Own">Own</option>
        <option value="Rent">Rent</option>
        <option value="LivingRentFree">Live Rent Free</option>
      </select>
    </div>
    <div class="col-md-3 fv-row" [ngClass]="{'mt-2': isMobile}" *ngIf="residencyAddress!.residencyBasis && residencyAddress!.residencyBasis === 'Rent' && !isHidden('residencyAddress.rent')">
      <label class="fs-6 fw-bold mb-2" [ngClass]="{'required': isRequired('residencyAddress.rent')}">{{getLabel('residencyAddress.rent', 'Monthly Rent')}}</label>
      <div class="position-relative d-flex align-items-center">
        <input class="form-control form-control-solid"
          name="monthlyPayment{{uniqueId}}"
          id="monthlyPayment{{uniqueId}}"
          #monthlyPayment="ngModel"
          [required]="isRequired('residencyAddress.rent')"
          mask="separator" thousandSeparator="," inputmode="decimal" prefix="$"
          [(ngModel)]="residencyAddress!.rent"
          [ngClass]="{'is-invalid' : monthlyPayment && monthlyPayment.touched && monthlyPayment.invalid}"/>
      </div>
    </div>
  </div>
</form>
