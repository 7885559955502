import { AssetType } from "./enums/asset-type-enum";

export class Asset {
    assetId : number | undefined;
    assetType: AssetType | undefined | null = null;
    cashMarketValue: number | undefined;
    owningBorrowerIds: number[] = [];
    //for automobile
    makeModel: string | undefined;
    year: number | undefined;
    //pending net sale proceeds from real estate assets
    //can't use address objects, field names should match model field names
    holderAddress1:string | undefined;
    holderAddress2:string | undefined;
    holderCity:string | undefined;
    holderZipCode:string | undefined;
    holderState:string | undefined;
    disposition: string | undefined;
    accountNumber: string | undefined;
    depository: string | undefined;
    amountOfMortgageLien: number | undefined;
    mortgagePayment:number | undefined;
    gross:number | undefined;
    netRentalIncome:number | undefined;
    insMainTaxMisc:number | undefined;

    constructor() {
      this.assetType = null;
    }
}
