import { Directive, Input } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
  ValidatorFn,
} from '@angular/forms';
import { Utils } from 'projects/borrower-app/src/app/services/utils';

type CaseType = 'upper' | 'lower' | 'both';
export function caseSizeValidator(type: CaseType): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (type === 'upper') {
      const isValid = !control.value || Utils.containsUppercase(control.value);
      return isValid ? null : { uppercase: { value: control.value } };
    }

    if (type === 'lower') {
      const isValid = !control.value || Utils.containsLowercase(control.value);
      return isValid ? null : { lowercase: { value: control.value } };
    }

    if (type === 'both') {
      const isLowercaseValid = !control.value || Utils.containsLowercase(control.value);
      const isUppercaseValid = !control.value || Utils.containsUppercase(control.value);

      if (isLowercaseValid && isUppercaseValid) {
        return null;
      }

      const result = {}
      if (!isLowercaseValid) {
        result['lowercase'] = { value: control.value }
      }
      if (!isUppercaseValid) {
        result['uppercase'] = { value: control.value }
      }
      return result;
    }
    return null;
  };
}

@Directive({
  selector: '[caseSize]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: CaseSizeValidatorDirective,
      multi: true,
    },
  ],
})
export class CaseSizeValidatorDirective implements Validator {
  @Input('caseSize')
  caseSize: CaseType;

  validate(control: AbstractControl): ValidationErrors | null {
    return this.caseSize ? caseSizeValidator(this.caseSize)(control) : null;
  }
}
