import { Injectable } from "@angular/core";
import { WizardFlowContext } from "../../../wizard-flow-context.model";
import { GenericStepFactory } from "./generic-step.factory";
import { NextStepDeciderFactory } from "./next-step-decider.factory";
import { ICreateStep } from "./step-factory.interface";
import { IncomeStep } from "../../income-step.model";
import { IncomeStepConfig } from "../income-step-config.model";

@Injectable()
export class IncomeStepFactory extends GenericStepFactory<IncomeStep> implements ICreateStep {

  constructor(nextStepDeciderFactory: NextStepDeciderFactory) {
    super(nextStepDeciderFactory, IncomeStep);
  }

  create(config: IncomeStepConfig, wizardFlowContext: WizardFlowContext): IncomeStep {
    let incomeStep = super.create(config, wizardFlowContext)
    incomeStep.enforceAtLeast2YearsOfEmploymentHistory = config.enforceAtLeast2YearsOfEmploymentHistory;
    incomeStep.w2EmploymentActionLabel = config.w2EmploymentActionLabel || 'Employment (W2)';
    incomeStep.independentContractorActionLabel = config.independentContractorActionLabel || 'Independent Contractor';
    incomeStep.militaryPayActionLabel = config.militaryPayActionLabel || 'Military Pay';
    incomeStep.rentalIncomeActionLabel = config.rentalIncomeActionLabel || 'Rental';
    incomeStep.socialSecurityIncomeActionLabel = config.socialSecurityIncomeActionLabel || 'Social Security';
    incomeStep.pensionIncomeActionLabel = config.pensionIncomeActionLabel || 'Pension';
    incomeStep.businessSelfEmploymentActionLabel = config.businessSelfEmploymentActionLabel || 'Business/Self Employment';
    incomeStep.otherIncomeActionLabel = config.otherIncomeActionLabel || 'Other';
    return incomeStep;
  }

  configure(step: IncomeStep, config: IncomeStepConfig): void {
    super.configure(step, config);
    config.enforceAtLeast2YearsOfEmploymentHistory = step.enforceAtLeast2YearsOfEmploymentHistory;
    config.w2EmploymentActionLabel = step.w2EmploymentActionLabel;
    config.independentContractorActionLabel = step.independentContractorActionLabel;
    config.militaryPayActionLabel = step.militaryPayActionLabel;
    config.rentalIncomeActionLabel = step.rentalIncomeActionLabel;
    config.socialSecurityIncomeActionLabel = step.socialSecurityIncomeActionLabel;
    config.pensionIncomeActionLabel = step.pensionIncomeActionLabel;
    config.businessSelfEmploymentActionLabel = step.businessSelfEmploymentActionLabel;
    config.otherIncomeActionLabel = step.otherIncomeActionLabel;
  }
}
