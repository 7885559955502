<div class="modal-header">
  <h5 class="modal-title d-flex align-items-center">EConsent Authorization</h5>
  <button
    type="button"
    class="btn-close"
    (click)="activeModal.close(false)"
    aria-label="Close"
  ></button>
</div>

<div class="modal-body">
  <div [innerHTML]="eConsentText" class="mb-4">
  </div>
  <form #eConsentForm="ngForm" novalidate>
    <div class="row">
      <div class="form-check ms-2">
        <input class="form-check-input" [(ngModel)]="isEconsentChecked" type="checkbox" value="" id="isEconsentChecked" name="isEconsentChecked">
        <label class="form-check-label" for="isEconsentChecked">
          I / We Consent
        </label>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button
    type="button"
    class="btn btn-sm btn-secondary"
    (click)="activeModal.close(false)"
    data-bs-dismiss="modal"
  >
    Cancel
  </button>
  <button
    type="button"
    class="btn btn-sm bp-primary-bg-color"
    (click)="activeModal.close(true)"
    data-bs-dismiss="modal"
    [disabled]="!isEconsentChecked"
  >
    Accept
  </button>
</div>
