import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationForUser } from '../../../models/borrower-portal/application-for-user-model';
import { ApplicationTaskModel } from '../../../models/borrower-portal/application-task-model';

@Component({
  selector: 'tasks-online-app',
  templateUrl: './tasks-online-app.component.html',
  styleUrls: ['./tasks-online-app.component.scss']
})
export class TasksOnlineAppComponent implements OnInit {

  @Input() tasks: ApplicationTaskModel[] = [];
  @Input() application: ApplicationForUser;
  @Input() isAgent: boolean = false;

  constructor(private readonly _router: Router) { }

  ngOnInit(): void {
  }

  goToCompleteApplication = () => {
    const routeToGo = this.application.onlineApplicationIsCoborrower ? 'co-apply' : 'apply';
    this._router.navigate(
      [routeToGo],
      {
        queryParams: {
          companyGuid: this.application.companyGuid,
          appId: this.application.applicationId,
          flowGuid: this.application.onlineApplicationVersion,
        },
        queryParamsHandling: 'merge'
      });
  }
}
