import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Address } from '../../../models/address-model';
import { MortgageApplication } from '../../../models/mortgage-app.model';
import { MortgageApplicationService } from '../../../services/mortgage-application.service';
import { AddressComponent } from '../../address/address.component';
import { WizardStepComponentBase } from '../wizard-step-base.component';

@Component({
  selector: 'purchase-property-address',
  templateUrl: 'purchase-property-address.component.html'
})
export class PurchasePropertyAddressComponent extends WizardStepComponentBase<any> implements OnInit {

  @ViewChild(AddressComponent) addressComponent: AddressComponent | undefined;

  public address: Address = new Address();

  private _mortgageApplication: MortgageApplication;

  constructor(private readonly _mortgageApplicationService: MortgageApplicationService,
              private readonly _injector: Injector) {
      super(_injector);
      this._mortgageApplication = this._mortgageApplicationService.mortgageApplication;
      if (this._mortgageApplication.propertyAddress) {
        this.address = this._mortgageApplication.propertyAddress;
      }
  }

  onNextClicked() {
    if (this.addressComponent?.validate()) {
      // you can safely save the address into the mortgage app.
        this._mortgageApplication.propertyAddress = this.address;
        super.onNextClicked();
    }
  }

  onBackClicked() {
    super.onBackClicked();
  }

  ngOnInit() {
  }
}
