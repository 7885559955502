
export class GeneratePrequalLetterRequestModel {
  productDescription: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  loanAmount: number;
  purchasePrice: number;
  downPayment: number;
  interestRate: number;
  includeAusFindings: boolean;
  includeProofOfFunds: boolean;
}
