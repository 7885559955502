<div class="modal-header">
  <h5 class="modal-title d-flex align-items-center text-gray-900 fs-4 fw-bold">
    <i class="bp-orange-color fa fa-file-alt fa-2x me-2"></i> Generate a
    Preapproval Letter
  </h5>
  <button
    type="button"
    class="btn-close"
    (click)="activeModal.dismiss()"
    aria-label="Close"
  ></button>
</div>

<div class="modal-body mb-1">
  <div class="wrapper d-none d-md-block d-lg-block d-xl-block">
    <ol class="c-stepper-horizontal">
      <li
        class="c-stepper__item"
        data-before-content="1"
        [ngClass]="step == 1 ? 'active-step' : 'inactive-step'"
        (click)="step = 1"
      >
        <h3 class="c-stepper__title">Get Started</h3>
      </li>
      <li
        class="c-stepper__item"
        data-before-content="2"
        [ngClass]="step == 2 ? 'active-step' : 'inactive-step'"
        (click)="step = 2"
      >
        <h3 class="c-stepper__title">Property Address</h3>
      </li>
      <li
        class="c-stepper__item"
        data-before-content="3"
        [ngClass]="step == 3 ? 'active-step' : 'inactive-step'"
        (click)="step = 3"
      >
        <h3 class="c-stepper__title">Purchase Price</h3>
      </li>
      <li
        class="c-stepper__item"
        data-before-content="4"
        [ngClass]="step == 4 ? 'active-step' : 'inactive-step'"
        (click)="step = 4"
      >
        <h3 class="c-stepper__title">Down Payment</h3>
      </li>
      <li *ngIf="showAdditionalOptionsStep"
      class="c-stepper__item"
      data-before-content="5"
      [ngClass]="step == 5 ? 'active-step' : 'inactive-step'"
      (click)="step = 5"
    >
      <h3 class="c-stepper__title">
        Additional Options
      </h3>
    </li>
      <li
        class="c-stepper__item"
        [attr.data-before-content]="showAdditionalOptionsStep ? 6 : 5"
        [ngClass]="step == 6 ? 'active-step' : 'inactive-step'"
        (click)="step = 6"
      >
        <h3 class="c-stepper__title">Review Information</h3>
      </li>
    </ol>
  </div>

  <div class="wrapper d-block d-sm-none">
    <ol class="c-stepper-vertical">
      <li
        class="c-stepper__item"
        data-before-content="1"
        [ngClass]="step == 1 ? 'active-step' : 'inactive-step'"
        (click)="step = 1"
      >
        <h3 class="c-stepper__title">Get Started</h3>
      </li>
      <li
        class="c-stepper__item"
        data-before-content="2"
        [ngClass]="step == 2 ? 'active-step' : 'inactive-step'"
        (click)="step = 2"
      >
        <h3 class="c-stepper__title">Property Address</h3>
      </li>
      <li
        class="c-stepper__item"
        data-before-content="3"
        [ngClass]="step == 3 ? 'active-step' : 'inactive-step'"
        (click)="step = 3"
      >
        <h3 class="c-stepper__title">Purchase Price</h3>
      </li>
      <li
        class="c-stepper__item"
        data-before-content="4"
        [ngClass]="step == 4 ? 'active-step' : 'inactive-step'"
        (click)="step = 4"
      >
        <h3 class="c-stepper__title">Down Payment</h3>
      </li>
      <li *ngIf="showAdditionalOptionsStep"
      class="c-stepper__item"
      data-before-content="5"
      [ngClass]="step == 5 ? 'active-step' : 'inactive-step'"
      (click)="step = 5"
    >
      <h3 class="c-stepper__title">Additional Options</h3>
    </li>
      <li
        class="c-stepper__item"
        [attr.data-before-content]="showAdditionalOptionsStep ? 6 : 5"
        [ngClass]="step == 6 ? 'active-step' : 'inactive-step'"
        (click)="step = 6"
      >
        <h3 class="c-stepper__title">Review Information</h3>
      </li>
    </ol>
  </div>

  <div class="mt-4 container">
    <div class="row">
      <prequal-letter-wizard-get-started
        *ngIf="step == 1"
        (next)="step = 2"
        (close)="activeModal.dismiss()"
      ></prequal-letter-wizard-get-started>
      <prequal-letter-wizard-property-address
        [request]="req"
        [appId]="details.applicationId"
        [hidden]="step != 2"
        (back)="step = 1"
        (next)="step = 3"
        (close)="activeModal.dismiss()"
      ></prequal-letter-wizard-property-address>
      <prequal-letter-wizard-purchase-price
        [request]="req"
        [maxPurchasePrice]="details.maxPurchasePrice"
        *ngIf="step == 3"
        (back)="step = 2"
        (next)="step = 4"
        (close)="activeModal.dismiss()"
      ></prequal-letter-wizard-purchase-price>
      <prequal-letter-wizard-down-payment
        *ngIf="step == 4"
        [request]="req"
        [minDownPaymentPercent]="details.downPaymentPercent"
        [maxDownPaymentPercent]="details.maxDownPaymentPercent"
        (back)="step = 3"
        (next)="step = (showAdditionalOptionsStep ? 5 : 6)"
        (close)="activeModal.dismiss()"
      ></prequal-letter-wizard-down-payment>
      <wizard-additional-options
        *ngIf="step == 5"
        [request]="req"
        [details]="details"
        (back)="step = 4"
        (next)="step = 6"
        (close)="activeModal.dismiss()"
      ></wizard-additional-options>
      <prequal-letter-wizard-review-information
        *ngIf="step == 6"
        [request]="req"
        [showAdditionalOptionsStep]="showAdditionalOptionsStep"
        (goStep)="step = $event"
        (next)="generatePrequalLetter()"
        (close)="activeModal.dismiss()"
      ></prequal-letter-wizard-review-information>
    </div>
  </div>
</div>
