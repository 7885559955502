import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApplicationForUser } from '../../../models/borrower-portal/application-for-user-model';
import { ApplicationTaskModel } from '../../../models/borrower-portal/application-task-model';
import { TurboInsuranceQuote } from '../../../models/turbo-insurance/turbo-insurance-quote.model';
import { NotificationService } from '../../../services/notification.service';
import { TurboInsuranceService } from '../../../services/turbo-insurance.service';

@Component({
  selector: 'tasks-hoi',
  templateUrl: './tasks-hoi.component.html',
  styleUrls: ['./tasks-hoi.component.scss']
})
export class TasksHoiComponent implements OnInit {

  @Input() tasks: ApplicationTaskModel[] = [];
  @Input() application: ApplicationForUser;

  quotes: TurboInsuranceQuote[] = [];
  loanDocTaskId: number;
  chosenQuoteId: string;

  constructor(
    private readonly _turBoInsuranceService: TurboInsuranceService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notifsService: NotificationService
  ) { }

  ngOnInit(): void {
    if (this.tasks.length) {
      this.getQuotesForTask(this.tasks[0].taskId);
    }
  }

  chooseQuote = (quoteId: string) => {
    this._spinner.show();
    this._turBoInsuranceService.chooseQuote(this.application.applicationId, quoteId, this.loanDocTaskId)
      .subscribe({
        next: () => {
          this._spinner.hide();
          this._notifsService.showSuccess("Quote selected successfully.", 'Success!');
        },
        error: (error) => {
          this._notifsService.showError(error.message || "Unable to fetch task quotes.", 'Error!');
          this._spinner.hide();
        }
      });

  }

  private getQuotesForTask(taskId: number): void {
    this._spinner.show();
    this._turBoInsuranceService.getQuotesForTask(taskId, false)
      .subscribe({
        next: (response) => {
          this.quotes = response.quotes;
          this.loanDocTaskId = response.loanDocTaskId;
          this.chosenQuoteId = response.chosenQuoteId;
          this._spinner.hide();
        },
        error: (error) => {
          this._notifsService.showError(error.message || "Unable to fetch task quotes.", 'Error!');
          this._spinner.hide();
        }
      });
  }
}
