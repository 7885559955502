<form #militaryServiceForm="ngForm" novalidate id="militaryServiceForm" name="militaryServiceForm">
  <div class="row mb-5" [ngClass]="{'border-danger': hasServedInMilitary.touched && hasServedInMilitary.invalid}">
    <div class="col-lg-10 col-md-12">
      <div class="position-relative" style="font-size: 15px;">
        Did you (or your deceased spouse) ever serve, or are you currently serving, in the United States Armed Forces?
      </div>
    </div>
    <div class="col-lg-2 col-sm-6">
      <div class="d-flex">
        <div class="form-check form-check-custom form-check-solid">
          <input class="form-check-input" name="hasServedInMilitary" required [value]="true" type="radio" id="yes{{borrower.borrowerId}}"
            [(ngModel)]="borrower.hasServedInMilitary" #hasServedInMilitary="ngModel"
            (ngModelChange)="onHasServedInMilitaryChanged()" />
          <label style="padding-right: 6px;" class="form-check-label" for="yes{{borrower.borrowerId}}">Yes</label>
        </div>
        <div class="form-check form-check-custom form-check-solid ms-8">
          <input class="form-check-input" name="hasServedInMilitary" required [value]="false" type="radio" id="no{{borrower.borrowerId}}"
            [(ngModel)]="borrower.hasServedInMilitary" #hasServedInMilitary="ngModel"
            (ngModelChange)="onHasServedInMilitaryChanged()" />
          <label style="padding-right: 6px;" class="form-check-label" for="no{{borrower.borrowerId}}">No</label>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="borrower.hasServedInMilitary">
    <div class="row mb-8">
      <div class="col-md-6 fv-row">
        <label class="form-label required">Military Status</label>
        <select class="form-select form-select-solid" data-control="select2" name="militaryStatus"
          #borrowerMilitaryStatus="ngModel" [(ngModel)]="militaryStatus" (ngModelChange)="onMilitaryStatusChanged()"
          [ngClass]="{'is-invalid' : borrowerMilitaryStatus && borrowerMilitaryStatus.touched && borrowerMilitaryStatus.invalid}"
          required>
          <option value="" disabled>Select one...</option>
          <option *ngFor="let militaryStatusType of militaryStatusTypes" [value]="militaryStatusType.value">
            {{militaryStatusType.name}}</option>
        </select>
      </div>
      <div class="col-md-6 fv-row" *ngIf="militaryStatus === 'isActiveDutyMilitary'">
        <label class="form-label">Completion Date</label>
        <div class="position-relative d-flex align-items-center">
          <input class="form-control form-control-solid" matInput mask="M0/d0/0000" [showMaskTyped]="true"
            [specialCharacters]="[ '/' ]" [dropSpecialCharacters]="false" datePicker #militaryTourEndDate="ngModel"
            [(ngModel)]="borrower.militaryTourEndDate" placeholder="" name="militaryTourEndDate" inputmode="numeric"
            ngControl="militaryTourEndDate" id="militaryTourEndDate"
            [ngClass]="{'is-invalid' : militaryTourEndDate && militaryTourEndDate.touched && militaryTourEndDate.invalid}" />
        </div>
      </div>
    </div>

    <div class="row mb-8">
      <div class="col-md-6 fv-row">
        <label class="form-label required">Branch of Service</label>
        <select class="form-select form-select-solid" data-control="select2" name="branchOfService"
          #branchOfService="ngModel" [(ngModel)]="borrower.branchOfService"
          [ngClass]="{'is-invalid' : branchOfService && branchOfService.touched && branchOfService.invalid}" required>
          <option [ngValue]="null" disabled>Select one...</option>
          <option *ngFor="let militaryBranchOfServiceType of militaryBranchOfServiceTypes"
            [value]="militaryBranchOfServiceType.value">
            {{militaryBranchOfServiceType.name}}</option>
        </select>
      </div>
    </div>

    <div class="row mb-4">
      <div class="checkbox mb-2">
        <label class="form-check form-check-inline form-check-solid me-2" for="isFirstUseOfVaBenefit">
          <input id="isFirstUseOfVaBenefit" name="isFirstUseOfVaBenefit" type="checkbox"
            [(ngModel)]="borrower.isFirstUseOfVaBenefit" class="form-check-input">
          <span class="fw-bold ps-2 fs-5">First Time Use</span>
        </label>
      </div>
      <div class="checkbox mb-2">
        <label class="form-check form-check-inline form-check-solid me-2" for="isVaFundingFeeExempt">
          <input id="isVaFundingFeeExempt" name="isVaFundingFeeExempt" type="checkbox"
            [(ngModel)]="borrower.isVaFundingFeeExempt" class="form-check-input">
          <span class="fw-bold ps-2 fs-5">Exempt from Funding Fee</span>
        </label>
      </div>
      <div class="checkbox mb-2">
        <label class="form-check form-check-inline form-check-solid me-2" for="isSurvivingSpouseOfMilitary">
          <input id="isSurvivingSpouseOfMilitary" name="isSurvivingSpouseOfMilitary" type="checkbox"
            [(ngModel)]="borrower.isSurvivingSpouseOfMilitary" class="form-check-input">
          <span class="fw-bold ps-2 fs-5">Surviving Spouse</span>
        </label>
      </div>
    </div>
  </ng-container>
</form>
