import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'close-x-svg-icon',
  template: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
              viewBox="0 0 24 24" version="1.1">
              <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)"
                fill="#000000">
                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1"></rect>
                <rect fill="#000000" opacity="0.5"
                  transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7"
                  width="16" height="2" rx="1"></rect>
              </g>
            </svg>`
})

export class CloseXSvgiconComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
