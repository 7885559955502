<ng-template #verifyCredit>
  <div class="d-flex mb-5" *ngIf="inEditMode">
    <div class="col-sm-3 col-form-label fw-bold fs-6 editor-label">Hide Real Time Credit Run Option</div>
    <div class="col-sm-2 form-check form-switch form-check-custom form-check-solid">
      <input class="form-check-input h-20px w-30px" type="checkbox" name="hideRealTimeCreditRunOption" id="hideRealTimeCreditRunOption"
        [(ngModel)]="step.hideRealTimeCreditRunOption" />
      <label class="form-check-label" for="hideRealTimeCreditRunOption">
        {{step.hideRealTimeCreditRunOption ? "Yes" : "No"}}
      </label>
    </div>
  </div>
  <div class="row" [hidden]="!isCheckingCreditHistory">
    <div class="col-xl-12 mb-10">
      <div class="row g-9">
        <div class="d-flex justify-content-center">
          <i class="fa fa-circle-notch fa-spin fa-4x"></i>
        </div>
      </div>
    </div>
  </div>
  <div [hidden]="isCheckingCreditHistory">
    <div class="card shadow-sm mb-5">
      <div class="card-header">
          <h3 class="card-title">{{mainBorrower.firstName + " " + mainBorrower.lastName}}</h3>
      </div>
      <div class="card-body">
        <borrower-credit-mini
          #borrowerCredit
          [borrowerIndex]="0"
          [borrower]="mainBorrower"
          [canRunCredit]="canRunCredit"
          (birthDateOrSsnChanged)="onBorrowersBirthDateOrSsnChanged()"
          (permissionToRunCreditChanged)="onPermissionToRunCreditChangedForBorrower($event)"
          [isCreditRun]="isCreditRunForBorrower(mainBorrower)">
        </borrower-credit-mini>
      </div>
    </div>

    <ng-container *ngIf="!inCoApplyFlow">
      <ng-container *ngFor="let borrower of coBorrowers; let i = index">
        <div *ngIf="borrower.willBeEdited" class="card shadow-sm mb-5" >
            <div class="card-header">
              <h3 class="card-title">{{borrower.firstName + " " + borrower.lastName}}</h3>
            </div>
            <div class="card-body">
              <borrower-credit-mini
                #borrowerCredit
                [borrower]="borrower"
                [borrowerIndex]="i+1"
                [canRunCredit]="canRunCredit"
                [isCreditRun]="isCreditRunForBorrower(borrower)">
              </borrower-credit-mini>
            </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<wizard-step-template [stepMainTemplate]="verifyCredit" [step]="step" [stepComponent]="this"></wizard-step-template>
