import { Component, ComponentFactoryResolver, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SvgIconComponentFactory } from 'projects/shared/svg-icons/svg-icon-component-factory';
import { SvgIconHostDirective } from 'projects/shared/svg-icons/svg-icon-host.directive';
import { Option } from '../../../models/wizard/option.model';

@Component({
  selector: 'answer-option',
  templateUrl: 'answer-option.component.html',
  styleUrls: ['answer-option.component.scss']
})

export class AnswerOptionComponent implements OnInit {

  @Input()
  option!: Option;

  @Input()
  inEditMode: boolean = true;

  @Output() optionSelected = new EventEmitter<Option>();

  @ViewChild(SvgIconHostDirective, { static: true }) svgIconHost!: SvgIconHostDirective;

  constructor(private _componentFactoryResolver: ComponentFactoryResolver, private _svgIconFactory: SvgIconComponentFactory) { }

  getOptionSelectedEmitter = () => {
    return this.optionSelected;
  }

  onOptionSelected = (option: Option) => {
    this.optionSelected.emit(option);
  }

  loadIcon() {
    if (!this.option.iconName || this.option.iconType !== "svg") {
      return;
    }
    const svgIconComponent = this._svgIconFactory.getSvIconComponent(this.option.iconName);
    if (!svgIconComponent) {
      return;
    }
    const componentFactory = this._componentFactoryResolver.resolveComponentFactory(svgIconComponent);

    const viewContainerRef = this.svgIconHost.viewContainerRef;
    viewContainerRef.clear();

    viewContainerRef.createComponent(componentFactory);
  }

  ngOnInit() {
    this.loadIcon();
  }
}
