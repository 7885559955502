import { Component, Injector, ViewChild } from '@angular/core';
import { Borrower } from '../../../models/borrower-model';
import { ReviewApplicationStep } from '../../../models/wizard/review-application.model';
import { BorrowerSummaryComponent } from '../../borrower-summary/borrower-summary.component';
import { WizardStepComponentBase } from '../wizard-step-base.component';
import { SubjectProperty } from '../../../models/subject-property.model';
import { MortgageTerm } from '../../../models/mortgage-term.model';
import { TransactionDetail } from "../../../models/transaction-detail.model";
import { ModalService } from "../../../../../../shared/modal/modal.service";
import { NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { LoanSummaryDialogComponent } from "../../loan-summary-dialog/loan-summary-dialog.component";
import { SubjectPropertySummaryDialogComponent } from "../../subject-property-summary-dialog/subject-property-summary-dialog.component";
import { finalize } from 'rxjs/operators';
import { ErrorMessage } from '../../../models/error-message.model';
import { LoanSummaryComponent } from '../../loan-summary/loan-summary.component';
import { MortgageApplicationService } from '../../../services/mortgage-application.service';
import { MortgageCalculationsService } from '../../../services/mortgage-calculations.service';
import { UrlaMortgage } from '../../../models/urla-mortgage.model';
import { NavigationService } from '../../../services/navigation.service';
import { Constants } from '../../../services/constants';

declare const Swal: any;

@Component({
  selector: 'review-application-step',
  templateUrl: 'review-application-step.component.html'
})
export class ReviewApplicationStepComponent extends WizardStepComponentBase<ReviewApplicationStep> {

  @ViewChild(BorrowerSummaryComponent) borrowerSummary!: BorrowerSummaryComponent;

  @ViewChild(LoanSummaryComponent) loanSummarySummary!: LoanSummaryComponent;

  borrowerSummaryExpanded: boolean = false;

  mainBorrower!: Borrower;
  coBorrowers: Borrower[] = [];

  isSubmitted: boolean = false;

  constructor(
    private readonly _injector: Injector,
    private readonly _mortgageApplicationService: MortgageApplicationService,
    private readonly _mortgageCalculationsService: MortgageCalculationsService,
    private readonly _modalService: ModalService) {
    super(_injector);
    if (this.currentBorrower) {
      this.mainBorrower = this.currentBorrower;
      this.coBorrowers = this.mortgageApplication.borrowers.filter(b => b.primaryEmail.toLocaleLowerCase() !==
        this.mainBorrower.primaryEmail.toLocaleLowerCase());
    }
  }

  ngOnInit() {
    super.ngOnInit();
    this.nextButtonText = "Submit Your Information";
  }

  get borrowers(): Borrower[] {
    return this.mortgageApplication.borrowers;
  }

  get subjectProperty(): SubjectProperty {
    return this.mortgageApplication && this.mortgageApplication.subjectProperty ? this.mortgageApplication.subjectProperty : new SubjectProperty();
  }

  get purchasePrice(): number {
    if (this.mortgageApplication && this.mortgageApplication.transactionDetail
      && this.mortgageApplication.transactionDetail.purchasePriceAmount) {
      return this.mortgageApplication.transactionDetail.purchasePriceAmount;
    }
    return 0;
  }

  get mortgageTerm(): MortgageTerm {
    return this.mortgageApplication && this.mortgageApplication.mortgageTerm ? this.mortgageApplication.mortgageTerm : new MortgageTerm();
  }

  get transactionDetail(): TransactionDetail {
    return this.mortgageApplication && this.mortgageApplication.transactionDetail ? this.mortgageApplication.transactionDetail : new TransactionDetail();
  }

  onLoanSummaryEditClicked = () => {
    this.showLoanSummaryPopup();
  }

  onSubjectPropertySummaryEditClicked = () => {
    this.showSubjectPropertySummaryPopup();
  }

  onBorrowerChanged = (borrower: Borrower) => {
    const borrowerThatChanged = this.borrowers.find(b => b.borrowerId === borrower.borrowerId);
    if (borrowerThatChanged) {
      const index = this.borrowers.indexOf(borrowerThatChanged);
      if (index >= 0) {
        const changedBorrower = { ...borrower }
        this.borrowers[index] = changedBorrower;
        if (this.mainBorrower.borrowerId === borrower.borrowerId) {
          this.mainBorrower = changedBorrower;
        }
      }
    }
  }

  onLoanDetailsClicked = () => {
    this.showLoanSummaryPopup();
  }

  onSubjectPropertyDetailsClicked = () => {
    this.showSubjectPropertySummaryPopup();
  }

  onNextClicked() {
    if (this.isEditEnabled) {
      this.isSubmitted = true;
      return;
    }
    this.startSpinner();
    this.wizardFlowService.context.borrowerAppStatus.details.applicationSubmitted = true;

    // TODO - kkilinc: There are 2 mortgage models in place right now, unify them!!
    const urlaMortgage = this.mortgageApplication as unknown as UrlaMortgage;

    this._mortgageCalculationsService.calculateMortgageStatistics(urlaMortgage);

    this.mortgageApplication.transactionDetail.cashFromToBorrowerAmount = urlaMortgage.calculatedStats?.cashFromOrToTheBorrower;

    this.wizardFlowService.saveMortgage().subscribe(result => {
      this.mortgageApplicationService.saveAppStatus(this.wizardFlowService.context.mortgage.applicationId,
        this.wizardFlowService.context.mortgage.mortgageId, this.wizardFlowService.context!.currentBorrower!.borrowerId!,
        this.wizardFlowService.context.borrowerAppStatus).subscribe(result => {
          this.mortgageApplicationService.submitApplication(this.mortgageApplication.applicationId).pipe(
            finalize(() => {
              this.stopSpinner();
            })
          ).subscribe(
            response => {
              Swal.fire(
                'Success!',
                'Your application has been successfully submitted.',
                'success'
              );
              this.redirectToBorrowerPortal();
            },
            error => {
              this.displayError();
            }
          )
        }, error => {
          this.displayError();
        })
    }, error => {
      this.displayError();
    });
  }

  private modalOptions: NgbModalOptions = {
    size: "lg",
    backdrop: "static",
    container: "#reviewApplication"
  };

  private showSubjectPropertySummaryPopup() {
    const modal = this._modalService.show(SubjectPropertySummaryDialogComponent, this.modalOptions);
    modal.componentInstance.subjectProperty = this.mortgageApplication.subjectProperty;
    modal.result.then(subjectProperty => {
      this.mortgageApplication.subjectProperty = subjectProperty;
    }, error => { });
  }

  private displayError = () => {
    const errorTitle = "Error!";
    const errorMessage = "Something went wrong, please try again";
    const error = new ErrorMessage(errorTitle, errorMessage);
    this.showError(error);
  }

  private redirectToBorrowerPortal = () => {
    this._mortgageApplicationService.getBorrowerPortalUrl().subscribe(url => {
      setTimeout(() => {
        //redirect
        if (!url.endsWith("/")) {
          url += "/";
        }
        const authorizationDataKey: string = Constants.authorization.authorizationDataKey;
        const authDataObj = JSON.parse(localStorage.getItem(authorizationDataKey)!);
        const token = authDataObj.token;
        let redirectUrl = "https://" + url + "borrower-portal/submit-app-redirect/"
          + this._mortgageApplicationService.mortgageApplication.applicationId + "?authToken=" + token;
        window.location.href = redirectUrl;
      }, 2000);
    }, error => {

    })
  }

  private showLoanSummaryPopup() {
    const modal = this._modalService.show(LoanSummaryDialogComponent, this.modalOptions);
    modal.componentInstance.subjectProperty = this.mortgageApplication.subjectProperty;
    modal.componentInstance.mortgageTerm = this.mortgageApplication.mortgageTerm;
    modal.componentInstance.transactionDetail = this.mortgageApplication.transactionDetail;
    modal.componentInstance.fieldsToConfig = this.step.fieldConfig;
    modal.result.then(result => {
      this.mortgageApplication.subjectProperty = result.subjectProperty;
      this.mortgageApplication.mortgageTerm = result.mortgageTerm;
      this.mortgageApplication.transactionDetail = result.transactionDetail;
      this.loanSummarySummary.calculateEstimatedLtv();
    }, error => { });
  }
}
