
<div class="card pt-4 mt-4 mb-4 mb-xl-4 border">
  <div class="card-body card-body-mobile pt-0 pe-3">
    <div class="py-0" data-kt-customer-payment-method="row">
      <div class="d-flex flex-stack flex-wrap">
        <div class="fs-6 fw-bolder text-dark pb-2">ASSETS</div>
        <!-- <a href="javascript:void(0);" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
          <span class="svg-icon svg-icon-3"><duotone-write-svg-icon>
          </duotone-write-svg-icon>
        </span></a> -->
      </div>
      <div class="d-flex flex-column flex-xl-row hidden-when-mobile">
        <div class="flex-lg-row-fluid mb-10 mb-xl-0">
          <div class="mt-n1">
            <div class="m-0">
              <asset-list
                [assets]="assets"
                (assetEdited)="onAssetEdited($event)"
                (assetDeleted)="onAssetDeleted($event)"></asset-list>
            </div>
          </div>
        </div>
      </div>
      <div class="hidden-when-not-mobile w-100">
        <div class="collapse show" data-bs-parent="#kt_customer_view_payment_method">
          <asset-list
                [assets]="assets"
                (assetEdited)="onAssetEdited($event)"
                (assetDeleted)="onAssetDeleted($event)">
          </asset-list>
        </div>
      </div>
    </div>
  </div>
</div>
