import { Component, Injector, OnInit } from '@angular/core';
import { SimpleMessageStep } from '../../../models/wizard/simple-message-step.model';
import { WizardStepComponentBase } from '../wizard-step-base.component';

@Component({
  selector: 'simple-message-step',
  templateUrl: 'simple-message-step.component.html'
})

export class SimpleMessageStepComponent extends WizardStepComponentBase<SimpleMessageStep> implements OnInit {
  constructor(private readonly _injector: Injector) {
    super(_injector);
  }

  ngOnInit() { }
}
