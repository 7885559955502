import { Injectable } from '@angular/core';
import { environment } from 'projects/borrower-app/src/environments/environment';
import { ApiInfo, IEnvironment, LogLevel } from './environment.interface';

@Injectable()
export class EnvironmentService implements IEnvironment {

  get production(): boolean {
    return environment.production;
  }

  get enableDebugTools(): boolean {
    return environment.enableDebugTools;
  }

  get logLevel(): LogLevel {
    return environment.logLevel;
  }

  get apiInfo(): ApiInfo {
    return environment.apiInfo;
  }

  get companyLogoUrl(): string {
    return environment.companyLogoUrl;
  }

  get branchLogoUrl(): string {
    return environment.branchLogoUrl;
  }

  get dnsBasedBranchLogoUrl(): string {
    return environment.dnsBasedBranchLogoUrl;
  }

  get faviconUrl(): string {
    return environment.faviconUrl;
  }

  get environment(): string {
    return environment.environment;
  }

  get clientRoot(): string {
    return environment.clientRoot;
  }

  get borrowerPortalURI(): string {
    return environment.borrowerPortalURI;
  }

  get scope(): string {
    return environment.scope;
  }

  constructor() { }
}

