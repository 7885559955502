import { Component, Injector, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Address } from '../../../models/address-model';
import { ResidencyType } from '../../../models/enums/residency-type.enum';
import { ResidencyAddress } from '../../../models/residency-address.model';
import { SubjectProperty } from '../../../models/subject-property.model';
import { AddressComponent } from '../../address/address.component';
import { WizardStepComponentBase } from '../wizard-step-base.component';

@Component({
  selector: 'current-address',
  templateUrl: 'current-address.component.html'
})
export class CurrentAddressComponent extends WizardStepComponentBase<any> {

  @ViewChild(AddressComponent) addressComponent: AddressComponent | undefined;

  @ViewChild('addressForm')
  addressForm: NgForm | undefined;

  address: | Address | undefined = new Address();

  refiPropertySameAsCurrentAddress: boolean | undefined = undefined;

  isRefinance: boolean = false;

  constructor(private readonly _injector: Injector) {
    super(_injector);

    if (!this.mortgageApplication.subjectProperty) {
      this.mortgageApplication.subjectProperty = new SubjectProperty();
    }

    this.saveMortgageApplicationBeforeNextStep = true;
    const presentAddress = this.currentBorrower.residencyAddresses.find(a => a.residencyType == ResidencyType.PresentAddress);

    this.isRefinance = this.mortgageApplication.subjectProperty.purposeOfLoan === "Refinance";

    this.refiPropertySameAsCurrentAddress = this.wizardFlowService.context.refiAddressSameAsCurrentAddress;

    if (presentAddress) {
      this.address = presentAddress.address;
    } else {
      const residencyAddress = new ResidencyAddress(ResidencyType.PresentAddress);
      residencyAddress.residencyAddressId = null;
      this.currentBorrower.residencyAddresses.push(residencyAddress);
      this.address = residencyAddress.address;
    }
  }

  onAddressQuestionAnswered = () => {
    if (this.refiPropertySameAsCurrentAddress) {
      if (this.address) {
        this.address.address1 = this.mortgageApplication.subjectProperty.address1;
        this.address.city = this.mortgageApplication.subjectProperty.city;
        this.address.state = this.mortgageApplication.subjectProperty.state;
        this.address.zipCode = this.mortgageApplication.subjectProperty.zipCode;
        this.address.county = this.mortgageApplication.subjectProperty.county;
      }
    } else {
      this.clearAddress();
    }
  }

  onNextClicked() {
    if (this.isRefinance) {
      this.wizardFlowService.context.refiAddressSameAsCurrentAddress = this.refiPropertySameAsCurrentAddress;
    }
    if (this.addressForm) {
      this.addressForm.form.markAllAsTouched();
      if (this.addressComponent?.validate() && this.addressForm.form.valid) {
        if (this.address) {
          if (this.isRefinance) {
            this.mortgageApplication.subjectProperty.address1 = this.address.address1;
            this.mortgageApplication.subjectProperty.city = this.address.city;
            this.mortgageApplication.subjectProperty.state = this.address.state;
            this.mortgageApplication.subjectProperty.zipCode = this.address.zipCode;
            this.mortgageApplication.subjectProperty.county = this.address.county;
          }
          let mailingAddress = this.currentBorrower.residencyAddresses.find(a => a.residencyType == ResidencyType.MailingAddress);
          if (!mailingAddress) {
            mailingAddress = new ResidencyAddress(ResidencyType.MailingAddress);
            this.currentBorrower.residencyAddresses.push(mailingAddress);
          }
          mailingAddress.address.address1 = this.address.address1;
          mailingAddress.address.city = this.address.city;
          mailingAddress.address.state = this.address.state;
          mailingAddress.address.zipCode = this.address.zipCode;
          mailingAddress.address.county = this.address.county;
        }
        super.onNextClicked();
      }
    }
  }

  private clearAddress = () => {
    if (this.address) {
      this.address.address1 = "";
      this.address.city = "";
      this.address.state = "";
      this.address.zipCode = "";
    }
  }
}
