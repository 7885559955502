import { AfterViewInit, Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Borrower } from '../../../models/borrower-model';
import { TurboInsuranceGetQuoteRequest } from '../../../models/turbo-insurance/get-quote-request.model';
import { HoiStep } from '../../../models/wizard/hoi-step.model';
import { TurboInsuranceService } from '../../../services/turbo-insurance.service';
import { WizardStepComponentBase } from '../wizard-step-base.component';

@Component({
  selector: 'hoi',
  templateUrl: 'hoi-step.component.html'
})
export class HoiStepComponent extends WizardStepComponentBase<HoiStep> implements OnInit, AfterViewInit {

  @ViewChild('hoiForm') hoiForm: NgForm | undefined;

  @ViewChild('hoiAmountInput') hoiAmountInputElement: ElementRef | undefined;

  mainBorrower!: Borrower;
  coBorrowers: Borrower[] = [];

  calculatedHoiAmount: number | undefined | null;
  hoiAmountEntered: number | undefined | null;

  showMoreHoiOptions: boolean = true;
  haveHoiQuote: boolean | undefined | null = undefined;

  isRefinance: boolean = false;

  subjectPropertyAddress: string = "";

  paymentPeriod: string = "PerYear";

  showMeOptionsMessage: string = "";

  enterHoiAmountQuestionText: string = ""

  hoiAmountValidationErrorMessage: string = "";

  isHoiAmountVisited: boolean = false;

  isHoiEnabled: boolean | undefined = false;

  constructor(private readonly _injector: Injector,
    private readonly _turboInsuranceService: TurboInsuranceService
  ) {
    super(_injector);
    this.saveMortgageApplicationBeforeNextStep = true;

    if (this.wizardFlowService.context.config) {
      this.isHoiEnabled = this.wizardFlowService.context.config.hoiEnabled;
    }
    if (this.wizardFlowService.isEditEnabled) {
      this.isHoiEnabled = true;
    }

    this.isRefinance = this.mortgageApplication.subjectProperty.purposeOfLoan === "Refinance";
    this.subjectPropertyAddress = this.mortgageApplication.subjectProperty.address1;
    if (this.isRefinance) {
      this.showMeOptionsMessage = `We might be able to save you some money on your monthly payments! Would you like to see your options
        after your application is submitted?`;
      this.enterHoiAmountQuestionText = "How much are you paying a month for your current homeowners insurance policy?";
      this.hoiAmountValidationErrorMessage = "Current homeowners insurance amount is required, please enter a value.";
    } else {
      this.showMeOptionsMessage = "Would you like us to show you some options after your application is submitted?";
      this.enterHoiAmountQuestionText = "What is the amount for the quote?";
      this.hoiAmountValidationErrorMessage = "Homeowners insurance quote is required, please enter a value.";
    }
  }

  ngOnInit() {
    const isSubjectPropertyAddressTbd = this.mortgageApplication.subjectProperty &&
      this.mortgageApplication.subjectProperty.address1 && this.mortgageApplication.subjectProperty.address1.toLocaleLowerCase() === 'tbd';
    if (isSubjectPropertyAddressTbd) {
      if (this.cameHereWithBackNavigation) {
        this.wizardFlowService.navigateBackward();
      } else {
        this.wizardFlowService.navigateForward();
      }
      return;
    }
    this.setupInitialState();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    if (this.hoiAmountInputElement) {
      this.hoiAmountInputElement.nativeElement.focus();
    }
  }

  onPaymentPeriodChanged = () => {
    this.calculateHoiAmountPerPeriod();
  }

  onHoiAmountChanged = () => {
    if (!this.hoiAmountEntered) {
      this.hoiAmountEntered = null;
      this.calculatedHoiAmount = null;
    }
    this.showMoreHoiOptions = true;
    this.calculateHoiAmountPerPeriod();
  }

  onHaveHoiQuoteQuestionAnswered = () => {
    if (this.hoiAmountInputElement) {
      setTimeout(() => {
        this.hoiAmountInputElement?.nativeElement.focus();
        if (!this.hoiAmountEntered || !this.haveHoiQuote) {
          this.mortgageApplication.proposedHousingExpense.homeownersInsurance = null;
        }
      }, 150);
    }
  }

  onNextClicked() {
    if (this.wizardFlowService.isEditEnabled) {
      super.onNextClicked();
      return;
    }
    if (this.hoiForm) {
      this.hoiForm.form.markAllAsTouched();
      if (this.hoiForm.form.valid) {
        this.wizardFlowService.context.customerPreferences.alreadyHasHoiQuote = this.haveHoiQuote;
        this.wizardFlowService.context.customerPreferences.showHoiQuotes = this.showMoreHoiOptions;
        if (this.haveHoiQuote || this.isRefinance) {
          this.mortgageApplication.proposedHousingExpense.homeownersInsurance = this.calculateMonthlyHoiAmount();
        }
        if (!this.isHoiEnabled) {
          super.onNextClicked();
          return;
        }
        if (this.showMoreHoiOptions) {
          this.startTurboInsuranceQuoteProcess();
        } else {
          super.onNextClicked();
        }
      }
    }
  }

  private calculateHoiAmountPerPeriod = () => {
    if (this.paymentPeriod === 'PerYear') {
      this.calculatedHoiAmount = this.hoiAmountEntered / 12;
    } else {
      this.calculatedHoiAmount = this.hoiAmountEntered * 12;
    }
  }

  private calculateMonthlyHoiAmount = (): number => {
    if (this.paymentPeriod === 'PerYear') {
      return this.calculatedHoiAmount;
    } else {
      return this.calculatedHoiAmount / 12
    }
  }

  private startTurboInsuranceQuoteProcess = () => {
    this.startSpinner();
    this._turboInsuranceService.createQuoteRequest(this.applicationInfo.applicationId).subscribe((quoteRequest: TurboInsuranceGetQuoteRequest) => {
      quoteRequest.quotePropertyInfo.streetAddress1 = this.mortgageApplication.subjectProperty.address1;
      quoteRequest.quotePropertyInfo.streetAddress2 = this.mortgageApplication.subjectProperty.address2;
      quoteRequest.quotePropertyInfo.city = this.mortgageApplication.subjectProperty.city;
      quoteRequest.quotePropertyInfo.county = quoteRequest.priorPropertyInfo?.county;
      quoteRequest.quotePropertyInfo.monthsAtAddress = quoteRequest.priorPropertyInfo?.monthsAtAddress;
      quoteRequest.quotePropertyInfo.state = this.mortgageApplication.subjectProperty.state;
      quoteRequest.quotePropertyInfo.zip = this.mortgageApplication.subjectProperty.zipCode;
      quoteRequest.additionalInfo.estimatedPropertyValue = this.mortgageApplication.subjectProperty.presentValue;
      this._turboInsuranceService.saveQuoteRequest(this.mortgageApplication.applicationId, quoteRequest).subscribe((response: any) => {
        this._turboInsuranceService.getQuotes(this.mortgageApplication.applicationId).subscribe((response: any) => {
          this.stopSpinner();
          super.onNextClicked();
        }, error => {
          // const errorMessage = error.error && error.error.message ? error.error.message : "An error occurred while preparing quotes.";
          // this.showError(new ErrorMessage("Error Preparing Quotes", errorMessage));
          this.stopSpinner();
          super.onNextClicked();
        });
      }, error => {
        this.stopSpinner();
        super.onNextClicked();
        // const errorMessage = error.error && error.error.message ? error.error.message : "An error occurred while preparing quotes.";
        // this.showError(new ErrorMessage("Error Preparing Quotes", errorMessage));
      });
    }, error => {
      this.stopSpinner();
      super.onNextClicked();
      // const errorMessage = error.error && error.error.message ? error.error.message : "An error occurred while preparing quotes.";
      // this.showError(new ErrorMessage("Error Preparing Quotes", errorMessage));
    });
  }

  private setupInitialState = () => {
    this.haveHoiQuote = this.wizardFlowService.context.customerPreferences.alreadyHasHoiQuote;
    this.showMoreHoiOptions = this.wizardFlowService.context.customerPreferences.showHoiQuotes;
    if (this.mortgageApplication.proposedHousingExpense && this.mortgageApplication.proposedHousingExpense.homeownersInsurance) {
      this.paymentPeriod = "PerMonth";
      this.hoiAmountEntered = this.mortgageApplication.proposedHousingExpense.homeownersInsurance;
      if (this.hoiAmountEntered) {
        this.haveHoiQuote = true;
      }
      this.calculateHoiAmountPerPeriod();
    }
  }
}
