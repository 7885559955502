import { Injectable } from "@angular/core";
import { Constants } from "projects/borrower-app/src/app/services/constants";
import { WizardFlowConfigServiceBase } from "projects/borrower-app/src/app/services/wizard/wizard-flow-config-service.base";
import { MyInfoStepComponent } from "projects/borrower-app/src/app/wizard/wizard-steps/my-info-step/my-info-step.component";
import { AddressFields } from "../../address-fields.model";
import { AddressStepConfig } from "../address-step-config.model";
import { ContextType } from "../context-type.enum";
import { CreateAccountStepConfig } from "../create-account-step-config.model";
import { CreditScoresStepConfig } from "../credit-scores-step.config.model";
import { GenericStepConfig } from "../../generic-step-config.model";
import { MyInfoStepConfig } from "../my-info-step-config.model";
import { SimpleNextStepDeciderConfig } from "../simple-next-step-decider-config.model";
import { SingleChoiceQuestionStepConfig } from "../single-choice-question-step-config.model";
import { StepConfig } from "../step-config.model";
import { StepTypeConfig } from "../step-type.config";
import { StepType } from "../step-type.enum";
import { WizardFlowConfig } from "../wizard-flow-config.model";
import { ChecklistsStepConfig } from "../checklists-step-config.model";
import { GatherLeadSourceStepConfig } from "../gather-lead-source-step-config.model";
import { DemographicsStepConfig } from "../demographics-step.config.model";
import { PricingStepConfig } from "../pricing-step.config.model";

const defaultStepName: string = "My New Step";
const defaultPath: string = "my-new-step";

@Injectable()
export class StepConfigFactory {

  constructor(private readonly _wizardFlowConfigService: WizardFlowConfigServiceBase) {
  }

  public static getConfig(stepType: StepType): StepTypeConfig | undefined {
    if (stepType == StepType.MyInfoStep) {
      return new StepTypeConfig(Constants.stepPaths.myInfo, MyInfoStepComponent)
    }
    return undefined;
  }

  public createConfig = (stepType: StepType, withinFlow: WizardFlowConfig, groupId?: number, path?: string): StepConfig | undefined => {
    const stepsOfTypeAlreadyInFlow = withinFlow.steps.filter(s => s.stepType === stepType);
    if (!path) {
      path = defaultPath;
    }
    if (stepsOfTypeAlreadyInFlow && stepsOfTypeAlreadyInFlow.length > 0) {
      const step = stepsOfTypeAlreadyInFlow[stepsOfTypeAlreadyInFlow.length - 1];
      path = step.path + "-" + (stepsOfTypeAlreadyInFlow.length + 1).toString();
    }
    let maxStepId = 0;
    if (withinFlow.steps && withinFlow.steps.length) {
      maxStepId = Math.max(...withinFlow.steps.map(o => isNaN(o.id as any) ? -9999 : Number(o.id)));
    }
    const id = (maxStepId + 1).toString();

    let stepConfig: StepConfig | undefined;
    if (stepType == StepType.MyInfoStep) {
      stepConfig = new MyInfoStepConfig(id, defaultStepName, path, undefined, undefined);
    } else if (stepType == StepType.CreateAccount) {
      stepConfig = new CreateAccountStepConfig(id, defaultStepName, path, undefined);
    } else if (stepType == StepType.AddressStep) {
      stepConfig = new AddressStepConfig(id, defaultStepName, path, groupId || 0, ContextType.None, new AddressFields("", "", "", "", ""));
    } else if (stepType == StepType.CreditScoresStep) {
      stepConfig = new CreditScoresStepConfig(id, defaultStepName, path, groupId || 0, undefined);
    } else if (stepType == StepType.SingleChoiceQuestionStep) {
      stepConfig = new SingleChoiceQuestionStepConfig(id, defaultStepName, path, groupId || 0, ContextType.None, undefined, undefined, undefined);
    } else if (stepType == StepType.CheckListsStep) {
      stepConfig = new ChecklistsStepConfig(id, defaultStepName, path, groupId, undefined);
    } else if (stepType === StepType.GatherLeadSourceStep) {
      stepConfig = new GatherLeadSourceStepConfig(id, defaultStepName, path, groupId || 0, undefined);
    } else if (stepType === StepType.DemographicsStep) {
      stepConfig = new DemographicsStepConfig(id, defaultStepName, path, groupId || 0, undefined);
    } else if (stepType === StepType.PricingStep) {
      stepConfig = new PricingStepConfig(id, defaultStepName, path, groupId || 0, undefined);
    } else {
      stepConfig = new GenericStepConfig(id, defaultStepName, path, groupId || 0, stepType, undefined);
    }
    stepConfig.nextStepDeciderConfig = new SimpleNextStepDeciderConfig("");
    return stepConfig;
  }
}
