

  <div class="modal-header">

    <h2>{{header}}</h2>

    <!-- hack to fool ngbModal autofocus -->
    <input type="text" style="display:none" />

    <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" aria-label="Close"
      (click)="activeModal.dismiss('Cross click')">
      <span class="svg-icon svg-icon-2x">
        <close-x-svg-icon></close-x-svg-icon>
      </span>
    </div>
  </div>

  <div class="modal-body py-10 px-lg-17">
    <form #assetInfoForm="ngForm" class="form" novalidate id="assetInfoForm" name="assetInfoForm">
      <div class="me-n7 pe-7">

        <div class="row mb-8">
          <div class="col-md-6 fv-row" *ngIf="!isHidden('asset.assetType')">
            <label class="form-label mb-2" [ngClass]="{'required': isRequired('asset.assetType')}">{{getLabel('asset.assetType', 'Asset Type')}}</label>
            <select name="assetType"
              [required]="isRequired('asset.assetType')"
              [ngClass]="{'is-invalid' : assetType && assetType.touched && assetType.invalid}"
              #assetType="ngModel" class="form-select form-select-solid" [(ngModel)]="asset!.assetType"
              data-control="select2" data-hide-search="true" data-placeholder="Choose one" (change)='onAssetTypeSelected(assetType.value)'>
              <option [ngValue]="null">Select one...</option>
              <option #incomeItem *ngFor="let assetType of assetTypes; let i = index" [ngValue]="assetType.value">{{assetType.name}}</option>
            </select>
            <div class="invalid-feedback">Asset Type is required.</div>
          </div>
          <div class="col-md-6 fv-row" *ngIf="!isHidden('asset.cashMarketValue')">
            <label class="form-label mb-2" [ngClass]="{'required': isRequired('asset.cashMarketValue')}">{{getLabel('asset.cashMarketValue', 'Amount')}}</label>
            <input class="form-control form-control-solid" mask="separator" thousandSeparator="," inputmode="decimal" prefix="$"
            [ngClass]="{'is-invalid' : amount && amount.touched && amount.invalid}"
            #amount="ngModel" [required]="isRequired('asset.cashMarketValue')" [(ngModel)]="asset!.cashMarketValue" placeholder="" name="amount" />
            <div class="invalid-feedback">Amount is required.</div>
          </div>
        </div>
        <div class="row mb-8" *ngIf="asset!.assetType == 'CheckingAccount' || asset!.assetType == 'SavingsAccount'
            || asset!.assetType == 'Stock' || asset!.assetType == 'TrustAccount' || asset!.assetType == 'MutualFund' || asset!.assetType == 'RetirementFund' ||
            asset!.assetType == 'MoneyMarketFund' || asset!.assetType == 'Bond' || asset!.assetType == 'CertificateOfDepositTimeDeposit'">
          <div class="col-md-6 fv-row" *ngIf="!isHidden('asset.depository')">
            <label class="form-label mb-2" [ngClass]="{'required': isRequired('asset.depository')}">{{getLabel('asset.depository', 'Name of Bank/Credit Union')}}</label>
            <input class="form-control form-control-solid"
              [required]="isRequired('asset.depository')"
              [ngClass]="{'is-invalid' : instituition && instituition.touched && instituition.invalid}"
              #instituition="ngModel" [(ngModel)]="asset!.depository" placeholder="" name="instituition" />
            <div class="invalid-feedback">Instituition is required.</div>
          </div>
          <div class="col-md-6 fv-row" *ngIf="!isHidden('asset.accountNumber')">
            <label class="form-label mb-2" [ngClass]="{'required': isRequired('asset.accountNumber')}">{{getLabel('asset.accountNumber', 'Account Number')}}</label>
            <input class="form-control form-control-solid" [required]="isRequired('asset.accountNumber')"
            [ngClass]="{'is-invalid' : accountNumber && accountNumber.touched && accountNumber.invalid}"
            #accountNumber="ngModel" [(ngModel)]="asset!.accountNumber" placeholder="" name="accountNumber" />
            <div class="invalid-feedback">Account number required.</div>
          </div>
        </div>
        <div class="row mb-8" *ngIf="asset!.assetType == 'Automobile'">
          <div class="col-md-6 fv-row" *ngIf="!isHidden('asset.makeModel')">
            <label class="form-label mb-2" [ngClass]="{'required': isRequired('asset.makeModel')}">{{getLabel('asset.makeModel', 'Make/Model')}}</label>
            <input class="form-control form-control-solid" [required]="isRequired('asset.makeModel')"
            [ngClass]="{'is-invalid' : makeModel && makeModel.touched && makeModel.invalid}"
            #makeModel="ngModel" [(ngModel)]="asset!.makeModel" placeholder="" name="makeModel" />
            <div class="invalid-feedback">Make/model is required.</div>
          </div>
          <div class="col-md-6 fv-row" *ngIf="!isHidden('asset.year')">
            <label class="form-label mb-2" [ngClass]="{'required': isRequired('asset.year')}">{{getLabel('asset.year', 'Year')}}</label>
            <input class="form-control form-control-solid"
            [ngClass]="{'is-invalid' : year && year.touched && year.invalid}"
            #year="ngModel" [required]="isRequired('asset.year')" [(ngModel)]="asset!.year" placeholder="" name="year" />
            <div class="invalid-feedback">Year is required.</div>
          </div>
        </div>
        <div *ngIf="asset!.assetType === 'PendingNetSaleProceedsFromRealEstateAssets'">
          <asset-pending-net-sale-proceeds [address]="address" [asset]="asset" [fieldsToConfig]="fieldConfig" ></asset-pending-net-sale-proceeds>
        </div>
        <div class="row mb-5" *ngIf="!inCoApplyFlow">
          <div class="col-md-12 fv-row">
            <label class="required form-label mb-2">Who owns this asset?</label>
            <div class="pl-20">
              <multi-checkbox-picker [possibleChoices]="possibleBorrowers" [selectedItems]="selectedBorrowers" [disabledChoices]="disabledBorrowers"></multi-checkbox-picker>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer flex-center">
    <button type="button" class="btn btn-white me-3" (click)="activeModal.close('Cancel click')">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="onSaveClicked()">Ok</button>
  </div>




