export enum ContextType {
  Mortgage = 1,
  SubjectProperty = 2,
  CustomerPreferences = 3,
  MainBorrower = 4,
  Borrowers = 5,
  WizardFlowContextItself = 6,
  Liabilities = 7,
  BorrowersThatMainBorrowerIsApplyingFor = 8,
  Extension = 9,
  ApplicationLoanPurpose = 10,
  None = 99
}
