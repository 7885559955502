import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ExpressionNextStepDeciderConfig } from '../../models/wizard/config/expression-next-step-decider-config.model';
import { WizardFlowConfig } from '../../models/wizard/config/wizard-flow-config.model';

declare const $: any;

@Component({
  selector: 'expression-next-step-decider-editor',
  templateUrl: 'expression-next-step-decider-editor.component.html'
})

export class ExpressionNextStepDeciderEditorComponent implements OnInit {

  @ViewChild("expressionForm")
  expressionForm: NgForm

  @Input()
  config: ExpressionNextStepDeciderConfig | undefined;

  @Output()
  expressionEditingCancelled: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  expressionEditingOked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  expressionsChanged: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  flow!: WizardFlowConfig;

  @Input()
  showButtons: boolean = true;

  constructor() { }

  ngOnInit() {
  }

  onOkClicked = () => {
    if (this.expressionForm) {
      this.expressionForm.form.markAllAsTouched();
      if (!this.expressionForm.form.valid) {
        return;
      }
    }
    this.expressionEditingOked.emit();
  }

  onCancelClicked = () => {
    this.expressionEditingCancelled.emit();
  }

  onConfigChanged = () => {
    this.expressionsChanged.emit();
  }
}
