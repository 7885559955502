<ng-template #income>
  <div class="d-flex mb-5" *ngIf="inEditMode">
    <div class="col-sm-6 col-form-label fw-bold fs-6 editor-label">Enforce At Least 2 Years Of Employment History</div>
    <div class="col-sm-2 form-check form-switch form-check-custom form-check-solid">
      <input class="form-check-input h-20px w-30px" type="checkbox" name="enforceAtLeast2YearsOfEmploymentHistory"
        id="enforceAtLeast2YearsOfEmploymentHistory" [(ngModel)]="step.enforceAtLeast2YearsOfEmploymentHistory" />
      <label class="form-check-label" for="enforceAtLeast2YearsOfEmploymentHistory">
        {{step.enforceAtLeast2YearsOfEmploymentHistory ? "Yes" : "No"}}
      </label>
    </div>
  </div>
  <div class="fv-row" id="income-main" *ngIf="mainBorrower">
    <div class="card shadow-sm mb-5"
      *ngIf="mainBorrower.employments.length > 0 || mainBorrower.nonEmploymentIncomes.length > 0">
      <div class="card-header">
        <h3 class="card-title">{{mainBorrower.firstName + " " + mainBorrower.lastName}}</h3>
        <span class="duration-indicator" [ngClass]="{'not-enough-history': step.enforceAtLeast2YearsOfEmploymentHistory && !borrowerEmploymentInfoByBorrowerId.get(mainBorrower.borrowerId).isRetired &&
          borrowerEmploymentInfoByBorrowerId.get(mainBorrower.borrowerId).numberOfMonthsEmployed < 24,
          'enough-history': (step.enforceAtLeast2YearsOfEmploymentHistory && !borrowerEmploymentInfoByBorrowerId.get(mainBorrower.borrowerId).isRetired &&
            borrowerEmploymentInfoByBorrowerId.get(mainBorrower.borrowerId).numberOfMonthsEmployed >= 24)}">
          {{borrowerEmploymentInfoByBorrowerId.get(mainBorrower.borrowerId).numberOfMonthsEmployed|
          monthsToYearsAndMonths}}
        </span>
      </div>
      <div class="card-body card-body-mobile">
        <borrower-income #borrowerIncome [fieldConfig]="step.fieldConfig" [borrower]="mainBorrower"
          (employmentChanged)="onEmploymentChanged()">
        </borrower-income>
      </div>
    </div>

    <ng-container *ngIf="!inCoApplyFlow">
      <ng-container *ngFor="let borrower of coBorrowers">
        <div
          *ngIf="borrowerSetting(borrower) && (borrower.employments.length > 0 || borrower.nonEmploymentIncomes.length > 0) "
          class="card shadow-sm mb-5">
          <div class="card-header">
            <h3 class="card-title">{{borrower.firstName + " " + borrower.lastName}}</h3>
            <span class="duration-indicator" [ngClass]="{'not-enough-history': step.enforceAtLeast2YearsOfEmploymentHistory && !borrowerEmploymentInfoByBorrowerId.get(borrower.borrowerId).isRetired &&
                borrowerEmploymentInfoByBorrowerId.get(borrower.borrowerId).numberOfMonthsEmployed < 24,
                'enough-history': (step.enforceAtLeast2YearsOfEmploymentHistory && !borrowerEmploymentInfoByBorrowerId.get(borrower.borrowerId).isRetired &&
                  borrowerEmploymentInfoByBorrowerId.get(borrower.borrowerId).numberOfMonthsEmployed >= 24)}">
              {{borrowerEmploymentInfoByBorrowerId.get(borrower.borrowerId).numberOfMonthsEmployed|
              monthsToYearsAndMonths}}
            </span>
          </div>
          <div class="card-body">
            <borrower-income #borrowerIncome [fieldConfig]="step.fieldConfig" [borrower]="borrower"
              (employmentChanged)="onEmploymentChanged()">
            </borrower-income>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <div class="row mt-10 hidden-when-mobile">
      <div class="col-xl-12 mb-6">
        <div class="row g-9" data-kt-buttons="true" data-kt-buttons-target="[data-kt-button]">
          <div class="col-md-3 col-lg-3 col-xxl-3">
            <label (click)="onW2BasedEmploymentClicked()"
              class="btn btn-outline btn-outline-dashed btn-outline-default p-4 d-flex align-items-center option-text"
              style="min-height: 80px;" for="militaryPay">
              <span class="svg-icon svg-icon-2x me-5">
                <duotone-shirt-svg-icon></duotone-shirt-svg-icon>
              </span>
              <span class="d-block fw-bold text-start">
                <span *ngIf="!inEditMode" class="text-dark fw-bolder d-block fs-5">{{ step.w2EmploymentActionLabel || 'Employment (W2)'}}</span>
                <input *ngIf="inEditMode" name="employmentW2ActionLabel" type="text"
                  class="form-control form-control-solid fs-5 fw-bolder" [(ngModel)]="step.w2EmploymentActionLabel" />
                <span class="text-gray-400 fw-bold fs-6"></span>
              </span>
            </label>
          </div>
          <div class="col-md-3 col-lg-3 col-xxl-3">
            <label (click)="onSelfEmploymentClicked()"
              class="btn btn-outline btn-outline-dashed btn-outline-default p-4 d-flex align-items-center option-text"
              style="min-height: 80px;" for="militaryPay">
              <span class="svg-icon svg-icon-2x me-5">
                <duotone-safe-svg-icon></duotone-safe-svg-icon>
              </span>
              <span class="d-block fw-bold text-start">
                <span *ngIf="!inEditMode" class="text-dark fw-bolder d-block fs-5">{{step.businessSelfEmploymentActionLabel || 'Business/Self Employment'}}</span>
                <input *ngIf="inEditMode" name="businessSelfEmploymentActionLabel" type="text"
                  class="form-control form-control-solid fs-5 fw-bolder" [(ngModel)]="step.businessSelfEmploymentActionLabel" />
                <span class="text-gray-400 fw-bold fs-6"></span>
              </span>
            </label>
          </div>
          <!-- <div class="col-md-3 col-lg-3 col-xxl-3">
            <label (click)="onIndependentContractorClicked()"
              class="btn btn-outline btn-outline-dashed btn-outline-default p-4 d-flex align-items-center option-text"
              style="min-height: 80px;" for="militaryPay">
              <span class="svg-icon svg-icon-2x me-5">
                <duotone-tools-svg-icon></duotone-tools-svg-icon>
              </span>
              <span class="d-block fw-bold text-start">
                <span *ngIf="!inEditMode" class="text-dark fw-bolder d-block fs-5">{{ step.independentContractorActionLabel || 'Independent Contractor'}}</span>
                <input *ngIf="inEditMode" name="independentContractorActionLabel" type="text"
                  class="form-control form-control-solid fs-5 fw-bolder" [(ngModel)]="step.independentContractorActionLabel" />
                <span class="text-gray-400 fw-bold fs-6"></span>
              </span>
            </label>
          </div> -->
          <div class="col-md-3 col-lg-3 col-xxl-3">
            <!-- <input type="radio" [checked]="option.selected" class="btn-check" (click)="onOptionSelected(option)" name="{{option.name}}" value="{{option.value}}" id="{{option.name}}" /> -->
            <label (click)="onMilitaryPayClicked()"
              class="btn btn-outline btn-outline-dashed btn-outline-default p-4 d-flex align-items-center option-text"
              style="min-height: 80px;" for="militaryPay">
              <span class="svg-icon svg-icon-2x me-5">
                <duotone-star-svg-icon></duotone-star-svg-icon>
              </span>
              <span class="d-block fw-bold text-start">
                <span *ngIf="!inEditMode" class="text-dark fw-bolder d-block fs-5">{{ step.militaryPayActionLabel || 'Military Pay'}}</span>
                <input *ngIf="inEditMode" name="militaryPayActionLabel" type="text"
                  class="form-control form-control-solid fs-5 fw-bolder" [(ngModel)]="step.militaryPayActionLabel" />
                <span class="text-gray-400 fw-bold fs-6"></span>
              </span>
            </label>
          </div>
          <div class="col-md-3 col-lg-3 col-xxl-3">
            <label (click)="onRentalIncomeClicked()"
              class="btn btn-outline btn-outline-dashed btn-outline-default p-4 d-flex align-items-center option-text"
              style="min-height: 80px;" for="militaryPay">
              <span class="svg-icon svg-icon-2x me-5">
                <duotone-home-svg-icon></duotone-home-svg-icon>
              </span>
              <span class="d-block fw-bold text-start">
                <span *ngIf="!inEditMode" class="text-dark fw-bolder d-block fs-5">{{step.rentalIncomeActionLabel || 'Rental'}}</span>
                <input *ngIf="inEditMode" name="rentalIncomeActionLabel" type="text"
                  class="form-control form-control-solid fs-5 fw-bolder" [(ngModel)]="step.rentalIncomeActionLabel" />
                <span class="text-gray-400 fw-bold fs-6"></span>
              </span>
            </label>
          </div>
        </div>
      </div>
      <div class="col-xl-12">
        <div class="row g-9">
          <div class="col-md-3 col-lg-3 col-xxl-3">
            <label (click)="onPensionClicked()"
              class="btn btn-outline btn-outline-dashed btn-outline-default p-4 d-flex align-items-center option-text"
              style="min-height: 80px;" for="militaryPay">
              <span class="svg-icon svg-icon-2x me-5">
                <duotone-dollar-svg-icon></duotone-dollar-svg-icon>
              </span>
              <span class="d-block fw-bold text-start">
                <span *ngIf="!inEditMode" class="text-dark fw-bolder d-block fs-5">{{step.pensionIncomeActionLabel || 'Pension'}}</span>
                <input *ngIf="inEditMode" name="pensionIncomeActionLabel" type="text"
                  class="form-control form-control-solid fs-5 fw-bolder" [(ngModel)]="step.pensionIncomeActionLabel" />
                <span class="text-gray-400 fw-bold fs-6"></span>
              </span>
            </label>
          </div>
          <div class="col-md-3 col-lg-3 col-xxl-3">
            <label (click)="onSocialSecurityClicked()"
              class="btn btn-outline btn-outline-dashed btn-outline-default p-4 d-flex align-items-center option-text"
              style="min-height: 80px;" for="militaryPay">
              <span class="svg-icon svg-icon-2x me-5">
                <duotone-bank-svg-icon></duotone-bank-svg-icon>
              </span>
              <span class="d-block fw-bold text-start">
                <span *ngIf="!inEditMode" class="text-dark fw-bolder d-block fs-5">{{step.socialSecurityIncomeActionLabel || 'Social Security'}}</span>
                <input *ngIf="inEditMode" name="socialSecurityIncomeActionLabel" type="text"
                  class="form-control form-control-solid fs-5 fw-bolder" [(ngModel)]="step.socialSecurityIncomeActionLabel" />
                <span class="text-gray-400 fw-bold fs-6"></span>
              </span>
            </label>
          </div>
          <div class="col-md-3 col-lg-3 col-xxl-3">
            <label (click)="onOtherIncomeClicked()"
              class="btn btn-outline btn-outline-dashed btn-outline-default p-4 d-flex align-items-center option-text"
              style="min-height: 80px;" for="militaryPay">
              <span class="svg-icon svg-icon-2x me-5">
                <duotone-wallet-svg-icon></duotone-wallet-svg-icon>
              </span>
              <span class="d-block fw-bold text-start">
                <span *ngIf="!inEditMode" class="text-dark fw-bolder d-block fs-5">{{step.otherIncomeActionLabel ||'Other'}}</span>
                <input *ngIf="inEditMode" name="otherIncomeActionLabel" type="text"
                  class="form-control form-control-solid fs-5 fw-bolder" [(ngModel)]="step.otherIncomeActionLabel" />
                <span class="text-gray-400 fw-bold fs-6"></span>
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-10 hidden-when-not-mobile" *ngIf="!addingIncome">
      <div class="col-md-12">
        <div class="d-flex justify-content-center ">
          <label (click)="onAddIncomeClicked()"
            class="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center">
            <span class="svg-icon svg-icon-2x me-5 duotone-home-svg-mobile" style="margin-top: -4px;">
              <duotone-home-svg-icon></duotone-home-svg-icon>
            </span>
            <span class="d-block fw-bold text-start">
              <span class="text-dark fw-bolder d-block fs-5 mb-2">Add Income</span>
              <span class="text-gray-400 fw-bold fs-6"></span>
            </span>
          </label>
        </div>
      </div>
    </div>
    <div class="row mt-10 hidden-when-not-mobile d-flex" *ngIf="addingIncome">
      <div class="col-md-12 d-flex">
        <form class="form w-100" #incomeForm="ngForm" novalidate id="incomeForm" name="incomeForm">
          <label class="form-label required">Type of Income</label>
          <div class="d-flex">
            <select class="form-select form-select-solid me-2" data-control="select2" name="typeOfIncome"
              #typeOfIncomeControl="ngModel" [(ngModel)]="selectedTypeOfIncome" (ngModelChange)="onTypeOfIncomeChanged()"
              [ngClass]="{'is-invalid' : typeOfIncomeControl && typeOfIncomeControl.touched && typeOfIncomeControl.invalid}"
              required>
              <option [ngValue]="null" disabled>Select one...</option>
              <option *ngFor="let typeOfIncome of typesOfIncome" [value]="typeOfIncome.value">
                {{typeOfIncome.name}}</option>
            </select>
            <button type="button" class="btn btn-lg btn-light-primary me-3" (click)="addingIncome = false;">
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>

<wizard-step-template [stepMainTemplate]="income" [step]="step" [stepComponent]="this"></wizard-step-template>
