<div class="container px-0 mt-5">
  <div class="row my-2">
    <div class="col-md-6 d-flex align-items-end justify-content-center">
      <div class="form-group w-100">
        <input type="text" class="form-control" placeholder="Search" (input)="onSearch($event)" />
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label class="form-label" for="loanStatus">Loan Status</label>
        <select class="form-select" id="loanStatus" [(ngModel)]="selectedLoanStatus"
          (ngModelChange)="filterByLoanStatus(selectedLoanStatus)">
          <option [ngValue]="null">All</option>
          <option *ngFor="let s of loanStatuses" [ngValue]="s.name">
            {{ s.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label class="form-label" for="sortField">Sort By</label>
        <select class="form-select" id="sortField" [(ngModel)]="selectedSortField"
          (ngModelChange)="sortBySortField(selectedSortField)">
          <option *ngFor="let f of sortFields" [ngValue]="f.value">
            {{ f.name }}
          </option>
        </select>
      </div>
    </div>
  </div>
</div>

<ng-template #loanCardView>
  <div class="row">
    <loan-info *ngFor="let application of filteredApplications"
      [portalContent]="portalContent"
      [showViewTasksButton]="true"
      [isAgent]="isAgent"
      [application]="application">
    </loan-info>
  </div>
</ng-template>

<div *ngIf="selectDisplayMode == 'card'" class="">
  <ng-template [ngTemplateOutlet]="loanCardView"></ng-template>
</div>

<div *ngIf="selectDisplayMode == 'list'" class="mt-4 container">
  <div class="d-none d-md-block d-lg-block d-xl-block">
    <table class="table bp-table loan-table">
      <thead>
        <tr>
          <th></th>
          <th>Name</th>
          <th>Loan Amount</th>
          <th>Interest Rate</th>
          <th>Loan Status</th>
          <th>Address</th>
          <th>Contact</th>
          <th class="text-end">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let app of filteredApplications" class="bp-table-row">
          <td>
            <span class="position-relative me-4"><i class="fa fa-check-circle fa-2x"></i><span
                *ngIf="totals[app.applicationId]?.taskCount > 0"
                class="position-absolute top-1 start-100 translate-middle badge rounded-pill bg-danger">
                {{ badgeLabel(totals[app.applicationId]?.taskCount) }}
              </span></span>

            <span class="position-relative"><i class="fa fa-comment fa-2x"></i><span
                *ngIf="totals[app.applicationId]?.messageCount > 0"
                class="position-absolute top-1 start-100 translate-middle badge rounded-pill bg-danger">
                {{ badgeLabel(totals[app.applicationId]?.messageCount) }}
              </span></span>
          </td>
          <td>
            {{ app.applicationName }}
          </td>
          <td>
            <span *ngIf="!app.loanAmountHiddenOnPortals">{{
              app.loanAmount || 0 | currency
              }}</span><span *ngIf="app.loanAmountHiddenOnPortals">-</span>
          </td>
          <td>
            <span *ngIf="!app.interestRateHiddenOnPortals">
              {{ app.interestRate || 0 }} %</span><span *ngIf="app.interestRateHiddenOnPortals">-</span>
          </td>
          <td>{{ app.loanStatus }}</td>
          <td>
            <span>{{ app.address || "No Street Address" }}</span>
            <br />
            <span *ngIf="app.city || app.state || app.zip">{{ app.city ? app.city + "," : "" }} {{ app.state }}
              {{ app.zip }}</span>
          </td>
          <td>
            <person-circle-image class="app-contact" (click)="openAgentContact(app.applicationId)" [url]="
                'https://api.lodasoft.dev/avatars/657a823f-89f7-4c3e-95e1-35799ca7f70e'
              " [size]="30"></person-circle-image>

            <span class="ms-2">{{ app.contactName }}</span>
          </td>
          <td class="text-end">
            <button type="button" class="btn btn-sm bp-orange-bg-color" *ngIf="app.onlineAppInProgress"
             >
              Complete
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="d-block d-sm-none">
    <ng-template [ngTemplateOutlet]="loanCardView"></ng-template>
  </div>
</div>
