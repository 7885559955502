import { WizardFlowContext } from "../../wizard-flow-context.model";
import { ContextBoundStepProperties } from "../context-bound-step-properties.model";
import { WizardStepBase } from "../wizard-step-base.model";

export class ContextInjector {

  public static availableKeys:string[] = [
    'borrower.firstName',
    'borrower.middleName',
    'borrower.lastName',
    'subjectProperty.address1',
    'subjectProperty.city',
    'subjectProperty.state',
    'subjectProperty.zipCode'
  ]

  public static injectContextToStep = (wizardFlowContext: WizardFlowContext, step: WizardStepBase): ContextBoundStepProperties => {
    let contextBoundStepProperties = new ContextBoundStepProperties();
    if (step) {
      contextBoundStepProperties.title = step.title;
      contextBoundStepProperties.explanation = step.explanation;
      contextBoundStepProperties.heading = step.heading;
    }
    ContextInjector.availableKeys.forEach(
      (key: string) => {
        const keyParts = key.split('.');
        if (keyParts.length === 2) {
          const objectIdentifier = keyParts[0];
          const fieldIdentifier = keyParts[1];
          let valueToReplaceKeyWith: string | undefined = undefined;
          let contextObject: any = undefined;
          if (objectIdentifier === "borrower") {
            contextObject = <any>wizardFlowContext.currentBorrower;
          } else if (objectIdentifier === "subjectProperty") {
            contextObject = <any>wizardFlowContext.mortgage.subjectProperty;
          }
          if (contextObject && fieldIdentifier) {
            valueToReplaceKeyWith = contextObject[fieldIdentifier];
            const keyToReplace = "[" + key + "]";
            if (valueToReplaceKeyWith) {
              if (contextBoundStepProperties.title.includes(keyToReplace)) {
                contextBoundStepProperties.title = contextBoundStepProperties.title.replace(keyToReplace, valueToReplaceKeyWith);
              }
              if (contextBoundStepProperties.explanation.includes(keyToReplace)) {
                contextBoundStepProperties.explanation = contextBoundStepProperties.explanation.replace(keyToReplace, valueToReplaceKeyWith);
              }
              if (contextBoundStepProperties.heading.includes(keyToReplace)) {
                contextBoundStepProperties.heading = contextBoundStepProperties.heading.replace(keyToReplace, valueToReplaceKeyWith);
              }
            }
          }
        }
      });
      return contextBoundStepProperties;
  }
}
