import { ContextType } from "./context-type.enum";
import { NextStepDeciderConfig } from "./next-step-decider-config.model";
import { StepType } from "./step-type.enum";

export abstract class StepConfig {
  title: string = "";
  explanation: string = "";
  heading: string = "";

  id!: string;
  name: string | undefined;
  path!: string;

  groupId: number | undefined;

  stepType!: StepType;

  contextType!: ContextType;

  nextStepDeciderConfig: NextStepDeciderConfig | undefined;

  fieldConfig: any = {};

  constructor(id: string, name: string | undefined, path: string, groupId: number | undefined,
      contextType: ContextType) {
    this.id = id;
    this.name = name;
    this.path = path;
    this.groupId = groupId;
    this.contextType = contextType;
  }
}
