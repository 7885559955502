import { Component, Injector, OnInit } from '@angular/core';
import { SpouseTransactionInvolvement } from '../../../models/enums/spouse-txn-involvement.enum';
import { MortgageApplication } from '../../../models/mortgage-app.model';
import { MortgageApplicationService } from '../../../services/mortgage-application.service';
import { WizardStepComponentBase } from '../wizard-step-base.component';

@Component({
  selector: 'spouse-transaction-involvement',
  templateUrl: 'spouse-transaction-involvement.component.html'
})

export class SpouseTransactionInvolvementComponent extends WizardStepComponentBase<any> implements OnInit {

    private _mortgageApplication: MortgageApplication;

    constructor(private readonly _mortgageApplicationService: MortgageApplicationService,
          private readonly _injector: Injector) {
        super(_injector);
        this._mortgageApplication = this._mortgageApplicationService.mortgageApplication;
    }

    public get isSpouseTransactionInvolvementSelected(): boolean {
        return this._mortgageApplication.spouseTransactionInvolvement !== undefined &&
          this._mortgageApplication.spouseTransactionInvolvement !== SpouseTransactionInvolvement.None;
    }

    public get isSpouseOnLoan(): boolean {
        return this._mortgageApplication.spouseTransactionInvolvement === SpouseTransactionInvolvement.OnLoan;
    }

    public get isSpouseOnTitle(): boolean {
      return this._mortgageApplication.spouseTransactionInvolvement === SpouseTransactionInvolvement.OnTitleOnly;
    }

    public get isSpouseNotOnLoanOrTitle(): boolean {
        return this._mortgageApplication.spouseTransactionInvolvement === SpouseTransactionInvolvement.NeitherOnLoanNorTitle;
    }

    onSpouseWillBeOnLoanSelected = () => {
        this._mortgageApplication.spouseTransactionInvolvement = SpouseTransactionInvolvement.OnLoan;
        this.wizardFlowService.navigateForward();
    }

    onSpouseWillBeOnTitleSelected = () => {
        this._mortgageApplication.spouseTransactionInvolvement = SpouseTransactionInvolvement.OnTitleOnly;
        this.wizardFlowService.navigateForward();
    }

    onSpouseWillNotBeOnLoanOrTitleSelected = () => {
        this._mortgageApplication.spouseTransactionInvolvement = SpouseTransactionInvolvement.NeitherOnLoanNorTitle;
        this.wizardFlowService.navigateForward();
    }

    ngOnInit() { }
}
