import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from 'projects/shared/modal/modal.service';
import { MortgageApplication } from '../../../models/mortgage-app.model';
import { Asset } from '../../../models/asset.model';
import { AssetDialogComponent } from '../asset-dialog/asset-dialog.component';
import { AssetItem } from '../../../models/asset-item-model';
import { AssetItemComponent } from '../asset-item/asset-item.component';
import { Borrower } from '../../../models/borrower-model';
import { EnumsService } from '../../../services/enums.service';
import { WizardFlowServiceBase } from '../../../services/wizard/wizard-flow-service.base';
import * as _ from 'lodash';
import { AssetType } from '../../../models/enums/asset-type-enum';

declare const Swal: any;

@Component({
  selector: 'asset-list',
  templateUrl: 'asset-list.component.html'
})

export class AssetListComponent implements OnInit, AfterViewInit {

  @ViewChildren('assetItem') assetItemComponents: QueryList<AssetItemComponent> | undefined;

  private modalOptions: NgbModalOptions = {
    size: "lg",
    backdrop: "static",
    container: "body"
  };

  private _mortgageApplication: MortgageApplication;

  private _subscriptionToAssetSavedForCreate: any;
  private _subscriptionToAssetSavedForEdit: any;

  private _assetItemEventSubscriptions: any[] = [];
  private _assets: Asset[] = [];

  @Input()
  public set assets(assets: Asset[]) {
    this._assets = assets;
    this.populateAssetItems(this._assets);
  }

  @Input()
  fieldConfig: any;

  @Output()
  assetEdited: EventEmitter<Asset> = new EventEmitter<Asset>();

  @Output()
  assetDeleted: EventEmitter<number> = new EventEmitter<number>();

  assetItems: AssetItem[] = [];

  constructor(private readonly _modalService: ModalService,
    private readonly _wizardFlowService: WizardFlowServiceBase,
    private readonly _enumsService: EnumsService) {
    this._mortgageApplication = this._wizardFlowService.context.mortgage;
  }

  ngOnInit(): void {
  }

  public get totalAssets(): number {
    let sum: number = 0;
    this.assetItems.forEach(asset => {
      sum = sum + Number(asset.value);
    });
    return sum;
  }

  public isValid(): boolean {
    let result: boolean = true;
    this.assetItems.forEach((assetItem) => {
      if (!assetItem.isValid) {
        result = false;
      }
    });
    return result;
  }

  private populateAssetItems(assets: Asset[]) {
    this.assetItems = [];
    assets?.forEach(asset => {
      const assetItem = this.toAssetItem(asset);
      if (assetItem) {
        this.assetItems.push(assetItem);
      }
    }
    );
  }

  private isAssetValid(asset: Asset) {
    if (!this._enumsService.assetTypes.find(a => a.value == asset.assetType)) {
      return false;
    }
    if (!asset.owningBorrowerIds || asset.owningBorrowerIds.length === 0) {
      return false;
    }
    if (asset.cashMarketValue == null || Number(asset.cashMarketValue) <= 0) {
      return false;
    }
    if (asset.assetType === AssetType.Automobile) {
      if (!asset.makeModel || asset.makeModel.length === 0 || asset.year == null) {
        return false;
      }
    }
    if (asset.assetType === AssetType.CheckingAccount) {
      if (this.fieldConfig && !this.fieldConfig['asset.depository'].isHidden && this.fieldConfig['asset.depository'].isRequired) {
        if (!asset.depository || asset.depository.length === 0) {
          return false;
        }
      }
      if (this.fieldConfig && !this.fieldConfig['asset.accountNumber'].isHidden && this.fieldConfig['asset.accountNumber'].isRequired) {
        if (!asset.accountNumber || asset.accountNumber.length === 0) {
          return false;
        }
      }
    }
    if (asset.assetType === AssetType.PendingNetSaleProceedsFromRealEstateAssets) {
      if (!asset.holderAddress1 || asset.holderAddress1.length === 0 ||
        !asset.holderCity || asset.holderCity.length === 0 ||
        !asset.holderState || asset.holderState.length === 0 ||
        !asset.holderZipCode || asset.holderZipCode.length === 0) {
        return false;
      }
    }
    return true;
  }

  private toAssetItem = (asset: Asset): AssetItem | undefined => {
    let owningBorrowerIds: number[] = asset.owningBorrowerIds;
    if (this._wizardFlowService.inCoApplyFlow) {
      // In co-apply flow, you're not supposed to see assets that you do not have ownership on
      if (!asset.owningBorrowerIds.includes(this._wizardFlowService.context.currentBorrower.borrowerId!)) {
        return undefined;
      }
    }
    let assetOwners: string = "";
    if (!owningBorrowerIds) {
      return new AssetItem(asset.assetId!, asset.assetType!, asset.cashMarketValue!, assetOwners, this.isAssetValid(asset));
    }

    for (let i = 0; i < owningBorrowerIds.length; i++) {
      let borrower: Borrower | undefined = this._mortgageApplication.borrowers.find(b => b.borrowerId === owningBorrowerIds[i]);
      if (borrower) {
        assetOwners = assetOwners + " " + borrower.firstName + " " + borrower.lastName;
      }
      if (i != owningBorrowerIds.length - 1) {
        assetOwners = assetOwners + ",";
      }
    }
    let assetItem = new AssetItem(asset.assetId!, asset.assetType!, asset.cashMarketValue!, assetOwners, this.isAssetValid(asset));
    if (this._wizardFlowService.inCoApplyFlow) {
      // If we're in co-apply flow and there is more than one owner on the asset (other than the current borrower), the asset will be readonly
      assetItem.isReadOnly = asset.owningBorrowerIds.length > 1;
    }
    return assetItem;
  }

  private showAssetPopup(asset?: Asset) {
    const modal = this._modalService.show(AssetDialogComponent, this.modalOptions);
    modal.componentInstance.fieldsToConfig = this.fieldConfig;
    if (asset) {
      modal.componentInstance.asset = asset;
    }
    this._subscriptionToAssetSavedForCreate = modal.componentInstance.saveClickedEmitterForCreate
      .subscribe((e: Asset) => this.onSaveClickedOnAssetModalForCreate(e));
    this._subscriptionToAssetSavedForEdit = modal.componentInstance.saveClickedEmitterForEdit
      .subscribe((e: Asset) => this.onSaveClickedOnAssetModalForEdit(e));
  }

  private onSaveClickedOnAssetModalForCreate = (asset: Asset): void => {
    this._assets?.push(asset);

    const assetItem = this.toAssetItem(asset);
    if (assetItem) {
      this.assetItems.push(assetItem);
    }
  }

  private onSaveClickedOnAssetModalForEdit = (asset: Asset): void => {
    const assets = this._assets;
    if (assets) {
      const existingAsset = assets?.find(e => e.assetId === asset.assetId);
      if (existingAsset) {
        const index = assets?.indexOf(existingAsset);
        assets[index] = asset;
        this.assetEdited.emit(asset);
      }
    }

    var assetItem = this.assetItems.find(i => i.id === asset.assetId);
    if (assetItem) {
      const indexItem = this.assetItems.indexOf(assetItem);
      const updatedAssetItem = this.toAssetItem(asset);
      if (updatedAssetItem) {
        this.assetItems[indexItem] = updatedAssetItem;
      }
    }
  }

  private onAssetDeleted = (asset: AssetItem) => {
    const index = this.assetItems.indexOf(asset);
    const assets = this._assets;
    if (index >= 0) {
      this.assetItems.splice(index, 1);
    }
    if (!assets) {
      return;
    }
    const assetToDelete = assets.find(i => i.assetId === asset.id);
    if (assetToDelete) {
      const index = assets?.indexOf(assetToDelete);
      assets.splice(index, 1);
      this.assetDeleted.emit(assetToDelete.assetId);
    }
  }

  private onAssetEditClicked = (asset: AssetItem) => {
    const assetItemToEdit = this.assetItems.find(i => i.id === asset.id);
    if (!assetItemToEdit) {
      return;
    }
    const assetToEdit = this._assets?.find(e => e.assetId === assetItemToEdit.id);
    if (assetToEdit) {
      this.showAssetPopup(_.cloneDeep(assetToEdit));
    }
  }

  private subscribeToAssetItemEvents = () => {
    if (!this.assetItemComponents) {
      return;
    }
    const assetItems: AssetItemComponent[] = this.assetItemComponents.toArray();
    assetItems.forEach(assetItem => {
      const deleteEventSubscription = assetItem.getDeleteClickedEmitter().subscribe(e => this.onAssetDeleted(e));
      const editEventSubscription = assetItem.getEditClickedEmitter().subscribe(e => this.onAssetEditClicked(e));
      this._assetItemEventSubscriptions.push(deleteEventSubscription);
      this._assetItemEventSubscriptions.push(editEventSubscription);
    });
  }

  ngAfterViewInit() {
    if (this.assetItemComponents) {
      this.assetItemComponents.changes.subscribe((r) => {
        if (r._results && r._results.length > 0) {
          this._assetItemEventSubscriptions.forEach(subscription => {
            subscription.unsubscribe();
          });
          this._assetItemEventSubscriptions = [];
          this.subscribeToAssetItemEvents();
        }
      });

      this.subscribeToAssetItemEvents();
    }
  }
}
