<div class="w-100 text-center">
  <i class="bp-orange-color fa fa-file-alt fa-3x d-block"></i>
  <h5 class="my-2 d-flex justify-content-center">
    <span style="max-width: 500px" class="text-gray-700 fs-4 fw-bold">
      Answer the following questions to create a custom preapproval
      letter.
    </span>
  </h5>

  <div class="d-flex justify-content-center ">
    <div style="max-width: 500px">
      <div class="d-flex flex-column">
        <a href="javascript:void(0)" class="btn bp-primary-bg-color my-2" style="min-width: 200px"
          (click)="nextClicked()"> Get Started
        </a>
        <a href="javascript:void(0)" class="btn btn-light" style="min-width: 200px" (click)="closeClicked()">Not
          right now
        </a>
      </div>
    </div>
  </div>
</div>
