<div style="min-height: 500px;">
  <div class="w-100">
    <div class="pb-10 pb-lg-15">
      <h2 class="fw-bolder d-flex align-items-center text-dark">What is your marital status?
      </h2>
    </div>
    <div class="fv-row">
      <div class="row mb-10">
        <div class="col-lg-4 pb-4">
          <input type="radio" [checked]="isMarried" class="btn-check" (click)="onMarriedSelected()" name="married" value="married" id="married" />
          <label class="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center" for="married">
            <span class="d-block fw-bold text-start">
              <span class="text-dark fw-bolder d-block fs-4 mb-2">Married</span>
            </span>
          </label>
        </div>
        <div class="col-lg-4 pb-4">
          <input type="radio" [checked]="isSeparated" class="btn-check" (click)="onSeparatedSelected()" name="separated" value="separated" id="separated" />
          <label class="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center" for="separated">
            <span class="d-block fw-bold text-start">
              <span class="text-dark fw-bolder d-block fs-4 mb-2">Separated</span>
            </span>
          </label>
        </div>
        <div class="col-lg-4 pb-4">
          <input type="radio" [checked]="isUnMarried" class="btn-check" (click)="onUnMarriedSelected()" name="unmarried" value="unmarried" id="unmarried" />
          <label class="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center" for="unmarried">
            <span class="d-block fw-bold text-start">
              <span class="text-dark fw-bolder d-block fs-4 mb-2">Unmarried</span>
            </span>
          </label>
        </div>

      </div>
    </div>
    <wizard-button-group [nextEnabled]="isMaritalStatusSelected"></wizard-button-group>
  </div>
</div>
