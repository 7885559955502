import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { TasksUtils } from 'projects/shared/tasks.utils';
import { map } from 'rxjs/operators';
import { ApplicationDetailModel } from '../../models/borrower-portal/application-detail-model';
import { ApplicationForUser } from "../../models/borrower-portal/application-for-user-model";
import { ApplicationTaskModel, BorrowerTaskType } from "../../models/borrower-portal/application-task-model";
import { ApplicationTasksModel } from "../../models/borrower-portal/application-tasks-model";
import { BorrowerDocumentModel } from "../../models/borrower-portal/borrower-document-model";
import { FeatureFlags } from '../../models/feature-flags.model';
import { PricingScenario } from '../../models/pricing/pricing-scenario.model';
import { BasePortalService } from "../../services/base-portal.service";
import { Constants } from '../../services/constants';
import { MortgageApplicationService } from '../../services/mortgage-application.service';
import { NavigationService } from "../../services/navigation.service";
import { NotificationService } from '../../services/notification.service';
import { AddFilesToTaskDialogComponent } from './add-files-to-task-dialog/add-files-to-task-dialog.component';
import { AssetsVerificationDialogComponent } from './assets-verification-dialog/assets-verification-dialog.component';
import { AttachedDocumentsDialogComponent } from './attached-documents-dialog/attached-documents-dialog.component';
import { EConsentDialogComponent } from './e-consent-dialog/e-consent-dialog.component';
import { PaymentInfoDialogComponent } from './payment-info-dialog/payment-info-dialog.component';
import { IncomeEmploymentVerificationDialogComponent } from './income-employment-verification-dialog/income-employment-verification-dialog.component';
import { TaskBorrowerPickerDialogComponent } from './task-borrower-picker-dialog/task-borrower-picker-dialog.component';
declare const Swal: any;

@Component({
  selector: 'portal-task-information',
  templateUrl: './task-information.component.html',
  styleUrls: ['task-information.component.scss']
})
export class PortalTaskInformationComponent implements OnInit {

  @Input()
  appId: number;

  @Input()
  isAgent: boolean = false;

  @Output()
  tasksLoaded: EventEmitter<ApplicationTaskModel[]> = new EventEmitter<ApplicationTaskModel[]>();

  application: ApplicationForUser = new ApplicationForUser();
  applicationTasks: ApplicationTasksModel = new ApplicationTasksModel;
  filteredApplicationTasks: ApplicationTasksModel = new ApplicationTasksModel;
  selectedTab: number = 1;

  selectedApplicationTasks: ApplicationTaskModel[] = [];
  groupedTasks: ApplicationTaskModel[][] = [];

  documents: BorrowerDocumentModel[] = [];
  scenarios: PricingScenario[] = [];
  selectedBorrowerId = 'all';
  selectDisplayMode: "list" | "card" = "card";

  selectedTask: ApplicationTaskModel = new ApplicationTaskModel();
  eConsentText: string;
  voaEnabled: boolean = false;
  voeEnabled: boolean = false;

  isLoadingTasks: boolean = false;
  isLoadingDocs: boolean = false;
  isLoadingScenarios: boolean = false;

  losEsignTaskIdToSignFirst: number = null;

  constructor(private readonly _portalService: BasePortalService,
    private readonly _onlineAppService: MortgageApplicationService,
    private readonly _modalService: NgbModal,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notifsService: NotificationService,
    private readonly _navigationService: NavigationService,
    private readonly _router: Router,
    private readonly _route: ActivatedRoute) {
  }

  ngOnInit() {
    this.initialize();
  }

  public reloadTasks = (isAfterDocumentUpload: boolean) => {
    this.initialize(isAfterDocumentUpload);
  }

  public openDigitalVerificationDialog = (taskType: BorrowerTaskType) => {
    const allTasks = this.applicationTasks.requested.concat(this.applicationTasks.submitted).concat(this.applicationTasks.approved);

    let verificationTasks = allTasks.filter(t => t.borrowerTaskType === taskType);
    if (verificationTasks.length === 0) {
      return;
    }
    verificationTasks.sort((a, b) => {
      return <any>new Date(b.submittedDate) - <any>new Date(a.submittedDate);
    });
    // Now, group the tasks above by borrowerId
    let groupedTasks = _.groupBy(verificationTasks, 'borrowerId');
    if (Object.keys(groupedTasks).length > 1) {
      let borrowersToPickFrom = [];
      for (let key in groupedTasks) {
        let borrowerId = Number(key);
        let borrowerName = groupedTasks[key][0].borrowerName;
        borrowersToPickFrom.push({ id: borrowerId, name: borrowerName });

        const modalRef = this._modalService.open(TaskBorrowerPickerDialogComponent, Constants.modalOptions.large);
        modalRef.componentInstance.borrowers = borrowersToPickFrom;

        modalRef.result.then((borrowerId) => {
          const tasksForBorrower = groupedTasks[borrowerId];
          if (tasksForBorrower && tasksForBorrower.length > 0) {
            if (taskType === BorrowerTaskType.DigitalIncomeEmploymentVerification) {
              this.startAssetsVerification(tasksForBorrower[0]);
            } else if (taskType === BorrowerTaskType.DigitalAssetVerification) {
              this.startIncomeEmploymentVerification(tasksForBorrower[0]);
            }
          }
        }, err => {});
      }
    } else {
      // Start the asset verification for the first task
      if (taskType === BorrowerTaskType.DigitalIncomeEmploymentVerification) {
        this.startIncomeEmploymentVerification(verificationTasks[0]);
      } else if (taskType === BorrowerTaskType.DigitalAssetVerification) {
        this.startAssetsVerification(verificationTasks[0]);
      }
    }
  }

  protected onSelectedBorrowerChanged() {
    if (this.selectedBorrowerId === 'all') {
      Object.assign(this.filteredApplicationTasks, this.applicationTasks);
    } else {
      this.filteredApplicationTasks.approved = this.applicationTasks.approved.filter(i => i.borrowerId === Number(this.selectedBorrowerId));
      this.filteredApplicationTasks.requested = this.applicationTasks.requested.filter(i => i.borrowerId === Number(this.selectedBorrowerId));
      this.filteredApplicationTasks.submitted = this.applicationTasks.submitted.filter(i => i.borrowerId === Number(this.selectedBorrowerId));
    }

    this.setSelectedApplicationTasks(this.selectedTab, false);
  }

  protected setSelectedApplicationTasks(tabIndex: number, isAfterDocumentUpload: boolean = false) {
    this.selectedTab = tabIndex;

    if (tabIndex < 3) {
      this.loadTasks(isAfterDocumentUpload);
    } else if (tabIndex === 3) { // documents
      this.selectedApplicationTasks = [];
      this.groupedTasks = [];
      this.loadDocuments();
    } else if (tabIndex === 4) { // scenarios
      this.selectedApplicationTasks = [];
      this.groupedTasks = [];
      this.loadScenarios();
    }
  }

  getSelectedTaskName() {
    if (this.selectedTab === 1) {
      return 'Outstanding';
    }
    else if (this.selectedTab === 2) {
      return 'Other';
    }
    return '';
  }

  openAttachedDocuments = (task: ApplicationTaskModel) => {
    const modalRef = this._modalService.open(AttachedDocumentsDialogComponent, Constants.modalOptions.xlarge);
    modalRef.componentInstance.task = task;

    modalRef.result.then(() => {
    });
  }

  openAddFiles(task: ApplicationTaskModel) {
    const modalRef = this._modalService.open(AddFilesToTaskDialogComponent, Constants.modalOptions.large);
    modalRef.componentInstance.task = task;

    modalRef.result.then(() => {
      this.initialize(true);
    });
  }

  openPaymentInfo = (task: ApplicationTaskModel) => {
    const modalRef = this._modalService.open(PaymentInfoDialogComponent, Constants.modalOptions.xlarge);
    modalRef.componentInstance.task = task;

    modalRef.result.then(() => {
      this.initialize();
    });
  }

  onTaskMarkedAsNotApplicable = (task: ApplicationTaskModel) => {
    this.initialize();
  }

  onBorrowerAnsweredAQuestion = (task: ApplicationTaskModel) => {
    this.initialize();
  }

  protected startIncomeEmploymentVerification = (task: ApplicationTaskModel) => {
    const modalRef = this._modalService.open(IncomeEmploymentVerificationDialogComponent, Constants.modalOptions.xlarge);
    modalRef.componentInstance.task = task;
    modalRef.componentInstance.applicationId = this.appId;
    modalRef.componentInstance.voeEnabled = this.voeEnabled;

    modalRef.result.then(() => {
      this.initialize();
    }, err => {});
  }

  protected startAssetsVerification = (task: ApplicationTaskModel) => {
    const modalRef = this._modalService.open(AssetsVerificationDialogComponent, Constants.modalOptions.xlarge);
    modalRef.componentInstance.task = task;
    modalRef.componentInstance.applicationId = this.appId;
    modalRef.componentInstance.voaEnabled = this.voaEnabled;

    modalRef.result.then(() => {
      this.initialize();
    }, err => {});
  }

  openEsignDocument = (task: ApplicationTaskModel) => {
    const { applicationId, myDetails, otherBorrowersOnLoan } = this.application;

    // call api to see if user assigned to task has e-consent completed if not, show econsent dialog, once completed, proceed to esign
    // if they do not econsent, toast a message that says, you must econsent to proceed with esigning your documents
    // if user already has econsent, just let them sign.

    if (task.borrowerId > 0) {
      if (task.borrowerId === myDetails?.borrowerId && myDetails?.acceptedEConsentDate) {
        return this.openSignerView(task);
      } else if (otherBorrowersOnLoan.length > 0) {
        otherBorrowersOnLoan.forEach(ob => {
          if (task.borrowerId === ob.borrowerId && ob.acceptedEConsentDate)
            return this.openSignerView(task);
        });
      }

      const modalRef = this._modalService.open(EConsentDialogComponent, Constants.modalOptions.large);
      modalRef.componentInstance.loanId = this.appId;
      modalRef.componentInstance.eConsentText = this.eConsentText;

      modalRef.result.then((res: boolean) => {
        if (!res) {
          return alert('E-Consent authorization is required for e-signing your documents.');
        }

        this._spinner.show();

        this._portalService.acceptESignConsent(applicationId, task.borrowerId)
          .subscribe({
            next: (acceptConsentRes) => {

              this._onlineAppService.generateEConsentDocument(applicationId, { eConsentText: this.eConsentText, borrowerId: task.borrowerId })
                .subscribe({
                  next: (generateDocRes) => {
                    this.openSignerView(task)
                  },
                  error: (error) => {
                    console.error(error.error.message ? error.error.message : 'Document generation failed');
                    this._spinner.hide();
                  }
                })
            },
            error: (error) => {
              console.error(error.error.message ? error.error.message : 'Accept E-Sign Consent failed');
              this._spinner.hide();
            }
          });
      });

    } else {
      this.openSignerView(task);
    }
  }

  private initialize = (isAfterDocumentUpload: boolean = false) => {
    let appID = this._route.snapshot.queryParamMap.get('applicationId');
    if (appID && !isNaN(Number(appID))) {
      this.appId = Number(appID);
    }
    this.loadLoanData();
    this.getLoanConfigs();
    this.loadDocuments(false);

    if (!this.isAgent){
      this.loadScenarios(false);
    }

    this.setSelectedApplicationTasks(this.selectedTab, isAfterDocumentUpload);
  }

  private loadLoanData = () => {
    this._spinner.show();
    this._portalService.getLoanDataForAppId(this.appId).subscribe({
      next: (application) => {
        if (!application.myDetails) {
          application.myDetails = new ApplicationDetailModel();
        }
        this.application = application;
      },
      error: (err) => {
        this._spinner.hide();
        this._notifsService.showError(err && err.error ? err.error.message || err.message : "An error occurred while getting loan details.", 'Error!');
      }
    });
  }

  private loadDocuments = (isLoadingShowing: boolean = true) => {
    if (isLoadingShowing) {
      this.isLoadingDocs = true;
    }

    this._portalService.getDocumentsForAppId(this.appId).subscribe(documents => {
      this.documents = documents;
      this.documents.sort((a, b) => {
        return <any>new Date(b.dateInserted) - <any>new Date(a.dateInserted);
      });

      if (isLoadingShowing) {
        this.isLoadingDocs = false;
      }
    }, err => {
      if (isLoadingShowing) {
        this.isLoadingDocs = false;
      }
      this._notifsService.showError(err && err.error ? err.error.message || err.message : "An error occurred while getting loan documents.", 'Error!');
    });
  }

  private loadTasks = (isAfterDocumentUpload: boolean = false) => {
    this.isLoadingTasks = true;
    this._portalService.getTasksForAppId(this.appId)
      .pipe(
        map(res => {
          // TODO Remove After Backend Update For allowUpload released
          return {
            requested: !!res.requested
              ? res.requested.map(t => ({ ...t, allowUpload: this.allowUpload(t) }))
              : [],
            submitted: !!res.submitted
              ? res.submitted.map(t => ({ ...t, allowUpload: this.allowUpload(t) }))
              : [],
            approved: !!res.approved
              ? res.approved.map(t => ({ allowUpload: false, ...t }))
              : [],
          };
        })
      )
      .subscribe(applicationTasks => {
        this.isLoadingTasks = false;
        this.applicationTasks = applicationTasks;
        this.applicationTasks.approved.sort((a, b) => {
          return <any>new Date(a.dueDate) - <any>new Date(b.dueDate);
        });
        this.applicationTasks.requested.sort((a, b) => {
          return <any>new Date(a.dueDate) - <any>new Date(b.dueDate);
        });
        this.applicationTasks.submitted.sort((a, b) => {
          return <any>new Date(a.dueDate) - <any>new Date(b.dueDate);
        });

        const allTasks = this.applicationTasks.requested.concat(this.applicationTasks.submitted).concat(this.applicationTasks.approved);
        if (allTasks.length > 0) {
          this.tasksLoaded.emit(allTasks);
        }

        Object.assign(this.filteredApplicationTasks, this.applicationTasks);

        if (this.selectedTab === 1) { //  outstanding
          this.selectedApplicationTasks = this.filteredApplicationTasks.requested;
        }
        else if (this.selectedTab === 2) { //  others
          this.selectedApplicationTasks = [...this.filteredApplicationTasks.submitted, ...this.filteredApplicationTasks.approved];
        }
        this.groupedTasks = this.groupTasks(this.selectedApplicationTasks);

        let taskArr = [];
        this.groupedTasks.forEach(tasks => {
          taskArr = taskArr.concat(...tasks);
        });

        let losEsignTaskToSignFirst = _.sortBy(taskArr, ["taskId"]).find(t => TasksUtils.shouldShowEsignDocument(t, !this.isAgent) && t.borrowerTaskType === BorrowerTaskType.LosEsign);

        if (losEsignTaskToSignFirst) {
          this.losEsignTaskIdToSignFirst = losEsignTaskToSignFirst.taskId;
        }

        if (isAfterDocumentUpload && this.selectedTab !== 2) {
          Swal.fire({
            title: "You've successfully uploaded your document(s). Uploaded files are viewable under the Submitted tab. Would you like to go there now?",
            icon: 'success',
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonText: 'Yes'
          }).then((result) => {
            if (result.value) {
              this.setSelectedApplicationTasks(2);
            }
          });
        }
      }, err => {
        this.isLoadingTasks = false;
        this._notifsService.showError(err && err.error ? err.error.message || err.message : "An error occurred while getting loan tasks.", 'Error!');
      });
  }

  private loadScenarios = (isLoadingShowing: boolean = true) => {
    if (isLoadingShowing) {
      this.isLoadingScenarios = true;
    }

    this._portalService.getScenariosForApp(this.appId).subscribe(scenarios => {
      this.scenarios = scenarios;

      if (isLoadingShowing) {
        this.isLoadingScenarios = false;
      }
    }, err => {
      if (isLoadingShowing) {
        this.isLoadingScenarios = false;
      }
      this._notifsService.showError(err && err.error ? err.error.message || err.message : "An error occurred while getting loan scenarios.", 'Error!');
    });
  }

  private allowUpload(task: ApplicationTaskModel): boolean {
    return task.allowUpload && task.borrowerTaskType !== BorrowerTaskType.EsignDocument && task.borrowerTaskType !== BorrowerTaskType.LosEsign;
  }

  private openSignerView = (task: ApplicationTaskModel) => {
    const companyGuid = this._navigationService.companyGuid;
    this._router.navigateByUrl(`/${this.isAgent ? 'agent' : 'borrower'}-portal/loans/${this.appId}/e-sign/${task.taskId}?companyGuid=${companyGuid}`, {
      replaceUrl: true
    });
    return;
  }

  private getLoanConfigs = () => {
    this._spinner.show();
    this._onlineAppService.getConfig(this.appId)
      .subscribe({
        next: (config: FeatureFlags) => {
          this.eConsentText = config.eConsentText;
          this.voaEnabled = config.voaEnabled;
          this.voeEnabled = config.voeEnabled;
          this._spinner.hide();
        },
        error: (err) => {
          this._spinner.hide();
          console.error(err);
        }
      })
  }

  private groupTasks = (tasks: ApplicationTaskModel[]): ApplicationTaskModel[][] => {
    return _.values(
      _.groupBy(
        _.sortBy(
          tasks,
          [
            v => !v.isAgentTask,
            v =>
              /**
               * Sort task type 6, 7 and 14 higher than other task types
               * 6: Complete Online Application
               * 7: Asset Verification
               * 14: Esign
               * Order would be 14 then 6 and then 7
               */

              (v.borrowerTaskType === BorrowerTaskType.LosEsign ||
                v.borrowerTaskType === BorrowerTaskType.DigitalAssetVerification ||
                v.borrowerTaskType === BorrowerTaskType.OnlineApplication)
                ? v.borrowerTaskType === BorrowerTaskType.LosEsign
                  ? 0
                  : v.borrowerTaskType === BorrowerTaskType.OnlineApplication
                    ? 1
                    : 2
                : 3,
            v => v.docSortOrder,
          ]
        ),
        (v) => v.taskTypeId
      )
    );
  }
}
