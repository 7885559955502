<div *ngIf="currentQuestion">
  <div class="col-md-12 fv-row mt-3" style="min-height: 94px">
    <form id="checklistQuestionsForm" #checklistQuestionsForm="ngForm">

      <div class="card shadow-sm mb-5">
        <div class="card-header">
            <h3 class="card-title">{{isCurrentQuestionBorrowerSpecific ? ('For ' + borrowerName + '...') : 'For your transaction...'}}</h3>
        </div>
        <div class="card-body">
          <label for="multi-choice-question" class="form-label mb-2">{{ currentQuestionMetaData.questionText}}</label>
          <ng-container [ngSwitch]="currentQuestionMetaData.questionType">
            <ng-select *ngSwitchCase="'MultiSelect'" width="100%" name="question" id="question" bindLabel="answerText"
              bindValue="questionAnswerId" placeholder="Select one or more options" [closeOnSelect]="false"
              [items]="currentQuestionMetaData.answers" #currentQuestion="ngModel" required class="custom-ng-select"
              [(ngModel)]="borrowersAnswersToQuestions[currentChecklistId][currentQuestionMetaData.questionId].choicesSelected"
              [multiple]="true"
              [ngClass]="{'is-invalid': currentQuestion && currentQuestion.touched && currentQuestion.invalid}"
              appendTo="body">
            </ng-select>
            <select *ngSwitchCase="'SelectList'" name="question" id="question"
              [ngClass]="{'is-invalid' : currentQuestion && currentQuestion.touched && currentQuestion.invalid}" required
              #currentQuestion="ngModel" class="form-select form-select-solid"
              [(ngModel)]="borrowersAnswersToQuestions[currentChecklistId][currentQuestionMetaData.questionId].choicesSelected[0]"
              data-control="select2" data-placeholder="Choose one">
              <option *ngFor="let option of currentQuestionMetaData.answers" [ngValue]="option.questionAnswerId">
                {{option.answerText}}
              </option>
            </select>
            <span *ngSwitchCase="'TriggerOnly'">Trigger - will be skipped</span>
            <!--default case when there are no matches -->
            <input *ngSwitchDefault class="form-control form-control-solid" autocomplete="off" #currentQuestion="ngModel"
              [(ngModel)]="borrowersAnswersToQuestions[currentChecklistId][currentQuestionMetaData.questionId].singleAnswer"
              placeholder="" id="question" name="question"
              [ngClass]="{'is-invalid' : currentQuestion && currentQuestion.touched && currentQuestion.invalid}" required />
          </ng-container>
          <div class="invalid-feedback">Please, answer the question.</div>
        </div>
      </div>
    </form>
  </div>
</div>
