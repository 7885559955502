import { Component, Injector, ViewChild } from '@angular/core';
import { WizardStepComponentBase } from '../wizard-step-base.component';
import * as _ from 'lodash';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PullExistingApplicationStep } from '../../../models/wizard/pull-existing-application-step.model';
import { NgForm } from '@angular/forms';
import { Observer } from 'rxjs';
import { MortgageApplication } from '../../../models/mortgage-app.model';

@Component({
  selector: 'pull-existing-application-step',
  templateUrl: 'pull-existing-application-step.component.html',
  styleUrls: ['pull-existing-application-step.component.scss']
})
export class PullExistingApplicationStepComponent extends WizardStepComponentBase<PullExistingApplicationStep> {

  @ViewChild('loanInfoForm')
  loanInfoForm: NgForm;

  protected zipCodePlaceHolder = 'XXXXX';
  protected zipCodeMask = '00000';
  protected zipCodePattern = '^[0-9]{5}?$';

  protected ssnPlaceHolder = 'XXXX';
  protected ssnMask = '0000';
  protected ssnPattern = '^[0-9]{4}?$';

  protected subjectPropertyZipCode: string = '';
  protected borrowerSSNLastFourDigits: string = '';

  protected pullInfoAttemptCount = 3;
  protected pullAttemptFailed = false;

  constructor(
    private readonly _injector: Injector,
    private readonly _modalService: NgbModal
  ) {
    super(_injector);
    this.nextButtonText = 'Skip & Continue';
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (!this.wizardFlowService.context.lead && !this.isEditEnabled) {
      this.wizardFlowService.navigateForward();
      return;
    }
  }

  ngOnDestroy(): void { }

  onNextClicked(): void {
    this.wizardFlowService.navigateForward();
  }

  onSubmitClicked = () => {
    if (this.pullInfoAttemptCount === 0) {
      return;
    }
    this.loanInfoForm.form.markAllAsTouched();
    if (!this.loanInfoForm.form.valid) {
      return;
    }
    this.startSpinner();
    const observer: Observer<MortgageApplication> = {
      next: (mortgage: MortgageApplication) => {
        this.wizardFlowService.context.hasSuccessfullyPulledExistingApplication = true;
        this.wizardFlowService.updateContext(mortgage);
        this.wizardFlowService.navigateForward();
      },
      error: (err: any) => {
        this.pullAttemptFailed = true;
        this.pullInfoAttemptCount--;
        if (this.pullInfoAttemptCount === 0) {
          this.wizardFlowService.context.hasSuccessfullyPulledExistingApplication = false;
        }
      },
      complete: () => {
      }
    }
    this.mortgageApplicationService.pullMortgageFromLead(this.wizardFlowService.context.companyGuid,
      this.wizardFlowService.leadGuid, this.borrowerSSNLastFourDigits, this.subjectPropertyZipCode).subscribe(observer)
      .add(() => {
        this.stopSpinner();
      });
  }
}
