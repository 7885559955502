import { IDecideNextStep } from "../../services/wizard/next-step-decider/next-step-decider.interface";
import { ReviewCreditStepComponent } from "../../wizard/wizard-steps/review-credit-step/review-credit-step.component";
import { WizardStepBase } from "./wizard-step-base.model";

export class ReviewCreditStep extends WizardStepBase {
  constructor(id: string, name: string, path: string, nextStepDecider?: IDecideNextStep) {
    super(id, name, path, nextStepDecider);
    this.component = ReviewCreditStepComponent;
  }
}
