import { Injectable } from "@angular/core";
import { WizardFlowContext } from "../../../wizard-flow-context.model";
import { GenericStepFactory } from "./generic-step.factory";
import { NextStepDeciderFactory } from "./next-step-decider.factory";
import { ICreateStep } from "./step-factory.interface";
import { PricingStep } from "../../pricing-step.model";
import { PricingStepConfig } from "../pricing-step.config.model";

@Injectable()
export class PricingStepFactory extends GenericStepFactory<PricingStep> implements ICreateStep {

  constructor(nextStepDeciderFactory: NextStepDeciderFactory) {
    super(nextStepDeciderFactory, PricingStep);
  }

  create(config: PricingStepConfig, wizardFlowContext: WizardFlowContext): PricingStep {
    let pricingStep = super.create(config, wizardFlowContext);
    pricingStep.noProductFoundMessageTitle = config.noProductFoundMessageTitle || "No Available Products";
    pricingStep.noProductsFoundMessageText = config.noProductsFoundMessageText || "We were not able to find any available products based on your loan criteria.";
    return pricingStep;
  }

  configure(step: PricingStep, config: PricingStepConfig): void {
    super.configure(step, config);
    config.noProductFoundMessageTitle = step.noProductFoundMessageTitle;
    config.noProductsFoundMessageText = step.noProductsFoundMessageText;
  }
}
