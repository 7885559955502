import { Component, Injector, OnInit } from '@angular/core';
import { Originator } from '../../../models/originator.model';
import { SelectOriginatorStep } from '../../../models/wizard/select-originator-step.model';
import { UtilsService } from '../../../services/utils.service';
import { WizardStepComponentBase } from '../wizard-step-base.component';

@Component({
  selector: 'select-originator-step',
  templateUrl: 'select-originator-step.component.html',
  styleUrls: ['select-originator-step.component.scss']
})

export class SelectOriginatorStepComponent extends WizardStepComponentBase<SelectOriginatorStep> implements OnInit {

  originators: Originator[] = [];

  filteredOriginators: Originator[] = [];

  searchText: string = "";

  loading: boolean = false;

  private _companyGuid: string = "";
  private _branchGuid: string = "";

  public get userGuid() {
    return this.mortgageApplication.userGuid;
  }

  constructor(private readonly _injector: Injector, private readonly _utilsService: UtilsService) {
    super(_injector);
    this._companyGuid = this._utilsService.getUrlParameter("companyGuid");
    this._branchGuid = this._utilsService.getUrlParameter("branchGuid");
  }

  onCardClicked = (userGuid: string) => {
    this.mortgageApplication.userGuid = userGuid;
    this.onNextClicked();
  }

  public get canMoveToNextStep(): boolean {
    return this.mortgageApplication.userGuid !== "unassigned";
  }

  searchTextChange = () => {
    setTimeout(() => {
      this.filteredOriginators = this.originators.filter(t => {
        const fullName = t.firstName.toLowerCase() + " " + t.lastName.toLowerCase();
        return fullName.includes(this.searchText.toLowerCase());
      });
    });
  }

  ngOnInit() {
    if (!this.wizardFlowService.isEditEnabled && !this.wizardFlowService.inPreviewMode) {
      this.loading = true;
      this.mortgageApplicationService.getOriginators(this._companyGuid, this._branchGuid).subscribe(originators => {
        this.originators = originators.filter(user => !(user.firstName === "Admin" && user.lastName === "Account"));
        this.filteredOriginators = [...this.originators];
        this.loading = false;
      }, error => {

      }).add(() => { this.loading = false; });
    } else {
      const originator1 = new Originator();
      originator1.firstName = "John";
      originator1.lastName = "Doe";
      const originator2 = new Originator();
      originator2.firstName = "Jane";
      originator2.lastName = "Doe";
      this.originators.push(originator1);
      this.originators.push(originator2);
    }
    this.filteredOriginators = [...this.originators];
  }
}
