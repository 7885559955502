export class BorrowerCreditScores {
  borrowerId: number | undefined;
  borrowerName: string | undefined;
  creditID: number | undefined;
  date: Date | undefined;
  equifax: number | undefined;
  experian: number | undefined;
  transUnion: number | undefined;
  refNumber: number | null = null;

}
