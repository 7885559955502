import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from 'projects/shared/modal/modal.service';
import { Borrower } from '../../models/borrower-model';
import { EnumsService } from '../../services/enums.service';
import { UtilsService } from '../../services/utils.service';
import { PersonalInfoDialogComponent } from '../personal-info-dialog/personal-info-dialog.component';

@Component({
  selector: 'personal-info-card',
  templateUrl: 'personal-info-card.component.html'
})

export class PersonalInfoComponent implements OnInit {

  @Input()
  borrower!: Borrower;

  @Output()
  borrowerChanged: EventEmitter<Borrower> = new EventEmitter<Borrower>();

  residenceStatusName!: string;
  maritalStatusName!: string;

  private _currentStreetAddress!: string;
  private _phoneNumber!: string;

  private modalOptions: NgbModalOptions = {
    size: "lg",
    backdrop: "static",
    container: "#reviewApplication"
  };

  constructor(
    private readonly _enumsService: EnumsService,
    private readonly _modalService: ModalService,
    private readonly _utilsService: UtilsService) {
  }

  ngOnInit() {
    this.massageFieldsForDisplay();
  }

  public get currentStreetAddress(): string {
    return this._currentStreetAddress;
  }

  public get phoneNumber(): string {
    return this._phoneNumber;
  }

  onEditClicked = () => {
    this.showPersonalInfoPopup();
  }

  private showPersonalInfoPopup() {
    const modal = this._modalService.show(PersonalInfoDialogComponent, this.modalOptions);
    modal.componentInstance.borrower = this.borrower;
    modal.result.then((borrower: Borrower) => {
      this.borrower = borrower;
      this.borrowerChanged.emit(this.borrower);
      this.massageFieldsForDisplay();
    })
  }

  private massageFieldsForDisplay() {
    this._currentStreetAddress = this._utilsService.getCurrentAddressStreetName(this.borrower);
    this._phoneNumber = this._utilsService.formatPhoneNumber(this.borrower.mobilePhone);
    let residenceStatusValue = this.borrower.declarations!.residenceStatus!;
    this.residenceStatusName = this._enumsService.residencyStatusTypeName(residenceStatusValue);
    this.maritalStatusName = this._enumsService.maritalStatusName(this.borrower.maritalStatus);
  }
}
