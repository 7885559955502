<div class="rounded border p-4 mb-4 align-items-center flex-wrap ribbon ribbon-top ribbon-vertical"
[ngClass]="{'border-danger' : eventToDelete === event}"
>
  <div class="ribbon-label bg-white m-n2">
    <i *ngIf="eventToDelete !== event" class="bi bi-trash fs-2 text-warning"
      (click)="onEventDeleteClicked()"></i>
    <i *ngIf="eventToDelete === event" class="bi bi-x fs-2 text-danger"
      (click)="onEventDeleteCancelClicked()"></i>
    <i *ngIf="eventToDelete === event" class="bi bi-check fs-2 text-success"
      style="padding-right: 4px;" (click)="onEventDeleteConfirmClicked()"></i>
  </div>
  <div class="row mb-6">
    <div [ngClass]="{'col-6 col-md-3': !(event.format == 'Other'), 'col-6 col-md-2': event.format == 'Other'}">
      <label class="form-label">Completion Date</label>
      <div class="position-relative d-flex align-items-center">
        <input class="form-control form-control-solid" matInput mask="M0/d0/0000" [showMaskTyped]="true"
          [specialCharacters]="[ '/' ]" [dropSpecialCharacters]="false" datePicker #completedDateInput="ngModel"
          [(ngModel)]="event.completedDate" placeholder="" [name]="'completedDate' + componentId" inputmode="numeric"
          ngControl="completedDateInput" [id]="'completedDate' + componentId" required
          [ngClass]="{'is-invalid' : completedDateInput && completedDateInput.touched && completedDateInput.invalid}" />
      </div>
    </div>
    <div [ngClass]="{'col-6 col-md-3': !(event.format == 'Other'), 'col-6 col-md-2': event.format == 'Other'}">
      <label class="form-label">Format</label>
      <select class="form-select form-select-solid" data-control="select2" [name]="'format' + componentId"
        #eventFormatInput="ngModel" [(ngModel)]="event.format"
        [ngClass]="{'is-invalid' : eventFormatInput && eventFormatInput.touched && eventFormatInput.invalid}" required>
        <option [ngValue]="null">Select one...</option>
        <option *ngFor="let type of borrowerCounselingFormatTypes" [value]="type.value">
          {{type.name}}
        </option>
      </select>
    </div>
    <div class="col-6 col-md-3" *ngIf="event.format == 'Other'">
      <label class="form-label">Other Description</label>
      <input type="text" class="form-control form-control-solid" [(ngModel)]="event.formatOtherDescription"
        [required]="true" placeholder="" [name]="'formatOtherDescription' + componentId"
        [ngClass]="{'is-invalid' : formatOtherDescriptionInput && formatOtherDescriptionInput.touched && formatOtherDescriptionInput.invalid}"
        #formatOtherDescriptionInput="ngModel" [id]="'formatOtherDescription' + componentId" />
    </div>

    <div [ngClass]="{'col-6 col-sm-3': !(event.format == 'Other'), 'col-6 col-sm-2': event.format == 'Other'}">
      <label class="form-label">Agency ID</label>
      <input class="form-control form-control-solid" [name]="'counselingAgencyIdentifier' + componentId"
        [id]="'counselingAgencyIdentifier' + componentId" [(ngModel)]="event.counselingAgencyIdentifier"
        #counselingAgencyIdentifierInput="ngModel" type="text" numeric [allowNegative]="false"
        [ngClass]="{'is-invalid' : counselingAgencyIdentifierInput && counselingAgencyIdentifierInput.touched && counselingAgencyIdentifierInput.invalid}" />
    </div>

    <div class="col-6 col-md-3">
      <label class="form-label">Agency Name</label>
      <input type="text" class="form-control form-control-solid" [(ngModel)]="event.counselingAgencyName"
        [required]="true" placeholder="" [name]="'counselingAgencyName' + componentId"
        [ngClass]="{'is-invalid' : counselingAgencyNameInput && counselingAgencyNameInput.touched && counselingAgencyNameInput.invalid}"
        #counselingAgencyNameInput="ngModel" [id]="'counselingAgencyName' + componentId" />
    </div>
  </div>
  <div class="row mb-6">
    <div class="col-12 col-md-6">
      <label class="form-label">Agency Address 1</label>
      <address-autocomplete (setGooglePickedAddress)="setGooglePickedAddress($event)"
        (setManuallyEnteredAddress)="setManuallyEnteredAddress($event)" adressType="geocode" [disabled]="false"
        [required]="true" [name]="'address1' + componentId" [id]="'address1' + componentId"
        [isItAForeignCountry]="false" [autocompleteInput]="event!.counselingAgencyAddress1">
      </address-autocomplete>
    </div>
    <div class="col-12 col-md-6">
      <label class="form-label">Agency Address 2</label>
      <input type="text" class="form-control form-control-solid" [(ngModel)]="event.counselingAgencyAddress2"
        [required]="false" placeholder="" [name]="'counselingAgencyAddress2' + componentId"
        [ngClass]="{'is-invalid' : counselingAgencyAddress2Input && counselingAgencyAddress2Input.touched && counselingAgencyAddress2Input.invalid}"
        #counselingAgencyAddress2Input="ngModel" [id]="'counselingAgencyAddress2' + componentId" />
    </div>
  </div>
  <div class="row mb-6">
    <div class="col-12 col-md-4">
      <label class="form-label">Agency City</label>
      <input type="text" class="form-control form-control-solid" [(ngModel)]="event.counselingAgencyCity"
        [required]="false" placeholder="" [name]="'counselingAgencyCity' + componentId"
        [ngClass]="{'is-invalid' : counselingAgencyCityInput && counselingAgencyCityInput.touched && counselingAgencyCityInput.invalid}"
        #counselingAgencyCityInput="ngModel" [id]="'counselingAgencyCity' + componentId" />
    </div>
    <div class="col-12 col-md-4">
      <label class="form-label">Agency State</label>
      <select class="form-select form-select-solid" data-control="select2"
        [name]="'counselingAgencyState' + componentId" #counselingAgencyStateInput="ngModel"
        [(ngModel)]="event.counselingAgencyState"
        [ngClass]="{'is-invalid' : counselingAgencyStateInput && counselingAgencyStateInput.touched && counselingAgencyStateInput.invalid}"
        required>
        <option [ngValue]="null">Select one...</option>
        <option *ngFor="let state of stateOptions" [value]="state.value">
          {{state.name}}
        </option>
      </select>
    </div>
    <div class="col-12 col-md-4">
      <label class="form-label">Agency Zip Code</label>
      <input type="text" class="form-control form-control-solid" [(ngModel)]="event.counselingAgencyZipCode"
        [required]="false" placeholder="" [name]="'counselingAgencyZipCode' + componentId"
        [ngClass]="{'is-invalid' : counselingAgencyZipCodeInput && counselingAgencyZipCodeInput.touched && counselingAgencyZipCodeInput.invalid}"
        #counselingAgencyZipCodeInput="ngModel" [id]="'counselingAgencyZipCode' + componentId" />
    </div>
  </div>
