import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from './data.service';

@Injectable({ providedIn: 'root' })
export class ShortLinkService {

  constructor(private _dataService: DataService) { }

  getShortLinkRedirectUrl = (shortCode: string, queryString: string): Observable<{url: string}> => {
    let url = `api/borrowerportal/redirect/${shortCode}`;
    if (queryString) {
      url += `?queryString=${queryString}`;
    }
    return this._dataService.get(url);
  }
}
