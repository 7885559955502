import { Injectable } from '@angular/core';
import Swal, { SweetAlertIcon, SweetAlertOptions } from 'sweetalert2';

@Injectable()
export class NotificationService {
    constructor() { }

    showSuccess(message: string, title: string): void {
        this.toast('success', message, title);
    }

    showError(message: string, title: string): void {
        this.toast('error', message, title);
    }

    showInfo(message: string, title: string, options?: Partial<SweetAlertOptions>): void {
        this.toast('info', message, title, options);
    }

    showWarning(message: string, title: string): void {
        this.toast('warning', message, title);
    }

    toast(icon: SweetAlertIcon, message: string, title: string, options?: any): void {
        const toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            showCloseButton: true,
            timer: 4000,
            didOpen: () => {
              const swalToasts = document.getElementsByClassName("swal2-toast");
              if (swalToasts && swalToasts.length) {
                const swalToast = swalToasts[0];
                swalToast.addEventListener('mouseenter', () => {
                  Swal.stopTimer();
                });
                swalToast.addEventListener('mouseleave', () => {
                  Swal.resumeTimer();
                })
              }
            },
            willClose: () => {
              const swalToasts = document.getElementsByClassName("swal2-toast");
              if (swalToasts && swalToasts.length) {
                const swalToast = swalToasts[0];
                swalToast.removeAllListeners();
              }
            }
        });

        toast.fire({
            icon: icon,
            title: title,
            // text: message, // includes only text
            html: message, // includes normal text and html
            ...options
        });
    }
}
