<div class="alert  d-flex align-items-center p-5 mb-10"
[ngClass]="{
  'alert-warning': alert.type === 1,
  'alert-danger': alert.type === 2,
  'alert-success': alert.type === 3
}"
*ngIf="alert">
  <span class="svg-icon svg-icon-2hx  me-4"
  [ngClass]="{
    'svg-icon-warning': alert.type === 1,
    'svg-icon-danger': alert.type === 2,
    'svg-icon-success': alert.type === 3
  }">
    <duotone-warning-circle-svg-icon *ngIf= "alert.type === 1"></duotone-warning-circle-svg-icon>
    <duotone-warning-circle-svg-icon *ngIf= "alert.type === 2"></duotone-warning-circle-svg-icon>
    <duotone-warning-circle-svg-icon *ngIf= "alert.type === 3"></duotone-warning-circle-svg-icon>
  </span>
  <div class="d-flex flex-column">
    <h5 class="mb-1">{{alert.title}}</h5>
    <span>{{alert.message}}</span>
  </div>
</div>
