import { Pipe } from "@angular/core";

@Pipe({
  name: "phone"
})
export class PhonePipe {

  transform(number: string) {
    return this.formatPhoneNumber(number);
  }

  private formatPhoneNumber = (number: string): string => {
    let cleaned = ('' + number).replace(/\D/g, ''),
      match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{3}|\d{4})$/);
    if (match) {
      let intlCode = (match[1] ? '+1 ' : '')
      return ['(', match[2], ') ', match[3], '-', match[4]].join('')
    }
    return number;
  }
}
