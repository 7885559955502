import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../../services/auth.service';
import { NavigationService } from '../../services/navigation.service';
import { ActivatedRoute } from '@angular/router';
import { EnvironmentService } from 'projects/shared/services/environment.service';
import { AuthenticationUserCompany } from '../../models/auth/auth-response.model';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'forgot-password',
  templateUrl: 'forgot-password.component.html'
})
export class ForgotPasswordComponent implements OnInit {

  @ViewChild('forgotPasswordForm') forgotPasswordForm: NgForm | undefined;

  possibleLogoUrls: string[] = [];
  availableCompanies: AuthenticationUserCompany[] = [];

  errorMessage: string | null = null;

  email: string = "";
  userCompanyGuid: string = "";

  sendResetLinkButtonText: string = "Send Reset Link";

  spinnerVisible: boolean = false;

  userScope: string = null;

  constructor(
    private readonly _navigationService: NavigationService,
    private readonly _authService: AuthService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _environment: EnvironmentService,
    private readonly _activatedRoute: ActivatedRoute
  ) {
    this.possibleLogoUrls = this._navigationService.possibleLogoUrls;
  }

  ngOnInit() {
    let userScopeFromPath = null;
    if (this._activatedRoute.routeConfig && this._activatedRoute.routeConfig.data && this._activatedRoute.routeConfig.data.scope) {
      userScopeFromPath = this._activatedRoute.routeConfig.data.scope;
    }
    this.userScope = this._environment.scope || userScopeFromPath;
  }

  onEmailChanged = () => {
    this.availableCompanies = null;
    this.userCompanyGuid = "";
  }

  onSendResetLinkClicked = async () => {
    this.forgotPasswordForm?.form.markAllAsTouched();
    if (!this.forgotPasswordForm?.form.valid) {
      return;
    }
    if (!this.userCompanyGuid) {
      this.toggleProgress(true);
      try {
        const availableCompanies = await firstValueFrom(this._authService.getAvailableCompanies(this.email, this.userScope || 'AgentAndBorrower'));
        this.availableCompanies = availableCompanies || [];
        if (this.availableCompanies.length === 1) {
          this.userCompanyGuid = this.availableCompanies[0].userCompanyGuid;
        }
      } catch (error) {
        this.errorMessage = 'Unable to get companies for a provided email';
        return
      } finally {
        this.toggleProgress(false);
      }
    }
    if (!this.userCompanyGuid) {
      return;
    }
    this.toggleProgress(true);
    this._authService.sentPasswordResetLink(this.email, this.userScope, this.userCompanyGuid).subscribe(result => {
      this._navigationService.navigateToCheckEmailForPasswordResetNotice(this.email);
    }, error => {
      this.errorMessage = (error && error.error) ? error.error : "An error occurred. Please contact your administrator.";
    }).add(() => {
      this.toggleProgress(false);
    });
  }

  private toggleProgress = (value: boolean) => {
    if (value) {
      this._spinner.show();
    } else {
      this._spinner.hide();
    }
    this.spinnerVisible = value;
    if (value) {
      this.sendResetLinkButtonText = "Please wait...";
    } else {
      this.sendResetLinkButtonText = "Send Reset Link";
    }
  }
}
