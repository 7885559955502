
<div class="modal-header">

  <h2>To Give You an Accurate Quote, We Need to Verify the Following Info</h2>

  <!-- hack to fool ngbModal autofocus -->
  <input type="text" style="display:none" />

  <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" aria-label="Close"
    (click)="activeModal.dismiss('Cross click')">
    <span class="svg-icon svg-icon-2x">
      <close-x-svg-icon></close-x-svg-icon>
    </span>
  </div>

</div>

<div class="modal-body py-10 px-lg-17">
  <div *ngIf="errorMessage" class="alert alert-danger d-flex align-items-center p-5 mb-10">
    <span class="svg-icon svg-icon-2hx svg-icon-danger me-3">
      <duotone-shield-check-svg-icon></duotone-shield-check-svg-icon>
    </span>
    <div class="d-flex flex-column">
      <span>{{errorMessage}}</span>
    </div>
  </div>
  <form *ngIf="currentView === 1" #quoteForm="ngForm" class="form" novalidate id="quoteForm" name="quoteForm">
    <div class="fs-4 fw-bolder text-dark">Applicant Info:</div>
    <div class="separator separator-dashed my-3"></div>
    <div class="row g-9 mb-8">
      <div class="col-md-4 fv-row">
        <label class="form-label required">First Name</label>
        <div class="position-relative d-flex align-items-center">
          <input class="form-control form-control-solid"
            [(ngModel)]="quoteRequest.applicantInfo.firstName" #firstName="ngModel"
            placeholder="First Name" name="firstName"
            [ngClass]="{'is-invalid' : firstName && firstName.touched && firstName.invalid}"
            required />
        </div>
      </div>
      <div class="col-md-4 fv-row">
        <label class="form-label required">Last Name</label>
        <div class="position-relative d-flex align-items-center">
          <input class="form-control form-control-solid"
            [(ngModel)]="quoteRequest.applicantInfo.lastName" #lastName="ngModel" name="lastName"
            placeholder="Last Name" required
            [ngClass]="{'is-invalid' : lastName && lastName.touched && lastName.invalid}"
            required />
        </div>
      </div>
      <div class="col-md-4 fv-row">
        <label class="form-label required">Date of Birth</label>
        <div class="position-relative d-flex align-items-center">
          <input class="form-control form-control-solid" matInput mask="M0/d0/0000" [showMaskTyped]="true"
            [specialCharacters]="[ '/' ]" [dropSpecialCharacters]="false" datePicker #birthDate="ngModel" inputmode="numeric"
            [(ngModel)]="quoteRequest.applicantInfo.dob" placeholder="" name="birthDate" ngControl="birthDate" id="birthDate"
            [ngClass]="{'is-invalid' : birthDate && birthDate.touched && birthDate.invalid}"
            required />
        </div>
      </div>
    </div>
    <div class="row g-9 mb-8">
      <div class="col-md-4 fv-row">
        <label for="email" class="form-label required">E-mail</label>
        <div class="position-relative d-flex align-items-center">
          <input class="form-control form-control-solid"
            [(ngModel)]="quoteRequest.applicantInfo.email" #email="ngModel"
            placeholder="E-mail" name="email" id="email"
            [ngClass]="{'is-invalid' : email && email.touched && email.invalid}"
            email
            required />
        </div>
      </div>
      <div class="col-md-4 fv-row">
        <label for="phoneNumber" class="form-label required">Phone Number</label>
        <div class="position-relative d-flex align-items-center">
          <input class="form-control form-control-solid"
            placeholder="" mask='(000) 000-0000'
            #phoneNumber="ngModel"
            [(ngModel)]="quoteRequest.applicantInfo.phone"
            name="phoneNumber"
            ngControl="phoneNumber"
            id="phoneNumber"
            [ngClass]="{'is-invalid' : phoneNumber && phoneNumber.touched && phoneNumber.invalid}"
            required />
        </div>
      </div>
      <div class="col-md-4 fv-row">
        <label for="maritalStatus" class="form-label required">Marital Status</label>
        <select class="form-select form-select-solid"
          data-control="select2"
          name="maritalStatus"
          [(ngModel)]="quoteRequest.applicantInfo.maritalStatus" #maritalStatus="ngModel"
          [ngClass]="{'is-invalid' : maritalStatus && maritalStatus.touched && maritalStatus.invalid}"
          required>
          <option value="" disabled>Select one...</option>
          <option value="Single">Single</option>
          <option value="Married">Married</option>
          <option value="Separated">Separated</option>
          <option value="Other">Other</option>
        </select>
      </div>
    </div>

    <div class="fs-4 fw-bolder text-dark">Quote Property Info:</div>
    <div class="separator separator-dashed my-3"></div>
    <turbo-insurance-property-info-editor
      #quotePropertyInfoEditor
      [propertyInfo]="quoteRequest.quotePropertyInfo"
      [showMonthsAtAddressField]="false">
    </turbo-insurance-property-info-editor>

    <div class="fs-4 fw-bolder text-dark">Prior Property Info:</div>
    <div class="separator separator-dashed my-3"></div>
    <turbo-insurance-property-info-editor
      #priorPropertyInfoEditor
      [propertyInfo]="quoteRequest.priorPropertyInfo">
    </turbo-insurance-property-info-editor>

    <div class="fs-4 fw-bolder text-dark">Additional Info:</div>
    <div class="separator separator-dashed my-3"></div>
    <div class="row g-9 mb-8">
      <div class="col-md-4 fv-row">
        <label for="estimatedPropertyValue" class="form-label required">Estimated Property Value</label>
        <input type="text" required class="form-control form-control-solid" mask="separator" inputmode="decimal" thousandSeparator="," prefix="$"
          [(ngModel)]="quoteRequest.additionalInfo.estimatedPropertyValue" placeholder="" name="estimatedPropertyValue"
          id="estimatedPropertyValue"
          [ngClass]="{'is-invalid' : estimatedPropertyValue && estimatedPropertyValue.touched && estimatedPropertyValue.invalid}"
          #estimatedPropertyValue="ngModel" required />
      </div>
      <div class="col-md-4 fv-row">
        <label for="propertyType" class="form-label required">Property Type</label>
        <select class="form-select form-select-solid"
          data-control="select2"
          name="propertyType"
          id="propertyType"
          [(ngModel)]="quoteRequest.additionalInfo.propertyType" #propertyType="ngModel"
          [ngClass]="{'is-invalid' : propertyType && propertyType.touched && propertyType.invalid}"
          required>
          <option [ngValue]="null" disabled>Select one...</option>
          <option [ngValue]="propertyType.value" *ngFor="let propertyType of turboInsurancePropertyTypes">{{propertyType.name}}</option>
        </select>
      </div>
      <div class="col-md-4 fv-row">
        <label for="propertyType" class="form-label required">Occupancy</label>
        <select class="form-select form-select-solid"
          data-control="select2"
          name="occupancy"
          id="occupancy"
          [(ngModel)]="quoteRequest.additionalInfo.occupancyType" #occupancyType="ngModel"
          [ngClass]="{'is-invalid' : occupancyType && occupancyType.touched && occupancyType.invalid}"
          required>
          <option [ngValue]="null" disabled>Select one...</option>
          <option [ngValue]="occupancyType.value" *ngFor="let occupancyType of turboInsuranceOccupancyTypes">{{occupancyType.name}}</option>
        </select>
      </div>
    </div>

  </form>
  <div *ngIf="currentView === 2">
    Quotes!
  </div>
</div>

<div class="modal-footer flex-center">
  <button type="button" class="btn btn-white me-3" (click)="activeModal.close()">Cancel</button>
  <button type="button" class="btn btn-primary" (click)="onBackClicked()" *ngIf="currentView === 2">
    < Back
  </button>
  <button type="submit" class="btn btn-primary" (click)="onGenerateQuotesClicked()" *ngIf="currentView === 1">
    <span class="indicator-label">{{prepareQuotesButtonText}}</span>
    <span *ngIf="isPreparingQuotes" class="spinner-border spinner-border-sm align-middle ms-2"></span>
  </button>
</div>




