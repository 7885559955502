import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { AddressHistory } from '../../../models/address-history-model';
import { EnumsService } from '../../../services/enums.service';

@Component({
  selector: 'address-history',
  templateUrl: 'address-history.component.html'
})

export class AddressHistoryComponent implements OnInit {

  constructor(private readonly _enumsService: EnumsService) {
      this.addressHistory = new AddressHistory();
   }

  @ViewChild('addressHistoryForm') addressHistoryForm: NgForm | undefined;

  @Input()
  addressHistory!: AddressHistory;

  validate = () => {
      this.addressHistoryForm?.form.markAllAsTouched();
      if (this.addressHistoryForm?.form.valid) {
          return true;
      }
      return false;
  }

  public get states() {
    return this._enumsService.states;
  }

  public get residencyBasisValues() {
    return this._enumsService.residencyBasisValues;
  }

  ngOnInit() { }
}
