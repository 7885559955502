import { IDecideNextStep } from "../../services/wizard/next-step-decider/next-step-decider.interface";
import { DigitalIncomeVerificationStepComponent } from "../../wizard/wizard-steps/digital-income-verification-step/digital-income-verification-step.component";
import { WizardStepBase } from "./wizard-step-base.model";

export class DigitalIncomeVerificationStep extends WizardStepBase {

  vendorToUse: string = "TWN";

  constructor(id: string, name: string, path: string, nextStepDecider?: IDecideNextStep) {
    super(id, name, path, nextStepDecider);
    this.component = DigitalIncomeVerificationStepComponent;
  }
}
