import { Type } from "@angular/core";
import { IDecideNextStep } from "../../services/wizard/next-step-decider/next-step-decider.interface";
import { ConfigurableFieldHost } from "./configurable-field-host.model";

export abstract class WizardStepBase extends ConfigurableFieldHost {
  id!: string;
  name: string | undefined;
  path: string;
  title: string;
  explanation: string;
  heading: string;
  component: Type<any> | undefined;
  previousStep: any | undefined;
  inEditMode: boolean = false;
  groupId!: number | undefined;

  private readonly _nextStepDecider: IDecideNextStep | undefined;

  private _requiresNextButton: boolean = true;

  public get hasPreviousStep(): boolean {
    return this.previousStep !== undefined && this.previousStep !== null;
  }

  public get hasNextStep(): boolean {
    return this._nextStepDecider !== undefined && this._nextStepDecider !== null;
  }

  public get canMoveToNextStep(): boolean {
    return true;
  }

  get requiresNextButton(): boolean {
    return this._requiresNextButton;
  }

  set requiresNextButton(value: boolean) {
    this._requiresNextButton = value;
  }

  constructor(id: string, name: string | undefined, path: string, nextStepDecider?: IDecideNextStep | undefined) {
    super();
    this.id = id;
    this.name = name;
    this._nextStepDecider = nextStepDecider;
    this.path = path;
    this.title = "";
    this.explanation = "";
    this.heading = "";
  }

  nextStepPath = (): string | undefined => {
    let nextStepPath: string | undefined = "";
    if (this._nextStepDecider) {
      nextStepPath = this._nextStepDecider.nextStepPath();
    }
    return nextStepPath;
  }
}
