import { Component, Input, OnInit } from '@angular/core';
import { FieldConfig } from 'projects/borrower-app/src/app/models/wizard/config/field-config-model';

@Component({
  selector: 'field-config-editor',
  templateUrl: 'field-config-editor.component.html'
})

export class FieldConfigEditorComponent implements OnInit {

  @Input()
  fieldConfigs: FieldConfig[] = [];

  @Input()
  header: string = "Field Configuration";

  constructor() { }

  ngOnInit() { }
}
