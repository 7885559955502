import { Injectable } from "@angular/core";
import { WizardFlowContext } from "../../../wizard-flow-context.model";
import { GenericStepFactory } from "./generic-step.factory";
import { NextStepDeciderFactory } from "./next-step-decider.factory";
import { ICreateStep } from "./step-factory.interface";
import { AssetsStep } from "../../assets-step.model";
import { AssetsStepConfig } from "../assets-step-config.model";

@Injectable()
export class AssetsStepFactory extends GenericStepFactory<AssetsStep> implements ICreateStep {

  constructor(nextStepDeciderFactory: NextStepDeciderFactory) {
    super(nextStepDeciderFactory, AssetsStep);
  }

  create(config: AssetsStepConfig, wizardFlowContext: WizardFlowContext): AssetsStep {
    let assetsStep = super.create(config, wizardFlowContext)
    assetsStep.dontLetUserSkipWithoutAssets = config.dontLetUserSkipWithoutAssets;
    assetsStep.addAssetActionLabel = config.addAssetActionLabel || 'Add Asset';
    return assetsStep;
  }

  configure(step: AssetsStep, config: AssetsStepConfig): void {
    super.configure(step, config);
    config.dontLetUserSkipWithoutAssets = step.dontLetUserSkipWithoutAssets;
    config.addAssetActionLabel = step.addAssetActionLabel;
  }
}
