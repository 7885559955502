
  <div class="row gy-5 g-xl-8">
    <div class="col-xxl-12 mt-2">
      <personal-info-card [borrower]="borrower" (borrowerChanged)="onBorrowerChanged($event)"></personal-info-card>
    </div>
  </div>
  <div class="row gy-5 g-xl-8">
    <div class="col-xxl-12 mt-0">
      <asset-card
        [assets]="assets"
        (assetEdited)="onAssetEdited($event)"
        (assetDeleted)="onAssetDeleted($event)">
      </asset-card>
    </div>
  </div>
  <div class="row g-xl-8">
    <div class="col-xxl-12 mt-5">
      <address-history-card
        [borrower]="borrower"
        (borrowerChanged)="onBorrowerChanged($event)">
      </address-history-card>
    </div>
  </div>
  <div class="row g-xl-8">
    <div class="col-xxl-12 mt-5">
      <employment-history-card [borrower]="borrower"></employment-history-card>
    </div>
  </div>

