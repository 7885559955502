import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'credit-score-indicator',
  templateUrl: 'credit-score-indicator.component.html'
})

export class CreditScoreIndicatorComponent implements OnInit {
  constructor() {
  }

  @Input()
  companyName!: string;

  @Input()
  score: number | undefined;

  @Input()
  useIcons: boolean = true;

  ngOnInit() { }
}
