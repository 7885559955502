<div class="modal-header">

  <h2>Price Options</h2>

  <!-- hack to fool ngbModal autofocus -->
  <input type="text" style="display:none" />

  <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" aria-label="Close" (click)="activeModal.dismiss()">
    <span class="svg-icon svg-icon-2x">
      <close-x-svg-icon></close-x-svg-icon>
    </span>
  </div>
</div>

<div class="modal-body py-10 px-lg-17" *ngIf="priceOptionsPages">
  <ngb-carousel [interval]="0">
    <ng-template ngbSlide *ngFor="let page of priceOptionsPages">
      <div class="d-flex flex-column">
        <div [ngClass]="{'d-flex justify-content-around': !isMobile}">
          <price-option-card *ngFor="let priceOption of page; let index = index" 
            [quote]="priceOption"
            [isMobile]="isMobile"
            [isSelected]="selectedQuote && priceOption['uniqueId'] === selectedQuote['uniqueId']"
            (priceOptionSelected)="onPriceOptionSelected($event)">
          </price-option-card>
        </div>
      </div>
    </ng-template>
  </ngb-carousel>
</div>

<div class="modal-footer flex-center">
  <button type="button" class="btn btn-white me-3" (click)="activeModal.dismiss()">Cancel</button>
  <button type="button" class="btn btn-primary" (click)="onOkClicked()">Done</button>
</div>