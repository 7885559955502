<ng-template #pricing>

  <div class="row" style="margin: 0 auto;">
    <div class=" card shadow-sm col me-2 non-price-panel">
      <div class="card-header px-4 d-flex align-items-center justify-content-between border-bottom-2">
        <h4 class="mb-0">
          Liabilities to Pay Off
        </h4>
      </div>
      <div class="mt-4">
        <liability-card *ngFor="let liability of mortgage.liabilities" (liabilityChanged)="onLiabilityChanged($event)"
          [liability]="liability">
        </liability-card>
      </div>
    </div>
    <div class="card shadow-sm col me-2" style="min-width: 360px;">
      <div class="d-flex justify-content-between">
        <div class="justify-content-center mb-10 text-center">
          <label class="fs-6 fw-bold form-label mt-3">
            Loan Amount
          </label>
          <span id="loan-amount-width-calculator" [hidden]="true">{{loanAmount}}</span>
          <input id="loan-amount" type="text" class="form-control loan-amount-editor"
            style="font: inherit; font-size: 2.5rem; font-weight: 600; text-align: center;" mask="separator"
            thousandSeparator="," inputmode="decimal" prefix="$" [(ngModel)]="loanAmount" placeholder=""
            name="loanAmount" (blur)="onLoanAmountChanged()" (keyup)="onKeyUpOnLoanAmount()" />
          <div style="width: 60%; margin: 0 auto">
            <p-slider [step]="1000" [min]="minLoanAmount" [max]="maxLoanAmount" [(ngModel)]="loanAmount"
              (onSlideEnd)="onLoanAmountChanged()">
            </p-slider>
          </div>
        </div>
        <div class="justify-content-center mb-10 text-center">
          <label class="fs-6 fw-bold form-label mt-3">
            Loan Term
          </label>
          <span id="loan-term-width-calculator" [hidden]="true">{{loanAmount}}</span>
          <input id="loan-term" type="text" class="form-control loan-term-editor"
            style="font: inherit; font-size: 2.5rem; font-weight: 600; text-align: center;"
            inputmode="number" [(ngModel)]="loanTerm" placeholder=""
            name="loanTerm" />
          <div style="width: 60%; margin: 0 auto">
            <p-slider [step]="5" [min]="5" [max]="30" [(ngModel)]="loanTerm"
              (onSlideEnd)="onLoanTermChanged()">
            </p-slider>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-center flex-wrap">
        <div class="text-center shadow-sm liability-payoff-info-body"
          *ngIf="liabilityPayOffInformationChanged && productSearchResults?.products">
          <h4> You have modified debts to be paid off </h4>
          <h4 class="mt-8"> Please click below to update pricing </h4>
          <button type="button" class="btn btn-primary" style="margin-top: 4rem;" (click)="onRefreshPricingClicked()">
            Refresh Pricing </button>
        </div>
        <div *ngFor="let product of productSearchResults?.products | filter : 'isEligible'" class="mb-2" style="max-width: 350px;">
          <product-price-card [loanAmount]="productSearchRequest.loanInformation.baseLoanAmount"
            [liabilityPayOffInformationChanged]="liabilityPayOffInformationChanged"
            [appraisedValue]="productSearchRequest.propertyInformation.appraisedValue" [product]="product"
            (rateEditClicked)="onRateEditClicked($event)"
            [isSelected]="selectedPricingProduct && product.productId === selectedPricingProduct.productId"
            (selectedCardChanged)="onSelectedCardChanged($event)">
          </product-price-card>
        </div>
        <div *ngIf="!productSearchResults?.products || ((productSearchResults?.products | filter : 'isEligible').length === 0) || inEditMode">
          <div class="alert bg-light-primary d-flex flex-column flex-sm-row p-5 mb-10">
            <div class="d-flex flex-column pe-0">
              <h4 *ngIf="!inEditMode" class="fw-semibold">{{step.noProductFoundMessageTitle}}</h4>
              <input *ngIf="inEditMode" [name]="'no-product-message-title'" type="text"
                class="form-control form-control-solid fs-4 fw-bolder" [(ngModel)]="step.noProductFoundMessageTitle" />
              <span *ngIf="!inEditMode">{{step.noProductsFoundMessageText}}</span>
              <input *ngIf="inEditMode" [name]="'no-product-message-text'" type="text"
                class="form-control form-control-solid fs-4 fw-bolder" [(ngModel)]="step.noProductsFoundMessageText" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card shadow-sm col non-price-panel benefits-panel">
      <div class="card-header px-4 d-flex align-items-center justify-content-between border-bottom-2">
        <h4 class="mb-0">
          Benefits
        </h4>
      </div>
      <div class="mt-4">
        <benefits [liabilitiesToPayOff]="liabilitiesToPayOff" [loanAmount]="loanAmount"
          [product]="selectedPricingProduct">
        </benefits>
      </div>
    </div>
  </div>

</ng-template>

<wizard-step-template [stepMainTemplate]="pricing" [step]="step" [stepComponent]="this"></wizard-step-template>
