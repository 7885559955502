import { IDecideNextStep } from "../../services/wizard/next-step-decider/next-step-decider.interface";
import { NumberOfBorrowersComponent } from "../../wizard/wizard-steps/number-of-borrowers/number-of-borrowers.component";
import { WizardStepBase } from "./wizard-step-base.model";

export class NumberOfBorrowersStep extends WizardStepBase {

  constructor(id: string, name: string | undefined, path: string, nextStepDecider?: IDecideNextStep | undefined) {
    super(id, name, path, nextStepDecider);
    this.component = NumberOfBorrowersComponent;
  }
}
