import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Flow } from "../models/flow.model";
import { DataService } from "./data.service";

@Injectable()
export class FlowBuilderService {

    constructor(private readonly _dataService: DataService)
    {
    }

    getFlow = (flowGuid: string): Observable<Flow> => {
      const url = `api/flow-builder/by-guid/${flowGuid}`;
      return this._dataService.getWithoutAuth(url);
    }
  }
