import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'projects/shared/services/notification.service';
import { FileService } from '../../../services/file.service';

@Component({
  selector: 'view-document',
  templateUrl: 'view-document.component.html',
  styleUrls: ['./view-document.component.scss']
})
export class ViewDocumentComponent implements OnInit {

  @Input()
  document: LinkedDocument;

  constructor(private readonly _notifsService: NotificationService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _fileService: FileService) { }

  ngOnInit() { }

  onDownloadFileClicked = (documentToDownload: LinkedDocument) => {
    this._spinner.show();

    const observer = {
      next: (data: any) => {
        this._spinner.hide();
        const blob = new Blob([data], { type: data.type });
        let downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        let fileName = documentToDownload.fileName;
        downloadLink.setAttribute('download', fileName);
        document.body.appendChild(downloadLink);
        downloadLink.click();
      },
      error: (error: any) => {
        this._spinner.hide();
        this._notifsService.showError(error.error ? error.error.message : 'An error occurred while downloading file.', 'Error!');
      }
    };

    this._spinner.show();
    this._fileService.downloadFile(documentToDownload.fileGuid).subscribe(observer);
  }

  onViewFileClicked = (document: LinkedDocument) => {
    const observer = {
      next: (data: any) => {
        this._spinner.hide();
        const blob = new Blob([data], { type: data.type });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      },
      error: (error: any) => {
        this._spinner.hide();
        this._notifsService.showError(error.error ? error.error.message : 'An error occurred while viewing file.', 'Error!');
      }
    };

    this._spinner.show();
    this._fileService.viewFile(document.fileGuid).subscribe(observer);
  };
}

export class LinkedDocument {
  fileGuid: string;
  mimeType: string;
  fileName: string;
  dateInserted: Date | null;
  note: string;
}
