import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SubjectProperty } from '../../models/subject-property.model';
import { EnumsService } from '../../services/enums.service';
import { TransactionDetail } from "../../models/transaction-detail.model";
import { MortgageTerm } from '../../models/mortgage-term.model';
import { LoanPurpose } from '../../models/enums/loan-purpose.enum';
import { ReviewApplicationStep } from '../../models/wizard/review-application.model';
import { ConfigurableFieldHostComponent } from '../configurable-field-host.component';

@Component({
  selector: 'loan-summary',
  templateUrl: 'loan-summary.component.html'
})
export class LoanSummaryComponent extends ConfigurableFieldHostComponent implements OnInit {

  @Input()
  inEditMode: boolean = false;

  @Input()
  set transactionDetail(transactionDetail: TransactionDetail) {
    this._transactionDetail = transactionDetail;
    this.ltv = this.calculateEstimatedLtv();
  }

  @Input()
  set subjectProperty(subjectProperty: SubjectProperty) {
    this.purposeOfLoan = this._enumsService.loanPurposeName(subjectProperty.purposeOfLoan);
    this._subjectProperty = subjectProperty;
    this.ltv = this.calculateEstimatedLtv();
  }

  @Input()
  set mortgageTerm(mortgageTerm: MortgageTerm) {
    this.mortgageAppliedFor = this._enumsService.mortgageTypeName(mortgageTerm.mortgageAppliedFor);
    this._mortgageTerm = mortgageTerm;
    this.ltv = this.calculateEstimatedLtv();
  }

  @Input()
  step: ReviewApplicationStep;

  get subjectProperty(): SubjectProperty {
    return this._subjectProperty;
  }

  get transactionDetail(): TransactionDetail {
    return this._transactionDetail;
  }

  @Output()
  loanDetailsClicked: EventEmitter<any> = new EventEmitter<any>();

  purposeOfLoan: string = '';
  mortgageAppliedFor: string = '';
  ltv: number = 0;

  private _subjectProperty: SubjectProperty | null = null;
  private _mortgageTerm: MortgageTerm | null = null;
  private _transactionDetail: TransactionDetail | null = null;

  constructor(private readonly _enumsService: EnumsService) {
    super();
  }

  ngOnInit() {
    this.ltv = this.calculateEstimatedLtv();
  }

  onLoanDetailsClicked = () => {
    this.loanDetailsClicked.emit();
  }

  calculateEstimatedLtv = (): number | undefined => {
    let loanAmount: number | undefined | null = this._mortgageTerm?.amount;
    let estimatedValue: number;
    if (this._subjectProperty.purposeOfLoan === LoanPurpose.Refinance) {
      estimatedValue = this._subjectProperty?.presentValue;
    } else {
      estimatedValue = this.transactionDetail?.purchasePriceAmount!;
    }
    if (!loanAmount) {
      loanAmount = 0;
    }
    if (!estimatedValue) {
      return undefined;
    }
    return Math.round((loanAmount / estimatedValue) * 100);
  }
}
