<div class="card">
  <div class="card-header d-flex align-items-center justify-content-center" style="min-height: 50px;padding-top: 6px;">
    <h5 class="opacity-80">The Loan Team</h5>
  </div>
  <div class="card-body p-1">
    <div class="row g-0 py-2" *ngFor="let contact of borrowerContact.loanContacts | filter: 'userId' | sort: 'order'">
      <div class="col-3 d-flex align-items-center justify-content-center">
        <span *ngIf="!contact.avatarUrl"
          class="bp-contact-name-circle p-2 rounded-circle fw-bold d-flex align-items-center justify-content-center">
          {{ contact.name?.charAt(0) }}
        </span>
        <person-circle-image *ngIf="contact.avatarUrl" [url]="contact.avatarUrl" [size]="60"></person-circle-image>
      </div>
      <div class="col-9">
        <div class="opacity-80 fw-bold" style="font-size:12px">{{ contact.name }}</div>
        <div class="text-muted" style="font-size:12px">{{ contact.title }}</div>
        <div *ngIf="contact.userId" class="d-flex flex-column" style="word-break: break-all">
            <a class="nav-link d-inline p-0 mt-1 mb-1" style="font-size:10px" href="tel:{{ contact.phone }}"
              [ngClass]="!contact.phone ? 'disabled' : ''"><i class="fa fa-phone"></i> {{ contact.phone | phone }}</a>

            <a class="nav-link d-inline p-0 mb-1" style="font-size:10px" [ngClass]="!contact.email ? 'disabled' : ''"
              href="mailto: {{ contact.email }}"><i class="fa fa-envelope"></i> {{ contact.email }}</a>
            <a *ngIf="contact?.calendarLink" class="nav-link d-inline p-0 mb-1" style="font-size:10px" [href]="contact?.calendarLink"
              target="_blank"><i class="fa fa-calendar"></i> View Calendar
            </a>
        </div>
      </div>
    </div>
  </div>
</div>
