<ng-template #currentAddressHistory>
  <div class="card shadow-sm mb-5">
    <div class="card-header">
        <h3 class="card-title">{{mainBorrower.firstName + " " + mainBorrower.lastName}}</h3>
    </div>
    <div class="card-body">
      <borrower-address-history
        #borrowerAddressHistory
        [mortgage]="mortgage"
        [borrower]="mainBorrower"
        [fieldsToConfig]="step.fieldConfig"
        [reoFieldsToConfig]="reoFieldsToConfig"
        [currentAddressLabel]="'My Home Address'" >
      </borrower-address-history>
    </div>
  </div>
</ng-template>

<wizard-step-template [stepMainTemplate]="currentAddressHistory" [step]="step" [stepComponent]="this"></wizard-step-template>
