import { AfterViewInit, Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { LookupType, QuestionSummary } from '../../../../models/checklists/config/checklist-config.model';
import { ChecklistQuestion } from '../../../../models/checklists/borrower-flow/checklist-question.model';
import { Borrower } from 'projects/borrower-app/src/app/models/borrower-model';
import { NgForm } from '@angular/forms';
import * as _ from 'lodash';
import { UtilsService } from 'projects/borrower-app/src/app/services/utils.service';

declare const Swal: any;

@Component({
  selector: 'borrower-checklists',
  templateUrl: 'borrower-checklists.component.html'
})
export class BorrowerChecklistsComponent implements OnInit, AfterViewInit {

  @ViewChild("checklistQuestionsForm")
  checklistQuestionsForm: NgForm | undefined;

  @Input()
  applicationId: number;

  @Input()
  borrower: Borrower;

  @Input()
  isPrimaryBorrower: boolean = true;

  @Input()
  questionMetaData: QuestionSummary[] = [];

  @Input()
  questionsByChecklist: Map<number, ChecklistQuestion[]> = new Map<number, ChecklistQuestion[]>();

  @Input()
  set selectedChecklistIds(selectedChecklistIds: number[]) {
    this._selectedChecklistIds = _.cloneDeep(selectedChecklistIds);
  }

  @Output()
  allQuestionsAreTriggerOnly: EventEmitter<BorrowerQuestionCountStatusEvent> = new EventEmitter<BorrowerQuestionCountStatusEvent>()

  get atTheEndOfChecklist(): boolean {
    return (this._currentChecklistQuestionSet.length - 1 === this._currentQuestionIndex &&
      this._currentChecklistIndex === this._selectedChecklistIds.length - 1) || !this.currentQuestion;
  }

  get atTheBeginningOfChecklist(): boolean {
    return (this._currentChecklistIndex === 0 && this._currentQuestionIndex === this._initialQuestionIndex) || !this.currentQuestion;
  }

  // This is a 2 level key/value pair-like object
  // where the first level keys are checklistIds,
  // and the second level keys are questionIds
  borrowersAnswersToQuestions: any = {};

  currentQuestion: ChecklistQuestion;
  currentQuestionMetaData: QuestionSummary;

  isSavingAnswer: boolean = false;

  singleAnswerToCurrentQuestion: string;

  currentChecklistId: number;

  isCurrentQuestionBorrowerSpecific: boolean = false;

  borrowerName: string | undefined | null = "";

  private _currentChecklistIndex: number;
  private _currentQuestionIndex: number;

  private _initialQuestionIndex: number = 0;
  private _currentChecklistQuestionSet: ChecklistQuestion[] = [];

  private _nonTriggerOnlyQuestionsByChecklist: Map<number, ChecklistQuestion[]> = new Map<number, ChecklistQuestion[]>();

  private _selectedChecklistIds: number[] = [];

  multiSelectOptions: any = {
    width: '100%',
    multiple: true,
    closeOnSelect: false,
    placeholder: "Select answers that apply"
  };

  constructor(private readonly _utils: UtilsService) {
  }

  ngOnInit() {
    this._currentChecklistIndex = 0;
    this._currentQuestionIndex = 0;
    this.questionsByChecklist = _.cloneDeep(this.questionsByChecklist);
    // First initialize answers for ALL questions including TriggerOnly ones, so when saved we submit answers for them
    this.initializeBorrowersAnswersGivenToQuestions();
    // After initializing answers for all, remove TriggerOnly questions, so they do not get shown to user
    this.removeTriggerOnlyQuestions();
    this.setCurrentChecklistAndQuestionNavigationMetaData();
    this._initialQuestionIndex = this._currentQuestionIndex;

    if (this.borrower) {
      this.borrowerName = this._utils.getPersonsFullName(this.borrower);
    }
  }

  ngAfterViewInit() {
    // After removing all trigger only questions, if there are no questions left, there is nothing to do here
    // Notify the parent component
    const e = new BorrowerQuestionCountStatusEvent();
    e.borrowerId = this.borrower.borrowerId;
    e.noQuestionsToAnswer = this.questionCountInAllChecklists() === 0;
    this.allQuestionsAreTriggerOnly.emit(e);
  }

  gotoNextQuestion = () => {
    if (!this.currentQuestion) {
      return;
    }
    if (!this.validate()) {
      return;
    }
    const answersGivenByBorrower: SelectedAnswer = this.borrowersAnswersToQuestions[this.currentChecklistId][this.currentQuestion.questionId];
    if (answersGivenByBorrower) {
      this.currentQuestion.answers.forEach(answer => {
        if (answersGivenByBorrower.choicesSelected.includes(answer.questionAnswerId) ||
          answersGivenByBorrower.singleAnswer === answer.questionAnswerId.toString()) {
          const currentChecklistQuestions = this._nonTriggerOnlyQuestionsByChecklist.get(this.currentChecklistId);
          answer.questions.forEach(q => {
            q['isSubsequent'] = true;

            this.updateAnswerGivenToQuestion(this.currentChecklistId, q);

          });
          currentChecklistQuestions.splice(this._currentQuestionIndex + 1, 0, ...answer.questions);
        }
      })
    }

    const currentChecklistQuestions = this._nonTriggerOnlyQuestionsByChecklist.get(this.currentChecklistId);
    if (this._currentQuestionIndex < currentChecklistQuestions.length - 1) {
      this._currentQuestionIndex++;
    } else {
      // This means we're already at the end of the current checklist, need to move to the next one
      if (this._currentChecklistIndex < this._selectedChecklistIds.length - 1) {
        this._currentChecklistIndex++;
        this._currentQuestionIndex = 0;
      } else {
        // We're done
      }
    }
    this.setCurrentChecklistAndQuestionNavigationMetaData();
  }

  validate = (): boolean => {
    if (this.checklistQuestionsForm) {
      this.checklistQuestionsForm.form.markAllAsTouched();
      return this.checklistQuestionsForm.form.valid;
    }
    return true;
  }

  gotoPreviousQuestion = () => {
    if (!this.currentQuestion) {
      return;
    }
    this.markQuestionControlsUntouched();
    if (this._currentQuestionIndex > this._initialQuestionIndex) {
      this._currentQuestionIndex--;
    } else {
      // This means we're at the first question of the current checklist already, we need to go to the previous checklist
      if (this._currentChecklistIndex > 0) {
        this._currentChecklistIndex--;
        this.currentChecklistId = this._selectedChecklistIds[this._currentChecklistIndex];
        const checklistQuestions = this._nonTriggerOnlyQuestionsByChecklist.get(this.currentChecklistId);
        this._currentChecklistQuestionSet = this._nonTriggerOnlyQuestionsByChecklist.get(this.currentChecklistId);
        // Set the question index to the last of the current checklist
        this._currentQuestionIndex = checklistQuestions.length - 1;
      } else {
        // We are at the very beginning
      }
    }
    const question = this._currentChecklistQuestionSet[this._currentQuestionIndex];
    // If what we came back to is a main question (not subsequent based on a given answer), delete them from the flow
    if (!question['isSubsequent']) {
      for (let i = this._currentChecklistQuestionSet.length - 1; i > this._currentQuestionIndex; i--) {
        if (this._currentChecklistQuestionSet[i]['isSubsequent']) {
          this._currentChecklistQuestionSet.splice(i, 1);
        }
      }
    }
    this.setCurrentChecklistAndQuestionNavigationMetaData();
  }

  private setCurrentChecklistAndQuestionNavigationMetaData = () => {
    if (!this._selectedChecklistIds.length) {
      return;
    }
    this.currentChecklistId = this._selectedChecklistIds[this._currentChecklistIndex];
    this._currentChecklistQuestionSet = this._nonTriggerOnlyQuestionsByChecklist.get(this.currentChecklistId);
    this.currentQuestion = this._currentChecklistQuestionSet[this._currentQuestionIndex];

    if (this.currentQuestion) {
      this.currentQuestionMetaData = this.questionMetaData.find(q => q.questionId === this.currentQuestion.questionId);
      this.isCurrentQuestionBorrowerSpecific = this.currentQuestion.validForBorrowerIds
        && this.currentQuestion.validForBorrowerIds.includes(this.borrower.contactId);
    }
    this.markQuestionControlsUntouched();
  }

  private initializeBorrowersAnswersGivenToQuestions = () => {
    this._selectedChecklistIds.forEach(checklistId => {
      this.borrowersAnswersToQuestions[checklistId] = {};
      this.questionMetaData.forEach(question => {
        this.borrowersAnswersToQuestions[checklistId][question.questionId] = new SelectedAnswer();
        this.borrowersAnswersToQuestions[checklistId][question.questionId].questionType = question.questionType;
      })
    })

    this._selectedChecklistIds.forEach(checklistId => {
      const questionsInChecklist = this.questionsByChecklist.get(checklistId);
      if (!questionsInChecklist || !questionsInChecklist.length) {
        return;
      }
      questionsInChecklist.forEach(answeredQuestion => {
        this.updateAnswerGivenToQuestion(checklistId, answeredQuestion);
      })
    })
  }

  private removeTriggerOnlyQuestions = () => {
    this._nonTriggerOnlyQuestionsByChecklist = _.cloneDeep(this.questionsByChecklist);

    for (let j = this._selectedChecklistIds.length - 1; j >= 0; j--) {
      const checklistId = this._selectedChecklistIds[j];
      const questionsInChecklist = this._nonTriggerOnlyQuestionsByChecklist.get(checklistId);
      if (questionsInChecklist && questionsInChecklist.length) {
        for (let i = questionsInChecklist.length - 1; i >= 0; i--) {
          const questionMetaData = this.questionMetaData.find(q => q.questionId === questionsInChecklist[i].questionId);
          if (questionMetaData && questionMetaData.questionType === LookupType.TriggerOnly) {
            questionsInChecklist.splice(i, 1);
            continue;
          }
          // If the borrower is not primary and the question is NOT specific to borrower, remove it for this borrower
          // ONLY primary borrowers answer the mortgage specific questions
          // OR If question has validForBorrowerIds setting, and it does not contain this borrower's Id, remove as well.
          if ((!this.isPrimaryBorrower && !questionsInChecklist[i].validForBorrowerIds) ||
            (questionsInChecklist[i].validForBorrowerIds && !questionsInChecklist[i].validForBorrowerIds.includes(this.borrower.contactId))) {
            questionsInChecklist.splice(i, 1);
          }
        }
      }
      if (!questionsInChecklist || !questionsInChecklist.length) {
        this._selectedChecklistIds.splice(j, 1);
      }
    }
  }

  private markQuestionControlsUntouched = () => {
    if (this.checklistQuestionsForm) {
      const keys = Object.keys(this.checklistQuestionsForm.form.controls);
      keys.forEach(key => {
        this.checklistQuestionsForm.controls[key].markAsUntouched();
      })
    }
  }

  private updateAnswerGivenToQuestion = (checklistId: number, question: ChecklistQuestion) => {
    const answersGivenByBorrower: SelectedAnswer = this.borrowersAnswersToQuestions[checklistId][question.questionId];
    if (question.validForBorrowerIds && question.validForBorrowerIds && question.validForBorrowerIds.includes(this.borrower.contactId)) {
      answersGivenByBorrower.borrowerId = this.borrower.contactId;
    } else {
      // If the borrower is NOT the primary, they dont get to answer the mortgage specific questions
      // So we delete the answers that we initialized for them in the beginning
      if (!this.isPrimaryBorrower) {
        this.borrowersAnswersToQuestions[checklistId][question.questionId] = undefined;
        return;
      }
    }
    if (question.submittedAnswers && question.submittedAnswers.length) {
      let answerIdsGivenByBorrower = [];
      if (answersGivenByBorrower.borrowerId) {
        answerIdsGivenByBorrower = question.submittedAnswers.filter(a => a.borrowerId === this.borrower.contactId &&
          a.applicationId === this.applicationId);
      } else {
        answerIdsGivenByBorrower = question.submittedAnswers.filter(a => a.applicationId === this.applicationId);
      }
      if (answerIdsGivenByBorrower.length === 1) {
        if (!answerIdsGivenByBorrower[0].questionAnswerId) {
          answersGivenByBorrower.singleAnswer = answerIdsGivenByBorrower[0].answerText;
        } else {
          answersGivenByBorrower.choicesSelected = [answerIdsGivenByBorrower[0].questionAnswerId];
        }
      } else if (answerIdsGivenByBorrower.length > 1) {
        answersGivenByBorrower.choicesSelected = answerIdsGivenByBorrower.map(answer => answer.questionAnswerId);
      }
    }
    this.borrowersAnswersToQuestions[checklistId][question.questionId] = answersGivenByBorrower;
  }

  private questionCountInAllChecklists = (): number => {
    let questionCount = 0;
    this._selectedChecklistIds.forEach(checklistId => {
      const questionsInChecklist = this._nonTriggerOnlyQuestionsByChecklist.get(checklistId);
      if (questionsInChecklist && questionsInChecklist.length) {
        questionCount += questionsInChecklist.length;
      }
    })
    return questionCount;
  }
}

export class SelectedAnswer {
  choicesSelected: number[] = [];
  singleAnswer: string;
  borrowerId?: number | null;
  questionType: string;
}

export class BorrowerQuestionCountStatusEvent {
  noQuestionsToAnswer: boolean;
  borrowerId: number;
}


