<div class="modal-header">
  <h5 class="modal-title d-flex align-items-center">Verify Your Income/Employment</h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>

<div class="modal-body">
  <div class="card-px text-center py-20 my-10" *ngIf="voeEnabled && loading">
    <p class="text-gray-400 fs-4 fw-bold mb-10">Please wait...</p>
    <i class="fas fa-circle-notch fa-4x fa-spin"></i>
  </div>
  <digital-income-employment-verification 
    *ngIf="voeEnabled && thirdPartyEnrollmentWidget && !loading" 
    (complete)="activeModal.close()" 
    (close)="activeModal.close()" 
    [applicationId]="applicationId"
    [borrowerId]="task.borrowerId"
    [taskId]="task.taskId"
    [provider]="provider"
    [thirdPartyEnrollmentWidget]="thirdPartyEnrollmentWidget">
  </digital-income-employment-verification >
  <div class="card-px text-center py-20 my-10" *ngIf="!voeEnabled">
    <h2 class="fs fw-bold mb-8">Digital Income/Employment Verification not Available</h2>
    <p class="text-gray-400 fs-4 fw-semibold mb-10">We are not able to pull your income/employment digitally at the moment.
      <!-- <br>You will have to tell us about your assets manually.</p>
    <a (click)="onSpecifyAssetsClicked()" class="btn btn-primary">Tell Us About Your Assets</a> -->
  </div>
</div>
