import { Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from 'projects/shared/modal/modal.service';
import { AssetsStep } from '../../../models/wizard/assets-step.model';
import { Asset } from '../../../models/asset.model';
import { AssetDialogComponent } from '../asset-dialog/asset-dialog.component';
import { MortgageApplication } from '../../../models/mortgage-app.model';
import { AssetListComponent } from '../asset-list/asset-list.component';
import { EnumerationItem } from 'projects/shared/models/enumeration-item.model';
import { WizardStepComponentBase } from '../wizard-step-base.component';
import * as _ from 'lodash';
import { ErrorMessage } from '../../../models/error-message.model';
import { MortgageApplicationService } from '../../../services/mortgage-application.service';
import { Observer } from 'rxjs';
import { getErrorMessageOrDefault } from 'projects/shared/utils/error.utils';

@Component({
  selector: 'assets-step',
  templateUrl: 'assets-step.component.html'
})
export class AssetsStepComponent extends WizardStepComponentBase<AssetsStep> implements OnInit, OnDestroy {

  @ViewChild(AssetListComponent) assetListComponent: AssetListComponent | undefined;

  private modalOptions: NgbModalOptions = {
    size: "lg",
    backdrop: "static",
    container: "#assets-main"
  };

  private _subscriptionToAssetSavedForCreate: any;

  mortgage: MortgageApplication;

  constructor(private readonly _modalService: ModalService,
    private readonly _mortgageService: MortgageApplicationService,
    private readonly _injector: Injector) {
    super(_injector);
    this.saveMortgageApplicationBeforeNextStep = true;
    this.mortgage = this.mortgageApplication;
  }

  ngOnInit(): void {
    super.ngOnInit();
    const applicationId = this.mortgage.applicationId;

    const observer: Observer<MortgageApplication> = {
      next: (mortgage: MortgageApplication) => {
        this.mortgage = mortgage;
        this.wizardFlowService.updateContext(mortgage);
        this.stopSpinner();
      },
      error: (err: any) => {
        const errorMessage = getErrorMessageOrDefault(err, {
          defaultMessage: 'Error getting the assets.',
        });
        this.showError(new ErrorMessage("Error getting the assets", errorMessage));
      },
      complete: () => {
      }
    }
    this.startSpinner();
    this._mortgageService.getMortgage(applicationId, true).subscribe(observer);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this._subscriptionToAssetSavedForCreate) {
      this._subscriptionToAssetSavedForCreate.unsubscribe();
    }
  }

  onAddAssetClicked = () => {
    if (!this.inEditMode) {
      this.showAssetPopup();
    }
  }

  onNextClicked() {
    if (this.step.dontLetUserSkipWithoutAssets && (!this.mortgage.assets || !this.mortgage.assets.length)) {
      const errorMessage = new ErrorMessage("Add Assets", "We need to know your assets to be able to process your application. Please, enter your assets.");
      this.showError(errorMessage);
      return;
    }
    if (this.assetListComponent?.isValid()) {
      super.onNextClicked();
    }
  }

  private showAssetPopup(asset?: Asset) {
    const modal = this._modalService.show(AssetDialogComponent, this.modalOptions);
    if (asset) {
      modal.componentInstance.asset = asset;
    } else {
      modal.componentInstance.selectedBorrowers = [new EnumerationItem(this.currentBorrower.firstName,
        <string>this.currentBorrower.borrowerId?.toString())];
    }
    modal.componentInstance.fieldsToConfig = this.step.fieldConfig;
    modal.componentInstance.inCoApplyFlow = this.wizardFlowService.inCoApplyFlow;
    this._subscriptionToAssetSavedForCreate = modal.componentInstance.saveClickedEmitterForCreate
      .subscribe((e: Asset) => this.onSaveClickedOnAssetModalForCreate(e));
  }

  private onSaveClickedOnAssetModalForCreate = (asset: Asset): void => {
    this.mortgage.assets?.push(asset);
    if (this.assetListComponent) {
      this.assetListComponent.assets = this.mortgage.assets;
      this.alert = null;
    }
  }
}
