export class PortalContent {

  companyId: number | undefined;
  userId: string | undefined;
  aboutUsContent: string = '';
  referralContent: string | undefined;
  showReferralTerms: boolean | undefined;
  referralTermsContent: string | undefined;
  showPrequalLetterGeneration: boolean | undefined;
  readonly isGoogleAddressesIntegrationDisabled: boolean | null;  
  readonly isAssetVerificationLoanActionEnabled : boolean | null;
  readonly isMortgageCalculatorLoanActionEnabled : boolean | null;
  readonly isEmploymentIncomeVerificationLoanActionEnabled : boolean | null;
  readonly isUploadingAdditionalDocumentsLoanActionEnabled : boolean | null;
  readonly isPreApprovalLetterGenerationLoanActionEnabled : boolean | null;
  
  constructor() {
  }
}

