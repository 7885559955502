import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EnumerationItem } from 'projects/shared/models/enumeration-item.model';
import { v4 as uuidv4 } from 'uuid';
import { Address } from '../../../models/address-model';
import { BorrowerCounselingEvent } from '../../../models/borrower-counseling-event.model';
import { Borrower } from '../../../models/borrower-model';
import { EnumsService } from '../../../services/enums.service';
import { formViewProvider } from '../../../services/form-view.provider';


@Component({
  selector: 'borrower-counseling-event',
  templateUrl: './borrower-counseling-event.component.html',
  styleUrls: ['./borrower-counseling-event.component.scss'],
  viewProviders: [formViewProvider],
})
export class BorrowerCounselingEventComponent implements OnInit {

  @Input()
  borrower!: Borrower;

  @Input()
  borrowerCounselingFormatTypes: EnumerationItem[] = [];

  @Input()
  event: BorrowerCounselingEvent;

  @Output()
  attemptedToDeleteEvent: EventEmitter<void> = new EventEmitter<void>();

  protected stateOptions: EnumerationItem[] = [];
  protected componentId: string;
  protected eventToDelete: BorrowerCounselingEvent;

  constructor(private readonly _enumsService: EnumsService) {
    this.componentId = uuidv4();
  }

  ngOnInit(): void {
    this.stateOptions = this._enumsService.states;
  }

  protected onDeleteClicked() {
    this.attemptedToDeleteEvent.emit();
  }

  protected onCompletionDateChanged() {
    this.event.borrowerHasCompleted = false;
    if (this.event.completedDate) {
      this.event.borrowerHasCompleted = true;
    }
  }

  protected onCounselingAgencyAddressChanged = (address: Address) => {
    this.event.counselingAgencyAddress1 = address.address1;
    this.event.counselingAgencyCity = address.city;
    this.event.counselingAgencyZipCode = address.zipCode;
    this.event.counselingAgencyState = address.state;
  }

  protected setGooglePickedAddress(address: Address) {
    this.event.counselingAgencyAddress1 = address.address1;
    this.event.counselingAgencyCity = address.city;
    this.event.counselingAgencyState = address.state;
    this.event.counselingAgencyZipCode = address.zipCode;
  }

  protected setManuallyEnteredAddress(address: string) {
    this.event.counselingAgencyAddress1 = address;
  }

  onEventDeleteClicked = () => {
    this.eventToDelete = this.event;
  }

  onEventDeleteCancelClicked = () => {
    this.eventToDelete = undefined;
  }

  onEventDeleteConfirmClicked = () => {
    this.onDeleteClicked();
    this.eventToDelete = undefined;
  }

}
