import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CharacteristicMergeValue } from '../../models/characteristics/characteristic-merge-value.model';
import { ICharacteristic } from '../../models/characteristics/characteristic.interface';
import { TaskCategory } from '../../models/characteristics/task-category.model';
import { ICharacteristicFactory } from './characteristic-factory.interface';
import { LoanCharacteristicFactory } from './loan-characteristic.factory';
import * as _ from 'lodash';

@Component({
    selector: 'characteristics-quantities-dialog',
    templateUrl: 'characteristics-quantities-dialog.component.html',
    styleUrls: ['characteristics-quantities-dialog.component.scss']
})

export class CharacteristicsQuantitiesDialogComponent implements OnInit {

    @Input()
    set taskCategory(taskCategory: TaskCategory | undefined) {
      this._taskCategory = taskCategory;
      if (this._taskCategory) {
        this.title = (this._taskCategory.characteristicType === 'LoanCharacteristic' ? 'Loan ': 'Borrower ') + 'Characteristics Quantities';
      }
    }

    @Input()
    set characteristics (characteristics: ICharacteristic[]) {
      this._characteristics = _.cloneDeep(characteristics);
    }

    get characteristics() {
      return this._characteristics;
    }

    @Input()
    nextCharacteristicId: number = -1;;

    @Input()
    applicationId: number | undefined;

    @Input()
    ownerId: number | undefined;

    @Input()
    characteristicFactory: ICharacteristicFactory;

    get taskCategory(): TaskCategory | undefined {
      return this._taskCategory;
    }

    title: string = "";

    private _taskCategory: TaskCategory | undefined;

    private _characteristics: ICharacteristic[] = [];

    constructor(public activeModal: NgbActiveModal) {
      this.characteristicFactory = new LoanCharacteristicFactory();
    }

    ngOnInit() {}

    onAddClicked = () => {
      if (this._taskCategory && this.applicationId) {
        const newOne = this.characteristicFactory.create(this.nextCharacteristicId--, this.applicationId, this._taskCategory.taskCategoryId, this.ownerId);
        this._taskCategory.fields.forEach(f => {
          newOne.characteristicMergeValues.push(new CharacteristicMergeValue(f.characteristicFieldId));
        });
        this.characteristics.push(newOne);
      }
    }

    onUpdateClicked = () => {
      this.activeModal.close(this._characteristics.filter(c => !c.pendingDeletion));
    }
}
