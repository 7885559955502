import { Component, Injector, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { BorrowerCreditScores } from '../../../models/borrower-credit-scores.model';
import { Borrower } from '../../../models/borrower-model';
import { CreditService } from '../../../services/credit.service';
import { WizardStepComponentBase } from '../wizard-step-base.component';

@Component({
  selector: 'credit-scores-step',
  templateUrl: 'credit-scores-step.component.html'
})
export class CreditScoresStepComponent extends WizardStepComponentBase<any> implements OnInit {

  borrowers: Borrower[] = [];

  allBorrowerCreditScores: BorrowerCreditScores[] = [];

  isCheckingCreditHistory: boolean = false;

  mainBorrower!: Borrower;
  coBorrowers: Borrower[] = [];

  constructor(private readonly _injector: Injector, private readonly _creditService: CreditService) {
    super(_injector);
    this.borrowers = this.mortgageApplication.borrowers;
    if (this.wizardFlowService.isEditEnabled) {
      this.createFakeDataForAdmin();
    }
    if (this.currentBorrower) {
      this.mainBorrower = this.currentBorrower;
      this.coBorrowers = this.mortgageApplication.borrowers.filter(b => b.primaryEmail.toLocaleLowerCase() !==
        this.mainBorrower.primaryEmail.toLocaleLowerCase());
    }
  }

  ngOnInit() {
    if (this.wizardFlowService.isEditEnabled) {
      return;
    }
    this.startSpinner();
    this.isCheckingCreditHistory = true;
    this._creditService.getCreditScores(this.mortgageApplication.applicationId).pipe(
      finalize(() => {
        this.isCheckingCreditHistory = false;
        this.stopSpinner();
        window.scroll(1, 1);
      })
    )
      .subscribe(
        response => {
          let scores = response.filter(r => r.refNumber != null).sort((a, b) =>
            (a.refNumber && b.refNumber && a.refNumber < b.refNumber) ? 1 : -1);
          const creditScoresForAllBorrowers: BorrowerCreditScores[] = [];
          this.borrowersToApplyFor().forEach(borrower => {
            const borrowerScores = scores.filter(b => b.borrowerId === borrower.contactId);
            if (borrowerScores) {
              creditScoresForAllBorrowers.push(borrowerScores[0]);
            }
          });

          this.allBorrowerCreditScores = creditScoresForAllBorrowers;
          this.wizardFlowService.creditScores = this.allBorrowerCreditScores;
        },
        error => {

        }
      )
  }

  private createFakeDataForAdmin() {

    this.mortgageApplication.borrowers.forEach(borrower => {
      let borrowerCreditScore = new BorrowerCreditScores();
      borrowerCreditScore.borrowerName = borrower.firstName + " " + borrower.lastName;
      borrowerCreditScore.equifax = 750;
      borrowerCreditScore.experian = 620;
      borrowerCreditScore.transUnion = 400;
      this.allBorrowerCreditScores.push(borrowerCreditScore);
    });
  }
}
