import { Borrower } from "../models/borrower-model";
import { MortgageApplication } from "./mortgage-app.model";
import { WizardFlowCustomerPreferences } from "./wizard-flow-customer-preferences.model";

export class WizardFlowDecisionContext {
  mortgage: MortgageApplication | undefined;
  mainBorrower: Borrower | undefined;
  customerPreferences: WizardFlowCustomerPreferences | undefined;
  borrowersThatMainBorrowerIsApplyingFor: Borrower[] = [];
  creditRunFailed: boolean = false;
  skipCreditCheck: boolean = false;
  skipAutomatedCreditCheck: boolean = false;
  thereAreLoanCharacteristics: boolean = false;
  thereAreBorrowerCharacteristics: boolean = false;
  hasSuccessfullyPulledExistingApplication: boolean = false;
  loanPurposeId: number | undefined;
}

