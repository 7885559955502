import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StepConfig } from '../../models/wizard/config/step-config.model';

declare const $: any;

@Component({
  selector: 'step-selector',
  templateUrl: 'step-selector.component.html'
})

export class StepSelectorComponent implements OnInit {

  @Input()
  steps: StepConfig[] = [];

  @Output()
  createStepClicked: EventEmitter<any> = new EventEmitter<any>();

  divStyle: string = "1";

  handleDragStart(ev :any) {
    ev.dataTransfer.setData("text", ev.target.id);
    this.divStyle = '0.4';
  }

  handleDragEnd(ev :any) {
    ev.dataTransfer.setData("text", ev.target.id);
    this.divStyle = '1';
  }

  onCreateClicked = () => {
    this.createStepClicked.emit();
  }

  constructor() {
    // for (var stepType in StepType) {
    //   if (isNaN(Number(stepType))) {
    //     this.stepTypeNames.push(stepType.replace(/([a-z])([A-Z])/g, '$1 $2'));
    //   }
    // }
  }

  ngOnInit() { }
}

