export class WizardNavGroup {
  id: number | undefined;
  title: string = '';
  svgIconName: string = '';
  iconClass: string = '';

  constructor(
    id: number,
    title: string,
    svgIconName: string,
    iconClass: string
  ) {
    this.id = id;
    this.title = title;
    this.svgIconName = svgIconName;
    this.iconClass = iconClass;
  }
}
