<div class="modal-header" *ngIf="employment">

  <h2>Monthly Income</h2>

  <!-- hack to fool ngbModal autofocus -->
  <input type="text" style="display:none" />

  <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" aria-label="Close"
    (click)="activeModal.dismiss('Cross click')">
    <span class="svg-icon svg-icon-2x">
      <close-x-svg-icon></close-x-svg-icon>
    </span>
  </div>

</div>

<div class="modal-body px-lg-17 py-10">
  <form #incomeForm="ngForm" class="form" novalidate id="incomeForm" name="incomeForm">
    <ng-container *ngIf="employment?.incomes?.length">
      <div class="row">
        <span class="col-6">Income Type</span>
        <span class="col-5">Monthly Amount</span>
      </div>
      <div class="row mt-3" *ngFor="let income of employment.incomes">
        <div class="col-6">
          <select class="form-select form-select-solid" data-control="select2" [name]="'income_type_' + income.incomeId"
            [(ngModel)]="income.typeOfIncome" #incomeTypeValue="ngModel" [required]="true"
            [ngClass]="{'is-invalid' : incomeTypeValue && incomeTypeValue.touched && incomeTypeValue.invalid}">
            <option [ngValue]="null">--Select one--</option>
            <option *ngFor="let incomeType of employmentIncomeTypes" [ngValue]="incomeType.value">
              {{incomeType.name}}
          </select>
        </div>
        <div class="col-6 d-flex">
          <currency-input [(ngModel)]="income.monthlyIncome" [name]="'monthly_amount_' + income.incomeId"
            [allowDecimals]="false" [min]="0" [placeholder]="'Monthly Amount'" (blur)="onIncomeChanged()">
          </currency-input>

          <a href="javascript:void(0);" *ngIf="deleteAttemptedForIncomeId != income.incomeId"
            class="btn btn-icon btn-bg-light btn-active-color-danger btn-sm ms-2 mt-1"
            style="min-width: 33.3281px;"
            (click)="onDeleteIncomeClicked(income)">
            <span class="svg-icon svg-icon-3">
              <duotone-trash-svg-icon></duotone-trash-svg-icon>
            </span>
          </a>
          <div class="d-flex ms-2 mt-1" *ngIf="deleteAttemptedForIncomeId == income.incomeId">
            <a href="javascript:void(0);" class="btn btn-icon btn-bg-light btn-active-color-success btn-sm me-1"
              (click)="onDeleteConfirmed(income)">
              <span class="svg-icon svg-icon-3">
                <stockholm-check-svg-icon></stockholm-check-svg-icon>
              </span>
            </a>
            <a href="javascript:void(0);" class="btn btn-icon btn-bg-light btn-active-color-danger btn-sm"
              (click)="onDeleteAborted(income)">
              <span class="svg-icon svg-icon-3">
                <stockholm-close-svg-icon></stockholm-close-svg-icon>
              </span>
            </a>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <span class="col-6"></span>
        <h5 class="col-6">Total Monthly: {{(totalMonthly || 0) | currency}}</h5>
      </div>
    </ng-container>
    <ng-container *ngIf="!employment?.incomes?.length">
      <h4>You have no incomes added.</h4>
    </ng-container>
    <div class="row mt-3">
      <div class="col-6">
        <a class="btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary mb-4"
          (click)="addIncomeClicked()">
          {{employment.incomes.length === 0 ? 'Add Income' : 'Add Another Income'}}
        </a>
      </div>
      <span class="col-6"></span>
    </div>
  </form>
</div>

<div class="modal-footer flex-center">
  <button type="button" class="btn btn-white me-3" (click)="activeModal.dismiss('Close click')">Cancel</button>
  <button type="button" class="btn btn-primary" (click)="onOkClicked()">OK</button>
</div>