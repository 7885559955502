import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'flow-config-save-dialog',
  templateUrl: 'flow-config-save-dialog.component.html'
})

export class FlowConfigSaveDialogComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) {
  }

  title: string = "";
  comment: string = "";
  guid: string | undefined = undefined;
  createNew: boolean = true;

  @ViewChild('configDialogForm') configDialogForm: NgForm | undefined;

  @Output()
  saveClickedEmitterForEdit = new EventEmitter();

  @Output()
  saveClickedEmitterForCreate = new EventEmitter();

  ngOnInit() {
    this.createNew = this.guid === undefined || this.guid.length === 0;
  }

  onSaveClicked = () => {
    this.configDialogForm?.form.markAllAsTouched();
    if (this.configDialogForm?.form.valid) {
      this.activeModal.close('Save click');
      if (this.createNew) {
        this.saveClickedEmitterForCreate.emit({title: this.title, comment: this.comment});
      } else {
        this.saveClickedEmitterForEdit.emit({title: this.title, comment: this.comment});
      }
    }
  }
}
