<div style="min-height: 500px;">
  <div class="w-100">
    <div class="pb-10 pb-lg-15">
      <h2 class="fw-bolder d-flex align-items-center text-dark">How will your spouse be part of this transaction?
      </h2>
    </div>
    <div class="fv-row">
      <div class="row">
        <div class="col-lg-12 pb-4">
          <input type="radio" [checked]="isSpouseOnLoan" class="btn-check" (click)="onSpouseWillBeOnLoanSelected()" name="willBeOnLoan" value="willBeOnLoan" id="willBeOnLoan" />
          <label class="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center" for="willBeOnLoan">
            <!--begin::Svg Icon | path: assets/media/icons/stockholm/Communication/Add-user.svg-->
            <span class="svg-icon svg-icon-3x me-5">
              <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <path d="M18,8 L16,8 C15.4477153,8 15,7.55228475 15,7 C15,6.44771525 15.4477153,6 16,6 L18,6 L18,4 C18,3.44771525 18.4477153,3 19,3 C19.5522847,3 20,3.44771525 20,4 L20,6 L22,6 C22.5522847,6 23,6.44771525 23,7 C23,7.55228475 22.5522847,8 22,8 L20,8 L20,10 C20,10.5522847 19.5522847,11 19,11 C18.4477153,11 18,10.5522847 18,10 L18,8 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                <path d="M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fill-rule="nonzero"/>
              </svg>
            </span>
            <!--end::Svg Icon-->
            <span class="d-block fw-bold text-start" style="height: 60px; padding-top: 20px;">
              <span class="text-dark fw-bolder d-block fs-4 mb-2">They Will be on Loan with Me</span>
            </span>
          </label>
        </div>
      </div>
    </div>
    <div class="fv-row">
      <div class="row">
        <div class="col-lg-12 pb-4">
          <input type="radio" [checked]="isSpouseOnTitle" class="btn-check" (click)="onSpouseWillBeOnTitleSelected()" name="willBeOnTitle" value="willBeOnTitle" id="willBeOnTitle" />
          <label class="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center" for="willBeOnTitle">
            <span class="svg-icon svg-icon-3x me-5">
              <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <path d="M18,2 L20,2 C21.6568542,2 23,3.34314575 23,5 L23,19 C23,20.6568542 21.6568542,22 20,22 L18,22 L18,2 Z" fill="#000000" opacity="0.3"/>
                <path d="M5,2 L17,2 C18.6568542,2 20,3.34314575 20,5 L20,19 C20,20.6568542 18.6568542,22 17,22 L5,22 C4.44771525,22 4,21.5522847 4,21 L4,3 C4,2.44771525 4.44771525,2 5,2 Z M12,11 C13.1045695,11 14,10.1045695 14,9 C14,7.8954305 13.1045695,7 12,7 C10.8954305,7 10,7.8954305 10,9 C10,10.1045695 10.8954305,11 12,11 Z M7.00036205,16.4995035 C6.98863236,16.6619875 7.26484009,17 7.4041679,17 C11.463736,17 14.5228466,17 16.5815,17 C16.9988413,17 17.0053266,16.6221713 16.9988413,16.5 C16.8360465,13.4332455 14.6506758,12 11.9907452,12 C9.36772908,12 7.21569918,13.5165724 7.00036205,16.4995035 Z" fill="#000000"/>
              </svg>
            </span>
            <span class="d-block fw-bold text-start" style="height: 60px; padding-top: 20px;">
              <span class="text-dark fw-bolder d-block fs-4 mb-2">They will be only on Title</span>
            </span>
          </label>
        </div>
      </div>
    </div>
    <div class="fv-row mb-10">
      <div class="row">
        <div class="col-lg-12 pb-4">
          <input type="radio" [checked]="isSpouseNotOnLoanOrTitle" class="btn-check" (click)="onSpouseWillNotBeOnLoanOrTitleSelected()" name="willNotBeAPartOfTxn" value="willNotBeAPartOfTxn" id="willNotBeAPartOfTxn" />
          <label class="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center" for="willNotBeAPartOfTxn">
            <!--begin::Svg Icon | path: assets/media/icons/stockholm/Communication/Delete-user.svg-->
            <span class="svg-icon svg-icon-3x me-5">
              <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <path d="M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z M21,8 L17,8 C16.4477153,8 16,7.55228475 16,7 C16,6.44771525 16.4477153,6 17,6 L21,6 C21.5522847,6 22,6.44771525 22,7 C22,7.55228475 21.5522847,8 21,8 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                <path d="M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fill-rule="nonzero"/>
              </svg>
            </span>
            <!--end::Svg Icon-->
            <span class="d-block fw-bold text-start" style="height: 60px; padding-top: 20px;">
              <span class="text-dark fw-bolder d-block fs-4 mb-2">They will not be on Loan or on Title
                </span>
            </span>
          </label>
        </div>
      </div>
    </div>

    <wizard-button-group [nextEnabled]="isSpouseTransactionInvolvementSelected"></wizard-button-group>
  </div>
</div>
