import {LinkedDocumentModel} from "./linked-document-model";

export class ApplicationTaskModel {
  taskId: number = -1;
  taskTypeId: string | undefined;
  borrowerTaskType: BorrowerTaskType | undefined;
  description: string | undefined;
  docType: string | undefined;
  docSortOrder: number | undefined;
  submittedDate: Date | undefined;
  approvedDate: Date | undefined;
  dueDate: Date = new Date();
  templateDocumentUrl: string | undefined;
  borrowerId: number | undefined;
  borrowerName: string | undefined;
  borrowerFacingNote: string | undefined;
  borrowerTaskStatus: BorrowerTaskStatus | undefined;
  linkedDocuments: LinkedDocumentModel[] | undefined;
  allowUpload: boolean | undefined;
  isAgentTask: boolean | undefined;
  allowMarkNA: boolean | undefined;
  signingLinkSentInEmail: boolean | null;
  htmlWidgetSrc?: string | null;

  constructor() {
  }
}

export enum BorrowerTaskStatus {
  Pending = "Pending",
  Submitted = "Submitted",
  Rejected = "Rejected",
  Completed = "Completed"
}

export enum BorrowerTaskType {
  RequestDocument = "RequestDocument",
  ViewDocument = "ViewDocument",
  AcknowledgeReceipt = "AcknowledgeReceipt",
  EsignDocument = "EsignDocument",
  RequestInformation = "RequestInformation",
  PerformAction = "PerformAction",
  OnlineApplication = "OnlineApplication",
  DigitalAssetVerification = "DigitalAssetVerification",
  DigitalIncomeEmploymentVerification = "DigitalIncomeEmploymentVerification",
  GenerateDocument = "GenerateDocument",
  RequestPayoff = "RequestPayoff",
  RequestHoi = "RequestHoi",
  RequestTitle = "RequestTitle",
  RequestEscrow = "RequestEscrow",
  RequestAppraisal = "RequestAppraisal",
  LosEsign = "LosEsign",
  WetSignDocument = "WetSignDocument",
  LoanBeamSync = "LoanBeamSync",
  GatherPayment = "GatherPayment",
  ChangeOfCircumstance = "ChangeOfCircumstance",
  Escalation = "Escalation"
}
