import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from '../data.service';
import { CheckListConfig, QuestionSummary } from '../../models/checklists/config/checklist-config.model';
import { ChecklistQuestion } from '../../models/checklists/borrower-flow/checklist-question.model';
import { ChecklistAnswer } from '../../models/checklists/borrower-flow/checklist-answer.model';

@Injectable()
export class ChecklistsService {
  constructor(private readonly _dataService: DataService) {}

  getChecklists = (): Observable<CheckListConfig[]> => {
    const url = `api/admin/checklist-config/checklist/all?includeInactive=false`;
    return this._dataService.get(url);
  };

  getChecklist = (checklistId: number): Observable<CheckListConfig> => {
    const url = `api/online-app/checklist/config/${checklistId}`;
    return this._dataService.get(url);
  };

  getChecklistQuestionsForApplication = (checklistId: number, applicationId: number): Observable<ChecklistQuestion> => {
    const url = `api/online-app/checklists/${checklistId}/for-app/${applicationId}`;
    return this._dataService.get(url);
  }

  getAllQuestionsWithAnswers = (): Observable<QuestionSummary[]> => {
    const url = 'api/online-app/question-answer/group-by-question';
    return this._dataService.get(url);
  }

  insertQuestionAnswer = (checklistId: number, answer: ChecklistAnswer): Observable<any> => {
    const url = `api/online-app/checklists/${checklistId}/answers`;
    return this._dataService.post(url, answer);
  }

  updateQuestionAnswer = (checklistId: number, answer: ChecklistAnswer): Observable<any> => {
    const url = `api/online-app/checklists/${checklistId}/answers/${answer.checklistAnswerId}`;
    return this._dataService.post(url, answer);
  }

  deleteQuestionAnswer = (checklistId: number, checklistAnswerId: number): Observable<any> => {
    const url = `api/online-app/checklists/${checklistId}/answers/${checklistAnswerId}`;
    return this._dataService.delete(url);
  }

  bulkUpdateQuestionAnswers = (checklistId: number, answers: ChecklistAnswer[]): Observable<any> => {
    const url = `api/checklist/${checklistId}/answers/bulk`;
    return this._dataService.post(url, answers);
  }
}
