<ng-container *ngIf="config">
  <form class="form-horizontal" #expressionForm="ngForm" novalidate id="expressionForm">
    <div class="rounded border px-4 py-4 mb-4">
      <div class="row mb-4">
        <div class="col-md-2">
          <label class="form-label px-4 pt-4">IF</label>
        </div>
        <div class="col-md-10">
          <expression-editor [name]="'ifConditionExpression'" [(ngModel)]="config!.ifStepExpression!.ifCondition"></expression-editor>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-md-2">
          <label class="form-label px-4 pt-4">Goto</label>
        </div>
        <div class="col-md-10">
          <select name="stepToGoForIf" id="stepToGoForIf" required data-placeholder="Choose one" #stepToGoForIf="ngModel"
            [(ngModel)]="config!.ifStepExpression!.goToPath" (ngModelChange)="onConfigChanged()"
            class="form-select form-select-solid"
            [ngClass]="{'is-invalid' : stepToGoForIf && stepToGoForIf.touched && stepToGoForIf.invalid}">
            <option value="">Select one</option>
            <option value="{{step.path}}" *ngFor="let step of flow.steps">{{step.name}}</option>
          </select>
          <div class="invalid-feedback">This field is required.</div>
        </div>
      </div>
    </div>

    <div class="rounded border px-4 py-4 mb-4" *ngFor="let expression of config!.elseIfStepExpressions; let i = index">
      <div class="row mb-4">
        <div class="col-md-2">
          <label class="form-label px-4 pt-4">ELSE IF</label>
        </div>
        <div class="col-md-10">
          <expression-editor [name]="'elseIfConditionExpression' + i" [(ngModel)]="expression!.ifCondition" (ngModelChange)="onConfigChanged()"></expression-editor>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-md-2">
          <label class="form-label px-4 pt-4">Goto</label>
        </div>
        <div class="col-md-10">
          <select name="stepToGoForElseIf{{i}}" id="stepToGoForElseIf{{i}}" required data-placeholder="Choose one" #stepToGoForElseIf="ngModel"
            [(ngModel)]="expression!.goToPath" (ngModelChange)="onConfigChanged()" required
            class="form-select form-select-solid"
            [ngClass]="{'is-invalid' : stepToGoForElseIf && stepToGoForElseIf.touched && stepToGoForElseIf.invalid}">
            <option value="">Select one</option>
            <option value="{{step.path}}" *ngFor="let step of flow.steps">{{step.name}}</option>
          </select>
          <div class="invalid-feedback">This field is required.</div>
        </div>
      </div>
    </div>

    <div class="rounded border px-4 py-4 mb-4">
      <div class="row mb-4">
        <div class="col-md-2">
          <label class="form-label px-4 pt-4">ELSE Goto</label>
        </div>
        <div class="col-md-10">
          <select name="stepToGoForElse" id="stepToGoForElse" required data-placeholder="Choose one" #elseStepPath="ngModel"
            [(ngModel)]="config!.elseStepPath" (ngModelChange)="onConfigChanged()" required
            class="form-select form-select-solid"
            [ngClass]="{'is-invalid' : elseStepPath && elseStepPath.touched && elseStepPath.invalid}">
            <option value="">Select one</option>
            <option value="{{step.path}}" *ngFor="let step of flow.steps">{{step.name}}</option>
          </select>
          <div class="invalid-feedback">This field is required.</div>
        </div>
      </div>
    </div>
  </form>
  <div class="row mb-6" [hidden]="!showButtons">
    <div class="col-md-12 fv-row d-flex justify-content-center">
      <a (click)="onCancelClicked()" class="btn btn-sm btn-light">Cancel</a>
      <a (click)="onOkClicked()" class="btn btn-sm btn-primary mx-4">OK</a>
    </div>
  </div>
</ng-container>



