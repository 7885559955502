import { IDecideNextStep } from "../../services/wizard/next-step-decider/next-step-decider.interface";
import { ReviewApplicationStepComponent } from "../../wizard/wizard-steps/review-application-step/review-application-step.component";
import { WizardStepBase } from "./wizard-step-base.model";

export class ReviewApplicationStep extends WizardStepBase {

  allowEditingOfSubjectProperty: boolean = true;

  showSubjectPropertyDetails: boolean = false;

  loanDetailsSectionTitle: string = "Loan Details";

  override get hasNextStep(): boolean {
    return true;
  }

  constructor(id: string, name: string, path: string, nextStepDecider?: IDecideNextStep) {
    super(id, name, path, nextStepDecider);
    this.component = ReviewApplicationStepComponent;
  }
}
