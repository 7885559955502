

  <div class="modal-header">

    <h2>{{header}}</h2>

    <!-- hack to fool ngbModal autofocus -->
    <input type="text" style="display:none" />

    <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" aria-label="Close"
      (click)="activeModal.dismiss()">
      <span class="svg-icon svg-icon-2x">
        <close-x-svg-icon></close-x-svg-icon>
      </span>
    </div>
  </div>

  <div class="modal-body py-10 px-lg-17">
    <form #reoInfoForm="ngForm" class="form" novalidate id="reoInfoForm" name="reoInfoForm">
      <div class="me-n7 pe-7">
        <address #addressComponent [address]="address"></address>
        <div class="row mb-8">
          <div class="col-md-6 fv-row" *ngIf="!isHidden('reo.typeOfProperty')">
            <label class="form-label mb-2" [ngClass]="{'required': isRequired('reo.typeOfProperty')}">{{getLabel('reo.typeOfProperty', 'Property Type')}}</label>
            <select name="propertyType" [ngClass]="{'is-invalid' : propertyType && propertyType.touched && propertyType.invalid}"
              [required]="isRequired('reo.typeOfProperty')"
              #propertyType="ngModel" class="form-select form-select-solid" [(ngModel)]="reo!.typeOfProperty"
              data-control="select2" data-hide-search="true" data-placeholder="Choose one">
              <option [ngValue]="null">Select one...</option>
              <option #reoItem *ngFor="let propertyType of propertyTypes; let i = index" value="{{propertyType.value}}">{{propertyType.name}}</option>
            </select>
            <div class="invalid-feedback">Property Type is required.</div>
          </div>
          <div class="col-md-6 fv-row" *ngIf="!isHidden('reo.marketValue')">
            <label class="form-label mb-2" [ngClass]="{'required': isRequired('reo.marketValue')}">{{getLabel('reo.marketValue', 'Property Value')}}</label>
            <input class="form-control form-control-solid" mask="separator"  thousandSeparator="," prefix="$"
              [required]="isRequired('reo.marketValue')"
              (change)="onPropertyValueChanged()"
              [ngClass]="{'is-invalid' : propertyValue && propertyValue.touched && propertyValue.invalid}"
              #propertyValue="ngModel" [(ngModel)]="reo!.marketValue" placeholder="" name="propertyValue" />
            <div class="invalid-feedback">Property Value is required.</div>
          </div>
        </div>
        <div class="row mb-8">
          <div class="col-md-6 fv-row" *ngIf="!isHidden('reo.purchasePrice')">
            <label class="form-label mb-2" [ngClass]="{'required': isRequired('reo.purchasePrice')}">{{getLabel('reo.purchasePrice', 'Original Purchase Price')}}</label>
            <input class="form-control form-control-solid" mask="separator" thousandSeparator="," prefix="$"
              [required]="isRequired('reo.purchasePrice')"
              (change)="onPurchasePriceChanged()"
              [ngClass]="{'is-invalid' : purchasePrice && purchasePrice.touched && purchasePrice.invalid}"
              #purchasePrice="ngModel" [(ngModel)]="reo!.purchasePrice" placeholder="" name="purchasePrice" />
          <div class="invalid-feedback">Purchase Price is required.</div>
          </div>
          <div class="col-md-6 fv-row" *ngIf="!isHidden('reo.dateAcquired')">
            <label class="form-label mb-2" [ngClass]="{'required': isRequired('reo.dateAcquired')}">{{getLabel('reo.dateAcquired', 'Date Acquired')}}</label>
            <div class="position-relative d-flex align-items-center">
              <input class="form-control form-control-solid" matInput mask="M0/d0/0000" [showMaskTyped]="true" autocomplete="off"
                [specialCharacters]="[ '/' ]" inputmode="numeric" [dropSpecialCharacters]="false" datePicker #dateAcquired="ngModel"
                [(ngModel)]="reo.dateAcquired" placeholder="" name="dateAcquired" ngControl="dateAcquired" id="dateAcquired"
                [ngClass]="{'is-invalid' : dateAcquired && dateAcquired.touched && dateAcquired.invalid}"
                [required]="isRequired('reo.dateAcquired')"/>
              <div class="invalid-feedback">Date Acquired is required.</div>
            </div>
          </div>
        </div>
        <div class="row mb-8">
          <div class="col-md-4 fv-row" *ngIf="!isHidden('reo.currentUsageType')">
            <label class="form-label mb-2" [ngClass]="{'required': isRequired('reo.currentUsageType')}">{{getLabel('reo.currentUsageType', 'How do you currently use this property?')}}</label>
            <select name="currentUsageType" [ngClass]="{'is-invalid' : currentUsageType && currentUsageType.touched && currentUsageType.invalid}"
              [required]="isRequired('reo.currentUsageType')"
              #currentUsageType="ngModel" class="form-select form-select-solid" [(ngModel)]="reo!.currentUsageType"
              data-control="select2" data-hide-search="true" data-placeholder="Choose one">
            <option [ngValue]="null">Select one...</option>
            <option #reoItem *ngFor="let usageType of usageTypes; let i = index" value="{{usageType.value}}">{{usageType.name}}</option>
          </select>
          <div class="invalid-feedback">This field is required.</div>
          </div>

          <div class="col-md-4 fv-row">
            <label class="form-label mb-2 required">Do you plan on selling this property?</label>
            <select
              name="dispositionStatus"
              id="dispositionStatus"
              [ngClass]="{'is-invalid' : willPropertyBeSoldValue && willPropertyBeSoldValue.touched && willPropertyBeSoldValue.invalid}"
              [required]="true"
              #willPropertyBeSoldValue="ngModel" class="form-select form-select-solid"
              [(ngModel)]="willPropertyBeSold"
              (ngModelChange)="onPropertyWillBeSoldChanged()"
              data-control="select2" data-hide-search="true" data-placeholder="Choose one">
            <option value="no">No</option>
            <option value="yes">Yes</option>
          </select>
          <div class="invalid-feedback">This field is required.</div>
          </div>

          <div class="col-md-4 fv-row" *ngIf="!isHidden('reo.intendedUsageType') && willPropertyBeSold === 'no'">
            <label class="form-label mb-2" [ngClass]="{'required': isRequired('reo.intendedUsageType')}">{{getLabel('reo.intendedUsageType', 'How do you plan on using this property?')}}</label>
            <select name="intendedUsageType" [ngClass]="{'is-invalid' : intendedUsageType && intendedUsageType.touched && intendedUsageType.invalid}"
              [required]="isRequired('reo.intendedUsageType')"
              #intendedUsageType="ngModel" class="form-select form-select-solid" [(ngModel)]="reo!.intendedUsageType"
              data-control="select2" data-hide-search="true" data-placeholder="Choose one">
            <option [ngValue]="null">Select one...</option>
            <option #reoItem *ngFor="let usageType of usageTypes; let i = index" value="{{usageType.value}}">{{usageType.name}}</option>
          </select>
          <div class="invalid-feedback">This field is required.</div>
          </div>
        </div>
        <div class="row mb-8">
          <div class="col-md-4 fv-row" *ngIf="!isHidden('reo.amountOfMortgage')">
            <label class="form-label mb-2" [ngClass]="{'required': isRequired('reo.amountOfMortgage')}">{{getLabel('reo.amountOfMortgage', 'Current balance of existing liens on this property?')}}</label>
            <input class="form-control form-control-solid" mask="separator" thousandSeparator="," inputmode="decimal" prefix="$"
              [ngClass]="{'is-invalid' : amountOfMortgage && amountOfMortgage.touched && amountOfMortgage.invalid}"
              [required]="isRequired('reo.amountOfMortgage')"
              #amountOfMortgage="ngModel" [(ngModel)]="reo!.amountOfMortgage" placeholder="" name="amountOfMortgage" />
            <div class="invalid-feedback">This field is required.</div>
          </div>
          <div class="col-md-4 fv-row" *ngIf="!isHidden('reo.mortgagePayment')">
            <label class="form-label mb-2" [ngClass]="{'required': isRequired('reo.mortgagePayment')}">{{getLabel('reo.mortgagePayment', 'Monthly Payment')}}</label>
            <input class="form-control form-control-solid" mask="separator" thousandSeparator="," inputmode="decimal" prefix="$"
              [ngClass]="{'is-invalid' : mortgagePayment && mortgagePayment.touched && mortgagePayment.invalid}"
              [required]="isRequired('reo.mortgagePayment')"
              #mortgagePayment="ngModel" [(ngModel)]="reo!.mortgagePayment" placeholder="" name="mortgagePayment" />
            <div class="invalid-feedback">This field is required.</div>
          </div>
          <div class="col-md-4 fv-row" *ngIf="!isHidden('reo.monthlyMiscExpenses')">
            <label class="form-label mb-2 me-2" [ngClass]="{'required': isRequired('reo.monthlyMiscExpenses')}">{{getLabel('reo.monthlyMiscExpenses', 'Insurance, HOA, Tax')}}</label>
            <button class="icon-button"
              title="Monthy Expense Breakdown"
              #popover="ngbPopover"
              data-bs-toggle="tooltip"
              [ngbPopover]="popoverContent"
              popoverClass="custom-popover"
              popoverTitle="Monthy Expense Breakdown"
              [autoClose]="'outside'"
              triggers="manual">
              <i class="fas fa-calculator text-primary" (click)="onOpenReoExpenseBreakdownClicked()"></i>
            </button>
            <ng-template #popoverContent let-reo="reo">
              <reo-expense-breakdown
                #reoExpenseBreakdown
                [reo]="reo"
                (cancelClicked)="popover.close()"
                (okClicked)="onOkClickedOnReoBreakdownPopover($event); popover.close();"
              ></reo-expense-breakdown>
            </ng-template>
            <input class="form-control form-control-solid" mask="separator" thousandSeparator="," inputmode="decimal" prefix="$"
              [ngClass]="{'is-invalid' : monthlyMiscExpenses && monthlyMiscExpenses.touched && monthlyMiscExpenses.invalid}"
              [required]="isRequired('reo.monthlyMiscExpenses')"
              #monthlyMiscExpenses="ngModel" [(ngModel)]="reo['totalExpenses']"
              [disabled]="isExpensesBrokenDown"
              (blur)="onTotalExpensesChanged()"
              placeholder="" name="monthlyMiscExpenses" />
            <div class="invalid-feedback">This field is required.</div>
          </div>
        </div>
        <div class="row mb-5" *ngIf="!inCoApplyFlow">
          <div class="col-md-12 fv-row">
            <label class="required form-label mb-2">Who does this real estate belong to?</label>
            <div class="pl-20">
              <multi-checkbox-picker [possibleChoices]="possibleBorrowers" [selectedItems]="selectedBorrowers"  [disabledChoices]="disabledBorrowers"></multi-checkbox-picker>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer flex-center">
    <button type="button" class="btn btn-white me-3" (click)="activeModal.dismiss()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="onSaveClicked()">Ok</button>
  </div>




