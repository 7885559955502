import { IDecideNextStep } from "../../../services/wizard/next-step-decider/next-step-decider.interface";
import { ContextType } from "./context-type.enum";
import { NextStepDeciderConfig } from "./next-step-decider-config.model";
import { StepConfig } from "./step-config.model";
import { StepType } from "./step-type.enum";

export class CreditScoresStepConfig extends StepConfig {

  useScoreIndicatorIcons: boolean = true;

  constructor(id: string, name: string | undefined, path: string, groupId: number | undefined,
      nextStepDeciderConfig: NextStepDeciderConfig | undefined) {
    super(id, name, path, groupId, ContextType.None);
    this.stepType = StepType.CreditScoresStep;
    this.nextStepDeciderConfig = nextStepDeciderConfig;
  }
}
