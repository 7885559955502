import { IDecideNextStep } from "../../services/wizard/next-step-decider/next-step-decider.interface";
import { OwnRentComponent } from "../../wizard/wizard-steps/own-rent/own-rent.component";
import { WizardStepBase } from "./wizard-step-base.model";

export class OwnRentStep extends WizardStepBase {

  ownActionLabel: string = "Own";
  rentActionLabel: string = "Rent";
  liveRentFreeActionLabel: "Live Rent Free";

  constructor(id: string, name: string, path: string, nextStepDecider?: IDecideNextStep) {
    super(id, name, path, nextStepDecider);
    this.component = OwnRentComponent;
  }
}
