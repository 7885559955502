import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ResidencyBasis } from '../../../models/enums/residency-basis.enum';
import { ResidencyAddress } from '../../../models/residency-address.model';
import { AddressComponent } from '../../address/address.component';
import { ConfigurableFieldHostComponent } from '../../configurable-field-host.component';
import { Address } from '../../../models/address-model';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'address-history2',
  templateUrl: 'address-history2.component.html'
})
export class AddressHistory2Component extends ConfigurableFieldHostComponent implements OnInit {

  @Input() residencyAddress: ResidencyAddress | undefined;

  @Output() durationAtAddressChanged = new EventEmitter<number>();

  @Output() addressChanged = new EventEmitter<Address>();

  @Output() ownershipStatusChanged: EventEmitter<ResidencyBasis> = new EventEmitter<ResidencyBasis>();

  @ViewChild('addressComponent') addressComponent: AddressComponent | undefined;

  @ViewChild('addressRelatedInfoForm') addressRelatedInfoForm: NgForm | undefined;

  uniqueId: string;

  protected isMobile: boolean = false;

  constructor() {
    super();
    if (!this.residencyAddress) {
      this.residencyAddress = new ResidencyAddress("PresentAddress");
    }
    this.uniqueId = uuidv4();
  }

  onMonthsOrYearsBlurred = (e: any) => {
    setTimeout(() => {
      const focusedElement = document.activeElement;
      if (focusedElement) {
        if (focusedElement.id !== "monthsAtAddress" && focusedElement.id !== "yearsAtAddress") {
          const years = this.residencyAddress!.durationYears ? this.residencyAddress!.durationYears : 0;
          const months = this.residencyAddress!.durationMonths ? this.residencyAddress!.durationMonths : 0;
          this.durationAtAddressChanged.emit(months + 12 * years);
        }
      }
    });
  }

  validate = () => {
    const isAddressValid = this.addressComponent?.validate();
    this.addressRelatedInfoForm?.form.markAllAsTouched();
    if (isAddressValid && this.addressRelatedInfoForm?.form.valid) {
      return true;
    }
    return false;
  }

  ngOnInit() {
    this.configureBasedOnScreenSize(window.innerWidth);
    if (!this.residencyAddress.residencyBasis) {
      this.residencyAddress.residencyBasis = null;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.configureBasedOnScreenSize(window.innerWidth);
  }

  onOwnershipStatusChanged = () => {
    this.ownershipStatusChanged.emit(this.residencyAddress.residencyBasis as ResidencyBasis)
  }

  onAddressChanged = (address: Address) => {
    this.addressChanged.emit(address);
  }

  private configureBasedOnScreenSize(windowWidth: number) {
    this.isMobile = windowWidth < 768
  }
}
