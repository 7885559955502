import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from 'projects/shared/services/environment.service';
import { Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { Address } from '../models/address-model';
import { MortgageDti } from '../models/mortgage-dti.model';
import { MortgageTerm } from '../models/mortgage-term.model';
import {
  Declarations,
  Demographics,
  Extension,
  GovernmentLoanDetail,
  HousingExpense,
  Mortgage,
  MortgageBorrower,
  MortgageInsuranceCalculationResult,
  MortgageInsuranceDetail,
  OriginatorInformation,
  SubjectProperty,
  TransactionDetail,
} from '../models/mortgage.model';
import {
  BorrowerCalculatedStats,
  EmploymentCalculatedStats,
  MortgageCalculatedStats,
  UrlaBorrower,
  UrlaEmployment,
  UrlaMortgage,
} from '../models/urla-mortgage.model';
import { DataService } from './data.service';
import { Lead } from '../models/lead.model';

@Injectable()
export class MortgageService {
  constructor(
    private readonly _dataService: DataService,
    private readonly _environmentService: EnvironmentService,
    private readonly _http: HttpClient
  ) { }

  getMortgageTerm = (mortgageTermId: number): Observable<MortgageTerm> => {
    const url = `api/mortgage/mortgageterms/${mortgageTermId}`;
    return this._dataService.get(url);
  };

  calculateMortgageInsurance = (
    applicationId: number
  ): Observable<MortgageInsuranceCalculationResult> => {
    const url = `api/mortgage/mortgage-insurance/calculate/${applicationId}`;
    return this._dataService.get(url);
  };

  postRefreshOriginator = (
    applicationId: number
  ): Observable<OriginatorInformation> => {
    const url = `api/mortgages/${applicationId}/refresh-originator`;
    return this._dataService.post(url, applicationId);
  };

  getMortgage = (applicationId: number): Observable<Mortgage> => {
    const url = `api/mortgages/urla2020?applicationId=${applicationId}`;
    return this._dataService.get(url);
  };

  getMortgageDetails = (applicationId: number): Observable<Mortgage> => {
    const url = `api/mortgages?applicationId=${applicationId}&autoCreateIfNotFound=false`;
    return this._dataService.get(url);
  };

  getMortgageDti = (mortgageId: number): Observable<MortgageDti> => {
    const url = `api/mortgages/${mortgageId}/dti`;
    return this._dataService.get(url);
  };

  refreshOriginator = (
    applicationId: number
  ): Observable<OriginatorInformation> => {
    const url = `api/mortgages/${applicationId}/refresh-originator`;
    return this._dataService.post(url, {});
  };

  getUrlaMortgage = (applicationId: number): Observable<UrlaMortgage> => {
    const url = `api/mortgages/urla2020?applicationId=${applicationId}`;
    return this._dataService.get(url).pipe(
      map((result) => {
        this.initializeMortgage(result);
        return result;
      })
    );
  };

  getMortgageBorrowers = (
    mortgageId: number
  ): Observable<MortgageBorrower[]> => {
    const url = `api/mortgages/${mortgageId}/borrowers`;
    return this._dataService.get(url);
  };

  insertBorrowerToMortgage = (
    mortgageId: number,
    borrower: MortgageBorrower
  ): Observable<MortgageBorrower> => {
    const url = `api/mortgages/${mortgageId}/borrowers`;
    return this._dataService.post(url, borrower);
  };

  deleteBorrowerFromMortgage = (
    borrowerId: number,
    cleanupOrphanedContacts: boolean = false
  ): Observable<any> => {
    const url = `api/mortgage/borrowers/${borrowerId}?cleanupOrphanedContacts=${cleanupOrphanedContacts}`;
    return this._dataService.delete(url);
  };

  exportUrlaDocs = (applicationId: number): Observable<any> => {
    const url =
      this._environmentService.apiInfo.apiBaseUrl +
      'api/mortgages/' +
      applicationId +
      '/generate-urla-docs';
    return this._http.post(url, {});
  };

  exportMismoFile = (applicationId: number): Observable<any> => {
    const url =
      this._environmentService.apiInfo.apiBaseUrl +
      'api/mortgages/' +
      applicationId +
      '/export-mismo34';
    const options: any = { responseType: 'blob', observe: 'response' };
    return this._http.post(url, {}, options).pipe(
      map((response: any) => {
        var a = document.createElement('a');
        a.href = URL.createObjectURL(response.body);
        let contentDisposition = response.headers.get('content-disposition');
        a.download = contentDisposition
          .split(';')[1]
          .split('filename')[1]
          .split('=')[1]
          .trim();
        a.click();
      })
    );
  };

  importMismoFile = (
    applicationId: number,
    mortgageId: number,
    file: File
  ): Observable<UrlaMortgage> => {
    let url =
      'api/mortgages/' + applicationId + '/import-mismo34/' + mortgageId;
    var formData = new FormData();
    formData.append('file', file);
    return this._dataService.postFormData(url, formData);
  };

  getLeadByGuid = (leadGuid: string): Observable<Lead> => {
    const url = `api/leads/prefill/${leadGuid}`;
    return this._dataService.get(url);
  };

  private initializeMortgage = (mortgage: UrlaMortgage) => {
    if (!mortgage.liabilities) {
      mortgage.liabilities = [];
    }
    if (!mortgage.proposedHousingExpense) {
      mortgage.proposedHousingExpense = new HousingExpense();
    }
    if (!mortgage.subjectProperty) {
      mortgage.subjectProperty = new SubjectProperty();
    }
    if (!mortgage.originatorInformation) {
      mortgage.originatorInformation = new OriginatorInformation();
    }
    if (!mortgage.extension) {
      mortgage.extension = new Extension();
    }
    if (!mortgage.mortgageInsuranceDetail) {
      mortgage.mortgageInsuranceDetail = new MortgageInsuranceDetail();
    }
    if (!mortgage.governmentLoanDetail) {
      mortgage.governmentLoanDetail = new GovernmentLoanDetail();
    }
    if (!mortgage.transactionDetail) {
      mortgage.transactionDetail = new TransactionDetail();
    } else {
      if (!mortgage.transactionDetail.purchaseCredits) {
        mortgage.transactionDetail.purchaseCredits = [];
      }
    }

    if (
      mortgage.transactionDetail.estimatedClosingCostsAmount &&
      !mortgage.transactionDetail.prepaidItemsEstimatedAmount &&
      !mortgage.transactionDetail.prepaidEscrowsTotalAmount &&
      !mortgage.mortgageInsuranceDetail.miOrFundingFeeTotalAmount
    ) {
      mortgage.transactionDetail.prepaidEscrowsTotalAmount =
        mortgage.transactionDetail.estimatedClosingCostsAmount;
    }

    mortgage.calculatedStats = new MortgageCalculatedStats();
    mortgage.borrowers.forEach((borrower) => {
      if (!borrower.governmentMonitors) {
        borrower.governmentMonitors = new Demographics();
      }
      if (!borrower.aliases) {
        borrower.aliases = [];
      }
      if (!borrower.currentHousingExpenses) {
        borrower.currentHousingExpenses = new HousingExpense();
      }
      if (!borrower.declarations) {
        borrower.declarations = new Declarations();
      }
      if (!borrower.dependents) {
        borrower.dependents = [];
      }
      if (!borrower.employments) {
        borrower.employments = [];
      } else {
        borrower.employments.forEach((e) => {
          if (!e.address) {
            e.address = new Address();
          }
        });
      }
      if (!borrower.nonEmploymentIncomes) {
        borrower.nonEmploymentIncomes = [];
      }
      if (!borrower.residencyAddresses) {
        borrower.residencyAddresses = [];
      }
      (<UrlaBorrower>borrower).calculatedStats = new BorrowerCalculatedStats();
      borrower.employments.forEach((employment) => {
        (<UrlaEmployment>employment).calculatedStats =
          new EmploymentCalculatedStats();
        if (!employment.incomes) {
          employment.incomes = [];
        }
      });
    });
    if (!mortgage.realEstateOwned) {
      mortgage.realEstateOwned = [];
    }
  };
}
