export interface BorrowerVerificationOrderHistory {
    formFreeHistoryId: number;
    integrationProvider: string;
    firstName: string;
    lastName: string;
    email: string;
    voaRequestType: string;
    transactionId: string;
    accountChekOrderId: string;
    vodId: string;
    latestReportId: string;
    orderStatus: string;
    loginUrl: string;
    status: BorrowerVerificationOrderStatus;
    leadId: number;
    applicationId: number;
    borrowerId: number;
    loanDocTaskId: number;
    taskStatus: string;
    enrollmentEvents: EnrollmentEvent[];
    thirdPartyEnrollmentWidget: string;
    modelGuid: string;
    insertedBy: string;
    updatedBy: string;
    dateInserted: string;
    dateUpdated: string;
  }

  export interface EnrollmentEvent {
    eventName: string;
    eventData: string;
    eventDateTime: string;
  }

  export enum BorrowerVerificationOrderStatus {
    Pending = "Pending",
    UserEnrolled = "UserEnrolled",
    VerificationSessionOpened = "VerificationSessionOpened",
    Canceled = "Canceled",
    AccountConnected = "AccountConnected"
  }
