import { CharacteristicMergeValue } from "./characteristic-merge-value.model";
import { ICharacteristic } from "./characteristic.interface";

export class LoanCharacteristic implements ICharacteristic {
    loanCharacteristicId: number;
    applicationId: number;
    borrowerId: number | undefined;
    characteristicId: number;
    characteristicMergeValues: Array<CharacteristicMergeValue>;
    companyId: number | undefined;
    insertedBy: string | undefined;
    dateInserted: string | undefined;
    updatedBy: string | undefined;
    dateUpdated: string | undefined;

    pendingDeletion: boolean = false;

    constructor(loanCharacteristicId: number, applicationId: number, characteristicId: number) {
      this.loanCharacteristicId = loanCharacteristicId;
      this.applicationId = applicationId;
      this.characteristicId = characteristicId;
      this.characteristicMergeValues = [];
    }
}

