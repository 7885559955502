import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Constants } from '../../services/constants';
import { NotificationService } from '../../services/notification.service';
import { UtilsService } from '../../services/utils.service';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'open-from-admin',
  templateUrl: 'open-from-admin.component.html',
})
export class OpenFromAdminComponent implements OnInit {

  constructor(private readonly _activatedRoute: ActivatedRoute,
    private readonly _utilsService: UtilsService,
    private readonly _navigationService: NavigationService,
    private readonly _notifsService: NotificationService) {
    this._activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this._activatedRoute.queryParams.subscribe((params) => {
        const token = params[Constants.token];
        if (token) {
          let jwtPayload = this._utilsService.parseJwt(token);

          localStorage.setItem(Constants.authorization.authorizationDataKey, JSON.stringify({
            token: token,
            useRefreshTokens: true,
            scope: "Borrower",
            companyGuid: jwtPayload.CompanyGuid,
            expiresAt: this._utilsService.getTokenExpireDate(jwtPayload.exp)
          }));

          this._navigationService.navigateToPath('borrower-portal/loans', true, null, null, false);

        } else {
          this._notifsService.showError('There needs to be a token in the URL.', 'Error!');
        }
      });
    });
  }

  ngOnInit() {}
}
