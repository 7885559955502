<form #borrowerInfoForm="ngForm" novalidate id="borrowerInfoForm" name="borrowerInfoForm">
  <div class="w-100">
    <div class="row mb-5">
      <div class="col-md-4 fv-row">
        <label class="required form-label">Birth Date</label>
        <div class="position-relative d-flex ">
          <input class="form-control form-control-solid" matInput mask="M0/d0/0000" [showMaskTyped]="true"
            [specialCharacters]="[ '/' ]" inputmode="numeric" [dropSpecialCharacters]="false" datePicker #birthDate="ngModel" required
            [(ngModel)]="borrower.dateOfBirth" placeholder="" name="birthDate" ngControl="birthDate" id="birthDate{{borrowerIndex}}"
            [ngClass]="{'is-invalid' : birthDate && birthDate.touched && birthDate.invalid}"
            (ngModelChange)="onTextChange($event)"/>
        </div>
      </div>
      <div class="col-md-4 fv-row">
        <label class="required form-label">Social Security Number</label>
        <div class="position-relative d-flex ">
          <input class="form-control form-control-solid" matInput mask="000-00-0000" [showMaskTyped]="true"
            [(ngModel)]="borrower.socialSecNum" #socialSecNum="ngModel" [specialCharacters]="[ '-' ]"
            [dropSpecialCharacters]="false" [patterns]="ssnPattern" placeholder="Social Security Number" name="ssn"
            required [ngClass]="{'is-invalid' : socialSecNum && socialSecNum.touched && socialSecNum.invalid}"
            (ngModelChange)="onTextChange($event)" />
        </div>
      </div>
    </div>
    <div class="row mb-5" *ngIf="!isCreditRun && canRunCredit">
      <div class="col-sm-3 fs-6 text-dark" style="padding-top: 8px;">Permission to run credit now</div>
      <div class="col-sm-2 form-check form-switch form-check-custom form-check-solid" style="margin-top: 8px;">
        <input class="form-check-input h-20px w-30px" type="checkbox" id="flexSwitch20x30" [ngModelOptions]="{standalone: true}"
        [(ngModel)]="borrower.permissionToRunCredit" (ngModelChange)="onPermissionToRunCreditChanged($event)" />
        <label class="form-check-label" for="flexSwitch20x30">
            {{borrower.permissionToRunCredit ? "Yes" : "No"}}
        </label>
      </div>
      <div class="col-sm-7"></div>
      <div class="col-sm-7"></div>
    </div>
    <div class="row mb-5" *ngIf="isCreditRun">
      <div class="d-flex flex-row align-items-center mb-5">
        <span class="fw-bold fs-6 text-gray-800 pe-3">Credit has already been run for this borrower</span>
        <span class="svg-icon svg-icon-1 svg-icon-success">
          <duotone-done-circle-svg-icon></duotone-done-circle-svg-icon>
        </span>
      </div>
    </div>
  </div>
</form>
