import { Component, Input, OnInit } from '@angular/core';
import { ContactInfoModel } from '../../models/borrower-portal/contact-info-model';

@Component({
  selector: ' loan-contacts',
  templateUrl: './loan-contacts.component.html'
})
export class LoanContactsComponent implements OnInit {

  @Input() borrowerContact: ContactInfoModel;

  constructor(
  ) { }

  ngOnInit(): void {
  }

}
