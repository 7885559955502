import { NextStepDeciderConfig } from "./next-step-decider-config.model";
import { NextStepDeciderType } from "./next-step-decider-type.enum";

export class SimpleNextStepDeciderConfig extends NextStepDeciderConfig {
    gotoStepPath: string | undefined;

    constructor(gotoStepPath: string) {
      super();
      this.gotoStepPath = gotoStepPath;
      this.deciderType = NextStepDeciderType.Simple;
    }
}
