import { Component, HostListener, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { isArray } from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from 'projects/shared/modal/modal.service';
import { ErrorMessage } from '../../../models/error-message.model';
import { Originator } from '../../../models/originator.model';
import { ContextInjector } from '../../../models/wizard/config/context-injector';
import { FieldConfig } from '../../../models/wizard/config/field-config-model';
import { WizardFlowSettings } from '../../../models/wizard/config/flow-settings.model';
import { WizardFlowConfigs } from '../../../models/wizard/config/wizard-flow-config.model';
import { ContextBoundStepProperties } from '../../../models/wizard/context-bound-step-properties.model';
import { WizardStepBase } from '../../../models/wizard/wizard-step-base.model';
import { NavigationService } from '../../../services/navigation.service';
import { WizardFlowConfigServiceBase } from '../../../services/wizard/wizard-flow-config-service.base';
import { WizardFlowServiceBase } from '../../../services/wizard/wizard-flow-service.base';
import { WizardButtonGroupComponent } from '../../wizard-button-group/wizard-button-group.component';
import { FlowConfigSaveDialogComponent } from '../config/config-save-dialog/flow-config-save-dialog.component';
import { FlowListDialogComponent } from '../config/flow-list-dialog/flow-list-dialog.component';
import { WizardStepComponentBase } from '../wizard-step-base.component';
import { NavigationType } from '../../../models/wizard/config/nav-type.enum';

declare const Swal: any;

declare const KTMenu: any;

@Component({
  selector: 'wizard-step-template',
  templateUrl: 'wizard-step-template.component.html',
  styleUrls: ['wizard-step-template.component.scss']
})
export class WizardStepTemplateComponent implements OnInit {

  @ViewChild(WizardButtonGroupComponent) buttonGroup: WizardButtonGroupComponent | undefined;

  private _step!: WizardStepBase;

  @Input()
  stepMainTemplate!: TemplateRef<any>;

  @Input()
  stepComponent!: WizardStepComponentBase<any>;

  @Input()
  get step(): WizardStepBase {
    return this._step;
  }

  @Input()
  showSpinnerOnMainContent: boolean = false;

  set step(value: WizardStepBase) {
    this._step = value;
    this.contextBoundStepProperties = ContextInjector.injectContextToStep(this._wizardFlowService.context, value);
    if (this._step && this.step.fieldConfig) {
      this.fieldsToConfig = this.step.getFieldsToConfig(this.step.fieldConfig);
    }
  }

  navType: NavigationType = NavigationType.Horizontal;

  hasProgressBar: boolean = true;

  isNavigationApplicable: boolean = false;

  error: ErrorMessage | undefined = undefined;

  fieldsToConfig: FieldConfig[] | undefined = undefined;

  contextBoundStepProperties!: ContextBoundStepProperties;

  isLoginLinkVisible: boolean = false;

  protected isMobile: boolean = false;

  onMenuTriggerClicked = () => {
    var menuElement = document.querySelector(".menu");
    var menu = KTMenu.getInstance(menuElement);
    var item = document.querySelector("#menu_item_1");
    menu.show(item);
  }

  private _flowConfig!: WizardFlowConfigs;

  get flowTitle(): string {
    if (!this._flowConfig.title || this._flowConfig.title.length === 0)
      return "Default Flow"
    return this._flowConfig.title;
  }

  get flowGuid(): string {
    return this._flowConfig.guid;
  }

  private _subscriptionToConfigCreated: any;
  private _subscriptionToConfigEdited: any;

  private modalOptions: NgbModalOptions = {
    size: "lg",
    backdrop: "static",
    container: "body"
  };

  findAvailableContextKeywords(searchText: string) {
    return ContextInjector.availableKeys.filter(item =>
      item.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  getSelectedKeyword(choice: string) {
    return `[${choice}]`;
  }

  get inEditMode() {
    return this._wizardFlowService.inEditMode;
  }

  get isNavigationHorizontal(): boolean {
    return !this.navType || this.navType == NavigationType.Horizontal;
  }

  get isNavigationVertical(): boolean {
    return this.navType == NavigationType.Vertical;
  }

  get isNavigationTurnedOff(): boolean {
    return this.navType == NavigationType.None;
  }

  get isEditEnabled() {
    return this._wizardFlowService.isEditEnabled;
  }

  get originator(): Originator | undefined {
    const originator = this._wizardFlowService.context.originator;
    return originator;
  }

  set inEditMode(value: boolean) {
    this._wizardFlowService.inEditMode = value;
  }

  get hasPreviousStep(): boolean {
    return this._wizardFlowService.hasPreviousStep();
  }

  get inPreviewMode(): boolean {
    return this._wizardFlowService.inPreviewMode;
  }

  get isFirstStepOfRegistrationFlow(): boolean {
    return this._wizardFlowService.isFirstStepOfRegistrationFlow;
  }

  get percentComplete(): string {
    return this._wizardFlowService.percentComplete + '%';
  }

  constructor(private readonly _wizardFlowService: WizardFlowServiceBase,
    private readonly _wizardFlowConfigService: WizardFlowConfigServiceBase,
    private readonly _navigationService: NavigationService,
    private readonly _modalService: ModalService,
    private readonly _spinner: NgxSpinnerService) {
    this._flowConfig = this._wizardFlowConfigService.flowConfigs;
    this.isNavigationApplicable = _wizardFlowService.isNavigationApplicable;
    if (this._flowConfig) {
      this.navType = this._flowConfig.navigationType;
      this.hasProgressBar = this._flowConfig.hasProgressBar;
    }
  }

  ngOnInit() {
    //KTMenu.createInstances();
    this.onWizardFlowSettingsChanged()
    this.configureBasedOnScreenSize(window.innerWidth);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.configureBasedOnScreenSize(window.innerWidth);
  }

  startSpinner = (): void => {
    this._spinner.show();
    this.buttonGroup?.startSpinner();
  }

  stopSpinner = (): void => {
    this._spinner.hide();
    this.buttonGroup?.stopSpinner();
  }

  showError = (error: ErrorMessage): void => {
    this.error = error;
  }

  onLoginClicked = () => {
    this._wizardFlowService.resetFlows();
    this._navigationService.navigateToLogin(undefined, false);
  }

  onFlowsClicked = () => {
    const modal = this._modalService.show(FlowListDialogComponent, this.modalOptions);
  }

  onEditFlowClicked = () => {
    this._navigationService.navigateToFlowEditor(this._flowConfig.guid, this._wizardFlowService.activeFlowType);
  }

  onGotoFlowClicked = (flowEntryPath: string) => {
    this._navigationService.navigateToPath(flowEntryPath, true);
  }

  onEditModeToggled = () => {
    this.contextBoundStepProperties = ContextInjector.injectContextToStep(this._wizardFlowService.context, this.step);
    setTimeout(() => {
      KTMenu.createInstances();
    }, 200);
  }

  onNextClicked = () => {
    this.stepComponent.onNextClicked();
  }

  onBackClicked = () => {
    this.stepComponent.onBackClicked();
  }

  onWizardFlowSettingsChanged = () => {
    const settings = new WizardFlowSettings();
    settings.hasProgressBar = this.hasProgressBar;
    settings.navigationType = this.navType;
    this.stepComponent.onWizardFlowSettingsChanged(settings);
  }

  onSaveStepConfigClicked = () => {
    const modal = this._modalService.show(FlowConfigSaveDialogComponent, this.modalOptions);
    modal.componentInstance.title = this._flowConfig.title;
    modal.componentInstance.comment = this._flowConfig.comment;
    modal.componentInstance.guid = this._flowConfig.guid;
    this._flowConfig.navigationType = this.navType;
    this._flowConfig.hasProgressBar = this.hasProgressBar;
    this._subscriptionToConfigCreated = modal.componentInstance.saveClickedEmitterForCreate
      .subscribe((e: any) => this.onSaveClickedOnSaveModalForCreate(e.title, e.comment));
    this._subscriptionToConfigEdited = modal.componentInstance.saveClickedEmitterForEdit
      .subscribe((e: any) => this.onSaveClickedOnSaveModalForEdit(e.title, e.comment));
  }

  private configureBasedOnScreenSize(windowWidth: number) {
    this.isMobile = windowWidth < 768
  }

  private onSaveClickedOnSaveModalForCreate = (title: string, comment: string) => {
    let settings = new WizardFlowSettings();
    settings.title = title;
    settings.comment = comment;
    settings.navigationType = this.navType;
    settings.hasProgressBar = this.hasProgressBar;
    settings.navGroups = this._wizardFlowService.activeFlowConfig.stepGroups;
    this.startSpinner();
    const self = this;
    const activeFlowType = this._wizardFlowService.activeFlowType;
    this._wizardFlowService.createFlowConfig(activeFlowType, this.step, settings)?.subscribe(result => {
      this.stopSpinner();
      this.inEditMode = false;
      Swal.fire(
        'Success!',
        'Your flow config has been successfully created with a new Guid: ' + result.flowGuid,
        'success'
      ).then(function (modalResult: any) {
        if (modalResult.value) {
          // Navigate to the new flow after creation
          self._wizardFlowService.navigateToFlow(result.flowGuid);
        }
      });
    }, err => {
      this.stopSpinner();
      this.showFlowSaveErrorHtmlMessage(err);
    });
  }

  private onSaveClickedOnSaveModalForEdit = (title: string, comment: string) => {
    let settings = new WizardFlowSettings();
    settings.title = title;
    settings.comment = comment;
    settings.navigationType = this.navType;
    settings.hasProgressBar = this.hasProgressBar;
    settings.navGroups = this._wizardFlowService.activeFlowConfig.stepGroups;
    this.startSpinner();
    const activeFlowType = this._wizardFlowService.activeFlowType;
    this._wizardFlowService.updateFlowConfig(activeFlowType, this.step, settings)?.subscribe(() => {
      this.stopSpinner();
      this.inEditMode = false;
      this.contextBoundStepProperties = ContextInjector.injectContextToStep(this._wizardFlowService.context, this.step);
      Swal.fire(
        'Success!',
        'Your flow config has been successfully saved.',
        'success'
      );
    }, err => {
      this.stopSpinner();
      this.showFlowSaveErrorHtmlMessage(err);
    })
  }

  private showFlowSaveErrorHtmlMessage = (err: any) => {
    let errorHtml = "<div>An error occurred while saving the flow.</div>";
    if (err.error && err.error.errors && err.error.errors) {
      Object.keys(err.error.errors).forEach(key => {
        if (isArray(err.error.errors[key])) {
          err.error.errors[key].forEach(errorMessage => {
            errorHtml += `<div>${errorMessage}</div>`
          })
        }
      })
    }
    Swal.fire({
      title: 'Error!',
      html: errorHtml,
      icon: 'error'
    }
    );
  }
}
