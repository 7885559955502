import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApplicationForUser } from '../../../models/borrower-portal/application-for-user-model';
import { ApplicationTaskModel } from '../../../models/borrower-portal/application-task-model';

@Component({
  selector: 'tasks-income-employment',
  templateUrl: './tasks-income-employment.component.html',
  styleUrls: ['./tasks-income-employment.component.scss']
})
export class TasksIncomeAndEmploymentComponent implements OnInit {

  @Input() tasks: ApplicationTaskModel[] = [];
  @Input() application: ApplicationForUser;

  @Input() showIncomeEmploymentVerificationButton: boolean = true;

  @Output() startIncomeEmploymentVerificationClicked = new EventEmitter<ApplicationTaskModel>();

  constructor() { }

  ngOnInit(): void {
  }

  onStartIncomeEmploymentVerificationClicked = (task: ApplicationTaskModel) => {
    this.startIncomeEmploymentVerificationClicked.emit(task);
  }
}
