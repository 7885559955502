import { Injectable } from "@angular/core";
import { WizardFlowContext } from "../../../wizard-flow-context.model";
import { GenericStepFactory } from "./generic-step.factory";
import { NextStepDeciderFactory } from "./next-step-decider.factory";
import { ICreateStep } from "./step-factory.interface";
import { DigitalIncomeVerificationStep } from "../../digital-income-verification-step.model";
import { DigitalIncomeVerificationStepConfig } from "../digital-income-verification-step.config.model";

@Injectable()
export class DigitalIncomeVerificationStepFactory extends GenericStepFactory<DigitalIncomeVerificationStep> implements ICreateStep {

  constructor(nextStepDeciderFactory: NextStepDeciderFactory) {
    super(nextStepDeciderFactory, DigitalIncomeVerificationStep);
  }

  create(config: DigitalIncomeVerificationStepConfig, wizardFlowContext: WizardFlowContext): DigitalIncomeVerificationStep {
    let digitalIncomeVerificationStep = super.create(config, wizardFlowContext)
    digitalIncomeVerificationStep.vendorToUse = config.vendorToUse != null
      ? config.vendorToUse : 'TWN';
    return digitalIncomeVerificationStep;
  }

  configure(step: DigitalIncomeVerificationStep, config: DigitalIncomeVerificationStepConfig): void {
    super.configure(step, config);
    config.vendorToUse = step.vendorToUse;
  }
}
