import { Injectable } from "@angular/core";
import { WizardFlowContext } from "../../../wizard-flow-context.model";
import { GenericStepFactory } from "./generic-step.factory";
import { NextStepDeciderFactory } from "./next-step-decider.factory";
import { ICreateStep } from "./step-factory.interface";
import { RealEstateOwnedStep } from "../../reo-step.model";
import { ReoStepConfig } from "../reo-step-config.model";

@Injectable()
export class ReoStepFactory extends GenericStepFactory<RealEstateOwnedStep> implements ICreateStep {

  constructor(nextStepDeciderFactory: NextStepDeciderFactory) {
    super(nextStepDeciderFactory, RealEstateOwnedStep);
  }

  create(config: ReoStepConfig, wizardFlowContext: WizardFlowContext): RealEstateOwnedStep {
    let reoStep = super.create(config, wizardFlowContext)
    reoStep.addRealEstateActionLabel = config.addRealEstateActionLabel || 'Add Real Estate';
    return reoStep;
  }

  configure(step: RealEstateOwnedStep, config: ReoStepConfig): void {
    super.configure(step, config);
    config.addRealEstateActionLabel = step.addRealEstateActionLabel;
  }
}
