<form #borrowerInfoForm="ngForm" novalidate id="borrowerInfoForm" name="borrowerInfoForm">
  <div class="w-100">
    <div class="row mb-5">
      <div class="col-sm-5 fs-6 text-dark" style="padding-top: 8px;">Permission to run income and employment</div>
      <div class="col-sm-2 form-check form-switch form-check-custom form-check-solid" style="margin-top: 8px;">
        <input class="form-check-input h-20px w-30px" type="checkbox" id="flexSwitch20x30" [ngModelOptions]="{standalone: true}" [(ngModel)]="borrower.permissionToRunIncome" (ngModelChange)="onModelChange($event)" />
        <label class="form-check-label" for="flexSwitch20x30">
            {{borrower.permissionToRunIncome ? "Yes" : "No"}}
        </label>
      </div>
      <div class="col-sm-4"></div>
    </div>
  </div>
</form>
