import { AfterViewInit, Component, Injector, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ResidencyType } from '../../../models/enums/residency-type.enum';
import { ResidencyAddress } from '../../../models/residency-address.model';
import { AddressHistoryStep } from '../../../models/wizard/address-history-step.model';
import { MortgageApplicationService } from '../../../services/mortgage-application.service';
import { AddressHistory2Component } from '../address-history2/address-history2.component';
import { WizardStepComponentBase } from '../wizard-step-base.component';
import { StepType } from '../../../models/wizard/config/step-type.enum';

@Component({
  selector: 'current-address-history-old',
  templateUrl: 'current-address-history-old.component.html'
})

export class CurrentAddressHistoryComponentOld extends WizardStepComponentBase<AddressHistoryStep> implements OnInit, AfterViewInit
{
  public errorMessage: string | undefined;

  public address: ResidencyAddress | undefined;

  @ViewChildren('addressHistoryInfo') addressHistoryInfoComponents: | QueryList<AddressHistory2Component> | undefined;

  private _addressHistoryEventSubscriptions: any[] = [];

  hasEnoughAddressHistory: boolean = false;

  constructor(
    private readonly _injector: Injector,
    private readonly _mortgageApplicationService: MortgageApplicationService
  ) {
    super(_injector);
    let currentAddress = this.currentBorrower.residencyAddresses.find(
      (a) => a.residencyType == ResidencyType.FormerAddress
    );
    if (currentAddress) {
      this.address = currentAddress;
    } else {
      currentAddress = new ResidencyAddress(ResidencyType.PresentAddress);
      this.currentBorrower.residencyAddresses.push(currentAddress);
      this.address = currentAddress;
    }
    this.hasEnoughAddressHistory = this.calculateMonthsOfAddressHistory() >= 24;
  }

  public get previousAddresses(): ResidencyAddress[] {
    const previousAddresses = this.currentBorrower.residencyAddresses.filter(
      (a) => a.residencyType == ResidencyType.FormerAddress
    );
    if (previousAddresses) return previousAddresses;
    return [];
  }

  public addAddressHistory() {
    const previousAddress = new ResidencyAddress(ResidencyType.FormerAddress);
    this.currentBorrower.residencyAddresses.push(previousAddress);
  }

  private calculateMonthsOfAddressHistory = (): number => {
    let months: number = 0;
    for (let residencyAddress of this.currentBorrower.residencyAddresses) {
      const yearsAtAddress = residencyAddress.durationYears
        ? Number(residencyAddress.durationYears)
        : 0;
      const monthsAtAddress = residencyAddress.durationMonths
        ? Number(residencyAddress.durationMonths)
        : 0;
      months = months + 12 * yearsAtAddress + monthsAtAddress;
    }
    return months;
  };

  onDurationChanged = () => {
    this.hasEnoughAddressHistory = this.calculateMonthsOfAddressHistory() >= 24;
  };

  onNextClicked() {
    let valid = true;
    if (this.addressHistoryInfoComponents) {
      for (let addressHistoryInfo of this.addressHistoryInfoComponents) {
        valid = addressHistoryInfo.validate() && valid;
      }
    }
    if (!valid) {
      return;
    }
    //if valid, reiterate and calculate total months lived
    if (!this.hasEnoughAddressHistory) {
      return;
    }
    super.onNextClicked();
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    if (this.addressHistoryInfoComponents) {
      this.addressHistoryInfoComponents.changes.subscribe((r) => {
        if (r._results && r._results.length > 0) {
          this._addressHistoryEventSubscriptions.forEach((subscription) => {
            subscription.unsubscribe();
          });
          this._addressHistoryEventSubscriptions = [];
          this.subscribeToAddressHistoryEvents();
        }
      });

      this.subscribeToAddressHistoryEvents();
    }
  }

  private subscribeToAddressHistoryEvents = () => {
    if (this.addressHistoryInfoComponents) {
      const components: AddressHistory2Component[] =
        this.addressHistoryInfoComponents.toArray();
      components.forEach((item) => {
        const durationAtAddressSubscription =
          item.durationAtAddressChanged.subscribe((e) =>
            this.onDurationAtAddressChanged(e)
          );
        this._addressHistoryEventSubscriptions.push(
          durationAtAddressSubscription
        );
      });
    }
  };

  private onDurationAtAddressChanged = (e: any) => {
    this.hasEnoughAddressHistory = this.calculateMonthsOfAddressHistory() >= 24;
  };
}
