import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EnumerationItem } from 'projects/shared/models/enumeration-item.model';
import { Employment, Income } from '../../../models/mortgage.model';
import { EnumsService } from '../../../services/enums.service';
import { Constants } from '../../../services/constants';
import { Utils } from '../../../services/utils';
import cloneDeep from 'lodash/cloneDeep';

@Component({
  selector: 'employment-income-dialog',
  templateUrl: 'employment-income-dialog.component.html',
})
export class EmploymentIncomeDialogComponent implements OnInit {

  @ViewChild('incomeForm')
  incomeForm: NgForm;

  @Input()
  protected set employment(employment: Employment) {
    if (employment) {
      this._employment = cloneDeep(employment);
    }
  }

  get employment() {
    return this._employment;
  }

  protected employmentIncomeTypes: EnumerationItem[] = [];

  protected totalMonthly: number = 0;

  protected deleteAttemptedForIncomeId: number | null = null;
  
  private _employmentIncomeTypeValues: string[] = [];

  private _enums: any;

  private _employment: Employment;

  constructor(public activeModal: NgbActiveModal, 
    private readonly _enumsService: EnumsService
  ) {
    this._enumsService.getMortgageEnumerations().subscribe(enumerations => {
      this.generateEmploymentIncomeTypeValuesList();
      this._enums = enumerations;
      this.prepareEmploymentIncomeTypesList(enumerations);
    });
  }

  ngOnInit() {
    if (!this._employment.incomes) {
      this._employment.incomes = [];
    }
    this.calculateTotalMonthly();
  }

  protected onOkClicked = () => {
    if (this.incomeForm?.form.valid) {
      this.activeModal.close(this._employment);
    }
  }

  protected addIncomeClicked = () => {
    this._employment.incomes.push({
      incomeId: Utils.getUniqueId(),
      typeOfIncome: this.employmentIncomeTypes[0].value,
      monthlyIncome: 0,
      typeOfIncomeOtherDescription: '',
      amount: 0,
      payPeriod: null,
      hoursPerWeek: null,
      isOmitted: false
    });
  }

  protected onDeleteConfirmed = (income: Income) => {
    const index = this._employment.incomes.indexOf(income);
    if (index > -1) {
      this._employment.incomes.splice(index, 1);
      this.calculateTotalMonthly();
      this.prepareEmploymentIncomeTypesList(this._enums);
      this.deleteAttemptedForIncomeId = null;
    }
  }

  protected onDeleteAborted = (income: Income) => {
    this.deleteAttemptedForIncomeId = null;
  }

  protected onDeleteIncomeClicked = (income: Income) => {
    this.deleteAttemptedForIncomeId = income.incomeId;
  }

  protected onIncomeChanged = () => {
    this.calculateTotalMonthly();
  }

  private calculateTotalMonthly = () => {
    this.totalMonthly = this._employment.incomes.reduce((total, income) => total + income.monthlyIncome, 0);
  }

  private generateEmploymentIncomeTypeValuesList = () => {
    this._employmentIncomeTypeValues = [
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.Base),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.Bonus),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.Commissions),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.Overtime),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryCombatPay),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryFlightPay),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryHazardPay),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryOverseasPay),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryPropPay),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryClothesAllowance),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryRationsAllowance),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryVariableHousingAllowance),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryQuartersAllowance),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.OtherTypesOfIncome)
    ]
  }

  private prepareEmploymentIncomeTypesList = (enums: any) => {
    const incomeTypes = enums[Constants.enumerations.incomeType];
    const employmentIncomeTypes = [];
    this._employmentIncomeTypeValues.forEach(type => {
      const incomeType = incomeTypes.find(t => t.value === type);
      if (incomeType) {
        employmentIncomeTypes.push(incomeType);
      }
    });
    this.employmentIncomeTypes = employmentIncomeTypes;
  }
}

export interface BorrowerViewModel {
  id: number;
  name: string;
}
