import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Observable } from 'rxjs';

@Injectable()
export class FileService {
  constructor(private readonly _dataService: DataService) {}

  viewFile = (fileGuid: string): Observable<BlobPart> => {
    return this._dataService.downloadFile(
      `api/BorrowerPortal/ViewFile/${fileGuid}`
    );
  };

  downloadFile = (fileGuid: string): Observable<BlobPart> => {
    return this._dataService.downloadFile(
      `api/BorrowerPortal/DownloadFile/${fileGuid}`
    );
  };
}
