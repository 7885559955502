import { AddressFields } from "../address-fields.model";
import { ContextType } from "./context-type.enum";
import { StepConfig } from "./step-config.model";
import { StepType } from "./step-type.enum";

export class AddressStepConfig extends StepConfig {
  addressFields!: AddressFields;

  constructor(id: string, name: string | undefined, path: string, groupId: number | undefined, contextType: ContextType,
    addressFields: AddressFields) {
    super(id, name, path, groupId, contextType);
    this.addressFields = addressFields;
    this.stepType = StepType.AddressStep;
  }
}
