import { Component, Input, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ApplicationTaskModel } from "../../../models/borrower-portal/application-task-model";
import { Constants } from "../../../services/constants";
import { AddFilesToTaskDialogComponent } from "../add-files-to-task-dialog/add-files-to-task-dialog.component";
import { AttachedDocumentsDialogComponent } from "../attached-documents-dialog/attached-documents-dialog.component";

@Component({
  selector: 'other-tasks-table',
  templateUrl: './other-tasks-table.component.html'
})

export class OtherTasksTableComponent implements OnInit {

  @Input() applicationTasks: ApplicationTaskModel[] = [];

  constructor(private modalService: NgbModal) {
  }

  ngOnInit(): void {
  }

  goToLink(url: string){
    window.open(url, "_blank");
  }

  openAddFiles(task: ApplicationTaskModel) {
    const modalRef = this.modalService.open(AddFilesToTaskDialogComponent, Constants.modalOptions.large);
    modalRef.componentInstance.task = task;

    modalRef.result.then(() => {
    });
  }

  openAttachedDocuments = (task: ApplicationTaskModel) => {
    const modalRef = this.modalService.open(AttachedDocumentsDialogComponent, Constants.modalOptions.xlarge);
    modalRef.componentInstance.task = task;

    modalRef.result.then(() => {
    });
  }
}
