

import { AfterViewInit, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as _ from 'lodash';
import { WizardFlowDecisionContext } from '../../models/wizard-flow-decision-context.model';
import { ContextExtractor } from '../../services/context-extractor';
import { WizardFlowServiceBase } from '../../services/wizard/wizard-flow-service.base';

@Component({
  selector: 'expression-editor',
  templateUrl: 'expression-editor.component.html',
  styleUrls: ['expression-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ExpressionEditorComponent),
      multi: true
    }
  ]
})

export class ExpressionEditorComponent implements OnInit, AfterViewInit, ControlValueAccessor {

  @Input()
  name: string;

  expression: string = "";

  triggerCharacter = '';

  private _lastWord: string = "";

  private _currentListOfChoices: string[] = [];

  private _currentObject: any = undefined;

  private _newWordStarted: boolean = false;

  private _searchText: string = "";

  private _isMenuVisible: boolean = false;

  onChange: any = () => { };

  onTouch: any = () => { };

  _decisionContext: WizardFlowDecisionContext | undefined;

  keyboardShortcut = (event: KeyboardEvent) => {

    if (this._isMenuVisible) {
      return false;
    }

    this._searchText = event.key;

    if ((event.keyCode === 32 || event.code === '32') && event.ctrlKey) {
      return true;
    }

    let key = event.key;

    // Nothing is selected, we start from scratch at the root
    if (this._lastWord === '') {
      const contextProperties = Object.getOwnPropertyNames(this._decisionContext).filter((c: any) => isNaN(c));;
      this._currentListOfChoices = contextProperties;
    } else {
      if (event.key == '.') {
        this.triggerCharacter = '.';
        return true;
      }
    }

    let found = this._currentListOfChoices.find(p => p.includes(key));
    if (found) {
      return true;
    }
    return false;
  }

  constructor(private readonly _wizardFlowService: WizardFlowServiceBase) {
    this._decisionContext = ContextExtractor.prepareDecisionContext(this._wizardFlowService.context);
  }

  get value(): string {
    return this.expression;
  }

  writeValue(expression: any): void {
    this.expression = expression;
    this.onChange(this.value);
  }

  registerOnChange(fn: (rating: number) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  ngAfterViewInit(): void {
    //$("#expression").focus();
  }

  ngOnInit() {
    this._currentObject = this._decisionContext;
  }

  onMenuShown = () => {
    this._searchText = '';
    this._isMenuVisible = true;
  }

  onKeyDown(event: any) {
    if (event.code === 'Enter') {
      event.preventDefault();
      return;
    }
    if (event.code === 'Space') {
      this._newWordStarted = true;
    }
  }

  onMenuHidden = () => {
    this._isMenuVisible = false;
  }

  findChoices = (searchText: string) => {
    if (searchText.length === 0 && this._searchText.length > 0 && this._searchText != '.') {
      searchText = this._searchText;
    }
    return this._currentListOfChoices.filter(item =>
      item.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  getChoiceLabel = (choice: string) => {
    if (this._newWordStarted || !this.triggerCharacter) {
      this._newWordStarted = false;
      this.triggerCharacter = '.';
      return choice;
    }
    return '.' + choice;
  }

  onExpressionChanged = () => {
    this.writeValue(this.expression);
    const lastWord = this.expression.split(" ").splice(-1)[0];
    this._lastWord = lastWord;
    this.decideOnCurrentObjectContext(lastWord);
    const lastCharacter = this.expression.charAt(this.expression.length - 1);
    if (lastCharacter === ' ' || this.expression.length === 0) {
      this._newWordStarted = true;
      this.triggerCharacter = '';
    }
  }

  decideOnCurrentObjectContext = (lastWord: string) => {
    let currentObject: any = this._decisionContext;
    const tokens = lastWord.split('.');
    tokens.forEach((token: string) => {
      if (token.length > 0)
        currentObject = currentObject[token];
    });
    if (currentObject != null) {
      if (typeof currentObject === 'object') {
        this._currentObject = currentObject;
        this._currentListOfChoices = Object.getOwnPropertyNames(this._currentObject).filter((c: any) => isNaN(c));
      } else {
        this._currentListOfChoices = [];
      }
    } else {
      if (!this._currentObject) {
        this._currentObject = this._decisionContext;
        this._currentListOfChoices = this.getPossibleChoicesFromObject(this._currentObject);
      }
    }
  }

  getPossibleChoicesFromObject = (o: any): string[] => {
    const choices = Object.getOwnPropertyNames(this._currentObject).filter((c: any) => isNaN(c)).map((c: any) => {
      if (typeof c === 'function') {
        return c + '()';
      }
      return c;
    });
    return choices;
  }

}
