import { Component, Injector, QueryList, ViewChildren } from '@angular/core';
import { Borrower } from '../../../models/borrower-model';
import { DemographicsStep } from '../../../models/wizard/demographics-step.model';
import { BorrowerDemographicsComponent } from '../../borrower-demographics/borrower-demographics.component';
import { WizardStepComponentBase } from '../wizard-step-base.component';

@Component({
  selector: 'demographics',
  templateUrl: 'demographics-step.component.html'
})

export class DemographicsStepComponent extends WizardStepComponentBase<DemographicsStep> {

  @ViewChildren('borrowerDemographics') borrowerDemographicsComponents: QueryList<BorrowerDemographicsComponent> | undefined;

  mainBorrower!: Borrower ;
  coBorrowers: Borrower[] = [];

  constructor(private readonly _injector: Injector) {
      super(_injector);
      this.saveMortgageApplicationBeforeNextStep = true;
      if (this.currentBorrower) {
        this.mainBorrower = this.currentBorrower;
        this.coBorrowers = this.mortgageApplication.borrowers.filter(b => b.primaryEmail.toLocaleLowerCase() !==
          this.mainBorrower.primaryEmail.toLocaleLowerCase());
      }
  }

  onNextClicked() {
    if (this.wizardFlowService.isEditEnabled) {
      super.onNextClicked();
      return;
    }
    let isFormValid = true;
    this.borrowerDemographicsComponents?.toArray().forEach(borrowerDemographicsComponent => {
      isFormValid = isFormValid && borrowerDemographicsComponent.validate();
    });
    if (isFormValid) {
      super.onNextClicked();
    }
  }
}
