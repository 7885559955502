<div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
  <div class="d-flex align-items-center justify-content-center">
    <span class="svg-icon svg-icon-3 svg-icon-success me-2">
      <i *ngIf="useIcons" class="far fa-3x text-success" [ngClass]="{
          'fa-laugh': score && score >= 720,
          'fa-smile': score && score >= 660 &&  score < 720,
          'fa-meh': score && score >= 620 &&  score < 660,
          'fa-frown': score && score >= 580 && score < 620,
          'fa-sad-tear': score && score < 580,
          'fa-question-circle': !score,
          'text-danger': score && score < 620,
          'text-warning': (score && score >= 620 && score < 660) || !score
        }"></i>
    </span>
    <div *ngIf="!score || score === 0" class="fs-1 fw-bolder">-</div>
    <div *ngIf="score && score > 0" style="font-size: 30px;" class="fw-bolder" data-kt-countup="true" [attr.data-kt-countup-value]="score">0</div>
  </div>
  <div class="fw-bold fs-6 text-gray-400">{{this.companyName}}</div>
</div>
