<div class="w-100">
  <h5 class="my-2 d-flex justify-content-center">
    <span style="max-width: 500px" class="text-gray-700 fs-4 fw-bold">
      What is the purchase price of the property?
    </span>
  </h5>
  <div class="my-2 d-flex justify-content-center">
    <div style="max-width: 500px">
      <form #purchasePriceForm="ngForm" novalidate class="form-horizontal">
        <div class="row p-4 mb-3">
          <div class="form-group mb-3 col-md-12">
            <currency-input
              #purchasePriceInput="ngModel"
              [(ngModel)]="request.purchasePrice"
              name="purchasePriceNumber"
              [allowDecimals]="false"
              [min]="minLoanAmountValue"
              [max]="maxLoanAmountValue"
              [placeholder]="'Purchase Price'"
            ></currency-input>

            <input
              type="range"
              class="form-control-range w-100"
              [(ngModel)]="request.purchasePrice"
              name="purchasePriceRange"
              [min]="minLoanAmountValue"
              [max]="maxLoanAmountValue"
              step="5000"
              required
              #purchasePriceRangeInput="ngModel"
              [ngClass]="{
                'is-invalid':
                  purchasePriceRangeInput &&
                  purchasePriceRangeInput.touched &&
                  purchasePriceRangeInput.invalid
              }"
            />
          </div>
          <small class="text-center text-muted"
            >Use the slide to adjust purchase price, or simply type in the
            value.</small
          >
        </div>
      </form>
    </div>
  </div>

  <div class="d-flex justify-content-between my-2">
    <span>
      <a
        href="javascript:void(0)"
        class="btn btn-light"
        (click)="closeClicked()"
      >
        Close
      </a>
    </span>
    <span>
      <a
        href="javascript:void(0)"
        class="btn bp-primary-bg-color me-2"
        (click)="backClicked()"
      >
        Back
      </a>
      <a
        href="javascript:void(0)"
        class="btn bp-primary-bg-color"
        (click)="nextClicked()"
      >
        Next
      </a>
    </span>
  </div>
</div>
