<ng-template #resetPassword>
  <!-- <header [logoUrl]="logoUrl"></header> -->
  <div class="d-flex flex-column flex-column-fluid p-10 pb-lg-10 login-wrapper">
    <div class="w-md-500px bg-white rounded shadow-sm p-7 p-lg-7 login-body">
      <div class="d-flex justify-content-center mb-4">
        <logo [logoUrlsToTry]="possibleLogoUrls"></logo>
      </div>

      <form class="form w-100" #resetPasswordForm="ngForm" novalidate id="resetPasswordForm" name="resetPasswordForm">
        <div class="text-center my-4">
          <div class="alert alert-info bg-light-success d-flex justify-content-center align-items-center p-5 mb-5">
               <span class="svg-icon svg-icon-2hx svg-icon-info">
              <duotone-shield-check-svg-icon></duotone-shield-check-svg-icon>
            </span>
            <div class="d-flex flex-column flex-grow-1">
              <span class="mb-3"><strong>Welcome back!</strong></span>
              <span>Please enter your new password below.</span>
            </div>
          </div>
        </div>
        <div *ngIf="errorMessage" class="alert alert-danger d-flex justify-content-center align-items-center p-5 mb-5">
          <span class="svg-icon svg-icon-2hx svg-icon-danger me-3">
            <duotone-warning-circle-svg-icon></duotone-warning-circle-svg-icon>
          </span>
          <div class="d-flex flex-column flex-grow-1">
            <span>{{errorMessage}}</span>
          </div>
        </div>
        <div class="fv-row">
          <div class="row">
            <div class="fv-row mb-5">
              <label class="form-label fw-bolder text-dark fs-6">E-mail</label>
              <input class="form-control form-control-lg form-control-solid" type="text" name="email" required email trim
                [ngClass]="{'is-invalid' : email && email.touched && email.invalid}" autocomplete="off"
                [(ngModel)]="userName" #email="ngModel" (change)="onEmailChangedDebounce()" />
              <div class="invalid-feedback" *ngIf="email.errors && email.errors.required">
                Please enter your e-mail.</div>
            </div>
            <div class="fv-row mb-5" *ngIf="userName && availableCompanies && availableCompanies.length > 1">
              <label class="form-label fs-6 fw-bolder text-dark">Company</label>
              <select class="form-control form-control-lg form-control-solid" required
                [ngClass]="{'is-invalid' : company && company.touched && company.invalid}" name="company"
                [(ngModel)]="userCompanyGuid" #company="ngModel" autocomplete="off">
                <option value="">--Select One-</option>
                <option *ngFor="let company of availableCompanies" value="{{company.userCompanyGuid}}">{{company.companyName}}</option>
              </select>
              <div class="invalid-feedback">Please select a company.</div>
            </div>
            <div class="fv-row mb-5" *ngIf="userCompanyGuid">
              <label class="form-label fw-bolder text-dark fs-6">Password</label>
              <input class="form-control form-control-lg form-control-solid" type="password" name="password" required
                reverse="true" validateEqual="confirmedPassword"
                [ngClass]="{'is-invalid' : newPassword && newPassword.touched && newPassword.invalid}" autocomplete="off"
                [(ngModel)]="password" #newPassword="ngModel" />
              <div class="invalid-feedback" *ngIf="newPassword.errors && newPassword.errors.required">
                Please enter your new password.</div>
              <div class="invalid-feedback" *ngIf="newPassword.errors && newPassword.errors.validateEqual"
                class="invalid-feedback">
                Password & Confirm Password do not match.</div>
            </div>
            <div class="fv-row mb-5" *ngIf="userCompanyGuid">
              <label class="form-label fw-bolder text-dark fs-6">Confirm Password</label>
              <input class="form-control form-control-lg form-control-solid" type="password" name="confirmedPassword" required
                validateEqual="password"
                [ngClass]="{'is-invalid' : passwordToConfirm && passwordToConfirm.touched && passwordToConfirm.invalid}"
                autocomplete="off" [(ngModel)]="confirmedPassword" #passwordToConfirm="ngModel" />
              <div class="invalid-feedback" *ngIf="passwordToConfirm.errors && passwordToConfirm.errors.required">
                Please enter your password again.</div>
              <div class="invalid-feedback" *ngIf="passwordToConfirm.errors && passwordToConfirm.errors.validateEqual"
                class="invalid-feedback">
                Password & Confirm Password do not match.</div>
            </div>
          </div>
        </div>
        <div class="text-center">
          <button type="submit" class="btn btn-lg btn-primary w-100 mb-5"
            (click)="onResetPasswordClicked()">
            <span class="indicator-label">{{resetPasswordButtonText}}</span>
            <span *ngIf="spinnerVisible" class="spinner-border spinner-border-sm align-middle ms-2"></span>
          </button>
        </div>
      </form>
    </div>
  </div>
  <div class="d-flex flex-column justify-content-center align-items-center">
    <div class="login-footer">
      <img src="/assets/images/loda-logo.svg" alt="Lodasoft">
    </div>
  </div>

</ng-template>

<user-account-template [contentTemplate]="resetPassword"></user-account-template>

