<!--begin::Card-->
<div class="card pt-4 mt-4 mb-4 mb-xl-4 border">
  <!--begin::Card body-->
  <div class="card-body card-body-mobile pt-0 pe-3">
    <!--begin::Option-->
    <div class="py-0" data-kt-customer-payment-method="row">
      <!--begin::Header-->
      <div class="d-flex flex-stack flex-wrap">
        <div class="fs-6 fw-bolder text-dark">EMPLOYMENT HISTORY</div>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
       <!--begin::Table container-->
       <div class="table-responsive hidden-when-mobile">
        <!--begin::Table-->
        <table
          class="table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder">
          <!--begin::Head-->
          <thead class="fs-7 text-gray-400 text-uppercase">
            <tr>
              <th class="min-w-200px">Position</th>
              <th class="min-w-110px">Employer</th>
              <th class="min-w-90px">Start Date</th>
              <th class="min-w-90px">End Date</th>
            </tr>
          </thead>
          <!--end::Head-->
          <!--begin::Body-->
          <tbody class="fs-6">
            <tr *ngFor="let employment of employments;">
              <td>
                <!--begin::User-->
                <div class="d-flex align-items-center">
                  <!--begin::Wrapper-->
                  <div class="me-5 position-relative">
                  </div>
                  <!--end::Wrapper-->
                  <!--begin::Info-->
                  <div class="d-flex flex-column justify-content-center">
                    <span class="text-gray-800 fw-bold">{{employment.position}}</span>
                    <!--<a href="" class="fs-6 text-gray-800 text-hover-primary"></a> -->
                  </div>
                  <!--end::Info-->
                </div>
                <!--end::User-->
              </td>
              <td><span class="text-gray-800 fw-bold">{{employment.employer}}</span></td>
              <td><span class="text-gray-800 fw-bold">{{employment.startDate}}</span></td>
              <td><span class="text-gray-800 fw-bold">{{employment.endDate ? employment.endDate : "-" }}</span></td>
              <td><a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" (click)="onEditClicked(employment)">
                <span class="svg-icon svg-icon-3"><duotone-write-svg-icon>
                </duotone-write-svg-icon>
                </span></a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!--end::Body-->
      <div class="hidden-when-not-mobile">
        <div class="collapse show" data-bs-parent="#kt_customer_view_payment_method">
          <div class="rounded border border-primary row py-2 m-2" style="border-style: dashed !important;"
          *ngFor="let employment of employments;">
            <div class="w-100 d-flex align-items-center justify-content-between mb-3">
              <div class="text-gray-800 fw-bold">{{employment.employer}}</div>
              <a (click)="onEditClicked(employment)"
                class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                <span class="svg-icon svg-icon-3"><duotone-write-svg-icon>
                  </duotone-write-svg-icon>
                </span></a>
            </div>
            <div class="row mb-3">
              <div class="text-gray-400 fw-bold col-6">Position:</div>
              <div class="text-gray-800 fw-bold col-6">{{employment.position}}</div>
            </div>
            <div class="row mb-3">
              <div class="text-gray-400 fw-bold col-6">Start Date:</div>
              <div class="text-gray-800 fw-bold col-6">{{employment.startDate}}</div>
            </div>
            <div class="row mb-3">
              <div class="text-gray-400 fw-bold col-6">End Date:</div>
              <div class="text-gray-800 fw-bold col-6">{{employment.endDate ? employment.endDate : "-" }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end::Option-->
  </div>
</div>
