import { Component, Input, OnInit } from '@angular/core';
import { PricingScenario } from 'projects/borrower-app/src/app/models/pricing/pricing-scenario.model';

@Component({
  selector: 'scenario-taxes-editor',
  templateUrl: './scenario-taxes-editor.component.html',
  styleUrls: ['./scenario-taxes-editor.component.scss']
})
export class ScenarioTaxesEditorComponent implements OnInit {

  @Input()
  scenario: PricingScenario;

  constructor() {
  }

  ngOnInit(): void {
  }

}
