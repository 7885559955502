import { ChangeDetectorRef, Component, Input } from '@angular/core';

@Component({
  selector: 'loan-image',
  templateUrl: 'loan-image.component.html',
  styleUrls: ['./loan-image.component.scss']
})
export class LoanImageComponent{

  @Input() imageUrls: string[] = [];
  @Input() isSrcAllowed: boolean = false;
  @Input() size: number = 200;

  get imageUrl() {
    if (this._imageRetries > this.imageUrls.length - 1 || !this.isSrcAllowed) {
      return 'assets/images/street-view-fallback-img.png';
    }
    return this.imageUrls[this._imageRetries];
  }

  constructor(private cdr: ChangeDetectorRef) {
  }

  onImageLoaded() {
    this.detach();
  }

  onImageError() {
    this._imageRetries++;
  }

  private _imageRetries: number = 0;

  private detach() {
    this.cdr.detach();
  }


}
