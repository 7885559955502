<div class="modal-body">
  <div class="row">
    <div class="form-group mb-3">
      <label for="homeInsurance" class="form-label">Home Insurance</label>
      <currency-input [(ngModel)]="reo.hazardInsurance" [name]="'homeInsurance'"
        [allowDecimals]="false" [min]="0" [placeholder]="'Home Insurance'">
      </currency-input>
    </div>

    <div class="form-group mb-3">
      <label for="propertyTax" class="form-label">Property Tax</label>
      <currency-input [(ngModel)]="reo.propertyTax" [name]="'propertyTax'"
        [allowDecimals]="false" [min]="0" [placeholder]="'Property Tax'">
      </currency-input>
    </div>

    <div class="form-group mb-3">
      <label for="associationDues" class="form-label">Association Dues</label>
      <currency-input [(ngModel)]="reo.hoa" [name]="'hoa'"
        [allowDecimals]="false" [min]="0" [placeholder]="'Association Dues'">
      </currency-input>
    </div>

    <div class="form-group mb-3">
      <label for="miscExpenses" class="form-label">Misc. Expenses</label>
      <currency-input [(ngModel)]="reo.monthlyMiscExpenses" [name]="'monthlyMiscExpenses'"
        [allowDecimals]="false" [min]="0" [placeholder]="'Misc. Expenses'">
      </currency-input>
    </div>

  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="onCancelClicked()" data-bs-dismiss="modal"> Cancel
  </button>
  <button type="button" class="btn btn-primary" (click)="onOkClicked()">Ok</button>
</div>
