import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wizard-navigation',
  templateUrl: 'wizard-navigation.component.html'
})

export class WizardNavigationComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
