import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApplicationTaskModel } from '../../../models/borrower-portal/application-task-model';
import { PortalServiceFactory } from '../../../services/portal-service.factory';
import { PortalBaseComponent } from '../../portal-base.component';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { NotificationService } from 'projects/shared/services/notification.service';
import { getErrorMessageOrDefault } from 'projects/shared/utils/error.utils';

@Component({
  selector: 'app-add-files-to-task-dialog',
  templateUrl: './add-files-to-task-dialog.component.html',
  styleUrls: ['./add-files-to-task-dialog.component.scss']
})
export class AddFilesToTaskDialogComponent extends PortalBaseComponent implements OnInit {

  @Input()
  task: ApplicationTaskModel;

  isUploading = false;

  constructor(public activeModal: NgbActiveModal, private readonly portalServiceFactory: PortalServiceFactory,
    private readonly _notifsService: NotificationService
  ) {
    super(portalServiceFactory);
  }

  ngOnInit(): void {
  }

  protected onUploadClicked = (files: any[]) => {
    this.uploadFiles(files);
  }

  private uploadFiles = (files: any[]) => {
    files.forEach((file, index) => {
      this.portalService.uploadDocument(this.task.taskId, '', file).subscribe(
        (event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            this.isUploading = true;
            file.progress = Math.round(100 * event.loaded / event.total);
          } else if (event instanceof HttpResponse) {
            this.isUploading = false;
            this.activeModal.close();
          }
        },
        (err: any) => {
          const errorMessage = getErrorMessageOrDefault(err, {
            defaultMessage: 'Error uploading documents to task.',
          });
          this._notifsService.showError(errorMessage, 'Error!');
          file.progress = 0;
          this.isUploading = false;
          this.activeModal.close();
        });
    });
  }
}
