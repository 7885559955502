<div class="modal-header">
  <h5 class="modal-title d-flex align-items-center">Enter Your Payment Information</h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>

<div class="modal-body">
  <h5 class="mx-4">{{task.description}}</h5>
  <form #paymentOrderForm="ngForm" novalidate>
    <payment-order-details [paymentInfo]="paymentInfoDetails"></payment-order-details>
  </form>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-sm btn-secondary" (click)="activeModal.dismiss()" data-bs-dismiss="modal">
    Cancel
  </button>
  <button type="button" class="btn btn-sm bp-primary-bg-color" (click)="save()" data-bs-dismiss="modal">
    Save
  </button>
</div>
