import { Injectable } from "@angular/core";
import { DataService } from "./data.service";
import { BorrowerVerificationUserEnrollmentRequest, BorrowerVerificationUserEnrollmentResponse } from "../models/borrower-verification-user-enrollment.model";
import { Observable } from "rxjs";
import { BorrowerVerificationOrderHistory } from "../models/borrower-verification-order-history.model";

@Injectable()
export class IncomeService {

    constructor(private readonly _dataService: DataService)
    {
    }

    public markFormFreeForBorrowerComplete = (borrowerId: number, applicationId: number): Observable<any> => {
      const url = `api/online-app/MarkFormFreeForBorrowerComplete/${applicationId}/${borrowerId}`;
      return this._dataService.post(url, {});
    }

    public verifyIncomeAndEmploymentData(applicationId: number, borrowerContactId: number) {
      const url = `api/online-app/twn/verification`;
      const payLoad = {
        applicationId: applicationId,
        borrowerContactId: borrowerContactId,
        requestType: 'IncomeAndEmployment',
        employeeStatusFilter: 'Active'
        };
      return this._dataService.post(url, payLoad);
    }

    public initializeBorrowerIncomeAndEmploymentVerification = (borrowerId: number, applicationId: number, integrationProvider)
        : Observable<BorrowerVerificationUserEnrollmentResponse> => {
      const request: BorrowerVerificationUserEnrollmentRequest = {
        applicationId: applicationId,
        borrowerId: borrowerId,
        maxHeightInPixels: 600,
        maxWidthInPixels: 800,
        integrationProvider: integrationProvider,
        allowedProducts: ['Employment', 'Income'],
        inviteAllApplicationBorrowers: true
      };
      const url = `api/online-app/borrower-verification/invite`;
      return this._dataService.post(url, request);
    }

    public getVerificationOrderHistoryForTask = (loanDocTaskId: number): Observable<BorrowerVerificationOrderHistory> => {
      const url = `api/form-free/orders/task/${loanDocTaskId}`;
      return this._dataService.get(url);
    }
  }
