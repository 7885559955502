import { Injectable } from "@angular/core";
import { WizardFlowContext } from "../../../wizard-flow-context.model";
import { GenericStepFactory } from "./generic-step.factory";
import { NextStepDeciderFactory } from "./next-step-decider.factory";
import { ICreateStep } from "./step-factory.interface";
import { OwnRentStep } from "../../own-rent-step-model";
import { OwnRentStepConfig } from "../own-rent-step-config.model";

@Injectable()
export class OwnRentStepFactory extends GenericStepFactory<OwnRentStep> implements ICreateStep {

  constructor(nextStepDeciderFactory: NextStepDeciderFactory) {
    super(nextStepDeciderFactory, OwnRentStep);
  }

  create(config: OwnRentStepConfig, wizardFlowContext: WizardFlowContext): OwnRentStep {
    let ownRentStep = super.create(config, wizardFlowContext)
    ownRentStep.ownActionLabel = config.ownActionLabel || 'Own';
    ownRentStep.rentActionLabel = config.rentActionLabel || 'Rent';
    ownRentStep.liveRentFreeActionLabel = config.liveRentFreeActionLabel || 'Live Rent Free';
    return ownRentStep;
  }

  configure(step: OwnRentStep, config: OwnRentStepConfig): void {
    super.configure(step, config);
    config.ownActionLabel = step.ownActionLabel;
    config.rentActionLabel = step.rentActionLabel;
    config.liveRentFreeActionLabel = step.liveRentFreeActionLabel;
  }
}
