import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from 'projects/borrower-app/src/app/services/auth.service';
import { NavigationService } from 'projects/borrower-app/src/app/services/navigation.service';
import { WizardFlowServiceBase } from 'projects/borrower-app/src/app/services/wizard/wizard-flow-service.base';
import { UserInfo } from '../models/user-info.model';
import { EnvironmentService } from '../services/environment.service';
import { ApplicationContextService } from 'projects/borrower-app/src/app/services/application-context.service';

@Component({
  selector: 'header',
  templateUrl: 'header.component.html'
})
export class HeaderComponent implements OnInit {

  @Input() userInfo: UserInfo | undefined;

  possibleLogoUrls: string[] = [];

  constructor(
    private readonly _authService: AuthService,
    private readonly _environment: EnvironmentService,
    private readonly _navigationService: NavigationService,
    private readonly _wizardFlowService: WizardFlowServiceBase,
    private readonly _applicationContextService: ApplicationContextService) {
    this.possibleLogoUrls = this._navigationService.possibleLogoUrls;
    if (this._wizardFlowService.companyId) {
      this.possibleLogoUrls.push(this._environment.companyLogoUrl + this._wizardFlowService.companyId);
    }
  }

  public get isLoggedIn(): boolean {
    const isLoggedIn = this._authService.isLoggedIn();
    return isLoggedIn;
  }

  logout = () => {
    this._authService.logout();
    this._wizardFlowService.resetFlows();
    this._navigationService.navigateToLogin(undefined, false);
    this._applicationContextService.reset();
  }

  redirectToBorrowerPortal = () => {
    this._navigationService.navigateToPath('borrower-portal', true);
  }

  ngOnInit() {
  }
}
