import { Component, ComponentFactoryResolver, EventEmitter, Input, OnDestroy, OnInit, Output, ViewContainerRef } from "@angular/core";
import { WizardFlowSettings } from "projects/borrower-app/src/app/models/wizard/config/flow-settings.model";
import { Subscription } from "rxjs";

@Component({
  selector: 'dynamically-rendered-content',
  template: ''
})
export class DynamicallyRenderedContentComponent implements OnInit, OnDestroy {

  private _component: any;

  @Input()
  set component(component: any) {
    this._component = component;
    if (this._component) {
      this.render();
    }
  }

  @Input() data: any;

  @Output()
  wizardFlowSettingsChanged: EventEmitter<WizardFlowSettings> = new EventEmitter<WizardFlowSettings>();

  private _wizardFlowSettingsChangedSubscription: Subscription;

  constructor(public viewContainerRef: ViewContainerRef,
              private componentFactoryResolver: ComponentFactoryResolver) { }
  ngOnInit() {
    if (!this._component) {
      return;
    }
    this.render();
  }

  ngOnDestroy(): void {
    if (this._wizardFlowSettingsChangedSubscription) {
      this._wizardFlowSettingsChangedSubscription.unsubscribe();
    }
  }

  private render = () => {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this._component);
    this.viewContainerRef.clear();

    const componentRef = this.viewContainerRef.createComponent(componentFactory);
    if ((componentRef.instance as any).wizardFlowSettingsChanged) {
      this._wizardFlowSettingsChangedSubscription = (componentRef.instance as any).wizardFlowSettingsChanged.subscribe(e => {
        this.wizardFlowSettingsChanged.emit(e);
      })
    }
  }
}
