
<form #addressHistoryForm="ngForm" novalidate id="addressHistoryForm" name="addressHistoryForm">
  <div class="fs-4 fw-bolder text-dark">{{currentAddressLabel}}</div>
  <div class="separator separator-dashed my-3"></div>
  <address-history2
    #currentAddressHistory
    [residencyAddress]="currentAddress"
    [fieldsToConfig]="fieldConfig"
    (durationAtAddressChanged)="onDurationAtAddressChanged($event)"
    (ownershipStatusChanged)="onOwnershipStatusChanged($event)"
    (addressChanged)="onAddressChanged($event)">
  </address-history2>
  <div *ngIf="currentAddress.residencyBasis === 'Own'">
    <reo-properties
      [reo]="reoForCurrentAddress"
      [fieldsToConfig]="reoFieldsToConfig"
      #reoProperties>
    </reo-properties>
  </div>
  <div class="alert alert-warning d-flex align-items-center p-5 mb-10" *ngIf="showInsufficientAddressHistoryWarning">
    <span class="svg-icon svg-icon-2hx svg-icon-warning me-4">
      <duotone-warning-circle-svg-icon></duotone-warning-circle-svg-icon>
    </span>
    <div class="d-flex flex-column">
      <h5 class="mb-1">You need at least 2 years of address history</h5>
      <span>Please add all the previous addresses you lived at until you give us at least 2 years of address
        history</span>
    </div>
  </div>
  <div *ngIf="previousAddresses.length > 0" class="fs-4 fw-bolder text-dark">Previous Addresses:</div>
<div *ngIf="previousAddresses.length > 0" class="separator separator-dashed my-3"></div>
  <div *ngFor="let previousAddress of previousAddresses; let i = index"
    class="rounded border p-10 pb-0 mb-6 align-items-center flex-wrap ribbon ribbon-top ribbon-vertical"
    [ngClass]="{'border-danger' : previousAddressToDelete === previousAddress}">
    <div class="ribbon-label bg-white">
      <i *ngIf="previousAddressToDelete !== previousAddress" class="bi bi-trash fs-2 text-warning"
        (click)="onPreviousAddressDeleteClicked(previousAddress)"></i>
      <i *ngIf="previousAddressToDelete === previousAddress" class="bi bi-x fs-2 text-danger"
        (click)="onPreviousAddressDeleteCancelClicked(previousAddress)"></i>
      <i *ngIf="previousAddressToDelete === previousAddress" class="bi bi-check fs-2 text-success"
        style="padding-right: 4px;" (click)="onPreviousAddressDeleteConfirmClicked(previousAddress)"></i>
    </div>
    <address-history2 #previousAddressHistory (durationAtAddressChanged)="onDurationAtAddressChanged($event)"
      [residencyAddress]="previousAddress" [fieldsToConfig]="fieldConfig">
    </address-history2>
  </div>
</form>
