import {
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { SvgIconComponentFactory } from 'projects/shared/svg-icons/svg-icon-component-factory';
import { SvgIconHostDirective } from 'projects/shared/svg-icons/svg-icon-host.directive';

@Component({
  selector: 'wizard-horizontal-nav-step',
  template: `<div
    class="wizard-step"
    data-wizard-type="step"
    [attr.data-wizard-state]="state"
  >
    <div
      class="wizard-label position-relative"
      [ngClass]="{ 'label-margin': index && index == 3 }"
    >
      <i class="wizard-icon {{ iconClass }} fa-2x">
        <ng-template svgIconHost></ng-template>
      </i>

      <h3 class="wizard-title">
        <span style="display: inline;">{{ index + 1 }}</span>
          &nbsp;&nbsp;
        <span *ngIf="!inEditMode" class="wizard-title-mobile">{{ title }}</span>
        <input *ngIf="inEditMode" [name]="'nav_step_title_' + index" type="text"
          class="form-control form-control-solid fs-4 fw-bolder" [(ngModel)]="title"
          (blur)="onStepTitleChanged()"
           />
        <span
          class="svg-icon svg-icon-1 svg-icon-success svg-success-icon-style"
          *ngIf="state === 'complete'"
        >
          <duotone-done-circle-svg-icon></duotone-done-circle-svg-icon>
        </span>
      </h3>
    </div>
    <span
      class="svg-icon svg-icon-2x wizard-arrow"
      *ngIf="index + 1 < numberOfTotalSteps"
    >
      <navigation-right-svg-icon></navigation-right-svg-icon>
    </span>
  </div>`,
  styleUrls: ['wizard-horizontal-nav-step.component.scss'],
})
export class WizardHorizontalNavStepComponent implements OnInit {

  @Input() inEditMode: boolean = false;
  @Input() state: string = 'pending';
  @Input() index: number = 0;
  @Input() title: string = '';
  @Input() iconClass: string = '';
  @Input() svgIconName: string = '';
  @Input() numberOfTotalSteps: number = 0;

  @Output()
  stepTitleChanged: EventEmitter<StepTitleChangedEvent> = new EventEmitter<StepTitleChangedEvent>();

  @ViewChild(SvgIconHostDirective, { static: true })
  svgIconHost!: SvgIconHostDirective;

  constructor(
    private _svgIconFactory: SvgIconComponentFactory,
    private _componentFactoryResolver: ComponentFactoryResolver
  ) {}

  ngOnInit(): void {
    this.loadIcon(this.svgIconName);
  }

  onStepTitleChanged = () => {
    this.stepTitleChanged.emit({stepIndex: this.index, newTitle: this.title});
  }

  loadIcon = (svgIconName: string) => {
    if (!svgIconName || svgIconName.length === 0) {
      return;
    }
    const svgIconComponent =
      this._svgIconFactory.getSvIconComponent(svgIconName);
    if (!svgIconComponent) {
      return;
    }
    const componentFactory =
      this._componentFactoryResolver.resolveComponentFactory(svgIconComponent);

    const viewContainerRef = this.svgIconHost.viewContainerRef;
    viewContainerRef.clear();

    viewContainerRef.createComponent(componentFactory);
  };
}

export class StepTitleChangedEvent {
  newTitle: string;
  stepIndex: number;
}
