export class CreditInquiryDetails {
  creditInquiryDetailsId: number | undefined;
  creditId: number | undefined;
  documentSigningOrderId: number | undefined;
  entries: CreditInquiryDetailsEntry[] = [];
  companyId: number | undefined;
  insertedBy: string | undefined;
  dateInserted: string | undefined;
  updatedBy: string | undefined;
  dateUpdated: string | undefined;
}

export class CreditInquiryDetailsEntry {
  reason: string | undefined; //['AutoLoan', 'CreditCard', 'MortgageShopping'],
  newDebtAcquired: boolean = false;
  creditInquiryDate: string | undefined;
  creditRepositorySourceType: string | undefined;
  fullName: string | undefined;
}
