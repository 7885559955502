import { IDecideNextStep } from "projects/borrower-app/src/app/services/wizard/next-step-decider/next-step-decider.interface";
import { WizardFlowContext } from "../../../wizard-flow-context.model";
import { WizardStepBase } from "../../wizard-step-base.model";
import { FieldConfigUtils } from "../field-config-utils";
import { GenericStepConfig } from "../../generic-step-config.model";
import { StepConfig } from "../step-config.model";
import { NextStepDeciderFactory } from "./next-step-decider.factory";
import { StepFactoryBase } from "./step-factory-base";
import { ICreateStep } from "./step-factory.interface";

export type Constructor<T> = new (...args: any[]) => T;

export class GenericStepFactory<TStep extends WizardStepBase> extends StepFactoryBase<GenericStepConfig> implements ICreateStep {

  private readonly _nextStepDeciderFactory: NextStepDeciderFactory;
  private readonly _cls: Constructor<TStep>;

  constructor(nextStepDeciderFactory: NextStepDeciderFactory, cls: Constructor<TStep>) {
    super();
    this._nextStepDeciderFactory = nextStepDeciderFactory;
    this._cls = cls;
  }

  configure(step: WizardStepBase, config: StepConfig): void {
    config.explanation = step.explanation;
    config.title = step.title;
    config.heading = step.heading;
    config.groupId = step.groupId;
  }

  create(config: StepConfig, wizardFlowContext: WizardFlowContext): TStep {
    let nextStepDecider: IDecideNextStep | undefined = undefined;
    if (config.nextStepDeciderConfig) {
      nextStepDecider = this._nextStepDeciderFactory.create(config.nextStepDeciderConfig, wizardFlowContext);
    }
    let step: TStep;
    step = this.createStepInstance(config.id, config.name, config.path, nextStepDecider, this._cls);
    step.title = config.title;
    step.explanation = config.explanation;
    step.heading = config.heading;
    step.groupId = config.groupId;
    let fieldConfig = config.fieldConfig;
    if (!fieldConfig || !Object.keys(fieldConfig).length) {
      fieldConfig = FieldConfigUtils.generateDefaultFieldConfigForStep(config.stepType);
      config.fieldConfig = fieldConfig;
    }
    step.fieldConfig = fieldConfig;
    return <TStep><unknown>step;
  }

  private createStepInstance = <T>(
    id: string, name: string | undefined, path: string, nextStepDecider: IDecideNextStep | undefined,
    destinationConstructor: Constructor<T>
  ): T => new destinationConstructor(id, name, path, nextStepDecider);
}

