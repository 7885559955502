import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { PageUtils } from '../utils/page.utils';

@Component({
  selector: 'favicon-loader',
  templateUrl: 'favicon-loader.component.html'
})
export class FaviconLoaderComponent {

  @Input()
  faviconUrl: string = '/assets/images/favicon.ico';

  constructor(private cdr: ChangeDetectorRef) {
  }

  onImageLoaded() {
    PageUtils.addFavicon(this.faviconUrl);
    this.detach();
  }

  onImageError() {
  }

  private detach() {
    this.cdr.detach();
  }
}
