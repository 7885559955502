import { ContextType } from "./context-type.enum";
import { StepConfig } from "./step-config.model";
import { StepType } from "./step-type.enum";

export class CreateAccountStepConfig extends StepConfig {

  constructor(id: string, name: string | undefined, path: string, groupId: number | undefined) {
    super(id, name, path, groupId, ContextType.None);
    this.stepType = StepType.CreateAccount;
  }
}
