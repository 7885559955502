import { IDecideNextStep } from "../../services/wizard/next-step-decider/next-step-decider.interface";
import { CreditScoresStepComponent } from "../../wizard/wizard-steps/credit-scores-step/credit-scores-step.component";
import { WizardStepBase } from "./wizard-step-base.model";

export class CreditScoresStep extends WizardStepBase {

  useScoreIndicatorIcons: boolean = true;

  constructor(id: string, name: string | undefined, path: string, nextStepDecider?: IDecideNextStep) {
    super(id, name, path, nextStepDecider);
    this.component = CreditScoresStepComponent;
  }
}
