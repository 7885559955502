<div class="card mt-3 group-container-repeat">
  <div class="card-header bg-primary" [title]="scenario.productName">
    <h4 class="card-title text-white">{{ scenario.productName }}</h4>
  </div>
  <div class="card-body">
    <div class="text-muted text-scenario">
      <span class="card-text">
        <a class="inline-edit-link">
          <span class="fs-larger">
            {{ scenario.name ? scenario.name : "Scenario " + index }}
          </span>
        </a>
      </span>
    </div>
    <hr />
    <div class="text-muted">
      <span>
        {{
          scenario.pricingRequestPayload &&
          scenario.pricingRequestPayload.loanInformation
            ? (scenario.pricingRequestPayload.loanInformation.baseLoanAmount
              | currency)
            : ""
        }}
      </span>
    </div>
    <div>
      <span class="card-text text-muted"><strong>LOAN AMOUNT</strong></span>
    </div>
    <hr />
    <div class="text-muted">
      <span
        >{{
          scenario.pricingRequestPayload &&
          scenario.pricingRequestPayload.propertyInformation
            ? (scenario.pricingRequestPayload.propertyInformation.appraisedValue
              | currency)
            : ""
        }}
      </span>
    </div>
    <div>
      <span class="card-text text-muted"><strong>APPRAISED VALUE</strong></span>
    </div>
    <hr />
    <div class="text-info">
      <span
        >{{
          scenario.adjustedRate
            ? (scenario.adjustedRate / 100 | percent : "1.3-3")
            : "Unknown"
        }}
        /
        {{
          scenario.apr ? (scenario.apr / 100 | percent : "1.3-3") : "Unknown"
        }}
      </span>
    </div>
    <div class="mt-1">
      <span class="pt-1 card-text text-info"><strong> RATE / APR</strong></span>
    </div>
    <hr />
    <div class="mt-1">
      <div class="row">
        <span class="col-12 cut-notes text-muted text-start" title="{{scenario.borrowerNotes}}"
          >Notes: {{ scenario.borrowerNotes }}
        </span>
      </div>
    </div>
    <hr />
    <div class="mt-2" *ngIf="scenario.history.length > 0">
      <apx-chart
        #chart
        [series]="chartOptions.series"
        [labels]="chartOptions.labels"
        [chart]="chartOptions.chart"
        [dataLabels]="chartOptions.dataLabels"
        [xaxis]="chartOptions.xaxis"
        [yaxis]="chartOptions.yaxis"
        [grid]="chartOptions.grid"
        [markers]="chartOptions.markers"
        [stroke]="chartOptions.stroke"
      ></apx-chart>
    </div>
    <hr *ngIf="scenario.history.length > 0" />
    <div class="text-info">
      <span
        *ngIf="scenario.adjustedPrice >= 100.0"
        class="text-muted price-rebate"
        >{{ scenario.rebatePercent }} /
        {{ scenario.rebateDollars | currency }}</span
      >
      <span
        *ngIf="scenario.adjustedPrice < 100.0"
        class="text-muted price-discount"
        >({{ scenario.discountPercent }}) / ({{
          scenario.discountDollars | currency
        }})</span
      >
    </div>
    <div>
      <span class="card-text text-info"><strong> POINTS </strong> </span>
    </div>
    <hr />
    <div class="text-info">
      <span
        >{{
          scenario.principalAndInterest
            ? (scenario.principalAndInterest | currency)
            : "Unknown"
        }}
        /
        {{
          scenario.totalPayment ? (scenario.totalPayment | currency) : "Unknown"
        }}
        <i
          class="fas fa-info-circle ms-1"
          [ngbPopover]="templateTaxes"
          triggers="manual"
          #p1="ngbPopover"
          (click)="togglePopover(p1)"
        ></i>
      </span>
      <ng-template #templateTaxes>
        <scenario-taxes-editor [scenario]="scenario"> </scenario-taxes-editor>
      </ng-template>
    </div>
    <div>
      <span class="card-text text-info"
        ><strong>MONTHLY P&I / PI(TI)</strong>
      </span>
    </div>
    <hr />
    <div class="text-info">
      <span class="text-danger">
        {{ feesTotal | currency }} <strong> </strong>
      </span>
    </div>
    <div>
      <span class="card-text text-info"><strong> SCENARIO FEES </strong></span>
    </div>
    <hr />
    <div class="text-info">
      <span class="text-danger"
        >{{ prepaidFeesTotal | currency }}<strong> </strong>
      </span>
    </div>

    <div>
      <span class="card-text text-info"><strong> PREPAIDS </strong></span>
    </div>
    <hr />
    <div class="text-muted">
      <span>{{
        scenario.totalClosingCost
          ? (scenario.totalClosingCost | currency)
          : "Unknown"
      }}</span>
    </div>
    <div>
      <span class="card-text text-muted"><strong> CASH TO CLOSE </strong></span>
    </div>
  </div>
</div>
