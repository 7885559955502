import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { GeneratePrequalLetterRequestModel } from 'projects/borrower-app/src/app/models/borrower-portal/generate-prequal-letter-request.model';

@Component({
  selector: 'prequal-letter-wizard-purchase-price',
  templateUrl: './prequal-letter-wizard-purchase-price.component.html'
})
export class PrequalLetterWizardPurchasePriceComponent implements OnInit {

  @ViewChild('purchasePriceForm') purchasePriceForm: NgForm | undefined;

  @Input() request: GeneratePrequalLetterRequestModel;
  @Input() maxPurchasePrice: number;

  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() next: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() back: EventEmitter<boolean> = new EventEmitter<boolean>();

  minLoanAmountValue: number = 10000;
  maxLoanAmountValue: number;

  constructor() { }

  ngOnInit(): void {
    this.maxLoanAmountValue = this.request.purchasePrice = this.maxPurchasePrice;
  }

  nextClicked = () => {
    this.purchasePriceForm.form.markAllAsTouched();
    if (this.purchasePriceForm.form.valid) {
      this.next.emit();
    }
  }

  closeClicked = () => {
    this.close.emit();
  }

  backClicked = () => {
    this.purchasePriceForm.form.markAllAsTouched();
    if (this.purchasePriceForm.form.valid) {
      this.back.emit();
    }
  }

}
