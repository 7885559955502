<div class="post d-flex flex-column-fluid">
  <div class="container">
    <div class="card">
      <div class="card-body p-0">
        <div class="card-px text-center py-20 my-10">
          <h2 class="fs-2x fw-bolder mb-10">{{heading}}</h2>
          <p class="text-gray-400 fs-4 fw-bold mb-4">{{message1}}</p>
          <p class="text-gray-400 fs-4 fw-bold mb-10">{{message2}}</p>
          <a (click)="onGotoBorrowerPortalClicked()" class="btn btn-success">Take Me to Borrower Portal</a>
        </div>
        <div class="text-center px-4" *ngIf="showImage">
          <img class="mw-100 mh-300px" alt="" src="assets/media/illustrations/terms-1.png" />
        </div>
      </div>
    </div>
  </div>
</div>
