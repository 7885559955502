import { Injectable } from "@angular/core";
import { CanDeactivate } from "@angular/router";
import { WizardFlowServiceBase } from "./wizard-flow-service.base";

@Injectable()
export class BrowserNavigationDeactivateGuard implements CanDeactivate<any> {
  constructor(private readonly _wizardFlowService: WizardFlowServiceBase) {}
  canDeactivate(component: any) {
    // will prevent user from going back
    if (this._wizardFlowService.browserBackOrForwardClicked) {
      this._wizardFlowService.browserBackOrForwardClicked = false;
      // push current state again to prevent further attempts.
      history.pushState('', '', location.href);
      return false;
    }
    return true;
  }
}


