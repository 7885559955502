import { Injectable } from "@angular/core";
import { NgbModal, NgbModalOptions, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";

declare const Swal: any;

@Injectable()
export class ModalService {

  container: string;

  constructor(private readonly _modal: NgbModal) {
    this.container = 'body';
  }

  show = (content: any, options?: NgbModalOptions): NgbModalRef => {
    if (!options) {
      const modalOptions: NgbModalOptions = {
        //size: "sm",
        backdrop: "static",
        container: "body"
      };
      options = modalOptions;
    }
    const modalRef = this._modal.open(content, {
        container: this.container,
        ...(options ? options : {})
    });
    return modalRef;
  }

  showErrorMessage = (header?: string, message?: string) => {
    const headerToShow = header ? header : "Error!";
    const messageToShow = message ? message : "An error has occurred, please contact your system administrator."
    Swal.fire(
      headerToShow,
      messageToShow,
      'error'
    );
  }
}
