export class CharacteristicMergeValue {
  characteristicMergeValueId: number | undefined;
  characteristicFieldId: number;
  borrowerCharacteristicId: number | undefined;
  loanCharacteristicId: number | undefined;
  mergeValue: string | undefined;

  constructor(characteristicFieldId: number) {
    this.characteristicFieldId = characteristicFieldId;
  }
}
