<form #propertyAddressForm="ngForm" novalidate id="propertyAddressForm" name="propertyAddressForm">
<div style="min-height: 500px;">
  <div class="w-100">
    <div class="pb-10 pb-lg-15">
      <h2 class="fw-bolder text-dark">Where are you interested in buying?</h2>
      <div class="text-gray-400 fw-bold fs-6">If you need more info, please check out
      <a href="#" class="text-primary fw-bolder">Help Page</a>.</div>
    </div>

    <div class="row mb-10">
      <div class="col-md-4 fv-row">
        <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
          <span class="required">City</span>
        </label>
        <div class="position-relative">
          <input type="text" [(ngModel)]="city"
          class="form-control form-control-solid" placeholder="" name="city"
          [ngClass]="{'is-invalid' : !isValidCityState() && propertyCity.touched &&
          propertyState.touched && !isValidZipCode()
        }" #propertyCity="ngModel" />
          <div class="invalid-feedback">City and state or zipcode required.</div>
        </div>
      </div>
      <div class="col-md-4 fv-row">
        <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
          <span class="required">State</span>
        </label>
        <div class="position-relative">
          <div class="col-12">
            <select name="state" [(ngModel)]="state" class="form-select form-select-solid"
            data-control="select2" data-hide-search="true" data-placeholder="Select a State"
            #propertyState="ngModel">
              <option></option>
              <option *ngFor="let state of states; let i = index" value="{{state.value}}">
                {{state.name}}
              </option>
            </select>
            <div class="invalid-feedback">Please enter a valid state.</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-10">
      <div class="col align-self-center">
          <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
            <span>OR</span>
          </label>
      </div>
    </div>
    <div class="row mb-10">
      <div class="col-md-4 fv-row">
        <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
          <span class="required">Zipcode</span>
        </label>
        <div class="position-relative">
          <input type="text" [(ngModel)]="zipCode" class="form-control form-control-solid"  placeholder="" name="zipCode"
          #propertyZipcode="ngModel" [ngClass]="{'is-invalid' : propertyZipcode.touched && !isValidZipCode() &&
        propertyCity.touched && propertyState.touched && !isValidCityState()}"/>
          <div class="invalid-feedback">Please enter a valid zip code.</div>
        </div>
      </div>
    </div>
    <wizard-button-group></wizard-button-group>
</div>
</div>
</form>
