import { Component, HostListener, Injector, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { LoanPurpose } from '../../../models/enums/loan-purpose.enum';
import { LoanType } from '../../../models/loan-type-model';
import { MortgageApplication } from '../../../models/mortgage-app.model';
import { TransactionDetail } from '../../../models/transaction-detail.model';
import { TypeOfLoanStep } from '../../../models/wizard/type-of-loan-step.model';
import { EnumsService } from '../../../services/enums.service';
import { WizardStepComponentBase } from '../wizard-step-base.component';
import { SubjectProperty } from '../../../models/subject-property.model';
import { Mortgage } from '../../../models/mortgage.model';

@Component({
  selector: 'type-of-loan-step',
  templateUrl: 'type-of-loan-step.component.html',
})
export class TypeOfLoanStepComponent
  extends WizardStepComponentBase<TypeOfLoanStep>
  implements OnInit {
  @ViewChild('loanInfoForm') loanInfoForm: NgForm | undefined;

  loanTypes: LoanType[] = [];

  loanTypeId: string | undefined;

  estimatedLoanAmount: number | undefined;

  downpayment: number | undefined;

  defaultLoanType: LoanType | undefined;

  mortgage: MortgageApplication;

  protected isRefinance: boolean = false;

  protected isMobile: boolean = false;

  protected homeValue: number;

  constructor(
    private readonly _injector: Injector,
    private readonly _enumsService: EnumsService
  ) {
    super(_injector);
    if (!this.mortgageApplication.transactionDetail) {
      this.mortgageApplication.transactionDetail = new TransactionDetail();
    }
    if (!this.mortgageApplication.subjectProperty) {
      this.mortgageApplication.subjectProperty = new SubjectProperty();
    }
    this.saveMortgageApplicationBeforeNextStep = true;
    this.loadLoanTypes();
    this.estimatedLoanAmount = this.mortgageApplication.mortgageTerm.amount;
    this.homeValue = this.calculateHomeValue(this.mortgageApplication);
    this.downpayment = this.calculateDownPayment(this.mortgageApplication);
    this.mortgage = this.mortgageApplication;
    if (!this.mortgage.subjectProperty.downPaymentSource) {
      this.mortgage.subjectProperty.downPaymentSource = null;
    }
    if (!this.mortgage.mortgageTerm.mortgageAppliedFor) {
      this.mortgage.mortgageTerm.mortgageAppliedFor = null;
      this.loanTypeId = null;
    }
  }

  public loadLoanTypes() {
    if (this.wizardFlowService.isEditEnabled) {
      this.setupSomeDefaultDataForEditMode();
      return;
    }
    this.mortgageApplicationService
      .getLoanTypes(this.mortgageApplication.applicationId)
      .subscribe((loanTypes) => {
        const sortedLoanTypes = loanTypes.sort((a, b) =>
          a.order! > b.order! ? 1 : -1
        );
        this.loanTypes = sortedLoanTypes.filter(
          (lt) => lt.mortgageAppliedForType != null
        );

        this.defaultLoanType = this.loanTypes.find(
          (lt) => lt.defaultForOnlineApp
        );

        const mortgageType = this._enumsService.mortgageTypes.find(
          (mt) =>
            mt.value == this.mortgageApplication.mortgageTerm.mortgageAppliedFor
        );
        const selectedLoanType = this.loanTypes.find(
          (lt) => lt.mortgageAppliedForType == mortgageType?.name
        );
        if (selectedLoanType) {
          this.loanTypeId = selectedLoanType.loanTypeId?.toString();
        }
      });
  }

  public get downPaymentSourceTypes(): any[] {
    return this._enumsService.downPaymentSourceTypes.filter(x => x.name != "Cash On Hand");
  }

  public get estimatedLTV(): number | undefined {
    if (
      this.mortgageApplication.subjectProperty.purposeOfLoan ===
      LoanPurpose.Refinance
    ) {
      const loanAmount = this.mortgageApplication!.mortgageTerm!.amount;
      const estValue = this.mortgageApplication!.subjectProperty!.presentValue;
      if (!loanAmount || loanAmount === 0 || !estValue || estValue === 0) {
        return undefined;
      }
      return Math.round((loanAmount / estValue) * 100);
    } else {
      let purchasePriceAmount: number =
        this.mortgageApplication!.transactionDetail!.purchasePriceAmount!;
      if (!purchasePriceAmount || purchasePriceAmount === 0) {
        return undefined;
      }
      let loanAmount =
        this.mortgageApplication!.transactionDetail!.purchasePriceAmount! -
        this.downpayment!;
      return Math.round(
        (loanAmount /
          this.mortgageApplication!.transactionDetail!.purchasePriceAmount!) *
        100
      );
    }
  }

  onEstimatedValueChanged = () => {
    if (!this.mortgageApplication.subjectProperty.presentValue) {
      this.mortgageApplication.subjectProperty.presentValue = undefined;
    }
  };

  onLoanAmountChanged = () => {
    if (!this.estimatedLoanAmount) {
      this.estimatedLoanAmount = undefined;
    }
  };

  onNextClicked() {
    if (this.loanInfoForm) {
      this.loanInfoForm.form.markAllAsTouched();
      if (this.loanInfoForm.form.valid) {
        this.mortgageApplication.mortgageTerm.amount = this.estimatedLoanAmount;
        if (this.wizardFlowService.isEditEnabled || !this.loanTypeId) {
          this.mortgageApplication.mortgageTerm.mortgageAppliedFor = null;
          super.onNextClicked();
          return;
        }
        if (this.loanTypeId === '-1') {
          if (this.defaultLoanType) {
            this.mortgageApplication.mortgageTerm.mortgageAppliedFor =
              this.defaultLoanType.mortgageAppliedForType;
          }
          super.onNextClicked();
          return;
        }
        this.startSpinner();
        this.mortgageApplicationService
          .saveLoanType(
            this.mortgageApplication.applicationId,
            this.loanTypeId!
          )
          .subscribe(
            () => {
              this.stopSpinner();
              const selectedLoanType = this.loanTypes.find(
                (lt) => lt.loanTypeId.toString() == this.loanTypeId
              );
              if (selectedLoanType) {
                this.mortgageApplication.mortgageTerm.mortgageAppliedFor =
                  selectedLoanType.mortgageAppliedForType;
              }
              super.onNextClicked();
            },
            (error) => {
              this.stopSpinner();
            }
          );
      }
    }
  }

  ngOnInit() {
    // This is just to make the validation work properly
    if (
      this.mortgageApplication.transactionDetail.purchasePriceAmount != null &&
      this.mortgageApplication.transactionDetail.purchasePriceAmount <= 0
    ) {
      this.mortgageApplication.transactionDetail.purchasePriceAmount =
        undefined;
    }
    this.isRefinance = this.mortgage.subjectProperty.purposeOfLoan === 'Refinance';

    this.configureBasedOnScreenSize(window.innerWidth);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.configureBasedOnScreenSize(window.innerWidth);
  }

  onPurchasePriceChanged = () => {
    if (
      this.mortgageApplication.transactionDetail.purchasePriceAmount != null &&
      this.mortgageApplication.transactionDetail.purchasePriceAmount <= 0
    ) {
      this.mortgageApplication.transactionDetail.purchasePriceAmount =
        undefined;
    }
    this.homeValue = this.calculateHomeValue(this.mortgageApplication);
    this.estimatedLoanAmount = this.calculateEstimatedLoanAmount(this.mortgageApplication);
  };

  onDownPaymentChanged = () => {
    if (!this.downpayment) {
      this.downpayment = undefined;
      this.mortgageApplication.subjectProperty.downPaymentSource = null;
    }
    this.estimatedLoanAmount = this.calculateEstimatedLoanAmount(this.mortgageApplication);
  };

  setupSomeDefaultDataForEditMode = () => {
    let conventional = new LoanType();
    conventional.loanTypeName = 'Conventional';
    conventional.mortgageAppliedForType = 'Conventional';
    conventional.order = 1;
    conventional.loanTypeId = 1;

    let fha = new LoanType();
    fha.loanTypeName = 'FHA';
    fha.mortgageAppliedForType = 'FHA';
    fha.order = 2;
    fha.loanTypeId = 2;

    let va = new LoanType();
    va.loanTypeName = 'VA';
    va.mortgageAppliedForType = 'VA';
    va.order = 3;
    va.loanTypeId = 3;

    let usda = new LoanType();
    usda.loanTypeName = 'USDA';
    usda.mortgageAppliedForType = 'USDA';
    usda.order = 4;
    usda.loanTypeId = 4;

    this.loanTypes.push(conventional);
    this.loanTypes.push(fha);
    this.loanTypes.push(va);
    this.loanTypes.push(usda);

    this.loanTypeId = '1';
  };

  private calculateDownPayment = (mortgage: MortgageApplication) => {
    const homeValue = this.calculateHomeValue(mortgage);
    if (!homeValue || !mortgage.mortgageTerm.amount) {
      return undefined;
    }
    const downPayment = (homeValue ?? 0) - (mortgage.mortgageTerm.amount ?? 0);
    return downPayment;
  }

  private calculateEstimatedLoanAmount(mortgage: MortgageApplication): number {
    const homeValue = this.calculateHomeValue(mortgage);
    return (
      homeValue - this.downpayment!
    );
  }

  private configureBasedOnScreenSize(windowWidth: number) {
    this.isMobile = windowWidth < 768
  }

  private calculateHomeValue = (mortgage: MortgageApplication) => {
    let minValue = this.minOfSalesPriceAndAppraisedValue(mortgage);
    if (!minValue) {
      minValue = 0;
    }
    return minValue;
  }

  private minOfSalesPriceAndAppraisedValue = (mortgage: MortgageApplication): number => {
    let presentValue = mortgage.mortgageTerm?.appraisedValue ?? mortgage.subjectProperty?.presentValue ?? 0;
    let purchasePriceAmount = mortgage.transactionDetail?.purchasePriceAmount ?? 0;

    if (mortgage.subjectProperty.purposeOfLoan === 'Purchase') {
      var salesPrice = purchasePriceAmount ? purchasePriceAmount : Number.MAX_VALUE;
      var appraisedValue = presentValue ? presentValue : Number.MAX_VALUE;
      var min = Math.min(salesPrice, appraisedValue);
      return min != Number.MAX_VALUE ? min : 0;
    } else {
      // Refinance
      return presentValue;
    }
  };
}
