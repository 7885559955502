import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { StepConfigFactory } from '../../models/wizard/config/factories/step-config.factory';
import { StepConfig } from '../../models/wizard/config/step-config.model';
import { StepType } from '../../models/wizard/config/step-type.enum';
import { WizardFlowConfig } from '../../models/wizard/config/wizard-flow-config.model';
import * as _ from 'lodash';
import { NgForm } from '@angular/forms';
import { StepTypeConfigFactory } from '../../models/wizard/config/factories/step-type-config.factory';
import { EnumerationItem } from 'projects/shared/models/enumeration-item.model';
import { stepGroupColors } from '../step-group-colors';

@Component({
  selector: 'create-step',
  templateUrl: 'create-step.component.html'
})
export class CreateStepComponent implements OnInit {

  @ViewChild('createStepForm') createStepForm: NgForm | undefined;

  step: StepConfig | undefined;

  stepCreationMethod: StepCreationMethod = StepCreationMethod.CopyExisting;

  @Input()
  flow!: WizardFlowConfig;

  @Input()
  defaultFlow!: WizardFlowConfig;

  @Output()
  newStepCreated: EventEmitter<StepConfig> = new EventEmitter<StepConfig>();

  @Output()
  newStepCreationCancelled: EventEmitter<any> = new EventEmitter<any>();

  stepIdToCopy: string | undefined = null;
  stepIdToInsertAfter: string | undefined = "";
  groupToPutStepInto: EnumerationItem | undefined = null;

  stepTypeForNewStep: string = "";

  stepTypes: any[] = [];

  copyExistingStep: boolean = false;
  copyExistingDefaultStep: boolean = false;

  existingStepsOfTheSameTypeInFlow: StepConfig[] = [];
  existingStepsOfTheSameTypeInDefaultFlow: StepConfig[] = [];

  stepsToChooseFromForCopying: StepConfig[] = [];

  protected stepGroups: EnumerationItem[] = [
    { value: 1, name: 'My Info' },
    { value: 2, name: 'My Finances' },
    { value: 3, name: 'Property & Loan' },
    { value: 4, name: 'Declarations' },
    { value: 5, name: 'Review & Submit' }
  ];

  protected stepGroupColors = stepGroupColors;

  constructor(private readonly _stepFactory: StepConfigFactory,
    private readonly _stepTypeConfigFactory: StepTypeConfigFactory) {
    for (var stepType in StepType) {
      if (isNaN(Number(stepType))) {
        const name = stepType.replace(/([a-z])([A-Z])/g, '$1 $2');
        if (stepType === 'CheckListsStep') {
          // TODO: Kaan, Hack, find a better fix for this
          this.stepTypes.push({ id: StepType[stepType], name: 'Checklists Step' });
        } else {
          this.stepTypes.push({ id: StepType[stepType], name: name });
        }
      }
    }
  }

  ngOnInit() { }

  onCopyExistingDefaultStepSelectionChanged = () => {
    if (this.copyExistingDefaultStep) {
      this.stepCreationMethod = StepCreationMethod.CopyFromDefault;
    } else {
      this.stepCreationMethod = StepCreationMethod.BrandNew;
    }
  }

  onStepCreationMethodChanged = () => {
    const stepType = <StepType>Number(this.stepTypeForNewStep);
    if (this.stepCreationMethod == StepCreationMethod.CopyExisting) {
      this.copyFromExistingFlow(stepType, StepCreationMethod.CopyExisting, this.flow);
    } else if (this.stepCreationMethod == StepCreationMethod.CopyFromDefault) {
      this.copyFromExistingFlow(stepType, StepCreationMethod.CopyFromDefault, this.defaultFlow);
    } else {
      this.step = this._stepFactory.createConfig(<StepType>Number(this.stepTypeForNewStep), this.flow);
    }
  }

  onStepTypeChanged = () => {
    this.stepCreationMethod = StepCreationMethod.BrandNew;
    const stepType = <StepType>Number(this.stepTypeForNewStep);

    const stepTypeConfig = this._stepTypeConfigFactory.getStepTypeConfig(stepType);
    let path: string = undefined;
    if (stepTypeConfig) {
      path = stepTypeConfig.path;
    }
    this.step = this._stepFactory.createConfig(stepType, this.flow, undefined, path);
    // Here, see if there is a step with the same type in the current flow, if not check if that exists in the default flow
    // And, if that is not the case, a brand new step will be created...
    this.existingStepsOfTheSameTypeInFlow = this.flow.steps.filter(s => s.stepType == stepType);
    this.existingStepsOfTheSameTypeInDefaultFlow = this.defaultFlow.steps.filter(s => s.stepType == stepType);
    if (this.existingStepsOfTheSameTypeInFlow && this.existingStepsOfTheSameTypeInFlow.length > 0) {
      this.copyFromExistingFlow(stepType, StepCreationMethod.CopyExisting, this.flow);
    } else if (this.existingStepsOfTheSameTypeInDefaultFlow && this.existingStepsOfTheSameTypeInDefaultFlow.length > 0) {
      this.copyFromExistingFlow(stepType, StepCreationMethod.CopyFromDefault, this.defaultFlow);
    }
  }

  copyFromExistingFlow = (stepType: StepType, copyType: StepCreationMethod, copyFromFlow: WizardFlowConfig) => {
    this.stepCreationMethod = copyType;
    this.copyExistingStep = copyType == StepCreationMethod.CopyExisting;
    this.copyExistingDefaultStep = copyType == StepCreationMethod.CopyFromDefault;
    this.stepsToChooseFromForCopying = copyFromFlow.steps.filter(s => s.stepType == stepType);
    const idOfStepToCopy = this.stepsToChooseFromForCopying[0].id;
    this.step = this.copyStep(this.stepsToChooseFromForCopying[0]);
    this.groupToPutStepInto = this.stepGroups.find(g => g.value == this.step.groupId);
    setTimeout(() => {
      this.stepIdToCopy = idOfStepToCopy;
    });
  }

  onStepToCopyChanged = () => {
    if (this.stepIdToCopy?.length == 0) {
      this.step = undefined;
      return;
    }
    let stepToCopy: StepConfig | undefined = undefined;
    const flowToCopyFrom: WizardFlowConfig = this.copyExistingStep ? this.flow : this.defaultFlow;
    stepToCopy = flowToCopyFrom.steps.find(s => s.id === this.stepIdToCopy);

    if (stepToCopy) {
      this.step = this.copyStep(stepToCopy);
    }
  }

  onCancelClicked = () => {
    this.newStepCreationCancelled.emit();
  }

  onCreateClicked() {
    if (this.createStepForm) {
      this.createStepForm.form.markAllAsTouched();
      if (this.createStepForm.form.valid) {
        if (this.step) {
          this.step.groupId = this.groupToPutStepInto?.value;
          if (this.flow.steps.length === 0) {
            this.flow.steps.push(this.step);
          } else {
            if (this.stepIdToInsertAfter === "-1") {
              // Insert into the first item
              this.flow.steps.splice(0, 0, this.step);
            } else {
              const stepToInsertAfter = this.flow.steps.find(s => s.id == this.stepIdToInsertAfter);
              if (stepToInsertAfter) {
                const index = this.flow.steps.indexOf(stepToInsertAfter);
                this.flow.steps.splice(index + 1, 0, this.step);
              }
            }
          }
          this.newStepCreated.emit(this.step);
        }
      }
    }
  }

  private copyStep = (step: StepConfig): StepConfig => {
    let copy = _.cloneDeep(step);
    let maxStepId = 0;
    if (this.flow.steps && this.flow.steps.length) {
      maxStepId = Math.max(...this.flow.steps.map(o => isNaN(o.id as any) ? -9999 : Number(o.id)));
    }
    copy.id = (maxStepId + 1).toString();
    const stepsOfTheSameTypeAlreadyInFlow = this.flow.steps.filter(s => s.stepType == copy.stepType);
    copy.path = step.path;
    if (stepsOfTheSameTypeAlreadyInFlow && stepsOfTheSameTypeAlreadyInFlow.length > 0) {
      copy.path = step.path + "-" + (stepsOfTheSameTypeAlreadyInFlow.length + 1).toString();
    }
    return copy;
  }
}

export enum StepCreationMethod {
  BrandNew = "1",
  CopyExisting = "2",
  CopyFromDefault = "3"
}
