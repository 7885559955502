import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'person-circle-image',
  templateUrl: 'person-circle-image.component.html'
})

export class PersonCircleImageComponent implements OnInit{

  @Input() url: string;
  @Input() size: number = 40;

  showingUrl: string | string[] = null;

  placeHolderUrl = "/assets/images/placeholder.png";

  constructor(private cdr: ChangeDetectorRef) {
  }
  ngOnInit(): void {
    if(!this.url){
      this.url = this.placeHolderUrl;
    }
  }

  private detach() {
    this.cdr.detach();
  }

  onImageLoaded() {
    this.detach();
  }

  onImageError() {
    this.url = this.placeHolderUrl;
  }
}
