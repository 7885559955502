import { HttpBackend, HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from 'projects/shared/services/environment.service';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Constants } from './constants';
import { NavigationService } from './navigation.service';
import { UtilsService } from './utils.service';

const headers = {
  'Content-Type': 'application/json',
  'auth-req': 'true',
  'X-Requested-With': 'XMLHttpRequest',
  'APPLICATION_NAME': 'OnlineAppV3',
  'APPLICATION_VERSION': '3.0' // Flow guid will go to the version
};

@Injectable()
export class DataService {

  // This one is the one to use to by-pass the auth interceptor
  private _httpClient: HttpClient;

  constructor(
    private _handler: HttpBackend,
    private readonly _http: HttpClient,
    private readonly _utilsService: UtilsService,
    private readonly _environmentService: EnvironmentService,
    private readonly _navigationService: NavigationService) {
    this._httpClient = new HttpClient(this._handler);
  }

  post = (url: string, data: any, admin?: boolean, customOptions?: any): Observable<any> => {
    const { urlToUse, headersToUse } = this.prepareRequestHeaders(url, admin);
    return this._http.post(urlToUse, data, { headers: headersToUse, ...customOptions });
  }

  upload(url: string, file: File): Observable<HttpEvent<any>> {
    const urlToUse = this._environmentService.apiInfo.apiBaseUrl + '/' + url

    const formData: FormData = new FormData();
    formData.append('uploadFile', file, file.name);

    const req = new HttpRequest('POST', urlToUse, formData, {
      reportProgress: true,
      responseType: 'text'
    });
    return this._http.request(req);
  }

  postFormData = (url: string, formData: FormData): Observable<any> => {
    const urlToUse = this._environmentService.apiInfo.apiBaseUrl + url;
    return this._http.post(urlToUse, formData, { headers: {} });
  };

  postWithoutAuth = (url: string, data: any): Observable<any> => {
    const urlToUse = this._environmentService.apiInfo.apiBaseUrl + '/' + url;
    const headersToUse = new HttpHeaders(headers)
      .set('APPLICATION_VERSION', this._navigationService.flowGuid ? this._navigationService.flowGuid : '');
    return this._httpClient.post(urlToUse, data, { headers: headersToUse });
  }

  put = (url: string, data: any, admin?: boolean): Observable<any> => {
    const { urlToUse, headersToUse } = this.prepareRequestHeaders(url, admin);
    return this._http.put(urlToUse, data, { headers: headersToUse });
  }

  getWithoutAuth = (url: string): Observable<any> => {
    const urlToUse = this._environmentService.apiInfo.apiBaseUrl + '/' + url;
    return this._httpClient.get(urlToUse, { headers });
  }

  get = (url: string, admin?: boolean, customOptions?: any): Observable<any> => {
    const { urlToUse, headersToUse } = this.prepareRequestHeaders(url, admin);
    return this._http.get(urlToUse, { headers: headersToUse, ...customOptions });
  }

  delete = (url: string, admin?: boolean): Observable<any> => {
    const { urlToUse, headersToUse } = this.prepareRequestHeaders(url, admin);
    return this._http.delete(urlToUse, { headers: headersToUse });
  }

  downloadFile = (url: string, relativePath: boolean = true, bypassAuth: boolean = false, mimeType: string = null): Observable<BlobPart> => {
    const httpClient = bypassAuth ? this._httpClient : this._http;
    const options: any = {
      headers: {
        'Content-Type': Constants.mimeTypes.octetStream
      },
      observe: 'response',
      responseType: mimeType ? ('arraybuffer' as 'arraybuffer') : ('blob' as 'blob')
    }
    const urlToUse = relativePath ? this._environmentService.apiInfo.apiBaseUrl + '/' + url : url;

    return httpClient.get(urlToUse, options).pipe(mergeMap((response: any) => {
      const blobUrl = response.headers.get('LE-B-Loc');
      const contentType = response.headers.get('Content-Type');
      let body = response.body;
      if (mimeType && mimeType !== contentType && !blobUrl) {
        body.type = mimeType
      }
      return blobUrl != null ? this.downloadFile(blobUrl, false, true, contentType) : of(body);
    }));
  }

  getExternalApi = (url: string): Observable<any> => {
    return this._httpClient.get(url);
  }
  
  getRelativePath = (url: string): Observable<any> => {
    const urlToUse = url;
    return this._http.get(urlToUse, { headers: headers });
  }

  private prepareRequestHeaders(url: string, admin?: boolean) {
    const urlToUse = this._environmentService.apiInfo.apiBaseUrl + '/' + url;
    let headersToUse = headers;
    const token = this._utilsService.getUrlParameter(Constants.editToken);
    if (admin && (!token || token.length === 0)) {
      this._navigationService.navigateToLogin();
      return { urlToUse, headersToUse };
    }
    const useAdmin = admin || (token !== undefined && token.length > 0);
    if (!useAdmin) {
      headersToUse = this.prepareRequestHeadersForBorrower();
    }
    return { urlToUse, headersToUse };
  }

  private prepareRequestHeadersForBorrower = (): any => {
    return new HttpHeaders(headers)
      .set('APPLICATION_VERSION', this._navigationService.flowGuid ? this._navigationService.flowGuid : '');
  }
}
