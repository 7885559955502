export class UserInfo {
  name: string = "";
  borrowerId: number;
  email: string = "";

  constructor(name: string, borrowerId: number, email: string) {
    this.name = name;
    this.borrowerId = borrowerId;
    this.email = email;
  }
}
