import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApplicationForUser } from '../../../models/borrower-portal/application-for-user-model';
import { ApplicationTaskModel } from '../../../models/borrower-portal/application-task-model';

@Component({
  selector: 'tasks-assets',
  templateUrl: './tasks-assets.component.html',
  styleUrls: ['./tasks-assets.component.scss']
})
export class TasksAssetsComponent implements OnInit {

  @Input() tasks: ApplicationTaskModel[] = [];
  @Input() application: ApplicationForUser;

  @Input() showAssetsButton: boolean = true;

  @Output() openAssetsClicked = new EventEmitter<ApplicationTaskModel>();

  constructor() { }

  ngOnInit(): void {
  }

  openAssetsModal = (task: ApplicationTaskModel) => {
    this.openAssetsClicked.emit(task);
  }
}
