import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { NgModel } from '@angular/forms';

declare const $: any;

@Directive({
  selector: '[ngModel][datePicker]',
  providers: [NgModel]
})
export class DatePickerDirective {

  constructor(private elRef: ElementRef, private renderer: Renderer2, private model: NgModel) { }

  ngOnInit() {
    this.applyDirectiveBasedOnScreenSize(window.innerWidth);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.applyDirectiveBasedOnScreenSize(window.innerWidth);
  }

  private enableFlatpickr = () => {
    const id = this.elRef.nativeElement.id;
    var dateField = $("#" + id);

    const dateValue = dateField.val();
    const isValid = !isNaN(new Date(dateValue.toString()) as any);

    if (dateValue && !isValid) {
      return;
    }

    setTimeout(() => {
      try {
        dateField.flatpickr({
          enableTime: false,
          dateFormat: "m/d/Y",
          static: true,
          allowInput: true,
          errorHandler: (error) => {
            // swallow
          }
          //disableMobile: true
        });
      } catch (e) {
        console.error(e);
      }
    })
  }

  private disableFlatpckr = () => {
    const id = this.elRef.nativeElement.id;
    var dateField = $("#" + id);
    var flatpickrInstance = dateField.data("flatpickr");

    // Destroy the Flatpickr instance to disable it
    if (flatpickrInstance) {
      flatpickrInstance.destroy();
    }
  }

  private applyDirectiveBasedOnScreenSize(windowWidth: number) {
    if (windowWidth >= 768) {
      this.enableFlatpickr();
    } else {
      this.disableFlatpckr();
    }
  }
}
