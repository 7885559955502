<div class="modal-header">

  <h2>Send Security Code for Password Reset</h2>

  <!-- hack to fool ngbModal autofocus -->
  <input type="text" style="display:none" />

  <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" aria-label="Close"
    (click)="activeModal.dismiss('Cross click')">
    <span class="svg-icon svg-icon-2x">
      <close-x-svg-icon></close-x-svg-icon>
    </span>
  </div>
</div>

<div class="modal-body py-5 px-lg-17">
  <div class="alert alert-info bg-light-success d-flex align-items-center p-5 mb-10">
    <span class="svg-icon svg-icon-2hx svg-icon-info me-3">
      <duotone-safe-svg-icon></duotone-safe-svg-icon>
    </span>
    <div class="d-flex flex-column" *ngIf="userName">
      <span class="mb-3">We will send a security code to your email that we have on your account.</span>
      <span>Please enter your email below.</span>
    </div>
  </div>
  <div *ngIf="errorMessage" class="alert alert-danger d-flex align-items-center p-5 mb-10">
    <span class="svg-icon svg-icon-2hx svg-icon-danger me-3">
      <duotone-warning-circle-svg-icon></duotone-warning-circle-svg-icon>
    </span>
    <div class="d-flex flex-column">
      <span>{{errorMessage}}</span>
    </div>
  </div>
  <form #sendCodeForm="ngForm" class="form" novalidate id="sendCodeForm" name="sendCodeForm">
    <div class="row">
      <div class="fv-row mb-5">
        <label class="form-label fs-6 fw-bolder text-dark">Email</label>
        <input class="form-control form-control-lg form-control-solid" required email trim
          [ngClass]="{'is-invalid' : userName && userName.touched && userName.invalid}" type="text" name="email"
          [(ngModel)]="email" #userName="ngModel" autocomplete="off" (change)="onEmailChangedDebounce()" />
        <div *ngIf="userName && userName.touched && userName.errors && userName.errors.required" class="invalid-feedback">Please enter your email.</div>
        <div *ngIf="userName && userName.touched && userName.errors && userName.errors.email" class="invalid-feedback">Please enter a valid email.</div>
      </div>
    </div>
    <div class="row">
      <div class="fv-row mb-5" *ngIf="email">
        <label class="form-label fs-6 fw-bolder text-dark">Company</label>
        <select class="form-control form-control-lg form-control-solid" required
          [ngClass]="{'is-invalid' : company && company.touched && company.invalid}" name="company"
          [(ngModel)]="userCompanyGuid" #company="ngModel" autocomplete="off">
          <option value="">--Select One-</option>
          <option *ngFor="let company of availableCompanies" value="{{company.userCompanyGuid}}">{{company.companyName}}</option>
        </select>
        <div class="invalid-feedback">Please select a company.</div>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer flex-center">
  <button type="button" class="btn btn-white me-3" (click)="activeModal.dismiss()">Cancel</button>
  <button type="submit" class="btn btn-primary" (click)="onSendCodeClicked()">
    <span class="indicator-label">{{sendCodeButtonText}}</span>
    <span *ngIf="spinnerVisible" class="spinner-border spinner-border-sm align-middle ms-2"></span>
  </button>
</div>
