import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Address } from '../../../models/address-model';
import { MortgageApplication } from '../../../models/mortgage-app.model';
import { EnumsService } from '../../../services/enums.service';
import { MortgageApplicationService } from '../../../services/mortgage-application.service';
import { WizardStepComponentBase } from '../wizard-step-base.component';

@Component({
  selector: 'pre-approval-property-address',
  templateUrl: 'pre-approval-property-address.component.html'
})

export class PreApprovalPropertyAddressComponent extends WizardStepComponentBase<any> implements OnInit {
  public get states() {
    return this._enumsService.states;
}

@ViewChild('propertyAddressForm') propertyAddressForm: NgForm | undefined;

public city: string = "";
public state: string = "";
public zipCode: string = "";

private _mortgageApplication: MortgageApplication;

onNextClicked() {
  this.propertyAddressForm?.form.markAllAsTouched();
  if (this.isValidCityState() || this.isValidZipCode()) {
      this.saveAddressFieldValues();
      super.onNextClicked();
  }
}

onBackClicked() {
  super.onBackClicked();
}

isValid() {

  let zipCodeDefined = this.zipCode && this.validateZipCode(this.zipCode);
  let cityDefined = this.city && this.city.length > 0;
  let stateDefined = this.state && this.state.length > 0;
  if (cityDefined && stateDefined) {
    return true;
  }
  if (zipCodeDefined) {
    return true;
  }
  return false;
}

isValidCityState() {
  let cityDefined = this.city && this.city.length > 0;
  let stateDefined = this.state && this.state.length > 0;
  if (cityDefined && stateDefined) {
    return true;
  }
  return false;
}

isValidZipCode() {
  return this.zipCode && this.validateZipCode(this.zipCode);
}

private validateZipCode(elementValue: any){
  var zipCodePattern = /^\d{5}$|^\d{5}-\d{4}$/;
  return zipCodePattern.test(elementValue);
}

constructor(private readonly _enumsService: EnumsService,
            private readonly _mortgageApplicationService: MortgageApplicationService,
            private readonly _injector: Injector) {
    super(_injector);
    this._mortgageApplication = this._mortgageApplicationService.mortgageApplication;
    this.city = this._mortgageApplication.subjectProperty?.city;
    this.state = this._mortgageApplication.subjectProperty?.state;
    this.zipCode = this._mortgageApplication.subjectProperty?.zipCode;
}

private saveAddressFieldValues = () => {
  this._mortgageApplication.subjectProperty.zipCode = this.zipCode;
  this._mortgageApplication.subjectProperty.state = this.state;
  this._mortgageApplication.subjectProperty.city = this.city;
}

ngOnInit() { }

}
