<div id="consent-authorization-main" class="container" *ngIf="!isLoading">
  <div class="card">
    <div class="card-body fs-6 p-6 p-lg-15">
      <div id="eConsentContainer" *ngIf="showEConsentVerbiage" [innerHtml]="eBusinessConsentVerbiage | safeHtml"></div>
      <div class="py-6">
        <h2 class="anchor fw-bolder mb-2" id="layout">Consent to Do Business Electronically</h2>
        <div class="col-lg-12 fv-row mb-6">
          <div class="d-flex align-items-center mt-3">
            <label class="form-check form-check-inline form-check-solid me-5">
              <input class="form-check-input" required name="consentToDoBusinessCheckbox" #consent="ngModel"
                [ngClass]="{'is-invalid' : consent && consent.touched && consent.invalid}"
                [(ngModel)]="consentToDoBusiness" type="checkbox" value="1" />
              <span class="fw-bold ps-2 fs-6 required">I/We Consent</span>
              <div class="invalid-feedback">Consent is required</div>
            </label>
          </div>
        </div>
      </div>
      <div>
        <h2 class="anchor fw-bolder" id="layout">Consent to Run Your Credit</h2>
        <div id="creditCheckConsentContainer" *ngIf="showCreditAuthVerbiage"
          [innerHtml]="creditCheckConsentVerbiage | safeHtml"></div>
        <div class="col-lg-12 fv-row mb-6">
          <div class="d-flex align-items-center mt-3">
            <label class="form-check form-check-inline form-check-solid me-5">
              <input class="form-check-input" required name="consentToDoCreditCheckAuthorizationCheckbox"
                #consentForCreditCheck="ngModel"
                [ngClass]="{'is-invalid' : consentForCreditCheck && consentForCreditCheck.touched && consentForCreditCheck.invalid}"
                [(ngModel)]="consentToRunCredit" type="checkbox" value="1" />
              <span class="fw-bold ps-2 fs-6 required">I/We Consent</span>
              <div class="invalid-feedback">Consent is required</div>
            </label>
          </div>
        </div>
      </div>

      <div class="d-flex flex-stack justify-content-center pt-5 pb-5 px-20">
        <div class="mr-2">
          <button
            type="button"
            class="btn btn-lg btn-primary"
            (click)="onOkClicked()"
            [disabled]="!consentToRunCredit || !consentToDoBusiness">
            Agree & Continue
          </button>
        </div>
      </div>

    </div>
  </div>
</div>
<loading-indicator *ngIf="isLoading" [heading]="'Loading your portal!'"
  [message1]="'Bringing up your mortgage applications.'"
  [message2]="'Please wait while we prepare your portal for you...'">
</loading-indicator>
