import { Component, Input, OnInit } from '@angular/core';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from 'projects/shared/modal/modal.service';
import { Employment } from '../../models/employment-model';
import { EmploymentHistoryDialogComponent } from '../employment-history-dialog/employment-history-dialog.component';
import * as _ from 'lodash';
import { Borrower } from '../../models/borrower-model';

@Component({
  selector: 'employment-history-card',
  templateUrl: 'employment-history-card.component.html'
})
export class EmploymentHistoryCardComponent implements OnInit {

  constructor(private readonly _modalService: ModalService) { }

  private modalOptions: NgbModalOptions = {
    size: "lg",
    backdrop: "static",
    container: "#reviewApplication"
  };

  @Input()
  borrower!: Borrower;

  public get employments(): Employment[] {
    return this.borrower.employments;
  }

  ngOnInit() { }

  onEditClicked = (employment: Employment) => {
    this.showEmploymentHistoryPopup(employment);
  }

  private showEmploymentHistoryPopup(employment: Employment) {
    const modal = this._modalService.show(EmploymentHistoryDialogComponent, this.modalOptions);
    let _employment = _.cloneDeep(employment);
    modal.componentInstance.employment = _employment;
    modal.result.then(employment => {
      this.onSaveClickedOnEmploymentHistoryModalForEdit(employment);
    }, error => { });
  }

  private onSaveClickedOnEmploymentHistoryModalForEdit(employment: Employment) {
    let index: number = this.borrower.employments.findIndex(e => e.employmentId === employment.employmentId)!;
    this.borrower.employments[index] = employment;
  }
}
