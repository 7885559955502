import { Component, Input, OnInit } from '@angular/core';
import { ApplicationForUser } from '../../models/borrower-portal/application-for-user-model';
import { OtherBorrowersOnLoanModel } from '../../models/borrower-portal/other-borrowers-on-loan-model';

@Component({
  selector: 'loan-borrowers',
  templateUrl: './loan-borrowers.component.html'
})
export class LoanBorrowersComponent implements OnInit {

  get application(): ApplicationForUser {
    return this._application;
  }

  @Input()
  set application(value: ApplicationForUser) {
    this._application = value;
    if(value){
      this.populateBorrowers(value);
    }
  }

  loanBorrowers: OtherBorrowersOnLoanModel[] = [];

  private _application: ApplicationForUser;

  constructor() { }

  ngOnInit(): void {
  }

  populateBorrowers = (app: ApplicationForUser) => {
    this.loanBorrowers = [{...app.myDetails}, ...(app.otherBorrowersOnLoan || [])].sort((a,b) => a.borrowerId - b.borrowerId);

  }

}
