export class EnumerationItem {
    name: string;
    value: any;
    alternateValue?: any;
    id?: number;
    groupName?: string | null;

    constructor(name: string, value: any) {
        this.name = name;
        this.value = value;
    }
}
