<ng-template #typeOfLoan>
  <form #loanInfoForm="ngForm" novalidate id="loanInfoForm" name="loanInfoForm">
    <div class="col-lg-8 fv-row mb-3" *ngIf="!isHidden('mortgageApplication.mortgageTerm.mortgageAppliedFor')"
      [ngClass]="{'mb-10': !isMobile}">
      <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2"
        [ngClass]="{'required': isRequired('mortgageApplication.mortgageTerm.mortgageAppliedFor')}">
        <span class="">{{getLabel('mortgageApplication.mortgageTerm.mortgageAppliedFor', 'If you do, please choose it from the list below. If not, please click continue.')}}</span>
      </label>
      <select name="loanType"
        [(ngModel)]="loanTypeId"
        #loanType="ngModel"
        [required]="isRequired('mortgageApplication.mortgageTerm.mortgageAppliedFor')"
        [ngClass]="{'is-invalid' : loanType && loanType.touched && loanType.invalid}"
        class="form-select form-select-solid" #loanType1="ngModel" data-placeholder="Select a Loan Type" [disabled]="inCoApplyFlow">
        <option [ngValue]="null">--Select One--</option>
        <option value="-1">I don't know</option>
        <option *ngFor="let loanType of loanTypes; let i = index" value="{{loanType.loanTypeId}}">
          {{loanType.loanTypeName}}
        </option>
      </select>
      <div class="invalid-feedback">Please select the type of loan you're applying to.</div>
    </div>
    <div *ngIf="loanTypeId != null && !isRefinance">
      <div class="d-flex row" [ngClass]="{'mb-7': !isMobile}">
        <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
          <span class="">We need to start crunching some numbers. Estimates are just fine, and you can change this later
            if needed.</span>
        </label>
      </div>
      <div class="d-flex row" [ngClass]="{'mb-7': !isMobile || !downpayment}">
        <div class="col-md-6" *ngIf="!isHidden('mortgage.transactionDetail.purchasePriceAmount')">
          <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2"
            [ngClass]="{'required': isRequired('mortgage.transactionDetail.purchasePriceAmount')}">
            <span class="">{{getLabel('mortgage.transactionDetail.purchasePriceAmount', 'Purchase Price')}}</span>
          </label>
          <input type="text" class="form-control form-control-solid" mask="separator" thousandSeparator="," inputmode="decimal" prefix="$"
            [required]="isRequired('mortgage.transactionDetail.purchasePriceAmount')"
            [ngClass]="{'is-invalid' : purchasePrice && purchasePrice.touched && purchasePrice.invalid}"
            [(ngModel)]="mortgage.transactionDetail.purchasePriceAmount" placeholder="" name="purchasePrice"
            (blur)="onPurchasePriceChanged()"
            #purchasePrice="ngModel" [readonly]="inCoApplyFlow"/>
            <div class="invalid-feedback">Purchase price is required, please enter a value.</div>
        </div>
        <div class="col-md-6" *ngIf="!isHidden('mortgage.transactionDetail.downpayment')">
          <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2"
            [ngClass]="{'required': isRequired('mortgage.transactionDetail.downpayment') && loanTypeId !== '3'}">
            <span class="">{{getLabel('mortgage.transactionDetail.downpayment', 'Down Payment')}}</span>
          </label>
          <input type="text" class="form-control form-control-solid" mask="separator" thousandSeparator="," inputmode="decimal" prefix="$"
            [required]="isRequired('mortgage.transactionDetail.downpayment') && loanTypeId !== '3'"
            [ngClass]="{'is-invalid' : downPayment && downPayment.touched && downPayment.invalid}"
            [(ngModel)]="downpayment" placeholder="" name="downPayment"
            (blur)="onDownPaymentChanged()"
            #downPayment="ngModel" [readonly]="inCoApplyFlow" />
          <div class="invalid-feedback">Down payment is required, please enter a value.</div>
        </div>
      </div>
      <div class="d-flex mb-3 row" *ngIf="downpayment" [ngClass]="{'mb-7': !isMobile}">
        <div class="col-md-6" *ngIf="!isHidden('mortgage.subjectProperty.downPaymentSource')">
          <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2"
            [ngClass]="{'required': isRequired('mortgage.subjectProperty.downPaymentSource')}">
            <span class="">{{getLabel('mortgage.subjectProperty.downPaymentSource', 'Down Payment Source')}}</span>
          </label>
          <select name="downPaymentSource" [(ngModel)]="mortgage.subjectProperty.downPaymentSource"
            class="form-select form-select-solid" #downPaymentSource="ngModel"
            [required]="isRequired('mortgage.subjectProperty.downPaymentSource')"
            [ngClass]="{'is-invalid' : downPaymentSource && downPaymentSource.touched && downPaymentSource.invalid}"
            data-placeholder="Select a Down Payment Source" [disabled]="inCoApplyFlow">
            <option [ngValue]="null">Select one...</option>
            <option *ngFor="let downPaymentSourceType of downPaymentSourceTypes;"
              value="{{downPaymentSourceType.value}}">
              {{downPaymentSourceType.name}}
            </option>
          </select>
          <div class="invalid-feedback">Down payment source is required, please enter a value.</div>
        </div>
      </div>
    </div>
    <div *ngIf="loanTypeId != null && isRefinance">
      <div class="d-flex row" [ngClass]="{'mb-7': !isMobile}">
        <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
          <span class="">We need to start crunching some numbers. Estimates are just fine, and you can change this later
            if needed.</span>
        </label>
      </div>
      <div class="d-flex mb-7 row">
        <div class="col-md-6">
          <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
            <span class="">Estimated Value</span>
          </label>
          <input type="text" class="form-control form-control-solid" mask="separator" thousandSeparator="," inputmode="decimal" prefix="$"
            [(ngModel)]="mortgage.subjectProperty.presentValue" placeholder="" name="presentValue" (change)="onEstimatedValueChanged()"
            [ngClass]="{'is-invalid' : presentValue1 && presentValue1.touched && presentValue1.invalid}"
            #presentValue1="ngModel" required />
          <div class="invalid-feedback">Estimated value is required, please enter a value.</div>
        </div>
        <div class="col-md-6">
          <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
            <span class="">Loan Amount</span>
          </label>
          <div class="position-relative">
            <input type="text" class="form-control form-control-solid" mask="separator" thousandSeparator="," inputmode="decimal" prefix="$"
              [(ngModel)]="estimatedLoanAmount" placeholder="" name="amount" #amount1="ngModel" (change)="onLoanAmountChanged()"
              required [readonly]="inCoApplyFlow"
              [ngClass]="{'is-invalid' : amount1 && amount1.touched && amount1.invalid}"/>
            <div class="invalid-feedback">Loan amount is required, please enter a value.</div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="loanTypeId != null && !isRefinance && downpayment">
      <div class="d-flex row" [ngClass]="{'mb-7': !isMobile}">
        <div class="col-md-6">
          <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
            <span class="">Estimated Loan Amount: {{estimatedLoanAmount | currency}} </span>
          </label>
        </div>
      </div>
      <div class="d-flex mb-7 row">
        <div class="col-md-6">
          <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
            <span class="">Estimated LTV: {{estimatedLTV? estimatedLTV : ""}}% (Based on home value: {{homeValue| currency}}) </span>
          </label>
        </div>
      </div>
    </div>
  </form>
</ng-template>

<wizard-step-template [stepMainTemplate]="typeOfLoan" [step]="step" [stepComponent]="this"></wizard-step-template>
