import { IDecideNextStep } from "../../services/wizard/next-step-decider/next-step-decider.interface";
import { GatherLeadSourceStepComponent } from "../../wizard/wizard-steps/gather-lead-source-step/gather-lead-source-step.component";
import { WizardStepBase } from "./wizard-step-base.model";

export class GatherLeadSourceStep extends WizardStepBase {

  pickAgentReferralFromAList: boolean = true;

  constructor(id: string, name: string, path: string, nextStepDecider?: IDecideNextStep) {
    super(id, name, path, nextStepDecider);
    this.component = GatherLeadSourceStepComponent;
  }
}
