<ng-template #hoi>
  <form #hoiForm="ngForm" novalidate id="hoiForm" name="hoiForm">
    <ng-container *ngIf="!isRefinance">
      <div class="row mb-5 mt-6 rounded"
          [ngClass]="{
            'is-invalid' : haveHoiQuoteQuestion && haveHoiQuoteQuestion.touched && haveHoiQuoteQuestion.invalid,
            'border' : haveHoiQuoteQuestion && haveHoiQuoteQuestion.touched && haveHoiQuoteQuestion.invalid,
            'p-4' : haveHoiQuoteQuestion && haveHoiQuoteQuestion.touched && haveHoiQuoteQuestion.invalid,
            'border-danger' : haveHoiQuoteQuestion && haveHoiQuoteQuestion.touched && haveHoiQuoteQuestion.invalid
          }">
          <div class="col-md-6 col-sm-12 fs-6 text-dark mb-3" style="padding-top: 4px;">Do you have a homeowner's insurance quote for <strong>{{subjectPropertyAddress}}</strong>?
          </div>
          <div class="col-md-2 col-sm-12 mb-3">
            <div class="d-flex">
              <div class="form-check form-check-custom form-check-solid">
                <input
                  class="form-check-input"
                  name="haveHoiQuote"
                  required
                  [value]="true"
                  type="radio"
                  id="yesHoiQuote"
                  [(ngModel)]="haveHoiQuote" #haveHoiQuoteQuestion="ngModel"
                  (ngModelChange)="onHaveHoiQuoteQuestionAnswered()"/>
                <label style="padding-right: 6px;" class="form-check-label" for="yesHoiQuote">Yes</label>
              </div>
              <div class="form-check form-check-custom form-check-solid ms-8">
                <input
                  class="form-check-input"
                  name="haveHoiQuote"
                  required
                  [value]="false"
                  type="radio"
                  id="noHoiQuote"
                  [(ngModel)]="haveHoiQuote" #haveHoiQuoteQuestion="ngModel"
                  (ngModelChange)="onHaveHoiQuoteQuestionAnswered()"/>
                <label style="padding-right: 6px;" class="form-check-label" for="noHoiQuote">No</label>
              </div>
            </div>
          </div>
      </div>
      <div class="invalid-feedback">Please answer the question above, so that we can help you better with your application.</div>
    </ng-container>

    <div class="row mb-5" [hidden]="!haveHoiQuote && !isRefinance">
      <div class="col-md-8" [ngClass]="{'is-invalid' : hoi && hoi.touched && hoi.invalid}">
        <label class="align-items-center fs-6 fw-bold form-label mb-2">
          <span class="">{{enterHoiAmountQuestionText}}</span>
        </label>
        <div class="position-relative d-flex">
          <input style="min-width: 85px" type="text" class="form-control form-control-solid" mask="separator" thousandSeparator="," inputmode="decimal" prefix="$"
            [(ngModel)]="hoiAmountEntered" placeholder="" name="hoiQuoteAmount" #hoi="ngModel"
            (ngModelChange)="onHoiAmountChanged()"
            #hoiAmountInput
            (focus)="isHoiAmountVisited = true;"
            [required]="haveHoiQuote || isRefinance"
            [ngClass]="{'is-invalid' : hoi && hoi.touched && hoi.invalid}"/>

          <select class="form-select form-select-solid ms-4 flex-grow-1" style="width: 150px" data-control="select2" name="period" #period="ngModel"
            [ngClass]="{'is-invalid' : hoi && hoi.touched && hoi.invalid}"
            [(ngModel)]="paymentPeriod"
            (ngModelChange)="onPaymentPeriodChanged()">
            <option value="PerYear">Per Year</option>
            <option value="PerMonth">Per Month</option>
          </select>
          <label [hidden]="!calculatedHoiAmount"
            style="min-width: 200px"
            class="align-items-center fs-6 fw-bold form-label mt-4 ms-3">{{ calculatedHoiAmount | currency}}/{{paymentPeriod === 'PerYear' ? 'month' : 'year'}}
          </label>
        </div>
      </div>
      <div class="invalid-feedback">{{hoiAmountValidationErrorMessage}}</div>
    </div>

    <div class="row mb-5 mt-6 rounded" *ngIf="isHoiEnabled && (calculatedHoiAmount || haveHoiQuote === false)"
        [ngClass]="{
          'is-invalid' : showMoreHoiOptionsQuestion && showMoreHoiOptionsQuestion.touched && showMoreHoiOptionsQuestion.invalid,
          'border' : showMoreHoiOptionsQuestion && showMoreHoiOptionsQuestion.touched && showMoreHoiOptionsQuestion.invalid,
          'border-danger' : showMoreHoiOptionsQuestion && showMoreHoiOptionsQuestion.touched && showMoreHoiOptionsQuestion.invalid
        }">
        <div class="col-md-8 col-sm-12 fs-6 text-dark mb-3" style="padding-top: 4px;">{{showMeOptionsMessage}}
        </div>
        <div class="col-md-4 col-sm-12 mb-3">
          <div class="d-flex">
            <div class="form-check form-check-custom form-check-solid">
              <input
                class="form-check-input"
                name="showMoreHoiOptions"
                required
                [value]="true"
                type="radio"
                id="aYes"
                [(ngModel)]="showMoreHoiOptions" #showMoreHoiOptionsQuestion="ngModel"/>
              <label style="padding-right: 6px;" class="form-check-label" for="aYes">Yes, show me!</label>
            </div>
            <div class="form-check form-check-custom form-check-solid ms-8">
              <input
                class="form-check-input"
                name="showMoreHoiOptions"
                required
                [value]="false"
                type="radio"
                id="aNo"
                [(ngModel)]="showMoreHoiOptions" #showMoreHoiOptionsQuestion="ngModel"/>
              <label style="padding-right: 6px;" class="form-check-label" for="aNo">No, not interested</label>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-sm-12 mb-3" style="font-style:italic" *ngIf="!isRefinance">
          You can always decline and shop yourself, but choosing an option here will help us close your loan faster.
        </div>
    </div>
  </form>
</ng-template>

<wizard-step-template [stepMainTemplate]="hoi" [step]="step" [stepComponent]="this"></wizard-step-template>
