import { Address } from "./address-model";
import { OwnOrRent } from "./enums/own-rent.enum";

export class AddressHistory {

  address: Address | undefined;
  ownOrRent: OwnOrRent | undefined;
  yearsLived: number;
  monthsLived: number;

  constructor() {
      this.address = new Address();
      this.yearsLived = 0;
      this.monthsLived = 0;
  }

}
