<div class="row p-4 mb-3">
  <div class="form-group mb-3 col-md-12">
    <label class="form-label" for="accountNumber">Account Number</label>
    <input
      type="text"
      class="form-control"
      [(ngModel)]="paymentInfo.accountNumber"
      name="accountNumber"
      required
      #accountNumberInput="ngModel"
      [ngClass]="{
        'is-invalid':
          accountNumberInput &&
          accountNumberInput.touched &&
          accountNumberInput.invalid
      }"
    />
    <div class="invalid-feedback">This field is required</div>
  </div>

  <div class="form-group mb-3 col-md-6">
    <label class="form-label" for="billingName">Name</label>
    <input
      type="text"
      class="form-control"
      [(ngModel)]="paymentInfo.billingName"
      name="billingName"
      required
      #billingNameInput="ngModel"
      [ngClass]="{
        'is-invalid':
          billingNameInput &&
          billingNameInput.touched &&
          billingNameInput.invalid
      }"
    />
    <div class="invalid-feedback">This field is required</div>
  </div>

  <div class="form-group mb-3 col-md-6">
    <label class="form-label" for="expirationDate">Expiration Date</label>
    <input
      class="form-control"
      type="month"
      id="expirationDate"
      name="expirationDate"
      [ngModel]="expirationDate | date : 'yyyy-MM'"
      (ngModelChange)="expirationDateChanged($event)"
    />
  </div>

  <div class="form-group mb-3 col-md-6">
    <label class="form-label" for="billingAddress">Billing Address</label>
    <input
      type="text"
      class="form-control"
      [(ngModel)]="paymentInfo.billingAddress"
      name="billingAddress"
      required
      #billingAddressInput="ngModel"
      [ngClass]="{
        'is-invalid':
          billingAddressInput &&
          billingAddressInput.touched &&
          billingAddressInput.invalid
      }"
    />
    <div class="invalid-feedback">This field is required</div>
  </div>

  <div class="form-group mb-3 col-md-6">
    <label class="form-label" for="billingAddress">Billing Address 2</label>
    <input
      type="text"
      class="form-control"
      [(ngModel)]="paymentInfo.billingAddress2"
      name="billingAddress"
    />
  </div>

  <div class="form-group mb-3 col-md-4">
    <label class="form-label" for="billingState">State</label>
    <select
      class="form-select"
      [(ngModel)]="paymentInfo.billingState"
      name="billingState"
      required
      #billingStateInput="ngModel"
      [ngClass]="{
        'is-invalid':
          billingStateInput &&
          billingStateInput.touched &&
          billingStateInput.invalid
      }"
    >
      <option *ngFor="let s of states" [ngValue]="s.value">{{ s.name }}</option>
    </select>
    <div class="invalid-feedback">This field is required</div>
  </div>

  <div class="form-group mb-3 col-md-4">
    <label class="form-label" for="billingCity">City</label>
    <input
      type="text"
      class="form-control"
      [(ngModel)]="paymentInfo.billingCity"
      name="billingCity"
      required
      #billingCityInput="ngModel"
      [ngClass]="{
        'is-invalid':
          billingCityInput &&
          billingCityInput.touched &&
          billingCityInput.invalid
      }"
    />
    <div class="invalid-feedback">This field is required</div>
  </div>

  <div class="form-group mb-3 col-md-4">
    <label class="form-label" for="billingZip">Zip Code</label>
    <input
      type="text"
      class="form-control"
      [(ngModel)]="paymentInfo.billingZip"
      name="billingZip"
      mask="00000"
      placeHolderCharacter="X"
      [showMaskTyped]="true"
      required
      #billingZipInput="ngModel"
      [ngClass]="{
        'is-invalid':
          billingZipInput && billingZipInput.touched && billingZipInput.invalid
      }"
    />
    <div class="invalid-feedback">This field is required</div>
  </div>

  <div class="form-group mb-3 col-md-6">
    <label class="form-label" for="billingPhoneNumber">Phone Number</label>
    <input
      type="tel"
      class="form-control"
      [(ngModel)]="paymentInfo.billingPhoneNumber"
      name="billingPhoneNumber"
      mask="(000) 000-0000"
      placeHolderCharacter="X"
      [showMaskTyped]="true"
      required
      #billingPhoneNumberInput="ngModel"
      [ngClass]="{
        'is-invalid':
          billingPhoneNumberInput &&
          billingPhoneNumberInput.touched &&
          billingPhoneNumberInput.invalid
      }"
    />
    <div class="invalid-feedback">This field is required</div>
  </div>
  <div class="form-group mb-3 col-md-6">
    <label class="form-label" for="billingEmail">Email Address</label>
    <input
      type="email"
      class="form-control"
      [(ngModel)]="paymentInfo.billingEmail"
      name="billingEmail"
      required
      pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"
      #billingEmailInput="ngModel"
      [ngClass]="{
        'is-invalid':
          billingEmailInput &&
          billingEmailInput.touched &&
          billingEmailInput.invalid
      }"
    />
    <div class="invalid-feedback d-block" *ngIf="billingEmailInput.errors?.required && billingEmailInput.touched">This field is required</div>
    <div
      class="invalid-feedback d-block"
      *ngIf="billingEmailInput.errors?.pattern"
    >
      Invalid email format
    </div>
  </div>
</div>
