

<div class="modal-header">

  <h2>Task Not Applicable</h2>

  <!-- hack to fool ngbModal autofocus -->
  <input type="text" style="display:none" />

  <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" aria-label="Close"
    (click)="activeModal.dismiss()">
    <span class="svg-icon svg-icon-2x">
      <close-x-svg-icon></close-x-svg-icon>
    </span>
  </div>
</div>

<div class="modal-body py-10 px-lg-17">
  <form #noteForm="ngForm" class="form" novalidate id="noteForm" name="noteForm">
    <div class="form-group mb-5 col-md-12">
      <label class="form-label" for="explanation">Please explain 'Why' or just click Done</label>
      <textarea class="form-control" rows="5" [(ngModel)]="explanation" name="explanation"></textarea>
    </div>
  </form>
</div>

<div class="modal-footer flex-center">
  <button type="button" class="btn btn-white me-3" (click)="activeModal.dismiss()">Cancel</button>
  <button type="button" class="btn btn-primary" (click)="onOkClicked()">Done</button>
</div>




