<div class="success-message">
  <div class="card">
    <div class="card-body">
      <div class="card-px text-center py-20 my-10">
        <h2 class="fs-2x fw-bold mb-10">E-sign Document</h2>
        <p class="fs-4 fw-semibold mb-10 success-text">
          Congratulations! You've successfully signed your document.
        </p>
        <a class="btn btn-lg btn-primary">Going Back to Your Loan
          <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
        </a>
      </div>
    </div>
  </div>
</div>
