import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ChartOptions } from '../../../agent-portal/agent-portal-referrals/agent-portal-referrals.component';
import { FeeSectionEnum } from '../../../models/fee/fee-section.enum';
import { LoanFee } from '../../../models/fee/fee.model';
import { PricingScenario } from '../../../models/pricing/pricing-scenario.model';
import { BasePortalService } from '../../../services/base-portal.service';

@Component({
  selector: 'scenario-card',
  templateUrl: 'scenario-card.component.html',
  styleUrls: ['./scenario-card.component.scss']
})

export class ScenarioCardComponent implements OnInit, AfterViewInit {

  @Input()
  scenario: PricingScenario;

  @Input()
  applicationId: number;

  @Input()
  index: number;

  feesTotal: number = 0;

  prepaidFeesTotal: number = 0;

  editClicked: boolean = false;

  chartOptions: Partial<ChartOptions> = {
    series: [],
    labels: [],
    yaxis: {
      show: false
    },
    xaxis: {
      labels: {
        show: false
      }
    },
    grid: {
      show: false
    },
    chart: {
      type: 'line',
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      }
    },
    stroke: {
      show: true,
      colors: ["transparent"]
    },
    markers: {
      size: 6,
      hover: {
        size: 10
      }
    },
    dataLabels: {
      enabled: false
    }
  };

  fees: LoanFee[];

  constructor(private readonly _portalService: BasePortalService) {
  }

  ngOnInit() {
    this.getFees();
  }

  ngAfterViewInit(){
    this.chartOptions = {
      ...this.chartOptions,
      series: [
        {
          name: 'Price',
          data: this.getPrices()
        }
      ],
      labels: this.getDates()
    }
  }

  togglePopover = (popover: any) => {
    if (popover.isOpen()) {
      popover.close();
    } else {
      popover.open();
    }
  }

  private getPrices = () => {
    return this.scenario ? this.scenario.history.filter(h => h && h.adjustedPrice).map(h => h.adjustedPrice) : [];
  }

  private getDates = () => {
    return this.scenario ? this.scenario.history.filter(h => h && h.date).map(h => new Date(h.date).toLocaleDateString("en-US")) : [];
  }

  private getFees = () => {
    this._portalService.getScenarioFees(this.scenario.pricingScenarioId).subscribe(fees => {
      this.fees = fees;
      this.feesTotal = fees.reduce((prev, cur) => prev + (cur.calculatedValues.borrowerTotal || 0), 0);
      this.prepaidFeesTotal = fees.filter(f => f.feeSection === FeeSectionEnum.Prepaids).reduce((prev, cur) => prev + (cur.calculatedValues.borrowerTotal || 0), 0);
    });
  }

}
