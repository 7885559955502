import { EventEmitter, Injectable, Output } from "@angular/core";
import { UserInfo } from "projects/shared/models/user-info.model";
import { Observable, Subject } from "rxjs";
import { BorrowerCreditScores } from "../../models/borrower-credit-scores.model";
import { MortgageApplication } from "../../models/mortgage-app.model";
import { WizardFlowContext } from "../../models/wizard-flow-context.model";
import { ICreateStep } from "../../models/wizard/config/factories/step-factory.interface";
import { FlowConfig } from "../../models/wizard/config/flow-config.model";
import { WizardFlowSettings } from "../../models/wizard/config/flow-settings.model";
import { StepConfig } from "../../models/wizard/config/step-config.model";
import { StepType } from "../../models/wizard/config/step-type.enum";
import { WizardNavGroup } from "../../models/wizard/wizard-nav-group.model";
import { WizardStepBase } from "../../models/wizard/wizard-step-base.model";
import { WizardFlowConfig, WizardFlowConfigs } from "../../models/wizard/config/wizard-flow-config.model";
import { FlowType } from "../../models/wizard/config/flow-type.enum";
import { ApplicationForUser } from "../../models/borrower-portal/application-for-user-model";

@Injectable()
export abstract class WizardFlowServiceBase {

  abstract navigateForward(): void;

  abstract navigateBackward(): void;

  abstract initializeFlowsInPreviewMode(entryPath: string): Promise<WizardFlowConfigs | undefined>;

  abstract startFlow(path: string, stepPathToGoAfterStart?: string): void;

  abstract navigateToApplicationFlow(applicationId?: number): void;

  @Output() abstract companyGuidIdentified: EventEmitter<string> | undefined;

  abstract getStep<TStep extends WizardStepBase>(path: string | undefined): TStep | undefined;

  abstract getStepById<TStep extends WizardStepBase>(id: string | undefined): TStep | undefined;

  abstract getNavGroups(): WizardNavGroup[];

  abstract getCurrentStep(): WizardStepBase;

  abstract isFirstStep(step: WizardStepBase): boolean;

  abstract get creditScores(): BorrowerCreditScores[];

  abstract set creditScores(borrowerCreditScores: BorrowerCreditScores[]);

  abstract get companyGuid(): string | undefined;

  abstract get companyId(): number | undefined;

  abstract get userGuid(): string | undefined;

  abstract get userInfo(): UserInfo | undefined;

  abstract get isNavigationApplicable(): boolean | false;

  abstract get percentComplete(): number | 0;

  abstract get referralSource(): number | undefined;

  abstract get isFirstStepOfRegistrationFlow(): boolean | false;

  abstract get loanInitializedEvent(): Subject<ApplicationForUser>;

  abstract navigateToGroup(navGroup: WizardNavGroup): void;

  abstract navigateToFlow(flowGuid: string | undefined): void

  abstract resetFlows(): void;

  abstract createFlowConfig(flowType: FlowType, step: WizardStepBase, settings: WizardFlowSettings): Observable<any> | undefined;

  abstract updateFlowConfig(flowType: FlowType, step: WizardStepBase, settings: WizardFlowSettings): Observable<any> | undefined;

  abstract getFlowConfigs(): Observable<FlowConfig[]> | undefined;

  abstract deleteFlowConfig(flowGuid: string): Observable<any>;

  abstract getStepFactory(stepType: StepType): ICreateStep | undefined

  browserBackOrForwardClicked: boolean = false;

  inEditMode: boolean = false;

  inPreviewMode: boolean = false;

  abstract set stepConfigBeingPreviewed(stepConfig: StepConfig | undefined);

  abstract get stepConfigBeingPreviewed(): StepConfig | undefined;

  abstract get inCoApplyFlow(): boolean;

  abstract get activeFlowType(): FlowType;

  abstract get activeFlowConfig(): WizardFlowConfig | undefined;

  abstract get isEditEnabled(): boolean;

  abstract get context(): WizardFlowContext;

  abstract updateContext(mortgage: MortgageApplication): void;

  abstract saveMortgage(): Observable<MortgageApplication>;

  abstract hasPreviousStep(): boolean;

  abstract isNavGroupCompleted(groupId: number): boolean;

  abstract saveFlowConfigs(settings: WizardFlowSettings): Promise<any> | undefined

  abstract navigateToManualAssetsStep(): void;

  abstract navigateToManualEmploymentAndIncomeStep(): void;

  abstract addNewStepToActiveFlow(stepConfig: StepConfig): void;

  abstract get campaignId(): number | undefined;

  abstract get leadId(): number | undefined;

  abstract get leadGuid(): string | undefined;

  abstract get leadSourceOverride(): string | undefined;

}
