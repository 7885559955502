<ng-template #borrowerCharacteristics>
  <div class="text-center" *ngIf="isLoading">
    <i class="fas fa-circle-notch fa-4x fa-spin"></i>
  </div>

  <ng-container *ngIf="!isLoading && categories.length">
    <div class="card shadow-sm mb-5">
      <div class="card-header">
          <h3 class="card-title">{{mainBorrower.firstName + " " + mainBorrower.lastName}}</h3>
      </div>
      <div class="card-body">
        <characteristics-per-borrower
          #characteristics
          [borrowerId]="mainBorrowerId"
          [categories]="categories"
          [applicationId]="applicationId"
          [characteristics]="getCharacteristics(mainBorrowerId)">
        </characteristics-per-borrower>
      </div>
    </div>

    <ng-container *ngIf="!inCoApplyFlow">
      <ng-container *ngFor="let borrower of otherBorrowersOnLoan">
        <div *ngIf="borrowerSetting(borrower)" class="card shadow-sm mb-5" >
            <div class="card-header">
              <h3 class="card-title">{{borrower.borrowerName}}</h3>
            </div>
            <div class="card-body">
              <characteristics-per-borrower
                #characteristics
                [borrowerId]="borrower.borrowerId"
                [categories]="categories"
                [applicationId]="applicationId"
                [characteristics]="getCharacteristics(borrower.borrowerId)">
              </characteristics-per-borrower>
            </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>

</ng-template>

<wizard-step-template [stepMainTemplate]="borrowerCharacteristics" [step]="step" [stepComponent]="this"></wizard-step-template>
