import { Component } from "@angular/core";
import { BasePortalService } from "../services/base-portal.service";
import { PortalServiceFactory } from "../services/portal-service.factory";

@Component({
  template: ''
})
export abstract class PortalBaseComponent {

  protected portalService: BasePortalService;

  constructor(private readonly _portalServiceFactory: PortalServiceFactory) {
    this.portalService = this._portalServiceFactory.create();
  }
}
