<ng-template #createAccount>
  <ng-container *ngIf="companyInfo && !companyInfo.externalAuthEnabled">
    <div id="submitApplication">
      <div class="alert alert-warning d-flex align-items-center p-5 mb-10" *ngIf="isSubmittedInEditMode">
        <span class="svg-icon svg-icon-2hx svg-icon-warning me-4">
          <duotone-warning-circle-svg-icon></duotone-warning-circle-svg-icon>
        </span>
        <div class="d-flex flex-column">
          <h5 class="mb-1">Will not submit the application!</h5>
          <span>You're an admin editing the flow. You will not be able to submit an application.</span>
        </div>
      </div>
    </div>
    <div class="d-flex mb-10" *ngIf="inEditMode">
      <div class="col-sm-3 col-form-label fw-bold fs-6 editor-label">User Creation is Auto-confirmed:</div>
      <div class="col-sm-2 form-check form-switch form-check-custom form-check-solid">
        <input class="form-check-input h-20px w-30px" type="checkbox" id="userCreationAutoConfirmed" name="userCreationAutoConfirmed"
        [ngModelOptions]="{standalone: true}" [(ngModel)]="userCreationAutoConfirmed" />
        <label class="form-check-label" for="userCreationAutoConfirmed">
          {{userCreationAutoConfirmed ? "Yes" : "No"}}
        </label>
      </div>
    </div>

    <div class="row mb-5">
      <div class="alert alert-warning d-flex align-items-center p-5 mb-10" *ngIf="passwordPolicyViolations">
        <span class="svg-icon svg-icon-2hx svg-icon-warning me-4">
          <duotone-warning-circle-svg-icon></duotone-warning-circle-svg-icon>
        </span>
        <div class="d-flex flex-column">
          <h5 class="mb-1">Password Policy Violation</h5>
          <ul>
            <li *ngFor="let violation of passwordPolicyViolations">
              {{violation}}
            </li>
          </ul>
        </div>
      </div>
      <div class="alert alert-warning d-flex align-items-center p-5 mb-10" *ngIf="userExists">
        <span class="svg-icon svg-icon-2hx svg-icon-warning me-4">
          <duotone-warning-circle-svg-icon></duotone-warning-circle-svg-icon>
        </span>
        <div class="d-flex flex-column">
          <h5 class="mb-1">User already exists.</h5>
          <div class="mb-2">
            A user with the e-mail <strong>{{step.borrower!.primaryEmail}}</strong> has already been registered.<br>
            Please click <a (click)="onLoginClicked()" class="fw-bolder me-1">here</a>to login.
          </div>
        </div>
      </div>
      <borrower-info customMessage="Great! Now it's time to create your secure account so we can collect the rest of your
        information" [doesSetPassword]="userCreationAutoConfirmed" [borrower]="step.borrower">
      </borrower-info>
    </div>
    <div class="row" *ngIf="step.borrower.mobilePhone">
      <div class="col-md-12 col-sm-12">
        <div class="py-3">
          <div class="col-lg-12 fv-row">
            <div class="d-flex align-items-center mt-3">
              <label class="form-check form-check-inline form-check-solid me-5">
                <input class="form-check-input" name="optInForReceivingSms" #consent="ngModel"
                  [ngClass]="{'is-invalid' : consent && consent.touched && consent.invalid}"
                  [(ngModel)]="optInForReceivingSms" type="checkbox" value="1" />
                <span class="ps-2 fs-6" style="font-style:italic">By checking this box, I consent to receive both communication and marketing SMS messages related to your application with <strong>{{companyName}}</strong>. Standard rates may apply. Read full terms and privacy policies here: <a href="javascript:void(0)" (click)="onShowTermsOfServiceClicked()">Terms of Service</a>,
                  <a href="javascript:void(0)" (click)="onShowPrivacyPolicyClicked()">Privacy Policy</a>. Messaging frequency varies and you may opt-out any time by replying "STOP".</span>
                <div class="invalid-feedback">Consent is required</div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="companyInfo && companyInfo.externalAuthEnabled">
    <div class="d-flex">
      <div class="spinner-border m-5 text-success" style="width: 2.75rem; height: 2.75rem" role="status"></div>
      <h4 style="margin-top: 24px">Please wait...</h4>
    </div>
  </ng-container>
</ng-template>

<wizard-step-template [stepMainTemplate]="createAccount" [step]="step" [stepComponent]="this"></wizard-step-template>

