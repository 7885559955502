import { ApplicationTaskModel, BorrowerTaskStatus, BorrowerTaskType } from "projects/borrower-app/src/app/models/borrower-portal/application-task-model"

export class TasksUtils {

  public static shouldShowEsignDocument = (task: ApplicationTaskModel, isBorrowerPortal: boolean): boolean => {
    return (task.borrowerTaskType === BorrowerTaskType.LosEsign
      || task.borrowerTaskType === BorrowerTaskType.EsignDocument)
      && task.borrowerTaskStatus !== BorrowerTaskStatus.Completed
      && task.borrowerTaskStatus !== BorrowerTaskStatus.Submitted
      && !task.signingLinkSentInEmail
      && ((isBorrowerPortal && !task.isAgentTask) || (!isBorrowerPortal && task.isAgentTask))
  }

}
