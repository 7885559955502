<ng-template #login>
  <!-- <header [logoUrl]="logoUrl"></header> -->
  <div class="d-flex flex-column flex-column-fluid p-10 pb-lg-10 login-wrapper">
    <div class="w-md-500px bg-white rounded shadow-sm p-7 p-lg-7 login-body">
      <div class="d-flex justify-content-center">
        <logo [logoUrlsToTry]="possibleLogoUrls"></logo>
      </div>

      <form class="form w-100" #loginForm="ngForm" novalidate id="loginForm" name="loginForm">
        <div class="text-center my-4">
          <h2 class="text-dark mb-2">Sign In to Your Account</h2>
          <div *ngIf="canCreateAccount" class="text-gray-400 fw-bold fs-5">New Here?
            <a (click)="onCreateAccountClicked()" class="link-primary fw-bolder">Create an Account</a>
          </div>
        </div>
        <div *ngIf="errorMessage" class="alert alert-danger d-flex align-items-center p-5 mb-5">
          <span class="svg-icon svg-icon-2hx svg-icon-danger me-3">
            <duotone-shield-check-svg-icon></duotone-shield-check-svg-icon>
          </span>
          <div class="d-flex flex-column">
            <span>{{errorMessage}}</span>
          </div>
        </div>
        <ngb-carousel #slider [showNavigationArrows]="false" [showNavigationIndicators]="false" 
          [interval]="0" [keyboard]="false" (slid)="onCarouselTransitionComplete($event)">
          <ng-template ngbSlide id="askForEmail">
            <div style="min-height: 200px;" *ngIf="!mfaCodeEntryRequired && !mfaSetupRequired">
              <div class="fv-row mb-10 mt-3">
                <label class="form-label fs-6 fw-bolder text-dark">Enter Your E-mail</label>
                <input type="text" autocomplete="off" trim
                  class="form-control form-control-lg form-control-solid" 
                  name="username" id="login-username"
                  placeholder="Enter username" #userName="ngModel"
                  [ngClass]="{'is-invalid' : userName && userName.touched && userName.invalid}" 
                  (keyup.enter)="onLoginClicked()"
                  [(ngModel)]="loginRequest.username" required email>
                <div class="invalid-feedback">Please enter a valid e-mail.</div>
              </div>
            </div>
          </ng-template>
          <ng-template ngbSlide id="companySelection">
            <div class="slider-pane p-3" style="min-height: 200px;">
              <div class="center mt-4">
                <h4 class="card-title mb-4">Your account is linked to multiple companies</h4>
                <p class="text-muted mb-4">Please, select the company you'd like to log into.</p>
                <div class="mt-2 mb-10">
                  <select class="form-select" name="company" id="company" [(ngModel)]="loginRequest.userCompanyGuid"
                    (ngModelChange)="onAvailableCompanyPickedForLogin()" placeholder="Select a Company">
                    <option [ngValue]="null">--Select One--</option>
                    <ng-container *ngFor="let companies of availableCompanies | groupBy: 'companyGuid'">
                      <optgroup label="{{companies.value[0].companyName}}">
                        <option *ngFor="let company of companies.value; let i = index"
                          value="{{company.userCompanyGuid}}">
                          {{company.userType}}
                        </option>
                      </optgroup>
                    </ng-container>
                  </select>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template ngbSlide id="askForPassword">
            <div style="min-height: 200px;" *ngIf="!mfaCodeEntryRequired && !mfaSetupRequired && passwordRequired">
              <div class="fv-row mb-10 mt-3">
                <div class="d-flex justify-content-between mb-2">
                  <label class="form-label fw-bolder text-dark fs-6 mb-0">Enter Your Password</label>
                  <a (click)="onForgotPasswordClicked()" class="link-primary fs-6 fw-bolder">Forgot
                    Password?</a>
                </div>
                <input class="form-control form-control-lg form-control-solid" type="password" name="password" required
                  [ngClass]="{'is-invalid' : userPassword && userPassword.touched && userPassword.invalid}"
                  (keyup.enter)="onLoginClicked()"
                  autocomplete="off" [(ngModel)]="loginRequest.password" 
                  #userPassword="ngModel" />
                <div class="invalid-feedback">Please enter your password.</div>
              </div>
            </div>
          </ng-template>
          <ng-template ngbSlide id="twoFactorCodeEntry">
            <div class="slider-pane p-3">
              <div class="center mt-4">
                <h4 class="card-title mb-2">We need to confirm it's really you</h4>
                <p class="text-muted mb-2">Please, enter the code we sent you.</p>
                <p class="text-muted mb-2">
                  Need a new code? <a *ngIf="!spinnerVisible" (click)="onSendCodeClicked()"> Resend Code </a>
                </p>

                <div class="mt-2">
                  <input type="text" autocomplete="off" class="form-control" name="twoFactorCode"
                    id="twoFactorCode" [disabled]="spinnerVisible" placeholder="Enter Code" #twoFactorCode="ngModel"
                    [ngClass]="{'is-invalid' : twoFactorCode && twoFactorCode.touched && twoFactorCode.invalid}"
                    [(ngModel)]="loginRequest.twoFactorCode" (keyup.enter)="onLoginClicked()" autofocus="autofocus"
                    #twoFactorCodeInput>

                  <div class="invalid-feedback">Incorrect code. Please re-enter the code we sent you.
                  </div>
                  <div class="d-flex bd-highlight mt-3">
                    <button type="button" class="btn btn-white" (click)="onCancelLoginClicked()">Cancel</button>

                    <button *ngIf="!spinnerVisible" (click)="onLoginClicked()" [disabled]="!loginRequest.twoFactorCode"
                      class="btn btn-primary ms-auto">Verify</button>
                    <button class="btn btn-primary waves-effect waves-light ms-auto" type="button" disabled=""
                      *ngIf="spinnerVisible">
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      Please wait...
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template ngbSlide id="mfaSetup">
            <div class="p-3">
              <form class="form-horizontal auth-form" #mfaSetupForm="ngForm" novalidate id="mfaSetupForm"
                name="mfaSetupForm">
                <div class="center mt-4">
                  <h4 class="card-title mb-2">You need to setup MFA on your account</h4>
                  <p class="text-muted mb-2" *ngIf="!needToConfirmPhone">Please, enter your phone number.</p>
                  <p class="text-muted mb-2">
                  </p>

                  <div class="mt-2">
                    <div class="form-group mb-3 d-flex" *ngIf="!needToConfirmPhone">
                      <div class="flex-grow-1">
                        <label class="form-label">Country Code</label>
                        <select class="form-select" name="areaCode" [(ngModel)]="areaCode" required>
                          <option *ngFor="let country of countries" [value]="country.value">{{'(' + country.areaCode + ')' + " " + country.name}}
                          </option>
                        </select>
                      </div>

                      <div class="ms-2">
                        <label class="form-label">Phone Number</label>
                        <input name="phoneNumber" class="form-control" [(ngModel)]="phone" [mask]="'(000) 000-0000'"
                          [placeholder]="'(###) ###-####'" type="text">
                      </div>
                    </div>

                    <div *ngIf="needToConfirmPhone">
                      <p class="text-muted mb-2">Please, enter the code we sent you.</p>

                      <div class="mt-2 d-flex">
                        <input type="text" autocomplete="off" class="form-control me-1" name="verificationCode"
                          id="verificationCode" [disabled]="spinnerVisible" placeholder="Enter Code" #verificationCodeValue="ngModel"
                          [ngClass]="{'is-invalid' : verificationCodeValue && verificationCodeValue.touched && verificationCodeValue.invalid}"
                          [(ngModel)]="verificationCode" (keyup.enter)="onLoginClicked()" autofocus="autofocus"
                          #verificationCodeInput>

                        <div class="invalid-feedback">Incorrect code. Please re-enter the code we sent you.
                        </div>
                        <button style="width: 250px" [disabled]="spinnerVisible" (click)="onChangeNumberClicked()" class="btn btn-primary ms-auto">Change Number</button>
                      </div>
                    </div>

                    <div class="d-flex bd-highlight mt-3" *ngIf="!needToConfirmPhone">
                      <button type="button" class="btn btn-white" (click)="onCancelLoginClicked()">Cancel</button>

                      <button *ngIf="!spinnerVisible" (click)="onSaveNumberClicked()" [disabled]="!areaCode || !phone"
                        class="btn btn-primary ms-auto">Save Number</button>
                      <button class="btn btn-primary waves-effect waves-light ms-auto" type="button" disabled="" *ngIf="spinnerVisible">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please wait...
                      </button>
                    </div>
                    <div class="d-flex bd-highlight mt-3" *ngIf="needToConfirmPhone">
                      <button type="button" class="btn btn-white" (click)="onCancelLoginClicked()">Cancel</button>
                      <button *ngIf="!spinnerVisible" (click)="onConfirmPhoneClicked()" [disabled]="!verificationCode"
                        class="btn btn-primary ms-auto">Verify</button>
                      <button class="btn btn-primary waves-effect waves-light ms-auto" type="button" disabled="" *ngIf="spinnerVisible">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please wait...
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </ng-template>
          <ng-template ngbSlide id="roleSelection">
            <div class="slider-pane p-3" style="min-height: 200px;">
              <div class="center mt-4">
                <h4 class="card-title mb-4">Your account is linked to multiple roles</h4>
                <p class="text-muted mb-4">Please, select the role you'd like to login as.</p>
                <div class="mt-2 mb-10">
                  <select class="form-select" name="role" id="role" [(ngModel)]="userScope" placeholder="Select a Role">
                    <option [value]="-1">--Select One--</option>
                    <option *ngFor="let role of availableRoles; let i = index" value="{{role}}">
                      {{role}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </ng-template>
        </ngb-carousel>
        <div class="text-center" *ngIf="!mfaSetupRequired && !needToConfirmPhone">
          <button type="submit" class="btn btn-lg btn-primary w-100 mb-5" (click)="onLoginClicked()" 
            [disabled]="mfaCodeEntryRequired">
            <span class="indicator-label">{{loginButtonText}}</span>
            <span *ngIf="spinnerVisible" class="spinner-border spinner-border-sm align-middle ms-2"></span>
          </button>
        </div>
      </form>
    </div>
    <div class="overlay-layer rounded bg-primary-o-20">
      <div class="spinner spinner-primary"></div>
    </div>
  </div>
  <div class="d-flex flex-column justify-content-center align-items-center">
    <div class="login-footer">
      <img src="/assets/images/loda-logo.svg" height="30" alt="Lodasoft">
    </div>
  </div>

</ng-template>

<user-account-template [contentTemplate]="login"></user-account-template>
