<!--begin::Card-->
<div class="card pt-4 mb-4 mb-xl-4 border" id="personal-info-main">
  <!--begin::Card body-->
  <div class="card-body card-body-mobile pt-0">
    <!--begin::Option-->
    <div class="py-0" data-kt-customer-payment-method="row">
      <!--begin::Header-->
      <div class="d-flex flex-stack flex-wrap">
        <div class="fs-6 fw-bolder text-dark">PERSONAL INFO</div>
        <a (click)="onEditClicked()" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
          <span class="svg-icon svg-icon-3"><duotone-write-svg-icon>
          </duotone-write-svg-icon>
        </span></a>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="collapse show fs-6 ps-10"
        data-bs-parent="#kt_customer_view_payment_method">
        <!--begin::Row-->
        <div class="row py-5">
          <!--begin::Row-->
          <div class="col-md-6">
            <!--begin::Item-->
            <div class="d-flex mb-3">
              <!--begin::Label-->
              <div class="text-gray-400 fw-bold w-125px">Phone</div>
              <!--end::Label-->
              <!--begin::Value-->
              <div class="text-gray-800 fw-bold">{{phoneNumber}}</div>
              <!--end::Value-->
            </div>
            <!--end::Item-->
            <!--begin::Item-->
            <div class="d-flex mb-3">
              <!--begin::Label-->
              <div class="text-gray-400 fw-bold w-125px">Marital Status</div>
              <!--end::Label-->
              <!--begin::Value-->
              <div class="text-gray-800 fw-bold">{{maritalStatusName}}</div>
              <!--end::Value-->
            </div>
            <!--end::Item-->
            <!--begin::Item-->
            <div class="d-flex mb-3">
              <!--begin::Label-->
              <div class="text-gray-400 fw-bold w-125px">SSN</div>
              <!--end::Label-->
              <!--begin::Value-->
              <div class="text-gray-800 fw-bold">{{borrower.socialSecNum}}</div>
              <!--end::Value-->
            </div>
            <!--end::Item-->
          </div>
          <!--end::Row-->
          <!--begin::Row-->
          <div class="col-md-6">
            <!--begin::Item-->
            <div class="d-flex mb-3">
              <!--begin::Label-->
              <div class="text-gray-400 fw-bold w-125px">Address</div>
              <!--end::Label-->
              <!--begin::Value-->
              <div class="text-gray-800 fw-bold">{{currentStreetAddress}}</div>
              <!--end::Value-->
            </div>
            <!--end::Item-->
            <!--begin::Item-->
            <div class="d-flex mb-3">
              <!--begin::Label-->
              <div class="text-gray-400 fw-bold w-125px">Date of Birth</div>
              <!--end::Label-->
              <!--begin::Value-->
              <div class="text-gray-800 fw-bold">{{borrower.dateOfBirth}}</div>
              <!--end::Value-->
            </div>
            <!--end::Item-->
           <!--begin::Item-->
           <div class="d-flex">
            <!--begin::Label-->
            <div class="text-gray-400 fw-bold w-125px">Citizenship</div>
            <!--end::Label-->
            <!--begin::Value-->
            <div class="text-gray-800 fw-bold">{{residenceStatusName}}</div>
            <!--end::Value-->
          </div>
          <!--end::Item-->

          </div>
          <!--end::Row-->
        </div>
        <!--end::Row-->
      </div>
      <!--end::Body-->
    </div>
    <!--end::Option-->
  </div>
</div>
