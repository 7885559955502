<div (click)="!isReadOnly && onEditClicked()" class="hidden-when-mobile mb-4 p-3 d-flex text-gray-400" [ngClass]="{
  'btn': !isReadOnly,
  'btn-outline' : !isReadOnly,
  'btn-outline-dashed': !deleteAttempted && reoItem.isValid && !isReadOnly ,
  'btn-outline-primary': !deleteAttempted && reoItem.isValid && !isReadOnly,
  'btn-active-light-primary': !deleteAttempted && reoItem.isValid && !isReadOnly,
  'btn-outline-danger': deleteAttempted || !reoItem.isValid && !isReadOnly,
  'btn-active-light-danger': deleteAttempted || !reoItem.isValid && !isReadOnly,
  'readonly-item': isReadOnly}">

  <span class="svg-icon svg-icon-2x svg-icon-primary me-4 align-items-center mt-1 duotone-home-svg-mobile"
    [ngClass]="{'svg-icon-primary': !deleteAttempted && !isReadOnly, 'svg-icon-danger' : deleteAttempted }">
    <ng-template svgIconHost></ng-template>
  </span>
  <div class="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap align-items-center">
    <span class="fs-6 text-gray-600 d-block fw-bold"><strong>{{reoItem.address}}</strong></span>
    <div class="d-flex justify-content-end align-items-center">
      <label class="form-label line-item-text px-4 mt-3">{{reoItem.reoOwners}}</label>
      <label class="form-label line-item-text px-4 mt-3">Property Type: {{propertyTypeName}}</label>
      <label class="form-label line-item-text px-4 mt-3">Property Value: {{reoItem.propertyValue | currency}}</label>

      <div class="align-self-center text-nowrap">
        <div class="d-flex" style="margin-top: 4px;" *ngIf="deleteAttempted">
          <a href="javascript:void(0);" class="btn btn-icon btn-bg-light btn-active-color-success btn-sm me-1"
            (click)="onDeleteConfirmed($event)">
            <span class="svg-icon svg-icon-3">
              <stockholm-check-svg-icon></stockholm-check-svg-icon>
            </span>
          </a>
          <a href="javascript:void(0);" class="btn btn-icon btn-bg-light btn-active-color-danger btn-sm"
            (click)="onDeleteAborted($event)">
            <span class="svg-icon svg-icon-3">
              <stockholm-close-svg-icon></stockholm-close-svg-icon>
            </span>
          </a>
        </div>
        <div class="d-flex" style="margin-top: 4px;" *ngIf="!deleteAttempted && !isReadOnly">
          <a href="javascript:void(0);" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
            (click)="onDeleteClicked($event)">
            <span class="svg-icon svg-icon-3">
              <duotone-trash-svg-icon></duotone-trash-svg-icon>
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<div (click)="!isReadOnly && onEditClicked()" class="hidden-when-not-mobile mb-4 p-3 d-flex text-gray-400" [ngClass]="{
  'btn': !isReadOnly,
  'btn-outline' : !isReadOnly,
  'btn-outline-dashed': !deleteAttempted && reoItem.isValid && !isReadOnly ,
  'btn-outline-primary': !deleteAttempted && reoItem.isValid && !isReadOnly,
  'btn-active-light-primary': !deleteAttempted && reoItem.isValid && !isReadOnly,
  'btn-outline-danger': deleteAttempted || !reoItem.isValid && !isReadOnly,
  'btn-active-light-danger': deleteAttempted || !reoItem.isValid && !isReadOnly,
  'readonly-item': isReadOnly}">

  <span class="svg-icon svg-icon-2x svg-icon-primary me-4 align-items-center mt-1 duotone-home-svg-mobile"
    [ngClass]="{'svg-icon-primary': !deleteAttempted && !isReadOnly, 'svg-icon-danger' : deleteAttempted }">
    <ng-template svgIconHost></ng-template>
  </span>
  <div style="width: 100%;">
    <div class="d-flex justify-content-between mx-2">
      <span class="fs-4 text-gray-600 d-block fw-bold mt-3"><strong>{{reoItem.address}}</strong></span>
      <div class="align-self-center text-nowrap">
        <div class="d-flex" style="margin-top: 4px;" *ngIf="deleteAttempted">
          <a href="javascript:void(0);" class="btn btn-icon btn-bg-light btn-active-color-success btn-sm me-1"
            (click)="onDeleteConfirmed($event)">
            <span class="svg-icon svg-icon-3">
              <stockholm-check-svg-icon></stockholm-check-svg-icon>
            </span>
          </a>
          <a href="javascript:void(0);" class="btn btn-icon btn-bg-light btn-active-color-danger btn-sm"
            (click)="onDeleteAborted($event)">
            <span class="svg-icon svg-icon-3">
              <stockholm-close-svg-icon></stockholm-close-svg-icon>
            </span>
          </a>
        </div>
        <div class="d-flex" style="margin-top: 4px;" *ngIf="!deleteAttempted && !isReadOnly">
          <a href="javascript:void(0);" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
            (click)="onDeleteClicked($event)">
            <span class="svg-icon svg-icon-3">
              <duotone-trash-svg-icon></duotone-trash-svg-icon>
            </span>
          </a>
        </div>
      </div>
    </div>
    <div class="text-start mt-3 px-2">
      <div *ngIf="reoItem.reoOwners">
        <label class="form-label line-item-text" style="min-width: 8rem;"> Borrower: </label>
        <span> {{reoItem.reoOwners}} </span>
      </div>
      <div *ngIf="propertyTypeName">
        <label class="form-label line-item-text" style="min-width: 8rem;"> Property Type: </label>
        <span> {{propertyTypeName}} </span>
      </div>
      <div *ngIf="reoItem.propertyValue">
        <label class="form-label line-item-text" style="min-width: 8rem;"> Property Value: </label>
        <span> {{reoItem.propertyValue | currency: 'USD':true:'2.0' }} </span>
      </div>
    </div>
  </div>
</div>