export enum AssetType {
  Annuity = "Annuity",
  Automobile = "Automobile",
  Bond= "Bond",
  BridgeLoanNotDeposited = "BridgeLoanNotDeposited",
  Cash = "CashOnHand" ,
  CertificateOfDepositTimeDeposit = "CertificateOfDepositTimeDeposit",
  CheckingAccount = "CheckingAccount",
  EarnestMoneyCashDepositTowardPurchase = "EarnestMoneyCashDepositTowardPurchase",
  GiftOfEquity = "GiftOfEquity",
  CashGift = "CashGift",
  IndividualDevelopmentAccount = "IndividualDevelopmentAccount",
  LifeInsurance = "LifeInsurance",
  MoneyMarketFund = "MoneyMarketFund",
  MutualFund = "MutualFund",
  NetWorthOfBusinessOwned = "NetWorthOfBusinessOwned",
  OtherLiquidAssets = "OtherLiquidAssets",
  OtherNonLiquidAssets = "OtherNonLiquidAssets",
  PendingNetSaleProceedsFromRealEstateAssets = "PendingNetSaleProceedsFromRealEstateAssets",
  ProceedsFromSaleOfNonRealEstateAsset = "ProceedsFromSaleOfNonRealEstateAsset",
  RelocationMoney =  "RelocationMoney",
  RetirementFund = "RetirementFund",
  SaleOtherAssets = "SaleOtherAssets",
  SavingsAccount = "SavingsAccount",
  SecuredBorrowedFundsNotDeposited = "SecuredBorrowedFundsNotDeposited",
  Stock = "Stock",
  StockOptions = "StockOptions",
  TrustAccount = "TrustAccount"
}
