
export class ReferAFriendRequest {
  referToUserId:	string;
  referralType:	ReferralType;
  firstName:	string;
  lastName:	string;
  userContact:	string;
  email:	string;
  phone:	string;
  note:	string;
  referredByName:	string;
  referredByEmail:	string;
  referredByPhone:	string;
}

export enum ReferralType {
  Borrower = "Borrower",
  Agent = "Agent"
}
