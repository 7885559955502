import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Borrower } from '../../../models/borrower-model';
import { MortgageApplication } from '../../../models/mortgage-app.model';
import { CreateAccountStep } from '../../../models/wizard/create-account-step.model';
import { MortgageApplicationService } from '../../../services/mortgage-application.service';
import { BorrowerInfoComponent } from '../../borrower-info/borrower-info.component';
import { WizardStepComponentBase } from '../wizard-step-base.component';

@Component({
  selector: 'create-coborrower-account',
  templateUrl: 'create-coborrower-account.component.html'
})

export class CreateCoborrowerComponent extends WizardStepComponentBase<CreateAccountStep> implements OnInit {
    private _mortgageApplication: MortgageApplication;

    constructor(private readonly _mortgageApplicationService: MortgageApplicationService,
          private readonly _injector: Injector) {
        super(_injector);
        this._mortgageApplication = this._mortgageApplicationService.mortgageApplication;
        let borrower = new Borrower();
        this._mortgageApplication.borrowers.push(borrower);
    }

  @ViewChild(BorrowerInfoComponent) borrowerInfo: BorrowerInfoComponent | undefined;

  public borrower: Borrower = new Borrower();

  onNextClicked() {
    if (this.borrowerInfo?.validate()) {
        super.onNextClicked();
    }
  }

  ngOnInit() {
  }
}
