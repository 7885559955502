import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Product, Quote } from 'projects/borrower-app/src/app/models/pricing/pricing-response.model';

@Component({
  selector: 'product-price-card',
  templateUrl: 'product-price-card.component.html',
  styleUrls: ['./product-price-card.component.scss']
})
export class ProductPriceCardComponent implements OnInit {

  @Input()
  set product(product: Product) {
    this._product = product;
    if (this._product.quotes && this._product.quotes.length > 0) {
      this.quote = this._product.quotes[0];
    }
  }

  @Input()
  liabilityPayOffInformationChanged: boolean;

  @Input()
  loanAmount: number | undefined;

  @Output()
  rateEditClicked: EventEmitter<Product> = new EventEmitter<Product>();

  @Output()
  selectedCardChanged: EventEmitter<Product> = new EventEmitter<Product>();

  @Input()
  appraisedValue: number | undefined;

  get product(): Product {
    return this._product;
  }

  protected quote: Quote | undefined;

  @Input()
  isSelected: boolean = false;

  private _product: Product;

  constructor() { }

  ngOnInit() {
  }

  onRateAprEditClicked = () => {
    this.rateEditClicked.emit(this.product);
  }

  cardClicked = () => {
    if (!this.liabilityPayOffInformationChanged) {
      this.isSelected = true;
      this.selectedCardChanged.emit(this.product);
    }
  }
}
