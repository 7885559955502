export enum TypeOfIncome {
  AlimonyChildSupport = "AlimonyChildSupport",
  AutomobileExpenseAccount = "AutomobileExpenseAccount",
  Base = "Base",
  Bonus = "Bonus",
  Commissions = "Commissions",
  DividendsInterest = "DividendsInterest",
  FosterCare = "FosterCare",
  NetRentalIncome = "NetRentalIncome",
  NotesReceivableInstallment = "NotesReceivableInstallment",
  OtherTypesOfIncome = "OtherTypesOfIncome",
  Overtime = "Overtime",
  Pension = "Pension",
  SocialSecurity = "SocialSecurity",
  SubjectPropertyNetCashFlow = "SubjectPropertyNetCashFlow",
  Trust = "Trust",
  Unemployment = "Unemployment",
  PublicAssistance = "PublicAssistance",
  VABenefitsNonEducational = "VABenefitsNonEducational",
  MortgageDifferential = "MortgageDifferential",
  MilitaryBasePay = "MilitaryBasePay",
  MilitaryRationsAllowance = "MilitaryRationsAllowance",
  MilitaryFlightPay = "MilitaryFlightPay",
  MilitaryHazardPay = "MilitaryHazardPay",
  MilitaryClothesAllowance = "MilitaryClothesAllowance",
  MilitaryQuartersAllowance = "MilitaryQuartersAllowance",
  MilitaryPropPay = "MilitaryPropPay",
  MilitaryOverseasPay = "MilitaryOverseasPay",
  MilitaryCombatPay = "MilitaryCombatPay",
  MilitaryVariableHousingAllowance = "MilitaryVariableHousingAllowance",
  AccessoryUnitIncome = "AccessoryUnitIncome",
  NonBorrowerHouseholdIncome = "NonBorrowerHouseholdIncome",
  HousingChoiceVoucher = "HousingChoiceVoucher",
  BoarderIncome = "BoarderIncome",
  MortgateCreditCertificate = "MortgateCreditCertificate",
  CapitalGains = "CapitalGains",
  EmploymentRelatedAssets = "EmploymentRelatedAssets",
  ForeignIncome = "ForeignIncome",
  RoyaltyPayment = "RoyaltyPayment",
  SeasonalIncome = "SeasonalIncome",
  TemporaryLeave = "TemporaryLeave",
  TipIncome = "TipIncome",
  FNMBoarderIncome = "FNMBoarderIncome",
  FNMGovernmentMortgageCreditCertificate = "FNMGovernmentMortgageCreditCertificate",
  FNMTrailingCoBorrower = "FNMTrailingCoBorrower",
  HousingChoiceVoucherProgramSection8 = "HousingChoiceVoucherProgramSection8",
  ContractBasis = "ContractBasis",
  ChildSupport = "ChildSupport",
  DefinedContributionPlan = "DefinedContributionPlan",
  Disability = "Disability",
  HousingAllowance = "HousingAllowance",
  HousingOrParsonage = "HousingOrParsonage",
  Retirement = "Retirement",
  SeparateMaintenance = "SeparateMaintenance",
  NonBorrowerContribution = "NonBorrowerContribution",
  ProposedGrossRentForSubjectProperty = "ProposedGrossRentForSubjectProperty",
  RealEstateOwnedGrossRentalIncome = "RealEstateOwnedGrossRentalIncome",
  SelfEmploymentIncome = "SelfEmploymentIncome",
  SelfEmploymentLoss = "SelfEmploymentLoss",
  WorkersCompensation = "WorkersCompensation",
  MiscellaneousIncome = "MiscellaneousIncome",
  BorrowerEstimatedTotalMonthlyIncome = "BorrowerEstimatedTotalMonthlyIncome"
}
