import { Component, Input, OnInit } from '@angular/core';
import { StepConfig } from '../../models/wizard/config/step-config.model';

@Component({
  selector: 'step-card',
  templateUrl: 'step-card.component.html',
  styleUrls: ['step-card.component.scss']
})

export class StepCardComponent implements OnInit {

  @Input()
  step!: StepConfig;

  constructor() { }

  ngOnInit() { }

}
