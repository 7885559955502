

<div class="modal-header">

  <h2>Subject Property</h2>

  <!-- hack to fool ngbModal autofocus -->

  <input type="text" style="display:none" />

  <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" aria-label="Close"
       (click)="activeModal.dismiss()">
      <span class="svg-icon svg-icon-2x">
        <close-x-svg-icon></close-x-svg-icon>
      </span>
  </div>
</div>

<div class="modal-body py-10 px-lg-17">
  <form #subjectPropertySummaryForm="ngForm" class="form" novalidate id="subjectPropertySummaryForm" name="subjectPropertySummaryForm">
    <div class="me-n7 pe-7">
      <div class="row">
        <address [address]="address"></address>
      </div>
      <div class="row mb-5">
        <div class="col-md-6 fv-row">
          <label class="required form-label mb-2">Type</label>
          <select class="form-select form-select-solid" data-control="select2" name="propertyType"
                  [(ngModel)]="subjectProperty!.propertyType" #propertyType="ngModel" required
                  [ngClass]="{'is-invalid' : propertyType && propertyType.touched && propertyType.invalid}">
            <option value="">Select one...</option>
            <option *ngFor="let propertyType of propertyTypes;" [ngValue]="propertyType.value">
              {{propertyType.name}}
            </option>
          </select>
          <div class="invalid-feedback">Please select a value.</div>
        </div>
        <div class="col-md-6 fv-row">
          <label class="required form-label mb-2">Will Be</label>
          <select class="form-select form-select-solid" data-control="select2" name="type"
                  [(ngModel)]="subjectProperty!.propertyWillBe" #propertyWillBe="ngModel" required
                  [ngClass]="{'is-invalid' : propertyWillBe && propertyWillBe.touched && propertyWillBe.invalid}">
            <option value="">Select one...</option>
            <option *ngFor="let willBeType of willBeTypes; let i = index" [ngValue]="willBeType.value">
              {{willBeType.name}}
            </option>
          </select>
          <div class="invalid-feedback">Please select a value.</div>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-md-6 fv-row">
          <label class="required form-label mb-2">Value</label>
          <input class="form-control form-control-solid" mask="separator" thousandSeparator="," inputmode="decimal" prefix="$"
                 [ngClass]="{'is-invalid' : presentValue && presentValue.touched && presentValue.invalid}"
                 #presentValue="ngModel" required [(ngModel)]="subjectProperty!.presentValue" placeholder="" name="presentValue" />
          <div class="invalid-feedback">Value is required.</div>
        </div>
        <div class="col-md-6 fv-row">
          <label class="required form-label mb-2">Year Built</label>
          <input class="form-control form-control-solid col-md-6" #yearBuilt="ngModel" name="yearBuilt" type="number"
                 [(ngModel)]="subjectProperty!.yearBuilt" [ngClass]="{'is-invalid' : yearBuilt && yearBuilt.touched && yearBuilt.invalid}" required />
          <div class="invalid-feedback">Please write Year Built value.</div>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer flex-center">
  <button type="button" class="btn btn-white me-3" (click)="activeModal.dismiss()">Cancel</button>
  <button type="button" class="btn btn-primary" (click)="onSaveClicked()">Ok</button>
</div>
