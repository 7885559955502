<div class="card">
  <div class="card-header d-flex align-items-center justify-content-center" style="min-height: 50px; padding-top: 6px">
    <h5 class="opacity-80">Loan Actions</h5>
  </div>
  <div class="card-body" *ngIf="actionsApplicable">
    <div class="row btn-loan-actions">
      <a *ngIf="actionsApplicable.hasPreApprovalLetter && hasPrequalDetails" href="javascript:void(0)"
        class="btn btn-sm bp-primary-bg-color py-4" (click)="openGeneratePrequalLetterDialog()"><i
          class="text-white fa fa-file-alt fs-3" style="margin-right: 13px;"></i>Generate
        Preapproval Letter
      </a>

      <a *ngIf="actionsApplicable.isMortgageCalculatorEnabled" href="javascript:void(0)"
        class="btn btn-sm bp-primary-bg-color py-4" (click)="openMortgageCalculatorModal()"><i
          class="text-white fa fa-calculator fs-3" style="margin-right: 8.5px;"></i>
        Open Payment Calculator
      </a>

      <a *ngIf="actionsApplicable.hasAssetVerificationTasks" href="javascript:void(0)"
        class="btn btn-sm bp-primary-bg-color py-4" (click)="onOpenAssetVerificationClicked()"><i
          class="text-white fa fa-university fs-3" style="margin-right: 6.5px;"></i>
        Verify Your Assets
      </a>

      <a *ngIf="actionsApplicable.hasIncomeVerificationTasks" href="javascript:void(0)"
        class="btn btn-sm bp-primary-bg-color py-4" (click)="onOpenIncomeVerificationClicked()"><i
          class="text-white fas fa-money-bill me-1 fs-3"></i>
        Verify Your Emp./Income
      </a>

      <a *ngIf="actionsApplicable.isUploadingAdditionalDocsEnabled" href="javascript:void(0)"
        class="btn btn-sm bp-primary-bg-color py-4" (click)="onAddAdditionalDocumentsClicked()"><i
          class="fas fa-paperclip text-white fs-3" style="margin-right: 8.5px;"></i>
        Add Additional Documents
      </a>

      <a *ngIf="paymentLinkConfig && paymentLinkConfig.isVisible && paymentLinkConfig.paymentLink"
        href="{{paymentLinkConfig.paymentLink || ''}}" target="_blank" class="btn btn-sm bp-primary-bg-color py-4"><i
          class="text-white fas fa-money-bill me-1 fs-3" style="margin-right: 8.5px;"></i>
        {{paymentLinkConfig.buttonText || 'Make a Payment'}}
      </a>
    </div>
  </div>
</div>