<div class="modal-header">

  <h2>{{header}}</h2>

  <!-- hack to fool ngbModal autofocus -->
  <input type="text" style="display:none" />

  <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" aria-label="Close"
    (click)="activeModal.dismiss('Cross click')">
    <span class="svg-icon svg-icon-2x">
      <close-x-svg-icon></close-x-svg-icon>
    </span>
  </div>
</div>

<div class="modal-body py-10 px-lg-17">
  <form #incomeInfoForm="ngForm" class="form" novalidate id="incomeInfoForm" name="incomeInfoForm">
    <div class="row mb-5" *ngIf="!inEditMode">
      <div class="col-md-6 fv-row">
        <label class="required form-label mb-2">Borrower</label>
        <borrower-picker [required]="true" [ngModelOptions]="{standalone: true}" [(ngModel)]="income.borrowerId" [borrowers]="borrowers"></borrower-picker>
      </div>
      <div class="col-md-6 fv-row">
      </div>
    </div>

    <div class="me-n7 pe-7" *ngIf="editorIncomeType === 'OtherTypesOfIncome'">
      <div class="row g-9 mb-5">
        <div class="col-md-12 fv-row">
          <label class="required form-label mb-2">Type of Income</label>
          <select name="incomeType" required
            [ngClass]="{'is-invalid' : incomeType && incomeType.touched && incomeType.invalid}"
            #incomeType="ngModel" class="form-select form-select-solid" [(ngModel)]="income.typeOfIncome"
            data-control="select2" data-hide-search="true" data-placeholder="Choose one">
            <option></option>
            <option [ngValue]="otherIncomeType.value" *ngFor="let otherIncomeType of otherIncomeTypes">{{otherIncomeType.name}}</option>
          </select>
          <div class="invalid-feedback">Please select a value.</div>
            </div>
      </div>
    </div>

    <div class="me-n7 pe-7" *ngIf="editorIncomeType === 'OtherTypesOfIncome' && income.typeOfIncome === 'OtherTypesOfIncome' && !isHidden('income.typeOfIncomeOtherDescription')">
      <div class="row g-9 mb-5">
        <div class="col-md-12 fv-row">
          <label class="form-label mb-2" [ngClass]="{'required': isRequired('income.typeOfIncomeOtherDescription')}">
            {{getLabel('income.typeOfIncomeOtherDescription', 'Description')}}
          </label>
          <input class="form-control form-control-solid"
            [ngClass]="{'is-invalid' : description && description.touched && description.invalid}"
            #description="ngModel" [required]="isRequired('income.typeOfIncomeOtherDescription')"
            [(ngModel)]="income.typeOfIncomeOtherDescription" placeholder="" name="description" />
          <div class="invalid-feedback">Description is required.</div>
        </div>
      </div>
    </div>

    <div class="me-n7 pe-7" *ngIf="!isHidden('income.monthlyIncome')">
      <div class="row g-9 mb-5">
        <div class="col-md-12 fv-row">
          <label class="form-label mb-2" [ngClass]="{'required': isRequired('income.monthlyIncome')}">{{label}}</label>
          <input class="form-control form-control-solid" mask="separator" thousandSeparator="," inputmode="decimal" prefix="$"
            [ngClass]="{'is-invalid' : monthlyIncome && monthlyIncome.touched && monthlyIncome.invalid}"
            #monthlyIncome="ngModel" [required]="isRequired('income.monthlyIncome')"
            [(ngModel)]="income.monthlyIncome" placeholder="" name="monthlyIncome" />
          <div class="invalid-feedback">Monthly income is required.</div>
        </div>
      </div>
    </div>

  </form>
</div>

<div class="modal-footer flex-center">
  <button type="button" class="btn btn-white me-3" (click)="activeModal.dismiss()">Cancel</button>
  <button type="button" class="btn btn-primary" (click)="onSaveClicked()">Ok</button>
</div>




