<div class="mt-3">
  <ng-container [ngSwitch]="questionMetaData.questionType">
    <label for="question" class="form-label mb-1">{{questionMetaData.questionText}}</label>
    <ng-select *ngSwitchCase="'MultiSelect'"
      width="100%"
      name="question"
      id="question"
      bindLabel="answerText"
      bindValue="questionAnswerId"
      placeholder="Select one or more options"
      [closeOnSelect]="false"
      [items]="questionMetaData.answers"
      #currentQuestion="ngModel"
      required
      class="custom-ng-select"
      [(ngModel)]="multipleAnswersGivenToQuestion"
      (ngModelChange)="onAnswerChanged()"
      [multiple]="true"
      [ngClass]="{'is-invalid': currentQuestion && currentQuestion.touched && currentQuestion.invalid}"
      appendTo="body">
    </ng-select>
    <select
      *ngSwitchCase="'SelectList'"
      name="question"
      id="question"
      [ngClass]="{'is-invalid' : currentQuestion && currentQuestion.touched && currentQuestion.invalid}"
      required
      #currentQuestion="ngModel" class="form-select form-select-solid"
      [(ngModel)]="singleAnswerGivenToQuestion"
      data-control="select2" data-placeholder="Choose one">
      <option *ngFor="let option of questionMetaData.answers"
        [ngValue]="option.questionAnswerId">
        {{option.answerText}}
      </option>
    </select>
    <!--default case when there are no matches -->
    <input *ngSwitchDefault
      class="form-control form-control-solid" autocomplete="off"
      #currentQuestion="ngModel"
      [(ngModel)]="singleAnswerGivenToQuestion"
      placeholder=""
      id="question"
      name="question"
      [ngClass]="{'is-invalid' : currentQuestion && currentQuestion.touched && currentQuestion.invalid}"
      required/>
    <div class="invalid-feedback">Please, answer the question.</div>
  </ng-container>
</div>

<ng-container *ngFor="let answer of question.answers">
  <ng-container *ngFor="let borrowerId of question.validForBorrowerIds">
    <div class="mt-3" *ngIf="multipleAnswersGivenToQuestion.includes(answer.questionAnswerId)">
      <ask-question *ngFor="let subsequentQuestion of answer.questions"
        [questionMetaData]="questionMetaDataByQuestionId.get(subsequentQuestion.questionId)"
        [question]="subsequentQuestion">
      </ask-question>
    </div>
  </ng-container>
</ng-container>
