import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from "@angular/forms";
import { EnumsService } from '../../../services/enums.service';
import { EnumerationItem } from 'projects/shared/models/enumeration-item.model';
import { MultiCheckboxPickerComponent } from 'projects/shared/multi-checkbox-picker/multi-checkbox-picker.component';
import { Address } from '../../../models/address-model';
import { Borrower } from '../../../models/borrower-model';
import { AddressComponent } from '../../address/address.component';
import { ConfigurableFieldHostComponent } from '../../configurable-field-host.component';
import { WizardFlowServiceBase } from '../../../services/wizard/wizard-flow-service.base';
import { UtilsService } from '../../../services/utils.service';
import { RealEstateOwned } from '../../../models/mortgage.model';
import { ReoExpenseBreakdownComponent } from '../reo-expense-breakdown/reo-expense-breakdown.component';

@Component({
  selector: 'reo-dialog',
  templateUrl: 'reo-dialog.component.html',
  styleUrls: ['./reo-dialog.component.scss']
})

export class RealEstateOwnedDialogComponent extends ConfigurableFieldHostComponent implements OnInit, AfterViewInit {

  @ViewChild('addressComponent') addressComponent: AddressComponent | undefined;

  @ViewChild('reoInfoForm') reoInfoForm: NgForm | undefined;

  @ViewChild(MultiCheckboxPickerComponent)
  borrowerPicker!: MultiCheckboxPickerComponent;

  @ViewChild('popover')
  reoExpenseBreakdownPopover: NgbPopover;

  @ViewChild('reoExpenseBreakdown')
  reoExpenseBreakdown: ReoExpenseBreakdownComponent | undefined;

  private _reo: RealEstateOwned;
  private _inEditMode: boolean = false;

  public address: Address = new Address();
  inCoApplyFlow: boolean = false;

  possibleBorrowers: EnumerationItem[] = [];
  disabledBorrowers: EnumerationItem[] = [];
  selectedBorrowers: EnumerationItem[] = [];

  public integerPattern = {
    'X': { pattern: new RegExp('[1-9]') },
    'Y': { pattern: new RegExp('[0-9]') }
  }

  protected willPropertyBeSold: string = 'no';

  protected isExpensesBrokenDown: boolean = false;

  constructor(public activeModal: NgbActiveModal,
    private readonly _utilsService: UtilsService,
    private readonly _enumsService: EnumsService,
    private readonly _wizardFlowService: WizardFlowServiceBase) {
    super();
    this._reo = new RealEstateOwned();
    this._reo.dispositionStatus = 'Retained';
    this._reo.reoId = this._utilsService.generateUniqueNumericId();
    const allBorrowers = this._wizardFlowService.context.mortgage.borrowers;
    this.possibleBorrowers = allBorrowers.map(b => new EnumerationItem(b.firstName + " " + b.lastName, b.borrowerId !== undefined ? b.borrowerId.toString() : (-1 * (new Date()).valueOf()).toString()));
    this.inCoApplyFlow = this._wizardFlowService.inCoApplyFlow;
  }

  @Output()
  saveClickedForCreate = new EventEmitter<RealEstateOwned>();

  @Output()
  saveClickedForEdit = new EventEmitter<RealEstateOwned>();

  @Input()
  public set reo(reo: RealEstateOwned) {
    this._reo = reo;
    this.address.address1 = this._reo.address1;
    this.address.city = this._reo.city;
    this.address.zipCode = this._reo.zipCode;
    this.address.state = this._reo.state;
    this._inEditMode = true;
    if (!this._reo.dispositionStatus) {
      this._reo.dispositionStatus = 'Retained';
    }
    this.willPropertyBeSold = (this._reo.dispositionStatus === 'PendingSale' ? 'yes' : 'no');
    this.fixReoAcquiredDate();
    this.setTotalExpenses();
  }

  public get propertyTypes(): any[] {
    return this._enumsService.reoPropertyTypes;
  }

  public get usageTypes(): any[] {
    return this._enumsService.usageTypes;
  }

  public get reoStatusTypes(): any[] {
    return this._enumsService.reoStatusTypes;
  }

  public get reo(): RealEstateOwned {
    return this._reo;
  }

  public get inEditMode(): boolean {
    return this._inEditMode;
  }

  public get header(): string {
    if (this._inEditMode) {
      return "Editing REO";
    }
    return "Add REO";
  }

  onPropertyWillBeSoldChanged = () => {
    if (this.willPropertyBeSold === 'no') {
      this._reo.dispositionStatus = 'Retained';
    } else {
      this._reo.dispositionStatus = 'PendingSale';
      this._reo.intendedUsageType = null;
    }
  }

  onSaveClicked = () => {
    this.reoInfoForm?.form.markAllAsTouched();
    if (this.reoInfoForm?.form.valid && this.addressComponent?.validate()) {
      this.activeModal.close();
      const owningBorrowerIds = this.borrowerPicker?.selectedItems.map(i => Number(i.value));
      this._reo.owningBorrowerIds = owningBorrowerIds;
      this._reo.address1 = this.address.address1!;
      this._reo.city = this.address.city!;
      this._reo.state = this.address.state!;
      this._reo.zipCode = this.address.zipCode!;
      if (this.inCoApplyFlow) {
        this._reo.owningBorrowerIds = [<number>this._wizardFlowService.context.currentBorrower.borrowerId];
      } else {
        const owningBorrowerIds = this.borrowerPicker?.selectedItems.map(i => Number(i.value));
        this._reo.owningBorrowerIds = owningBorrowerIds;
      }
      if (this._inEditMode) {
        this.saveClickedForEdit.emit(this._reo);
      } else {
        this.saveClickedForCreate.emit(this._reo);
      }
    }
  }

  onPurchasePriceChanged = () => {
    if (!this.reo.purchasePrice) {
      this.reo.purchasePrice = undefined;
    }
  }

  onPropertyValueChanged = () => {
    if (!this.reo.marketValue) {
      this.reo.marketValue = undefined;
    }
  }

  protected onOpenReoExpenseBreakdownClicked = () => {
    this.reoExpenseBreakdownPopover.open({ reo: this._reo });
  }

  protected onOkClickedOnReoBreakdownPopover = (reo: RealEstateOwned) => {
    this._reo.monthlyMiscExpenses = reo.monthlyMiscExpenses;
    this._reo.hoa = reo.hoa;
    this._reo.propertyTax = reo.propertyTax;
    this._reo.hazardInsurance = reo.hazardInsurance;
    this.setTotalExpenses();
  }

  public get saveClickedEmitterForEdit(): EventEmitter<RealEstateOwned> {
    return this.saveClickedForEdit;
  }

  public get saveClickedEmitterForCreate(): EventEmitter<RealEstateOwned> {
    return this.saveClickedForCreate;
  }

  private setBorrowerPickerValues() {
    if (!this.inEditMode) {
      return;
    }
    let owningBorrowerIds: number[] = this.reo.owningBorrowerIds;
    if (!owningBorrowerIds) {
      return;
    }
    let enumerationItems: EnumerationItem[] = [];
    for (let i = 0; i < owningBorrowerIds.length; i++) {
      let borrower: Borrower | undefined = this._wizardFlowService.context.mortgage.borrowers.find(b => b.borrowerId === owningBorrowerIds[i]);
      if (borrower) {
        let enumerationItem = new EnumerationItem(borrower.firstName + " " + borrower.lastName, borrower.borrowerId!.toString());
        enumerationItems.push(enumerationItem);
      }
    }
    this.borrowerPicker.selectedItems = enumerationItems;
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.setBorrowerPickerValues();
  }

  protected onTotalExpensesChanged = () => {
    this._reo.monthlyMiscExpenses = this._reo['totalExpenses'];
    this.isExpensesBrokenDown = false;
  }

  private fixReoAcquiredDate = () => {
    if (this.reo.dateAcquired) {
      const dateAcquired = new Date(this.reo.dateAcquired);
      let monthString = "";
      let month = dateAcquired.getMonth() + 1;
      monthString = month >= 10 ? month.toLocaleString() : "0" + month;
      let dayString = "";
      let day = dateAcquired.getDate();
      dayString = day >= 10 ? day.toLocaleString() : "0" + day;
      this.reo.dateAcquired = monthString + '/' + dayString + '/' + dateAcquired.getFullYear();
    }
  }

  private setTotalExpenses = () => {
    const monthlyMiscExpenses = this.reo.monthlyMiscExpenses || 0;
    const hoa = this.reo.hoa || 0;
    const insurance = this.reo.hazardInsurance || 0;
    const propertyTax = this.reo.propertyTax || 0;
    this.reo['totalExpenses'] = monthlyMiscExpenses + hoa + insurance + propertyTax;
    this.isExpensesBrokenDown = hoa > 0 || insurance > 0 || propertyTax > 0;
  }
}

