<form #stepEditorForm="ngForm" class="form" novalidate id="stepEditorForm" name="stepEditorForm">
  <div class="me-n7 pe-7">

    <div class="row mb-8">
      <div class="col-md-6 fv-row">
        <label class="form-label mb-2 required">Name</label>
          <input class="form-control form-control-solid" required
          [ngClass]="{'is-invalid' : name && name.touched && name.invalid}"
          #name="ngModel" [(ngModel)]="step.name" placeholder="Name" name="name" />
        <div class="invalid-feedback">Name is required.</div>
      </div>
      <div class="col-md-6 fv-row" *ngIf="step.nextStepDeciderConfig">
        <label class="form-label mb-2 required">Next Step Decider</label>
        <select name="nextStepDecider" id="nextStepDecider" required data-placeholder="Choose one" [(ngModel)]="step.nextStepDeciderConfig!.deciderType"
          (ngModelChange)="onNextStepDeciderTypeChanged()" class="form-select form-select-solid">
          <option value="2">Simple</option>
          <option value="1">Expression</option>
        </select>
        <div class="invalid-feedback">Next Step Decider is required.</div>
      </div>
    </div>
    <div class="row mb-8">
      <div class="col-md-6 fv-row" *ngIf="step.nextStepDeciderConfig && step.nextStepDeciderConfig!.deciderType.toString() == '2'">
        <label class="form-label mb-2 required">Next Step</label>
        <select name="nextStep" id="nextStep" required data-placeholder="Choose one" [(ngModel)]="simpleNextStepPath" (ngModelChange)="onSimpleNextStepChanged()" #nextStep="ngModel"
          [ngClass]="{'is-invalid' : nextStep && nextStep.touched && nextStep.invalid}"
        class="form-select form-select-solid">
        <option value="">Select one</option>
        <option value="{{step.path}}" *ngFor="let step of flow.steps">{{step.name}}</option>
      </select>
      <div class="invalid-feedback">You need to pick the next step.</div>
      </div>
      <div class="col-md-12 fv-row" [hidden]="!expressionNextStepDeciderConfig || !step.nextStepDeciderConfig || step.nextStepDeciderConfig!.deciderType.toString() != '1'">
        <expression-next-step-decider-editor [showButtons]='false' #expressionEditor [flow]="flow" [config]="expressionNextStepDeciderConfig"></expression-next-step-decider-editor>
      </div>
    </div>
    <div class="row mb-6">
      <div class="col-md-12 fv-row d-flex justify-content-center">
        <a (click)="onCancelClicked()" class="btn btn-sm btn-light">Cancel</a>
        <a (click)="onOkClicked()" class="btn btn-sm btn-primary mx-4">OK</a>
      </div>
    </div>
  </div>
</form>
