import { WizardNavGroup } from "../wizard-nav-group.model";
import { NavigationType } from "./nav-type.enum";

export class WizardFlowSettings {
  title: string = "";
  comment: string = "";
  navigationType: NavigationType = NavigationType.Horizontal;
  hasProgressBar: boolean = true;
  isDefault: boolean = false;
  isActive: boolean = false;
  navGroups: WizardNavGroup[] = [];
}
