import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Address } from '../../models/address-model';
import { Borrower } from '../../models/borrower-model';
import { UtilsService } from '../../services/utils.service';
import { AddressComponent } from '../address/address.component';
import * as _ from 'lodash';

@Component({
  selector: 'personal-info-dialog',
  templateUrl: 'personal-info-dialog.component.html'
})

export class PersonalInfoDialogComponent implements OnInit {

  ssnPattern = {'0': {pattern: new RegExp('\\d'), symbol: 'X'}};

  @ViewChild('personalInfoForm') personalInfoForm: NgForm | undefined;
  @ViewChild(AddressComponent) addressComponent: AddressComponent | undefined;

  private _borrower!: Borrower;

  public firstName!: string;
  public lastName!: string;
  public maritalStatus: string | undefined;
  public phoneNumber!: string;
  public address!: Address;
  public dateOfBirth!: string;
  public residenceStatus!: string;
  public socialSecNum!: string;

  @Input()
  public set borrower(borrower: Borrower) {
    this._borrower = _.cloneDeep(borrower);
  }

  get borrower(): Borrower {
    return this._borrower;
  }

  constructor(public activeModal: NgbActiveModal,
    public utilsService: UtilsService) {
  }

  ngOnInit() {
    this.address = this.utilsService.getCurrentAddress(this._borrower);
    this.residenceStatus = this._borrower.declarations!.residenceStatus!;
  }

  onSaveClicked = () => {
    this.personalInfoForm?.form.markAllAsTouched();
    if (this.addressComponent?.validate() && this.personalInfoForm?.form.valid) {
      this.utilsService.setCurrentAddress(this._borrower, this.address);
      this.activeModal.close(this._borrower);
    }
  }
}
