import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'loading-indicator',
  templateUrl: 'loading-indicator.component.html'
})
export class LoadingIndicatorComponent implements OnInit {

  @Input()
  heading: string = "Welcome!";

  @Input()
  message1: string = "Starting your mortgage application.";

  @Input()
  message2: string = "Please wait while we prepare the application for you...";

  @Input()
  showImage: boolean = true;

  constructor() { }

  ngOnInit() { }
}
