
 <ng-template #declaration>
  <div class="card shadow-sm mb-5">
    <div class="card-header">
        <h3 class="card-title">{{mainBorrower.firstName + " " + mainBorrower.lastName}}</h3>
    </div>
    <div class="card-body">
      <borrower-declarations #borrowerDeclarations [borrower]="mainBorrower"></borrower-declarations>
    </div>
  </div>

  <ng-container *ngIf="!inCoApplyFlow">
    <ng-container *ngFor="let borrower of coBorrowers">
      <div *ngIf="borrowerSetting(borrower)" class="card shadow-sm mb-5" >
          <div class="card-header">
            <h3 class="card-title">{{borrower.firstName + " " + borrower.lastName}}</h3>
          </div>
          <div class="card-body">
            <borrower-declarations #borrowerDeclarations [borrower]="borrower"></borrower-declarations>
          </div>
      </div>
    </ng-container>
  </ng-container>

</ng-template>

<wizard-step-template [stepMainTemplate]="declaration" [step]="step" [stepComponent]="this"></wizard-step-template>
