import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { PortalServiceFactory } from '../../services/portal-service.factory';
import { LocalStorageService } from '../../services/local-storage.service';
import { PortalBaseComponent } from '../portal-base.component';
import { PortalScope } from '../../login/login.component';

@Component({
  selector: 'e-sign-via-token-confirmation',
  templateUrl: './e-sign-via-token-confirmation.component.html',
  styleUrls: ['./e-sign-via-token-confirmation.component.scss'],
})
export class ESignViaTokenConfirmationComponent
  extends PortalBaseComponent
  implements OnInit {

  isAgent: boolean = false;

  protected taskNumber: string;
  protected allTasksCount: string;

  protected messageToUserAfterEsign: string = '';
  protected messageTitleToUserAfterEsign: string = '';

  private _orderId: number;

  constructor(
    private readonly portalServiceFactory: PortalServiceFactory,
    private readonly _spinner: NgxSpinnerService,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _localStorage: LocalStorageService
  ) {
    super(portalServiceFactory);
  }

  ngOnInit(): void {
    let portalScope = this._localStorage.getItem('portalScope') as PortalScope;
    if (portalScope && portalScope == PortalScope.Agent) {
      this.isAgent = true;
    }

    const eSignToken = this._activatedRoute.snapshot.paramMap.get('token');
    this.taskNumber = this._activatedRoute.snapshot.paramMap.get('taskNumber');
    this.allTasksCount = this._activatedRoute.snapshot.paramMap.get('allTasksCount');
    this._orderId = Number(this._activatedRoute.snapshot.paramMap.get('orderId'));

    if (this.taskNumber == this.allTasksCount) {
      this.messageToUserAfterEsign = `Congratulations - You've successfully signed all your documents`;
      this.messageTitleToUserAfterEsign = 'You can now close this browser window.';
    } else {
      this.messageToUserAfterEsign = `You've successfully signed ${this.taskNumber} of ${this.allTasksCount} documents`;
      this.messageTitleToUserAfterEsign = `Please wait while we load the next document...`;
    }
    const loanDocTaskId = Number(
      this._activatedRoute.snapshot.queryParamMap.get('loanDocTaskId')
    );
    const action = this._activatedRoute.snapshot.queryParamMap.get('event');

    this.portalService
      .onSignerViewSubmittedForAnonymousEsign(eSignToken, loanDocTaskId, action)
      .subscribe({
        next: (response) => {
          this._spinner.hide();
          window.parent.postMessage(
            {
              shouldCloseEsignDocIframe: true,
              orderId: this._orderId,
              response: response,
            },
            '*'
          );
        },
        error: (error) => {
          console.error(
            error.error.message
              ? error.error.message
              : 'E-sign confirmation failed.'
          );
          this._spinner.hide();
        },
      });
  }
}
