export class StepExpression
{
    ifCondition!: string;
    goToPath!: string;

    constructor(ifCondition: string, gotoPath: string) {
        this.ifCondition = ifCondition;
        this.goToPath = gotoPath;
    }
}
