export class HousingExpense {
  homeownersInsurance: number | undefined | null;
  firstMortgagePrincipalAndInterest: number | undefined | null;
  otherMortgageLoanPrincipalAndInterest: number | undefined | null;
  supplementalPropertyInsurance: number | undefined | null;
  realEstateTax: number | undefined | null;
  mortgageInsurance: number | undefined | null;
  homeownersAssociationDuesAndCondominiumFees: number | undefined | null;
  otherHousingExpense: number | undefined | null;
}
