import { Component, ComponentFactoryResolver, Input, OnInit, ViewChild } from '@angular/core';
import { SvgIconComponentFactory } from 'projects/shared/svg-icons/svg-icon-component-factory';
import { SvgIconHostDirective } from 'projects/shared/svg-icons/svg-icon-host.directive';

@Component({
  selector: 'wizard-vertical-nav-step',
  template: `<div class="wizard-step" data-wizard-type="step" [attr.data-wizard-state]="state">
              <div class="wizard-wrapper">
                <div class="wizard-icon">
                  <span class="svg-icon svg-icon-2x">
                    <ng-template svgIconHost></ng-template>
                    <i *ngIf="iconClass && iconClass.length > 0" class="{{iconClass}}" style="font-size: 0.65em"></i>
                  </span>
                </div>
                <div class="d-flex wizard-label mt-4">
                  <h3 class="wizard-title">{{index + 1}}. {{title}}</h3>
                </div>
                <span style='margin-top:15px; margin-left:15px' class="svg-icon svg-icon-1 svg-icon-success" *ngIf="state === 'complete'">
                  <duotone-done-circle-svg-icon ></duotone-done-circle-svg-icon>
                  </span>
              </div>
            </div>`
})

export class WizardVerticalNavStepComponent implements OnInit {

  @Input() state: string = "pending";
  @Input() index: number = 0;
  @Input() title: string = "";
  @Input() iconClass: string = "";
  @Input() svgIconName: string = "";

  @ViewChild(SvgIconHostDirective, {static: true}) svgIconHost!: SvgIconHostDirective;

  constructor(private _svgIconFactory: SvgIconComponentFactory,
    private _componentFactoryResolver: ComponentFactoryResolver) {
  }

  ngOnInit(): void {
    this.loadIcon(this.svgIconName);
  }

  loadIcon = (svgIconName: string) => {
    if (!svgIconName || svgIconName.length === 0) {
        return;
    }
    const svgIconComponent = this._svgIconFactory.getSvIconComponent(svgIconName);
    if (!svgIconComponent) {
        return;
    }
    const componentFactory = this._componentFactoryResolver.resolveComponentFactory(svgIconComponent);

    const viewContainerRef = this.svgIconHost.viewContainerRef;
    viewContainerRef.clear();

    viewContainerRef.createComponent(componentFactory);
  }
}
