<ng-template #creditScores>
  <loading-indicator *ngIf="isCheckingCreditHistory" [heading]="'Gathering your scores!'"
  [message1]="'Please wait while we gather your credit scores...'" [message2]="''"></loading-indicator>

  <div class="d-flex mb-10" *ngIf="inEditMode">
    <div class="col-sm-3 col-form-label fw-bold fs-6 editor-label">Show credit indicator icons:</div>
    <div class="col-sm-2 form-check form-switch form-check-custom form-check-solid">
      <input class="form-check-input h-20px w-30px" type="checkbox" id="showIcons"
      [ngModelOptions]="{standalone: true}" [(ngModel)]="step.useScoreIndicatorIcons" />
      <label class="form-check-label" for="configuresCoborrowers">
        {{step.useScoreIndicatorIcons ? "Yes" : "No"}}
      </label>
    </div>
  </div>

  <div *ngIf="!isCheckingCreditHistory">
    <div *ngFor="let borrowerCreditScores of allBorrowerCreditScores; let i = index">
      <div class="card shadow-sm mb-5" >
        <div class="card-header">
          <h3 class="card-title">{{borrowerCreditScores.borrowerName ?? '--'}}</h3>
        </div>
        <div class="card-body">
          <borrower-credit-scores
            [useScoreIndicatorIcons]="step.useScoreIndicatorIcons"
            #borrowerCreditScore
            [borrowerCreditScores]="borrowerCreditScores">
          </borrower-credit-scores>
        </div>
      </div>
    </div>
  </div>

</ng-template>

<wizard-step-template [stepMainTemplate]="creditScores" [step]="step" [stepComponent]="this"></wizard-step-template>
