import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GeneratePrequalLetterRequestModel } from '../../../models/borrower-portal/generate-prequal-letter-request.model';
import { PrequalDetailsModel } from '../../../models/borrower-portal/prequal-details.model';
import { PortalServiceFactory } from '../../../services/portal-service.factory';
import { PortalBaseComponent } from '../../portal-base.component';

@Component({
  selector: 'app-generate-prequal-letter-dialog',
  templateUrl: './generate-prequal-letter-dialog.component.html',
  styleUrls: ['./generate-prequal-letter-dialog.component.scss']
})
export class GeneratePrequalLetterDialogComponent extends PortalBaseComponent implements OnInit {

  @Input() details: PrequalDetailsModel;

  req: GeneratePrequalLetterRequestModel = new GeneratePrequalLetterRequestModel();

  step: number = 1;

  showAdditionalOptionsStep: boolean = false;

  constructor(
    private readonly portalServiceFactory: PortalServiceFactory,
    public activeModal: NgbActiveModal
  ) {
    super(portalServiceFactory);
  }

  ngOnInit(): void {
    this.showAdditionalOptionsStep = !!this.details.proofOfFundsDocFileId || !!this.details.duDocFileId || !!this.details.lpaDocFileId || !!this.details.duHistoryDocumentId || !!this.details.lpaHistoryDocumentId;
  }

  generatePrequalLetter = () => {
    this.activeModal.close(this.req);
  }
}
