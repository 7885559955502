import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'user-account-template',
  templateUrl: 'user-account-template.component.html'
})

export class UserAccountTemplateComponent implements OnInit {

  @Input()
  contentTemplate!: TemplateRef<any>;

  constructor() {
    document.body.style.backgroundImage = "url('../../../assets/images/login-background-2.jpg')";
    document.body.style.backgroundSize = "cover";
    document.body.style.backgroundPosition = "center left";
  }

  ngOnInit() { }

  ngOnDestroy(){
    document.body.style.backgroundImage = "";
  }
}
