import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'projects/shared/services/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Quote } from 'projects/borrower-app/src/app/models/pricing/pricing-response.model';

@Component({
  selector: 'price-options-dialog',
  templateUrl: 'price-options-dialog.component.html',
  styleUrls: ['./price-options-dialog.component.scss']
})
export class PriceOptionsDialogComponent implements OnInit {

  @ViewChild('noteForm')
  noteForm: NgForm | undefined;

  @Input()
  priceOptions: Quote[] = [];

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.configureBasedOnScreenSize(window.innerWidth);
  }

  protected priceOptionsPages: Quote[][] = null;
  protected selectedQuote: Quote;
  protected isMobile: boolean = false;

  images = [944, 1011, 984].map((n) => `https://picsum.photos/id/${n}/900/500`);

  constructor(
    public activeModal: NgbActiveModal,
    private readonly _notifsService: NotificationService,
    private readonly _spinner: NgxSpinnerService,) {
  }

  ngOnInit() {
    this.configureBasedOnScreenSize(window.innerWidth);
    this.priceOptionsPages = this.divideIntoPages(this.priceOptions);
  }

  onPriceOptionSelected = (quote: Quote) => {
    this.selectedQuote = quote;
  }

  private divideIntoPages(priceOptions: Quote[]): any {
    let pages: Quote[][] = [];
    let currentPageNumber = 1;
    let currentPage: Quote[] = [];
    for (let i = 0; i < priceOptions.length; i++) {
      currentPage.push(priceOptions[i]);
      if (currentPage.length === 4) {
        pages[currentPageNumber] = currentPage;
        currentPageNumber++;
        currentPage = [];
      }
    }
    if (currentPage.length > 0) {
      pages.push(currentPage);
    }
    return pages.filter(p => p.length);
  }

  onOkClicked() {
    this.activeModal.close(this.selectedQuote);
  }

  private configureBasedOnScreenSize(windowWidth: number) {
    this.isMobile = windowWidth < 1200
  }
}
