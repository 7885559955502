import { IDecideNextStep } from "../../services/wizard/next-step-decider/next-step-decider.interface";
import { VerifyCreditStepComponent } from "../../wizard/wizard-steps/verify-credit-step/verify-credit-step.component";
import { WizardStepBase } from "./wizard-step-base.model";

export class VerifyCreditStep extends WizardStepBase {

  hideRealTimeCreditRunOption: boolean = false;

  constructor(id: string, name: string, path: string, nextStepDecider?: IDecideNextStep) {
    super(id, name, path, nextStepDecider);
    this.component = VerifyCreditStepComponent;
  }
}
