import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Borrower } from '../../models/borrower-model';
import { ResidencyAddress } from '../../models/residency-address.model';
import { SubjectProperty } from '../../models/subject-property.model';
import { EnumsService } from '../../services/enums.service';
import { UtilsService } from '../../services/utils.service';
import { WizardFlowServiceBase } from '../../services/wizard/wizard-flow-service.base';
import { MortgageApplication } from '../../models/mortgage-app.model';

@Component({
  selector: 'borrower-summary',
  templateUrl: 'borrower-summary.component.html'
})

export class BorrowerSummaryComponent implements OnInit {

  private _borrower!: Borrower;

  address: string = "";
  fullName: string = "";
  email: string = "";
  phoneNumber: string = "";
  maritalStatus: string = "";
  propertyType: string = "";
  propertyValue: number | undefined;
  residencyBasis: string = "";
  residencyDuration: string = "";
  rent: number | undefined;
  monthlyPayment: number | undefined;
  isMainBorrower: boolean = false;

  @Output()
  borrowerInfoClicked: EventEmitter<any> = new EventEmitter<any>();

  private _mortgage: MortgageApplication;

  @Input()
  set mortgage(mortgage: MortgageApplication) {
    this._mortgage = mortgage;
    const subjectProperty = mortgage.subjectProperty;
    if (subjectProperty) {
      this.propertyType = this._enumsService.propertyTypeName(subjectProperty.propertyType);
    }
  }

  @Input()
  purchasePrice!: number;

  @Input()
  set borrower(borrower: Borrower) {
    this._borrower = borrower;
    this.address = this._utilsService.getCurrentAddressStreetName(this._borrower);
    this.fullName = this._borrower.firstName + " " + this._borrower.lastName;
    this.email = this._borrower.primaryEmail;
    this.phoneNumber = this._utilsService.formatPhoneNumber(this._borrower.mobilePhone);
    this.maritalStatus = this._enumsService.maritalStatusName(this._borrower.maritalStatus);
    let residencyAddress: ResidencyAddress = this._utilsService.getCurrentResidencyAddress(this._borrower);
    this.residencyBasis = this._enumsService.residencyBasisName(residencyAddress.residencyBasis);
    this.residencyDuration = residencyAddress.durationYears + " yrs " + residencyAddress.durationMonths + " mnts ";
    if (this.residencyBasis === "Rent") {
      this.rent = residencyAddress.rent;
    } else if (this.residencyBasis === "Own") {
      const reo = this._mortgage?.realEstateOwned.find(reo =>
        reo?.address1?.toLowerCase() === residencyAddress.address?.address1?.toLowerCase() &&
        reo?.city?.toLowerCase() === residencyAddress.address?.city?.toLowerCase() &&
        reo?.state?.toLowerCase() === residencyAddress.address?.state?.toLowerCase() &&
        reo?.zipCode?.toLowerCase() === residencyAddress.address?.zipCode?.toLowerCase());
      if (reo) {
        this.monthlyPayment = reo.mortgagePayment;
      }
    }
    this.isMainBorrower = borrower.primaryEmail === this._wizardFlowService.userInfo?.email;
  }

  get borrower(): Borrower {
    return this._borrower;
  }

  detailsHidden: boolean = false;

  constructor(private readonly _utilsService: UtilsService,
    private readonly _enumsService: EnumsService,
    private readonly _wizardFlowService: WizardFlowServiceBase) { }

  ngOnInit() { }

  onBorrowerInfoClicked = () => {
    this.borrowerInfoClicked.emit();
  }
}
