import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
  pure: false,
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], args: any): any {
    if (items === undefined || items === null) return [];

    if (typeof args === 'string') {
      return items.filter((el) => el[args]);
    }

    // for array of objects e.g
    // | filter: { name: "Sean", lastName: "DeJulia" }
    if (typeof args === 'object') {
      Object.keys(args).forEach((argKey) => {
        items = items.filter((item) => {
          if (
            typeof item[args] === 'string' &&
            typeof args[argKey] === 'string'
          ) {
            return item[argKey].toLowerCase() === args[argKey].toLowerCase();
          } else {
            return item[argKey] == args[argKey];
          }
        });
      });
      return items;
    }

    return items;
  }
}
