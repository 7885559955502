import { IDecideNextStep } from "../../services/wizard/next-step-decider/next-step-decider.interface";
import { AddressStepComponent } from "../../wizard/wizard-steps/address-step/address-step.component";
import { Address } from "../address-model";
import { AddressFields } from "./address-fields.model";
import { WizardStepBase } from "./wizard-step-base.model";

export class AddressStep extends WizardStepBase {
  private readonly _addressFields: AddressFields;
  private readonly _model: any;

  address: Address;

  constructor(id: string, name: string | undefined, path: string,
    nextStepDecider: IDecideNextStep | undefined, addressFields: AddressFields, model: any) {
    super(id, name, path, nextStepDecider);
    this._addressFields = addressFields;
    this._model = model;
    this.component = AddressStepComponent;

    if (!this._addressFields.county) {
      this._addressFields.county = "county";
    }

    this.address = new Address();
    if (this._model) {
      this.address.address1 = this._model[this._addressFields.address];
      this.address.city = this._model[this._addressFields.city];
      this.address.state = this._model[this._addressFields.state];
      this.address.zipCode = this._model[this._addressFields.zipCode];
      this.address.county = this._model[this._addressFields.county];
    }
  }

  setAddress = (address: Address): void => {
    if (this._model) {
      this._model[this._addressFields.address] = address.address1;
      this._model[this._addressFields.city] = address.city;
      this._model[this._addressFields.state] = address.state;
      this._model[this._addressFields.zipCode] = address.zipCode;
      this._model[this._addressFields.county] = address.county;
    }
  }
}
