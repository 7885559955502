import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Borrower } from '../../models/borrower-model';
import { ResidencyBasis } from '../../models/enums/residency-basis.enum';
import { ResidencyType } from '../../models/enums/residency-type.enum';
import { ResidencyAddress } from '../../models/residency-address.model';
import { ConfigurableFieldHostComponent } from '../configurable-field-host.component';
import { AddressHistory2Component } from '../wizard-steps/address-history2/address-history2.component';
import { ReoPropertiesComponent } from '../wizard-steps/reo-properties/reo-properties.component';
import { formViewProvider } from '../../services/form-view.provider';
import { MortgageApplication } from '../../models/mortgage-app.model';
import { EnumsService } from '../../services/enums.service';
import { Address } from '../../models/address-model';
import { RealEstateOwned } from '../../models/mortgage.model';

@Component({
  selector: 'borrower-address-history',
  templateUrl: 'borrower-address-history.component.html',
  viewProviders: [formViewProvider],
})
export class BorrowerAddressHistoryComponent extends ConfigurableFieldHostComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChildren('previousAddressHistory') addressHistoryComponents: QueryList<AddressHistory2Component> | undefined;

  @ViewChild('currentAddressHistory') currentAddressComponent: AddressHistory2Component | undefined;

  @ViewChild("reoProperties")
  reoProperties: ReoPropertiesComponent;

  @ViewChild('addressHistoryForm') addressHistoryForm: NgForm | undefined;

  @Input()
  mortgage: MortgageApplication;

  @Input()
  borrower!: Borrower;

  @Input()
  currentAddressLabel!: string;

  @Input()
  reoFieldsToConfig: any = {};

  @Input()
  inEditMode: boolean = false;

  @Output()
  residencyBasedChangedToNonOwner: EventEmitter<any> = new EventEmitter<any>();

  currentAddress!: ResidencyAddress;
  previousAddresses: ResidencyAddress[] = [];

  monthsOfAddressHistory: number = 0;

  previousAddressToDelete: ResidencyAddress | undefined = undefined;

  showInsufficientAddressHistoryWarning: boolean = false;

  protected reoForCurrentAddress: RealEstateOwned = new RealEstateOwned();

  private _invalidComponent;

  private _invalidComponentName: string;;

  constructor(private readonly _enumsService: EnumsService) {
    super();
  }

  public get hasAtLeast2YearsOfAddressHistory(): boolean {
    const residencyAddresses = [...this.previousAddresses, this.currentAddress];
    this.monthsOfAddressHistory = this.calculateMonthsOfAddressHistory(residencyAddresses);
    return this.monthsOfAddressHistory >= 24;
  }

  ngOnInit() {
    this.currentAddress = this.borrower.residencyAddresses.find(a => a.residencyType == ResidencyType.PresentAddress);
    if (!this.currentAddress) {
      this.currentAddress = new ResidencyAddress(ResidencyType.PresentAddress);
      this.borrower.residencyAddresses.push(this.currentAddress);
    }
    this.previousAddresses = this.borrower.residencyAddresses.filter(a => a.residencyType == ResidencyType.FormerAddress);
  }

  ngAfterViewInit() {
    this.reoForCurrentAddress = this.initializeReoRelatedToAddress(this.currentAddress);
    this.addOrRemoveReoBasedOnOwnershipStatus(this.currentAddress.residencyBasis);
  }

  ngOnDestroy(): void {
    if (this.reoForCurrentAddress && this.reoForCurrentAddress) {
      if (this.reoForCurrentAddress.dateAcquired) {
        this.reoForCurrentAddress.dateAcquired = new Date(this.reoForCurrentAddress.dateAcquired).toISOString();
      }
    }
  }

  private initializeReoRelatedToAddress = (address: ResidencyAddress): RealEstateOwned => {
    if (!this.inEditMode) {
      const existingReo = this.findAlreadyExistingReoInMortgageByAddress(address.address);
      if (existingReo) {
        this.fixReoAcquiredDate(existingReo);
        return existingReo;
      } else {
        let reo = new RealEstateOwned();
        reo.address1 = address.address?.address1;
        reo.city = address.address?.city;
        reo.state = address.address?.state;
        reo.zipCode = address.address?.zipCode;
        reo.owningBorrowerIds = [this.borrower.borrowerId];
        return reo;
      }
    } else {
      return this.initializeReoDefaultsForEditMode();
    }
  }

  onAddressChanged = (address: Address) => {
    const index = this.reoForCurrentAddress.owningBorrowerIds.findIndex(id => id == this.borrower.borrowerId);
    if (index >= 0) {
      this.reoForCurrentAddress.owningBorrowerIds.splice(index, 1);
    }
    this.reoForCurrentAddress = this.initializeReoRelatedToAddress(this.currentAddress);
    this.addOrRemoveReoBasedOnOwnershipStatus(this.currentAddress.residencyBasis);
  }

  onOwnershipStatusChanged = (ownershipStatus: ResidencyBasis) => {
    this.addOrRemoveReoBasedOnOwnershipStatus(ownershipStatus);
  }

  onPreviousAddressDeleteCancelClicked = (previousAddress: ResidencyAddress) => {
    this.previousAddressToDelete = undefined;
  }

  onPreviousAddressDeleteConfirmClicked = (previousAddress: ResidencyAddress) => {
    const index = this.borrower.residencyAddresses.indexOf(previousAddress);
    if (index >= 0) {
      this.borrower.residencyAddresses.splice(index, 1);
      this.previousAddresses = this.borrower.residencyAddresses.filter(a => a.residencyType == ResidencyType.FormerAddress);
    }
    this.previousAddressToDelete = undefined;
  }

  private addPreviousAddressClicked = () => {
    const previousAddress = new ResidencyAddress(ResidencyType.FormerAddress);
    this.borrower.residencyAddresses.push(previousAddress);
    this.previousAddresses = this.borrower.residencyAddresses.filter(a => a.residencyType == ResidencyType.FormerAddress);
  }

  onPreviousAddressDeleteClicked = (previousAddress: ResidencyAddress) => {
    this.previousAddressToDelete = previousAddress;
  }

  onDurationAtAddressChanged = (e: any) => {
    // const hasEnoughAddressHistory = this.hasAtLeast2YearsOfAddressHistory;
    // this.showInsufficientAddressHistoryWarning = !hasEnoughAddressHistory;
    // if (!hasEnoughAddressHistory) {
    //   if (this.validateAddressHistoryFields()) {
    //     this.addPreviousAddressClicked();
    //   }
    // }
  }

  public validateAddressHistory = (): boolean => {
    let valid = this.validateAddressHistoryFields();
    const hasAtLeast2YearsOfAddressHistory = this.hasAtLeast2YearsOfAddressHistory;
    if (!hasAtLeast2YearsOfAddressHistory && valid) {
      this.addPreviousAddressClicked();
    }
    return valid && hasAtLeast2YearsOfAddressHistory;
  }

  private addOrRemoveReoBasedOnOwnershipStatus = (ownershipStatus: ResidencyBasis) => {
    const alreadyExistingReo = this.findAlreadyExistingReoInMortgageByAddress(this.currentAddress.address);
    if (ownershipStatus === ResidencyBasis.Own) {
      if (!alreadyExistingReo) {
        this.mortgage.realEstateOwned.push(this.reoForCurrentAddress);
      } else {
        const index = alreadyExistingReo.owningBorrowerIds.findIndex(id => id == this.borrower.borrowerId);
        if (index < 0) {
          alreadyExistingReo.owningBorrowerIds.push(this.borrower.borrowerId);
        }
      }
    } else {
      if (alreadyExistingReo) {
        if (alreadyExistingReo.owningBorrowerIds.includes(this.borrower.borrowerId)) {
          if (alreadyExistingReo.owningBorrowerIds.length > 1) {
            const index = alreadyExistingReo.owningBorrowerIds.findIndex(id => id == this.borrower.borrowerId);
            if (index >= 0) {
              alreadyExistingReo.owningBorrowerIds.splice(index, 1);
            }
          } else {
            const index = this.mortgage.realEstateOwned.findIndex(reo => reo === this.reoForCurrentAddress);
            if (index >= 0) {
              this.mortgage.realEstateOwned.splice(index, 1);
            }
          }
        }
      }
    }
  }

  private calculateMonthsOfAddressHistory = (residencyAddresses: ResidencyAddress[]): number => {
    let months: number = 0;
    for (let residencyAddress of residencyAddresses) {
      const yearsAtAddress = residencyAddress.durationYears ? residencyAddress.durationYears : 0;
      const monthsAtAddress = residencyAddress.durationMonths ? residencyAddress.durationMonths : 0;
      months = months + 12 * yearsAtAddress + monthsAtAddress;
    }
    return months;
  }

  private validateAddressHistoryFields = (): boolean => {
    let valid = true;
    if (this.addressHistoryForm) {
      this.addressHistoryForm.form.markAllAsTouched();
      valid = this.addressHistoryForm.form.valid;
    }
    if (this.currentAddressComponent) {
      valid = this.currentAddressComponent.validate() && valid;
      if (!valid) {
        this._invalidComponentName = 'currentAddress';
      }
    }
    if (this.addressHistoryComponents) {
      const addressHistoryComponents: AddressHistory2Component[] = this.addressHistoryComponents.toArray();
      addressHistoryComponents.forEach(c => {
        valid = c.validate() && valid;
        if (!valid && !this._invalidComponentName && !this._invalidComponent)
          this._invalidComponentName = 'previousAddresses';
        this._invalidComponent = c;
      })
    }
    return valid;
  }

  public validate = (): boolean => {
    this._invalidComponentName = null;
    this._invalidComponent = null;
    let valid = this.validateAddressHistoryFields();
    if (!valid) {
      return false;
    }
    if (this.currentAddress.residencyBasis === 'Own') {
      valid = this.reoProperties.validate();
      if (!valid) {
        this._invalidComponentName = 'reo';
      }
    }
    if (valid) {
      const hasEnoughAddressHistory = this.hasAtLeast2YearsOfAddressHistory;
      this.showInsufficientAddressHistoryWarning = !hasEnoughAddressHistory;
      if (!hasEnoughAddressHistory) {
        this.addPreviousAddressClicked();
      }
      valid = hasEnoughAddressHistory;
    }

    return valid;
  }

  public scrollToFirstInvalid = () => {
    let firstInvalidOneId: string = null;
    switch (this._invalidComponentName) {
      case 'currentAddress':
        const addressFormControls = Object.assign(
          this.currentAddressComponent.addressComponent.addressForm.form.controls,
          this.currentAddressComponent.addressRelatedInfoForm.form.controls
        );

        for (var key in addressFormControls) {
          if (addressFormControls.hasOwnProperty(key)) {
            if (addressFormControls[key].status === 'INVALID') {
              firstInvalidOneId = key;

              break;
            }
          }
        }
        break;
      case 'reo':
        for (var key in this.reoProperties.reoPropertiesForm.form.controls) {
          if (this.reoProperties.reoPropertiesForm.form.controls.hasOwnProperty(key)) {
            if (this.reoProperties.reoPropertiesForm.form.controls[key].status === 'INVALID') {
              firstInvalidOneId = key;
              break;
            }
          }
        }
        break;
      case 'previousAddresses':
        const previousAddressFormControls = Object.assign(
          this._invalidComponent.addressComponent.addressForm.form.controls,
          this._invalidComponent.addressRelatedInfoForm.form.controls
        );
        for (var key in previousAddressFormControls) {
          if (previousAddressFormControls.hasOwnProperty(key)) {
            if (previousAddressFormControls[key].status === 'INVALID') {
              firstInvalidOneId = key;
              break;
            }
          }
        }
        break;
    }
    if (firstInvalidOneId) {
      const element = document.getElementById(firstInvalidOneId);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
          });
        }, 250);
      }
    }
  }

  private fixReoAcquiredDate = (reo: RealEstateOwned) => {
    if (reo.dateAcquired) {
      const dateAcquired = new Date(reo.dateAcquired);
      let monthString = "";
      let month = dateAcquired.getMonth() + 1;
      monthString = month >= 10 ? month.toLocaleString() : "0" + month;
      let dayString = "";
      let day = dateAcquired.getDate();
      dayString = day >= 10 ? day.toLocaleString() : "0" + day;
      reo.dateAcquired = monthString + '/' + dayString + '/' + dateAcquired.getFullYear();
    }
  }

  private initializeReoDefaultsForEditMode = (): RealEstateOwned => {
    const reo = new RealEstateOwned();
    reo.dateAcquired = '01/01/2000';
    reo.currentUsageType = this._enumsService.usageTypes[0].value;
    reo.intendedUsageType = this._enumsService.usageTypes[0].value;
    reo.typeOfProperty = this._enumsService.reoPropertyTypes[0].value;
    reo.marketValue = 500000;
    reo.purchasePrice = 450000;
    reo.dispositionStatus = this._enumsService.reoStatusTypes[0].value;
    reo.amountOfMortgage = 100000;
    reo.mortgagePayment = 3450;
    reo.monthlyMiscExpenses = 5600;
    return reo;
  }

  private findAlreadyExistingReoInMortgageByAddress = (address: Address): RealEstateOwned => {
    const reo = this.mortgage?.realEstateOwned.find(reo =>
      reo.address1.toLocaleLowerCase() === address?.address1?.toLocaleLowerCase() &&
      reo.city.toLocaleLowerCase() === address?.city?.toLocaleLowerCase() &&
      reo.state === address?.state &&
      reo.zipCode === address?.zipCode);

    return reo;
  }
}
