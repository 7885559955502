import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'submit-app-redirect',
  templateUrl: 'submit-app-redirect.component.html'
})

export class SubmitAppRedirectComponent implements OnInit, OnDestroy {

  private _routeSubscription1: Subscription;
  private _routeSubscription2: Subscription;

  constructor(private readonly _activatedRoute: ActivatedRoute, private readonly _authService: AuthService,
      private readonly _navigationService: NavigationService) {
    this._routeSubscription1 = this._activatedRoute.queryParams.subscribe(queryParams => {
      this._routeSubscription2 = this._activatedRoute.params.subscribe(routeParams => {
        const loanId = Number(this._activatedRoute.snapshot.paramMap.get('loanId'));
        const token = queryParams['authToken'];
        if (token) {
          this._authService.setBorrowerToken(token);
          this._navigationService.navigateToPath(`borrower-portal/loans/${loanId}`, true);
        }
      }, error => {
      });
    });
  }

  ngOnInit() { }

  ngOnDestroy(): void {
    this._routeSubscription1?.unsubscribe();
    this._routeSubscription2?.unsubscribe();
  }
}
