import { BorrowerAppStatusDetails } from "./borrower-app-status-details.model";

export class BorrowerAppStatus {
    currentStatus: string = "";
    lastSuccessfulStatus: string = "";
    //json data
    details: BorrowerAppStatusDetails;

    constructor() {
      this.details = new BorrowerAppStatusDetails();
    }
}
