<ng-template #checkEmail>
  <div class="d-flex flex-column flex-column-fluid p-10 pb-lg-10 login-wrapper">
    <div class="w-md-500px bg-white rounded shadow-sm p-7 p-lg-7 login-body">
      <div class="d-flex justify-content-center mb-4">
        <logo [logoUrlsToTry]="possibleLogoUrls"></logo>
      </div>
      <div class="text-center my-4">
        <h2 class="text-dark mb-3">Please, check your email...</h2>
      </div>
      <div class="alert alert-success d-flex align-items-center p-5 mb-5">
        <span class="svg-icon svg-icon-2hx svg-icon-info me-3">
          <duotone-envelope-svg-icon></duotone-envelope-svg-icon>
        </span>
        <div style="overflow: hidden;">
          <div style="white-space: normal;">
            We sent an email invitation to:
          </div>
          <div class="text-ellipsis">
            <strong>{{email}}.</strong>
          </div>
        </div>
      </div>
      <div class="text-center mb-10">
        <div class="text-gray-400 fw-bold fs-5">Please, click the link in the email to finish creating your account
          and complete your loan application.
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex flex-column justify-content-center align-items-center">
    <div class="login-footer">
      <img src="https://borrowerportal.lodasoft.dev/assets/img/Logo.svg" alt="Lodasoft">
    </div>
  </div>
</ng-template>

<user-account-template [contentTemplate]="checkEmail"></user-account-template>
