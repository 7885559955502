<!--begin::Tables Widget 11-->
<div class="card mb-5 mb-xl-8 border rounded">
  <!--begin::Header-->
  <div class="card-header border-0 pt-5">
    <h3 class="card-title align-items-start flex-column">
      <span class="card-label fw-bolder fs-3 mb-1">{{header}}</span>
    </h3>
  </div>
  <!--end::Header-->
  <!--begin::Body-->
  <div class="card-body py-3">
    <!--begin::Table container-->
    <div class="table-responsive">
      <!--begin::Table-->
      <table class="table align-middle gs-0 gy-4">
        <!--begin::Table head-->
        <thead>
          <tr class="fw-bolder text-muted bg-light">
            <th class="ps-4 min-w-325px rounded-start">Name</th>
            <th class="min-w-125px">Display Name</th>
            <th class="min-w-125px">Required</th>
            <th class="min-w-200px">Hidden</th>
          </tr>
        </thead>
        <!--end::Table head-->
        <!--begin::Table body-->
        <tbody>
          <tr *ngFor="let fieldConfig of fieldConfigs">
            <td>
              <div class="d-flex align-items-center">
                <div class="d-flex justify-content-start flex-column">
                  <span class="text-dark fw-bolder mb-1 fs-6">{{fieldConfig.name}}</span>
                </div>
              </div>
            </td>
            <td>
              <div class="d-flex justify-content-start flex-column">
                <input [(ngModel)]="fieldConfig.displayName" class="form-control" id="{{fieldConfig.name}}" name="{{fieldConfig.name}}">
              </div>
            </td>
            <td>
              <div class="form-check form-switch form-check-custom form-check-solid me-10">
                <input class="form-check-input h-20px w-30px" type="checkbox" [(ngModel)]="fieldConfig.isRequired"/>
            </div>
            </td>
            <td>
              <div class="form-check form-switch form-check-custom form-check-solid me-10">
                <input class="form-check-input h-20px w-30px" type="checkbox" [(ngModel)]="fieldConfig.isHidden"/>
            </div>
            </td>
          </tr>
        </tbody>
        <!--end::Table body-->
      </table>
      <!--end::Table-->
    </div>
    <!--end::Table container-->
  </div>
  <!--begin::Body-->
</div>
<!--end::Tables Widget 11-->
