<form #addressHistoryForm="ngForm" novalidate id="addressHistoryForm" name="addressHistoryForm">
  <div class="w-100">
    <div class="row mb-5">
      <address [address]="addressHistory.address!"></address>
    </div>
    <div class="row mb-5">
      <div class="col-md-6 fv-row">
        <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
          <span class="required">Residency Basis</span>
        </label>
        <div class="position-relative">
          <select name="ownOrRent" required [ngClass]="{'is-invalid' : ownOrRent && ownOrRent.touched && ownOrRent.invalid}"
          [(ngModel)]="addressHistory.ownOrRent" class="form-select form-select-solid" data-control="select2" data-hide-search="true"
          #ownOrRent="ngModel" required [ngClass]="{'is-invalid' : ownOrRent && ownOrRent.touched && ownOrRent.invalid}"
          data-placeholder="Select a Residency">
          <option></option>
          <option *ngFor="let residencyBasis of residencyBasisValues; let i = index" value="{{residencyBasis.value}}">
            {{residencyBasis.name}}
          </option>
          </select>
          <div class="invalid-feedback">Please select a value.</div>
        </div>
      </div>
      <div class="col-md-6 fv-row">
        <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
          <span class="required">How many years have you lived here?</span>
        </label>
        <div class="position-relative">
          <input type="text" class="form-control form-control-solid" [(ngModel)]="addressHistory.yearsLived"
            required [ngClass]="{'is-invalid' : yearsLived && yearsLived.touched && yearsLived.invalid}" placeholder="" name="yearsLived"
            [ngClass]="{'is-invalid' : yearsLived && yearsLived.touched && yearsLived.invalid}" #yearsLived="ngModel" />
          <div class="invalid-feedback">Please enter a value.</div>
        </div>
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-md-6 fv-row">
        <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
          <span class="required">How many months have you lived here?</span>
        </label>
        <div class="position-relative">
          <input type="text" class="form-control form-control-solid" [(ngModel)]="addressHistory.monthsLived"
            required [ngClass]="{'is-invalid' : monthsLived && monthsLived.touched && monthsLived.invalid}" placeholder="" name="monthsLived"
            [ngClass]="{'is-invalid' : monthsLived && monthsLived.touched && monthsLived.invalid}" #monthsLived="ngModel" />
          <div class="invalid-feedback">Please select a value.</div>
        </div>
      </div>
    </div>
  </div>
</form>
