export class Declarations {
  occupyProperty: boolean | undefined;
  havePropertiesOwnership: boolean   | undefined;
  typeOfProperty: string | undefined;
  heldTitleHow: string | undefined;
  haveRelationshipWithSeller: boolean  | undefined;
  borrowedDownPayment: boolean  | undefined;
  borrowedDownPaymentInfo:number | undefined;
  applyingForOtherMortgage: boolean  | undefined;
  applyingForNonMortgageCredit: boolean  | undefined;
  propertyHasLienWithCleanEnergyProgram: boolean  | undefined;
  coSignedLoan: boolean  | undefined;
  haveJudgement: boolean | undefined;
  haveTaxDebt: boolean  | undefined;
  haveLawsuit: boolean  | undefined;
  haveConveyedTitleInLieuOfForeclosure: boolean | undefined;
  haveCompletedShortSale: boolean  | undefined;
  beenForclosed: boolean  | undefined;
  declaredBankruptcy: boolean  | undefined;
  bankruptcyType:string | undefined;
  residenceStatus:string | undefined | null = null;
  undisclosedMoneyAmount: number | undefined;

  constructor() {
    this.residenceStatus = null;
  }
}

