<div class="modal-header">
  <h5 class="modal-title d-flex align-items-center">Add Files</h5>
  <button
    type="button"
    class="btn-close"
    (click)="activeModal.dismiss()"
    aria-label="Close"
  ></button>
</div>

<div class="modal-body">
  <form>
    <file-upload (uploadClicked)="onUploadClicked($event)" [isUploading]="isUploading"></file-upload>
  </form>
</div>

