<ng-template #creditInquiry>

  <div class="text-center" *ngIf="isLoading">
    <i class="fas fa-circle-notch fa-4x fa-spin"></i>
  </div>

  <ng-container *ngIf="!isLoading">
    <div class="card shadow-sm mb-5">
      <div class="card-header">
          <h3 class="card-title">{{mainBorrower.firstName + " " + mainBorrower.lastName}}</h3>
      </div>
      <div class="card-body">
        <borrower-credit-inquiry #borrowerCreditInquiry [borrower]="mainBorrower" [borrowerIndex]="0"></borrower-credit-inquiry>
      </div>
    </div>

    <ng-container *ngIf="!inCoApplyFlow">
      <ng-container *ngFor="let borrower of coBorrowers; let i = index">
        <div *ngIf="borrowerSetting(borrower) && borrower.creditInquiry && borrower.creditInquiry.entries && borrower.creditInquiry.entries.length > 0" class="card shadow-sm mb-5" >
            <div class="card-header">
              <h3 class="card-title">{{borrower.firstName + " " + borrower.lastName}}</h3>
            </div>
            <div class="card-body">
              <borrower-credit-inquiry #borrowerCreditInquiry [borrower]="borrower" [borrowerIndex]="i+1"></borrower-credit-inquiry>
            </div>
        </div>
      </ng-container>
    </ng-container>
</ng-container>

</ng-template>

<wizard-step-template [stepMainTemplate]="creditInquiry" [step]="step"  [stepComponent]="this"></wizard-step-template>
