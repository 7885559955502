import { TypeOfIncome } from './enums/type-of-income.enum';
import { v4 as uuidv4 } from 'uuid';

export class IncomeItem {
  id: number = 0;
  name: string = '';
  description: string | null = '';
  monthlyPay: number;
  startDate: string | undefined;
  position: string | undefined;
  type: TypeOfIncome | undefined;
  digitallyVerified: boolean = false;
  isValid: boolean = false;
  isSelfEmployed: boolean = false;

  guid: string = '';
  employmentGuid: string = '';

  incomeBreakdown: IncomeSubItem[] = [];

  constructor(
    type: TypeOfIncome,
    monthlyPay: number,
    isValid: boolean,
    position?: string,
    startDate?: string,
    description: string | null = null
  ) {
    this.type = type;
    this.monthlyPay = monthlyPay;
    this.startDate = startDate;
    this.position = position;
    this.isValid = isValid;
    this.description = description;
    this.guid = uuidv4();
    this.employmentGuid = uuidv4();
  }

  public get isOther(): boolean {
    if (this.type === TypeOfIncome.OtherTypesOfIncome) {
      return true;
    }
    return false;
  }
}

export class IncomeSubItem {
  type: TypeOfIncome;
  monthlyPay: number;
  guid: string = '';
}

