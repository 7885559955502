import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BorrowerCharacteristic } from "../models/characteristics/borrower-characteristic.model";
import { LoanCharacteristic } from "../models/characteristics/loan-characteristic.model";
import { TaskCategory } from "../models/characteristics/task-category.model";
import { LoanDocTask } from "../models/tasks/loan-doc-task.model";
import { DataService } from "./data.service";

@Injectable()
export class CharacteristicsService {

    constructor(private readonly _dataService: DataService)
    {
    }

    getTaskCategories = (): Observable<TaskCategory[]> => {
      const url = `api/online-app/characteristics`;
      return this._dataService.get(url);
    }

    getLoanCharacteristics = (applicationId: number): Observable<LoanCharacteristic[]> => {
      const url = `api/online-app/loan/${applicationId}/characteristics`;
      return this._dataService.get(url);
    }

    getBorrowerCharacteristics = (applicationId: number): Observable<BorrowerCharacteristic[]> => {
      const url = `api/online-app/loan/${applicationId}/characteristics/borrower`;
      return this._dataService.get(url);
    }

    saveLoanCharacteristicsPreview = (applicationId: number, characteristics: LoanCharacteristic[]): Observable<LoanDocTask[]> => {
      const url = `api/online-app/loan/${applicationId}/characteristics/preview`;
      return this._dataService.post(url, characteristics);
    }

    saveBorrowerCharacteristicsPreview = (applicationId: number, characteristics: BorrowerCharacteristic[]): Observable<LoanDocTask[]> => {
      const url = `api/online-app/loan/${applicationId}/characteristics/borrower/preview`;
      return this._dataService.post(url, characteristics);
    }

    saveBorrowerCharacteristics = (applicationId: number, characteristics: BorrowerCharacteristic[], loanDocTasks: LoanDocTask[]): Observable<BorrowerCharacteristic[]> => {
      const url = `api/online-app/loan/${applicationId}/characteristics/borrower`;
      const payLoad = {
        borrowerCharacteristics: characteristics,
        loanDocTasks: loanDocTasks
      }
      return this._dataService.post(url, payLoad);
    }

    saveLoanCharacteristics = (applicationId: number, characteristics: LoanCharacteristic[], loanDocTasks: LoanDocTask[]): Observable<LoanCharacteristic[]> => {
      const url = `api/online-app/loan/${applicationId}/characteristics`;
      const payLoad = {
        loanCharacteristics: characteristics,
        loanDocTasks: loanDocTasks
      }
      return this._dataService.post(url, payLoad);
    }
  }
