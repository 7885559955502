import { Component, Injector, OnInit } from '@angular/core';
import { MaritalStatus } from '../../../models/enums/marital-status.enum';
import { MortgageApplication } from '../../../models/mortgage-app.model';
import { MortgageApplicationService } from '../../../services/mortgage-application.service';
import { WizardStepComponentBase } from '../wizard-step-base.component';

@Component({
  selector: 'marital-status',
  templateUrl: 'marital-status.component.html'
})

export class MaritalStatusComponent extends WizardStepComponentBase<any> implements OnInit {

    private _mortgageApplication: MortgageApplication;

    constructor(private readonly _mortgageApplicationService: MortgageApplicationService,
          private readonly _injector: Injector) {
        super(_injector);
        this._mortgageApplication = this._mortgageApplicationService.mortgageApplication;
    }

    public get isMaritalStatusSelected(): boolean {
        return this._mortgageApplication.maritalStatus !== undefined
          && this._mortgageApplication.maritalStatus !== MaritalStatus.None;
    }

    public get isMarried(): boolean {
        return this._mortgageApplication.maritalStatus === MaritalStatus.Married;
    }

    public get isUnMarried(): boolean {
        return this._mortgageApplication.maritalStatus === MaritalStatus.Unmarried;
    }

    public get isSeparated(): boolean {
        return this._mortgageApplication.maritalStatus === MaritalStatus.Separated;
    }

    onMarriedSelected = () => {
        this._mortgageApplication.maritalStatus = MaritalStatus.Married;
        this.wizardFlowService.navigateForward();
    }

    onUnMarriedSelected = () => {
        this._mortgageApplication.maritalStatus = MaritalStatus.Unmarried;
        this.wizardFlowService.navigateForward();
    }

    onSeparatedSelected = () => {
        this._mortgageApplication.maritalStatus = MaritalStatus.Separated;
        this.wizardFlowService.navigateForward();
    }

    ngOnInit() { }
}
