import { Component, Injector, OnInit } from '@angular/core';
import { Borrower } from '../../../models/borrower-model';
import { ResidencyBasis } from '../../../models/enums/residency-basis.enum';
import { ResidencyType } from '../../../models/enums/residency-type.enum';
import { ResidencyAddress } from '../../../models/residency-address.model';
import { WizardStepComponentBase } from '../wizard-step-base.component';
import { OwnRentStep } from '../../../models/wizard/own-rent-step-model';

@Component({
  selector: 'own-rent',
  templateUrl: 'own-rent.component.html',
})
export class OwnRentComponent
  extends WizardStepComponentBase<OwnRentStep>
  implements OnInit {

  private _currentBorrower: Borrower;
  public currentAddress!: ResidencyAddress;

  public get isOwned(): boolean | undefined {
    return this.currentAddress?.residencyBasis == ResidencyBasis.Own;
  }

  public get isOwnOrRentSelected(): boolean {
    //what to do if current borrower is null?
    return this.currentAddress.residencyBasis !== undefined;
  }

  public get isRent(): boolean {
    return this.currentAddress?.residencyBasis == ResidencyBasis.Rent;
  }

  public get isRentFree(): boolean {
    return this.currentAddress?.residencyBasis == ResidencyBasis.LivingRentFree;
  }

  onOwnedSelected = () => {
    this.currentAddress.residencyBasis = ResidencyBasis.Own;
    this.wizardFlowService.navigateForward();
  };

  onRentSelected = () => {
    this.currentAddress.residencyBasis = ResidencyBasis.Rent;
    this.wizardFlowService.navigateForward();
  };

  onRentFreeSelected = () => {
    this.currentAddress.residencyBasis = ResidencyBasis.LivingRentFree;
    this.wizardFlowService.navigateForward();
  };

  constructor(private readonly _injector: Injector) {
    super(_injector);

    this._currentBorrower = this.wizardFlowService.context.currentBorrower;
    let currentAddress = this._currentBorrower.residencyAddresses.find(
      (a) => a.residencyType == ResidencyType.PresentAddress
    );
    if (!currentAddress) {
      currentAddress = new ResidencyAddress(ResidencyType.PresentAddress);
      this._currentBorrower.residencyAddresses.push(currentAddress);
    }
    this.currentAddress = currentAddress;
    if (this.mortgageApplication.subjectProperty.purposeOfLoan === 'Refinance') {
      if (this.isCurrentAddressSameAsSubjectProperty()) {
        if (this.cameHereWithBackNavigation) {
          this.wizardFlowService.navigateBackward();
        } else {
          this.onNextClicked();
        }
        return;
      }
    }
  }

  ngOnInit() {
    this.step.requiresNextButton = (this.isOwned || this.isRent || this.isRentFree);
    this.step.title =
      'Regarding your address at ' + this.currentAddress.address?.address1;
  }

  private isCurrentAddressSameAsSubjectProperty = (): boolean => {
    if (!this.currentAddress.address) {
      return false;
    }
    return (
      this.currentAddress.address.address1.toLocaleLowerCase() ===
      this.mortgageApplication.subjectProperty.address1?.toLocaleLowerCase() &&
      this.currentAddress.address.city.toLocaleLowerCase() ===
      this.mortgageApplication.subjectProperty.city.toLocaleLowerCase() &&
      this.currentAddress.address.state.toLocaleLowerCase() ===
      this.mortgageApplication.subjectProperty.state.toLocaleLowerCase() &&
      this.currentAddress.address.zipCode.toLocaleLowerCase() ===
      this.mortgageApplication.subjectProperty.zipCode.toLocaleLowerCase()
    );
  };
}
