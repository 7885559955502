import { Component, Input, OnInit } from '@angular/core';
import { Originator } from '../../../models/originator.model';

@Component({
  selector: 'originator-info',
  templateUrl: 'originator-info.component.html',
  styleUrls: ['originator-info.component.scss']
})
export class OriginatorInfoComponent implements OnInit {
  constructor() { }

  @Input()
  selected: boolean = false;

  @Input()
  originator!: Originator;

  ngOnInit() { }
}
