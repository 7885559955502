import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ContactInfoModel } from "../../models/borrower-portal/contact-info-model";
import { BasePortalService } from "../../services/base-portal.service";

@Injectable()
export class AgentPortalService extends BasePortalService {

  protected get baseUrl(): string {
    return "AgentPortal";
  }

  public getContactInfoForApplication(appId: number): Observable<ContactInfoModel> {
    return this._dataService.get(`api/${this.baseUrl}/GetAgentContactInfoForApplication/${appId}`);
  }
}
