import { AfterViewInit, Component, OnDestroy, OnInit, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { SvgUtilsService } from 'projects/shared/svg-icons/svg-utils.service';
import { ExpressionNextStepDeciderConfig } from '../../models/wizard/config/expression-next-step-decider-config.model';
import { NextStepDeciderType } from '../../models/wizard/config/next-step-decider-type.enum';
import { SimpleNextStepDeciderConfig } from '../../models/wizard/config/simple-next-step-decider-config.model';
import { StepConfig } from '../../models/wizard/config/step-config.model';
import { WizardFlowConfig, WizardFlowConfigs } from '../../models/wizard/config/wizard-flow-config.model';
import { WizardFlowConfigServiceBase } from '../../services/wizard/wizard-flow-config-service.base';
import { FlowSummaryComponent } from '../flow-summary/flow-summary.component';

declare const KTDrawer: any;
declare const KTApp: any;

import { FlowStepComponent } from '../flow-step/flow-step.component';
import { UtilsService } from '../../services/utils.service';
import { Constants } from '../../services/constants';

declare const $: any;
import * as _ from 'lodash';
import { StepExpression } from '../../models/wizard/step-expression.model';
import { FlowStepEditorComponent } from '../flow-step-editor/flow-step-editor.component';
import { FlowType } from '../../models/wizard/config/flow-type.enum';
import { CreateStepComponent } from '../create-step/create-step.component';
import { StepSelectorComponent } from '../step-selector/step-selector.component';
import { StepTypeConfigFactory } from '../../models/wizard/config/factories/step-type-config.factory';
import { WizardFlowServiceBase } from '../../services/wizard/wizard-flow-service.base';
import { DynamicallyRenderedContentComponent } from 'projects/shared/dynamically-rendered-component/dynamically-rendered.component';
import { ExpressionNextStepDeciderEditorComponent } from '../expression-next-step-decider-editor/expression-next-step-decider-editor.component';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FlowConfigSaveDialogComponent } from '../../wizard/wizard-steps/config/config-save-dialog/flow-config-save-dialog.component';
import { ModalService } from 'projects/shared/modal/modal.service';
import { WizardFlowSettings } from '../../models/wizard/config/flow-settings.model';
import { NavigationService } from '../../services/navigation.service';
import { EnvironmentService } from 'projects/shared/services/environment.service';
import { FlowConfig } from '../../models/wizard/config/flow-config.model';
import { FlowListDialogComponent } from '../../wizard/wizard-steps/config/flow-list-dialog/flow-list-dialog.component';
import { AuthService } from '../../services/auth.service';
import { isArray } from 'lodash';

declare const Swal: any;

@Component({
  selector: 'flow-editor-v2',
  templateUrl: 'flow-editor-main-v2.component.html',
  styleUrls: ['flow-editor-main-v2.component.scss']
})

export class FlowEditorV2Component implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('stepPreview') stepPreview: DynamicallyRenderedContentComponent | undefined;

  @ViewChild('template', { static: true }) _template: any;

  @ViewChild('sourceFlowStep') sourceFlowStep: FlowStepComponent | undefined;

  @ViewChild(FlowSummaryComponent) flowSummary: FlowSummaryComponent | undefined;

  @ViewChild('stepEditor') stepEditor!: FlowStepEditorComponent | undefined;
  @ViewChild('stepSelector') stepSelector!: StepSelectorComponent | undefined;
  @ViewChild('createStep') stepCreator!: CreateStepComponent | undefined;
  @ViewChild('expressionEditor') expressionEditor!: ExpressionNextStepDeciderEditorComponent | undefined;

  private modalOptions: NgbModalOptions = {
    size: "lg",
    backdrop: "static",
    container: "body"
  };

  previewZoomValue: number = 8;

  logoUrl: string = "";

  componentBeingPreviewed: Type<any> | undefined;

  flow!: WizardFlowConfig;
  defaultFlow!: WizardFlowConfig;

  flowConfigs!: WizardFlowConfigs | undefined;

  sourceStep: StepConfig | undefined;
  stepToEdit!: StepConfig | undefined;

  destinationSteps: StepConfig[] = [];

  conditions: any = {};

  rightDrawerHeader: string = "";

  action: string = "";

  idSeed: number = 0;

  flowType: FlowType = FlowType.Apply;
  flowTypeName: string = "";
  flowGuid: string | undefined = "";

  expressionNextStepDeciderConfigToEdit: ExpressionNextStepDeciderConfig | undefined;

  allFlows: FlowConfig[] = [];

  get inEditMode(): boolean {
    return this._flowService.inEditMode;
  }

  set inEditMode(value: boolean) {
    this._flowService.inEditMode = value;
  }

  private _expressionEditOkedSubscription: any;

  private _wizardFlowSettings: WizardFlowSettings = new WizardFlowSettings();

  private _flowBeingEdited: FlowConfig | null = null;

  onEditModeToggled = () => {
  }

  onZoomChanged = () => {
    const zoomScale = Number(this.previewZoomValue) / 10;
    this._utilsService.setZoom(zoomScale, document.getElementsByClassName('step-preview')[0]);
  }

  drop(ev: any) {
    ev.preventDefault();
    $("#flow-editor-svg").removeClass("over");
    if (!this.sourceStep) {
      return;
    }
    var stepId = ev.dataTransfer.getData("text");

    //  First check if there is already one of that type in the flow
    const stepInFlow = this.flow.steps.find(s => s.id == stepId);
    if (stepInFlow) {
      if (this.sourceStep.nextStepDeciderConfig?.deciderType == NextStepDeciderType.Simple) {
        // If there is one, just make the source step's next step decider simple and add this existing step as destination
        this.sourceStep.nextStepDeciderConfig = new SimpleNextStepDeciderConfig(stepInFlow.path);
      } else {
        let expressionNextStepDecider = <ExpressionNextStepDeciderConfig>this.sourceStep.nextStepDeciderConfig;
        if (!expressionNextStepDecider.ifStepExpression || !expressionNextStepDecider.ifStepExpression.goToPath) {
          expressionNextStepDecider.ifStepExpression = new StepExpression("", stepInFlow.path);
        } else if (!expressionNextStepDecider.elseStepPath) {
          expressionNextStepDecider.elseStepPath = stepInFlow.path;
        } else {
          if (!expressionNextStepDecider.elseIfStepExpressions) {
            expressionNextStepDecider.elseIfStepExpressions = [];
          }
          expressionNextStepDecider.elseIfStepExpressions.push(new StepExpression("", expressionNextStepDecider.elseStepPath));
          expressionNextStepDecider.elseStepPath = stepInFlow.path;
        }
      }
    }

    this.redrawFlowEditor();
  }

  allowDrop(ev: any) {
    ev.preventDefault();
  }

  handleDragEnter(ev: any) {
    $("#flow-editor-svg").addClass("over");
  }

  handleDragLeave(ev: any) {
    $("#flow-editor-svg").removeClass("over");
  }

  constructor(private readonly _flowConfigService: WizardFlowConfigServiceBase,
    private readonly _flowService: WizardFlowServiceBase,
    private readonly _svgUtils: SvgUtilsService,
    private readonly _utilsService: UtilsService,
    private readonly _viewContainerRef: ViewContainerRef,
    private readonly _stepTypeConfigFactory: StepTypeConfigFactory,
    private readonly _modalService: ModalService,
    private readonly _navigationService: NavigationService,
    private readonly _environment: EnvironmentService,
    private readonly _authService: AuthService) {
    this._flowService.inEditMode = true;
    this.logoUrl = "/assets/images/logo.png";
    if (this._flowService.companyGuid || this._flowService.companyId) {
      var companyGuidOrId = this._flowService.companyGuid ? this._flowService.companyGuid : this._flowService.companyId;
      this.logoUrl = this._environment.companyLogoUrl + companyGuidOrId;
    }
    if (this._navigationService.editToken) {
      this._authService.setAdminToken(this._navigationService.editToken);
    }
  }

  ngOnInit(): void {
    this.flowGuid = this._utilsService.getUrlParameter(Constants.flowGuid);
    this._viewContainerRef.createEmbeddedView(this._template);
    this.destinationSteps.push();
    this._flowService.getFlowConfigs()!.subscribe((flows: FlowConfig[]) => {
      this.allFlows = flows;
      this._flowBeingEdited = this.allFlows.find(f => f.flowGuid === this.flowGuid);
    })
  }

  ngAfterViewInit(): void {
    let flowType: FlowType = <FlowType><unknown>this._utilsService.getUrlParameter("flowType");
    setTimeout(() => {
      if (flowType) {
        this.flowType = flowType;
      }
      this._flowService.initializeFlowsInPreviewMode(this.getEntryPath(this.flowType)).then(flowConfigs => {
        this.flowConfigs = flowConfigs;
        this.setFlowData(this.flowType);
      });
      KTDrawer.createInstances();
    });
  }

  ngOnDestroy(): void {
    if (this._expressionEditOkedSubscription) {
      this._subscriptionToConfigEdited.unsubscribe();
    }
  }

  onWizardFlowSettingsChanged = (e: WizardFlowSettings) => {
    this._wizardFlowSettings = e;
  }

  onNewStepCreationCancelled = () => {
    this.hideRightDrawer();
  }

  onEditStepCancelled = () => {
    this.stepToEdit = this._originalStep;
    this.sourceStep = this._originalStep;
    const step = this.flow.steps.find(s => s.id == this.stepToEdit?.id);
    if (step) {
      const index = this.flow.steps.indexOf(step);
      if (index >= 0 && this.stepToEdit) {
        this.flow.steps[index] = this.stepToEdit;
      }
    }
    this.redrawFlowEditor();
    this.hideRightDrawer();
  }

  onStepEditingOked = () => {
    this.hideRightDrawer();
    this.redrawFlowEditor();
  }

  onStepNextStepDeciderChanged = (step: StepConfig) => {
    this.redrawFlowEditor();
  }

  private _subscriptionToConfigCreated: any;
  private _subscriptionToConfigEdited: any;
  onSaveFlowClicked = () => {
    if (this.flowConfigs) {
      const modal = this._modalService.show(FlowConfigSaveDialogComponent, this.modalOptions);
      modal.componentInstance.title = this.flowConfigs.title;
      modal.componentInstance.comment = this.flowConfigs.comment;
      modal.componentInstance.guid = this.flowConfigs.guid;
      //this._flowConfig.navigationType = this.navType;
      this._subscriptionToConfigCreated = modal.componentInstance.saveClickedEmitterForCreate
        .subscribe((e: any) => this.onSaveClickedOnSaveModalForCreate(e.title, e.comment));
      this._subscriptionToConfigEdited = modal.componentInstance.saveClickedEmitterForEdit
        .subscribe((e: any) => this.onSaveClickedOnSaveModalForEdit(e.title, e.comment));
    }
  }

  onDrawerCloseClicked = () => {
    if (this.action == "edit-step") {
      this.onEditStepCancelled();
      return;
    }
    if (this.action == "edit-expression") {
      this.onEditStepCancelled();
      return;
    }
    if (this.action == "create-step") {
      this.onNewStepCreationCancelled();
      return;
    }
    this.hideRightDrawer();
  }

  onGotoFlowClicked = () => {
    this._flowService.navigateToFlow(this.flowGuid);
  }

  onFlowsClicked = () => {
    const modal = this._modalService.show(FlowListDialogComponent, this.modalOptions);
  }

  onNewStepCreated = (step: StepConfig) => {
    this.hideRightDrawer();
    this._flowService.addNewStepToActiveFlow(step);
    this.redrawFlowEditor();
  }

  onCreateStepClicked = () => {
    this.action = "create-step";
    this.rightDrawerHeader = "Create New Step";
    this.showRightDrawer();
    setTimeout(() => {
      if (this.stepCreator) {
        this.stepCreator!.stepTypeForNewStep = "";
      }
    });
  }

  onFlowTypeChanged = () => {
    let promise = null;
    if (this.flowType == FlowType.Register) {
      promise = this._flowService.initializeFlowsInPreviewMode(Constants.flowEndpoints.register);
    } else if (this.flowType == FlowType.Apply) {
      promise = this._flowService.initializeFlowsInPreviewMode(Constants.flowEndpoints.apply);
    } else {
      promise = this._flowService.initializeFlowsInPreviewMode(Constants.flowEndpoints.coApply);
    }
    if (promise) {
      promise.then(flowConfigs => {
        this.flowConfigs = flowConfigs;
        this.setFlowData(this.flowType);
        this.flowSummary?.redraw(this.flow);
      });
    }
  }

  onDeleteAllStepsClicked = () => {
    this.stepToEdit = undefined;
    this.flow.steps = [];
    this.sourceStep = undefined;
    this.redrawFlowEditor();
  }

  onCopyFromDefaultClicked = () => {

  }

  onStepSelected = (step: StepConfig) => {
    this.hideRightDrawer();
    this.stepToEdit = step;
    setTimeout(() => {
      if (this.stepPreview) {
        this.loadStepPreview(step);
      }
    });
  }

  private _originalStep: StepConfig | undefined;
  onEditStepClicked = (step: StepConfig) => {
    this._originalStep = _.cloneDeep(step);
    this.action = "edit-step";
    this.stepToEdit = step;
    this.rightDrawerHeader = "Editing Step - " + step.name!;
    this.showRightDrawer();
  }

  onStepSelectedOnFlowSummary = (step) => {
    this.destinationSteps = [];
    this.sourceStep = step;
    this.stepToEdit = step;
    this.configureDestinationSteps(this.sourceStep);
    setTimeout(() => {
      this.connectSteps();
      KTApp.initBootstrapTooltips();
      this.onStepSelected(step);
    }, 300);
  }

  onAddStepClicked = (step: StepConfig) => {
    this.action = "add-step";
    this.rightDrawerHeader = "Select a Next Step";
    this.showRightDrawer();
  }

  onStepDeleteClicked = (step: StepConfig) => {
    const stepToDelete = this.flow.steps.find(s => s.id === step.id);
    if (stepToDelete) {
      const index = this.flow.steps.indexOf(stepToDelete);
      if (index >= 0) {
        this.flow.steps.splice(index, 1);
        if (this.sourceFlowStep) {
          if (stepToDelete.id == this.sourceStep?.id) {
            this.sourceStep = undefined;
            this.destinationSteps = [];
          }
        }
        this.stepToEdit = undefined;
        this.redrawFlowEditor();
      }
    }
  }

  onDeleteStepFromNextStepDestinationsClicked = (step: StepConfig) => {
    if (this.sourceStep?.nextStepDeciderConfig?.deciderType == NextStepDeciderType.Simple) {
      (<SimpleNextStepDeciderConfig>this.sourceStep.nextStepDeciderConfig).gotoStepPath = undefined;
    } else {
      let expressionNextStepDecider = <ExpressionNextStepDeciderConfig>this.sourceStep?.nextStepDeciderConfig;
      if (expressionNextStepDecider.ifStepExpression) {
        if (expressionNextStepDecider.ifStepExpression.goToPath == step.path) {
          Swal.fire(
            'No can do!',
            'You are not allowed to remove a destination step that is linked with an IF condition. You can configure the condition or the destination by clicking on the link though.',
            'error'
          );
          //expressionNextStepDecider.ifStepExpression = new StepExpression("", "");
        }
      }
      if (expressionNextStepDecider.elseIfStepExpressions) {
        let elseIfExpression = expressionNextStepDecider.elseIfStepExpressions.find(e => e.goToPath == step.path);
        if (elseIfExpression) {
          const index = expressionNextStepDecider.elseIfStepExpressions.indexOf(elseIfExpression);
          if (index >= 0) {
            expressionNextStepDecider.elseIfStepExpressions.splice(index, 1);
          }
        }
      }
      if (expressionNextStepDecider.elseStepPath == step.path) {
        if (!expressionNextStepDecider.elseIfStepExpressions || expressionNextStepDecider.elseIfStepExpressions.length === 0) {
          Swal.fire(
            'No can do!',
            'You are not allowed to remove a destination step that is linked with an ELSE condition. You can configure the condition or the destination by clicking on the link though.',
            'error'
          );
          return;
        }
        expressionNextStepDecider.elseStepPath = undefined;
        // If there are ELSE IFs, convert the last one ELSE
        if (expressionNextStepDecider.elseIfStepExpressions && expressionNextStepDecider.elseIfStepExpressions.length > 0) {
          const indexOfLast = expressionNextStepDecider.elseIfStepExpressions.length - 1;
          expressionNextStepDecider.elseStepPath = expressionNextStepDecider.elseIfStepExpressions[indexOfLast].goToPath;
          expressionNextStepDecider.elseIfStepExpressions.splice(indexOfLast, 1);
        }
      }
    }
    this.redrawFlowEditor();
  }

  private getEntryPath = (flowType: FlowType): string => {
    switch (Number(flowType)) {
      case FlowType.Apply:
        return Constants.flowEndpoints.apply;
      case FlowType.Register:
        return Constants.flowEndpoints.register;
      case FlowType.CoApply:
        return Constants.flowEndpoints.coApply;
      default:
        return Constants.flowEndpoints.apply;
    }
  }

  private onSaveClickedOnSaveModalForCreate = (title: string, comment: string) => {
    let settings = new WizardFlowSettings();
    settings.title = title;
    settings.comment = comment;
    settings.hasProgressBar = this._wizardFlowSettings.hasProgressBar;
    settings.navigationType = this._wizardFlowSettings.navigationType;
    settings.navGroups = this._flowService.activeFlowConfig.stepGroups;
    const step = this._flowService.getCurrentStep();
    //settings.navigationType = this.navType;
    //this.startSpinner();
    const self = this;
    this._flowService.createFlowConfig(this.flowType, step, settings)?.subscribe(result => {
      this.inEditMode = false;
      Swal.fire(
        'Success!',
        'Your flow config has been successfully created with a new Guid: ' + result.flowGuid,
        'success'
      ).then(function (modalResult: any) {
        if (modalResult.value) {
          // Navigate to the new flow after creation
          self._navigationService.navigateToFlowEditor(result.flowGuid);
        }
      });
    }, err => {
      let errorHtml = "<div>An error occurred while saving the flow.</div>";
      if (err.error && err.error.errors && err.error.errors) {
        Object.keys(err.error.errors).forEach(key => {
          if (isArray(err.error.errors[key])) {
            err.error.errors[key].forEach(errorMessage => {
              errorHtml += `<div>${errorMessage}</div>`
            })
          }
        })
      }
      Swal.fire({
        title: 'Error!',
        html: errorHtml,
        icon: 'error'
      }
      );
    });
  }

  private onSaveClickedOnSaveModalForEdit = (title: string, comment: string) => {
    const step = this._flowService.getCurrentStep();
    let settings = new WizardFlowSettings();
    settings.title = title;
    settings.comment = comment;
    settings.hasProgressBar = this._wizardFlowSettings.hasProgressBar;
    settings.navigationType = this._wizardFlowSettings.navigationType;
    settings.isDefault = this._flowBeingEdited?.isDefault;
    settings.isActive = this._flowBeingEdited?.active;
    settings.navGroups = this._flowService.activeFlowConfig.stepGroups;
    const self = this;
    this._flowService.updateFlowConfig(this.flowType, step, settings)?.subscribe(() => {
      const stepConfig = self._flowConfigService.getConfig(this.flowType, step);
      self.onStepSelected(stepConfig);
      self.inEditMode = false;
      Swal.fire(
        'Success!',
        'Your flow config has been successfully saved.',
        'success'
      );
    }, err => {
      let errorHtml = "<div>An error occurred while saving the flow.</div>";
      if (err.error && err.error.errors && err.error.errors) {
        Object.keys(err.error.errors).forEach(key => {
          if (isArray(err.error.errors[key])) {
            err.error.errors[key].forEach(errorMessage => {
              errorHtml += `<div>${errorMessage}</div>`
            })
          }
        })
      }
      Swal.fire({
        title: 'Error!',
        html: errorHtml,
        icon: 'error'
      }
      );
    });
  }

  private setFlowData = (flowType: FlowType) => {
    this.flowType = flowType;
    this.stepToEdit = undefined;
    this.sourceStep = undefined;
    this.destinationSteps = [];
    if (!this.flowConfigs) {
      return;
    }
    if (this.flowType == FlowType.Apply) {
      this.flow = this.flowConfigs.applicationFlowConfig;
      this.defaultFlow = this._flowConfigService.defaultFlowConfigs.applicationFlowConfig;
      this.flowTypeName = "Apply Flow";
    } else if (flowType == FlowType.Register) {
      this.flow = this.flowConfigs.registrationFlowConfig;
      this.defaultFlow = this._flowConfigService.defaultFlowConfigs.registrationFlowConfig;
      this.flowTypeName = "Register Flow";
    } else {
      this.flow = this.flowConfigs.coApplicationFlowConfig;
      this.defaultFlow = this._flowConfigService.defaultFlowConfigs.coApplicationFlowConfig;
      this.flowTypeName = "Co-Apply Flow";
    }
    if (this.flowSummary) {
      this.flowSummary.flow = this.flow;
    }
  }

  private loadStepPreview = (step: StepConfig) => {
    if (!step) {
      return;
    }
    this._flowService.stepConfigBeingPreviewed = step;
    const stepTypeConfig = this._stepTypeConfigFactory.getStepTypeConfig(step.stepType);
    this.componentBeingPreviewed = stepTypeConfig.component;
    if (this.stepPreview) {
      this.stepPreview.component = this.componentBeingPreviewed;
    }
    const zoomScale = Number(this.previewZoomValue) / 10;
    this._utilsService.setZoom(zoomScale, document.getElementsByClassName('step-preview')[0])
  }

  private configureDestinationSteps = (step: StepConfig) => {
    this.destinationSteps = [];
    if (step.nextStepDeciderConfig) {
      if (step.nextStepDeciderConfig.deciderType == NextStepDeciderType.Simple) {
        const decider = <SimpleNextStepDeciderConfig>step.nextStepDeciderConfig;
        if (decider.gotoStepPath) {
          const step = this.flow.steps.find(s => s.path === decider.gotoStepPath);
          if (step) {
            this.destinationSteps.push(step);
          }
        }
      } else {
        const decider = <ExpressionNextStepDeciderConfig>step.nextStepDeciderConfig;
        if (decider.ifStepExpression) {
          const step = this.flow.steps.find(s => s.path === decider.ifStepExpression.goToPath);
          if (step) {
            this.destinationSteps.push(step);
            this.conditions[step.id] = "IF " + decider.ifStepExpression.ifCondition;
          }
          if (decider.elseIfStepExpressions) {
            decider.elseIfStepExpressions.forEach(e => {
              const elseIfStep = this.flow.steps.find(s => s.path === e.goToPath);
              if (elseIfStep) {
                this.destinationSteps.push(elseIfStep);
                this.conditions[elseIfStep.id] = "ELSE IF " + e.ifCondition;
              }
            });
          }
          const elseStep = this.flow.steps.find(s => s.path === decider.elseStepPath);
          if (elseStep) {
            this.destinationSteps.push(elseStep);
            this.conditions[elseStep.id] = "ELSE";
          }
        }
      }
    }
  }

  private redrawFlowEditor = () => {
    if (this.sourceStep) {
      this.configureDestinationSteps(this.sourceStep);
    }
    if (this.flowSummary) {
      this.flowSummary.redraw(this.flow);
    }
    this.drawConnectors();
    setTimeout(() => {
      KTApp.initBootstrapTooltips();
    });
  }

  private connectSteps = () => {
    window.addEventListener("resize", this.drawConnectors);
    this.drawConnectors();
  }

  private drawConnectors = () => {
    const self = this;
    let index = 0;
    let svg = document.getElementById("flow-editor-svg");
    if (svg) {
      svg.innerHTML = '';
    }
    this.destinationSteps.forEach(s => {
      if (this.sourceStep) {
        this._svgUtils.drawCurvedLine(0, 40, 150, 40 + index * 160, '#666666', 1, s.id);
        $('#' + s.id).on('click', self.onLinkBetweenStepsClicked);
        index++;
      }
    });
    //this._svgUtils.drawCurvedLine(0, 40, 150, 40 + index * 160, '#666666', 1, 'step-placeholder', true);
  };

  private subscribeToExpressionEditorEvents = () => {
    setTimeout(() => {
      if (this.expressionEditor) {
        if (this._expressionEditOkedSubscription) {
          this._expressionEditOkedSubscription.unsubscribe();
        }
        this._expressionEditOkedSubscription = this.expressionEditor.expressionEditingOked.subscribe(e => {
          this.hideRightDrawer();
          this.redrawFlowEditor();
        });
        this._expressionEditOkedSubscription = this.expressionEditor.expressionsChanged.subscribe(e => {
          this.redrawFlowEditor();
        });
      }
    });
  }

  private showRightDrawer = () => {
    var drawerElement = document.querySelector("#kt_drawer_example_basic");
    var drawer = KTDrawer.getInstance(drawerElement);
    if (!drawer) {
      drawer = KTDrawer.createInstance(drawerElement);
    }
    drawer.hide();
    drawer.show();
  }

  private hideRightDrawer = () => {
    var drawerElement = document.querySelector("#kt_drawer_example_basic");
    var drawer = KTDrawer.getInstance(drawerElement);
    if (!drawer) {
      drawer = KTDrawer.createInstance(drawerElement);
    }
    drawer.hide();
  }

  private onLinkBetweenStepsClicked = () => {
    if (this.sourceStep && (this.sourceStep.nextStepDeciderConfig?.deciderType == NextStepDeciderType.Expression)) {
      this._originalStep = _.cloneDeep(this.sourceStep);
      this.action = "edit-expression";
      this.rightDrawerHeader = "Edit Expression Next Step Decider for " + this.sourceStep!.name;
      this.expressionNextStepDeciderConfigToEdit = <ExpressionNextStepDeciderConfig>this.sourceStep!.nextStepDeciderConfig;
      this.showRightDrawer();
      this.subscribeToExpressionEditorEvents();
    }
  }
}

