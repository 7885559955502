import { CategoryOfIncome } from "./enums/category-of-income.enum";
import { TypeOfIncome } from "./enums/type-of-income.enum";
import { v4 as uuidv4 } from 'uuid';

export class Income {
  incomeId!: number;
  monthlyIncome: number | undefined;
  categoryOfIncome: CategoryOfIncome | undefined;
  typeOfIncomeOtherDescription: string = "";
  businessType: string | undefined;
  typeOfIncome: TypeOfIncome | undefined;
  borrowerId: number | undefined;
  companyId: string | undefined;
  modelGuid?: string = "";

  constructor() {
    this.modelGuid = uuidv4();
  }
}


