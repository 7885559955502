import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApplicationTaskModel } from '../../../models/borrower-portal/application-task-model';
import { Observer } from 'rxjs';
import { PortalBaseComponent } from '../../portal-base.component';
import { PortalServiceFactory } from '../../../services/portal-service.factory';
import { NotificationService } from 'projects/shared/services/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'task-answer=question-dialog',
  templateUrl: 'task-answer-question-dialog.component.html'
})
export class TaskAnswerQuestionDialogComponent extends PortalBaseComponent implements OnInit {

  @ViewChild('answerForm')
  answerForm: NgForm | undefined;

  @Input()
  task: ApplicationTaskModel;

  protected answer: string;

  constructor(private readonly portalServiceFactory: PortalServiceFactory,
    public activeModal: NgbActiveModal,
    private readonly _notifsService: NotificationService,
    private readonly _spinner: NgxSpinnerService,) {
      super(portalServiceFactory);
    }

  ngOnInit() { }

  onOkClicked() {
    this.answerForm.form.markAllAsTouched();
    if (!this.answerForm.form.valid) {
      return;
    }

    const observer: Observer<any> = {
      next: (value: any) => {
        this.activeModal.close();
      },
      error: (err: any) => {
        this._notifsService.showError(err && err.error ? err.error.message || err.message : "An error occurred while answering the question.", 'Error!');
      },
      complete: () => {
      }
    }
    this._spinner.show();
    this.portalService.answerTaskQuestion(this.task.taskId, `\"${this.answer}\"`).subscribe(observer)
      .add(() => this._spinner.hide());
  }
}
