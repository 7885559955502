<ng-container *ngFor="let group of docGroups; let i = index">
<div class="accordion" id="kt_accordion_{{i}}">
  <div class="accordion-item">
      <h2 class="accordion-header" id="kt_accordion_header_{{i}}">
          <button class="accordion-button fs-4 fw-semibold collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#kt_accordion_body_' + i" aria-expanded="false">
            {{ group.documentTypeName }}
          </button>
      </h2>
      <div id="kt_accordion_body_{{i}}" class="accordion-collapse collapse"  [attr.data-bs-parent]="'#kt_accordion_' + i">
          <div class="accordion-body">
            <view-document
            [document]="document"
            *ngFor="let document of group.docs"
          ></view-document>
          </div>
      </div>
  </div>
</div>
</ng-container>


<h3 class="text-center my-4" *ngIf="documents.length === 0">
  There are no documents.
</h3>
