export class TurboInsuranceGetQuoteRequest {
  requestID: string | null = null;
  applicantInfo: TurboInsuranceApplicantInfo;
  quotePropertyInfo: TurboInsurancePropertyInfo;
  priorPropertyInfo: TurboInsurancePropertyInfo;
  loanInfo: TurboInsuranceLoanInfo;
  additionalInfo: TurboInsuranceAdditionalInfo;

  constructor() {
    this.applicantInfo = new TurboInsuranceApplicantInfo();
    this.quotePropertyInfo = new TurboInsurancePropertyInfo();
    this.priorPropertyInfo = new TurboInsurancePropertyInfo();
    this.loanInfo = new TurboInsuranceLoanInfo();
    this.additionalInfo = new TurboInsuranceAdditionalInfo();
  }
}

export class TurboInsuranceApplicantInfo {
    firstName: string | null = null;
    lastName: string | null = null;
    dob: string | null = null;
    email: string | null  = null;
    phone: string | null = null;
    maritalStatus: string | null = null;
}

export class TurboInsuranceLoanInfo {
    loanUID: string | null = null;
    lenderLoanNumber: string | null = null;
    loanAmount: string | null = null;
    loanPurpose: string | null = null;
    lenderName: string | null = null;
    officerFirstName: string | null = null;
    officerLastName: string | null = null;
    officerEmail: string | null = null;
    officerPhone: string | null = null;
}

export class TurboInsuranceAdditionalInfo {
    estimatedPropertyValue: number | null = 0;
    propertyType: string | null = null;
    occupancyType: string | null = null;
}

export class TurboInsurancePropertyInfo {
  streetAddress1:string | undefined = undefined;
  streetAddress2:string | undefined = undefined;
  city:string | null = null;
  county:string | null = null;
  state:string | null = null;
  zip:string | null = null;
  monthsAtAddress: number = 0;
}
