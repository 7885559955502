<ng-template #numberOfBorrowers let-stepComponent="stepComponent">
  <form #numberOfBorrowersForm="ngForm" novalidate id="numberOfBorrowersForm" name="numberOfBorrowersForm">
    <div class="fv-row">
      <div class="row mb-10">
        <div class="col">
          <label class="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4"
            [ngClass]="{'active': hasCoBorrowers}">
            <input type="radio" class="btn-check" name="yes" id="yes" (click)="onYesSelected()" />
            <span class="fw-bolder fs-3">Yes</span>
          </label>
        </div>
        <div class="col">
          <label class="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4"
            [ngClass]="{'active': hasCoBorrowers === false}">
            <input type="radio" class="btn-check" id="no" name="no" (click)="onNoSelected()" />
            <span class="fw-bolder fs-3">No</span>
          </label>
        </div>
      </div>
      <div class="row" *ngIf="hasCoBorrowers">
        <div class="col-md-4 fv-row">
          <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
            <span class="required">How many people?</span>
          </label>
          <div class="position-relative">
            <input type="number" [(ngModel)]="numberOfCoBorrowers" (ngModelChange)="onNumberOfCoBorrowersChanged()"
              required min="1"
              [ngClass]="{'is-invalid' : numberOfBorrowersOnLoan && numberOfBorrowersOnLoan.touched && numberOfBorrowersOnLoan.invalid}"
              class="form-control form-control-solid" placeholder="" name="numberOfBorrowers"
              #numberOfBorrowersOnLoan="ngModel" />
            <div class="invalid-feedback">Please enter the number of borrowers.</div>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>

<wizard-step-template [stepMainTemplate]="numberOfBorrowers" [step]="step" [stepComponent]="this">
</wizard-step-template>
