export class PaymentInformation {
  transactionId: string;
  paymentType: PaymentType;
  accountNumber: string;
  billingName: string;
  billingPhoneNumber: string;
  billingEmail: string;
  nullable: true
  billingAddress: string;
  billingAddress2: string;
  billingCity: string;
  billingState: string;
  billingZip: string;
  creditCardType: CreditCardType;
  creditCardExpirationMonth: number;
  creditCardExpirationYear: number;
  driversLicenseNumber: string;
  driversLicenseState: string;
  checkNumber: string;
  checkRoutingNumber: string;
}

export enum PaymentType {
  Bill = "Bill",
  Echeck = "Echeck",
  CreditCard = "CreditCard"
}

export enum CreditCardType {
  AmericanExpress = "AmericanExpress",
  Discover = "Discover",
  MasterCard = "MasterCard",
  Visa = "Visa"
}
