import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PrequalDetailsModel } from '../../models/borrower-portal/prequal-details.model';
import { ApplicationForUser } from '../../models/borrower-portal/application-for-user-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { GeneratePrequalLetterRequestModel } from '../../models/borrower-portal/generate-prequal-letter-request.model';
import { BasePortalService } from '../../services/base-portal.service';
import { Constants } from '../../services/constants';
import { NotificationService } from '../../services/notification.service';
import { MortgageCalculatorDialogComponent } from './mortgage-calculator-dialog/mortgage-calculator-dialog.component';
import { GeneratePrequalLetterDialogComponent } from './generate-prequal-letter-dialog/generate-prequal-letter-dialog.component';
import { AddDocumentsToLoanDialogComponent } from './add-documents-to-loan-dialog/add-documents-to-loan-dialog.component';
import { firstValueFrom } from 'rxjs';
import { PaymentLinkConfig } from '../../models/borrower-portal/payment-link-config.model';

@Component({
  selector: 'loan-actions',
  templateUrl: './loan-actions.component.html',
  styleUrls: ['./loan-actions.component.scss'],
})
export class LoanActionsComponent implements OnInit {

  @Input()
  application: ApplicationForUser;

  @Input()
  actionsApplicable: LoanActions;

  @Output()
  openAssetVerificationClicked: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  openIncomeVerificationClicked: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  additionalDocumentsAddedToLoan: EventEmitter<void> = new EventEmitter<void>();

  prequalDetails: PrequalDetailsModel;
  hasPrequalDetails: boolean = false;

  protected paymentLinkConfig: PaymentLinkConfig = {
    isVisible: false,
    paymentLink: null,
    buttonText: null,
  }

  constructor(
    private readonly _portalService: BasePortalService,
    private readonly _modalService: NgbModal,
    private readonly _spinnerService: NgxSpinnerService,
    private readonly _notifsService: NotificationService
  ) {
    const x = 10;
  }

  async ngOnInit() {
    await this.loadPaymentLinkConfig(this.application.applicationId);
    if (this.actionsApplicable.hasPreApprovalLetter) {
      this.loadPrequalDetail(this.application.applicationId);
    }
  }

  protected openGeneratePrequalLetterDialog = () => {
    const modalRef = this._modalService.open(
      GeneratePrequalLetterDialogComponent,
      Constants.modalOptions.xlarge
    );
    modalRef.componentInstance.details = this.prequalDetails;

    modalRef.result.then((req: GeneratePrequalLetterRequestModel) => {
      this.generatePrequalLetter(req);
    });
  };

  protected openMortgageCalculatorModal() {
    const modal = this._modalService.open(MortgageCalculatorDialogComponent, {
      size: 'lg',
      backdrop: 'static',
    });
    modal.componentInstance.application = this.application;
  }

  protected onAddAdditionalDocumentsClicked() {
    const modalRef = this._modalService.open(
      AddDocumentsToLoanDialogComponent,
      Constants.modalOptions.large
    );
    modalRef.componentInstance.application = this.application;
    modalRef.result.then(
      () => {
        this.additionalDocumentsAddedToLoan.emit();
      },
      (error) => {}
    );
  }

  protected onOpenAssetVerificationClicked = () => {
    this.openAssetVerificationClicked.emit();
  };

  protected onOpenIncomeVerificationClicked = () => {
    this.openIncomeVerificationClicked.emit();
  };

  private generatePrequalLetter = (req: GeneratePrequalLetterRequestModel) => {
    this._spinnerService.show();

    req.loanAmount = req.purchasePrice - req.downPayment;
    this._portalService
      .generatePrequalLetterForApp(this.application.applicationId, req)
      .subscribe({
        next: (res) => {
          this._spinnerService.hide();
          const downloadFileGuid = res.docFileGuid;
          if (downloadFileGuid) {
            this.downloadFile(downloadFileGuid);
          }
        },
        error: (err) => {
          this._spinnerService.hide();
          const errorMessage =
            err?.message ||
            'An error occurred generating prequalification letter.';
          this._notifsService.showError(errorMessage, 'Error');
        },
      });
  };

  private downloadFile = (downloadFileGuid: string) => {
    this._spinnerService.show();
    this._portalService.downloadFile(downloadFileGuid).subscribe({
      next: (res) => {
        this._spinnerService.hide();

        const blob = new Blob([res], { type: 'application/pdf' });
        let downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        let fileName = `PrequalificationLetter-${this.application.applicationId}.pdf`;
        downloadLink.setAttribute('download', fileName);
        document.body.appendChild(downloadLink);
        downloadLink.click();
      },
      error: (err) => {
        console.error(err);
        this._spinnerService.hide();
      },
    });
  };

  private loadPrequalDetail = (appId: number) => {
    this._portalService
      .getPrequalDetailsForAppId(appId)
      .subscribe((details) => {
        this.prequalDetails = details;
        this.hasPrequalDetails =
          details &&
          (details.downPaymentPercent > 0 ||
            details.maxPurchasePrice > 0 ||
            details.interestRate > 0);
      });
  };

  private loadPaymentLinkConfig = async (appId: number) => {
    try {
     this._spinnerService.show();
     this.paymentLinkConfig = await firstValueFrom(
        this._portalService.getPaymentLinkConfig(appId)
      );
    } catch (error) {
      
    } finally {
      this._spinnerService.hide();
    }
  }
}

export class LoanActions {
  hasAssetVerificationTasks: boolean;
  hasIncomeVerificationTasks: boolean;
  isMortgageCalculatorEnabled: boolean;
  isUploadingAdditionalDocsEnabled: boolean;
  hasPreApprovalLetter: boolean;

  constructor() {
    this.hasAssetVerificationTasks = false;
    this.hasIncomeVerificationTasks = false;
    this.isMortgageCalculatorEnabled = false;
    this.hasPreApprovalLetter = false;
  }
}
