import { Injectable } from "@angular/core";
import { WizardFlowContext } from "../../../wizard-flow-context.model";
import { GenericStepFactory } from "./generic-step.factory";
import { NextStepDeciderFactory } from "./next-step-decider.factory";
import { ICreateStep } from "./step-factory.interface";
import { CreateMultipleCoBorrowersStep } from "../../create-multiple-coborrowers-step.model";
import { CreateMultipleCoBorrowersStepConfig } from "../create-multiple-coborrowers-step-config.model";

@Injectable()
export class CreateMultipleCoBorrowersStepFactory extends GenericStepFactory<CreateMultipleCoBorrowersStep> implements ICreateStep {

  constructor(nextStepDeciderFactory: NextStepDeciderFactory) {
    super(nextStepDeciderFactory, CreateMultipleCoBorrowersStep);
  }

  create(config: CreateMultipleCoBorrowersStepConfig, wizardFlowContext: WizardFlowContext): CreateMultipleCoBorrowersStep {
    let createMultiCoBorrowersCreditsStep = super.create(config, wizardFlowContext)
    createMultiCoBorrowersCreditsStep.restrictMiddleNameToSingleCharacter =
      (config.restrictMiddleNameToSingleCharacter != null ? config.restrictMiddleNameToSingleCharacter : false);
    return createMultiCoBorrowersCreditsStep;
  }

  configure(step: CreateMultipleCoBorrowersStep, config: CreateMultipleCoBorrowersStepConfig): void {
    super.configure(step, config);
    config.restrictMiddleNameToSingleCharacter = step.restrictMiddleNameToSingleCharacter;
  }
}
