import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Liability, PayoffType } from 'projects/borrower-app/src/app/models/mortgage.model';

@Component({
  selector: 'liability-card',
  templateUrl: 'liability-card.component.html',
  styleUrls: ['liability-card.component.scss']
})

export class LiabilityCardComponent implements OnInit {

  @Output() liabilityChanged = new EventEmitter<Liability>();

  @Input()
  set liability(liability: Liability) {
    if (liability) {
      this._liability = liability;
      if (this.liability.payoffType === 'Full' ||
        this.liability.partialPayoffAmount === this.liability.unpaidBalance) {
        this._liability['willPayOff'] = true;
      } else {
        this._liability['willPayOff'] = false;
      }
    }
  }

  get liability(): Liability {
    return this._liability;
  }

  private _liability: Liability;

  constructor() { }

  ngOnInit() { }

  onPayoffSelectionChanged = () => {
    if (this.liability['willPayOff']) {
      this.liability.payoffType = PayoffType.Full;
      this.liability.partialPayoffAmount = this.liability.unpaidBalance;
    } else {
      this.liability.payoffType = PayoffType.None;
      this.liability.partialPayoffAmount = undefined;
    }
    this.liabilityChanged.emit(this.liability);
  }
}

