import {ApplicationTaskModel} from "./application-task-model";

export class ApplicationTasksModel {

  requested: ApplicationTaskModel[] = [];
  submitted: ApplicationTaskModel[] = [];
  approved: ApplicationTaskModel[] = [];

  constructor() { }
}
