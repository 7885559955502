<div class="modal-header">

  <h2>Reset Password</h2>

  <!-- hack to fool ngbModal autofocus -->
  <input type="text" style="display:none" />

  <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" aria-label="Close" *ngIf="!spinnerVisible"
    (click)="activeModal.dismiss('Cross click')">
    <span class="svg-icon svg-icon-2x">
      <close-x-svg-icon></close-x-svg-icon>
    </span>
  </div>
</div>

<div class="modal-body py-5 px-lg-17">
  <div class="alert alert-info bg-light-success d-flex align-items-center p-5 mb-10">
    <span class="svg-icon svg-icon-2hx svg-icon-info me-3">
      <duotone-shield-check-svg-icon></duotone-shield-check-svg-icon>
    </span>
    <div class="d-flex flex-column" *ngIf="userName">
      <span class="mb-3">We sent you a code to your email address <strong>{{userName}}</strong>.</span>
      <span>Please enter that code and your new password below.</span>
    </div>
  </div>
  <div *ngIf="errorMessage || (passwordErrors && passwordErrors.length)" class="alert alert-danger d-flex align-items-center p-5 mb-10">
    <span class="svg-icon svg-icon-2hx svg-icon-danger me-3">
      <duotone-warning-circle-svg-icon></duotone-warning-circle-svg-icon>
    </span>
    <div class="d-flex flex-column">
      <span>{{errorMessage}}</span>
      <div *ngFor="let passwordError of passwordErrors">
        {{passwordError}}
      </div>
    </div>
  </div>
  <form #resetPasswordForm="ngForm" class="form" novalidate id="resetPasswordForm" name="resetPasswordForm">
    <div class="row">
      <div class="fv-row mb-5">
        <label class="form-label fs-6 fw-bolder text-dark">Security Code</label>
        <input class="form-control form-control-lg form-control-solid" required
          [ngClass]="{'is-invalid' : code && code.touched && code.invalid}" type="text" name="securityCode"
          [(ngModel)]="securityCode" #code="ngModel" autocomplete="off" />
        <div class="invalid-feedback">Please enter the security code.</div>
      </div>
      <div class="fv-row mb-5" *ngIf="userName">
        <label class="form-label fs-6 fw-bolder text-dark">Company</label>
        <select class="form-control form-control-lg form-control-solid" required
          [ngClass]="{'is-invalid' : company && company.touched && company.invalid}" name="company"
          [(ngModel)]="userCompanyGuid" #company="ngModel" autocomplete="off">
          <option value="">--Select One-</option>
          <option *ngFor="let company of availableCompanies" value="{{company.userCompanyGuid}}">{{company.companyName}}</option>
        </select>
        <div class="invalid-feedback">Please select a company.</div>
      </div>
      <div class="fv-row mb-5">
        <label class="form-label fw-bolder text-dark fs-6">Password</label>
        <input class="form-control form-control-lg form-control-solid" type="password" name="password" required
          reverse="true" validateEqual="confirmedPassword"
          [ngClass]="{'is-invalid' : newPassword && newPassword.touched && newPassword.invalid}" autocomplete="off"
          [(ngModel)]="password" #newPassword="ngModel" />
        <div class="invalid-feedback" *ngIf="newPassword.errors && newPassword.errors.required">
          Please enter your new password.</div>
        <div class="invalid-feedback" *ngIf="newPassword.errors && newPassword.errors.validateEqual"
          class="invalid-feedback">
          Password & Confirm Password do not match.</div>
      </div>
      <div class="fv-row mb-5">
        <label class="form-label fw-bolder text-dark fs-6">Confirm Password</label>
        <input class="form-control form-control-lg form-control-solid" type="password" name="confirmedPassword" required
          validateEqual="password"
          [ngClass]="{'is-invalid' : passwordToConfirm && passwordToConfirm.touched && passwordToConfirm.invalid}"
          autocomplete="off" [(ngModel)]="confirmedPassword" #passwordToConfirm="ngModel" />
        <div class="invalid-feedback" *ngIf="passwordToConfirm.errors && passwordToConfirm.errors.required">
          Please enter your password again.</div>
        <div class="invalid-feedback" *ngIf="passwordToConfirm.errors && passwordToConfirm.errors.validateEqual"
          class="invalid-feedback">
          Password & Confirm Password do not match.</div>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer flex-center">
  <button *ngIf="!spinnerVisible" type="button" class="btn btn-white me-3" (click)="activeModal.dismiss()">Cancel</button>
  <button type="submit" class="btn btn-primary" (click)="onResendCodeClicked()" [disabled]="spinnerVisible">
    <span class="indicator-label">{{resendCodeButtonText}}</span>
    <span *ngIf="spinnerVisible" class="spinner-border spinner-border-sm align-middle ms-2"></span>
  </button>
  <button type="submit" class="btn btn-primary" (click)="onResetPasswordClicked()" [disabled]="spinnerVisible">
    <span class="indicator-label">{{resetPasswordButtonText}}</span>
    <span *ngIf="spinnerVisible" class="spinner-border spinner-border-sm align-middle ms-2"></span>
  </button>
</div>
