import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'laptop-svg-icon',
  template: `<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <!-- Generator: Sketch 50.2 (55047) - http://www.bohemiancoding.com/sketch -->
              <title>Stockholm-icons / Devices / Laptop</title>
              <desc>Created with Sketch.</desc>
              <defs></defs>
              <g id="Stockholm-icons-/-Devices-/-Laptop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect id="bound" x="0" y="0" width="24" height="24"></rect>
                  <path d="M6,8 L6,16 L18,16 L18,8 L6,8 Z M20,16 L21.381966,16 C21.7607381,16 22.1070012,16.2140024 22.2763932,16.5527864 L22.5,17 C22.6706654,17.3413307 22.5323138,17.7563856 22.190983,17.927051 C22.0950363,17.9750244 21.9892377,18 21.881966,18 L2.11803399,18 C1.73641461,18 1.42705098,17.6906364 1.42705098,17.309017 C1.42705098,17.2017453 1.45202663,17.0959467 1.5,17 L1.7236068,16.5527864 C1.89299881,16.2140024 2.23926193,16 2.61803399,16 L4,16 L4,8 C4,6.8954305 4.8954305,6 6,6 L18,6 C19.1045695,6 20,6.8954305 20,8 L20,16 Z" id="Combined-Shape" fill="#000000" fill-rule="nonzero"></path>
                  <polygon id="Combined-Shape" fill="#000000" opacity="0.3" points="6 8 6 16 18 16 18 8"></polygon>
              </g>
            </svg>`
})

export class LaptopSvgIconComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
