<ng-template #question>
  <!-- <h3>{{step.question.text}}</h3> -->
  <ng-container *ngIf="inEditMode">
    <div class="fv-row mb-3">
      <label class="fs-6 fw-bold form-label mb-2">
        <span>Options Come from:</span>
      </label>
      <div class="position-relative">
        <div class="col-6">
          <select name="optionsComeFrom" [(ngModel)]="step.optionsProviderType"
            (ngModelChange)="onOptionProviderChanged()" class="form-select form-select-solid" data-control="select2"
            data-hide-search="true" data-placeholder="Select One" #propertyState="ngModel">
            <option [ngValue]="null">--Select One--</option>
            <option *ngFor="let optionProvider of optionProviders; let i = index" value="{{optionProvider.value}}">
              {{optionProvider.name}}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div *ngIf="availableOptions.length" class="fv-row">
      <div class="checkbox mb-3" *ngFor="let availableOption of availableOptions">
        <label class="form-check form-check-inline form-check-solid me-2"
          for="availableOption_{{availableOption.name}}">
          <input id="availableOption_{{availableOption.name}}" name="availableOption_{{availableOption.name}}"
            type="checkbox" (change)="optionsSelectedToPresentToUserChanged(availableOption, $event)"
            [checked]="valuesOfOptionsSelectedToBePresentedToUserAsChoices.includes(availableOption.name.toLowerCase())"
            class="form-check-input">
          <span class="fw-bold ps-2 fs-5">{{availableOption.name}}</span>
        </label>
      </div>
    </div>

    <div class="fv-row mb-2">
      <hr>
      <div class="checkbox mb-3">
        <label class="form-check form-check-inline form-check-solid me-2" for="setDefaultValue">
          <div class="d-flex">
            <div style="margin-top: 9px; margin-right: 10px;">
              <input id="setDefaultValue" name="setDefaultValue" type="checkbox" class="form-check-input"
              [(ngModel)]="setDefaultAndSkipToNextChecked"
              (ngModelChange)="onSetDefaultValueCheckChanged()">
              <span class="fw-bold ps-2 fs-5">Sets Default Value & Skips To Next Step</span>
            </div>

            <div *ngIf="setDefaultAndSkipToNextChecked">
              <select name="optionsComeFrom" [(ngModel)]="step.nameOfDefaultOptionToSetAndSkip"
                class="form-select form-select-solid" data-control="select2"
                data-hide-search="true" data-placeholder="Select One" #propertyState="ngModel">
                <option [ngValue]="null">--Select One--</option>
                <option *ngFor="let option of availableOptions; let i = index" value="{{option.name}}">
                  {{option.name}}
                </option>
              </select>
            </div>
          </div>

        </label>
      </div>
    </div>

  </ng-container>

  <ng-container *ngIf="step.question">
    <div class="col col-md mb-4 overlay overlay-block" *ngFor="let option of step.question.options; let i = index">
      <answer-option [inEditMode]="inEditMode" #answerOption [option]="option">
      </answer-option>
    </div>
  </ng-container>
</ng-template>

<wizard-step-template [stepMainTemplate]="question" [step]="step" [stepComponent]="this"></wizard-step-template>
