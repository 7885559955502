import { BorrowerCharacteristic } from "../../models/characteristics/borrower-characteristic.model";
import { ICharacteristic } from "../../models/characteristics/characteristic.interface";
import { ICharacteristicFactory } from "./characteristic-factory.interface";

export class BorrowerCharacteristicFactory implements ICharacteristicFactory {

  create(id: number, applicationId: number, characteristicId: number, ownerId?: number): ICharacteristic {
    return new BorrowerCharacteristic(id, applicationId, characteristicId, ownerId);
  }
}
