<div class="modal-header">
  <h2 class="modal-title">{{title}}</h2>
  <button type="button" class="btn-close" (click)="activeModal.close('cancel')" aria-label="Close"></button>
</div>
<div class="modal-body">
    <div class="d-flex mb-2">
      <div *ngIf="taskCategory">
        <h4 *ngIf="taskCategory.fields && taskCategory.fields.length > 0">Please provide the following details for each
          '{{taskCategory.taskCategoryName}}': </h4>
        <h4 *ngIf="!taskCategory.fields || taskCategory.fields.length == 0">No details required for
          '{{taskCategory.taskCategoryName}}': </h4>
      </div>
      <div class="add-button">
          <a (click)="onAddClicked()" class="btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary">Add
            '{{taskCategory?.taskCategoryName}}'</a>
      </div>
    </div>

    <div class="card" *ngFor="let characteristic of characteristics; let i = index">
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col">
            <h4 class="card-title">{{taskCategory?.taskCategoryName}} {{i + 1}}</h4>
          </div>
          <div class="col-auto">
            <div class="align-self-center">
              <a class="btn btn-sm btn-outline btn-outline-dashed btn-outline-danger btn-active-light-danger"
                (click)="characteristic.pendingDeletion = !characteristic.pendingDeletion">
                {{characteristic.pendingDeletion ? 'Undo' : 'Remove'}}
                  <i *ngIf="!characteristic.pendingDeletion" class="fas fa-trash-alt text-danger"></i>
                  <i *ngIf="characteristic.pendingDeletion" class="fa fa-undo text-danger"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="card-body">

          <div class="row" *ngFor="let field of taskCategory?.fields; let j = index;">
            <div class="general-label">
              <form>
                  <div class="mb-3 row" [ngSwitch]="field.fieldType">
                      <label for="field_{{field.characteristicFieldId}}" class="col-sm-2 form-label align-self-center mb-lg-0">{{field.displayName}}</label>
                      <div class="col-sm-10">
                        <!-- Unspecified, Text, CheckBox, RadioButton, Email, Phone, Currency, Percent -->
                        <input *ngSwitchCase="'Unspecified'"
                          class="form-control"
                          type="text"
                          name="field_{{field.characteristicFieldId}}"
                          [(ngModel)]="characteristic.characteristicMergeValues[j].mergeValue"
                          [disabled]="characteristic.pendingDeletion"
                          required/>
                        <input *ngSwitchCase="'Text'"
                          class="form-control"
                          type="text"
                          name="field_{{field.characteristicFieldId}}"
                          [(ngModel)]="characteristic.characteristicMergeValues[j].mergeValue"
                          [disabled]="characteristic.pendingDeletion"
                          required/>
                        <input *ngSwitchCase="'CheckBox'"
                          class="form-control"
                          type="checkbox"
                          name="field_{{field.characteristicFieldId}}"
                          [checked]="characteristic.characteristicMergeValues[j].mergeValue === 'true'"
                          [(ngModel)]="characteristic.characteristicMergeValues[j].mergeValue"
                          [disabled]="characteristic.pendingDeletion"
                          required/>
                        <input *ngSwitchCase="'RadioButton'"
                          class="form-control"
                          type="radio"
                          name="field_{{field.characteristicFieldId}}"
                          [checked]="characteristic.characteristicMergeValues[j].mergeValue === 'true'"
                          [(ngModel)]="characteristic.characteristicMergeValues[j].mergeValue"
                          [disabled]="characteristic.pendingDeletion"
                          required/>
                        <input *ngSwitchCase="'Email'"
                          class="form-control"
                          type="email"
                          name="field_{{field.characteristicFieldId}}"
                          [(ngModel)]="characteristic.characteristicMergeValues[j].mergeValue"
                          [disabled]="characteristic.pendingDeletion"
                          required/>
                        <input *ngSwitchCase="'Phone'"
                          class="form-control"
                          type="text"
                          name="field_{{field.characteristicFieldId}}"
                          [mask]="'(000) 000-0000'"
                          placeholder="(###) ###-####"
                          [showMaskTyped]="true"
                          [(ngModel)]="characteristic.characteristicMergeValues[j].mergeValue"
                          [disabled]="characteristic.pendingDeletion"
                          required/>
                        <currency-input *ngSwitchCase="'Currency'"
                          [name]="'field_' + field.characteristicFieldId"
                          #propertyValue="ngModel"
                          [(ngModel)]="characteristic.characteristicMergeValues[j].mergeValue"
                          [disabled]="characteristic.pendingDeletion"
                          [required]="true">
                        </currency-input>
                        <percent-input *ngSwitchCase="'Percent'"
                          [name]="'field_' + field.characteristicFieldId"
                          #propertyValue="ngModel"
                          [(ngModel)]="characteristic.characteristicMergeValues[j].mergeValue"
                          [disabled]="characteristic.pendingDeletion"
                          [required]="true">
                        </percent-input>
                      </div>
                  </div>
              </form>
          </div>
        </div>
      </div>
    </div>
</div>
<div class="modal-footer form-footer">
  <div class="row">
    <div class="col-xs-6 text-left">
      <div class="button-items" style="float: right;">
        <button type="button" class="btn btn-soft-secondary" (click)="activeModal.close('cancel')">Cancel</button>
        <button type="button" (click)="onUpdateClicked()" class="btn btn-primary">Update</button>
      </div>
    </div>
  </div>
</div>
