import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'flow-step-placeholder',
  templateUrl: 'flow-step-placeholder.component.html'
})

export class FlowStepPlaceholderComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
