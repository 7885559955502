import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { MentionModule } from 'angular-mentions';
import { UserAccountTemplateComponent } from '../borrower-app/src/app/user-account-template/user-account-template.component';
import { AddressAutocompleteComponent } from './address-autocomplete/address-autocomplete.component';
import { AlertComponent } from './alert/alert.component';
import { CheckboxFlagsComponent } from './checkbox-flags/checkbox-flags.component';
import { DatePickerDirective } from './date-picker/date-picker.directive';
import { NumericDirective } from './directives/numeric.directive';
import { InputTrimDirective } from './directives/trim-input.directive';
import { DrawerComponent } from './drawer/drawer.component';
import { DynamicallyRenderedContentComponent } from './dynamically-rendered-component/dynamically-rendered.component';
import { EqualValidator } from './equal-validator.directive';
import { LoanImageComponent } from './loan-image/loan-image.component';
import { LogoComponent } from './logo/logo.component';
import { MessageEditorWithMentionsComponent } from './message-editor-with-mentions/message-editor-with-mentions.component';
import { MultiCheckboxPickerComponent } from './multi-checkbox-picker/multi-checkbox-picker.component';
import { MultiCheckboxComponent } from './multi-checkbox/multi-checkbox.component';
import { PersonCircleImageComponent } from './person-circle-image/person-circle-image.component';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { PhonePipe } from './pipes/phone.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { SafeHtml } from './pipes/shared-html.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { ProgressIndicatorComponent } from './progress-indicator/progress-indicator.component';
import { RemoveWrapperDirective } from './remove-wrapper.directive';
import { AlertService } from './services/alert.service';
import { SvgIconsModule } from './svg-icons.module';
import { MonthsToYearsAndMonthsPipe } from './pipes/months-to-years-and-months.pipe';
import { CurrencyInputComponent } from './currency-input/currency-input.component';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { LoadingIndicatorComponent } from 'projects/borrower-app/src/app/wizard/loading-indicator/loading-indicator.component';
import { CaseSizeValidatorDirective, RequireDigitValidatorDirective, RequireNonAlphaNumericValidatorDirective } from './directives/validators';
import { HtmlViewerDialogComponent } from './html-viewer-dialog/html-viewer-dialog.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { DndDirective } from "./file-upload/directives/dnd.directive";
import { FileListComponent } from "./file-upload/file-list/file-list.component";
import { ProgressComponent } from "./file-upload/progress/progress.component";
const Validators = [RequireDigitValidatorDirective, CaseSizeValidatorDirective, RequireNonAlphaNumericValidatorDirective];

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    SvgIconsModule,
    CKEditorModule,
    MentionModule,
    GooglePlaceModule
  ],
  declarations: [
    AddressAutocompleteComponent,
    DatePickerDirective,
    RemoveWrapperDirective,
    CheckboxFlagsComponent,
    MultiCheckboxPickerComponent,
    DynamicallyRenderedContentComponent,
    EqualValidator,
    UserAccountTemplateComponent,
    AlertComponent,
    LogoComponent,
    SafeHtml,
    MultiCheckboxComponent,
    InputTrimDirective,
    NumericDirective,
    PersonCircleImageComponent,
    DrawerComponent,
    PhonePipe,
    LoanImageComponent,
    MessageEditorWithMentionsComponent,
    ProgressIndicatorComponent,
    FilterPipe,
    MonthsToYearsAndMonthsPipe,
    SortPipe,
    DateAgoPipe,
    SafeUrlPipe,
    CurrencyInputComponent,
    LoadingIndicatorComponent,
    HtmlViewerDialogComponent,
    FileUploadComponent,
    DndDirective,
    ProgressComponent,
    FileListComponent,
    ...Validators
  ],
  exports: [
    GooglePlaceModule,
    AddressAutocompleteComponent,
    DatePickerDirective,
    RemoveWrapperDirective,
    CheckboxFlagsComponent,
    MultiCheckboxPickerComponent,
    DynamicallyRenderedContentComponent,
    EqualValidator,
    UserAccountTemplateComponent,
    AlertComponent,
    LogoComponent,
    SafeHtml,
    MultiCheckboxComponent,
    InputTrimDirective,
    NumericDirective,
    PersonCircleImageComponent,
    DrawerComponent,
    PhonePipe,
    LoanImageComponent,
    MessageEditorWithMentionsComponent,
    CKEditorModule,
    MentionModule,
    LoanImageComponent,
    ProgressIndicatorComponent,
    FilterPipe,
    MonthsToYearsAndMonthsPipe,
    SortPipe,
    DateAgoPipe,
    SafeUrlPipe,
    CurrencyInputComponent,
    SvgIconsModule,
    LoadingIndicatorComponent,
    HtmlViewerDialogComponent,
    FileUploadComponent,
    DndDirective,
    ProgressComponent,
    FileListComponent,
    ...Validators
  ],
  providers: [AlertService, CurrencyPipe],
})
export class SharedModule {
  constructor() { }
}
