<ng-template #loanCharacteristics>
  <div class="text-center" *ngIf="isLoading">
    <i class="fas fa-circle-notch fa-4x fa-spin"></i>
  </div>
  <div *ngIf="!isLoading && categories.length" class="row mb-4">
    <div class="checkbox mb-3" *ngFor="let category of categories">
      <ng-container *ngIf="category.onlineAppEnabled">
        <label class="form-check form-check-inline form-check-solid me-2" for="char_{{category.taskCategoryId}}">
          <input id="char_{{category.taskCategoryId}}" name="char_{{category.taskCategoryId}}" type="checkbox"
            (change)="onCharacteristicCheckChanged(category.taskCategoryId)"
            [checked]="isCharacteristicCheckedForLoan(category.taskCategoryId)" class="form-check-input">
          <span class="fw-bold ps-2 fs-5">{{category.questionText}}</span>
        </label>
        <span *ngIf="category.taskOption === 'RequestQuantity'">
          <a class="text-primary" (click)="openEditCharacteristicDialog(category.taskCategoryId, false)"> {{'(' +
            getCharacteristicQuantity(category.taskCategoryId) + ')'}}</a>
        </span>
      </ng-container>
    </div>
  </div>
</ng-template>

<wizard-step-template [stepMainTemplate]="loanCharacteristics" [step]="step" [stepComponent]="this">
</wizard-step-template>
