
import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Observable } from 'rxjs';
import { TurboInsuranceGetQuoteRequest } from '../models/turbo-insurance/get-quote-request.model';
import { TurboInsuranceOrder } from '../models/turbo-insurance/turbo-insurance-order.model';
import { TurboInsuranceChooseQuoteRequest } from '../models/turbo-insurance/turbo-insurance-choose-quote-request.model';

@Injectable()
export class TurboInsuranceService {

  constructor(private readonly _dataService: DataService) {
  }

  public createQuoteRequest = (applicationId: number): Observable<TurboInsuranceGetQuoteRequest> => {
    const url = `api/turbo-insurance/create-quote-request/${applicationId}`;
    return this._dataService.get(url);
  }

  public saveQuoteRequest = (applicationId: number, quoteRequest: TurboInsuranceGetQuoteRequest): Observable<TurboInsuranceGetQuoteRequest> => {
    const url = `api/turbo-insurance/save-quote-request/${applicationId}`;
    return this._dataService.post(url, quoteRequest);
  }

  public getQuotes = (applicationId: number): Observable<TurboInsuranceOrder> => {
    const url = `api/turbo-insurance/get-quote-with-wait/${applicationId}`;
    return this._dataService.post(url, {});
  }

  public getQuotesForTask = (loanDocTaskId: number, withWebhook: boolean = true): Observable<TurboInsuranceOrder> => {
    const url = `api/turbo-insurance/get-quotes-for-task/${loanDocTaskId}?withWebhook= ${withWebhook}`;
    return this._dataService.post(url, {});
  }

  public chooseQuote = (applicationId: number, quoteId?: string, loanDocTaskId?: number): Observable<TurboInsuranceChooseQuoteRequest> => {
    let queryParams = [];

    if (quoteId) {
      queryParams.push("quoteId=" + quoteId);
    }

    if (loanDocTaskId) {
      queryParams.push("loanDocTaskId=" + loanDocTaskId);
    }
    const url = `api/turbo-insurance/choose-quote/${applicationId}${queryParams.length > 0 ? '?' + queryParams.join("&") : ''}`;
    return this._dataService.post(url, {});
  }
}
