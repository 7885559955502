export enum FeeSectionEnum {
  Origination = "Origination",
  ServicesNoShop = "ServicesNoShop",
  Services = "Services",
  GovernmentTaxesAndFees = "GovernmentTaxesAndFees",
  Prepaids = "Prepaids",
  Escrow = "Escrow",
  Other = "Other",
  RealEstateCommission = "RealEstateCommission" // non-existing api sections
}
