import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'stockholm-check-svg-icon',
  templateUrl: 'stockholm-check-svg-icon.component.html'
})

export class StockholmCheckSvgIconComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
