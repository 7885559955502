import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ResetPasswordRequest } from '../../models/auth/reset-password-request.model';
import { AuthService } from '../../services/auth.service';
import { NavigationService } from '../../services/navigation.service';
import { UtilsService } from '../../services/utils.service';
import { AuthenticationUserCompany } from '../../models/auth/auth-response.model';
import { EnvironmentService } from 'projects/shared/services/environment.service';
import { ActivatedRoute } from '@angular/router';
import { debounce } from 'lodash';
declare const Swal: any;

@Component({
  selector: 'reset-password',
  templateUrl: 'reset-password.component.html',
  styleUrls: ['reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  @ViewChild('resetPasswordForm') resetPasswordForm: NgForm | undefined;

  possibleLogoUrls: string[] = [];
  availableCompanies: AuthenticationUserCompany[] = [];

  errorMessage: string | null = null;

  password: string = "";
  confirmedPassword: string = "";

  resetPasswordButtonText: string = "Reset Password";

  spinnerVisible: boolean = false;

  userName: string = "";
  userCompanyGuid: string = "";
  userScope: string = null;

  private _token: string = "";

  constructor(
    private readonly _navigationService: NavigationService,
    private readonly _authService: AuthService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _utilsService: UtilsService,
    private readonly _environment: EnvironmentService,
    private readonly _activatedRoute: ActivatedRoute,
  ) {
    this.possibleLogoUrls = this._navigationService.possibleLogoUrls;
  }

  ngOnInit() {
    this._token = this._utilsService.getUrlParameter("token");
    this.userName = this._utilsService.getUrlParameter("userName");
    this.userCompanyGuid = this._utilsService.getUrlParameter("userCompanyGuid");

    let userScopeFromPath = null;
    if (this._activatedRoute.routeConfig && this._activatedRoute.routeConfig.data && this._activatedRoute.routeConfig.data.scope) {
      userScopeFromPath = this._activatedRoute.routeConfig.data.scope;
    }
    this.userScope = this._environment.scope || userScopeFromPath;

    if (this.userName && !this.userCompanyGuid) {
      this.getAvailableCompanies();
    }
  }

  onResetPasswordClicked = () => {
    this.resetPasswordForm?.form.markAllAsTouched();
    if (this.resetPasswordForm?.form.valid) {
      this.toggleProgress(true);
      const request = new ResetPasswordRequest(this.userName, this.password, this.confirmedPassword, this._token, this.userCompanyGuid);
      this._authService.resetPassword(request).subscribe(result => {
        Swal.fire(
          'Success!',
          'Your password has been successfully reset.',
          'success'
        ).then(() => {
          this._navigationService.navigateToPath("apply", true);
        });
      }, error => {
        this.errorMessage = "It seems that this link has expired. Please, re-send the password reset request again.";
      }).add(() => {
        this.toggleProgress(false);
      });
    }
  }

  onEmailChangedDebounce = debounce(this.onEmailChanged, 300);

  private onEmailChanged() {
    if (!this.userName) {
      this.userCompanyGuid = null;
      return;
    }

    this.getAvailableCompanies();
  }

  private toggleProgress = (value: boolean) => {
    if (value) {
      this._spinner.show();
    } else {
      this._spinner.hide();
    }
    this.spinnerVisible = value;
    if (value) {
      this.resetPasswordButtonText = "Please wait...";
    } else {
      this.resetPasswordButtonText = "Reset Password";
    }
  }

  private getAvailableCompanies = () => {
    this._spinner.show();
    this._authService.getAvailableCompanies(this.userName, this.userScope || 'AgentAndBorrower').subscribe({
      next: (response) => {
        this.availableCompanies = response || [];

        if (this.availableCompanies.length === 1) {
          this.userCompanyGuid = this.availableCompanies[0].userCompanyGuid;
        }
      },
      error: (error) => {
        this.errorMessage = 'Unable to get companies for a provided email';
      }
    }).add(() => {
      this._spinner.hide();
    });
  }
}
