import { Injectable } from "@angular/core";
import { UtilsService } from "projects/borrower-app/src/app/services/utils.service";
import { IDecideNextStep } from "projects/borrower-app/src/app/services/wizard/next-step-decider/next-step-decider.interface";
import { WizardFlowContext } from "../../../wizard-flow-context.model";
import { ExpressionNextStepDeciderConfig } from "../expression-next-step-decider-config.model";
import { NextStepDeciderConfig } from "../next-step-decider-config.model";
import { NextStepDeciderType } from "../next-step-decider-type.enum";
import { SimpleNextStepDeciderConfig } from "../simple-next-step-decider-config.model";
import { ExpressionNextStepDeciderFactory } from "./expression-next-step-decider.factory";
import { SimpleNextStepDeciderFactory } from "./simple-next-step.decider.factory";

@Injectable()
export class NextStepDeciderFactory {
  constructor(private readonly _simpleNextStepDeciderFactory: SimpleNextStepDeciderFactory,
    private readonly _expressionNextStepDeciderFactory: ExpressionNextStepDeciderFactory,
    private readonly _utilsService: UtilsService) {
  }

  create = (config: NextStepDeciderConfig | undefined, wizardFlowContext: WizardFlowContext): IDecideNextStep | undefined => {
    if (!config) {
      return undefined;
    }
    let nextStepDecider: IDecideNextStep | undefined = undefined;
    if (config.deciderType == NextStepDeciderType.Simple) {
      nextStepDecider = this._simpleNextStepDeciderFactory.create(<SimpleNextStepDeciderConfig>config);
    } else {
      nextStepDecider = this._expressionNextStepDeciderFactory.create(<ExpressionNextStepDeciderConfig>config, wizardFlowContext);
    }
    return nextStepDecider;
  }
}
