import { Injectable } from "@angular/core";
import { WizardFlowContext } from "../../../wizard-flow-context.model";
import { GenericStepFactory } from "./generic-step.factory";
import { NextStepDeciderFactory } from "./next-step-decider.factory";
import { ICreateStep } from "./step-factory.interface";
import { DigitalAssetsStep } from "../../digital-assets-step.model";
import { DigitalAssetsStepConfig } from "../digital-assets-step-config.model";

@Injectable()
export class DigitalAssetsStepFactory extends GenericStepFactory<DigitalAssetsStep> implements ICreateStep {

  constructor(nextStepDeciderFactory: NextStepDeciderFactory) {
    super(nextStepDeciderFactory, DigitalAssetsStep);
  }

  create(config: DigitalAssetsStepConfig, wizardFlowContext: WizardFlowContext): DigitalAssetsStep {
    let digitalAssetsStep = super.create(config, wizardFlowContext)
    digitalAssetsStep.vendorToUse = config.vendorToUse != null
      ? config.vendorToUse : 'FormFree';
    return digitalAssetsStep;
  }

  configure(step: DigitalAssetsStep, config: DigitalAssetsStepConfig): void {
    super.configure(step, config);
    config.vendorToUse = step.vendorToUse;
  }
}
