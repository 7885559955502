import { IDecideNextStep } from "../../services/wizard/next-step-decider/next-step-decider.interface";
import { PurchaseCreditsStepComponent } from "../../wizard/wizard-steps/purchase-credits-step/purchase-credits-step.component";
import { WizardStepBase } from "./wizard-step-base.model";

export class PurchaseCreditsStep extends WizardStepBase {

  underContractActionLabel: string = "Under Contract";
  lookingToGetPreApprovedActionLabel: string = "Looking to Get Pre-approved";
  subjectPropertyAddressTbdLabel: string = "TBD";

  restrictStatesToLicenseStates: boolean = false;
  requireUserToEnterZipcode: boolean = false;

  autoSelectLookingToGetPreApproved: boolean = false;
  autoSelectTbdForSubjectPropertyAddress: boolean = false;

  constructor(id: string, name: string, path: string, nextStepDecider?: IDecideNextStep) {
    super(id, name, path, nextStepDecider);
    this.component = PurchaseCreditsStepComponent;
  }
}
