<div class="rounded border p-5 mb-6">
  <div class="w-100 d-flex align-items-center justify-content-end">
    <a href="javascript:void(0);" class="text-primary fw-bold fs-7 mt-n2"
      (click)="showBorrowerDetails = !showBorrowerDetails; rotateStateChanged(); onBorrowerSummaryToggled()">
      {{showBorrowerDetails ? 'Hide' : 'Show'}} Details
      <span class="svg-icon svg-icon-3 ms-1">
        <i class="fa fa-angle-right text-primary" [@rotatedState]='state' ></i>
      </span>
    </a>
  </div>
  <borrower-summary [mortgage]="mortgage" [borrower]="borrower" (borrowerInfoClicked)="onBorrowerSummaryToggled()"
    [purchasePrice]="purchasePrice" #borrowerSummary class="flex-fill"
    id="borrowerSummary">
  </borrower-summary>
  <div *ngIf="showBorrowerDetails" class="fs-6 pt-5">
    <borrower-details [borrower]="borrower" (borrowerChanged)="onBorrowerChanged($event)"></borrower-details>
  </div>
</div>
