import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { CreateBorrowerAccountMortgageRequest } from '../models/create-borrower-account-mortgage-request.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { MortgageApplicationService } from '../services/mortgage-application.service';
import { NavigationService } from '../services/navigation.service';
import { UtilsService } from '../services/utils.service';
import { Constants } from '../services/constants';
import { getErrorMessageOrDefault } from 'projects/shared/utils/error.utils';

@Component({
	selector: 'create-account-ext-auth',
	templateUrl: 'create-account-external-auth.component.html',
	styleUrls: ['create-account-external-auth.component.scss']
})
export class CreateAccountExtAuthComponent implements OnInit, OnDestroy {

	protected error: string = null;

	private _activatedRouteQueryParamsSubscription: Subscription;
	private _activatedRouteParamsSubscription: Subscription;

	private _token: string;

	private _queryParams: any = {};

	constructor(private readonly _activatedRoute: ActivatedRoute,
		private readonly _spinner: NgxSpinnerService,
		private readonly _mortgageApplicationService: MortgageApplicationService,
		private readonly _navigationService: NavigationService,
		private readonly _utilsService: UtilsService
	) { }

	ngOnInit() {
		this._activatedRouteQueryParamsSubscription = this._activatedRoute.queryParams.subscribe((queryParams) => {
			this._activatedRouteParamsSubscription = this._activatedRoute.params.subscribe((routeParams) => {
				Object.keys(queryParams).forEach(key => {
					if (key !== 'token' && key !== 's') {
						this._queryParams[key] = queryParams[key];
					} else {
						this._token = queryParams[key];
					}
				});
				const createLoanRequestJson = atob(queryParams['s']);
				const createLoanRequest = JSON.parse(createLoanRequestJson);
				this.createUserAccountAndMortgage(createLoanRequest);
			});
		});
	}

	ngOnDestroy(): void {
		this._activatedRouteQueryParamsSubscription?.unsubscribe();
		this._activatedRouteParamsSubscription?.unsubscribe();
	}

	private createUserAccountAndMortgage = (createApplicationRequest: CreateBorrowerAccountMortgageRequest) => {
		this.persistAuthData();
		this._mortgageApplicationService.saveLoanApplicationNonAnonymous(createApplicationRequest).subscribe(
			response => {
				if (response.applicationId) {
					this._queryParams['appId'] = response.applicationId;
				}
				this._navigationService.navigateToPath("apply", true, undefined, this._queryParams, false);
			},
			error => {
				this.error = getErrorMessageOrDefault(error);
			}
		).add(() => {
		});
	}

	private persistAuthData = () => {
		let jwtPayload = this._utilsService.parseJwt(this._token);

		localStorage.setItem(Constants.authorization.authorizationDataKey, JSON.stringify({
			token: this._token,
			userName: jwtPayload['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'],
			userId: jwtPayload['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'],
			scope: 'Borrower',
			companyGuid: jwtPayload.CompanyGuid,
			expiresAt: this._utilsService.getTokenExpireDate(jwtPayload.exp)
		}));
	}
}