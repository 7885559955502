import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EnvironmentService } from 'projects/shared/services/environment.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PortalScope } from '../login/login.component';
import { AuthenticationRequest } from '../models/auth/auth-request.model';
import { AuthenticationResponse, AuthenticationUserCompany } from '../models/auth/auth-response.model';
import { ResetPasswordRequest } from '../models/auth/reset-password-request.model';
import { Constants } from './constants';
import { DataService } from './data.service';
import { LocalStorageService } from './local-storage.service';
import { NavigationService } from './navigation.service';
import { UtilsService } from './utils.service';
import { CompanyInfo } from '../models/company-info.model';

const headers = {
  'Content-Type': 'application/json',
  'auth-req': 'true',
  'X-Requested-With': 'XMLHttpRequest',
  'APPLICATION_NAME': 'OnlineAppV3',
  'APPLICATION_VERSION': '3.0' // Flow guid will go to the version
};

@Injectable()
export class AuthService {

  constructor(private readonly _route: ActivatedRoute,
    private readonly _http: HttpClient,
    private readonly _dataService: DataService,
    private readonly _environmentService: EnvironmentService,
    private readonly _localStorageService: LocalStorageService,
    private readonly _utilsService: UtilsService,
    private readonly _navigationService: NavigationService) {
  }

  confirmRegistrationToken = (userName: string, token: string, companyGuid: string): Observable<any> => {
    var payload = {
      username: userName,
      token: token,
      companyGuid: companyGuid
    }
    const headersToUse = new HttpHeaders(headers)
      .set('APPLICATION_VERSION', this._navigationService.flowGuid ? this._navigationService.flowGuid : '');

    const portal = this._environmentService.scope === PortalScope.Agent ? "AgentPortal" : "BorrowerPortal";
    return this._dataService.postWithoutAuth(`api/${portal}/ConfirmRegistrationToken`, payload);
  }

  confirmRegistration = (userName: string, token: string, password: string, confirmPassword: string, companyGuid: string) => {
    const headersToUse = new HttpHeaders(headers)
      .set('APPLICATION_VERSION', this._navigationService.flowGuid ? this._navigationService.flowGuid : '');
    const portal = this._environmentService.scope === PortalScope.Agent ? "AgentPortal" : "BorrowerPortal";
    var payload = { userName: userName, token: token, password: password, confirmPassword: confirmPassword, isConfirmed: true, companyGuid: companyGuid };
    return this._dataService.postWithoutAuth(`api/${portal}/ConfirmRegistration`, payload);
  }

  isLoggedIn = (): boolean => {
    const authData = localStorage.getItem(Constants.authToken);
    return authData !== null;
  }

  logout = () => {
    localStorage.removeItem(Constants.authorization.authorizationDataKey);
  }

  setAdminToken = (token: string) => {
    localStorage.setItem(Constants.authorization.authorizationDataKey, JSON.stringify({
      token: token,
      useRefreshTokens: true,
      scope: 'Admin',
      expiresAt: new Date(4000, 2, 2)
    }));
  }

  setBorrowerToken = (token: string) => {
    localStorage.setItem(Constants.authorization.authorizationDataKey, JSON.stringify({
      token: token,
      useRefreshTokens: true,
      scope: 'Borrower',
      expiresAt: new Date(4000, 2, 2)
    }));
  }

  loginWithToken(authToken: string, companyGuid: string, userCompanyGuid: string): Observable<AuthenticationResponse> {
    localStorage.setItem(Constants.authorization.authorizationDataKey, JSON.stringify({
      token: authToken
    }));
    return this.exchangeToken(companyGuid, userCompanyGuid);
  }

  sendTwoFactorEmailCode = (userCompanyGuid: string) => {
    const url = `api/auth/SendTwoFactorEmailCode?userCompanyGuid=${userCompanyGuid}`;
    return this._dataService.postWithoutAuth(url, {});
  }

  sendTwoFactorPhoneCode = (userCompanyGuid: string) => {
    const url = `api/auth/SendTwoFactorPhoneCode?userCompanyGuid=${userCompanyGuid}`;
    return this._dataService.postWithoutAuth(url, {});
  }

  sentPasswordResetLink = (email: string, scope: string, userCompanyGuid: string) => {
    const url = `api/auth/ForgotPassword`;
    const payload = {
      email: email,
      scope: scope || "Borrower",
      userCompanyGuid: userCompanyGuid
    }
    return this._dataService.postWithoutAuth(url, payload);
  }

  resetPassword = (resetPasswordRequest: ResetPasswordRequest) => {
    const url = `api/auth/ResetPassword`;
    return this._dataService.postWithoutAuth(url, resetPasswordRequest);
  }

  resetPasswordWithRecurityCode = (resetPasswordRequest: ResetPasswordRequest) => {
    const url = `api/auth/ResetPassword/code`;
    return this._dataService.postWithoutAuth(url, resetPasswordRequest);
  }

  refreshToken = (refreshToken: string): Observable<AuthenticationResponse> => {
    const url = `api/auth/refreshToken?refreshToken=${refreshToken}`;
    return this._dataService.postWithoutAuth(url, {}).pipe(map(response => {
      this.saveAuthResponseInLocalStorage(response);
      return response;
    }));
  }

  getDefaultOnlineAppUrl = (companyGuid?: string): Observable<any> => {
    const baseUrl = this._environmentService.apiInfo.publicApiBaseUrl;
    let url = `${baseUrl}/api/publicapi/GetDefaultOnlineAppUrl`;
    if (companyGuid) {
      url += '/' + companyGuid;
    }
    return this._http.get(url, { responseType: 'text' });
  }

  exchangeToken = (companyGuid: string, userCompanyGuid: string): Observable<AuthenticationResponse> => {
    const url = `api/auth/exchangeToken?companyGuid=${companyGuid}&userType=Borrower&userCompanyGuid=${userCompanyGuid}`;
    return this._dataService.post(url, {});
  }

  signIn = (request: AuthenticationRequest): Observable<AuthenticationResponse> => {
    const url = 'api/auth/signin';
    return this._dataService.postWithoutAuth(url, request).pipe(map(response => {
      if (response.loginStatus === 'Success') {
        this.saveAuthResponseInLocalStorage(response);
      }
      return response;
    }));
  }

  challenge = (companyGuid: string, scope: string, returnUrl: string): Observable<any> => {
    const url = `api/auth/connect/challenge/${companyGuid}/${scope}?returnUrl=${encodeURIComponent(returnUrl)}`;
    return this._dataService.postWithoutAuth(url, {});
  }

  getAvailableCompanies = (email: string, scope: string): Observable<AuthenticationUserCompany[]> => {
    return this._dataService.get(`api/auth/AvailableCompanies?userName=${encodeURIComponent(email)}&scope=${scope}`);
  }

  confirmUpdatePhoneAnonymous = (phoneNumber: string, areacode: string, code: string, request: AuthenticationRequest): Observable<any> => {
    return this._dataService.postWithoutAuth(`api/auth/ConfirmUpdatePhoneAnonymous?phone=${phoneNumber}&areacode=${areacode}&code=${code}`, request);
  }

  updatePhoneAnonymous = (phoneNumber: string, areacode: string, request: AuthenticationRequest): Observable<any> => {
    return this._dataService.postWithoutAuth(`api/auth/UpdatePhoneAnonymous?phone=${phoneNumber}&areacode=${areacode}`, request);
  }

  getCompanyInfo = (userScope: PortalScope): Observable<CompanyInfo> => {
    return this._dataService.get(`api/auth/company-info?scope=${userScope}`);
  }

  private saveAuthResponseInLocalStorage = (authResponse: AuthenticationResponse) => {
    let jwtPayload = this._utilsService.parseJwt(authResponse.jwtToken);

    localStorage.setItem(Constants.authorization.authorizationDataKey, JSON.stringify({
      token: authResponse.jwtToken,
      userName: authResponse.userName,
      userId: authResponse.userCompanyGuid,
      refreshToken: authResponse.refreshToken,
      useRefreshTokens: true,
      scope: authResponse.userType,
      companyGuid: jwtPayload.CompanyGuid,
      expiresAt: this._utilsService.getTokenExpireDate(jwtPayload.exp)
    }));
  }
}
