import { Injectable } from "@angular/core";
import { FlowType } from "../../models/wizard/config/flow-type.enum";
import { StepConfig } from "../../models/wizard/config/step-config.model";
import { WizardFlowConfigs } from "../../models/wizard/config/wizard-flow-config.model";
import { WizardStepBase } from "../../models/wizard/wizard-step-base.model";
import { Observable } from "rxjs";

@Injectable()
export abstract class WizardFlowConfigServiceBase {

  abstract get flowConfigs(): WizardFlowConfigs;

  abstract get defaultFlowConfigs(): WizardFlowConfigs;

  abstract configureFlows(flowGuid: string | undefined, useCompanyDefaultIfAny: boolean): Observable<WizardFlowConfigs> | undefined;

  abstract getConfig(flowType: FlowType, step: WizardStepBase): StepConfig | undefined;
}
