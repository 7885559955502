import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CheckListConfig } from 'projects/borrower-app/src/app/models/checklists/config/checklist-config.model';

@Component({
  selector: 'checklist-picker',
  templateUrl: 'checklist-picker.component.html'
})

export class ChecklistPickerComponent implements OnInit {

  @Input()
  checklists: CheckListConfig[] = [];

  @Input()
  selectedChecklistIds: number[] = [];

  @Output()
  selectedChecklistsChanged: EventEmitter<number[]> = new EventEmitter<number[]>();

  constructor() { }

  ngOnInit() {
    if (!this.selectedChecklistIds) {
      this.selectedChecklistIds = [];
    } else {
      for (let i = this.selectedChecklistIds.length - 1; i >= 0; i--) {
        const exists = this.checklists.find(cl => cl.checklistId === this.selectedChecklistIds[i]);
        if (!exists) {
          this.selectedChecklistIds.splice(i, 1);
        }
      }
    }
  }

  onCheckListCheckChanged = (checklistId: number, e: any) => {
    if (e.target.checked) {
      if (!this.selectedChecklistIds.includes(checklistId)) {
        this.selectedChecklistIds.push(checklistId);
      }
    } else {
      if (this.selectedChecklistIds.includes(checklistId)) {
        const index = this.selectedChecklistIds.findIndex(id => id === checklistId);
        if (index >= 0) {
          this.selectedChecklistIds.splice(index, 1);
        }
      }
    }
    this.selectedChecklistsChanged.emit(this.selectedChecklistIds);
  }
}
