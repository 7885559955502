
<div class="d-flex align-items-center mb-8">
  <div class="form-check form-check-custom form-check-solid mx-5">
    <input class="form-check-input" type="checkbox" [(ngModel)]="liability['willPayOff']"
      (ngModelChange)="onPayoffSelectionChanged()">
  </div>
  <div class="flex-grow-1">
    <span class="d-inline-block text-truncate text-gray-800 fw-bold fs-6" [title]="liability.holderName">{{liability.holderName}}</span>
    <span class="text-muted fw-semibold d-block">{{(liability.monthlyPayment || 0) | currency}}/{{(liability.unpaidBalance || 0) | currency}}</span>
  </div>
  <span *ngIf="liability['willPayOff']" class="badge badge-light-success fs-8 fw-bold">Will pay off</span>
  <span *ngIf="!liability['willPayOff']" class="badge badge-light-danger fs-8 fw-bold">Will not pay off</span>
</div>
