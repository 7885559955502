<div class="row g-6 g-xl-9">
  <label class="col-lg-12 col-form-label fw-bold fs-6 pt-0 pb-0"><span>Below are the steps that exist in the flow. You can drag any one and drop on the left as a next step.</span></label>
  <div class="d-flex flex-wrap">
    <step-card draggable="true" class="step-card" [ngStyle]="{'opacity': divStyle}" (dragstart)="handleDragStart($event)" (dragend)="handleDragEnd($event)" [id]="step.id" [step]="step" *ngFor="let step of steps"></step-card>
  </div>
  <label class="col-lg-12 col-form-label fw-bold fs-6 pt-0 pb-0 d-flex">
    <span style="margin-top: 12px; padding-right: 20px;">You do not see the step you're looking for above?</span>
    <button type="button" class="btn btn-light-primary" (click)="onCreateClicked()">
      <!--begin::Svg Icon | path: icons/duotune/general/gen035.svg-->
      <span class="svg-icon svg-icon-3">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black"></rect>
          <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black"></rect>
          <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black"></rect>
        </svg>
      </span>
      <!--end::Svg Icon-->Create One</button>
  </label>
</div>
