import { Injectable } from "@angular/core";
import { EnumerationItem } from "projects/shared/models/enumeration-item.model";
import {combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import { DataService } from "./data.service";

@Injectable()
export class MergeFieldsService {

    constructor(
        private readonly _dataService: DataService,
    ) {}

    getAllMergeFields(): Observable<EnumerationItem[]> {
        return combineLatest([
            this._dataService.get('api/configuration/document-templates/app-global-merge-field-keys'),
            this._dataService.get('api/configuration/document-templates/agent-global-merge-field-keys'),
            this._dataService.get('api/configuration/document-templates/borrower-global-merge-field-keys'),
            this._dataService.get('api/configuration/document-templates/lead-global-merge-field-keys'),
            this._dataService.get('api/configuration/document-templates/credit-monitoring-global-merge-field-keys')
        ]).pipe(
            map(
                ([app, agent, borrower, lead, creditMonitoring]) => {
                    return [
                        ...[{ name: 'TaskUpdateSection', value: 'TaskUpdateSection', groupName: 'custom' }],
                        ...app.map(field => ({ name: field, value: field, groupName: 'app'})),
                        ...agent.map(field => ({ name: field, value: field, groupName: 'agent'})),
                        ...borrower.map(field => ({ name: field, value: field, groupName: 'borrower'})),
                        ...lead.map(field => ({ name: field, value: field, groupName: 'lead'})),
                        ...creditMonitoring.map(field => ({ name: field, value: field, groupName: 'creditMonitoring'})),
                    ];
                }
            )
        );
    }
}
