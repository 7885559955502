<ng-template #confirmAccount>
  <div class="d-flex flex-column flex-column-fluid p-10 pb-lg-10 register-wrapper"
    [ngClass]="{'overlay': spinnerVisible, 'overlay-block': spinnerVisible}">

    <div class="w-md-500px bg-white rounded shadow-sm p-7 p-lg-7 register-body">
      <div class="d-flex justify-content-center mb-4">
        <logo [logoUrlsToTry]="possibleLogoUrls"></logo>
      </div>
      <form class="form" novalidate="novalidate" #createAccountForm="ngForm" id="createAccountForm"
        name="createAccountForm">
        <div class="text-center my-4">
          <h2 class="text-dark mb-3">Welcome Back!</h2>
          <div class="text-gray-400 fw-bold fs-5">To complete setting up your account, please set a password for
            {{userName}}
          </div>
        </div>
        <div *ngIf="tokenConfirmed && !errorMessage" class="alert alert-success d-flex align-items-center p-5 mb-5">
          <span class="svg-icon svg-icon-2hx svg-icon-success me-3">
            <duotone-shield-check-svg-icon></duotone-shield-check-svg-icon>
          </span>
          <div class="d-flex flex-column">
            <span>Your account has been confirmed! Now, please setup a password.</span>
          </div>
        </div>
        <div *ngIf="errorMessage" class="alert alert-danger d-flex align-items-center p-5 mb-5">
          <span class="svg-icon svg-icon-2hx svg-icon-danger me-3">
            <duotone-shield-check-svg-icon></duotone-shield-check-svg-icon>
          </span>
          <div class="d-flex flex-column login-error-body">
            <span>{{errorMessage}}</span>
          </div>
        </div>
        <div class="fv-row mb-5">
          <label class="form-label fw-bolder text-dark fs-6">Email</label>
          <input class="form-control form-control-solid" type="email" value="{{userName}}" placeholder="" readonly
            name="email" autocomplete="off" email trim />
        </div>
        <div class="mb-5 fv-row" data-kt-password-meter="true" #passwordSection>
          <div class="mb-1">
            <label class="form-label fw-bolder text-dark fs-6">Password</label>
            <div class="position-relative mb-3">
              <input type="password" class="form-control form-control-solid" [(ngModel)]="password" autocomplete="off"
                placeholder="" id="password" name="password" reverse="true"
                required
                requiresDigit
                minlength="8"
                [caseSize]="'both'"
                requiresNonAlphaNumeric
                (mousedown)="op.show($event)"
                (ngModelChange)="onPasswordChanged()"
                (blur)="op.hide()"
                [ngClass]="{'is-invalid' : userPassword && userPassword.touched && userPassword.invalid}"
                #userPassword="ngModel" validateEqual="confirmedPassword" />
              <p-overlayPanel #op styleClass="password-validation-overlay">
                <ng-template pTemplate>
                  <div>
                    <div class="d-flex password-validation-indicator" [ngClass]="{
                        'text-success': passwordIsMinimum8Characters
                        }">
                      <i class="far password-validation-indicator-icon fa-sad-tear" [ngClass]="{
                          'fa-smile': passwordIsMinimum8Characters,
                          'text-success': passwordIsMinimum8Characters
                          }"></i>
                      <div>Min. 8 characters</div>
                    </div>
                    <div class="d-flex password-validation-indicator" [ngClass]="{
                        'text-success': passwordContainsDigit
                        }">
                      <i class="far password-validation-indicator-icon fa-sad-tear" [ngClass]="{
                          'fa-smile': passwordContainsDigit,
                          'text-success': passwordContainsDigit
                          }"></i>
                      <div>Include number</div>
                    </div>
                    <div class="d-flex password-validation-indicator" [ngClass]="{
                      'text-success': passwordContainsLowercase
                      }">
                      <i class="far password-validation-indicator-icon fa-sad-tear" [ngClass]="{
                        'fa-smile': passwordContainsLowercase,
                        'text-success': passwordContainsLowercase
                        }"></i>
                      <div>Include lowercase letter</div>
                    </div>
                    <div class="d-flex password-validation-indicator" [ngClass]="{
                    'text-success': passwordContainsUppercase
                    }">
                      <i class="far password-validation-indicator-icon fa-sad-tear" [ngClass]="{
                      'fa-smile': passwordContainsUppercase,
                      'text-success': passwordContainsUppercase
                      }"></i>
                      <div>Include uppercase letter</div>
                    </div>
                    <div class="d-flex password-validation-indicator" [ngClass]="{
                      'text-success': passwordContainsSpecialCharacter
                      }">
                        <i class="far password-validation-indicator-icon fa-sad-tear" [ngClass]="{
                        'fa-smile': passwordContainsSpecialCharacter,
                        'text-success': passwordContainsSpecialCharacter
                        }"></i>
                        <div>Include a special character</div>
                      </div>
                  </div>

                </ng-template>
              </p-overlayPanel>
              <div class="invalid-feedback" *ngIf="userPassword.errors && userPassword.errors.required">Please enter
                your password.</div>
              <div class="invalid-feedback" *ngIf="userPassword.errors && userPassword.errors.validateEqual"
                class="invalid-feedback">Password & Confirm Password do not match.</div>
              <span class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                data-kt-password-meter-control="visibility">
                <i class="bi bi-eye-slash fs-2"></i>
                <i class="bi bi-eye fs-2 d-none"></i>
              </span>
            </div>
            <div class="d-flex align-items-center mb-3" data-kt-password-meter-control="highlight">
              <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
              <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
              <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
              <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
            </div>
          </div>
          <div class="text-muted">Use 8 or more characters with a mix of letters, numbers &amp; symbols.</div>
        </div>
        <div class="fv-row mb-5">
          <label class="form-label fw-bolder text-dark fs-6">Confirm Password</label>
          <input type="password" class="form-control form-control-solid" [(ngModel)]="confirmedPassword"
            autocomplete="off" required placeholder="" name="confirmedPassword"
            [ngClass]="{'is-invalid' : userConfirmedPassword && userConfirmedPassword.touched && userConfirmedPassword.invalid}"
            #userConfirmedPassword="ngModel" validateEqual="password" />
          <div class="invalid-feedback" *ngIf="userConfirmedPassword.errors && userConfirmedPassword.errors.required">
            Please enter your password again.</div>
          <div class="invalid-feedback"
            *ngIf="userConfirmedPassword.errors && userConfirmedPassword.errors.validateEqual" class="invalid-feedback">
            Password & Confirm Password do not match.</div>
        </div>
        <div class="fv-row mb-10">
          <label class="form-check form-check-custom form-check-solid form-check-inline mb-5">
            <input class="form-check-input" type="checkbox" name="toc" [(ngModel)]="tocAccepted" />
            <span class="form-check-label fw-bold text-gray-700">
              <a (click)="onTermsAndConditionsClicked()" class="link-primary ms-1">Terms &amp; Conditions</a>.</span>
          </label>
        </div>
        <div class="text-center pb-lg-0 pb-8">
          <button type="button" [disabled]="!tocAccepted" class="btn btn-lg btn-primary fw-bolder"
            (click)="onCreatePasswordClicked()">
            <span class="indicator-label">Create Account and {{gotoPortalScope ? "Go to Your Portal" : "Continue
              Application"}} </span>
            <span *ngIf="!spinnerVisible" class="svg-icon svg-icon-4 ms-1 me-0">
              <duotone-navigation-right-svg-icon></duotone-navigation-right-svg-icon>
            </span>
            <span *ngIf="spinnerVisible" class="spinner-border spinner-border-sm align-middle ms-2"></span>
          </button>
        </div>
      </form>
      <div class="overlay-layer rounded bg-primary-o-20">
        <div class="spinner spinner-primary"></div>
      </div>

    </div>
  </div>
  <div class="d-flex flex-column justify-content-center align-items-center">
    <div class="login-footer">
      <img src="https://borrowerportal.lodasoft.dev/assets/img/Logo.svg" alt="Lodasoft">
    </div>
  </div>

</ng-template>

<user-account-template [contentTemplate]="confirmAccount"></user-account-template>
