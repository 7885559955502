import { Injectable } from "@angular/core";
import { WizardFlowContext } from "../../../wizard-flow-context.model";
import { GenericStepFactory } from "./generic-step.factory";
import { NextStepDeciderFactory } from "./next-step-decider.factory";
import { ICreateStep } from "./step-factory.interface";
import { DemographicsStep } from "../../demographics-step.model";
import { DemographicsStepConfig } from "../demographics-step.config.model";

@Injectable()
export class DemographicsStepFactory extends GenericStepFactory<DemographicsStep> implements ICreateStep {

  constructor(nextStepDeciderFactory: NextStepDeciderFactory) {
    super(nextStepDeciderFactory, DemographicsStep);
  }

  create(config: DemographicsStepConfig, wizardFlowContext: WizardFlowContext): DemographicsStep {
    let demographicsStep = super.create(config, wizardFlowContext)
    demographicsStep.showOptionToNotProvideDemographicsInfo = config.showOptionToNotProvideDemographicsInfo != null
      ? config.showOptionToNotProvideDemographicsInfo : true;
    return demographicsStep;
  }

  configure(step: DemographicsStep, config: DemographicsStepConfig): void {
    super.configure(step, config);
    config.showOptionToNotProvideDemographicsInfo = step.showOptionToNotProvideDemographicsInfo;
  }
}
