import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { EnumerationItem } from 'projects/shared/models/enumeration-item.model';
import { PortalScope } from '../../login/login.component';
import { ApplicationForUser } from "../../models/borrower-portal/application-for-user-model";
import { LiveDataTotalModel } from '../../models/borrower-portal/live-data-total-model';
import { BasePortalService } from "../../services/base-portal.service";
import { LocalStorageService } from '../../services/local-storage.service';
import { NavigationService } from '../../services/navigation.service';
import { PortalContent } from '../../models/borrower-portal/portal-content-model-model';
import { ApplicationContextService } from '../../services/application-context.service';
import { ApplicationContext } from '../../models/application-context.model';

@Component({
  selector: 'portal-loans',
  templateUrl: './loans.component.html',
})
export class PortalLoansComponent implements OnInit {
  loanStatuses: EnumerationItem[] = [];
  sortFields: EnumerationItem[] = [
    { name: 'App Id', value: 'appId' },
    { name: 'App Name', value: 'appName' },
    { name: 'Amount (High to Low)', value: 'amountDesc' },
    { name: 'Amount (Low to High)', value: 'amountAsc' },
    { name: 'Status', value: 'status' },
  ];

  selectedLoanStatus: string = null;
  selectedSortField: string = 'appId';

  applications: ApplicationForUser[] = [];
  filteredApplications: ApplicationForUser[] = [];

  selectDisplayMode: 'list' | 'card' = 'card';
  totals: Record<number, LiveDataTotalModel> = {};

  isAgent: boolean = false;

  portalContent: PortalContent | null = null;

  appContext: ApplicationContext;

  constructor(
    private readonly _portalService: BasePortalService,
    private readonly _spinnerService: NgxSpinnerService,
    private readonly _router: Router,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _navigationService: NavigationService,
    private readonly _localStorage: LocalStorageService,
    private readonly _ctx: ApplicationContextService
  ) {
    let portalScope = this._localStorage.authorizationData.scope as PortalScope;
    if (portalScope && portalScope == PortalScope.Agent) {
      this.isAgent = true;
    }

    this._ctx.context.subscribe(contextData => {
      this.appContext = contextData;
    })
  }

  ngOnInit(): void {
    this._spinnerService.show();
    this._portalService.getPortalContent().subscribe({
      next: (portalContent) => {
        this.portalContent = portalContent;
        this._portalService.getApplicationsForUser().subscribe({
          next: (applications) => {
            this.applications = applications;
            this.filteredApplications = [...this.applications];

            if (this._activatedRoute.snapshot.url.find(url => !url.path.includes('my-loans'))) {
              if (this.applications.length === 1) {
                let companyGuid = this._navigationService.companyGuid;
                this._router.navigate([`${this.isAgent ? 'agent' : 'borrower'}-portal/loans`,
                  this.applications[0].applicationId], { queryParams: { companyGuid: companyGuid } });
                  return;
              }
            }

            this.loadTotals();

            this.sortBySortField(this.selectedSortField);
            this.initStatusOptions();
            this._spinnerService.hide();

            let affiliations = [];
            this.applications.forEach(app => {
              let appAffiliations = app.industryAffiliations ? app.industryAffiliations.split(",").map(aff => aff.trim()) : [];
              affiliations = affiliations.concat(appAffiliations.filter((value, index, self) => self.indexOf(value) === index));
            });

            affiliations = affiliations.filter((value, index, self) => self.indexOf(value) === index && value && value != "Disabled");

            if (this.appContext) {
              this.appContext.industryAffiliations = affiliations;
              this._ctx.updateContext(this.appContext);
            }
          },
          error: (err) => {
            console.log(err);
            this._spinnerService.hide();
          },
        });
      },
      error: (err) => {
        console.log(err);
        this._spinnerService.hide();
      },
    });
  }

  initStatusOptions() {
    this.applications.forEach((application) => {
      if (
        application.loanStatus &&
        !this.loanStatuses.map((s) => s.name).includes(application.loanStatus)
      ) {
        this.loanStatuses.push(
          new EnumerationItem(application.loanStatus, application.loanStatus)
        );
      }
    });
  }

  loadTotals = () => {
    this._portalService.getLiveData().subscribe({
      next: (totals) => {
        this.totals = totals.applicationTotals as any;
      },
      error: (err) => {
        console.error(err);
      },
    });
  };

  onSearch = (e: any) => {
    let searchKey = e.target.value.toUpperCase();

    this.filteredApplications = [];

    if (searchKey) {
      this.applications.forEach((application) => {
        let isMatched =
          application.city?.toUpperCase().includes(searchKey) ||
          application.state?.toUpperCase().includes(searchKey) ||
          application.zip?.toUpperCase().includes(searchKey) ||
          application.address?.toUpperCase().includes(searchKey) ||
          application.applicationName?.toUpperCase().includes(searchKey) ||
          application?.myDetails?.borrowerName?.toUpperCase().includes(searchKey) ||
          application.loanPurpose?.toUpperCase().includes(searchKey) ||
          application.loanStatus?.toUpperCase().includes(searchKey);
        if (isMatched) {
          this.filteredApplications.push(application);
        }
      });
    } else {
      this.filteredApplications = [...this.applications];
    }
  };

  filterByLoanStatus = (loanStatus: string) => {
    if (!loanStatus) {
      this.filteredApplications = [...this.applications];
    } else {
      this.filteredApplications = [
        ...this.applications.filter((app) => app.loanStatus === loanStatus),
      ];
    }
  };

  sortBySortField = (sortFieldValue: string) => {
    switch (sortFieldValue) {
      case 'appId':
        this.filteredApplications = this.filteredApplications.sort(
          (a, b) => b.applicationId - a.applicationId
        );
        break;
      case 'appName':
        this.filteredApplications = this.filteredApplications.sort((a, b) =>
          a.applicationName.localeCompare(b.applicationName)
        );
        break;
      case 'amountDesc':
        this.filteredApplications = this.filteredApplications.sort(
          (a, b) => b.loanAmount - a.loanAmount
        );
        break;
      case 'amountAsc':
        this.filteredApplications = this.filteredApplications.sort(
          (a, b) => a.loanAmount - b.loanAmount
        );
        break;
      case 'status':
        this.filteredApplications = this.filteredApplications.sort(
          (a, b) =>
            a.loanStatus &&
            b.loanStatus &&
            a.loanStatus.localeCompare(b.loanStatus)
        );
        break;
      default:
        break;
    }
    this.filteredApplications = [...this.filteredApplications];
  };

  badgeLabel = (count = 0): string => {
    if (!count) {
      return '';
    }
    return count > 9 ? '9+' : !count ? '' : String(count);
  };

  openAgentContact = (loanId: number) => {
    let companyGuid = this._navigationService.companyGuid;
    this._router.navigate(
      [`${this.isAgent ? 'agent' : 'borrower'}-portal/request-a-call`],
      { queryParams: { companyGuid: companyGuid, loanId: loanId } }
    );
  };
}
