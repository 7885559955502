import { Component, OnInit } from '@angular/core';
import { WizardNavigationComponentBase } from '../wizard-navigation/wizard-navigation-base.component';
import { StepTitleChangedEvent } from './wizard-horizontal-nav-step.component';

@Component({
  selector: 'wizard-horizontal-nav',
  templateUrl: 'wizard-horizontal-nav.component.html'
})
export class WizardHorizontalNavComponent extends WizardNavigationComponentBase implements OnInit {

  onStepTitleChanged = (e: StepTitleChangedEvent) => {
    const changedStep = this.navGroups[e.stepIndex];
    changedStep.title = e.newTitle;
  }
}
