import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { Borrower } from '../../../models/borrower-model';
import { ErrorMessage } from '../../../models/error-message.model';
import { MortgageApplication } from '../../../models/mortgage-app.model';
import { MortgageApplicationService } from '../../../services/mortgage-application.service';
import { WizardStepComponentBase } from '../wizard-step-base.component';
import { NumberOfBorrowersStep } from '../../../models/wizard/number-of-borrowers-step.model';

@Component({
  selector: 'number-of-borrowers',
  templateUrl: 'number-of-borrowers.component.html'
})
export class NumberOfBorrowersComponent extends WizardStepComponentBase<NumberOfBorrowersStep> implements OnInit {

  @ViewChild('numberOfBorrowersForm') numberOfBorrowersForm: NgForm | undefined;

  private _mortgageApplication: MortgageApplication;

  public numberOfCoBorrowers: number | undefined = 0;

  public hasCoBorrowers: boolean | null = null;

  onYesSelected = () => {
    this.numberOfCoBorrowers = 1;
    this.hasCoBorrowers = true;
    this.step.requiresNextButton = true;
  }

  onNumberOfCoBorrowersChanged = () => {
    if (this.numberOfCoBorrowers) {
      if (this.numberOfCoBorrowers < 0) {
        this.numberOfCoBorrowers = 0;
      }
    }
  }

  onNoSelected = () => {
    this.hasCoBorrowers = false;
    this.numberOfCoBorrowers = 0;
    this.onNextClicked();
  }

  constructor(private readonly _injector: Injector, private _mortgageApplicationService: MortgageApplicationService) {
    super(_injector);
    this._mortgageApplication = this._mortgageApplicationService.mortgageApplication;
  }

  ngOnInit() {
    this.step.requiresNextButton = false;
    this.numberOfCoBorrowers = this._mortgageApplication.borrowers.length - 1;
    if (this.numberOfCoBorrowers > 0) {
      this.hasCoBorrowers = true;
      this.step.requiresNextButton = true;
    } else {
      if (this.wizardFlowService.context.hasMultipleBorrowers === false) {
        this.hasCoBorrowers = false;
        this.step.requiresNextButton = true;
      }
    }
  }

  onNextClicked() {
    this.numberOfBorrowersForm?.form.markAllAsTouched();
    if (this.numberOfBorrowersForm?.form.valid) {
      let existingNumberOfCoBorrowers = this._mortgageApplication.borrowers.length - 1;
      if (this.numberOfCoBorrowers == null) {
        super.onNextClicked();
        return;
      }
      this.wizardFlowService.context.hasMultipleBorrowers = this.numberOfCoBorrowers > 0;
      if (this.numberOfCoBorrowers > existingNumberOfCoBorrowers) {
        for (let i = 1; i <= this.numberOfCoBorrowers - existingNumberOfCoBorrowers; i++) {
          const coBorrower = new Borrower();
          coBorrower.borrowerId = 0 - i;
          this._mortgageApplication.borrowers.push(coBorrower);
        }
        super.onNextClicked();
      } else {
        const numberOfBorrowersToRemove = existingNumberOfCoBorrowers - this.numberOfCoBorrowers;

        if (numberOfBorrowersToRemove <= this._mortgageApplication.borrowers.length - 1) {
          const startIndex = this._mortgageApplication.borrowers.length - numberOfBorrowersToRemove;
          const apiCalls: any[] = [];

          for (let i = this._mortgageApplication.borrowers.length - 1; i >= startIndex; i--) {
            const borrowerToRemove = this._mortgageApplication.borrowers[i];
            if (borrowerToRemove.borrowerId && borrowerToRemove.borrowerId > 0) {
              const apiCallToRemoveBorrower = this._mortgageApplicationService.removeBorrower(this._mortgageApplication.applicationId,
                borrowerToRemove.contactId);
              apiCalls.push(apiCallToRemoveBorrower);
            }
            this._mortgageApplication.borrowers.splice(i, 1);
            this._mortgageApplication.realEstateOwned.forEach(reo => {
              const index = reo.owningBorrowerIds.findIndex(id => id == borrowerToRemove.borrowerId);
              if (index >= 0) {
                reo.owningBorrowerIds.splice(index, 1);
              }
            })
          }

          if (apiCalls.length) {
            this.startSpinner();
            forkJoin(apiCalls).subscribe(() => {
              super.onNextClicked();
            }, error => {
              this.showError(new ErrorMessage("Error", error.message));
            }).add(() => {
              this.stopSpinner();
            })
          } else {
            super.onNextClicked();
          }
        }
      }
    }
  }
}
