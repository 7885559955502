<form #borrowerHomeownershipEducationForm="ngForm" novalidate id="borrowerHomeownershipEducationForm"
	name="borrowerHomeownershipEducationForm">
  <div class="row mb-5 rounded border p-4"
    [ngClass]="{'border-danger': hasHomebuyerEducationInput.touched && hasHomebuyerEducationInput.invalid}">
    <div class="col-lg-10 col-md-12">
      <div class="position-relative">
        Has the Borrower(s) completed homebuyer education (group or web-based classes) within the last 12 months?
      </div>
    </div>
    <div class="col-lg-2 col-sm-6">
      <div class="d-flex">
        <div class="form-check form-check-custom form-check-solid">
          <input class="form-check-input" name="hasHomebuyerEducationQuestion{{borrower.borrowerId}}" required [value]="true" type="radio" id="hasHomebuyerEducation_aYes{{borrower.borrowerId}}"
            [(ngModel)]="hasHomebuyerEducation" (change)="onBorrowerCounselingEventChanged('Education')" #hasHomebuyerEducationInput="ngModel"
            />
          <label style="padding-right: 6px;" class="form-check-label" for="hasHomebuyerEducation_aYes{{borrower.borrowerId}}">Yes</label>
        </div>
        <div class="form-check form-check-custom form-check-solid ms-8">
          <input class="form-check-input" name="hasHomebuyerEducationQuestion{{borrower.borrowerId}}" required [value]="false" type="radio" id="hasHomebuyerEducation_aNo{{borrower.borrowerId}}"
            [(ngModel)]="hasHomebuyerEducation" (change)="onBorrowerCounselingEventChanged('Education')" #hasHomebuyerEducationInput="ngModel"/>
          <label style="padding-right: 6px;" class="form-check-label" for="hasHomebuyerEducation_aNo{{borrower.borrowerId}}">No</label>
        </div>
      </div>
    </div>
  </div>

  <div class="w-100" *ngIf="hasHomebuyerEducation">
    <ng-container *ngFor="let counselingEvent of borrower.counselingEvents | filter: { counselingType: 'Education' }; let index = index">
      <borrower-counseling-event [borrower]="borrower" [borrowerCounselingFormatTypes]="formatTypes" [event]="counselingEvent" (attemptedToDeleteEvent)="onDelete(index)">
      </borrower-counseling-event>
    </ng-container>
    <div class="row px-2 my-2">
      <div class="form-group">
        <button type="button" class="btn btn-sm btn-light-primary me-3"
          (click)="onAddNewCounselingEventClicked('Education')">
          <i class="fas fa-plus me-1"></i>
          New Event
        </button>
      </div>
    </div>
  </div>

  <div class="row mb-5 rounded border p-4"
  [ngClass]="{'border-danger': hasCounselingInput.touched && hasCounselingInput.invalid}">
  <div class="col-lg-10 col-md-12">
    <div class="position-relative">
      Has the Borrower(s) completed housing counseling (customized counselor-to-client services) within the last 12 months?
    </div>
  </div>
  <div class="col-lg-2 col-sm-6">
    <div class="d-flex">
      <div class="form-check form-check-custom form-check-solid">
        <input class="form-check-input" name="hasCounselingQuestion{{borrower.borrowerId}}" required [value]="true" type="radio" id="hasCounseling_aYes{{borrower.borrowerId}}"
          [(ngModel)]="hasCounseling" (change)="onBorrowerCounselingEventChanged('Counseling')" #hasCounselingInput="ngModel"
          />
        <label style="padding-right: 6px;" class="form-check-label" for="hasCounseling_aYes{{borrower.borrowerId}}">Yes</label>
      </div>
      <div class="form-check form-check-custom form-check-solid ms-8">
        <input class="form-check-input" name="hasCounselingQuestion{{borrower.borrowerId}}" required [value]="false" type="radio" id="hasCounseling_aNo{{borrower.borrowerId}}"
          [(ngModel)]="hasCounseling" (change)="onBorrowerCounselingEventChanged('Counseling')" #hasCounselingInput="ngModel"/>
        <label style="padding-right: 6px;" class="form-check-label" for="hasCounseling_aNo{{borrower.borrowerId}}">No</label>
      </div>
    </div>
  </div>
</div>

<div class="w-100" *ngIf="hasCounseling">
  <ng-container *ngFor="let counselingEvent of borrower.counselingEvents | filter: { counselingType: 'Counseling' }; let index = index">
    <borrower-counseling-event [borrower]="borrower" [borrowerCounselingFormatTypes]="formatTypes" [event]="counselingEvent" (attemptedToDeleteEvent)="onDelete(index)">
    </borrower-counseling-event>
  </ng-container>
  <div class="row px-2 my-2">
    <div class="form-group">
      <button type="button" class="btn btn-sm btn-light-primary me-3"
        (click)="onAddNewCounselingEventClicked('Counseling')">
        <i class="fas fa-plus me-1"></i>
        New Event
      </button>
    </div>
  </div>
</div>
</form>
