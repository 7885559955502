import { FieldConfig } from "./field-config-model";
import { StepType } from "./step-type.enum";

export class FieldConfigUtils {

  public static generateAddressHistoryStepFieldConfig = (): any => {
    let addressHistoryStepFieldConfig: any = {};
    addressHistoryStepFieldConfig["residencyAddress.durationYears"] = new FieldConfig("residencyAddress.durationYears", "Year(s)");
    addressHistoryStepFieldConfig["residencyAddress.durationMonths"] = new FieldConfig("residencyAddress.durationMonths", "Month(s)");
    addressHistoryStepFieldConfig["residencyAddress.residencyBasis"] = new FieldConfig("residencyAddress.residencyBasis", "Ownership Status");
    addressHistoryStepFieldConfig["residencyAddress.rent"] = new FieldConfig("residencyAddress.rent", "Monthly Rent");

    const reoFieldsConfig = this.generateReoStepFieldConfig();
    addressHistoryStepFieldConfig = { ...addressHistoryStepFieldConfig, ...reoFieldsConfig };
    return addressHistoryStepFieldConfig;
  }

  public static generateAssetsStepFieldConfig = (): any => {
    let assetsStepFieldConfig: any = {};
    assetsStepFieldConfig["asset.assetType"] = new FieldConfig("asset.assetType", "Asset Type");
    assetsStepFieldConfig["asset.depository"] = new FieldConfig("asset.depository", "Name of Bank/Credit Union");
    assetsStepFieldConfig["asset.cashMarketValue"] = new FieldConfig("asset.cashMarketValue", "Amount");
    assetsStepFieldConfig["asset.accountNumber"] = new FieldConfig("asset.accountNumber", "Account Number");
    assetsStepFieldConfig["asset.makeModel"] = new FieldConfig("asset.makeModel", "Make/Model");
    assetsStepFieldConfig["asset.year"] = new FieldConfig("asset.year", "Year");
    assetsStepFieldConfig["asset.disposition"] = new FieldConfig("asset.disposition", "Disposition");
    assetsStepFieldConfig["asset.amountOfMortgageLien"] = new FieldConfig("asset.amountOfMortgageLien", "Amount Of Mortgage Lien");
    assetsStepFieldConfig["asset.mortgagePayment"] = new FieldConfig("asset.mortgagePayment", "Mortgage Payment");
    assetsStepFieldConfig["asset.gross"] = new FieldConfig("asset.gross", "Gross");
    assetsStepFieldConfig["asset.netRentalIncome"] = new FieldConfig("asset.netRentalIncome", "Net Rental Income");
    assetsStepFieldConfig["asset.insMainTaxMisc"] = new FieldConfig("asset.insMainTaxMisc", "Insurance Maintainance Taxes Misc");
    return assetsStepFieldConfig;
  }

  public static generatePurchaseCreditsStepFieldConfig = (): any => {
    let purchaseCreditsStepFieldConfig: any = {};
    purchaseCreditsStepFieldConfig["subjectProperty.presentValue"] = new FieldConfig("subjectProperty.presentValue", "Estimated Value");
    purchaseCreditsStepFieldConfig["subjectProperty.propertyWillBe"] = new FieldConfig("subjectProperty.propertyWillBe", "Property Will Be");
    purchaseCreditsStepFieldConfig["subjectProperty.state"] = new FieldConfig("subjectProperty.state", "State");
    purchaseCreditsStepFieldConfig["subjectProperty.yearBuilt"] = new FieldConfig("subjectProperty.yearBuilt", "Year Built");
    purchaseCreditsStepFieldConfig["subjectProperty.yearBuilt"].isRequired = false;
    purchaseCreditsStepFieldConfig["subjectProperty.noOfUnits"] = new FieldConfig("subjectProperty.noOfUnits", "Number of Units");
    purchaseCreditsStepFieldConfig["subjectProperty.noOfUnits"].isRequired = false;
    purchaseCreditsStepFieldConfig["mortgageApplication.extension.purchaseAgreementContractSignedDate"] =
      new FieldConfig("mortgageApplication.extension.purchaseAgreementContractSignedDate", "When was the contract signed?");
    purchaseCreditsStepFieldConfig["mortgageApplication.extension.purchaseAgreementExpectedCOEDate"] =
      new FieldConfig("mortgageApplication.extension.purchaseAgreementExpectedCOEDate", "When is the expected close of escrow?");

    purchaseCreditsStepFieldConfig["mortgageApplication.transactionDetail.purchaseCredits.earnestMoneyDeposit"] =
      new FieldConfig("mortgageApplication.transactionDetail.purchaseCredits.earnestMoneyDeposit", "How much is the earnest money deposit?");

    purchaseCreditsStepFieldConfig["mortgageApplication.transactionDetail.purchaseCredits.sellerCredits"] =
      new FieldConfig("mortgageApplication.transactionDetail.purchaseCredits.sellerCredits", "What is the amount?");

    return purchaseCreditsStepFieldConfig;
  }

  public static generateReviewApplicationStepFieldConfig = (): any => {
    let reviewApplicationStepFieldConfig: any = {};
    reviewApplicationStepFieldConfig["mortgage.transactionDetail.purchasePriceAmount"] = new FieldConfig("mortgage.transactionDetail.purchasePriceAmount", "Purchase Price");
    return reviewApplicationStepFieldConfig;
  }

  public static generateIncomeStepFieldConfig = (): any => {
    let incomeStepFieldConfig: any = {};
    incomeStepFieldConfig["employment.businessType"] = new FieldConfig("employment.businessType", "Business Type");
    incomeStepFieldConfig["employment.employer"] = new FieldConfig("employment.employer", "Employer Name");
    incomeStepFieldConfig["employment.isPrimary"] = new FieldConfig("employment.isPrimary", "Job Type");
    incomeStepFieldConfig["employment.position"] = new FieldConfig("employment.position", "Position");
    incomeStepFieldConfig["employment.employmentType"] = new FieldConfig("employment.employmentType", "Current or Former");
    incomeStepFieldConfig["employment.employerPhone"] = new FieldConfig("employment.employerPhone", "Corporate Phone");
    incomeStepFieldConfig["employment.yearsInLineOfWork"] = new FieldConfig("employment.yearsInLineOfWork", "Year(s) in Profession");
    incomeStepFieldConfig["employment.startDate"] = new FieldConfig("employment.startDate", "Start Date");
    incomeStepFieldConfig["employment.endDate"] = new FieldConfig("employment.endDate", "End Date");
    incomeStepFieldConfig["employment.selfEmploymentMonthlyIncomeOrLoss"] = new FieldConfig("employment.selfEmploymentMonthlyIncomeOrLoss", "Monthly Income/Loss");
    incomeStepFieldConfig["employment.borrowerOwnershipShare"] = new FieldConfig("employment.borrowerOwnershipShare", "Ownership Share");
    incomeStepFieldConfig["employment.selfEmploymentForm"] = new FieldConfig("employment.selfEmploymentForm", "Self Employment Form");
    incomeStepFieldConfig["monthlyPay"] = new FieldConfig("monthlyPay", "Monthly Pay");
    incomeStepFieldConfig["employment.selfEmployed"] = new FieldConfig("employment.selfEmployed", "Self Employed");
    incomeStepFieldConfig["income.typeOfIncomeOtherDescription"] = new FieldConfig("income.typeOfIncomeOtherDescription", "Description");
    incomeStepFieldConfig["income.monthlyIncome"] = new FieldConfig("income.monthlyIncome", "Monthly Income");
    incomeStepFieldConfig["income.monthlyRent"] = new FieldConfig("income.monthlyRent", "Monthly Rent");
    return incomeStepFieldConfig;
  }

  public static generateReoStepFieldConfig = (): any => {
    let reoStepFieldConfig: any = {};
    reoStepFieldConfig["reo.typeOfProperty"] = new FieldConfig("reo.typeOfProperty", "Property Type");
    reoStepFieldConfig["reo.marketValue"] = new FieldConfig("reo.marketValue", "Property Value");
    reoStepFieldConfig["reo.currentUsageType"] = new FieldConfig("reo.currentUsageType", "How do you currently use this property?");
    reoStepFieldConfig["reo.intendedUsageType"] = new FieldConfig("reo.intendedUsageType", "How do you plan on using this property?");
    reoStepFieldConfig["reo.dispositionStatus"] = new FieldConfig("reo.dispositionStatus", "Do you plan on selling this property?");
    reoStepFieldConfig["reo.amountOfMortgage"] = new FieldConfig("reo.amountOfMortgage", "Current balance of existing liens on this property?");
    reoStepFieldConfig["reo.mortgagePayment"] = new FieldConfig("reo.mortgagePayment", "Monthly Payment");
    reoStepFieldConfig["reo.monthlyMiscExpenses"] = new FieldConfig("reo.monthlyMiscExpenses", "Insurance, HOA, Tax");
    reoStepFieldConfig["reo.purchasePrice"] = new FieldConfig("reo.purchasePrice", "Original Purchase Price");
    reoStepFieldConfig["reo.dateAcquired"] = new FieldConfig("reo.dateAcquired", "Date Acquired");
    return reoStepFieldConfig;
  }

  public static generateLoanInfoStepFieldConfig = (): any => {
    let loanInfoStepFieldConfig: any = {};
    loanInfoStepFieldConfig["mortgageApplication.mortgageTerm.mortgageAppliedFor"] =
      new FieldConfig("mortgageApplication.mortgageTerm.mortgageAppliedFor", "If you do, please choose it from the list below. If not, please click continue.");
    loanInfoStepFieldConfig["mortgage.transactionDetail.purchasePriceAmount"] = new FieldConfig("mortgage.transactionDetail.purchasePriceAmount", "Purchase Price");
    loanInfoStepFieldConfig["mortgage.transactionDetail.downpayment"] =
      new FieldConfig("mortgage.transactionDetail.downpayment", "Down Payment"); // mortgage.transactionDetail.downpayment does not really exist on mortgage model
    loanInfoStepFieldConfig['mortgage.subjectProperty.downPaymentSource'] = new FieldConfig('mortgage.subjectProperty.downPaymentSource', 'Down Payment Source');
    return loanInfoStepFieldConfig;
  }

  public static generateBorrowerFieldsConfig = (): any => {
    let borrowerFieldsConfig: any = {};
    borrowerFieldsConfig["borrower.firstName"] = new FieldConfig("borrower.firstName", "First Name");
    borrowerFieldsConfig["borrower.lastName"] = new FieldConfig("borrower.lastName", "Last Name");
    borrowerFieldsConfig["borrower.middleName"] = new FieldConfig("borrower.middleName", "Middle Name");
    borrowerFieldsConfig["borrower.middleName"].isRequired = false;
    borrowerFieldsConfig["borrower.nameSuffix"] = new FieldConfig("borrower.nameSuffix", "Suffix");
    borrowerFieldsConfig["borrower.nameSuffix"].isRequired = false;
    borrowerFieldsConfig["borrower.mobilePhone"] = new FieldConfig("borrower.mobilePhone", "Phone Number");
    borrowerFieldsConfig["borrower.dateOfBirth"] = new FieldConfig("borrower.dateOfBirth", "Birth Date");
    borrowerFieldsConfig["borrower.socialSecNum"] = new FieldConfig("borrower.socialSecNum", "Social Security Number");
    borrowerFieldsConfig["borrower.declarations.residenceStatus"] = new FieldConfig("borrower.declarations.residenceStatus", "Citizenship");
    borrowerFieldsConfig["borrower.maritalStatus"] = new FieldConfig("borrower.maritalStatus", "Marital Status");
    borrowerFieldsConfig["borrower.numberOfDependents"] = new FieldConfig("borrower.numberOfDependents", "Number of Dependents");
    borrowerFieldsConfig["borrower.languagePreference"] = new FieldConfig("borrower.languagePreference", "Language Preference");
    borrowerFieldsConfig["borrower.languagePreferenceOtherDescription"] =
      new FieldConfig("borrower.languagePreferenceOtherDescription", "Specify Language");
    borrowerFieldsConfig["borrower.dependentAges"] = new FieldConfig("borrower.dependentAges", "Dependent Ages");
    return borrowerFieldsConfig;
  }

  public static generateCoBorrowerFieldsConfig = (): any => {
    let borrowerFieldsConfig: any = {};
    borrowerFieldsConfig["borrower.firstName"] = new FieldConfig("borrower.firstName", "First Name");
    borrowerFieldsConfig["borrower.lastName"] = new FieldConfig("borrower.lastName", "Last Name");
    borrowerFieldsConfig["borrower.middleName"] = new FieldConfig("borrower.middleName", "Middle Name");
    borrowerFieldsConfig["borrower.middleName"].isRequired = false;
    borrowerFieldsConfig["borrower.nameSuffix"] = new FieldConfig("borrower.nameSuffix", "Suffix");
    borrowerFieldsConfig["borrower.nameSuffix"].isRequired = false;
    borrowerFieldsConfig["borrower.primaryEmail"] = new FieldConfig("borrower.primaryEmail", "E-mail");
    borrowerFieldsConfig["borrower.mobilePhone"] = new FieldConfig("borrower.mobilePhone", "Phone Number");
    borrowerFieldsConfig["borrower.dateOfBirth"] = new FieldConfig("borrower.dateOfBirth", "Birth Date");
    borrowerFieldsConfig["borrower.socialSecNum"] = new FieldConfig("borrower.socialSecNum", "Social Security Number");
    borrowerFieldsConfig["borrower.declarations.residenceStatus"] = new FieldConfig("borrower.declarations.residenceStatus", "Citizenship");
    borrowerFieldsConfig["borrower.maritalStatus"] = new FieldConfig("borrower.maritalStatus", "Marital Status");
    return borrowerFieldsConfig;
  }

  public static generateAddressFieldsConfig = (): any => {
    let addressFieldsConfig: any = {};
    addressFieldsConfig["residencyAddress.durationYears"] = new FieldConfig("residencyAddress.durationYears", "Year(s)");
    addressFieldsConfig["residencyAddress.durationMonths"] = new FieldConfig("residencyAddress.durationMonths", "Month(s)");
    addressFieldsConfig["residencyAddress.residencyBasis"] = new FieldConfig("residencyAddress.residencyBasis", "Ownership Status");
    addressFieldsConfig["residencyAddress.rent"] = new FieldConfig("residencyAddress.rent", "Monthly Payment");
    return addressFieldsConfig;
  }

  public static generateDefaultFieldConfigForStep = (stepType: StepType): any => {
    let fieldConfig: any = {}
    if (stepType === StepType.MyInfoStep) {

    } else if (stepType === StepType.ManualIncomeAndEmploymentStep) {
      fieldConfig = FieldConfigUtils.generateIncomeStepFieldConfig();
    } else if (stepType === StepType.ManualAssetsStep) {
      fieldConfig = FieldConfigUtils.generateAssetsStepFieldConfig();
    } else if (stepType === StepType.ReoStep) {
      fieldConfig = FieldConfigUtils.generateReoStepFieldConfig();
    } else if (stepType === StepType.AddressHistoryStep) {
      fieldConfig = FieldConfigUtils.generateAddressFieldsConfig();
    } else if (stepType === StepType.LoanTypeStep) {
      fieldConfig = FieldConfigUtils.generateLoanInfoStepFieldConfig();
    } else if (stepType === StepType.PurchaseCreditsStep) {
      fieldConfig = FieldConfigUtils.generatePurchaseCreditsStepFieldConfig();
    } else if (stepType === StepType.ReviewApplicationStep) {
      fieldConfig = FieldConfigUtils.generateReviewApplicationStepFieldConfig();
    } else if (stepType === StepType.CreateCoBorrowersStep) {
      fieldConfig = FieldConfigUtils.generateCoBorrowerFieldsConfig();
    }
    return fieldConfig;
  }

  public static isHidden = (fieldConfig: any, fieldName: string): boolean => {
    if (fieldConfig[fieldName]) {
      return fieldConfig[fieldName].isHidden;
    }
    return false;
  }

  public static isRequied = (fieldConfig: any, fieldName: string): boolean => {
    if (fieldConfig[fieldName]) {
      return fieldConfig[fieldName].isRequied;
    }
    return false;
  }
}

// Just to trigger a build
