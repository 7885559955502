import { IDecideNextStep } from "../../services/wizard/next-step-decider/next-step-decider.interface";
import { MyInfoStepComponent } from "../../wizard/wizard-steps/my-info-step/my-info-step.component";
import { WizardStepBase } from "./wizard-step-base.model";

export class MyInfoStep extends WizardStepBase {

  configuresAddressHistory: boolean = true;

  configuresCoborrowers: boolean = true;

  restrictMiddleNameToSingleCharacter: boolean = false;

  borrowerFieldsConfig: any = {};

  addressFieldsConfig: any = {};

  constructor(id: string, name: string | undefined, path: string, nextStepDecider?: IDecideNextStep | undefined) {
    super(id, name, path, nextStepDecider);
    this.component = MyInfoStepComponent;
    this.hasCustomFieldConfigEditor = true;
  }

  get fieldConfig() {
    return {...this.borrowerFieldsConfig, ...this.addressFieldsConfig};
  }
}
