<link rel="stylesheet" type="text/css" [href]="overrideCssUrl | safeUrl">
<ng-template #template>
  <header [userInfo]="userInfo"></header>
  <div class="content d-flex flex-column flex-column-fluid">
    <div class="post d-flex flex-column-fluid">
      <div class="container my-20">
        <div class="row my-20 gy-5 g-xl-8 mobile-margins-top-bottom">
          <div class="col-xxl-12">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
  <footer [isDefaultFlow]="isDefaultFlow" [industryAffiliations]="industryAffiliations"></footer>
</ng-template>
