import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { GeneratePrequalLetterRequestModel } from 'projects/borrower-app/src/app/models/borrower-portal/generate-prequal-letter-request.model';
import { EnumsService } from 'projects/borrower-app/src/app/services/enums.service';
import { PortalServiceFactory } from 'projects/borrower-app/src/app/services/portal-service.factory';
import { EnumerationItem } from 'projects/shared/models/enumeration-item.model';
import { PortalBaseComponent } from '../../../portal-base.component';

@Component({
  selector: 'prequal-letter-wizard-property-address',
  templateUrl: './prequal-letter-wizard-property-address.component.html'
})
export class PrequalLetterWizardPropertyAddressComponent extends PortalBaseComponent implements OnInit {

  @ViewChild('propertyAddressForm') propertyAddressForm: NgForm | undefined;

  @Input() request: GeneratePrequalLetterRequestModel
  @Input() appId: number;

  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() next: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() back: EventEmitter<boolean> = new EventEmitter<boolean>();

  states: EnumerationItem[] = [];
  stateChangingEnabled: boolean = true;

  constructor(
    private readonly portalServiceFactory: PortalServiceFactory,
    private readonly _enumsService: EnumsService
  ) {
    super(portalServiceFactory);
    this.states = this._enumsService.stateNames;
  }

  ngOnInit(): void {
    this.loadApplicationDetails(this.appId);
  }

  loadApplicationDetails = (appId: number) => {
    this.portalService.getLoanDataForAppId(appId).subscribe(application => {
      this.request.address = application.address;
      this.request.city = application.city;
      this.request.zip = application.zip;
      this.request.state = this.states.find(s => s.name.toLowerCase() == application.state?.toLowerCase())?.value;

      this.stateChangingEnabled = !application.state;
    })
  }

  nextClicked = () => {
    this.propertyAddressForm.form.markAllAsTouched();
    if (this.propertyAddressForm.form.valid) {
      this.next.emit();
    }
  }

  closeClicked = () => {
    this.close.emit();
  }

  backClicked = () => {
    this.propertyAddressForm.form.markAllAsTouched();
    if (this.propertyAddressForm.form.valid) {
      this.back.emit();
    }
  }
}
