import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'duotone-warning-circle-svg-icon',
  template: `<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
              <circle id="Oval-5" fill="#000000" opacity="0.3" cx="12" cy="12" r="10"></circle>
                <rect id="Rectangle-9" fill="#000000" x="11" y="7" width="2" height="8" rx="1"></rect>
                <rect id="Rectangle-9-Copy" fill="#000000" x="11" y="16" width="2" height="2" rx="1"></rect>
            </svg>`
})

export class DuotoneWarningCircleSvgIconComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
