import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['../file-upload.component.scss'],
})
export class FileListComponent implements OnInit {
  @Input() files: any[] = [];
  @Input() isUploading: boolean = false;

  constructor() {}

  ngOnInit() {}

  deleteFile(index: number) {
    if (this.isUploading) {
      console.log("Upload in progress.");
      return;
    }
    this.files.splice(index, 1);
  }

  formatBytes(bytes: number, decimals = 2)
  {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
}
