<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path opacity="0.25" d="M14 12C14 10.8954 14.8954 10 16 10H20C21.1046 10 22 10.8954 22 12V20C22 21.1046 21.1046 22 20 22H16C14.8954 22 14 21.1046 14 20V12Z" fill="#12131A"/>
  <path d="M19 15C19 14.4477 18.5523 14 18 14C17.4477 14 17 14.4477 17 15C17 15.5523 17.4477 16 18 16C18.5523 16 19 15.5523 19 15Z" fill="#12131A"/>
  <path d="M19 18C19 17.4477 18.5523 17 18 17C17.4477 17 17 17.4477 17 18C17 18.5523 17.4477 19 18 19C18.5523 19 19 18.5523 19 18Z" fill="#12131A"/>
  <path opacity="0.25" d="M2 4C2 2.89543 2.89543 2 4 2H10C11.1046 2 12 2.89543 12 4V20C12 21.1046 11.1046 22 10 22H4C2.89543 22 2 21.1046 2 20V4Z" fill="#12131A"/>
  <path d="M9 7C9 6.44772 8.55228 6 8 6H6C5.44772 6 5 6.44772 5 7C5 7.55228 5.44772 8 6 8H8C8.55228 8 9 7.55228 9 7Z" fill="#12131A"/>
  <path d="M9 11C9 10.4477 8.55228 10 8 10H6C5.44772 10 5 10.4477 5 11C5 11.5523 5.44772 12 6 12H8C8.55228 12 9 11.5523 9 11Z" fill="#12131A"/>
  <path d="M8 14C8.55228 14 9 14.4477 9 15C9 15.5523 8.55228 16 8 16H6C5.44772 16 5 15.5523 5 15C5 14.4477 5.44772 14 6 14H8Z" fill="#12131A"/>
  <path d="M9 19C9 18.4477 8.55228 18 8 18H6C5.44772 18 5 18.4477 5 19V21.5C5 21.7761 5.22386 22 5.5 22H8.5C8.77614 22 9 21.7761 9 21.5L9 19Z" fill="#12131A"/>
  </svg>
