import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { EnumerationItem } from 'projects/shared/models/enumeration-item.model';
import { TurboInsuranceGetQuoteRequest } from '../../models/turbo-insurance/get-quote-request.model';
import { EnumsService } from '../../services/enums.service';
import { TurboInsuranceService } from '../../services/turbo-insurance.service';
import { TurboInsurancePropertyInfoEditorComponent } from './turbo-insurance-property-info-editor/turbo-insurance-property-info-editor.component';

@Component({
  selector: 'turbo-insurance-quote-dialog',
  templateUrl: 'turbo-insurance-quote-dialog.component.html'
})

export class TurboInsuranceQuoteDialogComponent implements OnInit {

  @ViewChild('quoteForm') quoteForm: NgForm | undefined;

  @ViewChild('quotePropertyInfoEditor')
  quotePropertyInfoEditor: TurboInsurancePropertyInfoEditorComponent | undefined;

  @ViewChild('priorPropertyInfoEditor')
  priorPropertyInfoEditor: TurboInsurancePropertyInfoEditorComponent | undefined;

  @Input()
  applicationId: number | undefined;

  @Input()
  quoteRequest: TurboInsuranceGetQuoteRequest = new TurboInsuranceGetQuoteRequest();

  currentView: QuoteView = QuoteView.Config;

  turboInsurancePropertyTypes: EnumerationItem[] = [];
  turboInsuranceOccupancyTypes: EnumerationItem[] = [];

  prepareQuotesButtonText = "Prepare Quotes";

  isPreparingQuotes: boolean = false;

  errorMessage: string | null = null;

  constructor(public activeModal: NgbActiveModal,
    private readonly _turboInsuranceService: TurboInsuranceService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _enumsService: EnumsService) {
      this.turboInsurancePropertyTypes = this._enumsService.tueboInsurancePropertyTypes;
      this.turboInsuranceOccupancyTypes = this._enumsService.tueboInsuranceOccupancyTypes;
  }

  ngOnInit() {

  }

  onBackClicked = () => {
    this.currentView = QuoteView.Config;
  }

  onGenerateQuotesClicked = () => {
    this.quoteForm?.form.markAllAsTouched();
    if (this.quoteForm?.form.valid && this.quotePropertyInfoEditor?.validate() && this.priorPropertyInfoEditor?.validate()) {
      if (this.applicationId) {
        this.errorMessage = null;
        this._spinner.show();
        this.prepareQuotesButtonText = "Preparing Quotes...";
        this.isPreparingQuotes = true;
        this._turboInsuranceService.saveQuoteRequest(this.applicationId, this.quoteRequest).subscribe(() => {
          this._turboInsuranceService.getQuotes(this.applicationId as number).subscribe(() => {
            this._spinner.hide();
            this.currentView = QuoteView.Quotes;
          }, error => {
            this.errorMessage = error.error && error.error.message ? error.error.message : "An error occurred while preparing quotes.";
            this._spinner.hide();
            this.prepareQuotesButtonText = "Prepare Quotes";
            this.isPreparingQuotes = false;
          });
        }, error => {
          this.errorMessage = error.error && error.error.message ? error.error.message : "An error occurred while preparing quotes.";
          this._spinner.hide();
          this.prepareQuotesButtonText = "Prepare Quotes";
          this.isPreparingQuotes = false;
        })
      }
    }
  }
}

export enum QuoteView {
  Config = 1,
  Quotes = 2
}

