<div class="liabilities-container mb-4">
  <ng-container *ngIf="liabilitiesToPayOff && liabilitiesToPayOff.length">
    <div class="d-flex align-items-center mb-4" *ngFor="let liability of liabilitiesToPayOff">
      <div class="flex-grow-1">
        <span class="d-inline-block text-truncate text-gray-800 fw-bold fs-6" [title]="liability.holderName">{{liability.holderName}}</span>
      </div>
      <span class="text-muted fw-semibold d-block">{{(liability.monthlyPayment || 0) | currency}}/{{(liability.unpaidBalance || 0) | currency}}</span>
    </div>
  </ng-container>
  <div class="d-flex justify-content-center flex-wrap mb-4" *ngIf="!liabilitiesToPayOff || !liabilitiesToPayOff.length">
    <span style="width: 130px;" class="text-muted fw-semibold d-block">Liabilities to Pay Off</span>
  </div>
</div>

<div class="d-flex align-items-center mb-4">
  <div class="flex-grow-1">
    <span class="text-gray-800 fw-bold fs-6">Total Debt Payed Off</span>
  </div>
  <span class="text-muted fw-semibold d-block">{{(totalMonthlyPayment || 0) | currency}}/{{(totalDebtToPayOff || 0) | currency}}</span>
</div>

<div class="d-flex align-items-center mb-4">
  <div class="flex-grow-1">
    <span class="text-gray-800 fw-bold fs-6">Total Closing Costs</span>
  </div>
  <span class="text-muted fw-semibold d-block">{{(totalClosingCosts || 0) | currency}}</span>
</div>

<div class="d-flex align-items-center liabilities-container">
  <div class="flex-grow-1 mb-4">
    <span class="text-gray-800 fw-bold fs-6">Cash to Borrower</span>
  </div>
  <span class="text-muted fw-semibold d-block cash-to-borrower">{{(cashToBorrower || 0) | currency}}</span>
</div>

<div class="d-flex align-items-center">
  <div class="flex-grow-1 mb-4 mt-4">
    <span class="text-gray-800 fw-bold fs-6">New Payment</span>
  </div>
  <span class="text-muted fw-semibold d-block">{{(quote?.principalAndInterest || 0) | currency}}</span>
</div>

<div class="d-flex align-items-center" *ngIf="savingsAfterRefinance > 0">
  <div class="flex-grow-1 mb-4">
    <span class="text-gray-800 fw-bold fs-6">Savings after Refi</span>
  </div>
  <span class="text-muted fw-semibold d-block cash-to-borrower">{{(savingsAfterRefinance || 0) | currency}}</span>
</div>

