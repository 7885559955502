import { NgModule } from "@angular/core";
import { DuotoneBankSvgIconComponent } from "./svg-icons/duotone/bank/duotone-bank-svg-icon.component";
import { DuotoneBuilding2SvgIconComponent } from "./svg-icons/duotone/building_/duotone-building2-svg-icon.component";
import { DuotoneBriefcaseSvgIconComponent } from "./svg-icons/duotone/business/briefcase/duotone-briefcase-svg-icon.component";
import { DuotoneCoinsSvgIconComponent } from "./svg-icons/duotone/coins/duotone-coins-svg-icon.component";
import { DuotoneDollarSvgIconComponent } from "./svg-icons/duotone/dollar/duotone-dollar-svg-icon.component";
import { DuotoneDoneCircleSvgIconComponent } from "./svg-icons/duotone/done-circle/duotone-done-circle-svg-icon.component";
import { DuotoneEnvelopeSvgIconComponent } from "./svg-icons/duotone/envelope/duotone-envelope-svg-icon.component";
import { DuotoneHomeSvgIconComponent } from "./svg-icons/duotone/home/duotone-home-svg-icon.component";
import { DuotoneMailSvgIconComponent } from "./svg-icons/duotone/mail/duotone-mail-svg-icon.component";
import { DuotoneMapMarkerSvgIconComponent } from "./svg-icons/duotone/map-marker/duotone-map-marker-svg-icon.component";
import { DuotoneNavigationLeftSvgIconComponent } from "./svg-icons/duotone/navigation-left/duotone-navigation-left-svg-icon.component";
import { DuotoneNavigationRightSvgIconComponent } from "./svg-icons/duotone/navigation-right/duotone-navigation-right-svg-icon.component";
import { DuotoneNavigationUpSvgIconComponent } from "./svg-icons/duotone/navigation-up/duotone-navigation-up-svg-icon.component";
import { DuotoneNavAngleRightComponent } from "./svg-icons/duotone/navigation/duotone-angle-right-svg-icon.component";
import { DuotonePencilSvgIconComponent } from "./svg-icons/duotone/pencil_/duotone-pencil-svg-icon.component";
import { DuotoneSafeSvgIconComponent } from "./svg-icons/duotone/safe/duotone-safe-svg-icon.component";
import { DuotoneShieldCheckSvgIconComponent } from "./svg-icons/duotone/shield-check/duotone-shield-check-svg-icon.component";
import { DuotoneShirtSvgIconComponent } from "./svg-icons/duotone/shirt/duotone-shirt-svg-icon.component";
import { DuotoneStarSvgIconComponent } from "./svg-icons/duotone/star/duotone-star-svg-icon.component";
import { DuotoneToolsSvgIconComponent } from "./svg-icons/duotone/tools/duotone-tools-svg-icon.component";
import { DuotoneTrashSvgIconComponent } from "./svg-icons/duotone/trash/duotone-trash-svg-icon.component";
import { DuotoneWalletSvgIconComponent } from "./svg-icons/duotone/wallet/duotone-wallet-svg-icon.component";
import { DuotoneWarningCircleSvgIconComponent } from "./svg-icons/duotone/warning-circle/duotone-warning-circle-svg-icon.component";
import { DuotoneWriteSvgIconComponent } from "./svg-icons/duotone/write/duotone-write-svg-icon.component";
import { ClipboardSvgIconComponent } from "./svg-icons/general/clipboard-svg-icon-component";
import { CloseXSvgiconComponent } from "./svg-icons/general/close-x-svg-icon.component";
import { CompassSvgIconComponent } from "./svg-icons/general/compass.svg-icon.component";
import { LaptopSvgIconComponent } from "./svg-icons/general/laptop-svg-icon.component";
import { NavigationArrowRightSvgIconComponent } from "./svg-icons/general/navigation-arrow-right-svg-icon.component";
import { UserSvgIconComponent } from "./svg-icons/general/user-svg-icon.component";
import { StockholmCheckSvgIconComponent } from "./svg-icons/stockholm/check/stockholm-check-svg-icon.component";
import { StockholmCloseSvgIconComponent } from "./svg-icons/stockholm/close/stockholm-close-svg-icon.component";
import { StockholmShieldDisabledSvgIconComponent } from "./svg-icons/stockholm/shield-disabled/stockholm-shield-disabled-svg-icon.component";


@NgModule({
    imports: [],
    declarations: [
        DuotoneCoinsSvgIconComponent,
        DuotoneStarSvgIconComponent,
        DuotoneSafeSvgIconComponent,
        DuotoneShirtSvgIconComponent,
        DuotoneWalletSvgIconComponent,
        DuotoneToolsSvgIconComponent,
        DuotoneHomeSvgIconComponent,
        DuotoneBankSvgIconComponent,
        DuotoneDollarSvgIconComponent,
        DuotoneTrashSvgIconComponent,
        DuotoneWriteSvgIconComponent,
        DuotoneNavigationRightSvgIconComponent,
        DuotoneNavigationLeftSvgIconComponent,
        DuotoneWarningCircleSvgIconComponent,
        DuotoneShieldCheckSvgIconComponent,
        DuotoneMailSvgIconComponent,
        DuotoneEnvelopeSvgIconComponent,
        DuotoneMapMarkerSvgIconComponent,
        DuotoneNavAngleRightComponent,
        DuotonePencilSvgIconComponent,
        DuotoneNavigationUpSvgIconComponent,
        DuotoneBriefcaseSvgIconComponent,
        DuotoneBuilding2SvgIconComponent,
        StockholmShieldDisabledSvgIconComponent,
        UserSvgIconComponent,
        CompassSvgIconComponent,
        LaptopSvgIconComponent,
        ClipboardSvgIconComponent,
        NavigationArrowRightSvgIconComponent,
        CloseXSvgiconComponent,
        StockholmCloseSvgIconComponent,
        StockholmCheckSvgIconComponent,
        DuotoneDoneCircleSvgIconComponent
    ],
    exports: [
        DuotoneCoinsSvgIconComponent,
        DuotoneStarSvgIconComponent,
        DuotoneSafeSvgIconComponent,
        DuotoneShirtSvgIconComponent,
        DuotoneWalletSvgIconComponent,
        DuotoneToolsSvgIconComponent,
        DuotoneHomeSvgIconComponent,
        DuotoneBankSvgIconComponent,
        DuotoneDollarSvgIconComponent,
        DuotoneTrashSvgIconComponent,
        DuotoneWriteSvgIconComponent,
        DuotoneNavigationRightSvgIconComponent,
        DuotoneNavigationLeftSvgIconComponent,
        DuotoneWarningCircleSvgIconComponent,
        DuotoneShieldCheckSvgIconComponent,
        DuotoneMailSvgIconComponent,
        DuotoneEnvelopeSvgIconComponent,
        DuotoneMapMarkerSvgIconComponent,
        DuotoneNavAngleRightComponent,
        DuotonePencilSvgIconComponent,
        DuotoneNavigationUpSvgIconComponent,
        DuotoneBriefcaseSvgIconComponent,
        DuotoneBuilding2SvgIconComponent,
        StockholmShieldDisabledSvgIconComponent,
        UserSvgIconComponent,
        CompassSvgIconComponent,
        LaptopSvgIconComponent,
        ClipboardSvgIconComponent,
        NavigationArrowRightSvgIconComponent,
        CloseXSvgiconComponent,
        StockholmCloseSvgIconComponent,
        StockholmCheckSvgIconComponent,
        DuotoneDoneCircleSvgIconComponent
    ],
    providers: []
})
export class SvgIconsModule {
  constructor() {

  }
}
