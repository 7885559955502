import { ContextType } from "./config/context-type.enum";
import { NextStepDeciderConfig } from "./config/next-step-decider-config.model";
import { StepConfig } from "./config/step-config.model";
import { StepType } from "./config/step-type.enum";

export class GenericStepConfig extends StepConfig {

  constructor(id: string, name: string | undefined, path: string, groupId: number | undefined,
    stepType: StepType, nextStepDeciderConfig: NextStepDeciderConfig | undefined) {
    super(id, name, path, groupId, ContextType.None);
    this.stepType = stepType;
    this.contextType = ContextType.None;
    this.nextStepDeciderConfig = nextStepDeciderConfig;
  }
}
