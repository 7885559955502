<div class="modal-header">
  <h5 class="modal-title d-flex align-items-center">Verify Your Assets</h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>

<div class="modal-body">
  <digital-assets-verification 
    *ngIf="voaEnabled && thirdPartyEnrollmentWidget" 
    (complete)="activeModal.close()" 
    (close)="activeModal.close()" 
    [applicationId]="applicationId"
    [borrowerId]="task.borrowerId"
    [taskId]="task.taskId"
    [provider]="provider"
    [thirdPartyEnrollmentWidget]="thirdPartyEnrollmentWidget">
  </digital-assets-verification>
  <div class="card-px text-center py-20 my-10" *ngIf="!voaEnabled">
    <h2 class="fs fw-bold mb-8">Digital Assets Not Available</h2>
    <p class="text-gray-400 fs-4 fw-semibold mb-10">We are not able to pull your assets digitally at the moment.
      <!-- <br>You will have to tell us about your assets manually.</p>
    <a (click)="onSpecifyAssetsClicked()" class="btn btn-primary">Tell Us About Your Assets</a> -->
  </div>
</div>
