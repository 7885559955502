<form #createStepForm="ngForm" class="form" novalidate id="createStepForm" name="createStepForm">
  <div class="me-n7 pe-7">
    <div class="row mb-6">
      <label class="col-lg-4 col-form-label fw-bold">
        <span class="required">Type of Step to Create</span>
      </label>
      <div class="col-lg-8 fv-row">
        <select name="stepType" id="stepType" required data-placeholder="Choose one" [(ngModel)]="stepTypeForNewStep"
          (ngModelChange)="onStepTypeChanged()" class="form-select form-select-solid">
          <option value="">Select one</option>
          <option *ngFor="let type of stepTypes" value="{{type.id}}">{{type.name}}</option>
        </select>
      </div>
    </div>
    <ng-container *ngIf="stepTypeForNewStep.length > 0">
      <div class="row mb-6"
        *ngIf="existingStepsOfTheSameTypeInFlow.length > 0 && existingStepsOfTheSameTypeInDefaultFlow.length === 0">
        <label class="col-lg-10 col-form-label fw-bold">
          <span>A step with the same type already exists in the flow. Would you like to copy it?</span>
        </label>
        <div class="col-sm-2 form-check form-switch form-check-custom form-check-solid" style="margin-top: 8px;">
          <input class="form-check-input h-20px w-30px" type="checkbox" id="flexSwitch20x30"
            [ngModelOptions]="{standalone: true}" [(ngModel)]="copyExistingStep" />
          <label class="form-check-label" for="flexSwitch20x30">
            {{copyExistingStep ? "Yes" : "No"}}
          </label>
        </div>
      </div>
      <div class="row mb-6"
        *ngIf="existingStepsOfTheSameTypeInFlow.length > 0 && existingStepsOfTheSameTypeInDefaultFlow.length > 0">
        <label class="col-lg-7 col-form-label fw-bold">
          <span>A step with the same type already exists in both the current flow and the default. Would you like to copy
            one?</span>
        </label>
        <div class="col-sm-5 form-check form-switch form-check-custom form-check-solid" style="margin-top: 8px;">
          <select name="stepCreationMethod" id="stepCreationMethod" required data-placeholder="Choose one"
            [(ngModel)]="stepCreationMethod" class="form-select form-select-solid"
            (ngModelChange)="onStepCreationMethodChanged()">
            <option value="1">No, create brand new</option>
            <option value="2">Yes, copy the one in this flow</option>
            <option value="3">Yes, copy the one in default flow</option>
          </select>
        </div>
      </div>
      <div class="row mb-6"
        *ngIf="existingStepsOfTheSameTypeInDefaultFlow.length > 0 && existingStepsOfTheSameTypeInFlow.length === 0">
        <label class="col-lg-10 col-form-label fw-bold">
          <span>A step with the same type already exists in the default flow. Would you like to copy it?</span>
        </label>
        <div class="col-sm-2 form-check form-switch form-check-custom form-check-solid" style="margin-top: 8px;">
          <input class="form-check-input h-20px w-30px" type="checkbox" id="flexSwitch20x30"
            [ngModelOptions]="{standalone: true}" [(ngModel)]="copyExistingDefaultStep" (ngModelChange)="onCopyExistingDefaultStepSelectionChanged()" />
          <label class="form-check-label" for="flexSwitch20x30">
            {{copyExistingDefaultStep ? "Yes" : "No"}}
          </label>
        </div>
      </div>
      <div class="row mb-6" *ngIf="stepCreationMethod && stepCreationMethod != '1'">
        <div class="col-md-6 fv-row">
          <label class="form-label mb-2 required">{{stepCreationMethod == '2' ? 'Step to Copy' : 'Default Step to Copy'}}</label>
          <select name="stepToCopy" id="stepToCopy" required data-placeholder="Choose one"
            [disabled]="stepsToChooseFromForCopying.length === 1"
            [(ngModel)]="stepIdToCopy"
            (ngModelChange)="onStepToCopyChanged()" #stepToCopy="ngModel"
            [ngClass]="{'is-invalid' : stepToCopy && stepToCopy.touched && stepToCopy.invalid}"
            class="form-select form-select-solid">
            <option [ngValue]="null">Select one</option>
            <option [ngValue]="step.id" *ngFor="let step of stepsToChooseFromForCopying">{{step.name}} ({{step.path}})</option>
          </select>
          <div class="invalid-feedback">Step to copy is required.</div>
        </div>
        <div class="col-md-6 fv-row">

        </div>
      </div>
      <ng-container *ngIf="step">
        <div class="row mb-6" *ngIf="flow.steps.length > 0">
          <div class="col-md-6 fv-row">
            <label class="form-label mb-2 required">Insert After</label>
            <select name="insertAfter" id="insertAfter" required data-placeholder="Choose one" [(ngModel)]="stepIdToInsertAfter"
              #insertAfter="ngModel" [ngClass]="{'is-invalid' : insertAfter && insertAfter.touched && insertAfter.invalid}"
              class="form-select form-select-solid">
              <option value="">Select one</option>
              <option value="-1">As First Step</option>
              <option value="{{step.id}}" *ngFor="let step of flow.steps">{{step.name}}</option>
            </select>
            <div class="invalid-feedback">You need to pick the step to insert after.</div>
          </div>
          <div class="col-md-6 fv-row">
            <label class="form-label mb-2">Put Into Group</label>
            <div>
              <p-dropdown name="groupIdToTputStepInto"
                [required]="false"
                #stepGroup="ngModel"
                styleClass="{{
                  stepGroup && stepGroup.touched && stepGroup.invalid ? 'is-invalid' : ''
                }}"
                [options]="stepGroups" optionLabel="name" [(ngModel)]="groupToPutStepInto"
                placeholder="Select one">
                <ng-template pTemplate="selectedItem">
                  <div class="flex align-items-center">
                    <i class="fas fa-circle me-2" [ngStyle]="{color: stepGroupColors[groupToPutStepInto.value]}"></i><span>{{ groupToPutStepInto.name }}</span>
                  </div>
                </ng-template>
                <ng-template let-group pTemplate="item">
                  <div class="flex align-items-center">
                    <i class="fas fa-circle me-2" [ngStyle]="{color: stepGroupColors[group.value]}"></i><span>{{ group.name }}</span>
                  </div>
                </ng-template>
              </p-dropdown>
              <div class="invalid-feedback d-block" *ngIf="stepGroup && stepGroup.touched && stepGroup.invalid">You need to pick a group.</div>
            </div>

            <!-- <select name="groupIdToTputStepInto" id="groupIdToTputStepInto" data-placeholder="Choose one"
              [(ngModel)]="groupIdToTputStepInto"
              #groupId="ngModel" [ngClass]="{'is-invalid' : groupId && groupId.touched && groupId.invalid}"
              class="form-select form-select-solid">
              <option value="">Select one</option>
              <option [ngValue]="1">&#xf042; My Info</option>
              <option [ngValue]="2">My Finances</option>
              <option [ngValue]="3">Property & Loan</option>
              <option [ngValue]="4">Declarations</option>
              <option [ngValue]="5">Review & Submit</option>
            </select> -->
          </div>
        </div>
        <div class="row mb-6">
          <div class="col-md-6 fv-row">
            <label class="form-label mb-2">Id</label>
            <input class="form-control form-control-solid" [disabled]="true" [(ngModel)]="step.id" name="id" />
          </div>
          <div class="col-md-6 fv-row">
            <label class="form-label mb-2 required">Name</label>
            <input class="form-control form-control-solid" required
              [ngClass]="{'is-invalid' : name && name.touched && name.invalid}" #name="ngModel" [(ngModel)]="step.name"
              placeholder="Name" name="name" />
            <div class="invalid-feedback">Name is required.</div>
          </div>
        </div>
        <div class="row mb-6">
          <div class="col-md-6 fv-row">
            <label class="form-label mb-2">Path</label>
            <input class="form-control form-control-solid" required [disabled]="stepCreationMethod == '1' || stepCreationMethod == '2'"
              [ngClass]="{'is-invalid' : path && path.touched && path.invalid}" #path="ngModel" [(ngModel)]="step.path"
              placeholder="Path" name="path" />
            <div class="invalid-feedback">Path is required.</div>
          </div>
          <div class="col-md-6 fv-row" *ngIf="step.nextStepDeciderConfig">
            <label class="form-label mb-2 required">Next Step Decider</label>
            <select name="nextStepDecider" id="nextStepDecider" required data-placeholder="Choose one"
              [(ngModel)]="step.nextStepDeciderConfig!.deciderType" class="form-select form-select-solid">
              <option value="2">Simple</option>
              <option value="1">Expression</option>
            </select>
            <div class="invalid-feedback">Next Step Decider is required.</div>
          </div>
        </div>
        <div class="row mb-6">
          <div class="col-md-12 fv-row d-flex justify-content-center">
            <a (click)="onCancelClicked()" class="btn btn-sm btn-light">Cancel</a>
            <a (click)="onCreateClicked()" class="btn btn-sm btn-primary mx-4">OK</a>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</form>
