export class FieldConfig {
  name: string = "";
  displayName: string = "";
  isHidden: boolean = false;
  isRequired: boolean = true;

  constructor(name: string, displayName: string) {
    this.name = name;
    this.displayName = displayName;
  }
}
