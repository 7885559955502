import { Injectable } from "@angular/core";
import { Address } from "../models/address-model";
import { Borrower } from "../models/borrower-model";
import { IHaveName } from "../models/have-name.interface";
import { ResidencyAddress } from "../models/residency-address.model";

@Injectable()
export class UtilsService {

  private _presentAddressType: string = "PresentAddress";

  constructor() {
  }

  getUrlParameter = (name: string): string => {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var decodedUrl = decodeURIComponent(location.search);
    var results = regex.exec(decodedUrl);
    return results === null ? '' : decodeURIComponent(results[1]);//.replace(/\+/g, ' '));
  }

  generateUniqueNumericId = (): number => {
    const uniqueId = Date.now() & 0xffffffff;
    return uniqueId < 0 ? uniqueId : (uniqueId * -1);
  }

  getLastUrlSegment = (): string => {
    const urlWithoutQueryString = location.href.split('?')[0];
    return urlWithoutQueryString.substring(urlWithoutQueryString.lastIndexOf('/') + 1);
  }

  getBaseUrl = (): string => {
    const parts = window.location.pathname.split('/');
    if (parts.length) {
      return parts[1];
    }
    return window.location.pathname;
  }

  getCurrentResidencyAddress = (borrower: Borrower): ResidencyAddress => {
    return borrower.residencyAddresses.find(i => i.residencyType == this._presentAddressType)!
  }

  getCurrentAddress = (borrower: Borrower): Address => {
    return borrower.residencyAddresses.find(i => i.residencyType == this._presentAddressType)!.address!;
  }

  setCurrentAddress = (borrower: Borrower, address: Address): void => {
    borrower.residencyAddresses.find(i => i.residencyType == this._presentAddressType)!.address! = address;
  }

  getCurrentAddressStreetName = (borrower: Borrower): string => {
    if (borrower.residencyAddresses) {
      const currentAddress = borrower.residencyAddresses.find(i => i.residencyType == this._presentAddressType);
      if (currentAddress && currentAddress.address && currentAddress.address.address1) {
        return currentAddress.address.address1;
      }
    }
    return "";
  }

  getPersonsFullName = (person: IHaveName): string => {
    if (!person) return "";

    let fullName = "";
    if (person.firstName) {
      fullName = person.firstName;
    }
    if (person.lastName) {
      if (fullName) {
        fullName = fullName + " " + person.lastName;
      } else {
        fullName = person.lastName;
      }
    }
    return fullName;
  }

  formatPhoneNumber = (phoneNumberString: string): string => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return phoneNumberString;
  }

  setZoom = (zoom: number, el: any): void => {
    const transformOrigin = [0, 0];
    var p = ["webkit", "moz", "ms", "o"],
      s = "scale(" + zoom + ")",
      oString = (transformOrigin[0] * 100) + "% " + (transformOrigin[1] * 100) + "%";

    for (var i = 0; i < p.length; i++) {
      el.style[p[i] + "Transform"] = s;
      el.style[p[i] + "TransformOrigin"] = oString;
    }

    el.style["transform"] = s;
    el.style["transformOrigin"] = oString;
  }

  injectJavaScriptAsCode = (script: string) => {
    const scriptElement = document.createElement('script');
    scriptElement.innerHTML = script;
    document.head.appendChild(scriptElement);
  }

  injectJavaScriptAsLink(source: string, isAsync: boolean, defer: boolean, id: string) {
    let scriptLinkElement = document.createElement("script");
    scriptLinkElement.type = "text/javascript";
    scriptLinkElement.async = isAsync;
    scriptLinkElement.defer = defer;
    scriptLinkElement.src = source;
    scriptLinkElement.id = id;
    document.head.appendChild(scriptLinkElement);
  }

  injectNoScriptPixelTracker = (source: string) => {
    const noscriptElement = document.createElement('noscript');
    const imgElement = document.createElement("img");
    imgElement.width = 1;
    imgElement.height = 1;
    imgElement.style.display = "none";
    imgElement.src = source;
    noscriptElement.appendChild(imgElement);
    document.head.appendChild(noscriptElement);
  }

  parseJwt = (token: string): any => {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  getTokenExpireDate = (exp: number) => {
    let expiresDate = new Date(0);
    expiresDate.setUTCMilliseconds(exp * 1000);

    return expiresDate;
  }

  formatISODate = (dateString: string) => {
    const date = new Date(dateString);
    let month: number | string = date.getUTCMonth() + 1;
    if (month < 10) {
      month = '0' + month;
    }
    let day: number | string = date.getUTCDate();
    if (day < 10) {
      day = '0' + day;
    }
    return date.getUTCFullYear() + "-" + month + "-" + day + "T00:00:00.00Z";
  }

  fromISOFormatToLocal = (dateString: string): string => {
    const dateToFormat = new Date(dateString);
    let month: number | string = dateToFormat.getUTCMonth() + 1;
    let day: number | string = dateToFormat.getUTCDate();
    let year = dateToFormat.getUTCFullYear();

    if (month < 10) {
      month = '0' + month;
    }
    if (day < 10) {
      day = '0' + day;
    }
    return month + '/' + day + '/' + year;
  }

  containsDigit = (value: string): boolean => {
    return /\d/.test(value);
  }
}
