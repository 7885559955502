<ng-template #multiBorrowerAddressHistory>
  <div class="card shadow-sm mb-5">
    <div class="card-header">
        <h3 class="card-title">{{mainBorrower.firstName + " " + mainBorrower.lastName}}</h3>
    </div>
    <div class="card-body">
      <borrower-address-history
        #borrowerAddressHistory
        [inEditMode]="isStepBeingEdited"
        [mortgage]="mortgage"
        [borrower]="mainBorrower"
        [reoFieldsToConfig]="reoFieldsToConfig"
        [currentAddressLabel]="'My Home Address'" >
      </borrower-address-history>
    </div>
  </div>

  <ng-container *ngIf="!inCoApplyFlow">
    <ng-container *ngFor="let borrower of coBorrowers">
      <div *ngIf="borrowerSetting(borrower)" class="card shadow-sm mb-5" >
          <div class="card-header">
            <h3 class="card-title">{{borrower.firstName + " " + borrower.lastName}}</h3>
            <a (click)="onCopyFromPrimaryBorrowerClicked(borrower)" class="pull-right mt-6 fs-6">Copy from {{mainBorrower.firstName + " " + mainBorrower.lastName}}</a>
          </div>
          <div class="card-body">
            <borrower-address-history
              #borrowerAddressHistory
              [mortgage]="mortgage"
              [borrower]="borrower"
              [reoFieldsToConfig]="reoFieldsToConfig"
              [currentAddressLabel]="'Current Address'">
            </borrower-address-history>
          </div>
      </div>
    </ng-container>
  </ng-container>

</ng-template>

<wizard-step-template [stepMainTemplate]="multiBorrowerAddressHistory" [step]="step" [stepComponent]="this"></wizard-step-template>
