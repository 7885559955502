<div class="modal-header">
  <h5 class="modal-title"> {{characteristicType === 'LoanCharacteristic' ? 'Loan ': 'Borrower '}} Characteristics Preview </h5>
  <button type="button" class="btn-close" (click)="activeModal.close()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <div class="row" *ngFor="let byCategoryId of tasksPreview | groupBy: 'taskCategoryId'; let i = index">
      <h5 class="card-title mb-2">Tasks triggered by '{{getTaskCategoryName(byCategoryId.key)}}'</h5>
      <hr>
      <div class="mb-4" *ngFor="let byBorrower of byCategoryId.value | groupBy: 'borrowerId'; let j = index;">
        <p style="margin-bottom: 0;" *ngIf="byBorrower.key">For borrower {{getBorrowerName(byBorrower.key)}}</p>
        <div *ngFor="let task of byBorrower.value">
          <input class="form-control mx-2" type="text" [(ngModel)]="task.description" name="description_{{byCategoryId.key}}_{{byBorrower.key}}">
        </div>
      </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="activeModal.close('cancel')" data-bs-dismiss="modal"> Cancel</button>
  <button type="button" (click)="onSaveClicked()" class="btn btn-primary">Save</button>
</div>
