import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FieldConfig } from 'projects/borrower-app/src/app/models/wizard/config/field-config-model';
import { MyInfoStep } from 'projects/borrower-app/src/app/models/wizard/my-info-step.model';
import { FieldConfigEditorComponent } from '../field-config-editor/field-config-editor.component';

@Component({
  selector: 'my-info-step-editor',
  templateUrl: 'my-info-step-editor.component.html'
})

export class MyInfoStepEditorComponent implements OnInit, AfterViewInit {

  @ViewChild("borrowerFieldsConfigEditor") borrowerFieldsConfigEditor: FieldConfigEditorComponent | undefined;

  @ViewChild("addressFieldsConfigEditor") addressFieldsConfigEditor: FieldConfigEditorComponent | undefined;

  @Input()
  step!: MyInfoStep;

  constructor() { }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.borrowerFieldsConfigEditor) {
        const fieldConfigs: FieldConfig[] = this.step.getFieldsToConfig(this.step.borrowerFieldsConfig);
        this.borrowerFieldsConfigEditor.fieldConfigs = fieldConfigs;
      }
      if (this.addressFieldsConfigEditor) {
        const fieldConfigs: FieldConfig[] = this.step.getFieldsToConfig(this.step.addressFieldsConfig);
        this.addressFieldsConfigEditor.fieldConfigs = fieldConfigs;
      }
    });
  }

  ngOnInit() { }
}
