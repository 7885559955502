<div class="modal-header">

  <h2>Existing Account</h2>

  <!-- hack to fool ngbModal autofocus -->
  <input type="text" style="display:none" />

  <div *ngIf="!spinnerVisible" class="btn btn-icon btn-sm btn-active-light-primary ms-2" aria-label="Close"
    (click)="activeModal.dismiss('Cross click')">
    <span class="svg-icon svg-icon-2x">
      <close-x-svg-icon></close-x-svg-icon>
    </span>
  </div>
</div>

<div class="modal-body py-5 px-lg-17">
  <div class="alert alert-info d-flex align-items-center p-5 mb-10">
    <span class="svg-icon svg-icon-2hx svg-icon-info me-3">
      <duotone-shield-check-svg-icon></duotone-shield-check-svg-icon>
    </span>
    <div class="d-flex flex-column" *ngIf="userName">
      <span class="mb-3">It appears you already have an account with us with the user name <strong>{{userName}}</strong>.</span>
    </div>
  </div>
  <div *ngIf="errorMessage" class="alert alert-danger d-flex align-items-center p-5 mb-10">
    <span class="svg-icon svg-icon-2hx svg-icon-danger me-3">
      <duotone-warning-circle-svg-icon></duotone-warning-circle-svg-icon>
    </span>
    <div class="d-flex flex-column">
      <span>{{errorMessage}}</span>
    </div>
  </div>
  <form #loginForm="ngForm" class="form" novalidate id="loginForm" name="loginForm">
    <div class="row mb-4">
      <h5 class="col-md-12 fv-row">
        You also configured a new loan up to here. Are you sure that's your intention, or do you have an existing loan that you'd like to manage?
      </h5>
    </div>
    <div class="row">
      <div class="col-md-12 fv-row">
        <div class="mb-8 d-flex flex-column">
          <div class="form-check form-check-custom form-check-solid form-check-sm flex-fill mb-3">
            <input class="form-check-input" type="radio" [value]="false" name="manageExistingLoans"
              id="manageExistingLoans" [(ngModel)]="applyingForANewLoan">
            <label class="form-check-label" style="font-size:1.1rem" for="manageExistingLoans">Manage My Existing Loans</label>
          </div>
          <div class="form-check form-check-custom form-check-solid form-check-sm flex-fill">
            <input class="form-check-input" type="radio" [value]="true" name="applyingForANewLoan" checked="checked"
              id="applyingForANewLoan" [(ngModel)]="applyingForANewLoan">
            <label class="form-check-label" style="font-size:1.1rem" for="applyingForANewLoan">Apply for a Brand New Loan</label>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!this.createApplicationRequest.password">
      <div class="fv-row mb-5">
        <div class="d-flex flex-stack mb-2">
          <label class="form-label fw-bolder text-dark fs-6 mb-0" for="password">Password</label>
          <a class="link-primary fs-6 fw-bolder" (click)="onForgotPasswordClicked()">Forgot
            Password?</a>
        </div>
        <input class="form-control form-control-lg form-control-solid" type="password" name="password" required
          [ngClass]="{'is-invalid' : userPassword && userPassword.touched && userPassword.invalid}" autocomplete="off"
          [(ngModel)]="password" #userPassword="ngModel" />
        <div class="invalid-feedback">Please enter your password.</div>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer flex-center">
  <button *ngIf="!spinnerVisible" type="button" class="btn btn-white me-3" (click)="activeModal.dismiss()">Cancel</button>
  <button type="submit" class="btn btn-primary" (click)="onLoginClicked()" [disabled]="spinnerVisible">
    <span class="indicator-label">{{loginButtonText}}</span>
    <span *ngIf="spinnerVisible" class="spinner-border spinner-border-sm align-middle ms-2"></span>
  </button>
</div>
