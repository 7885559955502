
export class AddressFields {
	address: string;
	city: string;
	state: string;
	zipCode: string;
	county: string;

	constructor(address: string, city: string, state: string, zipCode: string, county: string) {
		this.address = address;
		this.city = city;
		this.state = state;
		this.zipCode = zipCode;
		this.county = county;
	}
}
