import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EnumerationItem } from '../models/enumeration-item.model';

@Component({
  selector: 'multi-checkbox',
  templateUrl: 'multi-checkbox.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiCheckboxComponent),
      multi: true
    }
  ]
})

export class MultiCheckboxComponent implements ControlValueAccessor {

  @Input()
  options: EnumerationItem[] = [];

  @Input()
  name: string = '';

  checkedStatuses: boolean[] = [];

  onChange: any = () => {};

  onTouch: any = () => {};

  private _selectedValues: string[] = [];

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(selectedValues: string[]) {
    if (!selectedValues) {
      selectedValues = [];
    }
    this._selectedValues = selectedValues;
    this.checkedStatuses = [];
    this.options.forEach(o => {
      const optionSelected = this._selectedValues.find(v => o.value == v);
      this.checkedStatuses.push(optionSelected != undefined);
    });
    this.onChange(this._selectedValues);
  }

  onOptionSelectionChanged(checked: boolean, optionValue: string) {
    const index = this._selectedValues.findIndex(v => v == optionValue);
    if (checked) {
      if (index < 0) {
        this._selectedValues.push(optionValue);
      }
    } else {
      if (index >= 0) {
        this._selectedValues.splice(index, 1);
      }
    }
    this.onChange(this._selectedValues);
  }
}
