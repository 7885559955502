<ng-template #myInfo>
  <form #myInfoForm="ngForm" novalidate id="myInfoForm" name="myInfoForm">

    <my-info-step-editor *ngIf="inEditMode" [step]="step"></my-info-step-editor>

    <div class="fs-4 fw-bolder text-dark">My Info:</div>
    <div class="separator separator-dashed my-3"></div>
    <div class="row g-9 mb-8">
      <div class="col-md-3 fv-row" *ngIf="!isHidden('borrower.firstName')">
        <label class="form-label" [ngClass]="{'required': isRequired('borrower.firstName')}">{{getLabel('borrower.firstName', 'First Name')}}</label>
        <div class="position-relative d-flex align-items-center">
          <input class="form-control form-control-solid" required [(ngModel)]="borrower.firstName" #firstName="ngModel"
            placeholder="First Name" name="firstName"
            [ngClass]="{'is-invalid' : firstName && firstName.touched && firstName.invalid}"
            [required]="isRequired('borrower.firstName')"/>
        </div>
      </div>
      <div class="col-md-3 fv-row" *ngIf="!isHidden('borrower.middleName')">
        <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
          <span [ngClass]="{'required': isRequired('borrower.middleName')}">{{getLabel('borrower.middleName', 'Middle Name')}}
          </span>
        </label>
        <div class="position-relative">
          <input type="text" class="form-control form-control-solid" [(ngModel)]="borrower.middleName"
            [required]="isRequired('borrower.middleName')" placeholder="" name="middleName"
            [attr.maxlength]="step.restrictMiddleNameToSingleCharacter ? 1 : 50"
            [ngClass]="{'is-invalid' : middleName && middleName.touched && middleName.invalid}" #middleName="ngModel" />
          <!-- <div class="invalid-feedback">Please enter a middle name.</div> -->
        </div>
      </div>
      <div class="col-md-3 fv-row" *ngIf="!isHidden('borrower.lastName')">
        <label class="form-label" [ngClass]="{'required': isRequired('borrower.lastName')}">{{getLabel('borrower.lastName', 'Last Name')}}</label>
        <div class="position-relative d-flex align-items-center">
          <input class="form-control form-control-solid" placeholder="Last Name"
            [(ngModel)]="borrower.lastName" #lastName="ngModel" name="lastName"
            [ngClass]="{'is-invalid' : lastName && lastName.touched && lastName.invalid}"
            [required]="isRequired('borrower.lastName')"/>
        </div>
      </div>
      <div class="col-md-3 fv-row" *ngIf="!isHidden('borrower.nameSuffix')">
        <label class="form-label" [ngClass]="{'required': isRequired('borrower.nameSuffix')}">{{getLabel('borrower.nameSuffix', 'Suffix')}}</label>
        <select class="form-select form-select-solid" data-control="select2" name="suffix" #suffix="ngModel" [(ngModel)]="borrower.nameSuffix"
        [ngClass]="{'is-invalid' : suffix && suffix.touched && suffix.invalid}"
        [required]="isRequired('borrower.nameSuffix')">
          <option [ngValue]="null">Select one...</option>
          <option value="Jr">Jr</option>
          <option value="Sr">Sr</option>
          <option value="I">I</option>
          <option value="II">II</option>
          <option value="III">III</option>
        </select>
      </div>
    </div>
    <div class="row g-9 mb-8">
      <div class="col-md-3 fv-row" *ngIf="!isHidden('borrower.mobilePhone')">
        <label class="form-label" [ngClass]="{'required': isRequired('borrower.mobilePhone')}">{{getLabel('borrower.mobilePhone', 'Phone Number')}}</label>
        <div class="position-relative d-flex align-items-center">
          <input class="form-control form-control-solid"  placeholder="" mask='(000) 000-0000'
            #phoneNumber="ngModel"
            [(ngModel)]="borrower.mobilePhone" name="phoneNumber" ngControl="phoneNumber" id="phoneNumber"
            [ngClass]="{'is-invalid' : phoneNumber && phoneNumber.touched && phoneNumber.invalid}"
            [required]="isRequired('borrower.mobilePhone')"/>
        </div>
      </div>
      <div class="col-md-3 fv-row" *ngIf="!isHidden('borrower.dateOfBirth')">
        <label class="form-label" [ngClass]="{'required': isRequired('borrower.dateOfBirth')}">{{getLabel('borrower.dateOfBirth', 'Birth Date')}}</label>
        <div class="position-relative d-flex align-items-center">
          <input class="form-control form-control-solid" matInput mask="M0/d0/0000" [showMaskTyped]="true"
            [specialCharacters]="[ '/' ]" inputmode="numeric" [dropSpecialCharacters]="false" datePicker #birthDate="ngModel"
            [(ngModel)]="borrower.dateOfBirth" placeholder="" name="birthDate" ngControl="birthDate" id="birthDate"
            [ngClass]="{'is-invalid' : birthDate && birthDate.touched && birthDate.invalid}"
            [required]="isRequired('borrower.dateOfBirth')"/>
        </div>
      </div>
      <div class="col-md-3 fv-row" *ngIf="!isHidden('borrower.socialSecNum')">
        <label class="form-label" [ngClass]="{'required': isRequired('borrower.socialSecNum')}">{{getLabel('borrower.socialSecNum', 'Social Security Number')}}</label>
        <div class="position-relative d-flex align-items-center">
          <input class="form-control form-control-solid" matInput mask="000-00-0000" [showMaskTyped]="true"
            [(ngModel)]="borrower.socialSecNum" #socialSecNum="ngModel" [specialCharacters]="[ '-' ]"
            [dropSpecialCharacters]="false" [patterns]="ssnPattern" placeholder="Social Security Number" name="ssn" id="ssn"
            [required]="isRequired('borrower.socialSecNum')" [ngClass]="{'is-invalid' : socialSecNum && socialSecNum.touched && socialSecNum.invalid}" />
        </div>
      </div>
      <div class="col-md-3 fv-row" *ngIf="!isHidden('borrower.declarations.residenceStatus')">
        <label class="form-label" [ngClass]="{'required': isRequired('borrower.declarations.residenceStatus')}">{{getLabel('borrower.declarations.residenceStatus', 'Citizenship')}}</label>
        <select class="form-select form-select-solid" data-control="select2" name="citizenship"
          [(ngModel)]="borrower.declarations!.residenceStatus" #residenceStatus="ngModel"
          [ngClass]="{'is-invalid' : residenceStatus && residenceStatus.touched && residenceStatus.invalid}"
          [required]="isRequired('borrower.declarations.residenceStatus')">
          <option [ngValue]="null" disabled>Select one...</option>
          <option value="USCitizen">US Citizen</option>
          <option value="PermanentResident">Permanent Resident</option>
          <option value="NonPermanentResidentAlien">Non-Permanent Resident Alien</option>
        </select>
      </div>
    </div>
    <div class="row g-9 mb-8">
      <div class="col-md-3 fv-row" *ngIf="!isHidden('borrower.maritalStatus')">
        <label class="form-label" [ngClass]="{'required': isRequired('borrower.maritalStatus')}">{{getLabel('borrower.maritalStatus', 'Marital Status')}}</label>
        <select class="form-select form-select-solid" data-control="select2" name="maritalStatus"
          [(ngModel)]="borrower.maritalStatus" #maritalStatus="ngModel"
          [ngClass]="{'is-invalid' : maritalStatus && maritalStatus.touched && maritalStatus.invalid}"
          [required]="isRequired('borrower.maritalStatus')">
          <option [ngValue]="null" disabled>Select one...</option>
          <option value="Single">Single</option>
          <option value="Married">Married</option>
          <option value="Separated">Separated</option>
          <option value="Other">Other</option>
        </select>
      </div>

      <div class="col-md-3 fv-row" *ngIf="!isHidden('borrower.numberOfDependents')">
        <label class="form-label" [ngClass]="{'required': isRequired('borrower.numberOfDependents')}">{{getLabel('borrower.numberOfDependents', 'Number of Dependents')}}</label>
        <div class="position-relative d-flex align-items-center">
          <input type="number"
            numeric
            [allowNegative]="false"
            class="form-control form-control-solid numeric-without-arrows"
            value="{{borrower.dependents.length}}"
            name="numberOfDependents"
            [(ngModel)]="noOfDependents"
            (ngModelChange)="onNoOfDependentsChanged()"/>
        </div>
      </div>
      <div class="col-md-3 fv-row" *ngIf="!isHidden('borrower.dependentAges')">
        <label class="form-label">{{getLabel('borrower.dependentAges', 'Dependent Ages')}}</label>
        <div class="position-relative d-flex align-items-center">
          <input type="text" class="form-control form-control-solid dependents-info"
            name="dependentAges"
            placeholder="None" name="dependentAges"
            #borrowerDependentAges="ngModel"
            [(ngModel)]="dependentAges"/>
          <span data-bs-toggle="tooltip" data-bs-trigger="hover" class="mx-2" title="Edit Dependents">
            <a (click)="onEditDependentsClicked()" class="btn btn-sm btn-light-primary">Edit Dependents</a>
          </span>
        </div>
      </div>

      <div class="col-md-3 fv-row">
      </div>
    </div>
    <div class="row g-9 mb-8">
      <div class="col-md-3 fv-row" *ngIf="!isHidden('borrower.languagePreference')">
        <label class="form-label" [ngClass]="{'required': isRequired('borrower.languagePreference')}">{{getLabel('borrower.languagePreference', 'Language Preference')}}</label>
        <select class="form-select form-select-solid" data-control="select2" name="languagePreference"
          [(ngModel)]="borrower.languagePreference"  (ngModelChange)="onLanguagePreferenceChanged()" #languagePreference="ngModel"
          [ngClass]="{'is-invalid' : languagePreference && languagePreference.touched && languagePreference.invalid}"
          [required]="isRequired('borrower.languagePreference')">
          <option [ngValue]="null" disabled>Select one...</option>
          <option *ngFor="let lang of languagePreferenceOptions" [ngValue]="lang.value">{{lang.name}}</option>
        </select>
      </div>
      <div class="col-md-3 fv-row" *ngIf="!isHidden('borrower.languagePreferenceOtherDescription') && borrower.languagePreference === 'Other'" >
        <label class="form-label" [ngClass]="{'required': isRequired('borrower.languagePreferenceOtherDescription')}">{{getLabel('borrower.languagePreferenceOtherDescription', 'Specify Language')}}</label>
        <div class="position-relative d-flex align-items-center">
          <input class="form-control form-control-solid"  [(ngModel)]="borrower.languagePreferenceOtherDescription" #languagePreferenceOtherDescription="ngModel"
            placeholder="Specify Language" name="languagePreferenceOtherDescription"
            [ngClass]="{'is-invalid' : languagePreferenceOtherDescription && languagePreferenceOtherDescription.touched && languagePreferenceOtherDescription.invalid}"
            [required]="isRequired('borrower.languagePreferenceOtherDescription')"/>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="step.configuresAddressHistory">
      <borrower-address-history
        [borrower]="borrower"
        [fieldsToConfig]="step.addressFieldsConfig"
        [currentAddressLabel]="'My Home Address'">
      </borrower-address-history>
    </div>

    <div class="row" *ngIf="!inCoApplyFlow && step.configuresCoborrowers">
      <div class="col-sm-4 fs-4 fw-bolder text-dark" style="padding-top: 8px;">Are you applying with anyone else?</div>
      <a class="col-sm-3 btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary mb-8"
        (click)="onAddCoBorrowerClicked()">Add Co-borrower</a>
      <div class="col-sm-5"></div>
    </div>
    <div class="row" *ngIf="step.configuresCoborrowers">
      <div *ngIf="coBorrowers.length > 0" class="fs-4 fw-bolder text-dark">Co-borrowers:</div>
      <div *ngIf="coBorrowers.length > 0" class="separator separator-dashed my-3"></div>
      <div *ngFor="let coBorrower of coBorrowers; let i = index"
        class="rounded border p-10 pb-0 mb-6 d-flex align-items-center flex-wrap ribbon ribbon-top ribbon-vertical"
        [ngClass]="{'border-danger' : coBorrowerToDelete === coBorrower}">
        <div class="ribbon-label bg-white">
          <i *ngIf="coBorrowerToDelete !== coBorrower" class="bi bi-trash fs-2 text-warning"
            (click)="onCoBorrowerDeleteClicked(coBorrower)"></i>
          <i *ngIf="coBorrowerToDelete === coBorrower" class="bi bi-x fs-2 text-danger"
            (click)="onCoBorrowerDeleteCancelClicked(coBorrower)"></i>
          <i *ngIf="coBorrowerToDelete === coBorrower" class="bi bi-check fs-2 text-success" style="padding-right: 4px;"
            (click)="onCoBorrowerDeleteConfirmClicked(coBorrower)"></i>
        </div>
        <borrower-info-mini #borrowerInfo [fieldsToConfig]="step.borrowerFieldsConfig" [borrower]="coBorrower" [borrowerSettings]="borrowerSettings">
        </borrower-info-mini>
      </div>
    </div>

  </form>
</ng-template>

<wizard-step-template [stepMainTemplate]="myInfo" [step]="step" [stepComponent]="this"></wizard-step-template>
