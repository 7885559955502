import { Component, Injector, OnInit } from '@angular/core';
import { Borrower } from '../../../models/borrower-model';
import { MortgageApplicationService } from '../../../services/mortgage-application.service';
import { WizardStepComponentBase } from '../wizard-step-base.component';

@Component({
  selector: 'coborrower-address-history',
  templateUrl: 'coborrowers-address-history.component.html'
})

export class CoborrowersAdressHistoryComponent  extends WizardStepComponentBase<any> implements OnInit {

  constructor(private readonly _mortgageApplicationService: MortgageApplicationService,
    private readonly _injector: Injector) {
    super(_injector);
    this.saveMortgageApplicationBeforeNextStep = true;
  }

  public get coBorrowers(): Borrower[] {
      if (this.mortgageApplication) {
        return this._mortgageApplicationService.getCoBorrowers(this.mortgageApplication);
      }
      return [];
  }

  ngOnInit() { }
}
