import { Injectable, Injector } from "@angular/core";
import { IProvideOptions } from "./options-provider.interface";
import { LoanPurposeOptionsProvider } from "./loan-purpose-options-provider";
import { LoanPurposeOptionsProviderV2 } from "./loan-purpose-options-providerv2";
import { DefaultPropertyTypeOptionsProvider } from "./default-property-type-options-provider";

@Injectable()
export class OptionsProviderFactory {

  constructor(private readonly _injector: Injector) {
  }

  create = (providerType: OptionsProviderTypes): IProvideOptions | null => {
    switch(providerType) {
      case OptionsProviderTypes.LoanPurpose:
        const loanPurposeProvider = this._injector.get(LoanPurposeOptionsProvider);
        return loanPurposeProvider;
      case OptionsProviderTypes.loanPurposeV2:
        const loanPurposeProviderV2 = this._injector.get(LoanPurposeOptionsProviderV2);
        return loanPurposeProviderV2;
      case OptionsProviderTypes.propertyType:
        const propertyTypeProvider = this._injector.get(DefaultPropertyTypeOptionsProvider);
        return propertyTypeProvider;
      default:
        return null;
    }
  }
}

export enum OptionsProviderTypes {
  LoanPurpose = "LoanPurpose",
  loanPurposeV2 = "LoanPurposeV2",
  propertyType = "PropertyType",
}
