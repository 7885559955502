<ng-template #liability>
  <div class="fv-row" id="liabilities-main">
    <div class="row mb-10">
      <div class="col-xl-12 mb-6">
        <div class="row g-9">
          <div class="col-md-4 col-lg-4 col-xxl-4">
            <label (click)="onAddLiabilityClicked()" class="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center">
              <span class="svg-icon svg-icon-2x me-5" style="margin-top: -4px;">
                <duotone-coins-svg-icon></duotone-coins-svg-icon>
              </span>
              <span class="d-block fw-bold text-start">
                <span class="text-dark fw-bolder d-block fs-5 mb-2">Add Debt</span>
                <span class="text-gray-400 fw-bold fs-6"></span>
              </span>
            </label>
          </div>
          <div class="col-md-4 col-lg-4 col-xxl-4"></div>

          <div class="col-md-4 col-lg-4 col-xxl-4"></div>
        </div>
      </div>
    </div>
    <div class="row mb-10">
    </div>
  </div>
</ng-template>

<wizard-step-template [stepMainTemplate]="liability" [step]="step" [stepComponent]="this"></wizard-step-template>
