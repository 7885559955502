import { Injectable } from "@angular/core";
import { IDecideNextStep } from "projects/borrower-app/src/app/services/wizard/next-step-decider/next-step-decider.interface";
import { SingleChoiceQuestionStepComponent } from "projects/borrower-app/src/app/wizard/wizard-steps/single-choice-question-step/single-choice-question-step.component";
import { WizardFlowContext } from "../../../wizard-flow-context.model";
import { SingleChoiceQuestionStep } from "../../single-choice-question-step.model";
import { SingleChoiceQuestionStepConfig } from "../single-choice-question-step-config.model";
import { NextStepDeciderFactory } from "./next-step-decider.factory";
import { StepFactoryBase } from "./step-factory-base";
import { ICreateStep } from "./step-factory.interface";

@Injectable()
export class SingleChoiceQuestionStepFactory extends StepFactoryBase<SingleChoiceQuestionStepConfig> implements ICreateStep {

  constructor(private readonly _nextStepDeciderFactory: NextStepDeciderFactory) {
    super();
  }

  configure(step: SingleChoiceQuestionStep, config: SingleChoiceQuestionStepConfig): void {

    config.title = step.title;
    config.explanation = step.explanation;
    config.heading = step.heading || step.question.text;
    config.groupId = step.groupId;

    config.optionsProviderType = step.optionsProviderType;
    config.nameOfDefaultOptionToSetAndSkip = step.nameOfDefaultOptionToSetAndSkip;

    config.question.options.forEach(optionConfig => {
      const option = step.question.options.find(o => o.name === optionConfig.name);
      if (option) {
        optionConfig.text = option.text;
        optionConfig.explanation = option.explanation
      }
    });
  }

  create = (config: SingleChoiceQuestionStepConfig, wizardFlowContext: WizardFlowContext): SingleChoiceQuestionStep => {
    let nextStepDecider: IDecideNextStep | undefined = this._nextStepDeciderFactory.create(config.nextStepDeciderConfig,
      wizardFlowContext);
    let step = new SingleChoiceQuestionStep(config.id, config.name,
      config.path, config.question, nextStepDecider, config.fieldToSetWithAnswer, wizardFlowContext, config.contextType, SingleChoiceQuestionStepComponent);
    step.title = config.title;
    step.explanation = config.explanation;
    step.heading = config.heading;
    step.groupId = config.groupId;

    step.optionsProviderType = config.optionsProviderType;
    step.nameOfDefaultOptionToSetAndSkip = config.nameOfDefaultOptionToSetAndSkip;
    step.question.text = config.heading || step.question.text;

    step.question.options.forEach(option => {
      const optionConfig = config.question.options.find(o => o.name === option.name);
      if (optionConfig) {
        option.text = optionConfig.text;
        option.explanation = optionConfig.explanation;
      }
    });

    return step;
  }
}
