import { ICharacteristic } from "../../models/characteristics/characteristic.interface";
import { LoanCharacteristic } from "../../models/characteristics/loan-characteristic.model";
import { ICharacteristicFactory } from "./characteristic-factory.interface";

export class LoanCharacteristicFactory implements ICharacteristicFactory {

  create(id: number, applicationId: number, characteristicId: number, ownerId?: number): ICharacteristic {
    return new LoanCharacteristic(id, applicationId, characteristicId);
  }
}
