<div (click)="onEditClicked()"
  class="hidden-when-mobile notice d-flex rounded border-primary border border-dashed min-w-lg-600px align-items-center flex-shrink-0 b-4 p-6 mb-4 btn btn-outline"
  [ngClass]="{
  'btn-outline-dashed' : !deleteAttempted && income.isValid,
  'btn-outline-primary' : !deleteAttempted && income.isValid,
  'btn-active-light-primary': !deleteAttempted && income.isValid,
  'border-danger': deleteAttempted || !income.isValid,
  'btn-active-light-danger': deleteAttempted || !income.isValid
}">
  <span class="svg-icon svg-icon-2x svg-icon-primary me-4 align-items-center duotone-home-svg-mobile"
    style="padding-bottom: 2px;"
    [ngClass]="{'svg-icon-primary': !deleteAttempted && income.isValid, 'svg-icon-danger' : deleteAttempted || !income.isValid }">
    <ng-template svgIconHost></ng-template>
  </span>
  <div class="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap align-items-center">
    <span class="fs-6 text-gray-600 d-block fw-bold" style="min-width: 200px;text-align: left;">{{incomeName}}
      <span *ngIf="income.digitallyVerified" class="badge badge-success" style="margin-left: 12px;">Verified</span>
    </span>
    <div class="d-flex justify-content-end align-items-center">
      <div class="position-relative px-4" style="width: 150px; text-align: left;">
        <label *ngIf="income.position" class="text-gray-600">{{income.isOther ? 'Description' : 'Position'}}:
          <span class="d-block"> {{income.position}} </span>
        </label>
      </div>
      <div style="width: 130px; text-align: left;">
        <label *ngIf="income.startDate" class="text-gray-600 align-items-center px-4">Start Date: {{income.startDate|
          date}}</label>
      </div>
      <label class="text-gray-600 px-4" style="min-width: 205px; text-align: left;">Monthly Pay: {{income.monthlyPay|
        currency}}</label>
      <div class="mx-4" style="width: 175px; min-width: 175px;">
        <ng-container *ngIf="income.type === 'Base'">
          <div *ngFor="let incomeItem of income.incomeBreakdown" class="income-row">
            <span class="income-title">{{ incomeItem.type }}:</span>
            <span class="income-amount" (click)="onEditIncomeClicked($event)">{{ incomeItem.monthlyPay | currency
              }}</span>
          </div>
          <div class="shown-only-monthly-income-link">
            <span (click)="onEditIncomeClicked($event)"> Income Details <i class="text-primary fa fa-eye"></i></span>
          </div>
        </ng-container>
      </div>

      <div class="align-self-center text-nowrap">
        <div class="d-flex" *ngIf="deleteAttempted">
          <a href="javascript:void(0);" class="btn btn-icon btn-bg-light btn-active-color-success btn-sm me-1"
            (click)="onDeleteConfirmed($event)">
            <span class="svg-icon svg-icon-3">
              <stockholm-check-svg-icon></stockholm-check-svg-icon>
            </span>
          </a>
          <a href="javascript:void(0);" class="btn btn-icon btn-bg-light btn-active-color-danger btn-sm"
            (click)="onDeleteAborted($event)">
            <span class="svg-icon svg-icon-3">
              <stockholm-close-svg-icon></stockholm-close-svg-icon>
            </span>
          </a>
        </div>
        <div class="d-flex" *ngIf="!deleteAttempted">
          <a href="javascript:void(0);" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
            (click)="onDeleteClicked($event)">
            <span class="svg-icon svg-icon-3">
              <duotone-trash-svg-icon></duotone-trash-svg-icon>
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<div (click)="onEditClicked()"
  class="hidden-when-not-mobile align-items-center rounded border-primary border border-dashed min-w-lg-600px mb-4"
  [ngClass]="{'border-danger' : deleteAttempted || !income.isValid, 'btn-active-light-danger': deleteAttempted || !income.isValid}"
  style="padding: 5px !important;">
  <span class="svg-icon svg-icon-2x svg-icon-primary me-4 align-items-center duotone-home-svg-mobile"
    style="padding-bottom: 2px;"
    [ngClass]="{'svg-icon-primary': !deleteAttempted && income.isValid, 'svg-icon-danger' : deleteAttempted || !income.isValid }">
    <ng-template svgIconHost></ng-template>
  </span>
  <div style="width: 100%;">
    <div class="d-flex justify-content-between mx-2">
      <span class="fs-4 text-gray-600 d-block fw-bold mt-3">{{incomeName}}
        <span *ngIf="income.digitallyVerified" class="badge badge-success" style="margin-left: 12px;">Verified</span>
      </span>
      <div class="align-self-center text-nowrap">
        <div class="d-flex" *ngIf="deleteAttempted">
          <a href="javascript:void(0);" class="btn btn-icon btn-bg-light btn-active-color-success btn-sm me-1"
            (click)="onDeleteConfirmed($event)">
            <span class="svg-icon svg-icon-3">
              <stockholm-check-svg-icon></stockholm-check-svg-icon>
            </span>
          </a>
          <a href="javascript:void(0);" class="btn btn-icon btn-bg-light btn-active-color-danger btn-sm"
            (click)="onDeleteAborted($event)">
            <span class="svg-icon svg-icon-3">
              <stockholm-close-svg-icon></stockholm-close-svg-icon>
            </span>
          </a>
        </div>
        <div class="d-flex" *ngIf="!deleteAttempted">
          <a href="javascript:void(0);" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
            (click)="onDeleteClicked($event)">
            <span class="svg-icon svg-icon-3">
              <duotone-trash-svg-icon></duotone-trash-svg-icon>
            </span>
          </a>
        </div>
      </div>
    </div>
    <div class="text-start mt-3 px-2">
      <div *ngIf="income.position">
        <label class="form-label line-item-text" style="min-width: 6.5rem;"> {{income.isOther ? 'Description' :
          'Position'}}: </label>
        <span> {{income.position}} </span>
      </div>
      <div *ngIf="income.startDate">
        <label class="form-label line-item-text" style="min-width: 6.5rem;"> Start Date: </label>
        <span> {{income.startDate| date}} </span>
      </div>
      <div *ngIf="income.monthlyPay">
        <label class="form-label line-item-text" style="min-width: 6.5rem;"> Monthly Pay: </label>
        <span> {{income.monthlyPay| currency}} </span>
      </div>
      <div class="shown-only-monthly-income-link" *ngIf="income.type === 'Base'">
        <span (click)="onEditIncomeClicked($event)" class="line-item-text">
          Income Details <i class="text-primary fa fa-eye"></i></span>
      </div>
    </div>
  </div>
</div>