<div class="border border-dashed border-gray-300 rounded px-7 py-3 mb-6">
  <div class="d-flex flex-stack mb-3">
    <div class="me-3">
      <a href="javascript:void(0)" (click)="onViewFileClicked(document)"
        class="text-gray-800 text-hover-primary fw-bold">{{document.fileName}}</a>
    </div>

    <div class="m-0">
      <label class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body me-1" (click)="onViewFileClicked(document)">
        <i class="bi bi-eye fs-2"></i>
      </label>
      <label class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body" (click)="onDownloadFileClicked(document)">
        <i class="bi bi-cloud-download fs-2"></i>
      </label>
    </div>
  </div>

  <div class="d-flex flex-stack">
    <span class="text-gray-400 fw-bold">Notes:
      <span
        class="text-gray-800 fw-bold">
        {{ document.note ?? "No note from the loan representative." }}
    </span>
    </span>
    <span class="badge badge-light-info">Created: {{ document.dateInserted ?  (document.dateInserted | date : "medium") : '--' }}</span>
  </div>
</div>
