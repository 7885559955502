import { Component, Input, OnInit } from '@angular/core';
import { BorrowerCreditScores } from '../../../models/borrower-credit-scores.model';

@Component({
  selector: 'borrower-credit-scores',
  templateUrl: 'borrower-credit-scores.component.html'
})

export class BorrowerCreditScoresComponent implements OnInit {
  constructor() { }

  @Input()
  borrowerCreditScores!: BorrowerCreditScores;

  @Input()
  useScoreIndicatorIcons: boolean = false;

  ngOnInit() { }
}
