import { Directive } from "@angular/core";
import { AbstractControl, ValidationErrors, NG_VALIDATORS } from "@angular/forms";
import { Utils } from "projects/borrower-app/src/app/services/utils";

export function requireNonAlphaNumericValidator() {
  return (control: AbstractControl): ValidationErrors | null => {
    const isValid = !control.value || Utils.containsSpecialCharacter(control.value);
    return isValid ? null : { requiresNonAplhaNumeric: { value: control.value } };
  }
}
@Directive({
  selector: '[requiresNonAlphaNumeric]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: RequireNonAlphaNumericValidatorDirective,
      multi: true,
    },
  ],
})
export class RequireNonAlphaNumericValidatorDirective {
  validate(control: AbstractControl): ValidationErrors | null {
    return requireNonAlphaNumericValidator()(control);
  }
}
