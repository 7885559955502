import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'turbo-insurance-quote-status-dialog',
    templateUrl: 'turbo-insurance-quote-status-dialog.component.html'
})

export class TurboInsuranceQuoteStatusDialogComponent {
    @Input() message: any;

    constructor(public activeModal: NgbActiveModal) {}
}
