export enum CategoryOfIncome {
  //These values correspont to the values defined in LE.Lodasoft.Common.Models.Mortgage.IncomeViewModel
  Employment = "Employment",
  IndependentContractor = "IndependentContractor",
  MilitaryPay = "MilitaryPay",
  Rental = "Rental",
  SocialSecurity = "SocialSecurity",
  Pension = "Pension",
  //TODO: LE.Lodasoft.Common.Models.Mortgage.IncomeViewModel has typo
  BusinessSelfEmployment = "BuisnessSelfEmployment",
  Other = "Other"
}
