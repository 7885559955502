<ng-template #digitalAsset>
  <div class="d-flex mb-5" *ngIf="inEditMode">
    <label class="form-label mb-2">Vendor for Asset Verification</label>
    <select #vendorToUseField="ngModel" class="form-select form-select-solid"
      [(ngModel)]="step.vendorToUse"
      name="vendorToUse">
      <option [ngValue]="null">--Select One--</option>
      <option [ngValue]="'FormFree'">FormFree</option>
      <option [ngValue]="'Truv'">Truv</option>
    </select>
  </div>

  <digital-assets-verification 
    *ngIf="voaEnabled && !isEditEnabled && thirdPartyEnrollmentWidget"
    (complete)="onNextClicked()"
    (error)="onNextClicked()" 
    (close)="onNextClicked()"
    [applicationId]="mortgageApplication.applicationId"
    [borrowerId]="borrower.contactId"
    [taskId]="loanDocTaskId"
    [provider]="step.vendorToUse"
    [thirdPartyEnrollmentWidget]="thirdPartyEnrollmentWidget"
    >
  </digital-assets-verification>

  <div class="card-px text-center py-20 my-10" *ngIf="!voaEnabled && !isEditEnabled">
    <h2 class="fs fw-bold mb-8">Digital Assets Not Available</h2>
    <p class="text-gray-400 fs-4 fw-semibold mb-10">We are not able to pull your assets digitally at the moment.
      <br>You will have to tell us about your assets manually.
    </p>
    <a (click)="onSpecifyAssetsClicked()" class="btn btn-primary">Tell Us About Your Assets</a>
  </div>
  <div class="card-px text-center py-20 my-10" *ngIf="isEditEnabled">
    <h2 class="fs fw-bold mb-8">Here will go the Digital Assets</h2>
    <p class="text-gray-400 fs-4 fw-semibold mb-10">In normal flow (outside of edit mode), digital assets will be
      displayed here.</p>
  </div>
</ng-template>

<wizard-step-template [stepMainTemplate]="digitalAsset" [stepComponent]="this" [step]="step"></wizard-step-template>
