import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Borrower } from "../../models/borrower-model";
import { EnumsService } from "../../services/enums.service";
import { BorrowerCounselingEvent } from "../../models/borrower-counseling-event.model";
import { Utils } from "../../services/utils";
import { EnumerationItem } from "projects/shared/models/enumeration-item.model";

@Component({
  selector: 'borrower-homeownership-education',
  templateUrl: 'borrower-homeownership-education.component.html',
  styleUrls: ['./borrower-homeownership-education.component.scss'],
})
export class BorrowerHomeownershipEducationComponent implements OnInit {

  @ViewChild('borrowerHomeownershipEducationForm') borrowerHomeownershipEducationForm: NgForm | undefined;

  @Input()
  borrower!: Borrower;

  hasHomebuyerEducation: boolean = false;
  hasCounseling: boolean = false;

  formatTypes: EnumerationItem[] = [];

  constructor(private readonly _enumsService: EnumsService) {

  }

  ngOnInit(): void {
    this.formatTypes = this._enumsService.borrowerCounselingFormatTypes;

    if (this.borrower.counselingEvents && this.borrower.counselingEvents.length > 0) {
      this.hasHomebuyerEducation = this.borrower.counselingEvents.findIndex(ev => ev.counselingType == "Education") > -1;
      this.hasCounseling = this.borrower.counselingEvents.findIndex(ev => ev.counselingType == "Counseling") > -1;

      this.borrower.counselingEvents.forEach(e => {
        e.format = e.format ?? null;
        e.counselingAgencyState = e.counselingAgencyState ?? null;
      });
    }
  }

  onBorrowerCounselingEventChanged = (counselingType: string): void => {
    const hasEventForType = this.borrower.counselingEvents?.some(ev => ev.counselingType == counselingType);
    if (counselingType === 'Education') {
      if (this.hasHomebuyerEducation && !hasEventForType) {
        this.onAddNewCounselingEventClicked(counselingType);
      } else if (!this.hasHomebuyerEducation) {
        this.borrower.counselingEvents = this.borrower.counselingEvents.filter(ev => ev.counselingType != counselingType)
      }
    } else if (counselingType === 'Counseling') {
      if (this.hasCounseling && !hasEventForType) {
        this.onAddNewCounselingEventClicked(counselingType);
      } else if (!this.hasCounseling) {
        this.borrower.counselingEvents = this.borrower.counselingEvents.filter(ev => ev.counselingType != counselingType)
      }
    }
  }

  onAddNewCounselingEventClicked = (counselingType: string): void => {
    let newEvent = new BorrowerCounselingEvent();
    newEvent.borrowerId = this.borrower.borrowerId;
    newEvent.counselingType = counselingType;
    newEvent.format = null;
    newEvent.counselingAgencyState = null;
    newEvent.borrowerCounselingEventId = Utils.getUniqueId();
    this.borrower.counselingEvents.push(newEvent);
  }

  onDelete = (index: number) => {
    this.borrower.counselingEvents.splice(index, 1);
  }

  validate = () => {
    this.borrowerHomeownershipEducationForm?.form.markAllAsTouched();
    if (this.borrowerHomeownershipEducationForm?.form.valid) {
      return true;
    }
    return false;
  }
}
