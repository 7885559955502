

<div class="row" style="margin-left: 0;">
  <div *ngFor="let choice of possibleChoices; let i = index" class="form-check form-check-custom form-check-solid mb-5 col-lg-{{colWidth}}">
    <input class="form-check-input" id="{{choice.name}}" type="checkbox" [checked]="isSelected(choice)" [disabled]="isDisabled(choice)" (change)="selectionChanged($event.target, choice)"/>
    <label class="form-check-label fs-6" for="{{choice.name}}">
      {{choice.name}}
    </label>
  </div>
</div>
