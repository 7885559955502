import { Component, OnInit } from '@angular/core';
import { MortgageApplication } from '../../../models/mortgage-app.model';
import { EnumsService } from '../../../services/enums.service';
import { MortgageApplicationService } from '../../../services/mortgage-application.service';
import { Liability } from '../../../models/mortgage.model';

@Component({
  selector: 'liability-editor',
  templateUrl: 'liability-editor.component.html'
})

export class LiabilityEditorComponent implements OnInit {

  public liability: Liability | undefined;

  private _mortgageApplication: MortgageApplication;

  constructor( private readonly _mortgageApplicationService: MortgageApplicationService,
    private readonly _enumsService: EnumsService) {
      this._mortgageApplication = this._mortgageApplicationService.mortgageApplication;
      this.liability = new Liability();
      //this.liability = this._mortgageApplication.liabilities;
  }

  public get liabilityTypes() {
    return this._enumsService.liabilityTypes;
  }

  public get lienTypes() {
    return this._enumsService.lienTypes;
  }

  public get fhaSourceTypes() {
    return this._enumsService.fhaSourceTypes;
  }

  ngOnInit() { }
}
