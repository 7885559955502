<div style="min-height: 500px;">
  <div class="w-100">
    <div class="pb-10 pb-lg-15">
      <h2 class="fw-bolder d-flex align-items-center text-dark">Credits and Debts
      <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title=""></i></h2>
      <div class="text-gray-400 fw-bold fs-6" style="min-height: 20px;"></div>
    </div>
    <div class="fv-row">
        <div class="row">
          <span class="col-lg-12 text-dark fw-bolder d-block fs-4 pb-2" style="min-height: 60px;">
          </span>
        </div>
    </div>
    <div class="fv-row" style="min-height: 400px;">
      <div class="row mb-10">
        <form #liabilityForm="ngForm" novalidate id="liabilityForm" name="liabilityForm">

          <div class="d-flex mb-7 row">
            <div class="col-md-4">
              <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                <span class="required">Account Type</span>
              </label>
                 <!-- <div class="invalid-feedback">Address is required, please enter an address.</div> -->
              <select name="typeOfLiability" required [ngClass]="{'is-invalid' : typeOfLiability && typeOfLiability.touched && typeOfLiability.invalid}"
                [(ngModel)]="liability!.typeOfLiability" class="form-select form-select-solid" #typeOfLiability="ngModel"
                data-placeholder="Select an Account Type">
                  <option></option>
                  <option *ngFor="let liabilityType of liabilityTypes; let i = index" [ngValue]="liabilityType.value">
                     {{liabilityType.name}}
                  </option>
              </select>
            </div>
            <div class="col-md-4">
              <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                <span class="required">Creditor</span>
              </label>
              <div class="position-relative">
                <input type="text" class="form-control form-control-solid" [(ngModel)]="liability!.holderName" required placeholder="" name="holderName"
                  [ngClass]="{'is-invalid' : holderName && holderName.touched && holderName.invalid}" #holderName="ngModel" />
                <!-- <div class="invalid-feedback">City is required, please enter a city.</div> -->
              </div>
            </div>
            <div class="col-md-4 fv-row">
              <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                <span class="required">Amount</span>
              </label>
              <input type="text" class="form-control form-control-solid" [(ngModel)]="liability!.unpaidBalance" required placeholder="" name="amount"
              [ngClass]="{'is-invalid' : amount && amount.touched && amount.invalid}" #amount="ngModel" />
            </div>
          </div>
          <div class="d-flex mb-7 row">
            <div class="col-md-3">
              <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                <span class="required">Lien Type</span>
              </label>
                 <!-- <div class="invalid-feedback">Address is required, please enter an address.</div> -->
              <select name="lienPosition" required [ngClass]="{'is-invalid' : lienPosition && lienPosition.touched && lienPosition.invalid}"
                [(ngModel)]="liability!.lienPosition" class="form-select form-select-solid" #lienPosition="ngModel"
                data-placeholder="Select a Lien Type">
                  <option></option>
                  <option *ngFor="let lienType of lienTypes; let i = index" [ngValue]="lienType.value">
                     {{lienType.name}}
                  </option>
              </select>
            </div>
            <div class="col-md-3">
              <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                <span class="required">FHA Source</span>
              </label>
                 <!-- <div class="invalid-feedback">Address is required, please enter an address.</div> -->
              <select name="fhaSource" required
                class="form-select form-select-solid"
                data-placeholder="Select a FHA Source">
                  <option></option>
                  <option *ngFor="let fhaSource of fhaSourceTypes; let i = index" [ngValue]="fhaSource.value">
                     {{fhaSource.name}}
                  </option>
              </select>
            </div>
            <div class="col-md-3">
              <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                <span class="required">Monthly Payment</span>
              </label>
              <div class="position-relative">
                <input type="text" class="form-control form-control-solid" [(ngModel)]="liability!.monthlyPayment" required placeholder="" name="monthlyPayment"
                  [ngClass]="{'is-invalid' : monthlyPayment && monthlyPayment.touched && monthlyPayment.invalid}" #monthlyPayment="ngModel" />
              </div>
            </div>
            <div class="col-md-3">
              <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                <span class="required">Loan Amount/Draw</span>
              </label>
              <div class="position-relative">
                <input type="text" class="form-control form-control-solid" required placeholder="" name="loanAmont"/>
              </div>
            </div>
          </div>
          <div class="d-flex mb-7 row">
            <div class="col-md-4">
              <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                <span class="required">Credit Limit</span>
              </label>
              <div class="position-relative">
                <input type="text" class="form-control form-control-solid" [(ngModel)]="liability!.creditLimit" required placeholder="" name="creditLimit"
                  [ngClass]="{'is-invalid' : creditLimit && creditLimit.touched && creditLimit.invalid}" #creditLimit="ngModel" />
              </div>
            </div>
            <div class="col-md-4">
              <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                <span class="required">HELOC?</span>
              </label>
              <div class="position-relative">
                <input class="form-check-input" type="checkbox" value="" id="isHeloc" />
              </div>
            </div>
          </div>
          <div class="d-flex mb-7 row">

          </div>
        </form>

      </div>
    </div>
  </div>
</div>


