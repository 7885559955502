<div class="row">
  <div class="p-2 px-5 col-md-3" style="min-height: 70px;">
    <div class="d-flex align-items-center mb-2">
      <div class="symbol symbol-40px symbol-lg-80px symbol-fixed position-relative me-4">
        <span class="svg-icon svg-icon-2x svg-icon-primary">
          <duotone-bank-svg-icon></duotone-bank-svg-icon>
        </span>
      </div>
      <a *ngIf="!inEditMode"
        (click)="onLoanDetailsClicked()"
        href="javascript:void(0)"
        class="text-gray-800 text-hover-primary fs-4 fw-bolder me-1">
        {{step.loanDetailsSectionTitle}}
      </a>
      <input *ngIf="inEditMode" [name]="'loanDetailsCaption'" type="text"
        class="form-control form-control-solid fs-4 fw-bolder" [(ngModel)]="step.loanDetailsSectionTitle" />
    </div>
  </div>
  <div class="p-2 px-5 col-md-3" style="min-height: 70px;">
    <div class="mb-3">
      <div class="text-gray-400 fw-bold">Purpose: <span class="text-gray-800 fw-bold">{{purposeOfLoan}}</span></div>
    </div>
    <div class="mb-3">
      <div class="text-gray-400 fw-bold">Type: <span class="text-gray-800 fw-bold">{{mortgageAppliedFor}}</span></div>
    </div>
  </div>
  <div class="p-2 col-md-3 px-5" style="min-height: 70px;">

    <div class="mb-3" *ngIf="!isHidden('mortgage.transactionDetail.purchasePriceAmount') && purposeOfLoan !== 'Refinance'">
      <div class="text-gray-400 fw-bold">
        {{getLabel('mortgage.transactionDetail.purchasePriceAmount', 'Purchase Price:')}} <span class="text-gray-800 fw-bold">{{transactionDetail.purchasePriceAmount | currency}}</span></div>
    </div>
    <div class="mb-3" *ngIf="purposeOfLoan == 'Refinance'">
      <div class="text-gray-400 fw-bold">Estimated Value: <span class="text-gray-800 fw-bold">{{subjectProperty.presentValue | currency}}</span></div>
    </div>
    <div class="mb-3">
      <div class="text-gray-400 fw-bold">LTV: <span class="text-gray-800 fw-bold">{{ltv | number : '1.2-2'}} %</span></div>
    </div>
  </div>
</div>
