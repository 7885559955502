<form #borrowerPickerForm="ngForm" class="form" novalidate id="borrowerPickerForm" name="borrowerPickerForm">
  <select class="form-select form-select-solid" data-control="select2" name="type" [(ngModel)]="value"  (ngModelChange)="borrowerChanged($event)"
    #modelValue="ngModel" [required]="required" [ngClass]="{'is-invalid' : modelValue && modelValue.touched && modelValue.invalid}">
    <option value="">Select one...</option>
    <option *ngFor="let borrower of borrowers;" [ngValue]="borrower.borrowerId">
      {{borrower.firstName + ' ' + borrower.lastName}}
    </option>
  </select>
  <div class="invalid-feedback">Borrower is required.</div>
</form>
