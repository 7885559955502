import { IDecideNextStep } from "../../services/wizard/next-step-decider/next-step-decider.interface";
import { PricingStepComponent } from "../../wizard/wizard-steps/pricing-step/pricing-step.component";
import { WizardStepBase } from "./wizard-step-base.model";

export class PricingStep extends WizardStepBase {

  noProductFoundMessageTitle: string = "No Available Products";

  noProductsFoundMessageText: string = "We were not able to find any available products based on your loan criteria.";

  constructor(id: string, name: string, path: string, nextStepDecider?: IDecideNextStep) {
    super(id, name, path, nextStepDecider);
    this.component = PricingStepComponent;
  }
}
