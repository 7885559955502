import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Company } from '../models/company-model';
import { Profile } from '../models/profile.model';
import { UserData } from '../models/user/user-data.model';
import { UserPermissions } from '../models/user/user-permissions.model';
import { DataService } from './data.service';

@Injectable()
export class UserService {

  constructor(private readonly _dataService: DataService) { }

  getLoggedInUserData = (): Observable<UserData> => {
    return this._dataService.get('api/auth/userdata').pipe(
      map((user) => {
        if (user.avatar === 'content/img/avatars/male.png') {
          user.avatar = 'assets/images/male.png';
        }
        return user;
      })
    );
  };

  getProfile = (userId: string): Observable<Profile> => {
    return this._dataService.get(`api/admin/profile/${userId}`);
  };

  getUserCompany = (userName: string): Observable<Array<Company>> => {
    return this._dataService.post(
      `api/auth/AllCompanyForUserName?username=${encodeURIComponent(
        userName
      )}`,
      {}
    );
  };

getUserPermissions = (): Observable<UserPermissions> => {
    return this._dataService.get('api/admin/permissionMenu');
  };
}
