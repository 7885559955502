import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AddressAutocompleteComponent } from 'projects/shared/address-autocomplete/address-autocomplete.component';
import { TurboInsurancePropertyInfo } from '../../../models/turbo-insurance/get-quote-request.model';
import { EnumsService } from '../../../services/enums.service';


@Component({
  selector: 'turbo-insurance-property-info-editor',
  templateUrl: 'turbo-insurance-property-info-editor.component.html'
})
export class TurboInsurancePropertyInfoEditorComponent implements OnInit {

  @ViewChild('addressForm') addressForm: NgForm | undefined;
  @ViewChild(AddressAutocompleteComponent) addressAutoComplete: AddressAutocompleteComponent | undefined;
  @ViewChild('zipCodeOfAddress', {static: false}) zipCodeOfAddress: ElementRef | undefined;
  @ViewChild('cityOfAddress', {static: false}) cityOfAddress: ElementRef | undefined;

  constructor(private readonly _enumsService: EnumsService) {
    this.propertyInfo = new TurboInsurancePropertyInfo();
  }

  @Input()
  propertyInfo: TurboInsurancePropertyInfo;

  @Input()
  showMonthsAtAddressField: boolean = true;

  setManuallyEnteredAddress(address: string) {
    this.propertyInfo.streetAddress1 = address;
  }

  setGooglePickedAddress(place: any) {
    this.propertyInfo.streetAddress1 = place.name;
    if (place.address_components) {
      const self = this;
      place.address_components.forEach((component: { types: any; long_name: any; short_name: any; }) => {
          let types = component.types;
          types.forEach((type: string) => {
              if (type === 'locality' || type === 'sublocality') {
                  this.propertyInfo.city = component.long_name;
              }
              if (type === 'administrative_area_level_1') {
                  const state = this.states.find(s => s.name === component.short_name);
                  if (state)
                    this.propertyInfo.state = state.value;
              }
              if (type === 'postal_code') {
                  this.propertyInfo.zip = component.long_name;
              }
              setTimeout(() => self.cityOfAddress!.nativeElement.focus(), setTimeout(() => self.zipCodeOfAddress!.nativeElement.focus()));
          });
      });
    }
  }

  validate = () => {
    this.addressForm?.form.markAllAsTouched();
    this.addressAutoComplete?.markAsTouched();
    if (this.addressForm?.form.valid) {
        return true;
    }
    return false;
  }

  public get states() {
    return this._enumsService.states;
  }

  ngOnInit() { }
}
