import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { CreateBorrowerAccountMortgageRequest } from '../../models/create-borrower-account-mortgage-request.model';
import { CreateApplicationResponse } from '../../models/create-application-response.model';
import { ResetPasswordResult } from '../../models/auth/reset-password-result.model';
import { MortgageApplicationService } from '../../services/mortgage-application.service';
import { AuthService } from '../../services/auth.service';
import { AuthenticationRequest } from '../../models/auth/auth-request.model';
import { LoginStatus } from '../../models/auth/login-status.enum';
import { NavigationService } from '../../services/navigation.service';
import { ResetPasswordRequest } from '../../models/auth/reset-password-request.model';
import { AuthenticationUserCompany } from '../../models/auth/auth-response.model';
import { EnvironmentService } from 'projects/shared/services/environment.service';

declare const Swal: any;

@Component({
  selector: 'reset-password-dialog',
  templateUrl: 'reset-password-dialog.component.html',
  styleUrls: ['reset-password-dialog.component.scss']
})
export class ResetPasswordDialogComponent implements OnInit {

  @ViewChild('resetPasswordForm') resetPasswordForm: NgForm | undefined;

  @Input()
  userName: string | undefined;

  @Input()
  createApplicationRequest: CreateBorrowerAccountMortgageRequest | undefined;

  @Input()
  applyingForANewLoan: boolean = false;

  securityCode: string | undefined;

  password: string | undefined;

  confirmedPassword: string | undefined;

  errorMessage: string | undefined;

  spinnerVisible: boolean = false;

  resetPasswordButtonText: string = "Reset Password";

  resendCodeButtonText: string = "Resend Code";

  result: ResetPasswordResult = new ResetPasswordResult();

  passwordErrors: string[] = null;

  userCompanyGuid: string = ""

  userScope: string;
  
  availableCompanies: AuthenticationUserCompany[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private readonly _mortgageApplicationService: MortgageApplicationService,
    private readonly _authService: AuthService,
    private readonly _navigationService: NavigationService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _environment: EnvironmentService,
  ) { }

  ngOnInit() {
    this.userScope = this._environment.scope
    this.getAvailableCompanies();
  }

  onResendCodeClicked = () => {
    this.toggleProgress(true);
    this._authService.sendTwoFactorEmailCode(this.userCompanyGuid).subscribe(result => {
      Swal.fire(
        'Success!',
        "We've sent another security code to " + this.userName + ". Please, use that code to reset your password.",
        'success'
      ).then(function (modalResult: any) {
        if (modalResult.value) {

        }
      });
    }, error => {
      this.errorMessage = error;
    }).add(() => {
      this.toggleProgress(false);
    });
  }

  onResetPasswordClicked = () => {
    this.resetPasswordForm?.form.markAllAsTouched();
    if (this.resetPasswordForm?.form.valid && this.createApplicationRequest) {
      this.createApplicationRequest.password = this.password;
      this.createApplicationRequest.resetPasswordCode = this.securityCode;
      this.toggleProgress(true);
      if (this.applyingForANewLoan) {
        this.applyForANewLoan();
      } else {
        this.resetPasswordAndGotoBorrowerPortal();
      }
    }
  }

  private resetPasswordAndGotoBorrowerPortal = () => {
    this.passwordErrors = null;
    const resetPasswordRequest: ResetPasswordRequest = {
      email: this.userName,
      password: this.password,
      confirmPassword: this.confirmedPassword,
      code: this.securityCode,
      userCompanyGuid: this.userCompanyGuid,
    }
    this.toggleProgress(true);
    this._authService.resetPasswordWithRecurityCode(resetPasswordRequest).subscribe(response => {
      this.toggleProgress(false);
      this.loginAndGotoBorrowerPortal();
    }, error => {
      this.toggleProgress(false);
      this.passwordErrors = this.formatErrorMessageForPasswordReset(error);
    });
  }

  private loginAndGotoBorrowerPortal = () => {
    const loginRequest: AuthenticationRequest = {
      companyGuid: this.createApplicationRequest.companyGuid,
      userCompanyGuid: this.userCompanyGuid,
      username: this.userName,
      password: this.password,
      rememberMe: false,
      scope: 'Borrower'
    }
    this.toggleProgress(true);
    this._authService.signIn(loginRequest).subscribe(response => {
      this.toggleProgress(false);
      if (response.loginStatus === LoginStatus.Error) {
        this.errorMessage = response.errorMessage;
        this.createApplicationRequest.password = null;
        return;
      }
      this.activeModal.close();
      this._navigationService.navigateToPath("borrower-portal", true);
    }, error => {
      this.toggleProgress(false);
    });
  }

  private applyForANewLoan = () => {
    this._mortgageApplicationService.saveLoanApplication(this.createApplicationRequest).subscribe((response: CreateApplicationResponse) => {
      if (response.authToken) {
        this.result.authToken = response.authToken;
        this.result.newPassword = this.password;
        this.activeModal.close(this.result);
      } else {
        if (response.loginError) {
          this.errorMessage = response.loginError;
          return;
        }
        this.errorMessage = "Either your user name or your password is incorrect.";
      }
    }, error => {
      this.errorMessage = "Not able to log you in at this time.";
    }).add(() => {
      this.toggleProgress(false);
    });
  }

  private toggleProgress = (value: boolean) => {
    if (value) {
      this._spinner.show();
    } else {
      this._spinner.hide();
    }
    this.spinnerVisible = value;
    if (value) {
      this.resetPasswordButtonText = "Please wait...";
    } else {
      this.resetPasswordButtonText = "Reset Password";
    }
  }

  private formatErrorMessageForPasswordReset = (error: any): string[] => {
    const passwordErrors = error?.error?.errors?.password;
    if (passwordErrors && passwordErrors.length) {
      return passwordErrors;
    }
    const errorMessage = error?.error || "An error occurred while resetting your password.";
    return [errorMessage];
  }

  private getAvailableCompanies = () => {
    this._spinner.show();
    this._authService.getAvailableCompanies(this.userName, this.userScope || 'AgentAndBorrower').subscribe({
      next: (response) => {
        this.availableCompanies = response || [];

        if (this.availableCompanies.length === 1) {
          this.userCompanyGuid = this.availableCompanies[0].userCompanyGuid;
        }
      },
      error: (error) => {
        this.errorMessage = 'Unable to get companies for a provided email';
      }
    }).add(() => {
      this._spinner.hide();
    });
  }
}
