import { Component } from '@angular/core';
import { WizardNavigationComponentBase } from '../wizard-navigation/wizard-navigation-base.component';

@Component({
  selector: 'wizard-vertical-nav',
  templateUrl: 'wizard-vertical-nav.component.html'
})

export class WizardVerticalNavComponent extends WizardNavigationComponentBase {

}
