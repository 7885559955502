import { Component, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from "@angular/forms";

@Component({
  selector: 'dependents-dialog',
  templateUrl: 'dependents-dialog.component.html'
})
export class DependentsDialogComponent {

  @ViewChild(NgForm) dependentsForm: NgForm | undefined;

  @Input()
  set dependentAges(ages: number[]) {
    this.dependents = ages.map(a => {
      return { age: a }
    });
  }

  protected indexToDelete: number | undefined;

  protected dependents: Array<{ age: number }> = [];

  constructor(public activeModal: NgbActiveModal) {
  }

  onSaveClicked = () => {
    this.dependentsForm?.form.markAllAsTouched();
    if (this.dependentsForm?.form.valid) {
      this.activeModal.close(this.dependents.map(d => d.age));
    }
  }

  addDependentClicked = () => {
    this.dependents.push({ age: 0 });
  }

  onDeleteClicked = (index: number) => {
    this.indexToDelete = index;
  }

  onDeleteConfirmed = (index: number) => {
    this.dependents.splice(index, 1);
    this.indexToDelete = undefined;
  }

  onDeleteAborted = () => {
    this.indexToDelete = undefined;
  }
}
