import { IDecideNextStep } from "../../services/wizard/next-step-decider/next-step-decider.interface";
import { HoiStepComponent } from "../../wizard/wizard-steps/hoi-step/hoi-step.component";
import { WizardStepBase } from "./wizard-step-base.model";

export class HoiStep extends WizardStepBase {
  constructor(id: string, name: string, path: string, nextStepDecider?: IDecideNextStep) {
    super(id, name, path, nextStepDecider);
    this.component = HoiStepComponent;
  }
}
