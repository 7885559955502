import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BorrowerCharacteristic } from 'projects/borrower-app/src/app/models/characteristics/borrower-characteristic.model';
import { CharacteristicMergeValue } from 'projects/borrower-app/src/app/models/characteristics/characteristic-merge-value.model';
import { TaskCategory } from 'projects/borrower-app/src/app/models/characteristics/task-category.model';
import { Constants } from 'projects/borrower-app/src/app/services/constants';
import { BorrowerCharacteristicFactory } from '../../../characteristics-quantities-dialog/borrower-characteristics.factory';
import { CharacteristicsQuantitiesDialogComponent } from '../../../characteristics-quantities-dialog/characteristics-quantities-dialog.component';

@Component({
  selector: 'characteristics-per-borrower',
  templateUrl: 'characteristics-per-borrower.component.html'
})

export class CharacteristicsPerBorrowerComponent implements OnInit {

  @Input()
  categories: TaskCategory[] = [];

  @Input()
  characteristics: BorrowerCharacteristic[] = [];

  @Input()
  borrowerId: number | undefined;

  @Input()
  applicationId: number = 0;

  private _nextCharacteristicId: number = -1;

  constructor(private readonly _modalService: NgbModal) {
  }

  ngOnInit() {
  }

  onCharacteristicCheckChanged = (characteristicId: number, borrowerId: number | undefined) => {
    let index = [];
    for (let i = 0; i < this.characteristics.length; i++) {
      if (this.characteristics[i].characteristicId === characteristicId) {
        index.push(i);
      }
    }
    if (index.length === 0) {
      const newOne = new BorrowerCharacteristic(this._nextCharacteristicId--, this.applicationId, characteristicId, borrowerId);
      this.characteristics.push(newOne);

      if (this.categories.filter(x => x.taskCategoryId == characteristicId)[0].taskOption === 'RequestQuantity') {
        const category = this.categories.find(c => c.taskCategoryId === characteristicId);
        if (category) {
          this.openEditCharacteristicDialog(category, borrowerId, true);
        }
      }
    }
    else {
      index.forEach(i => {
        this.characteristics[i].pendingDeletion = this.characteristics[i].pendingDeletion === true ? false : true;
      });
    }
  }

  openEditCharacteristicDialog = (category: TaskCategory, borrowerId: number | undefined, addingForTheFirstTime: boolean) => {
    const characteristicId = category.taskCategoryId;
    let existingCharacteristics = this.characteristics.filter(c => c.characteristicId == characteristicId && c.borrowerId == borrowerId);
    if (existingCharacteristics.length === 0) {
        let newOne = new BorrowerCharacteristic(this._nextCharacteristicId--, this.applicationId, characteristicId as number, borrowerId);
        this.characteristics.push(newOne);
        existingCharacteristics = this.characteristics.filter(c => c.characteristicId == characteristicId && c.borrowerId == borrowerId);
        addingForTheFirstTime = true;
    }
    existingCharacteristics.forEach(c => {
      if (!c.characteristicMergeValues || c.characteristicMergeValues.length === 0) {
        c.characteristicMergeValues = [];
        if (category.fields) {
          category.fields.forEach(f => {
            c.characteristicMergeValues.push(new CharacteristicMergeValue(f.characteristicFieldId));
          });
        }
      }
    });

    const modalRef = this._modalService.open(CharacteristicsQuantitiesDialogComponent, Constants.modalOptions.large);
    modalRef.componentInstance.taskCategory = this.categories.find(c => c.taskCategoryId === characteristicId);
    modalRef.componentInstance.nextCharacteristicId = this._nextCharacteristicId;
    modalRef.componentInstance.characteristics = existingCharacteristics;
    modalRef.componentInstance.characteristicFactory = new BorrowerCharacteristicFactory();
    modalRef.componentInstance.applicationId = this.applicationId;
    modalRef.componentInstance.ownerId = borrowerId;

    modalRef.result.then((result) => {
      if (result !== 'cancel') {
        let remaining = this.characteristics.filter(c => !(c.characteristicId == characteristicId && c.borrowerId == borrowerId));
        if (result != undefined) {
          result.forEach((characteristic: BorrowerCharacteristic) => {
            remaining.push(characteristic);
          });
        }
        this.characteristics = remaining;
      } else {
        if (addingForTheFirstTime) {
          this.characteristics = this.characteristics.filter(c => c.characteristicId !== characteristicId);
        }
      }
    }, (err) => {
      if (addingForTheFirstTime) {
        this.characteristics = this.characteristics.filter(c => c.characteristicId !== characteristicId);
      }
    });
  }

  isCharacteristicCheckedForLoan = (characteristicId: number): boolean => {
    const quantity = this.getCharacteristicQuantity(characteristicId);
    return quantity > 0;
  }

  getCharacteristicQuantity = (characteristicId: number): number => {
    var bcs = this.characteristics.filter(c => c.characteristicId == characteristicId && !c.pendingDeletion);
    if (bcs && bcs.length > 0) {
        return bcs.length;
    }
    return 0;
  }
}
