import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'task-borrower-picker-dialog',
  templateUrl: 'task-borrower-picker-dialog.component.html',
})
export class TaskBorrowerPickerDialogComponent implements OnInit {

  @ViewChild('borrowerPickerForm')
  borrowerPickerForm: NgForm;

  @Input()
  borrowers: BorrowerViewModel[] = [];

  protected selectedBorrowerId: number | null | undefined = null;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    if (this.borrowers.length === 1) {
      this.selectedBorrowerId = this.borrowers[0].id;
    }
  }

  protected onOkClicked = () => {
    if (this.borrowerPickerForm?.form.valid) {
      this.activeModal.close(this.selectedBorrowerId);
    }
  }
}

export interface BorrowerViewModel {
  id: number;
  name: string;
}
