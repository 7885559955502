<ng-template #selectOriginator>
  <div id="originator-main">
   <!--begin::Tab Content-->
   <div class="tab-content" >
    <!--begin::Tab pane-->
    <div class="text-center" *ngIf="loading">
      <i class="fas fa-circle-notch fa-4x fa-spin"></i>
    </div>
    <div class="tab-pane fade show active" *ngIf="!loading">
      <div class="row g-6 g-xl-9 pb-5">
        <!--begin::Input group-->
        <div class="position-relative me-md-2">
          <!--begin::Svg Icon | path: icons/stockholm/General/Search.svg-->
          <span class="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ms-6">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect x="0" y="0" width="24" height="24" />
                <path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />
                <path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" fill="#000000" fill-rule="nonzero" />
              </g>
            </svg>
          </span>
          <!--end::Svg Icon-->
          <input
            autocomplete="new-username"
            type="text"
            class="form-control form-control-solid search-input"
            name="search"  [(ngModel)]='searchText' value=""
            placeholder="Search"
            (ngModelChange)="searchTextChange()"/>
        </div>
        <!--end::Input group-->
      </div>
      <!--begin::Row-->
      <div class="row g-6 g-xl-9">
        <!--begin::Col-->
        <div *ngFor="let originator of filteredOriginators" class="col-md-6 col-xxl-4">
          <originator-info [selected]="originator.userId === userGuid" (click)="onCardClicked(originator.userId)" [originator]="originator"></originator-info>
        </div>
        <!--end::Col-->
    </div>
      <!--end::Row-->
    </div>
    <!--end::Tab pane-->
  </div>
  </div>
</ng-template>

<wizard-step-template [stepMainTemplate]="selectOriginator" [stepComponent]="this" [step]="step"></wizard-step-template>
