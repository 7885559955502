import { StepExpression } from "../step-expression.model";
import { NextStepDeciderConfig } from "./next-step-decider-config.model";
import { NextStepDeciderType } from "./next-step-decider-type.enum";

export class ExpressionNextStepDeciderConfig extends NextStepDeciderConfig {
  public ifStepExpression!: StepExpression;
  public elseIfStepExpressions: StepExpression[] | undefined = [];
  public elseStepPath: string |  undefined;

  constructor(ifStepExpression: StepExpression, elseIfStepExpressions: StepExpression[] | undefined, elseStepPath: string | undefined) {
    super();
    this.ifStepExpression = ifStepExpression
    this.elseIfStepExpressions = elseIfStepExpressions;
    this.elseStepPath = elseStepPath;
    this.deciderType = NextStepDeciderType.Expression;
  }
}
