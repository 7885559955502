
<div class="modal-header">
    <h2>Quotes Information</h2>

    <!-- hack to fool ngbModal autofocus -->
    <input type="text" style="display:none" />

    <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" aria-label="Close"
         (click)="activeModal.dismiss('Cross click')">
      <span class="svg-icon svg-icon-2x">
        <close-x-svg-icon></close-x-svg-icon>
      </span>
    </div>
</div>

<div class="modal-body py-10 px-lg-17">
    {{message}}
</div>

<div class="modal-footer flex-center">
    <button type="button" class="btn btn-white me-3" (click)="activeModal.dismiss()">Close</button>
    <button type="button" class="btn btn-primary" (click)="activeModal.close()">Proceed</button>
</div>




