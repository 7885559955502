import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { PortalScope } from '../../login/login.component';
import { LocalStorageService } from '../../services/local-storage.service';
import { NavigationService } from '../../services/navigation.service';
import { PortalBaseComponent } from '../portal-base.component';
import { PortalServiceFactory } from '../../services/portal-service.factory';

@Component({
  selector: 'app-confirm-e-signer',
  templateUrl: './confirm-e-signer.component.html',
  styleUrls: ['./confirm-e-signer.component.scss']
})
export class ConfirmESignerComponent extends PortalBaseComponent implements OnInit {

  isAgent: boolean = false;

  constructor(
    private readonly portalServiceFactory: PortalServiceFactory,
    private readonly _navigationService: NavigationService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _router: Router,
    private readonly _localStorage: LocalStorageService
  ) {
    super(portalServiceFactory);
  }

  ngOnInit(): void {

    let portalScope = this._localStorage.getItem("portalScope") as PortalScope;
    if (portalScope && portalScope == PortalScope.Agent) {
      this.isAgent = true;
    }

    const taskId = Number(this._activatedRoute.snapshot.queryParamMap.get('loanDocTaskId'));
    const action = this._activatedRoute.snapshot.queryParamMap.get('event');
    const loanId = Number(this._activatedRoute.snapshot.paramMap.get('id'));

    this.portalService.onSignerViewSubmitted(taskId, action)
      .subscribe({
        next: (response) => {
          this._spinner.hide();
          window.parent.postMessage({
            shouldCloseEsignDocIframe: true,
            response: response
          }, '*');
        },
        error: (error) => {
          console.error(error.error.message ? error.error.message : 'Accept E-Sign Consent failed');
          this._spinner.hide();
          setTimeout(() => {
            this._router.navigate([`${this.isAgent ? 'agent' : 'borrower'}-portal/loans`, loanId], { queryParams: { companyGuid: this._navigationService.companyGuid } });
          }, 2000)
        }
      });
  }
}
