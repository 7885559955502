<ng-template #forgotPassword>
  <!-- <header [logoUrl]="logoUrl"></header> -->
  <div class="d-flex flex-column flex-column-fluid p-10 pb-lg-10 login-wrapper">
    <div class="w-md-500px bg-white rounded shadow-sm p-7 p-lg-7 login-body">
      <div class="d-flex justify-content-center">
        <logo [logoUrlsToTry]="possibleLogoUrls"></logo>
      </div>

      <form class="form w-100" #forgotPasswordForm="ngForm" novalidate id="forgotPasswordForm" name="forgotPasswordForm">
        <div class="text-center my-4">
          <div class="alert alert-info bg-light-success d-flex justify-content-center align-items-center p-5 mb-5">
            <span class="svg-icon svg-icon-2hx svg-icon-info me-3">
              <duotone-safe-svg-icon></duotone-safe-svg-icon>
            </span>
            <div class="d-flex flex-column flex-grow-1">
              <span class="mb-3">We will send a secure link to your e-mail that we have on your account.</span>
              <span>Please enter your e-mail below.</span>
            </div>
          </div>
        </div>
        <div *ngIf="errorMessage" class="alert alert-danger d-flex justify-content-center align-items-center p-5 mb-5">
          <span class="svg-icon svg-icon-2hx svg-icon-danger me-3">
            <duotone-shield-check-svg-icon></duotone-shield-check-svg-icon>
          </span>
          <div class="d-flex flex-column flex-grow-1 login-error-body">
            <span>{{errorMessage}}</span>
          </div>
        </div>
        <div class="fv-row mb-10">
          <label class="form-label fs-6 fw-bolder text-dark">E-mail</label>
          <input class="form-control form-control-lg form-control-solid" trim email required
            [ngClass]="{'is-invalid' : userName && userName.touched && userName.invalid}" 
            type="text" name="email" (ngModelChange)="onEmailChanged()"
            [(ngModel)]="email" #userName="ngModel" autocomplete="off" />
          <div class="invalid-feedback">Please enter a valid e-mail.</div>
        </div>
        <div class="fv-row mb-10" *ngIf="email && availableCompanies && availableCompanies.length > 1">
          <label class="form-label fs-6 fw-bolder text-dark">Company</label>
          <select class="form-control form-control-lg form-control-solid" required
            [ngClass]="{'is-invalid' : selectedCompany && selectedCompany.touched && selectedCompany.invalid}" name="company"
            [(ngModel)]="userCompanyGuid" #selectedCompany="ngModel" autocomplete="off">
            <option value="">--Select One-</option>
            <option *ngFor="let company of availableCompanies" value="{{company.userCompanyGuid}}">{{company.companyName}}</option>
          </select>
          <div class="invalid-feedback">Please select a company.</div>
        </div>
        <div class="text-center">
          <button type="submit" class="btn btn-lg btn-primary w-100 mb-5"
            (click)="onSendResetLinkClicked()">
            <span class="indicator-label">{{sendResetLinkButtonText}}</span>
            <span *ngIf="spinnerVisible" class="spinner-border spinner-border-sm align-middle ms-2"></span>
          </button>
        </div>
      </form>
    </div>
  </div>
  <div class="d-flex flex-column justify-content-center align-items-center">
    <div class="login-footer">
      <img src="/assets/images/loda-logo.svg" alt="Lodasoft">
    </div>
  </div>

</ng-template>

<user-account-template [contentTemplate]="forgotPassword"></user-account-template>

