<div class="container">
  <form #requestCallForm="ngForm" novalidate (ngSubmit)="submit()">
    <div class="row p-4 mb-3">
      <div class="form-group mb-3">
        <h4>REQUEST A CALL</h4>
      </div>
      <div class="form-group mb-3">
        <label class="form-label" for="bestContactMumber"
          >Preferred Phone Number</label
        >
        <input
          class="form-control"
          type="tel"
          id="bestContactMumber"
          name="bestContactMumber"
          [(ngModel)]="requestACall.bestContactMumber"
        />
      </div>
      <div class="form-group mb-3">
        <label class="form-label" for="bestCallbackTime">Callback Time</label>
        <input
          class="form-control"
          type="datetime-local"
          id="bestCallbackTime"
          name="bestCallbackTime"
          [ngModel]="requestACall.bestCallbackTime | date : 'yyyy-MM-ddTHH:mm'"
          (ngModelChange)="bestCallbackTimeChanged($event)"
        />
      </div>
      <div class="form-group mb-3">
        <label class="form-label" for="note">Note</label>
        <textarea
          class="form-control"
          rows="3"
          id="note"
          name="note"
          [(ngModel)]="requestACall.note"
        ></textarea>
      </div>
      <div class="d-flex justify-content-end">
        <button class="btn btn-sm bp-primary-bg-color" type="submit">Submit</button>
      </div>
    </div>
  </form>
</div>
