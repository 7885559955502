import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'duotone-pencil-svg-icon',
  template: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.69601 15.8112L2.15894 19.91C1.70676 21.1158 2.88429 22.2934 4.09012 21.8412L8.18896 20.3041C8.72361 20.1036 9.20914 19.791 9.6129 19.3872L10 19L5 14L4.6129 14.3872C4.20914 14.791 3.8965 15.2765 3.69601 15.8112Z" fill="#12131A"/>
              <path opacity="0.25" d="M5 14L10 19L19.5 9.5L14.5 4.5L5 14Z" fill="#12131A"/>
              <path d="M20.8787 8.12136L19.5 9.5L14.5 4.5L15.8787 3.12135C17.0503 1.94978 18.9497 1.94978 20.1213 3.12136L20.8787 3.87872C22.0503 5.05029 22.0503 6.94978 20.8787 8.12136Z" fill="#12131A"/>
            </svg>`
})

export class DuotonePencilSvgIconComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
