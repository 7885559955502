import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { CreateBorrowerAccountMortgageRequest } from '../../models/create-borrower-account-mortgage-request.model';
import { CreateApplicationResponse } from '../../models/create-application-response.model';
import { ExistingAccountLoginResult } from '../../models/existing-account-login-result.model';
import { MortgageApplicationService } from '../../services/mortgage-application.service';
import { NavigationService } from '../../services/navigation.service';
import { AuthenticationRequest } from '../../models/auth/auth-request.model';
import { AuthService } from '../../services/auth.service';
import { LoginStatus } from '../../models/auth/login-status.enum';

@Component({
  selector: 'existing-user-action-selection-dialog',
  templateUrl: 'existing-user-action-selection-dialog.component.html',
  styleUrls: ['existing-user-action-selection-dialog.component.scss']
})
export class ExistingUserActionSelectionDialogComponent implements OnInit {

  @ViewChild('loginForm') loginForm: NgForm | undefined;

  @Input()
  userName: string | undefined;

  @Input()
  createApplicationRequest: CreateBorrowerAccountMortgageRequest | undefined;

  password: string | undefined;

  errorMessage: string | undefined;

  spinnerVisible: boolean = false;

  loginButtonText: string = "Login";

  protected applyingForANewLoan: boolean = false;

  private _result: ExistingAccountLoginResult = new ExistingAccountLoginResult();

  constructor(public activeModal: NgbActiveModal,
    private readonly _navigationService: NavigationService,
    private readonly _mortgageApplicationService: MortgageApplicationService,
    private readonly _authService: AuthService,
    private readonly _spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    this.password = this.createApplicationRequest.password;
  }

  onLoginClicked = () => {
    this.loginForm?.form.markAllAsTouched();
    if (this.loginForm?.form.valid && this.createApplicationRequest) {
      if (this.applyingForANewLoan) {
        this.loginAndCreateBrandNewApplication();
      } else {
        this.loginAndGotoBorrowerPortal();
      }
    }
  }

  private loginAndCreateBrandNewApplication = () => {
    this.createApplicationRequest.password = this.password;
    this.toggleProgress(true);
    this._mortgageApplicationService.saveLoanApplication(this.createApplicationRequest).subscribe((response: CreateApplicationResponse) => {
      if (response.authToken) {
        this._result.authToken = response.authToken;
        this._result.appId = response.applicationId;
        this.activeModal.close(this._result);
      } else {
        this.errorMessage = "Either your user name or your password is incorrect.";
        this.createApplicationRequest.password = null;
      }
    }, error => {
      this.errorMessage = "Not able to log you in at this time.";
    }).add(() => {
      this.toggleProgress(false);
    });
  }

  private loginAndGotoBorrowerPortal = () => {
    const loginRequest: AuthenticationRequest = {
      companyGuid: this.createApplicationRequest.companyGuid,
      username: this.userName,
      password: this.password,
      rememberMe: false,
      scope: 'Borrower'
    }
    this.toggleProgress(true);
    this._authService.signIn(loginRequest).subscribe(response => {
      this.toggleProgress(false);
      if (response.loginStatus === LoginStatus.Error) {
        this.errorMessage = response.errorMessage;
        this.createApplicationRequest.password = null;
        return;
      }
      this.activeModal.close();
      this._navigationService.navigateToPath("borrower-portal", true);
    }, error => {
      this.toggleProgress(false);
    });
  }

  onForgotPasswordClicked = () => {
    this._result.applyingForANewLoan = this.applyingForANewLoan;
    this._result.authToken = undefined;
    this._result.userForgotPassword = true;
    this.activeModal.close(this._result);
  }

  private toggleProgress = (value: boolean) => {
    if (value) {
      this._spinner.show();
    } else {
      this._spinner.hide();
    }
    this.spinnerVisible = value;
    if (value) {
      this.loginButtonText = "Please wait...";
    } else {
      this.loginButtonText = "Login";
    }
  }
}
