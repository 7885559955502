<div class="flow-step">
  <div class="d-flex">
    <div id="step-placeholder">
      <div style="margin-top: 25px; padding-left: 30px;">{{'Placeholder'}}</div>
      <div class="logic-indicator" data-bs-toggle="tooltip" data-bs-placement="top"
        title="{{'ELSE'}}">
        <i class="fas fa-bolt" style="margin-top:13px; margin-left: 13px"></i>
      </div>
    </div>
  </div>
</div>

