import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Borrower } from '../../../models/borrower-model';

@Component({
  selector: 'borrower-credit-mini',
  templateUrl: 'borrower-credit-mini.component.html'
})

export class BorrowerCreditMiniComponent implements OnInit {

  @ViewChild('borrowerInfoForm') borrowerInfoForm: NgForm | undefined;

  @Output() permissionToRunCreditChanged: EventEmitter<any> = new EventEmitter<any>();

  @Output() birthDateOrSsnChanged: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  borrowerIndex!: number;

  @Input()
  borrower!: Borrower;

  @Input()
  isCreditRun!: boolean;

  @Input()
  canRunCredit: boolean = false;

  constructor() { }

  ssnPattern = {'0': {pattern: new RegExp('\\d'), symbol: 'X'}};

  checked: boolean = false;

  onPermissionToRunCreditChanged(checked: boolean) {
    this.permissionToRunCreditChanged.emit({borrowerIndex: this.borrowerIndex, checked: checked});
  }

  onTextChange(event: any) {
    this.birthDateOrSsnChanged.emit();
  }

  validate = () => {
    this.borrowerInfoForm?.form.markAllAsTouched();
    if (this.borrowerInfoForm?.form.valid) {
        return true;
    }
    return false;
  }

  ngOnInit() { }
}
