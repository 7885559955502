

  <div class="modal-header">

    <h2>{{header}}</h2>

    <!-- hack to fool ngbModal autofocus -->
    <input type="text" style="display:none" />

    <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" aria-label="Close"
      (click)="activeModal.dismiss()">
      <span class="svg-icon svg-icon-2x">
        <close-x-svg-icon></close-x-svg-icon>
      </span>
    </div>
  </div>

  <div class="modal-body py-10 px-lg-17">
    <form #employmentInfoForm="ngForm" class="form" novalidate id="employmentInfoForm" name="employmentInfoForm">
      <div class="me-n7 pe-7">
        <div class="row mb-5">
          <div class="col-md-6 fv-row">
            <label class="required form-label mb-2">Employer Name</label>
            <input type="text" class="form-control form-control-solid"
              [ngClass]="{'is-invalid' : employer && employer.touched && employer.invalid}" #employer="ngModel"
              [(ngModel)]="employment.employer" placeholder="" name="employer" required />
            <div class="invalid-feedback">Employer name is required.</div>
          </div>
          <div class="col-md-6 fv-row">
            <label class="required form-label mb-2">Position</label>
            <input type="text" class="form-control form-control-solid"
              [ngClass]="{'is-invalid' : position && position.touched && position.invalid}" #position="ngModel"
              [(ngModel)]="employment!.position" placeholder="" name="position" required />
            <div class="invalid-feedback">Position is required.</div>
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-md-6 fv-row">
            <label class="required form-label mb-2">Start Date</label>
            <input class="form-control form-control-solid" matInput mask="M0/d0/0000" [showMaskTyped]="true" [specialCharacters]="[ '/' ]"
              [dropSpecialCharacters]="false" datePicker
              [ngClass]="{'is-invalid' : startDate && startDate.touched && startDate.invalid}" #startDate="ngModel" inputmode="numeric"
              required [(ngModel)]="employment!.startDate" placeholder="" name="startDate" ngControl="startDate" id="startDate" />
            <div [ngStyle]="{'display':startDate && startDate.touched && startDate.invalid ? 'block' : 'none' }" class="invalid-feedback">Start date is required.</div>
          </div>
          <div class="col-md-6 fv-row">
            <label class="form-label mb-2">End Date</label>
            <input class="form-control form-control-solid" matInput mask="M0/d0/0000" [showMaskTyped]="true"
              [specialCharacters]="[ '/' ]" [dropSpecialCharacters]="false" datePicker
              [ngClass]="{'is-invalid' : endDate && endDate.touched && endDate.invalid}" #endDate="ngModel" inputmode="numeric"
              [(ngModel)]="employment!.endDate" placeholder="" name="endDate" ngControl="endDate" id="endDate" />
            <div [ngStyle]="{'display':startDate && startDate.touched && startDate.invalid ? 'block' : 'none' }" class="invalid-feedback">End date is required.</div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer flex-center">
    <button type="button" class="btn btn-white me-3" (click)="activeModal.dismiss()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="onSaveClicked()">Ok</button>
  </div>




