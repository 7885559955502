<div class="post d-flex flex-column-fluid mt-8">
	<div class="container">
		<div class="card">
			<div class="card-body p-0">

				<div class="card-px text-center py-20 my-10">
					<ng-container *ngIf="!error">
						<h2 class="fs-2x fw-bolder mb-10">Creating Your Account</h2>
						<p class="text-gray-400 fs-4 fw-bold mb-4">We're finalizing the creation of your loan
							application.</p>
						<p class="text-gray-400 fs-4 fw-bold mb-10">Please wait...</p>
						<i class="fas fa-circle-notch fa-4x fa-spin"></i>
					</ng-container>
					<div class="error-message" *ngIf="error">
						<div class="alert bg-light-danger border border-danger border-dashed d-flex flex-column flex-sm-row w-100 p-5 mb-10">
							<i class="fas fa-exclamation-triangle fs-2hx text-danger me-4 mb-5 mb-sm-0"></i>
							<div class="d-flex flex-column pe-0 pe-sm-10">
								<h5 class="mb-1">Error</h5>
								<span>An error occurred while creating your application, please contact your loan representative.</span>
							</div>
						</div>
					</div>
				</div>

				<div class="text-center px-4">
					<img class="mw-100 mh-300px" alt="" src="assets/media/illustrations/terms-1.png" />
				</div>
			</div>
		</div>
	</div>
</div>