import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Address } from 'projects/borrower-app/src/app/models/address-model';
import { EnumsService } from 'projects/borrower-app/src/app/services/enums.service';
import { formViewProvider } from 'projects/borrower-app/src/app/services/form-view.provider';

@Component({
  selector: 'address-autocomplete',
  templateUrl: 'address-autocomplete.component.html',
  viewProviders: [formViewProvider],
})
export class AddressAutocompleteComponent {

  @Input() adressType: string = 'geocode';

  @Input() isItAForeignCountry: boolean = false;

  @Output() setGooglePickedAddress: EventEmitter<Address> = new EventEmitter<Address>();

  @Output() setManuallyEnteredAddress: EventEmitter<any> = new EventEmitter();

  @ViewChild('addressText') addressText: any;

  @Input() autocompleteInput: string | undefined;

  @Input()
  disabled: boolean = false;

  @Input()
  required: boolean = true;

  @Input()
  name: string;

  @Input()
  id: string;

  options: any = {
    types: ['geocode'],
    componentRestrictions: { country: 'us' },
  };

  private _map: any;
  protected autoCompleteDirectiveName: string;

  // Hack to mark the control as touched for general validation
  markAsTouched = () => {
    this.addressText.nativeElement.focus();
    this.addressText.nativeElement.blur();
  };

  constructor(private readonly _enumsService: EnumsService) { }

  onAddressChanged = () => {
    this.setManuallyEnteredAddress.emit(this.autocompleteInput);
  };

  handleAddressChange = (e: any) => {
    let address = new Address();
    address.address1 = "";
    setTimeout(() => {
      e.address_components.forEach((component: { types: any; long_name: any; short_name: any; }) => {
        let types = component.types;
        types.forEach((type: string) => {
          if (type === 'locality' || type === 'sublocality') {
            address.city = component.long_name;
          }
          if (type === 'administrative_area_level_1') {
            const state = this._enumsService.states.find(s => s.name === component.short_name);
            if (state) {
              address.state = state.value;
            }
          }
          if (type === 'administrative_area_level_2') {
            address.county = component.short_name;
          }
          if (type === 'postal_code') {
            address.zipCode = component.long_name;
          }
          if (type === 'street_number') {
            if (component.short_name) {
              address.address1 = component.short_name;
            }
          }
          if (type === 'route') {
            if (component.short_name) {
              address.address1 += " " + component.short_name;
            }
          }
        });
      });
      this.setGooglePickedAddress.emit(address);
    }, 200);
  }
}
