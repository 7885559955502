
<div class="modal-header">

    <h2>Credit Check Authorization</h2>

    <!-- hack to fool ngbModal autofocus -->
    <input type="text" style="display:none" />

    <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" aria-label="Close"
      (click)="activeModal.dismiss()">
      <span class="svg-icon svg-icon-2x">
        <close-x-svg-icon></close-x-svg-icon>
      </span>
    </div>
  </div>

  <div class="modal-body py-10 px-lg-17">
    <h4>{{message}}</h4>
    <form #creditCheckConsentForm="ngForm" class="form" novalidate id="creditCheckConsentForm" name="creditCheckConsentForm">
      <div class="py-6">
       <div id="creditCheckConsentContainer" class="text-break"></div>
        <div class="col-lg-12 fv-row mb-6">
          <div class="d-flex align-items-center mt-3">
            <label class="form-check form-check-inline form-check-solid me-5">
              <input class="form-check-input" required name="consentToDoCreditCheckAuthorizationCheckbox" #consent="ngModel"
                [ngClass]="{'is-invalid' : consent && consent.touched && consent.invalid}"
                [(ngModel)]="consentGivenToCheckCredit" type="checkbox" value="1" />
              <span class="fw-bold ps-2 fs-6 required">I/We Consent</span>
              <div class="invalid-feedback">Consent is required</div>
            </label>
            </div>
          </div>
      </div>
    </form>
  </div>

  <div class="modal-footer flex-center">
    <button type="button" class="btn btn-white me-3" (click)="activeModal.dismiss()">Cancel</button>
    <button type="button" class="btn btn-primary" [disabled]="!consentGivenToCheckCredit" (click)="onConsentGivenClicked()">
      {{creditWillBeRunLater ? 'Run My Credit Later' : 'Run My Credit Now'}}
    </button>
  </div>




