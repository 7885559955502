import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApplicationTaskModel } from '../../../models/borrower-portal/application-task-model';
import { Observer } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { BorrowerVerificationOrderHistory } from '../../../models/borrower-verification-order-history.model';
import { BorrowerVerificationUserEnrollmentResponse } from '../../../models/borrower-verification-user-enrollment.model';
import { IncomeService } from '../../../services/income-service';

@Component({
  selector: 'income-employment-verification-dialog',
  templateUrl: './income-employment-verification-dialog.component.html',
  styleUrls: ['./income-employment-verification-dialog.component.scss']
})
export class IncomeEmploymentVerificationDialogComponent implements OnInit {

  @Input() applicationId: number;
  @Input() task: ApplicationTaskModel;
  @Input() voeEnabled: boolean = false;

  protected provider: "AccountCheck" | "Truv" = "Truv";
  protected thirdPartyEnrollmentWidget: string = "";

  protected loading: boolean = true;

  constructor(public activeModal: NgbActiveModal,
    private _spinner: NgxSpinnerService,
    private _incomeService: IncomeService,
  ) {}

  ngOnInit(): void {
    const observer: Observer<BorrowerVerificationOrderHistory> = {
      next: (order: BorrowerVerificationOrderHistory) => {
        if (order) {
          this.provider = order.integrationProvider as "AccountCheck" | "Truv";
        }
        this.setEnrollmentWidget();
      },
      error: (err: any) => {
        if (err.status == 404) {
          this.setEnrollmentWidget();
        } else {
          console.log(err);
        }
      },
      complete: () => {
      }
    }

    this._spinner.show();
    this._incomeService.getVerificationOrderHistoryForTask(this.task.taskId).subscribe(observer)
      .add(() => {
        this._spinner.hide();
      });
  }

  setEnrollmentWidget(): void {
    // TODO: Need to find provider to use based on task
    this.loading = true;
    this._incomeService.initializeBorrowerIncomeAndEmploymentVerification(this.task.borrowerId, this.applicationId, this.provider).subscribe({
      next: (response: BorrowerVerificationUserEnrollmentResponse) => {
        this.thirdPartyEnrollmentWidget = response.htmlWidgetSrc;
      },
      error: (error: any) => {
        console.log(error);
      },
      complete: () => {
        console.log('complete');
      }
    }).add(() => {
      this.loading = false;
    });
  }
}
