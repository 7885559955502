import { AddressHistory } from "./address-history-model";
import { BorrowerCounselingEvent } from "./borrower-counseling-event.model";
import { CreditInquiryDetails } from "./credit-inquiry-details.model";
import { Declarations } from "./declarations-model";
import { Demographics } from "./demographics-model";
import { Employment } from "./employment-model";
import { OwnOrRent } from "./enums/own-rent.enum";
import { ResidencyType } from "./enums/residency-type.enum";
import { IHaveName } from "./have-name.interface";
import { Income } from "./income-model";
import { ResidencyAddress } from "./residency-address.model";

export class Borrower implements IHaveName {
  borrowerId: number | undefined;
  contactId: number | undefined;
  firstName: string = "";
  middleName: string = "";
  lastName: string = "";
  nameSuffix: string = "";
  primaryEmail: string = "";
  mobilePhone: string = "";
  homePhone: string = "";
  phoneNumber: string = "";
  ownOrRent: OwnOrRent | undefined;
  creditScore: number | undefined;
  currentAddressHistory: AddressHistory | undefined;
  nonEmploymentIncomes: Income[] = [];
  employments: Employment[] = [];
  declarations: Declarations | undefined;
  governmentMonitors: Demographics;
  residencyAddresses: ResidencyAddress[] = [];
  counselingEvents: BorrowerCounselingEvent[] = [];
  dateOfBirth: string = "";
  socialSecNum: string = "";
  maritalStatus: string | undefined | null = null;
  dependents: number[] = [];
  hasServedInMilitary: boolean = false;
  militaryTourEndDate: string = "";
  branchOfService: string | null = null;
  isFirstUseOfVaBenefit: boolean = false;
  isVaFundingFeeExempt: boolean = false;
  isSurvivingSpouseOfMilitary: boolean = false;
  isActiveDutyMilitary: boolean = false;
  onlyNonActivatedReserveOrNationalGuard: boolean = false;
  isRetiredDischargedSeparatedFromMilitary: boolean = false;

  //not in the model
  permissionToRunCredit: boolean | undefined;
  creditInquiry: CreditInquiryDetails | undefined;
  permissionToRunIncome: boolean | undefined;
  willBeEdited: boolean = false;
  isPrimary: boolean;
  jointWithBorrowerId?: number | undefined;
  printApplicationIndex?: number | undefined;
  authorizedCreditCheck?: boolean | undefined;
  dateAuthorizedCreditCheck?: string | undefined;
  authorizationMethod?: string | undefined;
  hasValidEConsent?: boolean | undefined;

  languagePreference?: PredefinedLanguagePreference | string | null = null;
  languagePreferenceOtherDescription?: string;
  mortgagePartyType?: string | null = null;

  isMarriedToPrimaryBorrower?: boolean | null = null;

  constructor() {
    const currentAddress = new ResidencyAddress(ResidencyType.PresentAddress);
    this.residencyAddresses?.push(currentAddress);
    this.declarations = new Declarations();
    this.governmentMonitors = new Demographics();
    this.maritalStatus = null;
    this.languagePreference = null;
    this.nameSuffix = null;
    this.hasServedInMilitary = null;
  }
}

export type PredefinedLanguagePreference =
  | 'Chinese'
  | 'English'
  | 'Korean'
  | 'Spanish'
  | 'Tagalog'
  | 'Vietnamese'
  | 'Other'
  ;
