import { Directive } from "@angular/core";
import { AbstractControl, ValidationErrors, NG_VALIDATORS } from "@angular/forms";

export function requireDigitValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
        const regexp = /[0-9]/g;
        const isValid = !control.value || regexp.test(control.value);
        return isValid ? null : { requiresDigit: { value: control.value } };
    }
}
@Directive({
    selector: '[requiresDigit]',
    providers: [
        {
          provide: NG_VALIDATORS,
          useExisting: RequireDigitValidatorDirective,
          multi: true,
        },
      ],
})
export class RequireDigitValidatorDirective {
    validate(control: AbstractControl): ValidationErrors | null {
      return requireDigitValidator()(control);
    }
}