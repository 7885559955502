	<!--begin::Footer-->
  <div class="footer py-4">
    <!--begin::Container-->
    <div class="container align-items-center" [ngClass]="{'justify-content-between': industryAffiliations.length}">
      <!--begin::Copyright-->
      <div class="text-dark order-2 order-md-1 d-flex flex-stack">
        <div class="d-flex">
          <span class="text-muted fw-bold me-1">{{currentYear}}©</span>
          <a href="https://www.lodasoft.com" height="24" target="_blank" class="text-gray-800 text-hover-primary">Lodasoft</a>
          <a href="https://www.lodasoft.com/privacy-policy"  height="24" target="_blank" class="text-gray-800 text-hover-primary ms-4">Privacy Policy</a>
        </div>
        <div *ngIf="industryAffiliations.length" class="industry-affiliations-container">
          <ng-container *ngFor="let aff of industryAffiliations">
            <img src="/assets/images/industry-affiliations/fdic.png" class="mx-2" height="56" alt="logo" *ngIf="aff == 'Fdic'" />
            <img src="/assets/images/industry-affiliations/ehl.jpg" class="mx-2" height="56" alt="logo" *ngIf="aff == 'Ehl'" />
            <img src="/assets/images/industry-affiliations/eho.jpg" class="mx-2" height="56" alt="logo" *ngIf="aff == 'Eho'" />
            <img src="/assets/images/industry-affiliations/ncua.jpg" class="mx-2" height="56" alt="logo" *ngIf="aff == 'Ncua'" />
            <img src="/assets/images/industry-affiliations/mba.jpg" class="mx-2" height="56" alt="logo" *ngIf="aff == 'Mba'" />
          </ng-container>
        </div>
        <!-- <span *ngIf="isDefaultFlow">Using Default Flow</span> -->
      </div>
      <!--end::Copyright-->
      <!--begin::Menu-->
      <!-- <ul class="menu menu-gray-600 menu-hover-primary fw-bold order-1">
        <li class="menu-item">
          <a href="https://lodasoft.com/faqs" target="_blank" class="menu-link px-2">About</a>
        </li>
        <li class="menu-item">
          <a href="https://lodasoft.com/support" target="_blank" class="menu-link px-2">Support</a>
        </li>
        <li class="menu-item">
          <a href="https://lodasoft.com" target="_blank" class="menu-link px-2">Purchase</a>
        </li>
      </ul> -->
      <!--end::Menu-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Footer-->
