import { SingleChoiceQuestion } from "../single-choice-question.model";
import { ContextType } from "./context-type.enum";
import { NextStepDeciderConfig } from "./next-step-decider-config.model";
import { OptionsProviderTypes } from "./options-providers/options-provider.factory";
import { StepConfig } from "./step-config.model";
import { StepType } from "./step-type.enum";

export class SingleChoiceQuestionStepConfig extends StepConfig {

    question!: SingleChoiceQuestion | undefined;

    fieldToSetWithAnswer!: string;

    listOptionsVertically: boolean = false;

    optionsProviderType: OptionsProviderTypes | undefined | null = null;

    nameOfDefaultOptionToSetAndSkip: any | undefined | null = null;

    constructor(id: string, name: string | undefined, path: string, groupId: number | undefined,
        contextType: ContextType,
      question: SingleChoiceQuestion | undefined, nextStepDeciderConfig: NextStepDeciderConfig | undefined,
      stepType?: StepType
      ) {
      super(id, name, path, groupId, contextType);
      this.question = question;
      this.nextStepDeciderConfig = nextStepDeciderConfig;
      if (!stepType) {
        this.stepType = StepType.SingleChoiceQuestionStep;
      } else {
        this.stepType = stepType;
      }
    }
}
