import { ContextType } from "./context-type.enum";
import { FieldConfigUtils } from "./field-config-utils";
import { NextStepDeciderConfig } from "./next-step-decider-config.model";
import { StepConfig } from "./step-config.model";
import { StepType } from "./step-type.enum";

export class MyInfoStepConfig extends StepConfig {

  configuresAddressHistory: boolean = true;

  restrictMiddleNameToSingleCharacter: boolean = false;

  configuresCoborrowers: boolean = true;

  borrowerFieldsConfig: any = {};

  addressFieldsConfig: any = {};

  constructor(id: string, name: string | undefined, path: string, groupId: number | undefined, nextStepDeciderConfig: NextStepDeciderConfig | undefined) {
    super(id, name, path, groupId, ContextType.None);
    this.stepType = StepType.MyInfoStep;
    this.nextStepDeciderConfig = nextStepDeciderConfig;
    this.borrowerFieldsConfig = FieldConfigUtils.generateBorrowerFieldsConfig();
    this.addressFieldsConfig = FieldConfigUtils.generateAddressFieldsConfig();
  }
}
