<div *ngIf="scenario" class="w-100">
  <div class="row">
    <label class="col-sm-6 col-form-label" for="interest"
      >Principal And Interest</label
    >
    <div class="col-sm-6">
      <input
        type="text"
        class="form-control"
        prefix="$ "
        mask="separator.2"
        thousandSeparator=","
        inputmode="decimal"
        [disabled]="true"
        name="principalAndInterest"
        [(ngModel)]="scenario.principalAndInterest"
      />
    </div>
  </div>
  <div class="row mt-1">
    <label class="col-sm-6 col-form-label" for="monthlyMi">Monthly MI</label>
    <div class="col-sm-6">
      <input
        type="text"
        class="form-control"
        prefix="$ "
        mask="separator.2"
        thousandSeparator=","
        inputmode="decimal"
        [disabled]="true"
        name="monthlyMi"
        [(ngModel)]="scenario.monthlyMi"
      />
    </div>
  </div>
  <div class="row mt-1">
    <label class="col-sm-6 col-form-label" for="monthlyInsurance"
      >Monthly Insurance</label
    >
    <div class="col-sm-6">
      <input
        type="text"
        class="form-control"
        prefix="$ "
        mask="separator.2"
        thousandSeparator=","
        inputmode="decimal"
        [disabled]="true"
        name="monthlyInsurance"
        [(ngModel)]="scenario.monthlyInsurance"
      />
    </div>
  </div>
  <div class="row mt-1">
    <label class="col-sm-6 col-form-label" for="monthlyTaxes"
      >Monthly Taxes</label
    >
    <div class="col-sm-6">
      <input
        type="text"
        class="form-control"
        prefix="$ "
        mask="separator.2"
        thousandSeparator=","
        inputmode="decimal"
        [disabled]="true"
        name="monthlyTaxes"
        [(ngModel)]="scenario.monthlyTaxes"
      />
    </div>
  </div>
  <div class="row mt-1">
    <label class="col-sm-6 col-form-label" for="totalPayment"
      >Total Payment</label
    >
    <div class="col-sm-6">
      <input
        type="text"
        class="form-control"
        prefix="$ "
        mask="separator.2"
        thousandSeparator=","
        inputmode="decimal"
        [disabled]="true"
        name="totalPayment"
        [(ngModel)]="scenario.totalPayment"
      />
    </div>
  </div>
</div>
