import { Injectable } from '@angular/core';
import { WizardGroupEnum } from '../../models/enums/wizard-group-enum';
import { Flow } from '../../models/flow.model';
import { AddressFields } from '../../models/wizard/address-fields.model';
import { AddressStepConfig } from '../../models/wizard/config/address-step-config.model';
import { ChecklistsStepConfig } from '../../models/wizard/config/checklists-step-config.model';
import { ContextType } from '../../models/wizard/config/context-type.enum';
import { CreateAccountStepConfig } from '../../models/wizard/config/create-account-step-config.model';
import { CreditScoresStepConfig } from '../../models/wizard/config/credit-scores-step.config.model';
import { ExpressionNextStepDeciderConfig } from '../../models/wizard/config/expression-next-step-decider-config.model';
import { FieldConfigUtils } from '../../models/wizard/config/field-config-utils';
import { FlowType } from '../../models/wizard/config/flow-type.enum';
import { GenericStepConfig } from '../../models/wizard/generic-step-config.model';
import { MyInfoStepConfig } from '../../models/wizard/config/my-info-step-config.model';
import { NavigationType } from '../../models/wizard/config/nav-type.enum';
import { SimpleNextStepDeciderConfig } from '../../models/wizard/config/simple-next-step-decider-config.model';
import { SingleChoiceQuestionStepConfig } from '../../models/wizard/config/single-choice-question-step-config.model';
import { StepConfig } from '../../models/wizard/config/step-config.model';
import { StepType } from '../../models/wizard/config/step-type.enum';
import { WizardFlowConfig, WizardFlowConfigs } from '../../models/wizard/config/wizard-flow-config.model';
import { Option } from '../../models/wizard/option.model';
import { SingleChoiceQuestion } from '../../models/wizard/single-choice-question.model';
import { StepExpression } from '../../models/wizard/step-expression.model';
import { WizardStepBase } from '../../models/wizard/wizard-step-base.model';
import { Constants } from '../constants';
import { DataService } from '../data.service';
import { FlowBuilderService } from '../flow-builder.service';
import { UtilsService } from '../utils.service';
import { WizardFlowConfigServiceBase } from './wizard-flow-config-service.base';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { OptionsProviderTypes } from '../../models/wizard/config/options-providers/options-provider.factory';

@Injectable()
export class WizardFlowConfigService extends WizardFlowConfigServiceBase {

  private _wizardFlowConfigs!: WizardFlowConfigs;

  private _defaultFlowConfigs!: WizardFlowConfigs;

  constructor(private readonly _utilsService: UtilsService,
    private readonly _dataService: DataService,
    private readonly _flowBuilderService: FlowBuilderService) {
    super();
  }

  public get flowConfigs(): WizardFlowConfigs {
    return this._wizardFlowConfigs;
  }

  public get defaultFlowConfigs(): WizardFlowConfigs {
    if (!this._defaultFlowConfigs) {
      this.generateDefaultFlows();
    }
    return this._defaultFlowConfigs;
  }

  getConfig = (flowType: FlowType, step: WizardStepBase): StepConfig | undefined => {
    let flowToSearchStepIn = this._wizardFlowConfigs.registrationFlowConfig;
    const flowTypeToLookFor = Number(flowType);
    switch (flowTypeToLookFor) {
      case FlowType.Apply:
        flowToSearchStepIn = this._wizardFlowConfigs.applicationFlowConfig;
        break;
      case FlowType.CoApply:
        flowToSearchStepIn = this._wizardFlowConfigs.coApplicationFlowConfig;
        break;
    }

    let config = flowToSearchStepIn.steps.find(c => c.path === step.path);
    return config;
  }

  public configureFlows = (flowGuid: string | undefined, useCompanyDefaultIfAny: boolean): Observable<WizardFlowConfigs> | undefined => {
    // Here - make a call into the flow builder API to get the flow config from the server.
    // If there is none, use the default one, and then save it.
    if (flowGuid && flowGuid.length > 0) {
      return this._flowBuilderService.getFlow(flowGuid).pipe(
        switchMap((flow: Flow) => {
          if (flow) {
            this.generateFlowConfigs(flow);
            return of(this._wizardFlowConfigs);
          } else {
            return this.configureDefaultFlows(useCompanyDefaultIfAny);
          }
        }), catchError((error) => this.configureDefaultFlows(useCompanyDefaultIfAny)));
    }
    return this.configureDefaultFlows(useCompanyDefaultIfAny);
  }

  private configureDefaultFlows = (useCompanyDefaultIfAny: boolean): Observable<WizardFlowConfigs> | undefined => {
    const companyGuid = this._utilsService.getUrlParameter(Constants.companyGuid);
    if (companyGuid && useCompanyDefaultIfAny) {
      return this._dataService.getWithoutAuth('api/flow-builder/default-by-company-guid/' + companyGuid).pipe(map(result => {
        if (result) {
          this.generateFlowConfigs(result);
          return this._wizardFlowConfigs;
        } else {
          this._wizardFlowConfigs = this.generateDefaultFlows();
          return this._wizardFlowConfigs;
        }
      }, error => {
        this._wizardFlowConfigs = this.generateDefaultFlows();
        return this._wizardFlowConfigs;
      }));
    }
    this._wizardFlowConfigs = this.generateDefaultFlows();
    return of(this._wizardFlowConfigs);
  }

  private generateDefaultFlows = (): WizardFlowConfigs => {
    if (this._defaultFlowConfigs) {
      return this._defaultFlowConfigs;
    }
    let registrationFlowConfig = this.generateDefaultRegistrationFlowConfig();
    let applicationFlowConfig = this.generateDefaultApplicationFlowConfig();
    let coApplicationFlowConfig = this.generateDefaultCoApplicationFlowConfig();
    let wizardFlowConfigs = new WizardFlowConfigs(applicationFlowConfig, coApplicationFlowConfig, registrationFlowConfig);
    wizardFlowConfigs.isDefault = true;
    wizardFlowConfigs.userCreationAutoConfirmed = false;
    wizardFlowConfigs.navigationType = NavigationType.Horizontal;
    this._defaultFlowConfigs = wizardFlowConfigs;
    return wizardFlowConfigs;
  }

  private generateDefaultCoApplicationFlowConfig = (): WizardFlowConfig => {
    let coApplyFlowConfig = new WizardFlowConfig();
    coApplyFlowConfig.isDefault = true;

    /* Begin - My Info */
    const myInfoStepNextStepDeciderConfig = new SimpleNextStepDeciderConfig(Constants.stepPaths.address);
    const myInfoStepConfig = new MyInfoStepConfig('6', 'My info', Constants.stepPaths.myInfo, WizardGroupEnum.MyInfo,
      myInfoStepNextStepDeciderConfig);
    myInfoStepConfig.title = 'Tell us a little more about yourself.';
    myInfoStepConfig.configuresCoborrowers = false;
    myInfoStepConfig.configuresAddressHistory = false;
    coApplyFlowConfig.steps.push(myInfoStepConfig);
    /* End - My Info */

    /* Begin - Current Address */
    const currentAddressStepConfig = new GenericStepConfig('10', 'Current Address', Constants.stepPaths.address, WizardGroupEnum.MyInfo,
      StepType.CurrentAddressStep, new SimpleNextStepDeciderConfig(Constants.stepPaths.ownershipStatus));
    currentAddressStepConfig.title = 'What is your current address?';
    coApplyFlowConfig.steps.push(currentAddressStepConfig);
    /* End - Current Address */

    /* Begin - Own Rent */
    const ownRentStepConfig = new GenericStepConfig('11', 'Own, Rent or Live Rent Free', Constants.stepPaths.ownershipStatus, WizardGroupEnum.MyInfo,
      StepType.OwnershipStatusStep, new SimpleNextStepDeciderConfig(Constants.stepPaths.addressHistory));
    ownRentStepConfig.heading = 'Do you...';
    coApplyFlowConfig.steps.push(ownRentStepConfig);
    /* End - Own Rent */

    /* Begin - Address History */
    const addressHistoryStepConfig = new GenericStepConfig('12', 'Address History', Constants.stepPaths.addressHistory, WizardGroupEnum.MyInfo,
      StepType.AddressHistoryStep, new SimpleNextStepDeciderConfig(Constants.stepPaths.manualOrDigitalEmploymentAndIncomeChoice));
    addressHistoryStepConfig.explanation = 'Please provide at least 2 years of address history';
    let addressHistoryStepFieldConfig: any = FieldConfigUtils.generateAddressHistoryStepFieldConfig();
    addressHistoryStepConfig.fieldConfig = addressHistoryStepFieldConfig;
    coApplyFlowConfig.steps.push(addressHistoryStepConfig);
    /* End - Address History */

    /* Begin - Choose digital or manual employment-income step */
    const digialEmploymentAndIncomeOption = new Option('digital', 'Digitally', 'Securely verify your income electronically, eliminating the need for paper copies',
      '1', 'laptop-svg-icon');
    const manualEmploymentAndIncomeOption = new Option('manual', 'Manually', 'Manually enter any employment and income information and provide paper statements later',
      '2', 'clipboard-svg-icon');
    const employmentAndIncomeQuestion = new SingleChoiceQuestion('', '', [digialEmploymentAndIncomeOption, manualEmploymentAndIncomeOption]);
    const employmentAndIncomeStepConfig = new SingleChoiceQuestionStepConfig('14', 'Employment and Income', Constants.stepPaths.manualOrDigitalEmploymentAndIncomeChoice,
      WizardGroupEnum.MyFinances,
      ContextType.CustomerPreferences,
      employmentAndIncomeQuestion,
      new ExpressionNextStepDeciderConfig(new StepExpression('customerPreferences.gatherEmploymentAndIncomeDigitally == 2', Constants.stepPaths.manualIncomeAndEmployment), [], Constants.stepPaths.digitalIncomeAndEmployment,
      ));
    employmentAndIncomeStepConfig.fieldToSetWithAnswer = 'gatherEmploymentAndIncomeDigitally';
    employmentAndIncomeStepConfig.title = 'Tell us about your employment and income';
    employmentAndIncomeStepConfig.explanation = 'Select how you want to tell us about your employment and income';
    coApplyFlowConfig.steps.push(employmentAndIncomeStepConfig);
    /* End - Configure digital employment/income verification step */

    /* Begin - Configure income step */
    const incomeStepConfig = new GenericStepConfig('15', 'Income', Constants.stepPaths.manualIncomeAndEmployment, WizardGroupEnum.MyFinances, StepType.ManualIncomeAndEmploymentStep,
      new SimpleNextStepDeciderConfig(Constants.stepPaths.manualOrDigitalAssetChoice));
    incomeStepConfig.title = 'Tell us about your Income/Employment history.';
    incomeStepConfig.explanation = 'Please specify what type of income you have by adding all that apply';
    let incomeStepFieldConfig: any = FieldConfigUtils.generateIncomeStepFieldConfig();
    incomeStepConfig.fieldConfig = incomeStepFieldConfig;
    coApplyFlowConfig.steps.push(incomeStepConfig);
    /* End - Configure income step */

    /* Begin - Configure digital employment/income verification step */
    const digitalEmploymentIncomeStepConfig = new GenericStepConfig('16', 'Digital Income', Constants.stepPaths.digitalIncomeAndEmployment, WizardGroupEnum.MyFinances,
      StepType.DigitalIncomeAndEmploymentStep, new SimpleNextStepDeciderConfig(Constants.stepPaths.manualIncomeAndEmployment));
    digitalEmploymentIncomeStepConfig.title = 'Digital Income and Employment Verification';
    digitalEmploymentIncomeStepConfig.explanation = 'Securely gathering digital assets eliminates the need to track down paper statements.';
    coApplyFlowConfig.steps.push(digitalEmploymentIncomeStepConfig);
    /* End - Configure digital employment/income verification step */

    /* Begin - Choose digital or manual asset step */
    const digitalAssetOption = new Option('digital', 'Digitally', 'Securely gather your bank statements electronically, eliminating the need for paper copies',
      '1', 'laptop-svg-icon');
    const manualAssetOption = new Option('manual', 'Manually', 'Manually enter any asset information and provide paper statements later',
      '2', 'clipboard-svg-icon');
    const assetQuestion = new SingleChoiceQuestion('', '', [digitalAssetOption, manualAssetOption]);
    const assetManualOrDigitalStepConfig = new SingleChoiceQuestionStepConfig('17', 'Asset Manual or Digital', Constants.stepPaths.manualOrDigitalAssetChoice,
      WizardGroupEnum.MyFinances, ContextType.CustomerPreferences, assetQuestion,
      new ExpressionNextStepDeciderConfig(new StepExpression('customerPreferences.gatherAssetsDigitally == 2', Constants.stepPaths.manualAssets), [], Constants.stepPaths.digitalAssets,
      ));
    assetManualOrDigitalStepConfig.fieldToSetWithAnswer = 'gatherAssetsDigitally';
    assetManualOrDigitalStepConfig.title = 'Tell us about your assets';
    assetManualOrDigitalStepConfig.explanation = 'Select how you want to tell us about your assets';
    coApplyFlowConfig.steps.push(assetManualOrDigitalStepConfig);
    /* End - Choose digital or manual asset step */

    /* Begin - Digital assets step */
    const digitalAssetsStepConfig = new GenericStepConfig('18', 'Digital Assets', Constants.stepPaths.digitalAssets, WizardGroupEnum.MyFinances,
      StepType.DigitalAssetsStep, new SimpleNextStepDeciderConfig(Constants.stepPaths.reo));
    digitalAssetsStepConfig.title = 'Digital Assets';
    digitalAssetsStepConfig.explanation = 'Securely gathering digital assets eliminates the need to track down paper statements.' +
      'Search for your financial institution and connect  with them the same way you do when you visit them on the web.';
    coApplyFlowConfig.steps.push(digitalAssetsStepConfig);
    /* End - Digital assets step */

    /* Begin - Configure assets step */
    const assetsStepConfig = new GenericStepConfig('19', 'Assets', Constants.stepPaths.manualAssets, WizardGroupEnum.MyFinances,
      StepType.ManualAssetsStep, new SimpleNextStepDeciderConfig(Constants.stepPaths.reo));
    assetsStepConfig.title = 'Now, we need to collect some Asset details.';
    assetsStepConfig.explanation = 'Click Add below, choose an asset type and enter the amount for the asset. Please note, we will need to verify enough assets to cover any funds you will need during this transaction.';
    let assetsStepFieldConfig: any = FieldConfigUtils.generateAssetsStepFieldConfig();
    assetsStepConfig.fieldConfig = assetsStepFieldConfig;
    coApplyFlowConfig.steps.push(assetsStepConfig);
    /* End - Configure assets step */

    /* Begin - Configure REO step */
    const reoStepConfig = new GenericStepConfig('20', 'Real Estate Owned', Constants.stepPaths.reo, WizardGroupEnum.MyFinances,
      StepType.ReoStep,
      new ExpressionNextStepDeciderConfig(new StepExpression('!skipCreditCheck', Constants.stepPaths.verifyCredit), [],
        Constants.stepPaths.loanInfo));
    reoStepConfig.title = 'Real Estate Information.';
    reoStepConfig.explanation = 'If you currently own any home or other type of real estate, please enter it below. If you don\'t own any real estate, just continue.';
    let reoStepFieldConfig: any = FieldConfigUtils.generateReoStepFieldConfig();
    reoStepConfig.fieldConfig = reoStepFieldConfig;
    coApplyFlowConfig.steps.push(reoStepConfig);
    /* End - Configure REO step */

    /* Begin - Configure verify credit step */
    const verifyCreditStepConfig = new GenericStepConfig('21', 'Verify Credit', Constants.stepPaths.verifyCredit, WizardGroupEnum.MyFinances,
      StepType.VerifyCreditStep,
      new ExpressionNextStepDeciderConfig(new StepExpression('customerPreferences.doNotRunCredit == 0 && !creditRunFailed && !skipAutomatedCreditCheck', Constants.stepPaths.creditScores), [], Constants.stepPaths.declarations));
    verifyCreditStepConfig.title = 'Credit Verification';
    verifyCreditStepConfig.heading = `Now, we need to review all applicants' credit reports. Please provide the following information: `;
    coApplyFlowConfig.steps.push(verifyCreditStepConfig);
    /* End - Configure verify credit step */

    /* Begin - Credit scores step */
    const nextStepDecider = new ExpressionNextStepDeciderConfig(new StepExpression('mortgage.liabilities.length == 0', Constants.stepPaths.creditInquiry), [], Constants.stepPaths.reviewCredit);
    const creditScoresStepConfig = new CreditScoresStepConfig('22', 'Credit Scores', Constants.stepPaths.creditScores, WizardGroupEnum.MyFinances, nextStepDecider);
    creditScoresStepConfig.title = 'Here are your credit scores:';
    creditScoresStepConfig.useScoreIndicatorIcons = true;
    coApplyFlowConfig.steps.push(creditScoresStepConfig);
    /* End - Credit scores step */

    /* Begin - Configure review credit step */
    const reviewCreditStepConfig = new GenericStepConfig('23', 'Review Credit', Constants.stepPaths.reviewCredit, WizardGroupEnum.MyFinances, StepType.ReviewCreditStep,
      new SimpleNextStepDeciderConfig(Constants.stepPaths.creditInquiry));
    reviewCreditStepConfig.title = 'Congratulations!! We were able to retrieve your credit report. ';
    reviewCreditStepConfig.heading = 'Please link your Mortgage Liabilities to the Real Estate owned.';
    coApplyFlowConfig.steps.push(reviewCreditStepConfig);
    /* End - Configure verify credit step */

    /* Begin - Configure Credit Inquiry Step */
    const creditInquiryStepConfig = new GenericStepConfig('24', 'Credit Inquiry', Constants.stepPaths.creditInquiry, WizardGroupEnum.MyFinances,
      StepType.CreditInquiryStep, new SimpleNextStepDeciderConfig(Constants.stepPaths.hoi));
    creditInquiryStepConfig.title = 'Credit Inquiries';
    //const numberOfCreditInquiries = 10; // this._mortgageApplicationService.getInquiryCount(this._mortgageApplication);
    creditInquiryStepConfig.heading = 'Based on the credit report, here are the credit inquires in the past 120 days. ' +
      ' Please select an option for each inquiry below, and if any new debt was acquired.';
    coApplyFlowConfig.steps.push(creditInquiryStepConfig);
    /* End -  Configure Credit Inquiry Step */

    /* Begin - Configure HOI step */
    const hoiStepConfig = new GenericStepConfig('34', "Home Owner's Insurance", Constants.stepPaths.hoi, WizardGroupEnum.PropertyAndLoan,
      StepType.HoiStep,
      new SimpleNextStepDeciderConfig(Constants.stepPaths.declarations));
    hoiStepConfig.title = "Let's review your homeowner's insurance needs";
    hoiStepConfig.heading = ``;
    coApplyFlowConfig.steps.push(hoiStepConfig);
    /* End - Configure HOI step */

    /* Begin - Configure Declarations step */
    const declarationsStepConfig = new GenericStepConfig('26', 'Declarations', Constants.stepPaths.declarations, WizardGroupEnum.Declarations,
      StepType.DeclarationsStep, new SimpleNextStepDeciderConfig(Constants.stepPaths.militaryService));
    declarationsStepConfig.title = 'Declarations';
    declarationsStepConfig.explanation = 'Government Declaration and Monitoring Questions';
    coApplyFlowConfig.steps.push(declarationsStepConfig);
    /* End - Configure Declarations step */

    /* Begin - Configure Military Service step */
    const militaryServiceStepConfig = new GenericStepConfig('33', 'Military Service', Constants.stepPaths.militaryService, WizardGroupEnum.Declarations,
      StepType.MilitaryServiceStep, new SimpleNextStepDeciderConfig(Constants.stepPaths.demographics));
    militaryServiceStepConfig.title = 'Military Service';
    militaryServiceStepConfig.explanation = 'Please provide the following information as it pertains to military service. This will help us better understand which products you might qualify for.';
    coApplyFlowConfig.steps.push(militaryServiceStepConfig);
    /* End - Configure Military Service step */

    /* Begin - Configure Demographics step */
    const demographicsStepConfig = new GenericStepConfig('27', 'Demograghics', Constants.stepPaths.demographics, WizardGroupEnum.Declarations,
      StepType.DemographicsStep, new SimpleNextStepDeciderConfig(Constants.stepPaths.loanCharacteristics));
    demographicsStepConfig.title = 'Demographics';
    demographicsStepConfig.explanation = 'What is your demographic information? ' +
      'You do not have to provide this information, but we are required by the Federal Government to ask.';
    coApplyFlowConfig.steps.push(demographicsStepConfig);
    /* End - Configure Demographics step */

    /* Begin - Configure Loan Characteristics step */
    const loanCharacteristicsStepConfig = new GenericStepConfig('31', 'Loan Characteristics', Constants.stepPaths.loanCharacteristics, WizardGroupEnum.Declarations,
      StepType.LoanCharacteristicsStep, new SimpleNextStepDeciderConfig(Constants.stepPaths.borrowerCharacteristics));
    loanCharacteristicsStepConfig.title = 'Loan Characteristics';
    loanCharacteristicsStepConfig.explanation = "Now we need to identify some specific items that may require further documentation. Please check all that apply, even if " +
      "you've already noted these characteristics previously in this application.";
    coApplyFlowConfig.steps.push(loanCharacteristicsStepConfig);
    /* End - Configure Loan Characteristics step */

    /* Begin - Configure Borrower Characteristics step */
    const borrowerCharacteristicsStepConfig = new GenericStepConfig('32', 'Borrower Characteristics', Constants.stepPaths.borrowerCharacteristics, WizardGroupEnum.Declarations,
      StepType.BorrowerCharacteristicsStep, new SimpleNextStepDeciderConfig(Constants.stepPaths.econsent));
    borrowerCharacteristicsStepConfig.title = 'Borrower Characteristics';
    borrowerCharacteristicsStepConfig.explanation = "Now we need to identify some specific items that may require further documentation. " +
      "Please check all that apply to each borrower, even if you've already noted these characteristics previously in this application.";
    coApplyFlowConfig.steps.push(borrowerCharacteristicsStepConfig);
    /* End - Configure Borrower Characteristics step */

    /* Begin - E-consent step */
    const consentAuthorizationStepConfig = new GenericStepConfig('28', 'E-Consent Authorization', Constants.stepPaths.econsent,
      WizardGroupEnum.Declarations, StepType.EConsentStep, new SimpleNextStepDeciderConfig(Constants.stepPaths.reviewApplication));
    consentAuthorizationStepConfig.title = 'E-Consent Authorization';
    consentAuthorizationStepConfig.explanation = 'Your Consent To Do Business Electronically (The E-Disclosure Agreement)';
    coApplyFlowConfig.steps.push(consentAuthorizationStepConfig);
    /* End - E-consent step */

    /* Begin - Review application step */
    const reviewApplicationStepConfig = new GenericStepConfig('29', 'Review Application', Constants.stepPaths.reviewApplication,
      WizardGroupEnum.ReviewAndSubmit,
      StepType.ReviewApplicationStep, undefined);
    reviewApplicationStepConfig.title = 'Review Application';
    reviewApplicationStepConfig.explanation = 'Please review your application and continue.';
    coApplyFlowConfig.steps.push(reviewApplicationStepConfig);
    /* Begin - Review Application step */

    return coApplyFlowConfig;
  }

  private generateDefaultApplicationFlowConfig = (): WizardFlowConfig => {
    let applicationFlowConfig = new WizardFlowConfig();
    applicationFlowConfig.isDefault = true;

    /* Begin - Pull Existing Application */
    // const pullExistingApplicationStepConfig = new GenericStepConfig('39', 'Pull Existing Application',
    //   Constants.stepPaths.pullExistingApplication, WizardGroupEnum.MyInfo,
    //   StepType.PullExistingApplicationStep, new SimpleNextStepDeciderConfig(Constants.stepPaths.myInfo));
    // pullExistingApplicationStepConfig.title = 'It seems like we might already have your information.';
    // pullExistingApplicationStepConfig.explanation = 'Please enter the following info, so we can pull up your existing application.';
    // applicationFlowConfig.steps.push(pullExistingApplicationStepConfig);
    /* End - Pull Existing Application */

    /* Begin - My Info */
    const myInfoStepNextStepDeciderConfig = new SimpleNextStepDeciderConfig(Constants.stepPaths.numberOfBorrowers);
    const myInfoStepConfig = new MyInfoStepConfig('6', 'My info', Constants.stepPaths.myInfo, WizardGroupEnum.MyInfo,
      myInfoStepNextStepDeciderConfig);
    myInfoStepConfig.title = 'Tell us a little more about yourself.';
    myInfoStepConfig.configuresCoborrowers = false;
    myInfoStepConfig.configuresAddressHistory = false;
    applicationFlowConfig.steps.push(myInfoStepConfig);
    /* End - My Info */

    /* Begin - Number of Borrowers */
    const numberOfBorrowersNextStepDeciderConfig = new ExpressionNextStepDeciderConfig(new StepExpression('mortgage.borrowers.length == 1', Constants.stepPaths.address), [],
      Constants.stepPaths.createCoBorrowers);
    const numberOfBorrowersStepConfig = new GenericStepConfig('8', 'Number of Borrowers', Constants.stepPaths.numberOfBorrowers, WizardGroupEnum.MyInfo,
      StepType.NumberOfBorrowersStep, numberOfBorrowersNextStepDeciderConfig);
    numberOfBorrowersStepConfig.title = 'Hi, [borrower.firstName]! Will anyone else be on the loan with you?';
    applicationFlowConfig.steps.push(numberOfBorrowersStepConfig);
    /* End - Number of Borrowers */

    /* Begin - Create co-borrowers step */
    const createCoborrowersStepConfig = new GenericStepConfig('9', 'Create Coborrowers', Constants.stepPaths.createCoBorrowers, WizardGroupEnum.MyInfo,
      StepType.CreateCoBorrowersStep,
      new SimpleNextStepDeciderConfig(Constants.stepPaths.address));
    createCoborrowersStepConfig.title = 'Let\'s gather information about co-borrowers.';
    createCoborrowersStepConfig.explanation = 'We will ask for more details about each co-borrower later.';
    applicationFlowConfig.steps.push(createCoborrowersStepConfig);
    /* End - Create co-borrowers step */

    /* Begin - Current Address */
    const currentAddressStepConfig = new GenericStepConfig('10', 'Current Address', Constants.stepPaths.address, WizardGroupEnum.MyInfo,
      StepType.CurrentAddressStep, new SimpleNextStepDeciderConfig(Constants.stepPaths.ownershipStatus));
    currentAddressStepConfig.title = 'What is your current address?';
    applicationFlowConfig.steps.push(currentAddressStepConfig);
    /* End - Current Address */

    /* Begin - Own Rent */
    const ownRentStepConfig = new GenericStepConfig('11', 'Own, Rent or Live Rent Free', Constants.stepPaths.ownershipStatus, WizardGroupEnum.MyInfo,
      StepType.OwnershipStatusStep,
      new ExpressionNextStepDeciderConfig(new StepExpression('borrowersThatMainBorrowerIsApplyingFor.length == 0', Constants.stepPaths.addressHistory), [], Constants.stepPaths.multiBorrowerAddressHistory,
      ));
    ownRentStepConfig.heading = 'Do you...';
    applicationFlowConfig.steps.push(ownRentStepConfig);
    /* End - Own Rent */

    /* Begin - Address History */
    const addressHistoryStepConfig = new GenericStepConfig('12', 'Address History', Constants.stepPaths.addressHistory, WizardGroupEnum.MyInfo,
      StepType.AddressHistoryStep, new SimpleNextStepDeciderConfig(Constants.stepPaths.manualOrDigitalEmploymentAndIncomeChoice));
    addressHistoryStepConfig.title = "Now, let's gather some information about your address history.";
    addressHistoryStepConfig.explanation = 'Please provide at least 2 years of address history';
    let addressHistoryStepFieldConfig: any = FieldConfigUtils.generateAddressHistoryStepFieldConfig();
    addressHistoryStepConfig.fieldConfig = addressHistoryStepFieldConfig;
    applicationFlowConfig.steps.push(addressHistoryStepConfig);
    /* End - Address History */

    /* Begin - Multiple Borrower Address History */
    const multipleBorrowerAddressHistoryStepConfig = new GenericStepConfig('13', 'Multiple Borrower Address History', Constants.stepPaths.multiBorrowerAddressHistory,
      WizardGroupEnum.MyInfo, StepType.MultiBorrowersAddressHistoryStep, new SimpleNextStepDeciderConfig(Constants.stepPaths.manualOrDigitalEmploymentAndIncomeChoice));
    multipleBorrowerAddressHistoryStepConfig.title = 'Please provide at least 2 years of address history for each borrower.';
    applicationFlowConfig.steps.push(multipleBorrowerAddressHistoryStepConfig);
    /* End - Multiple Borrower Address History */

    /* Begin - Choose digital or manual employment-income step */
    const digialEmploymentAndIncomeOption = new Option('digital', 'Digitally', 'Securely verify your income electronically, eliminating the need for paper copies',
      Constants.incomeVerificationOptions.digital, 'laptop-svg-icon');
    const manualEmploymentAndIncomeOption = new Option('manual', 'Manually', 'Manually enter any employment and income information and provide paper statements later',
      Constants.incomeVerificationOptions.manual, 'clipboard-svg-icon');
    const employmentAndIncomeQuestion = new SingleChoiceQuestion('', '', [digialEmploymentAndIncomeOption, manualEmploymentAndIncomeOption]);
    const employmentAndIncomeStepConfig = new SingleChoiceQuestionStepConfig('14', 'Employment and Income', Constants.stepPaths.manualOrDigitalEmploymentAndIncomeChoice,
      WizardGroupEnum.MyFinances,
      ContextType.CustomerPreferences,
      employmentAndIncomeQuestion,
      new ExpressionNextStepDeciderConfig(new StepExpression('customerPreferences.gatherEmploymentAndIncomeDigitally == 2', Constants.stepPaths.manualIncomeAndEmployment), [], Constants.stepPaths.digitalIncomeAndEmployment,
      ));
    employmentAndIncomeStepConfig.fieldToSetWithAnswer = 'gatherEmploymentAndIncomeDigitally';
    employmentAndIncomeStepConfig.title = 'Tell us about your employment and income';
    employmentAndIncomeStepConfig.explanation = 'Select how you want to tell us about your employment and income';
    applicationFlowConfig.steps.push(employmentAndIncomeStepConfig);
    /* End - Configure digital employment/income verification step */

    /* Begin - Configure income step */
    const incomeStepConfig = new GenericStepConfig('15', 'Income', Constants.stepPaths.manualIncomeAndEmployment, WizardGroupEnum.MyFinances, StepType.ManualIncomeAndEmploymentStep,
      new SimpleNextStepDeciderConfig(Constants.stepPaths.manualOrDigitalAssetChoice));
    incomeStepConfig.title = 'Tell us about your Income/Employment history.';
    incomeStepConfig.explanation = 'Please specify what type of income you have by adding all that apply';
    let incomeStepFieldConfig: any = FieldConfigUtils.generateIncomeStepFieldConfig();
    incomeStepConfig.fieldConfig = incomeStepFieldConfig;
    applicationFlowConfig.steps.push(incomeStepConfig);
    /* End - Configure income step */

    /* Begin - Configure digital employment/income verification step */
    const digitalEmploymentIncomeStepConfig = new GenericStepConfig('16', 'Digital Income', Constants.stepPaths.digitalIncomeAndEmployment, WizardGroupEnum.MyFinances,
      StepType.DigitalIncomeAndEmploymentStep, new SimpleNextStepDeciderConfig(Constants.stepPaths.manualIncomeAndEmployment));
    digitalEmploymentIncomeStepConfig.title = 'Digital Income and Employment Verification';
    digitalEmploymentIncomeStepConfig.explanation = 'Securely gathering digital assets eliminates the need to track down paper statements.';
    applicationFlowConfig.steps.push(digitalEmploymentIncomeStepConfig);
    /* End - Configure digital employment/income verification step */

    /* Begin - Choose digital or manual asset step */
    const digitalAssetOption = new Option('digital', 'Digitally', 'Securely gather your bank statements electronically, eliminating the need for paper copies',
      Constants.assetVerificationOptions.digital, 'laptop-svg-icon');
    const manualAssetOption = new Option('manual', 'Manually', 'Manually enter any asset information and provide paper statements later',
      Constants.assetVerificationOptions.manual, 'clipboard-svg-icon');
    const assetQuestion = new SingleChoiceQuestion('', '', [digitalAssetOption, manualAssetOption]);
    const assetManualOrDigitalStepConfig = new SingleChoiceQuestionStepConfig('17', 'Asset Manual or Digital', Constants.stepPaths.manualOrDigitalAssetChoice,
      WizardGroupEnum.MyFinances, ContextType.CustomerPreferences, assetQuestion,
      new ExpressionNextStepDeciderConfig(new StepExpression('customerPreferences.gatherAssetsDigitally == 2', Constants.stepPaths.manualAssets), [], Constants.stepPaths.digitalAssets,
      ));
    assetManualOrDigitalStepConfig.fieldToSetWithAnswer = 'gatherAssetsDigitally';
    assetManualOrDigitalStepConfig.title = 'Tell us about your assets';
    assetManualOrDigitalStepConfig.explanation = 'Select how you want to tell us about your assets';
    applicationFlowConfig.steps.push(assetManualOrDigitalStepConfig);
    /* End - Choose digital or manual asset step */

    /* Begin - Digital assets step */
    const digitalAssetsStepConfig = new GenericStepConfig('18', 'Digital Assets', Constants.stepPaths.digitalAssets, WizardGroupEnum.MyFinances,
      StepType.DigitalAssetsStep, new SimpleNextStepDeciderConfig(Constants.stepPaths.reo));
    digitalAssetsStepConfig.title = 'Digital Assets';
    digitalAssetsStepConfig.explanation = 'Securely gathering digital assets eliminates the need to track down paper statements.' +
      'Search for your financial institution and connect  with them the same way you do when you visit them on the web.';
    applicationFlowConfig.steps.push(digitalAssetsStepConfig);
    /* End - Digital assets step */

    /* Begin - Configure assets step */
    const assetsStepConfig = new GenericStepConfig('19', 'Assets', Constants.stepPaths.manualAssets, WizardGroupEnum.MyFinances,
      StepType.ManualAssetsStep, new SimpleNextStepDeciderConfig(Constants.stepPaths.reo));
    assetsStepConfig.title = 'Now, we need to collect some Asset details.';
    assetsStepConfig.explanation = 'Click Add below, choose an asset type and enter the amount for the asset. Please note, we will need to verify enough assets to cover any funds you will need during this transaction.';
    let assetsStepFieldConfig: any = FieldConfigUtils.generateAssetsStepFieldConfig();
    assetsStepConfig.fieldConfig = assetsStepFieldConfig;
    applicationFlowConfig.steps.push(assetsStepConfig);
    /* End - Configure assets step */

    /* Begin - Configure REO step */
    const reoStepConfig = new GenericStepConfig('20', 'Real Estate Owned', Constants.stepPaths.reo, WizardGroupEnum.MyFinances,
      StepType.ReoStep,
      new SimpleNextStepDeciderConfig(Constants.stepPaths.linkLiabilitiesToReo));
    reoStepConfig.title = 'Real Estate Information.';
    reoStepConfig.explanation = 'If you currently own any home or other type of real estate, please enter it below. If you don\'t own any real estate, just continue.';
    let reoStepFieldConfig: any = FieldConfigUtils.generateReoStepFieldConfig();
    reoStepConfig.fieldConfig = reoStepFieldConfig;
    applicationFlowConfig.steps.push(reoStepConfig);
    /* End - Configure REO step */

    /* Begin - Link Liabilities to REO step */
    const linkLiabilitiesToStepConfig = new GenericStepConfig('40', 'Link Liabilities to REO',
      Constants.stepPaths.linkLiabilitiesToReo, WizardGroupEnum.MyFinances,
      StepType.LinkLiabilitiesToReoStep,
      new ExpressionNextStepDeciderConfig(new StepExpression('!skipCreditCheck', Constants.stepPaths.verifyCredit), [],
        Constants.stepPaths.purchaseCredits));
    linkLiabilitiesToStepConfig.title = 'Now, let us go over the mortgage loans you already have.';
    linkLiabilitiesToStepConfig.explanation = 'Please associate the mortgage loans you have with the real estate you own below.';
    applicationFlowConfig.steps.push(linkLiabilitiesToStepConfig);
    /* End - Link Liabilities to REO step */

    /* Begin - Configure verify credit step */
    const verifyCreditStepConfig = new GenericStepConfig('21', 'Verify Credit', Constants.stepPaths.verifyCredit, WizardGroupEnum.MyFinances,
      StepType.VerifyCreditStep,
      new ExpressionNextStepDeciderConfig(new StepExpression('customerPreferences.doNotRunCredit == 0 && !creditRunFailed && !skipAutomatedCreditCheck', Constants.stepPaths.creditScores), [], Constants.stepPaths.purchaseCredits));
    verifyCreditStepConfig.title = 'Credit Verification';
    verifyCreditStepConfig.heading = `Now, we need to review all applicants' credit reports. Please provide the following information: `;
    // if (this._mortgageApplication.borrowers.length == 1) {
    //   verifyCreditStepConfig.heading = 'Now, we need to review your credit reports for all borrowers. Please provide the following information: ';
    // } else {
    //   verifyCreditStepConfig.heading = 'Now, we need to review all applicants credit reports. Please provide the following information: ';
    // }
    applicationFlowConfig.steps.push(verifyCreditStepConfig);
    /* End - Configure verify credit step */

    /* Begin - Credit scores step */
    const nextStepDecider = new ExpressionNextStepDeciderConfig(new StepExpression('mortgage.liabilities.length == 0', Constants.stepPaths.creditInquiry), [], Constants.stepPaths.reviewCredit);
    const creditScoresStepConfig = new CreditScoresStepConfig('22', 'Credit Scores', Constants.stepPaths.creditScores, WizardGroupEnum.MyFinances, nextStepDecider);
    creditScoresStepConfig.title = 'Here are your credit scores:';
    creditScoresStepConfig.useScoreIndicatorIcons = true;
    applicationFlowConfig.steps.push(creditScoresStepConfig);
    /* End - Credit scores step */

    /* Begin - Configure review credit step */
    // tslint:disable-next-line:max-line-length
    const reviewCreditStepConfig = new GenericStepConfig('23', 'Review Credit', Constants.stepPaths.reviewCredit, WizardGroupEnum.MyFinances, StepType.ReviewCreditStep,
      new SimpleNextStepDeciderConfig(Constants.stepPaths.creditInquiry));
    reviewCreditStepConfig.title = 'Now that we have your credit report, we need to understand what debts are linked to the real estate you own.';
    reviewCreditStepConfig.heading = 'Please link your Mortgage Liabilities to the Real Estate you own.';
    applicationFlowConfig.steps.push(reviewCreditStepConfig);
    /* End - Configure verify credit step */

    /* Begin - Configure Credit Inquiry Step */
    const creditInquiryStepConfig = new GenericStepConfig('24', 'Credit Inquiry', Constants.stepPaths.creditInquiry, WizardGroupEnum.MyFinances,
      StepType.CreditInquiryStep, new SimpleNextStepDeciderConfig(Constants.stepPaths.purchaseCredits));
    creditInquiryStepConfig.title = 'Credit Inquiries';
    creditInquiryStepConfig.heading = 'Based on the credit report, here are the' +
      ' credit inquires in the past 120 days. Please select an option for each inquiry below, and if any new debt was acquired.';
    applicationFlowConfig.steps.push(creditInquiryStepConfig);
    /* End -  Configure Credit Inquiry Step */

    /* Begin - Purchase Credits */
    const purchaseCreditsStepConfig = new GenericStepConfig('35', 'Purchase Credits',
      Constants.stepPaths.purchaseCredits, WizardGroupEnum.PropertyAndLoan,
      StepType.PurchaseCreditsStep, new SimpleNextStepDeciderConfig(Constants.stepPaths.loanInfo));
    purchaseCreditsStepConfig.title = 'Regarding the home you wish to purchase, are you...';
    let purchaseCreditsStepFieldConfig: any = FieldConfigUtils.generatePurchaseCreditsStepFieldConfig();
    purchaseCreditsStepConfig.fieldConfig = purchaseCreditsStepFieldConfig;
    applicationFlowConfig.steps.push(purchaseCreditsStepConfig);
    /* End - Purchase Credits */

    /* Begin - Type of Loan */
    const typeOfLoanStepConfig = new GenericStepConfig('25', 'Type of Loan', Constants.stepPaths.loanInfo, WizardGroupEnum.PropertyAndLoan,
      StepType.LoanTypeStep, new SimpleNextStepDeciderConfig(Constants.stepPaths.hoi));
    typeOfLoanStepConfig.title = 'Do you know which loan type you are applying for?';
    let loanInfoStepFieldConfig = FieldConfigUtils.generateLoanInfoStepFieldConfig();
    typeOfLoanStepConfig.fieldConfig = loanInfoStepFieldConfig;
    applicationFlowConfig.steps.push(typeOfLoanStepConfig);
    /* End - Type of Loan */

    /* Begin - Pricing */
    // const pricingStepConfig = new GenericStepConfig('38', 'Pricing', Constants.stepPaths.pricing, WizardGroupEnum.PropertyAndLoan,
    //   StepType.PricingStep, new SimpleNextStepDeciderConfig(Constants.stepPaths.hoi));
    // pricingStepConfig.title = 'Please select a rate?';
    // applicationFlowConfig.steps.push(pricingStepConfig);
    /* End - Pricing */

    /* Begin - Configure HOI step */
    const hoiStepConfig = new GenericStepConfig('34', "Home Owner's Insurance", Constants.stepPaths.hoi, WizardGroupEnum.PropertyAndLoan,
      StepType.HoiStep,
      new SimpleNextStepDeciderConfig(Constants.stepPaths.declarations));
    hoiStepConfig.title = "Let's review your homeowner's insurance needs";
    hoiStepConfig.heading = ``;
    applicationFlowConfig.steps.push(hoiStepConfig);
    /* End - Configure HOI step */

    /* Begin - Configure Declarations step */
    const declarationsStepConfig = new GenericStepConfig('26', 'Declarations', Constants.stepPaths.declarations, WizardGroupEnum.Declarations,
      StepType.DeclarationsStep, new SimpleNextStepDeciderConfig(Constants.stepPaths.militaryService));
    declarationsStepConfig.title = 'Declarations';
    declarationsStepConfig.explanation = 'Government Declaration and Monitoring Questions';
    applicationFlowConfig.steps.push(declarationsStepConfig);
    /* End - Configure Declarations step */

    /* Begin - Configure Military Service step */
    const militaryServiceStepConfig = new GenericStepConfig('33', 'Military Service', Constants.stepPaths.militaryService, WizardGroupEnum.Declarations,
      StepType.MilitaryServiceStep, new SimpleNextStepDeciderConfig(Constants.stepPaths.demographics));
    militaryServiceStepConfig.title = 'Military Service';
    militaryServiceStepConfig.explanation = 'Please provide the following information as it pertains to military service. This will help us better understand which products you might qualify for.';
    applicationFlowConfig.steps.push(militaryServiceStepConfig);
    /* End - Configure Military Service step */

    /* Begin - Configure Demographics step */
    const demographicsStepConfig = new GenericStepConfig('27', 'Demograghics', Constants.stepPaths.demographics, WizardGroupEnum.Declarations,
      StepType.DemographicsStep, new SimpleNextStepDeciderConfig(Constants.stepPaths.homeownershipEducation));
    demographicsStepConfig.title = 'Demographics';
    demographicsStepConfig.explanation = 'What is your demographic information? ' +
      'You do not have to provide this information, but we are required by the Federal Government to ask.';
    applicationFlowConfig.steps.push(demographicsStepConfig);
    /* End - Configure Demographics step */

    /* Begin - Configure Homeownership Education step */
    const homeownershipEducationConfig = new GenericStepConfig('41', 'Homeownership Education', Constants.stepPaths.homeownershipEducation, WizardGroupEnum.Declarations,
      StepType.HomeownershipEducationStep, new SimpleNextStepDeciderConfig(Constants.stepPaths.loanCharacteristics));
      homeownershipEducationConfig.title = 'Homeownership Education';
      homeownershipEducationConfig.explanation = 'Please, give us the details of ' +
      'all the homeownership education/councelling you had before.';
    applicationFlowConfig.steps.push(homeownershipEducationConfig);
    /* End - Configure Demographics step */

    /* Begin - Configure Loan Characteristics step */
    const loanCharacteristicsStepConfig = new GenericStepConfig('31', 'Loan Characteristics', Constants.stepPaths.loanCharacteristics, WizardGroupEnum.Declarations,
      StepType.LoanCharacteristicsStep, new SimpleNextStepDeciderConfig(Constants.stepPaths.borrowerCharacteristics));
    loanCharacteristicsStepConfig.title = 'Loan Characteristics';
    loanCharacteristicsStepConfig.explanation = "Now we need to identify some specific items that may require further documentation. Please check all that apply, even if " +
      "you've already noted these characteristics previously in this application.";
    applicationFlowConfig.steps.push(loanCharacteristicsStepConfig);
    /* End - Configure Loan Characteristics step */

    /* Begin - Configure Borrower Characteristics step */
    const borrowerCharacteristicsStepConfig = new GenericStepConfig('32', 'Borrower Characteristics', Constants.stepPaths.borrowerCharacteristics, WizardGroupEnum.Declarations,
      StepType.BorrowerCharacteristicsStep, new SimpleNextStepDeciderConfig(Constants.stepPaths.checklists));
    borrowerCharacteristicsStepConfig.title = 'Borrower Characteristics';
    borrowerCharacteristicsStepConfig.explanation = "Now we need to identify some specific items that may require further documentation. " +
      "Please check all that apply to each borrower, even if you've already noted these characteristics previously in this application.";
    applicationFlowConfig.steps.push(borrowerCharacteristicsStepConfig);
    /* End - Configure Borrower Characteristics step */

    /* Begin - Configure Checklists step */
    const checkListsStepConfig = new ChecklistsStepConfig('36', 'Checklists', Constants.stepPaths.checklists, WizardGroupEnum.Declarations,
      new SimpleNextStepDeciderConfig(Constants.stepPaths.econsent));
    checkListsStepConfig.title = 'Checklists';
    checkListsStepConfig.explanation = "Now we need to identify some specific items that may require further documentation. " +
      "Please answer the following questions.";
    applicationFlowConfig.steps.push(checkListsStepConfig);
    /* End - Configure Checklists step */

    /* Begin - E-consent step */
    const consentAuthorizationStepConfig = new GenericStepConfig('28', 'E-Consent Authorization', Constants.stepPaths.econsent,
      WizardGroupEnum.Declarations, StepType.EConsentStep, new SimpleNextStepDeciderConfig(Constants.stepPaths.gatherLeadSource));
    consentAuthorizationStepConfig.title = 'E-Consent Authorization';
    consentAuthorizationStepConfig.explanation = 'Your Consent To Do Business Electronically (The E-Disclosure Agreement)';
    applicationFlowConfig.steps.push(consentAuthorizationStepConfig);
    /* End - E-consent step */

    /* Begin - Gather lead source step */
    const gatherReferralSourceStepConfig = new GenericStepConfig('37', 'Gather Lead Source', Constants.stepPaths.gatherLeadSource,
      WizardGroupEnum.Declarations, StepType.GatherLeadSourceStep, new SimpleNextStepDeciderConfig(Constants.stepPaths.reviewApplication));
    gatherReferralSourceStepConfig.title = 'How Did You Hear About Us?';
    gatherReferralSourceStepConfig.explanation = 'Please tell us below how you heard about us.';
    applicationFlowConfig.steps.push(gatherReferralSourceStepConfig);
    /* End - Gather lead source step */

    /* Begin - Review application step */
    const reviewApplicationStepConfig = new GenericStepConfig('29', 'Review Application', Constants.stepPaths.reviewApplication,
      WizardGroupEnum.ReviewAndSubmit,
      StepType.ReviewApplicationStep, undefined);
    reviewApplicationStepConfig.title = 'Review Application';
    reviewApplicationStepConfig.explanation = 'Please review your application and continue.';
    let reviewApplicationStepFieldConfig: any = FieldConfigUtils.generateReviewApplicationStepFieldConfig;
    reviewApplicationStepConfig.fieldConfig = reviewApplicationStepFieldConfig;
    applicationFlowConfig.steps.push(reviewApplicationStepConfig);
    /* Begin - Review Application step */

    return applicationFlowConfig;
  }

  private generateDefaultRegistrationFlowConfig = (): WizardFlowConfig => {

    let registrationFlowConfig = new WizardFlowConfig();
    registrationFlowConfig.isDefault = true;

    const loanPurposeQuestion = new SingleChoiceQuestion('Are you...', '', []);

    const nextStepDeciderConfig = new ExpressionNextStepDeciderConfig(new StepExpression("mortgage.subjectProperty.purposeOfLoan == 'Purchase'", Constants.stepPaths.currentAppState), [],
      Constants.stepPaths.propertyAddressRefi);
    const loanPurposeStepConfig = new SingleChoiceQuestionStepConfig('1', 'Loan Purpose', Constants.stepPaths.loanPurpose,
      undefined, ContextType.SubjectProperty,
      loanPurposeQuestion, nextStepDeciderConfig);
    loanPurposeStepConfig.optionsProviderType = OptionsProviderTypes.LoanPurpose;
    loanPurposeStepConfig.heading = '';
    loanPurposeStepConfig.title = 'Welcome to our online application!';
    loanPurposeStepConfig.explanation = 'We look forward to working with you on your mortgage goals. Let\'s get started...';
    loanPurposeStepConfig.fieldToSetWithAnswer = 'purposeOfLoan';
    registrationFlowConfig.steps.push(loanPurposeStepConfig);

    /* Begin - Configure current state of application step */
    const haveSignedPurchaseAgreementOption = new Option('purchase-agreement', 'Have a signed purchase agreement', '', true, 'duotone-clipboard-check');
    const lookingToGetPreApprovedOption = new Option('pre-approval', 'Looking to get pre-approved', '', false, 'duotone-coupon-discount');
    const currentStateOfApplicationQuestion = new SingleChoiceQuestion('', '', [haveSignedPurchaseAgreementOption, lookingToGetPreApprovedOption]);

    const currentStateOfApplicationStepConfig = new SingleChoiceQuestionStepConfig('2', 'Current State of Application', Constants.stepPaths.currentAppState,
      undefined, ContextType.Extension, currentStateOfApplicationQuestion, new ExpressionNextStepDeciderConfig(new StepExpression('mortgage.extension.hasSignedPurchaseAgreement == true',
        Constants.stepPaths.propertyAddress), [], Constants.stepPaths.occupancy));
    currentStateOfApplicationStepConfig.title = 'What is the current status of your application?';
    currentStateOfApplicationStepConfig.explanation = 'We look forward to working with you on your mortgage goals.';
    currentStateOfApplicationStepConfig.fieldToSetWithAnswer = 'hasSignedPurchaseAgreement';
    registrationFlowConfig.steps.push(currentStateOfApplicationStepConfig);
    /* End - configure current state of application step */

    /* Begin - Configure property address step */
    const propertyAddressStepNextStepDeciderConfig = new SimpleNextStepDeciderConfig(Constants.stepPaths.occupancy);
    const propertyAddressStepConfig = new AddressStepConfig('3.a', 'Property Address', Constants.stepPaths.propertyAddress, undefined,
      ContextType.SubjectProperty, new AddressFields('address1', 'city', 'state', 'zipCode', 'county'));
    propertyAddressStepConfig.title = 'Awesome! What is the address of the new home?';
    propertyAddressStepConfig.nextStepDeciderConfig = propertyAddressStepNextStepDeciderConfig;
    registrationFlowConfig.steps.push(propertyAddressStepConfig);
    /* End - Configure property address step */

    /* Begin - Configure property address refi step */
    const propertyAddressRefiStepNextStepDeciderConfig = new SimpleNextStepDeciderConfig(Constants.stepPaths.occupancy);
    const propertyAddressRefiStepConfig = new AddressStepConfig('3.b', 'Property Address Refi', Constants.stepPaths.propertyAddressRefi, undefined,
      ContextType.SubjectProperty, new AddressFields('address1', 'city', 'state', 'zipCode', 'county'));
    propertyAddressRefiStepConfig.title = 'Congratulations! What is the address of the property that you\'re refinancing ? ';
    propertyAddressRefiStepConfig.nextStepDeciderConfig = propertyAddressRefiStepNextStepDeciderConfig;
    registrationFlowConfig.steps.push(propertyAddressRefiStepConfig);
    /* End - Configure property address refi step */

    /* Begin - Configure occupancy step */
    // TODO:
    const primaryResidenceOption = new Option('primaryResidence', 'Primary Residence', '', 'PrimaryResidence', 'stockholm-home-heart');
    const secondHomeOption = new Option('secondHome', 'Vacation/Second Home', '', 'SecondaryResidence', 'stockholm-sun');
    const investmentPropertyOption = new Option('investmentProperty', 'Investment Property', '', 'Investment', 'stockholm-bank');
    const occupancyQuestion = new SingleChoiceQuestion('', '', [primaryResidenceOption, secondHomeOption, investmentPropertyOption]);
    const occupancyNextStepDeciderConfig = new SimpleNextStepDeciderConfig(Constants.stepPaths.propertyType);
    const occupancyStepConfig = new SingleChoiceQuestionStepConfig('4', 'Occupancy', Constants.stepPaths.occupancy, undefined, ContextType.SubjectProperty,
      occupancyQuestion, occupancyNextStepDeciderConfig);
    occupancyStepConfig.title = 'How will you occupy this property?';
    occupancyStepConfig.fieldToSetWithAnswer = 'propertyWillBe';
    registrationFlowConfig.steps.push(occupancyStepConfig);
    /* End - Configure occupancy step */

    /* Begin - Configure property type */
    const singleFamilyOption = new Option('singleFamily', 'Single Family', '', 'SFR', 'stockholm-home-heart');
    const townhouseOption = new Option('townhouse', 'Townhouse', '', 'Townhouse', 'duotone-building');
    const condoOption = new Option('condo', 'Condo', '', 'Condominium');
    condoOption.iconClass = 'fa fa-2x fa-building';
    condoOption.iconType = 'font-awesome';
    const multiUnitOption = new Option('multiUnit', 'Multi Unit', '', 'MultiFamilyResidence', 'duotone-building2');
    const manufacturedHomeOption = new Option('manufacturedHousing', 'Manufactured Home', '', 'ManufacturedHousing');
    manufacturedHomeOption.iconType = 'font-awesome';
    manufacturedHomeOption.iconClass = 'las la-tools fs-3x';
    const propertyTypeNextStepDeciderConfig = new ExpressionNextStepDeciderConfig(new StepExpression('mortgage.userGuid == "unassigned"', Constants.stepPaths.originator), [],
      Constants.stepPaths.createAccount);
    const propertyTypeQuestion = new SingleChoiceQuestion('', '', [singleFamilyOption, townhouseOption, condoOption, multiUnitOption, manufacturedHomeOption]);
    const propertyTypeStepConfig = new SingleChoiceQuestionStepConfig('5', 'Property Type', Constants.stepPaths.propertyType, undefined, ContextType.SubjectProperty,
      propertyTypeQuestion, propertyTypeNextStepDeciderConfig);
    propertyTypeStepConfig.title = 'What Type of Property?';
    propertyTypeStepConfig.fieldToSetWithAnswer = 'propertyType';
    registrationFlowConfig.steps.push(propertyTypeStepConfig);
    /* End - Configure property type step */

    /* Begin - Select originator step */
    const originatorStepConfig = new GenericStepConfig('6', 'Select Originator', Constants.stepPaths.originator, undefined,
      StepType.SelectOriginatorStep, new SimpleNextStepDeciderConfig(Constants.stepPaths.createAccount));
    originatorStepConfig.title = 'Our team of originators are listed below. Select the one you would like to work with or start typing a name in the search box';
    registrationFlowConfig.steps.push(originatorStepConfig);
    /* End - Select originator step */

    /* Begin - Configure create my account step */
    const createAccountStepConfig = new CreateAccountStepConfig('7', 'Create Account', Constants.stepPaths.createAccount, undefined);
    createAccountStepConfig.title = 'Now, let\'s create an account to save your progress.';
    registrationFlowConfig.steps.push(createAccountStepConfig);
    /* End - Configure create my account step */

    /* Begin - Configure loan purpose step V2 - This step is not added to the flow, it's dangling, so flow designers can add this to their flows if needed */
    const loanPurposeV2NextStepDeciderConfig = new ExpressionNextStepDeciderConfig(new StepExpression("mortgage.subjectProperty.purposeOfLoan == 'Purchase'", Constants.stepPaths.currentAppState), [],
      Constants.stepPaths.propertyAddressRefi);
    const loanPurposeStepV2Config = new SingleChoiceQuestionStepConfig('42', 'Loan Purpose', Constants.stepPaths.loanPurposeV2,
      undefined, ContextType.WizardFlowContextItself,
    loanPurposeQuestion, loanPurposeV2NextStepDeciderConfig);
    loanPurposeStepV2Config.optionsProviderType = OptionsProviderTypes.loanPurposeV2;
    loanPurposeStepV2Config.heading = '';
    loanPurposeStepV2Config.title = 'Welcome to our online application!';
    loanPurposeStepV2Config.explanation = 'We look forward to working with you on your mortgage goals. Let\'s get started...';
    loanPurposeStepV2Config.fieldToSetWithAnswer = 'loanPurposeId';
    registrationFlowConfig.steps.push(loanPurposeStepV2Config);

    /* End - Configure loan purpose step V2 */

    return registrationFlowConfig;
  }

  private generateFlowConfigs(flow: Flow) {

    let registrationFlowConfig: WizardFlowConfig;
    let applicationFlowConfig: WizardFlowConfig;
    let coApplicationFlowConfig: WizardFlowConfig;

    let flowConfigs: WizardFlowConfigs | null = null;

    const defaultApplicationFlowConfig = this.generateDefaultApplicationFlowConfig();
    if (!flow.data) {
      registrationFlowConfig = this.generateDefaultRegistrationFlowConfig();
      applicationFlowConfig = defaultApplicationFlowConfig;
      coApplicationFlowConfig = this.generateDefaultCoApplicationFlowConfig();
    } else {
      flowConfigs = JSON.parse(flow.data);

      if (flowConfigs) {
        registrationFlowConfig = flowConfigs.registrationFlowConfig;
        if (!registrationFlowConfig) {
          registrationFlowConfig = this.generateDefaultRegistrationFlowConfig();
        }

        applicationFlowConfig = flowConfigs.applicationFlowConfig;
        if (!applicationFlowConfig) {
          applicationFlowConfig = defaultApplicationFlowConfig;
        } else {
          applicationFlowConfig.steps.forEach(stepConfig => {
            const defaultStepConfig = defaultApplicationFlowConfig.steps.find(s => s.stepType === stepConfig.stepType);
            if (defaultStepConfig) {
              stepConfig.fieldConfig = { ...defaultStepConfig.fieldConfig, ...stepConfig.fieldConfig };
            }
          })
        }

        coApplicationFlowConfig = flowConfigs.coApplicationFlowConfig;
        if (!coApplicationFlowConfig) {
          coApplicationFlowConfig = this.generateDefaultCoApplicationFlowConfig();
        }
      } else {
        registrationFlowConfig = this.generateDefaultRegistrationFlowConfig();
        applicationFlowConfig = defaultApplicationFlowConfig;
        coApplicationFlowConfig = this.generateDefaultCoApplicationFlowConfig();
      }
    }

    this._wizardFlowConfigs = new WizardFlowConfigs(applicationFlowConfig, coApplicationFlowConfig, registrationFlowConfig);
    this._wizardFlowConfigs.title = flow.title;
    this._wizardFlowConfigs.comment = flow.comment;
    this._wizardFlowConfigs.guid = flow.flowGuid;
    this._wizardFlowConfigs.isDefault = flow.isDefault;
    this._wizardFlowConfigs.navigationType = flowConfigs ? flowConfigs.navigationType : NavigationType.Horizontal;
    let hasProgressBar = true;
    if (flowConfigs && flowConfigs.hasProgressBar === false) {
      hasProgressBar = false;
    }
    this._wizardFlowConfigs.hasProgressBar = hasProgressBar;
    this._wizardFlowConfigs.userCreationAutoConfirmed = flow.userCreationAutoConfirmed;
  }
}
