import { Location } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { AgentRequestACallModel } from '../../models/borrower-portal/agent-request-a-call.model';
import { BasePortalService } from '../../services/base-portal.service';

@Component({
  selector: 'portal-request-a-call',
  templateUrl: './borrower-portal-request-a-call.component.html',
  styleUrls: ['./borrower-portal-request-a-call.component.scss']
})
export class PortalRequestACallComponent implements OnInit {

  @ViewChild('requestCallForm') requestCallForm: NgForm | undefined;

  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();

  loanId: number;
  requestACall: AgentRequestACallModel;

  constructor(
    private readonly _portalService: BasePortalService,
    private location: Location,
    private readonly _route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this._route.paramMap.subscribe((params: ParamMap) => {
      this.loanId = +params.get('loanId');
      this.requestACall = new AgentRequestACallModel(this.loanId);
    });
  }

  bestCallbackTimeChanged = (date: string) => {
    this.requestACall.bestCallbackTime = new Date(date);
  }

  submit = () => {
    this.requestCallForm.form.markAllAsTouched();
    if (this.requestCallForm.form.valid) {
      this._portalService.postReqestACall(this.requestACall)
        .subscribe(() => {
          this.close.emit();
          this.location.back();
          alert("Thanks, you'll hear from us soon!");
        });
    }

  }

}
