import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FlowConfig } from 'projects/borrower-app/src/app/models/wizard/config/flow-config.model';
import { NavigationService } from 'projects/borrower-app/src/app/services/navigation.service';
import { UtilsService } from 'projects/borrower-app/src/app/services/utils.service';
import { WizardFlowServiceBase } from 'projects/borrower-app/src/app/services/wizard/wizard-flow-service.base';
import { finalize } from 'rxjs/operators';


@Component({
  selector: 'flow-list-dialog',
  templateUrl: 'flow-list-dialog.component.html'
})

export class FlowListDialogComponent implements OnInit {

  flows: FlowConfig[] = [];
  loading: boolean = true;

  constructor(public activeModal: NgbActiveModal,
    private readonly _wizardFlowService: WizardFlowServiceBase,
    private readonly _navigationService: NavigationService,
    private readonly _utilsService: UtilsService) { }

  ngOnInit() {
    const observable = this._wizardFlowService.getFlowConfigs();
    if (observable) {
      observable.pipe(
        finalize(() => {
          this.loading = false;
        })
      ).subscribe((results) => {
        this.flows = results;
      });
    }
  }

  onFlowClicked = (flow: FlowConfig) => {
    this.activeModal.close();
    this._wizardFlowService.navigateToFlow(flow.flowGuid);
  }

  onDeleteFlowClicked = (flow: FlowConfig) => {
    this._wizardFlowService.deleteFlowConfig(flow.flowGuid)
    .pipe(
      finalize(() => {
      })
    )
    .subscribe((results) => {
      const flowConfig = this.flows.find(f => f.flowGuid === flow.flowGuid);
      if (flowConfig) {
        const index = this.flows.indexOf(flowConfig);
        if (index >= 0) {
          this.flows.splice(index, 1);
        }
      }
    });
  }
}
