<div class="flow-step" (click)="onStepClicked()">
  <div class="d-flex">
    <div id="step_{{step.id}}">
      <div style="margin-top: 25px; padding-left: 30px;">{{step.name}}</div>
      <div *ngIf="!isSourceStep" class="logic-indicator" data-bs-toggle="tooltip" data-bs-placement="top"
        title={{condition}}>
        <i class="fas fa-bolt" *ngIf="nextStepDeciderType == 1" style="margin-top:13px; margin-left: 13px"></i>
        <i class="fas fa-question" *ngIf="!nextStepDeciderType" style="margin-top:13px; margin-left: 13px"></i>
        <i class="fas fa-arrow-right" *ngIf="nextStepDeciderType == 2" style="margin-top:11px; margin-left: 11px"></i>
      </div>
    </div>
  </div>
</div>
<div style="position: absolute; margin-top: -78px;">
  <div style="text-align: center;">
    <button *ngIf="!isSourceStep" class="btn btn-sm btn-icon btn-bg-light btn-active-color-danger"
      style="height: 20px; margin-left: 2px" (click)="onDeleteStepFromNextStepDestinationsClicked($event)">
      <i class="bi bi-x fs-3"></i>
    </button>
    <button *ngIf="(isSourceStep && nextStepDeciderType == 1) || addEnabled"
      class="btn btn-sm btn-icon btn-bg-light btn-active-color-success" style="height: 20px; margin-left: 2px"
      (click)="onAddStepClicked($event)">
      <i class="bi bi-plus fs-3"></i>
    </button>
    <button *ngIf="isSourceStep"
      class="btn btn-sm btn-icon btn-bg-light btn-active-color-success" style="height: 20px; margin-left: 2px"
      (click)="onEditStepClicked($event)">
      <i class="bi bi-pencil fs-8"></i>
    </button>
    <button class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary" data-kt-menu-trigger="click"
      style="height: 20px; margin-left: 2px" data-kt-menu-placement="bottom-end" data-kt-menu-flip="top-end">
      <i class="bi bi-three-dots fs-3"></i>
    </button>
    <div
      class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3"
      data-kt-menu="true">
      <div class="menu-item px-3">
        <a class="menu-link px-3" (click)="onDeleteStepFromFlowClicked()">Delete Step from Flow</a>
      </div>
    </div>
  </div>
</div>
