<div class="d-flex align-items-stretch" *ngFor="let task of tasks | filter: 'taskId'">
  <div class="card w-100 mb-4 p-0 border border-2" style="min-height: 200px;">
    <div class="card-header px-4 d-flex align-items-center justify-content-between border-bottom-2">
      <div>
        <h4 class="mb-0">
          {{ task.docType || task.description }}
          <br>
          <span class="text-gray-400 fs-8 fw-bold">
            {{ task.description || task.docType }}
          </span>
        </h4>
      </div>
      <div *ngIf="
      task.borrowerTaskStatus == 'Pending' ||
      task.borrowerTaskStatus == 'Rejected'
    ">
        <a href="javascript:void(0)" class="btn btn-sm bp-primary-bg-color" (click)="goToCompleteApplication()"
          [class.disabled]="isAgent">Complete Your Loan Application
        </a>
      </div>
    </div>
    <div class="card-body p-4">
      <div class="d-flex align-items-center align-xs-items-start flex-xs-column justify-content-between mt-xs-5">
        <div class="d-flex">
          <div class="d-flex align-items-center " *ngIf="task.borrowerId">
            <span
              class="bp-contact-name-circle p-2 rounded-circle fw-bold d-flex align-items-center justify-content-center">
              {{ task.borrowerName?.charAt(0) }}
            </span>
          </div>
          <div class="d-flex flex-column ms-2">
            <strong>{{ task?.borrowerName || "Unknown" }}</strong>
            <span class="text-nowrap">Due {{ task?.dueDate | dateAgo }}</span>
          </div>
        </div>
        <div class="mt-xs-5">
          <span class="badge rounded-pill bg-warning text-dark task-status-badge" *ngIf="
              task.borrowerFacingNote && task.borrowerTaskStatus == 'Rejected'
            ">Urgent Message</span>
          <span class="badge rounded-pill bg-light text-dark task-status-badge" *ngIf="
              task.borrowerFacingNote && task.borrowerTaskStatus != 'Rejected'
            ">New Message</span>
          <span class="badge rounded-pill bg-light-warning text-dark task-status-badge"
            *ngIf="task.linkedDocuments && task.linkedDocuments.length">{{ task.linkedDocuments.length }}
            Documents</span>
          <span class="badge rounded-pill bg-light-warning text-dark task-status-badge"
            *ngIf="task.borrowerTaskStatus == 'Pending'">Pending</span>
          <span class="badge rounded-pill bg-light text-dark task-status-badge"
            *ngIf="task.borrowerTaskStatus == 'Submitted'">Submitted</span>
          <span class="badge rounded-pill bg-warning text-dark task-status-badge"
            *ngIf="task.borrowerTaskStatus == 'Rejected'">Rejected</span>
          <span class="badge rounded-pill bg-light text-dark task-status-badge"
            *ngIf="task.borrowerTaskStatus == 'Completed'">Completed</span>

          <span class="badge rounded-pill bg-danger text-white task-status-badge">URGENT</span>
        </div>
      </div>


    </div>
  </div>
</div>
