<mwl-text-input-autocomplete-container>
  <textarea type="text" id="expression" name="{{name}}" class="form-control form-control-solid" required
    [value]="'This is the expression'" placeholder="Press CTRL+SPACE to search..." rows="10" cols="50" style="height: 100px;"
    [triggerCharacter]="triggerCharacter" (keydown)="onKeyDown($event)"
    [(ngModel)]="expression" (ngModelChange)="onExpressionChanged()" mwlTextInputAutocomplete (menuShown)="onMenuShown()" (menuHidden)="onMenuHidden()"
    [keyboardShortcut]="keyboardShortcut" [findChoices]="findChoices" [getChoiceLabel]="getChoiceLabel"
    [ngClass]="{'is-invalid' : expressionBeingEdited && expressionBeingEdited.touched && expressionBeingEdited.invalid}" #expressionBeingEdited="ngModel"
    >
  </textarea>
</mwl-text-input-autocomplete-container>
