import { Injectable } from "@angular/core";
import { Constants } from "projects/borrower-app/src/app/services/constants";
import { MilitaryServiceStepComponent } from "projects/borrower-app/src/app/wizard/military-service-step/military-service-step.component";
import { AddressStepComponent } from "projects/borrower-app/src/app/wizard/wizard-steps/address-step/address-step.component";
import { AssetsStepComponent } from "projects/borrower-app/src/app/wizard/wizard-steps/assets-step/assets-step.component";
import { BorrowerCharacteristicsStepComponent } from "projects/borrower-app/src/app/wizard/wizard-steps/borrower-characteristics-step/borrower-characteristics-step.component";
import { ChecklistsStepComponent } from "projects/borrower-app/src/app/wizard/wizard-steps/checklists-step/checklists-step.component";
import { ConsentAuthorizationStepComponent } from "projects/borrower-app/src/app/wizard/wizard-steps/consent-authorization-step/consent-authorization-step.component";
import { CreateAccountStepComponent } from "projects/borrower-app/src/app/wizard/wizard-steps/create-account-step/create-account-step.component";
import { CreateMultipleCoBorrowersStepComponent } from "projects/borrower-app/src/app/wizard/wizard-steps/create-multiple-coborrowers/create-multiple-coborrowers-step.component";
import { CreditInquiryStepComponent } from "projects/borrower-app/src/app/wizard/wizard-steps/credit-inquiry-step/credit-inquiry-step.component";
import { CreditScoresStepComponent } from "projects/borrower-app/src/app/wizard/wizard-steps/credit-scores-step/credit-scores-step.component";
import { CurrentAddressHistoryComponent } from "projects/borrower-app/src/app/wizard/wizard-steps/current-address-history/current-address-history.component";
import { CurrentAddressComponent } from "projects/borrower-app/src/app/wizard/wizard-steps/current-address/current-address.component";
import { DeclarationsStepComponent } from "projects/borrower-app/src/app/wizard/wizard-steps/declarations-step/declarations-step.component";
import { DemographicsStepComponent } from "projects/borrower-app/src/app/wizard/wizard-steps/demographics-step/demographics-step.component";
import { DigitalAssetsStepComponent } from "projects/borrower-app/src/app/wizard/wizard-steps/digital-assets-step/digital-assets-step.component";
import { DigitalIncomeVerificationStepComponent } from "projects/borrower-app/src/app/wizard/wizard-steps/digital-income-verification-step/digital-income-verification-step.component";
import { HoiStepComponent } from "projects/borrower-app/src/app/wizard/wizard-steps/hoi-step/hoi-step.component";
import { IncomeStepComponent } from "projects/borrower-app/src/app/wizard/wizard-steps/income-step/income-step.component";
import { LoanCharacteristicsStepComponent } from "projects/borrower-app/src/app/wizard/wizard-steps/loan-characteristics-step/loan-characteristics-step.component";
import { MultiBorrowersAddressHistoryComponent } from "projects/borrower-app/src/app/wizard/wizard-steps/multiborrowers-address-history-step/multiborrowers-address-history-step.component";
import { MyInfoStepComponent } from "projects/borrower-app/src/app/wizard/wizard-steps/my-info-step/my-info-step.component";
import { NumberOfBorrowersComponent } from "projects/borrower-app/src/app/wizard/wizard-steps/number-of-borrowers/number-of-borrowers.component";
import { OwnRentComponent } from "projects/borrower-app/src/app/wizard/wizard-steps/own-rent/own-rent.component";
import { PurchaseCreditsStepComponent } from "projects/borrower-app/src/app/wizard/wizard-steps/purchase-credits-step/purchase-credits-step.component";
import { RealEstateOwnedStepComponent } from "projects/borrower-app/src/app/wizard/wizard-steps/reo-step/reo-step.component";
import { ReviewApplicationStepComponent } from "projects/borrower-app/src/app/wizard/wizard-steps/review-application-step/review-application-step.component";
import { ReviewCreditStepComponent } from "projects/borrower-app/src/app/wizard/wizard-steps/review-credit-step/review-credit-step.component";
import { SelectOriginatorStepComponent } from "projects/borrower-app/src/app/wizard/wizard-steps/select-originator-step/select-originator-step.component";
import { SingleChoiceQuestionStepComponent } from "projects/borrower-app/src/app/wizard/wizard-steps/single-choice-question-step/single-choice-question-step.component";
import { SubmitApplicationStepComponent } from "projects/borrower-app/src/app/wizard/wizard-steps/submit-application-step/submit-application-step.component";
import { TypeOfLoanStepComponent } from "projects/borrower-app/src/app/wizard/wizard-steps/type-of-loan-step/type-of-loan-step.component";
import { VerifyCreditStepComponent } from "projects/borrower-app/src/app/wizard/wizard-steps/verify-credit-step/verify-credit-step.component";
import { StepTypeConfig } from "../step-type.config";
import { StepType } from "../step-type.enum";
import { GatherLeadSourceStepComponent } from "projects/borrower-app/src/app/wizard/wizard-steps/gather-lead-source-step/gather-lead-source-step.component";
import { PricingStepComponent } from "projects/borrower-app/src/app/wizard/wizard-steps/pricing-step/pricing-step.component";
import { PullExistingApplicationStepComponent } from "projects/borrower-app/src/app/wizard/wizard-steps/pull-existing-application-step/pull-existing-application-step.component";
import { LinkLiabilitiesToReoStepComponent } from "projects/borrower-app/src/app/wizard/wizard-steps/link-liabilities-to-reo-step/link-liabilities-to-reo-step.component";
import { HomeownershipEducationStepComponent } from "projects/borrower-app/src/app/wizard/wizard-steps/homeownership-education-step/homeownership-education-step.component";

@Injectable()
export class StepTypeConfigFactory {

  private _configs: any = {};

  getStepTypeConfig = (stepType: StepType): StepTypeConfig => {
    return this._configs[stepType];
  }

  constructor() {
    this._configs[StepType.SingleChoiceQuestionStep] = new StepTypeConfig("", SingleChoiceQuestionStepComponent);
    this._configs[StepType.MyInfoStep] = new StepTypeConfig(Constants.stepPaths.myInfo, MyInfoStepComponent);
    this._configs[StepType.AddressStep] = new StepTypeConfig(Constants.stepPaths.address, AddressStepComponent);
    this._configs[StepType.CreateAccount] = new StepTypeConfig(Constants.stepPaths.createAccount, CreateAccountStepComponent);
    this._configs[StepType.NumberOfBorrowersStep] = new StepTypeConfig(Constants.stepPaths.numberOfBorrowers, NumberOfBorrowersComponent);
    this._configs[StepType.CreateCoBorrowersStep] = new StepTypeConfig(Constants.stepPaths.createCoBorrowers, CreateMultipleCoBorrowersStepComponent);
    this._configs[StepType.CurrentAddressStep] = new StepTypeConfig(Constants.stepPaths.currentAddress, CurrentAddressComponent);
    this._configs[StepType.OwnershipStatusStep] = new StepTypeConfig(Constants.stepPaths.ownershipStatus, OwnRentComponent);
    this._configs[StepType.AddressHistoryStep] = new StepTypeConfig(Constants.stepPaths.addressHistory, CurrentAddressHistoryComponent);
    this._configs[StepType.DigitalIncomeAndEmploymentStep] = new StepTypeConfig(Constants.stepPaths.digitalIncomeAndEmployment, DigitalIncomeVerificationStepComponent);
    this._configs[StepType.ManualIncomeAndEmploymentStep] = new StepTypeConfig(Constants.stepPaths.manualIncomeAndEmployment, IncomeStepComponent);
    this._configs[StepType.DigitalAssetsStep] = new StepTypeConfig(Constants.stepPaths.digitalAssets, DigitalAssetsStepComponent);
    this._configs[StepType.ManualAssetsStep] = new StepTypeConfig(Constants.stepPaths.manualAssets, AssetsStepComponent);
    this._configs[StepType.ReoStep] = new StepTypeConfig(Constants.stepPaths.reo, RealEstateOwnedStepComponent);
    this._configs[StepType.VerifyCreditStep] = new StepTypeConfig(Constants.stepPaths.verifyCredit, VerifyCreditStepComponent);
    this._configs[StepType.CreditScoresStep] = new StepTypeConfig(Constants.stepPaths.creditScores, CreditScoresStepComponent);
    this._configs[StepType.ReviewCreditStep] = new StepTypeConfig(Constants.stepPaths.reviewCredit, ReviewCreditStepComponent);
    this._configs[StepType.CreditInquiryStep] = new StepTypeConfig(Constants.stepPaths.creditInquiry, CreditInquiryStepComponent);
    this._configs[StepType.LoanTypeStep] = new StepTypeConfig(Constants.stepPaths.loanInfo, TypeOfLoanStepComponent);
    this._configs[StepType.DeclarationsStep] = new StepTypeConfig(Constants.stepPaths.declarations, DeclarationsStepComponent);
    this._configs[StepType.DemographicsStep] = new StepTypeConfig(Constants.stepPaths.demographics, DemographicsStepComponent);
    this._configs[StepType.EConsentStep] = new StepTypeConfig(Constants.stepPaths.econsent, ConsentAuthorizationStepComponent);
    this._configs[StepType.ReviewApplicationStep] = new StepTypeConfig(Constants.stepPaths.reviewApplication, ReviewApplicationStepComponent);
    this._configs[StepType.SubmitApplicationStep] = new StepTypeConfig(Constants.stepPaths.submitApplication, SubmitApplicationStepComponent);
    this._configs[StepType.SelectOriginatorStep] = new StepTypeConfig(Constants.stepPaths.originator, SelectOriginatorStepComponent);
    this._configs[StepType.MultiBorrowersAddressHistoryStep] = new StepTypeConfig(Constants.stepPaths.multiBorrowerAddressHistory, MultiBorrowersAddressHistoryComponent);
    this._configs[StepType.MilitaryServiceStep] = new StepTypeConfig(Constants.stepPaths.militaryService, MilitaryServiceStepComponent);
    this._configs[StepType.HoiStep] = new StepTypeConfig(Constants.stepPaths.hoi, HoiStepComponent);
    this._configs[StepType.LoanCharacteristicsStep] = new StepTypeConfig(Constants.stepPaths.loanCharacteristics, LoanCharacteristicsStepComponent);
    this._configs[StepType.BorrowerCharacteristicsStep] = new StepTypeConfig(Constants.stepPaths.borrowerCharacteristics, BorrowerCharacteristicsStepComponent);
    this._configs[StepType.PurchaseCreditsStep] = new StepTypeConfig(Constants.stepPaths.purchaseCredits, PurchaseCreditsStepComponent);
    this._configs[StepType.CheckListsStep] = new StepTypeConfig(Constants.stepPaths.checklists, ChecklistsStepComponent);
    this._configs[StepType.GatherLeadSourceStep] = new StepTypeConfig(Constants.stepPaths.gatherLeadSource, GatherLeadSourceStepComponent);
    this._configs[StepType.PricingStep] = new StepTypeConfig(Constants.stepPaths.pricing, PricingStepComponent);
    this._configs[StepType.PullExistingApplicationStep] = new StepTypeConfig(Constants.stepPaths.pullExistingApplication,
      PullExistingApplicationStepComponent);
    this._configs[StepType.LinkLiabilitiesToReoStep] = new StepTypeConfig(Constants.stepPaths.linkLiabilitiesToReo, LinkLiabilitiesToReoStepComponent);
    this._configs[StepType.HomeownershipEducationStep] = new StepTypeConfig(Constants.stepPaths.homeownershipEducation, HomeownershipEducationStepComponent);
  }
}

