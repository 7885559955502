import { Directive, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from "@angular/forms";

export function minTimeValidator(minTime: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const invalid = control.value && Date.parse('1/1/2023 ' + control.value) < Date.parse('1/1/2023 ' + minTime);
    return invalid ? { minTime: { value: control.value } } : null;
  };
}

@Directive({
  selector: '[minTime]',
  providers: [{provide: NG_VALIDATORS, useExisting: MinTimeValidatorDirective, multi: true}]
})
export class MinTimeValidatorDirective implements Validator {

  private _minTime: string = "00:00";
  private _onChange?: () => void;

  get minTime(): string {
    return this._minTime;
  }

  @Input()
  set minTime(value: string) {
    this._minTime = value ? value : "00:00";
    if (this._onChange) this._onChange();
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.minTime != null ? minTimeValidator(this.minTime)(control) : null;
  }

  registerOnValidatorChange(fn: () => void): void {
    this._onChange = fn;
  }
}
