import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RealEstateOwned } from '../../../models/mortgage.model';
import * as _ from 'lodash';

@Component({
  selector: 'reo-expense-breakdown',
  templateUrl: 'reo-expense-breakdown.component.html',
  styleUrls: ['./reo-expense-breakdown.component.scss']
})
export class ReoExpenseBreakdownComponent  implements OnInit {

  @Input()
  set reo(reo: RealEstateOwned) {
    if (reo) {
      this._reo = _.cloneDeep(reo);
    }
  }

  get reo(): RealEstateOwned {
    return this._reo;
  }

  @Output()
  okClicked: EventEmitter<RealEstateOwned> = new EventEmitter<RealEstateOwned>();

  @Output()
  cancelClicked: EventEmitter<void> = new EventEmitter<void>();

  private _reo: RealEstateOwned;

  constructor() {}

  ngOnInit() {}

  protected onOkClicked = () => {
    this.okClicked.emit(this.reo);
  }

  protected onCancelClicked = () => {
    this.cancelClicked.emit();
  }
}
