import { forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export abstract class AbstractValueAccessor implements ControlValueAccessor {

    protected _value: any = '';

    get value(): any { return this._value; };

    set value(v: any) {
      this.doSetValue(v);
    }

    writeValue(value: any) {
      this._value = value;
      // warning: comment below if only want to emit on user intervention
      this.onChange(value);
    }

    protected doSetValue(v: any) {
      if (v !== this._value) {
        this._value = v;
        this.onChange(v);
      }
    }

    onChange = (_: any) => {};

    onTouched = () => {};

    registerOnChange(fn: (_: any) => void): void { this.onChange = fn; }

    registerOnTouched(fn: () => void): void { this.onTouched = fn; }
}

export function MakeProvider(type : any){
  return {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => type),
    multi: true
  };
}
