import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { AbstractValueAccessor, MakeProvider } from "projects/shared/abstract-value-accessor";
import { Borrower } from "../../models/borrower-model";

@Component({
  selector : 'borrower-picker',
  templateUrl: 'borrower-picker.component.html',
  providers: [MakeProvider(BorrowerPicker)]
})
export class BorrowerPicker extends AbstractValueAccessor {

  @ViewChild('borrowerPickerForm') borrowerPickerForm: NgForm | undefined;

  @Input() borrowers: Borrower[] = [];

  @Input() required = false;

  @Output()
  borrowerChangedEvent = new EventEmitter<Borrower>();

  markAsTouched = () => {
    this.borrowerPickerForm?.form.markAllAsTouched();
  }

  validate = () => {
    this.borrowerPickerForm?.form.markAllAsTouched();
    if (this.borrowerPickerForm?.form.valid) {
        return true;
    }
    return false;
  }

  public get borrowerChangedEmitter(): EventEmitter<Borrower> {
    return this.borrowerChangedEvent;
  }

  borrowerChanged = (borrower:Borrower) => {
    this.borrowerChangedEvent.emit(borrower);
  }
}
