<div class="modal-header">
  <h2>Payment Calculator</h2>
  <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" aria-label="Close"
    (click)="activeModal.dismiss('Cross click')">
    <span class="svg-icon svg-icon-2x">
      <close-x-svg-icon></close-x-svg-icon>
    </span>
  </div>
</div>
<div class="modal-body py-10 px-lg-17">
  <form #mortgageCalculatorForm="ngForm" class="form" novalidate id="mortgageCalculatorForm" name="mortgageCalculatorForm">
    <!-- hack to fool ngbModal autofocus -->
    <input type="text" style="display:none" />
    <div class="row mb-3">
      <div class="col-md-6 fv-row">
        <div class="row mb-4">
          <div class="col-md-12 fv-row">
            <label class="form-label mb-2">Transaction Type</label>
            <input type="text" class="form-control form-control-solid" disabled [(ngModel)]="application.loanPurpose"
              name="loanPurpose" />
          </div>
        </div>
        <div class="row mb-4" *ngIf="application.loanPurpose === 'Purchase'">
          <div class="col-md-12 fv-row">
            <label class="form-label mb-2">Purchase Price</label>
            <currency-input #purchasePrice="ngModel"
              [(ngModel)]="calculations.purchasePrice" name="purchasePrice"
              [allowDecimals]="false" [min]="0" [placeholder]="'Purchase Price'"
              (blur)="onPurchasePriceChanged()">
            </currency-input>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md-12 fv-row">
            <label class="form-label mb-2">Loan Amount</label>
            <currency-input #loanAmountInput="ngModel" [(ngModel)]="calculations.loanAmount" name="loanAmount"
              [allowDecimals]="false" [min]="0" [placeholder]="'Loan Amount'" [required]="true"
              (blur)="onLoanAmountChanged()"
              [ngClass]="{'is-invalid': loanAmountInput && loanAmountInput.touched && loanAmountInput.invalid}">
            </currency-input>
          </div>
        </div>
        <div class="row mb-4" *ngIf="application.loanPurpose === 'Purchase'">
          <div class="col-md-12 fv-row">
            <label class="form-label mb-2">Down Payment</label>
            <div class="d-flex flex-wrap position-relative">
              <currency-input #downPaymentInput="ngModel" [(ngModel)]="calculations.downPayment" name="downPayment"
                [allowDecimals]="false" [min]="0" [placeholder]="'Down Payment'" style="width: 70%;"
                (blur)="onDownPaymentAmountChanged()">
              </currency-input>
              <i class="fa fa-percent input-percent-icon" aria-hidden="true"></i>
              <input class="dp-percent-control form-control form-control-solid numeric-without-arrows" type="number"
                numeric [allowNegative]="false" #downPaymentPercentInput="ngModel"
                [(ngModel)]="calculations.downPaymentPercent" name="downPaymentPercent" [min]="0" [max]="100"
                [required]="true"
                (blur)="onDownPaymentPercentageChanged()">
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md-12 fv-row">
            <label class="form-label mb-2">Loan Term</label>
            <select #loanTermInput="ngModel" class="form-select form-select-solid"
              (change)="calculatePIAndTotal()"
              [(ngModel)]="calculations.loanTerm" name="loanTerm">
              <option value="30">30 Years</option>
              <option value="20">20 Years</option>
              <option value="15">15 Years</option>
              <option value="10">10 Years</option>
            </select>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md-12 fv-row">
            <label class="form-label mb-2">Interest Rate</label>
            <div class="position-relative">
              <i class="fa fa-percent input-percent-icon" aria-hidden="true"></i>
              <input class="form-control form-control-solid numeric-without-arrows" type="number" numeric
                [allowNegative]="false" [decimals]="3" #interestRateInput="ngModel" [min]="0" [max]="100"
                [(ngModel)]="calculations.interestRate" name="interestRate" [required]="true"
                (ngModelChange)="calculatePIAndTotal()"
                [ngClass]="{'is-invalid': interestRateInput && interestRateInput.touched && interestRateInput.invalid}">
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 fv-row">
        <div class="row mb-4">
          <div class="col-md-12 fv-row p-i-container">
            <div class="d-flex justify-content-between align-items-center">
              <label class="form-label fs-4">Principal & Interest</label>
              <label class="form-label fs-4">{{calculations.pi | currency}}</label>
            </div>
          </div>
        </div>
        <div class="row mb-4" [hidden]="true">
          <div class="col-md-12 fv-row">
            <label class="form-label mb-2"></label>
            <div style="height: 52px;"></div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md-12 fv-row">
            <label class="form-label mb-2">Property Tax</label>
            <currency-input #propertyTaxInput="ngModel" [(ngModel)]="calculations.propertyTax" name="propertyTax"
              [allowDecimals]="false" [min]="0" (ngModelChange)="calculateTotal()"
              [placeholder]="'Property Tax'"></currency-input>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md-12 fv-row">
            <label class="form-label mb-2">Homeowner's Insurance</label>
            <currency-input #hoiInput="ngModel" [(ngModel)]="calculations.hoi" name="hoi"
              (ngModelChange)="calculateTotal()" [allowDecimals]="false" [min]="0"
              [placeholder]="'Homeowner\'s Insurance'"></currency-input>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md-12 fv-row">
            <label class="form-label mb-2">PMI</label>
            <currency-input #hoiInput="ngModel" [(ngModel)]="calculations.pmi" name="pmi"
              (ngModelChange)="calculateTotal()" [allowDecimals]="false" [min]="0"
              [placeholder]="'PMI'"></currency-input>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md-12 fv-row">
            <label class="form-label mb-2">HOA Fees</label>
            <currency-input #hoiInput="ngModel" [(ngModel)]="calculations.hoaFees" name="hoaFees"
              (ngModelChange)="calculateTotal()" [allowDecimals]="false" [min]="0"
              [placeholder]="'HOA Fees'"></currency-input>
          </div>
        </div>
      </div>
      <div class="row mb-4 total-container">
        <div class="col-md-6 offset-md-6 fv-row">
          <div class="d-flex justify-content-between align-items-center">
            <label class="form-label fs-4 text-success">Total Monthly Payment</label>
            <label class="form-label fs-4 text-success">{{totalMonthlyPayment | currency}}</label>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer flex-end">
  <button type="button" class="btn btn-white me-3" (click)="activeModal.dismiss()">Close</button>
</div>
