<div class="d-flex align-items-stretch" *ngFor="let task of tasks">
  <div class="card w-100 mb-4 border border-2">
    <div class="card-header px-4 py-4 d-flex align-items-center justify-content-between border-bottom-2">
      <h4 class="mb-0">
        {{ task.docType || task.description }}
        <br>
        <span class="text-gray-400 fs-8 fw-bold">
          {{ task.description || task.docType }}
        </span>
      </h4>
    </div>

    <div class="card-body">
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex">
          <div class="d-flex align-items-center" *ngIf="task.borrowerId">
            <span
              class="bp-contact-name-circle p-2 rounded-circle fw-bold d-flex align-items-center justify-content-center">
              {{ task.borrowerName?.charAt(0) }}
            </span>
          </div>
          <div class="d-flex flex-column ms-2">
            <strong>{{ task?.borrowerName || "Unknown" }}</strong>
            <span class="text-nowrap">Due {{ task?.dueDate | dateAgo }}</span>
          </div>
        </div>
        <div>
          <span class="badge rounded-pill bg-warning text-dark task-status-badge" *ngIf="
              task.borrowerFacingNote && task.borrowerTaskStatus == 'Rejected'
            ">Urgent Message</span>
          <span class="badge rounded-pill bg-light text-dark task-status-badge" *ngIf="
              task.borrowerFacingNote && task.borrowerTaskStatus != 'Rejected'
            ">New Message</span>
          <span class="badge rounded-pill bg-light text-dark task-status-badge"
            *ngIf="task.linkedDocuments && task.linkedDocuments.length">{{ task.linkedDocuments.length }}
            Documents</span>
          <span class="badge rounded-pill bg-light text-dark task-status-badge"
            *ngIf="task.borrowerTaskStatus == 'Pending'">Pending</span>
          <span class="badge rounded-pill bg-light text-dark task-status-badge"
            *ngIf="task.borrowerTaskStatus == 'Submitted'">Submitted</span>
          <span class="badge rounded-pill bg-warning text-dark task-status-badge"
            *ngIf="task.borrowerTaskStatus == 'Rejected'">Rejected</span>
          <span class="badge rounded-pill bg-light text-dark task-status-badge"
            *ngIf="task.borrowerTaskStatus == 'Completed'">Completed</span>

          <span class="badge rounded-pill bg-danger text-white task-status-badge">URGENT</span>
        </div>
      </div>

      <div class="row my-2" *ngIf="showIncomeEmploymentVerificationButton">
        <div class="w-100 d-flex justify-content-center p-3" *ngIf="task.borrowerTaskStatus != 'Completed'">
          <a href="javascript:void(0)" class="btn btn-sm bp-primary-bg-color" (click)="onStartIncomeEmploymentVerificationClicked(task)">Start
            Income/Employment Verification
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
