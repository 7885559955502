import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ExpressionNextStepDeciderConfig } from '../../models/wizard/config/expression-next-step-decider-config.model';
import { NextStepDeciderConfig } from '../../models/wizard/config/next-step-decider-config.model';
import { NextStepDeciderType } from '../../models/wizard/config/next-step-decider-type.enum';
import { SimpleNextStepDeciderConfig } from '../../models/wizard/config/simple-next-step-decider-config.model';
import { StepConfig } from '../../models/wizard/config/step-config.model';
import { StepExpression } from '../../models/wizard/step-expression.model';

import * as _ from 'lodash';
import { WizardFlowConfig } from '../../models/wizard/config/wizard-flow-config.model';
import { ExpressionNextStepDeciderEditorComponent } from '../expression-next-step-decider-editor/expression-next-step-decider-editor.component';
import { GenericStepConfig } from '../../models/wizard/generic-step-config.model';
import { StepType } from '../../models/wizard/config/step-type.enum';

@Component({
  selector: 'step-editor',
  templateUrl: 'flow-step-editor.component.html'
})

export class FlowStepEditorComponent implements OnInit, AfterViewInit {

  @ViewChild('expressionEditor') expressionEditor!: ExpressionNextStepDeciderEditorComponent | undefined;

  _step: StepConfig;

  @Input()
  set step(step: StepConfig) {
    this._step = step;
    if (this._step.nextStepDeciderConfig?.deciderType == NextStepDeciderType.Simple) {
      this.simpleNextStepPath = (<SimpleNextStepDeciderConfig>this._step.nextStepDeciderConfig).gotoStepPath;
    } else {
      this.expressionNextStepDeciderConfig = <ExpressionNextStepDeciderConfig>step.nextStepDeciderConfig;
    }
  }

  get step(): StepConfig {
    return this._step;
  }

  @Input()
  flow!: WizardFlowConfig;

  simpleNextStepPath: string | undefined = "";

  private _originalNextStepDeciderConfig!: NextStepDeciderConfig;

  @Output()
  nextDeciderTypeChanged = new EventEmitter<StepConfig>();

  @Output()
  stepEditingCancelled: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  stepEditingEditingOked: EventEmitter<any> = new EventEmitter<any>();

  expressionNextStepDeciderConfig: ExpressionNextStepDeciderConfig | undefined;

  constructor() {
    this._step = new GenericStepConfig("", "", "", 1, StepType.ReoStep, undefined);
  }

  ngAfterViewInit(): void {
    if (this.expressionEditor) {
      this.expressionEditor.expressionsChanged.subscribe(e => {
        this.nextDeciderTypeChanged.emit();
      });
    }
  }

  ngOnInit() {
    if (!this.step.nextStepDeciderConfig) {
      this.step.nextStepDeciderConfig = new SimpleNextStepDeciderConfig("");
    }
    this._originalNextStepDeciderConfig = _.cloneDeep(this.step.nextStepDeciderConfig);
  }

  onSimpleNextStepChanged = () => {
    if (this._step.nextStepDeciderConfig?.deciderType == NextStepDeciderType.Simple) {
      (<SimpleNextStepDeciderConfig>this.step.nextStepDeciderConfig).gotoStepPath = this.simpleNextStepPath;
      this.nextDeciderTypeChanged.emit(this.step);
    }
  }

  onOkClicked = () => {
    this.stepEditingEditingOked.emit();
  }

  onCancelClicked = () => {
    this.stepEditingCancelled.emit();
  }

  onNextStepDeciderTypeChanged = () => {
    setTimeout(() => {
      if (this.step.nextStepDeciderConfig?.deciderType == this._originalNextStepDeciderConfig.deciderType) {
        this.step.nextStepDeciderConfig = _.cloneDeep(this._originalNextStepDeciderConfig);
        this.nextDeciderTypeChanged.emit(this.step);
        return;
      }
      if (this.step.nextStepDeciderConfig?.deciderType == NextStepDeciderType.Expression) {
        let gotoPath: string | undefined = "";
        if (this._originalNextStepDeciderConfig.deciderType == NextStepDeciderType.Simple) {
          gotoPath = (<SimpleNextStepDeciderConfig>this._originalNextStepDeciderConfig).gotoStepPath;
        } else {
          gotoPath = (<ExpressionNextStepDeciderConfig>this._originalNextStepDeciderConfig).ifStepExpression.goToPath;
        }
        const stepExpression = new StepExpression("", gotoPath ? gotoPath : "");
        this.step.nextStepDeciderConfig = new ExpressionNextStepDeciderConfig(stepExpression, undefined, undefined);
        this.expressionNextStepDeciderConfig = <ExpressionNextStepDeciderConfig>this.step.nextStepDeciderConfig;
      } else {
        this.step.nextStepDeciderConfig = new SimpleNextStepDeciderConfig("");
      }
      this.nextDeciderTypeChanged.emit(this.step);
    }
    );
  }
}
