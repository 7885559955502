import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../../services/auth.service';
import { EnvironmentService } from 'projects/shared/services/environment.service';
import { AuthenticationUserCompany } from '../../models/auth/auth-response.model';
import { debounce } from 'lodash';

@Component({
  selector: 'password-reset-send-code-dialog',
  templateUrl: 'password-reset-send-code-dialog.component.html',
  styleUrls: ['password-reset-send-code-dialog.component.scss']
})
export class PasswordResetSendCodeDialogComponent implements OnInit {

  @ViewChild('sendCodeForm') sendCodeForm: NgForm | undefined;

  @Input()
  email: string | undefined;

  sendCodeButtonText: string = "Send Code";

  spinnerVisible: boolean = false;

  errorMessage: string = "";

  userScope: string;

  availableCompanies: AuthenticationUserCompany[] = [];

  userCompanyGuid: string;

  constructor(
    public activeModal: NgbActiveModal,
    private readonly _authService: AuthService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _environment: EnvironmentService,
  ) { }

  ngOnInit() {
    this.userScope = this._environment.scope
    this.getAvailableCompanies();
  }

  onSendCodeClicked = () => {
    this.sendCodeForm?.form.markAllAsTouched();
    if (this.sendCodeForm?.form.valid) {
      this.toggleProgress(true);
      this._authService.sendTwoFactorEmailCode(this.userCompanyGuid).subscribe(result => {
        this.activeModal.close(this.email);
      }, error => {
        this.errorMessage = error;
      }).add(() => {
        this.toggleProgress(false);
      });
    }
  }

  onEmailChangedDebounce = debounce(this.onEmailChanged, 300);

  private onEmailChanged() {
    if (!this.email) {
      this.userCompanyGuid = null;
      return;
    }

    this.getAvailableCompanies();
  }

  private toggleProgress = (value: boolean) => {
    if (value) {
      this._spinner.show();
    } else {
      this._spinner.hide();
    }
    this.spinnerVisible = value;
    if (value) {
      this.sendCodeButtonText = "Please wait...";
    } else {
      this.sendCodeButtonText = "Send Code";
    }
  }

  private getAvailableCompanies = () => {
    this._spinner.show();
    this._authService.getAvailableCompanies(this.email, this.userScope || 'AgentAndBorrower').subscribe({
      next: (response) => {
        this.availableCompanies = response || [];

        if (this.availableCompanies.length === 1) {
          this.userCompanyGuid = this.availableCompanies[0].userCompanyGuid;
        }
      },
      error: (error) => {
        this.errorMessage = 'Unable to get companies for a provided email';
      }
    }).add(() => {
      this._spinner.hide();
    });
  }
}
