import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { NgxSpinnerService } from 'ngx-spinner';
import { PortalContent } from "../../models/borrower-portal/portal-content-model-model";
import { BasePortalService } from "../../services/base-portal.service";
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'portal-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})

export class PortalAboutComponent implements OnInit {

  portalContent: PortalContent = new PortalContent();
  aboutUsContent: any;

  constructor(
    private readonly _portalService: BasePortalService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notifyService: NotificationService,
    private sanitizer: DomSanitizer,
  ) {
  }

  ngOnInit() {
    this._spinner.show();
    this._portalService.getPortalContent().subscribe({
      next: (portalContent) => {
        this.portalContent = portalContent;
        this.aboutUsContent = this.sanitizer.bypassSecurityTrustHtml(this.portalContent.aboutUsContent);
        this._spinner.hide();
      },
      error: (err) => {
        this._spinner.hide();
        this._notifyService.showError(err.message || err, "Error!");
      }
    });
  }
}
