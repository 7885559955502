import { Component, Input, OnInit } from '@angular/core';
import { ApplicationForUser } from '../../../models/borrower-portal/application-for-user-model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-mortgage-calculator-dialog',
  templateUrl: './mortgage-calculator-dialog.component.html',
  styleUrls: ['./mortgage-calculator-dialog.component.scss']
})
export class MortgageCalculatorDialogComponent implements OnInit {
  @Input() application: ApplicationForUser;

  calculations: MortgageCalculation = new MortgageCalculation();
  totalMonthlyPayment: number;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.calculations.loanAmount = this.application.loanAmount;
    this.calculations.interestRate = this.application.interestRate;
    this.calculations.loanPurpose = this.application.loanPurpose;
    this.calculations.purchasePrice = this.application.purchasePrice;

    this.onLoanAmountChanged();
  }

  protected onPurchasePriceChanged = () => {
    const homePrice = this.calculations.purchasePrice || 0;
    const downPayment = this.calculations.downPayment || 0;
    if (homePrice) {
      this.calculations.loanAmount = homePrice - downPayment
    } else {
      this.calculations.loanAmount = 0;
    }
    this.calculatePIAndTotal();
  }

  protected onLoanAmountChanged = () => {
    const homePrice = this.calculations.purchasePrice || 0;
    const loanAmount = this.calculations.loanAmount || 0;
    this.calculations.downPayment = homePrice - loanAmount;
    if (homePrice) {
      this.calculations.downPaymentPercent = Math.floor((this.calculations.downPayment * 100) / homePrice);
    } else {
      this.calculations.downPaymentPercent = 0;
    }
    this.calculatePIAndTotal();
  }

  protected onDownPaymentAmountChanged = () => {
    const homePrice = this.calculations.purchasePrice || 0;
    const downPayment = this.calculations.downPayment || 0;
    this.calculations.loanAmount = homePrice - downPayment;
    if (homePrice) {
      this.calculations.downPaymentPercent = Math.floor((this.calculations.downPayment * 100) / homePrice);
    } else {
      this.calculations.downPaymentPercent = 0;
    }
    this.calculatePIAndTotal();
  }

  protected onDownPaymentPercentageChanged = () => {
    const homePrice = this.calculations.purchasePrice || 0;
    const downPaymentPercent = this.calculations.downPaymentPercent || 0;
    this.calculations.downPayment = homePrice * (downPaymentPercent / 100);
    this.calculatePIAndTotal();
  }

  protected calculateTotal() {
    const pi = this.calculations.pi || 0;
    const propertyTax = this.calculations.propertyTax || 0;
    const homeownerInsurance = this.calculations.hoi || 0;
    const pmi = this.calculations.pmi || 0;
    const hoaFees = this.calculations.hoaFees || 0;
    this.totalMonthlyPayment = Number(pi) + Number(propertyTax) + Number(homeownerInsurance) + Number(pmi) + Number(hoaFees);
  }

  protected calculatePIAndTotal() {
    this.calculatePAndI();
    this.calculateTotal();
  }

  private calculatePAndI() {
    /* M=P[r(1+r)^n/((1+r)^n)-1)]
      * M = the total monthly mortgage payment.
      * P = the principal loan amount.
      * r = the monthly interest rate. This is the annual rate that your lender provides divided by 12 months.
      * n = the number of monthly loan payments. This is the number of years of your loan multiplied by 12.
    */
    const annualInterestRate = this.calculations.interestRate || 0;
    const downPayment = this.calculations.downPayment || 0;
    const loanTermInMonths = Number(this.calculations.loanTerm) * 12;
    const homePrice = this.calculations.purchasePrice || 0;
    const principalLoanAmount = homePrice - downPayment;
    const monthlyInterest = (annualInterestRate / 100) / 12;
    var exponential = Number(Math.pow((1 + monthlyInterest), loanTermInMonths));  // (1+r)^n
    var pi = exponential == 1
      ? principalLoanAmount / loanTermInMonths
      : principalLoanAmount * ((monthlyInterest * exponential) / (exponential - 1));

    this.calculations.pi = Math.floor(pi);
  }
}

class MortgageCalculation {
  loanPurpose: string;
  loanAmount: number = 0;
  downPayment: number = 0;
  downPaymentPercent: number = 0;
  loanTerm: number = 30;
  interestRate: number = 0;
  pi: number = 0;
  propertyTax: number = 0;
  hoi: number = 0;
  pmi: number = 0;
  hoaFees: number = 0;
  purchasePrice: number = 0;
}
