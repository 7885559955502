import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

declare const $: any;

@Component({
  selector: 'credit-check-consent-dialog',
  templateUrl: 'credit-check-consent-dialog.component.html'
})

export class CreditCheckConsentDialogComponent implements OnInit {

  consentGivenToCheckCredit: boolean = false;

  constructor(public activeModal: NgbActiveModal) {
  }

  @ViewChild('creditCheckConsentForm') creditCheckConsentForm: NgForm | undefined;

  @Input()
  creditAuthText: string = "";

  @Input()
  message: string = "";

  @Input()
  creditWillBeRunLater: boolean = false;

  onConsentGivenClicked = () => {
    this.creditCheckConsentForm?.form.markAllAsTouched();
    if (this.creditCheckConsentForm?.form.valid) {
      this.activeModal.close();
    }
  }

  ngOnInit() {
    $("#creditCheckConsentContainer").html(this.creditAuthText);
  }
}
