<ng-template #pullExistingApplication>

  <div class="row editor-container">
    <div class="col-12 container">
      <form #loanInfoForm="ngForm" class="form" novalidate id="loanInfoForm" name="loanInfoForm">
        <div style="margin: 0 auto;">
          <div class="fv-row mb-3">
            <div>
              <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                <span>Enter Property Zip:</span>
              </label>
              <div class="position-relative">
                <input #enteredZipCode="ngModel" type="text" [dropSpecialCharacters]="false"
                  [validation]="false" [pattern]="zipCodePattern" [mask]="zipCodeMask" [placeholder]="zipCodePlaceHolder"
                  [name]="'zipCode'" [id]="'zipCode'" [required]="true" [(ngModel)]="subjectPropertyZipCode"
                  (keydown.enter)="onSubmitClicked()" class="form-control form-control-solid" [ngClass]="{
                  'is-invalid':
                    enteredZipCode && enteredZipCode.touched && enteredZipCode.invalid
                }" />
                <div class="invalid-feedback">Please enter a zip code.</div>
              </div>
            </div>
          </div>
          <div class="fv-row mb-3">
            <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
              <span>Enter Last 4 Digits of SSN:</span>
            </label>
            <div class="position-relative">
              <input class="form-control form-control-solid" [showMaskTyped]="true" [(ngModel)]="borrowerSSNLastFourDigits"
                #socialSecNum="ngModel" [dropSpecialCharacters]="false" [mask]="ssnMask" [pattern]="ssnPattern"
                placeholder="Last 4 of SSN" name="ssn" id="ssn" (keydown.enter)="onSubmitClicked()"
                [required]="true" [ngClass]="{
                'is-invalid':
                  socialSecNum && socialSecNum.touched && socialSecNum.invalid
              }" />
              <div class="invalid-feedback">
                Please enter last 4 digits of your SSN.
              </div>
            </div>
          </div>
          <div class="fv-row button-container" *ngIf="pullInfoAttemptCount > 0">
            <a (click)="onSubmitClicked()" class="btn btn-success">Get My Info & Continue</a>
          </div>
          <div class="fv-row mt-1" *ngIf="pullAttemptFailed && pullInfoAttemptCount > 0">
            We are unable to verify that information, you have {{pullInfoAttemptCount}} attempt(s) remaining.
          </div>
          <div class="fv-row mt-1" *ngIf="pullAttemptFailed && pullInfoAttemptCount === 0">
            We are unable to verify that information, please skip and continue.
          </div>
        </div>
      </form>
    </div>
  </div>

</ng-template>

<wizard-step-template [stepMainTemplate]="pullExistingApplication" [step]="step"
  [stepComponent]="this"></wizard-step-template>
