export class Utils {

  static containsDigit = (value: string): boolean => {
    const regexp = /[0-9]/g;
    return regexp.test(value);
  };

  static containsUppercase = (value: string): boolean => {
    const regexp = /[A-Z]/g;
    return regexp.test(value);
  };

  static containsLowercase = (value: string): boolean => {
    const regexp = /[a-z]/g;
    return regexp.test(value);
  };

  static containsSpecialCharacter = (value: string): boolean => {
    const regexp = /[^a-zA-Z0-9]/g;
    return regexp.test(value);
  }

  static removeCountryCodeIfAny = (phoneNumber: string): string => {
    if (phoneNumber && phoneNumber.length > 10 && phoneNumber.startsWith("1")) {
      return phoneNumber.substring(phoneNumber.length - 10);
    }
    return phoneNumber;
  }

  static getUniqueId = () => {
    const uniqueId = Date.now() & 0xffffffff;
    return uniqueId < 0 ? uniqueId : uniqueId * -1;
  }
}

declare const google: any;

export function load_map_and_street_view_from_address(address) {
  // Check if GPS has been locally cached.
  var geocoder = new google.maps.Geocoder();
  var directionsService = new google.maps.DirectionsService();
  geocoder.geocode({
    'address': address
  }, function(results, status) {
    if (status == google.maps.GeocoderStatus.OK) {

      var gps = results[0].geometry.location;
      // create_map_and_streetview(gps.lat(), gps.lng(), 'map_canvas', 'pano');

      var request = {
        origin: address,
        destination: address,
        travelMode: google.maps.TravelMode.DRIVING
      };
      directionsService.route(request, function(response, status) {
        if (status == google.maps.DirectionsStatus.OK) {
          var cameraLatLng = response.routes[0].legs[0].steps[0].start_location;
          create_map_and_streetview(gps, cameraLatLng, 'pano');
        }
      });
    }
  });
}

export function create_map_and_streetview(addLatLng, panoLatLng, street_view_id) {

  var panorama = new google.maps.StreetViewPanorama(document.getElementById(street_view_id));
  var service = new google.maps.StreetViewService();
  service.getPanoramaByLocation(panoLatLng, 50, function(panoData, status) {
    if (status != google.maps.StreetViewStatus.OK) {
      // $('#pano').html('No StreetView Picture Available').attr('style', 'text-align:center;font-weight:bold').show();
      return;
    }
    var angle = google.maps.geometry.spherical.computeHeading(panoData.location.latLng, addLatLng);
    document.getElementById('angle').innerHTML = angle.toString();
    var panoOptions = {
      position: panoLatLng,
      pov: {
        heading: angle,
        pitch: 0,
        zoom: 1
      },
      enableCloseButton: false,
      visible: true
    };

    panorama.setOptions(panoOptions);
  });
}
