export class Option {
    name: string = "";
    text: string = "";
    explanation: string = "";
    value: any = "";
    iconName: string | undefined;
    selected: boolean;
    iconType: string = "svg";
    iconClass: string | undefined;

    constructor(name: string, text: string, explanation: string, value: any, iconName?: string) {
      this.text = text;
      this.explanation = explanation;
      this.value = value;
      this.selected = false;
      this.name = name;
      this.iconName = iconName;
    }
}
