import { IDecideNextStep } from "../../services/wizard/next-step-decider/next-step-decider.interface";
import { CreditInquiryStepComponent } from "../../wizard/wizard-steps/credit-inquiry-step/credit-inquiry-step.component";
import { WizardStepBase } from "./wizard-step-base.model";

export class CreditInquiryStep extends WizardStepBase {
  constructor(id: string, name: string, path: string, nextStepDecider?: IDecideNextStep) {
    super(id, name, path, nextStepDecider);
    this.component = CreditInquiryStepComponent;
  }
}
