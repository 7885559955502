<div class="modal-header">
  <h5 class="modal-title d-flex align-items-center">Add Files</h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>

<div class="modal-body">
  <form>
    <div class="row mb-4">
      <div class="col-md-6 fv-row">
        <label class="form-label mb-2">Select a Borrower</label>
        <div class="form-group ms-auto">
          <select [(ngModel)]="selectedBorrowerId" class="form-select" name="selectedBorrower">
            <option *ngIf="application.myDetails.borrowerId" [ngValue]="application.myDetails.borrowerId" selected>
              {{ application.myDetails.borrowerName }}
            </option>
            <option *ngFor="let borrower of application.otherBorrowersOnLoan" [ngValue]="borrower.borrowerId">
              {{ borrower.borrowerName }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-md-12 fv-row">
        <label class="form-label" for="notes">Notes</label>
        <textarea class="form-control" rows="3" [(ngModel)]="notes" name="notes"></textarea>
      </div>
    </div>

    <hr />

    <file-upload (uploadClicked)="onUploadClicked($event)" [isUploading]="isUploading"></file-upload>
  </form>
</div>
