
<!--begin::Tables Widget 9-->
<div>
  <!--begin::Header-->
  <div>
    <h3 class="align-items-start flex-column">
      <span class="text-muted mt-1 fw-bold fs-7">{{inquiry.entries.length}} inquiries</span>
    </h3>
  </div>
  <!--end::Header-->
  <!--begin::Body-->
  <div>
    <!--begin::Table container-->
    <div class="table-responsive">
      <form #borrowerInfoForm="ngForm" novalidate id="borrowerInfoForm" name="borrowerInfoForm">
        <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
          <!--begin::Table head-->
          <thead>
            <tr class="fw-bolder text-muted">
              <th class="min-w-150px">Inquirer</th>
              <th class="min-w-140px">Date</th>
              <th class="min-w-100px text-end">Reason For Inquiry</th>
              <th class="min-w-100px text-end">Was New Debt Acquired</th>
            </tr>
          </thead>
          <!--end::Table head-->
          <tbody>
            <tr *ngFor="let entry of inquiry.entries; let i = index">
              <td>
                <div class="d-flex align-items-center">
                  <div class="d-flex justify-content-start flex-column">
                    <a href="#" class="text-dark fw-bolder text-hover-primary fs-6">{{entry.fullName}}</a>
                    <span class="text-muted fw-bold text-muted d-block fs-7"></span>
                  </div>
                </div>
              </td>
              <td>
                <a href="#" class="text-dark fw-bolder text-hover-primary d-block fs-6">{{inquiry.dateInserted | date}}</a>
                <span class="text-muted fw-bold text-muted d-block fs-7"></span>
              </td>
              <td class="text-end">
                <select class="form-select form-select-solid" [(ngModel)]="entry!.reason" data-control="select2" id="reason{{borrowerIndex}}{{i}}" name="reason{{borrowerIndex}}{{i}}"
                [ngClass]="{'is-invalid' : reason && reason.touched && reason.invalid}"  #reason="ngModel">
                  <option value="">Select one...</option>
                  <option value="AutoLoan">Auto Loan</option>
                  <option value="CreditCard">Credit Card</option>
                  <option value=">MortgageShopping">Mortgage Shopping</option>
                </select>
              </td>
              <td class="text-end">
                <select class="form-select form-select-solid" [(ngModel)]="entry!.newDebtAcquired" data-control="select2" id="newDebtAcquired{{borrowerIndex}}{{i}}" name="newDebtAcquired{{borrowerIndex}}{{i}}"
                [ngClass]="{'is-invalid' : newDebtAcquired && newDebtAcquired.touched && newDebtAcquired.invalid}"  #newDebtAcquired="ngModel">
                  <option value="">Select one...</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
        </form>
    </div>
    <!--end::Table container-->
  </div>
  <!--begin::Body-->
</div>
<!--end::Tables Widget 9-->

