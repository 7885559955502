import { Component, Input, OnInit } from '@angular/core';
import { EnumerationItem } from 'projects/shared/models/enumeration-item.model';
import { Borrower } from '../../models/borrower-model';
import { Demographics } from '../../models/demographics-model';
import { EnumsService } from '../../services/enums.service';
import { WizardFlowServiceBase } from '../../services/wizard/wizard-flow-service.base';

@Component({
  selector: 'borrower-demographics',
  templateUrl: 'borrower-demographics.component.html',
  styleUrls: ['borrower-demographics.component.scss']
})

export class BorrowerDemographicsComponent implements OnInit {

  @Input()
  borrower!: Borrower;

  @Input()
  showOptionToNotProvideDemographicsInfo: boolean = true;

  dontWantToProvideAnyOfTheInfo: boolean = false;

  sexOptions: EnumerationItem[] = [];
  ethnicityOptions: EnumerationItem[] = [];
  hispanicOriginOptions: EnumerationItem[] = [];
  raceOptions: EnumerationItem[] = [];
  raceDesignationOptions: EnumerationItem[] = [];
  asianRaceDesignationOptions: EnumerationItem[] = [];
  pacificIslanderRaceDesignationOptions: EnumerationItem[] = [];

  asianRace: string = '';
  pacificIslanderRace: string = '';
  nativeAmericanRace: string = '';

  ethnicities: string[] = [];
  ethnicityOrigins: string[] = [];
  sexes: string[] = [];
  races: string[] = [];
  raceDesignations: string[] = [];

  protected sexHasValidSelection = true;
  protected ethnicityHasValidSelection = true;
  protected raceHasValidSelection = true;

  constructor(private readonly _wizardFlowservice: WizardFlowServiceBase,
    private readonly _enumsService: EnumsService) {
  }

  ngOnInit() {
    this._enumsService.getMortgageEnumerations().subscribe(enums => {
      this.populateOptionsToChooseFrom(enums, this.borrower);
      if (this._wizardFlowservice.isEditEnabled) {
        this.dontWantToProvideAnyOfTheInfo = true;
        setTimeout(() => {
          this.dontWantToProvideAnyInfoCheckChanged();
        });
      } else {
        if (!this.borrower.governmentMonitors) {
          this.borrower.governmentMonitors = new Demographics();
        }
        if (this.borrower.governmentMonitors.ethnicity && this.borrower.governmentMonitors.ethnicity.length > 0) {
          this.ethnicities = this.borrower.governmentMonitors.ethnicity.split(", ");
        }
        if (this.borrower.governmentMonitors.ethnicityOrigin && this.borrower.governmentMonitors.ethnicityOrigin.length > 0) {
          this.ethnicityOrigins = this.borrower.governmentMonitors.ethnicityOrigin.split(", ");
        }
        if (this.borrower.governmentMonitors.sex && this.borrower.governmentMonitors.sex.length > 0) {
          this.sexes = this.borrower.governmentMonitors.sex.split(", ");
        }
        if (this.borrower.governmentMonitors.race && this.borrower.governmentMonitors.race.length > 0) {
          this.races = this.borrower.governmentMonitors.race.split(", ");
        }
        if (this.borrower.governmentMonitors.raceDesignation && this.borrower.governmentMonitors.raceDesignation.length > 0) {
          this.raceDesignations = this.borrower.governmentMonitors.raceDesignation.split(", ");
        }
      }
    });
  }

  public validate = (): boolean => {
    this.sexHasValidSelection = this.isSexSelected();
    this.raceHasValidSelection = this.isRaceSelected();
    this.ethnicityHasValidSelection = this.isEthnicitySelected();
    if (this.sexHasValidSelection && this.raceHasValidSelection && this.ethnicityHasValidSelection) {
      return true;
    }
    return false;
  }

  protected etnicityChanged = () => {
    this.borrower.governmentMonitors.ethnicity = '';

    if (this.ethnicities.length != 0 && this.ethnicities[this.ethnicities.length-1] == 'NotApplicable') {
      this.ethnicities = ['NotApplicable'];
      this.borrower.governmentMonitors.ethnicity = 'NotApplicable';
    } else if (this.ethnicities.length != 0 && this.ethnicities[this.ethnicities.length-1] == 'InformationNotProvided') {
      this.ethnicities = ['InformationNotProvided'];
      this.borrower.governmentMonitors.ethnicity = 'InformationNotProvided';
    } else if (this.ethnicities.length != 0) {
      if (this.ethnicities.includes('NotApplicable')) {
        const index = this.ethnicities.findIndex(ethnicity => ethnicity == 'NotApplicable');
        this.ethnicities.splice(index, 1);
        this.ethnicities = [...this.ethnicities];
      }
      if (this.ethnicities.includes('InformationNotProvided')) {
        const index = this.ethnicities.findIndex(ethnicity => ethnicity == 'InformationNotProvided');
        this.ethnicities.splice(index, 1);
        this.ethnicities = [...this.ethnicities];
      }

      this.ethnicities.forEach(ethnicity => {
        this.borrower.governmentMonitors.ethnicity += ethnicity + ', ';
      });
      this.borrower.governmentMonitors.ethnicity = this.borrower.governmentMonitors.ethnicity.slice(0, this.borrower.governmentMonitors.ethnicity.length-2);
    }
    if (this.ethnicities.length && !this.ethnicityHasValidSelection) {
      this.ethnicityHasValidSelection = true;
    }
  }

  protected ethnicityOriginChanged = () => {
    this.borrower.governmentMonitors.ethnicityOrigin = '';

    if (this.ethnicityOrigins.length != 0) {
      this.ethnicityOrigins.forEach(ethnicityOrigin => {
        this.borrower.governmentMonitors.ethnicityOrigin += ethnicityOrigin + ', ';
      });
      this.borrower.governmentMonitors.ethnicityOrigin = this.borrower.governmentMonitors.ethnicityOrigin.slice(0, this.borrower.governmentMonitors.ethnicityOrigin.length-2);
    }
  }

  protected raceChanged = (event: any, value: string) => {
    if (event) {
      const index = this.races.findIndex(v => v == value);
      if (event.target.checked) {
        if (index < 0) {
          this.races.push(value);
        }
      } else if (!event.target.checked) {
        if (index >= 0) {
          this.races.splice(index, 1);
        }
      }
    } else if (value == 'InformationNotProvided') {
      this.races = ['InformationNotProvided'];
    }

    this.borrower.governmentMonitors.race = '';
    if (this.races.length == 0) {
      return
    }

    if (value == 'NotApplicable') {
      this.races = ['NotApplicable'];
      this.borrower.governmentMonitors.race = 'NotApplicable';
    } else if (value == 'InformationNotProvided') {
      this.races = ['InformationNotProvided'];
      this.borrower.governmentMonitors.race = 'InformationNotProvided';
    } else if (this.races.length != 0) {
      if (this.races.includes('NotApplicable')) {
        const index = this.races.findIndex(race => race == 'NotApplicable');
        this.races.splice(index, 1);
      }
      if (this.races.includes('InformationNotProvided')) {
        const index = this.races.findIndex(race => race == 'InformationNotProvided');
        this.races.splice(index, 1);
      }
      this.races.forEach(race => {
        this.borrower.governmentMonitors.race += race + ', ';
      });
      this.borrower.governmentMonitors.race = this.borrower.governmentMonitors.race.slice(0, this.borrower.governmentMonitors.race.length-2);
    }
    if (this.races.length && !this.raceHasValidSelection) {
      this.raceHasValidSelection = true;
    }
  }

  protected raceDesignationChanged = () => {
    this.borrower.governmentMonitors.raceDesignation = '';

    if (this.raceDesignations.length != 0) {
      this.raceDesignations.forEach(raceDesignation => {
        this.borrower.governmentMonitors.raceDesignation += raceDesignation + ', ';
      });
      this.borrower.governmentMonitors.raceDesignation = this.borrower.governmentMonitors.raceDesignation.slice(0, this.borrower.governmentMonitors.raceDesignation.length-2);
    }
  }

  protected onSexInformationChanged = () => {
    this.borrower.governmentMonitors.sex = '';

    if (this.sexes.length != 0 && this.sexes[this.sexes.length -1] == 'NotApplicable') {
      this.sexes = ['NotApplicable'];
      this.borrower.governmentMonitors.sex = 'NotApplicable';
    } else if (this.sexes.length != 0 && this.sexes[this.sexes.length -1] == 'InformationNotProvided') {
      this.sexes = ['InformationNotProvided'];
      this.borrower.governmentMonitors.sex = 'InformationNotProvided';
    } else if (this.sexes.length != 0) {
      if (this.sexes.includes('NotApplicable')) {
        const index = this.sexes.findIndex(sex => sex == 'NotApplicable');
        this.sexes.splice(index, 1);
        this.sexes = [...this.sexes];
      }
      if (this.sexes.includes('InformationNotProvided')) {
        const index = this.sexes.findIndex(sex => sex == 'InformationNotProvided');
        this.sexes.splice(index, 1);
        this.sexes = [...this.sexes];
      }

      this.sexes.forEach(sex => {
        this.borrower.governmentMonitors.sex += sex + ', ';
      });
      this.borrower.governmentMonitors.sex = this.borrower.governmentMonitors.sex.slice(0, this.borrower.governmentMonitors.sex.length-2);
    }
    if (this.sexes.length && !this.sexHasValidSelection) {
      this.sexHasValidSelection = true;
    }
  }

  protected isHispanicOrLatinoSelected = () => {
    const selected = this.ethnicities.includes(this._enumsService.getEnumValue('EthnicityTypeHispanicOrLatino'));
    if (!selected) {
      this.ethnicityOrigins = [];
    }
    return selected;
  }

  protected isOtherSelectedForHispanicOrLatinoEthnicity = () => {
    const selected = this.ethnicityOrigins.includes(this._enumsService.getEnumValue('EthnicityOriginTypeOther'));
    if (!selected) {
      this.borrower.governmentMonitors.ethnicityOriginOtherDescription = undefined;
    }
    return selected;
  }

  protected isEthnicitySelected = (): boolean => {
    const isEthnicitySelected = this.borrower.governmentMonitors.ethnicity != null &&
        this.borrower.governmentMonitors.ethnicity.length > 0;
    if (!isEthnicitySelected){
        this.borrower.governmentMonitors.ethnicityOrigin = undefined;
    }
    return isEthnicitySelected;
  }

  protected isSexSelected = (): boolean => {
    const isSexSelected = this.borrower.governmentMonitors.sex != null &&
        this.borrower.governmentMonitors.sex.length > 0;
    return isSexSelected;
  }

  protected isRaceSelected = (): boolean => {
    const isRaceSelected = this.borrower.governmentMonitors.race != null &&
        this.borrower.governmentMonitors.race.length > 0;
    if (!isRaceSelected){
        this.borrower.governmentMonitors.raceDesignation = undefined;
        this.raceDesignations = [];
    }
    return isRaceSelected;
  }

  protected isAsianSelected = () => {
    const selected = this.races.includes(this._enumsService.getEnumValue('RaceTypeAsian'));
      if (!selected) {
        if(this.raceDesignations && this.raceDesignations.length > 0) {
          this.asianRaceDesignationOptions.forEach(asianRaceDesignation => {
            const index = this.raceDesignations.findIndex(raceDesignation => raceDesignation == asianRaceDesignation.value);
            if (index >= 0) {
              this.raceDesignations.splice(index, 1);
              this.raceDesignationChanged();
            }
          });
        }

      }
    return selected;
  }

  protected isPacificIslanderSelected = () => {
    const selected = this.races.includes(this._enumsService.getEnumValue('RaceTypeNativeHawaiianOrOtherPacificIslander'));
      if (!selected) {
        if(this.raceDesignations && this.raceDesignations.length > 0) {
          this.pacificIslanderRaceDesignationOptions.forEach(pacificIslanderRaceDesignation => {
            const index = this.raceDesignations.findIndex(raceDesignation => raceDesignation == pacificIslanderRaceDesignation.value);
            if (index >= 0) {
              this.raceDesignations.splice(index, 1);
              this.raceDesignationChanged();
            }
          });
        }
      }
    return selected;
  }

  protected isNativeAmericanSelected = () => {
    const selected = this.races.includes(this._enumsService.getEnumValue('RaceTypeAmericanIndianOrAlaskaNative'));
    if (!selected) {
      this.borrower.governmentMonitors.nativeAmericanTribeName = undefined;
    }
    return selected;
  }

  protected isOtherSelectedForAsianRace = () => {
    var selected = this.raceDesignations.includes(this._enumsService.getEnumValue('RaceDesignationOtherAsian'));
    if (!selected){
        this.borrower.governmentMonitors.otherAsianRace = undefined;
    }
    return selected;
  }

  protected isOtherSelectedForPacificIslanderRace = () => {
    var selected = this.raceDesignations.includes(this._enumsService.getEnumValue('RaceDesignationOtherPacificIslander'));
    if (!selected){
        this.borrower.governmentMonitors.otherPacificIslanderRace = undefined;
    }
    return selected;
  }

  protected dontWantToProvideAnyInfoCheckChanged = () => {
    if (this.dontWantToProvideAnyOfTheInfo) {
      this.raceChanged(undefined, 'InformationNotProvided');
      this.ethnicities.push('InformationNotProvided');
      this.etnicityChanged();
      this.sexes.push('InformationNotProvided');
      this.onSexInformationChanged();
    }
  }

  private populateOptionsToChooseFrom = (enums: any, borrower: Borrower) => {
    this.ethnicityOptions = enums['EthnicityType'];
      this.hispanicOriginOptions = enums['EthnicityOriginType'];
      this.raceOptions = enums['RaceType'];
      this.sexOptions = enums['SexType'];

      if (borrower.mortgagePartyType === 'Individual') {
        const naIndexForEthnicity = this.ethnicityOptions.findIndex(e => e.value === 'NotApplicable');
        if (naIndexForEthnicity >= 0) {
          this.ethnicityOptions.splice(naIndexForEthnicity, 1);
        }
        const naIndexForRace = this.raceOptions.findIndex(e => e.value === 'NotApplicable');
        if (naIndexForRace >= 0) {
          this.raceOptions.splice(naIndexForRace, 1);
        }
        const naIndexForSex = this.sexOptions.findIndex(e => e.value === 'NotApplicable');
        if (naIndexForSex >= 0) {
          this.sexOptions.splice(naIndexForSex, 1);
        }
      }
      this.raceDesignationOptions = enums['RaceDesignation'];
      this.asianRaceDesignationOptions = this.raceDesignationOptions.filter(t =>
        t.value == this._enumsService.getEnumValue('RaceDesignationAsianIndian') ||
        t.value == this._enumsService.getEnumValue('RaceDesignationChinese') ||
        t.value == this._enumsService.getEnumValue('RaceDesignationFilipino') ||
        t.value == this._enumsService.getEnumValue('RaceDesignationJapanese') ||
        t.value == this._enumsService.getEnumValue('RaceDesignationKorean') ||
        t.value == this._enumsService.getEnumValue('RaceDesignationVietnamese') ||
        t.value == this._enumsService.getEnumValue('RaceDesignationOtherAsian'));

      this.asianRace = this._enumsService.getEnumValue('RaceTypeAsian');

      this.pacificIslanderRaceDesignationOptions = this.raceDesignationOptions.filter(t =>
        t.value == this._enumsService.getEnumValue('RaceDesignationGuamanianOrChamorro') ||
        t.value == this._enumsService.getEnumValue('RaceDesignationNativeHawaiian') ||
        t.value == this._enumsService.getEnumValue('RaceDesignationSamoan') ||
        t.value == this._enumsService.getEnumValue('RaceDesignationOtherPacificIslander'));

      this.pacificIslanderRace = this._enumsService.getEnumValue('RaceTypeNativeHawaiianOrOtherPacificIslander');
      this.nativeAmericanRace = this._enumsService.getEnumValue('RaceTypeAmericanIndianOrAlaskaNative');
  }
}
