
<div class="post d-flex flex-column-fluid">
  <div class="container">
    <div class="card">
      <div class="card-body p-0">
        <div class="card-px text-center py-20 my-10">
          <h2 class="fs-2x fw-bolder mb-10">System Error!</h2>
          <p class="text-gray-400 fs-4 fw-bold mb-4">Something went wrong.</p>
          <p class="text-gray-400 fs-4 fw-bold mb-10">Please try again later...</p>
        </div>
        <div class="text-center px-4">
          <img class="mw-100 mh-300px" alt="" src="assets/images/error.jpg" />
        </div>
      </div>
    </div>
  </div>
</div>
