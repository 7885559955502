import { Injectable } from "@angular/core";
import { UtilsService } from "projects/borrower-app/src/app/services/utils.service";
import { IDecideNextStep } from "projects/borrower-app/src/app/services/wizard/next-step-decider/next-step-decider.interface";
import { WizardFlowContext } from "../../../wizard-flow-context.model";
import { CheckListsStep } from "../../checklists-step.model";
import { ChecklistsStepConfig } from "../checklists-step-config.model";
import { NextStepDeciderFactory } from "./next-step-decider.factory";
import { StepFactoryBase } from "./step-factory-base";
import { ICreateStep } from "./step-factory.interface";

@Injectable()
export class ChecklistsStepFactory extends StepFactoryBase<ChecklistsStepConfig> implements ICreateStep {

  constructor(private readonly _nextStepDeciderFactory: NextStepDeciderFactory,
    private readonly _utilsService: UtilsService) {
      super();
  }

  configure(step: CheckListsStep, config: ChecklistsStepConfig): void {
    config.title = step.title;
    config.explanation = step.explanation;
    config.heading = step.heading;
    config.checklistIds = step.selectedChecklistIds;
  }

  create(config: ChecklistsStepConfig, wizardFlowContext: WizardFlowContext): CheckListsStep {
    let nextStepDecider: IDecideNextStep | undefined = undefined;
    if (config.nextStepDeciderConfig) {
        nextStepDecider = this._nextStepDeciderFactory.create(config.nextStepDeciderConfig, wizardFlowContext);
    }
    let checklistsStep = new CheckListsStep(config.id, config.name, config.path, nextStepDecider);
    checklistsStep.title = config.title;
    checklistsStep.explanation = config.explanation;
    checklistsStep.heading = config.heading;
    checklistsStep.selectedChecklistIds = config.checklistIds;
    checklistsStep.groupId = config.groupId;

    return checklistsStep;
  }
}
