<div class="card card shadow-sm h-100 price-card" (click)="cardClicked()" *ngIf="product" style="border: 1px solid #eff2f5;"
  [ngClass]="{'inactive-box cursor-pointer': !isSelected && !liabilityPayOffInformationChanged, 'readonly' : liabilityPayOffInformationChanged}">
  <div class="card-body p-9" [ngClass]="{'blur-overlay': liabilityPayOffInformationChanged}">
    <div class="d-flex">
      <div class="fs-2hx fw-bold flex-grow-1">{{ quote ? ((quote.adjustedRate / 100) | percent:'1.3-3') : 'N/A'}}
      </div>
      <a href="javascript:void(0)" class="d-flex align-items-center text-primary opacity-75-hover fs-6 fw-semibold"
        (click)="onRateAprEditClicked()">
        See Other Options

        <i class="fas fa-eye fs-4 ms-1"><span class="path1"></span><span class="path2"></span></i>
      </a>
    </div>

    <div class="fs-4 fw-semibold text-gray-500 mb-7 product-name">{{product.productName}}</div>

    <div class="fs-6 d-flex justify-content-between mb-4">
      <div class="fw-semibold">Loan Amount</div>
      <div class="d-flex fw-bold">
        {{loanAmount | currency: 'USD': 'symbol'}}
      </div>
    </div>

    <div class="separator separator-dashed"></div>

    <div class="fs-6 d-flex justify-content-between my-4">
      <div class="fw-semibold">Appraised Value</div>

      <div class="d-flex fw-bold">
        {{appraisedValue | currency: 'USD': 'symbol'}}
      </div>
    </div>

    <div class="separator separator-dashed"></div>

    <div class="fs-6 d-flex justify-content-between my-4">
      <div class="fw-semibold">Points</div>

      <div class="d-flex fw-bold">
        <span *ngIf="quote.adjustedPrice >= 100.00" class="text-muted price-rebate">{{quote.rebatePercent || '0.00'}} /
          {{(quote.rebateDollars | currency)}}</span>
        <span *ngIf="quote.adjustedPrice < 100.00" class="text-muted price-discount">{{quote.discountPercent}} /
          {{(quote.discountDollars | currency)}}</span>
      </div>
    </div>

    <div class="separator separator-dashed"></div>

    <div class="fs-6 d-flex justify-content-between my-4">
      <div class="fw-semibold">Rate/APR</div>

      <div class="d-flex fw-bold">
        <span>{{quote.adjustedRate ? ((quote.adjustedRate / 100) |
          percent:'1.3-3'):
          'Unknown'}} / {{quote.apr ? ((quote.apr / 100) | percent:'1.3-3') : 'Unknown'}}
          <!-- <i
            class="fas fa-pencil-alt ms-1" (click)="onRateAprEditClicked()">
          </i> -->
        </span>
      </div>
    </div>

    <div class="separator separator-dashed"></div>

    <div class="fs-6 d-flex justify-content-between my-4">
      <div class="fw-semibold">Est. Fees for APR</div>

      <div class="d-flex fw-bold">
        <span>{{((quote.totalFeesDollars) || 0 | currency)}}
        </span>
        <a href="javascript:void(0)" class="d-flex align-items-center text-primary opacity-75-hover fs-6 fw-semibold"
          (mousedown)="op.show($event)">
          <i class="fas fa-info-circle fs-4 ms-1"></i>
        </a>
        <p-overlayPanel #op styleClass="password-validation-overlay">
          <ng-template pTemplate>
            <div style="min-width: 300px;">
              <div class="fs-6 d-flex justify-content-between my-4">
                <div class="fw-semibold">Closing Costs</div>
                <div class="d-flex fw-bold">
                  {{(quote.thirdPartyFeesDollars || 0)| currency: 'USD': 'symbol'}}
                </div>
              </div>

              <div class="separator separator-dashed"></div>

              <div class="fs-6 d-flex justify-content-between my-4">
                <div class="fw-semibold">Odd Days Interest</div>

                <div class="d-flex fw-bold">
                  {{ ((quote.totalFeesDollars || 0) - (quote.thirdPartyFeesDollars || 0)) | currency: 'USD': 'symbol'}}
                </div>
              </div>
            </div>
          </ng-template>
        </p-overlayPanel>
      </div>
    </div>

    <div class="separator separator-dashed"></div>

    <div class="fs-6 d-flex justify-content-between mt-4">
      <div class="fw-semibold">Monthly P&I</div>

      <div class="d-flex fw-bold">
        <span>{{quote.principalAndInterest | currency: 'USD': 'symbol'}}</span>
      </div>
    </div>
  </div>
</div>
