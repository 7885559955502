import { IDecideNextStep } from "../../services/wizard/next-step-decider/next-step-decider.interface";
import { LoanCharacteristicsStepComponent } from "../../wizard/wizard-steps/loan-characteristics-step/loan-characteristics-step.component";
import { WizardStepBase } from "./wizard-step-base.model";

export class LoanCharacteristicsStep extends WizardStepBase {
  constructor(id: string, name: string, path: string, nextStepDecider?: IDecideNextStep) {
    super(id, name, path, nextStepDecider);
    this.component = LoanCharacteristicsStepComponent;
  }
}
