<div id="{{id}}" class="rightnav ">
  <div class="notification-header box-shadow-drawer">
    <div class="row align-items-center">
      <div class="d-flex">
        <h4 class="notification-title flex-grow-1">{{title}}</h4>
        <a href="javascript:void(0)" class="closebtn" (click)="onCloseClicked()">&times;</a>
      </div>
    </div>
  </div>
  <div class="notification-footer box-shadow-inset">
  </div>
  <ng-container #container *ngIf="isOpen" [ngTemplateOutlet]="templateRef">
  </ng-container>
  <div class="drawer-content-wrapper">
    <ng-container #dynamicComponentContainer></ng-container>
  </div>
</div>
