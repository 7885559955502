
export class AgentRequestACallModel {
  applicationId: number;
  bestContactMumber: string | undefined;
  note: string | undefined;
  bestCallbackTime: Date;

  constructor(appId: number) {
    this.applicationId = appId;
    this.bestCallbackTime = new Date();
  }
}
