import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SubjectProperty } from '../../models/subject-property.model';
import { EnumsService } from '../../services/enums.service';
import { Address } from '../../models/address-model';
import { AddressComponent } from '../address/address.component';
import * as _ from 'lodash';
import { EnumerationItem } from 'projects/shared/models/enumeration-item.model';
import { Constants } from '../../services/constants';

@Component({
  selector: 'subject-property-summary-dialog',
  templateUrl: 'subject-property-summary-dialog.component.html'
})
export class SubjectPropertySummaryDialogComponent implements OnInit {

  @ViewChild('subjectPropertySummaryForm') subjectPropertySummaryForm: NgForm | undefined;

  @ViewChild(AddressComponent) addressComponent: AddressComponent | undefined;

  @Input()
  set subjectProperty(subjectProperty: SubjectProperty | undefined) {
    this._subjectProperty = _.cloneDeep(subjectProperty);
    if (this._subjectProperty) {
      this.address = new Address();
      this.address.address1 = this._subjectProperty.address1;
      this.address.city = this._subjectProperty.city;
      this.address.state = this._subjectProperty.state;
      this.address.zipCode = this._subjectProperty.zipCode;
    }
  }

  get subjectProperty(): SubjectProperty | undefined {
    return this._subjectProperty;
  }

  public get willBeTypes() {
    return this._enumsService.propertyWillBeTypes;
  }

  public address!: Address;

  protected propertyTypes: EnumerationItem[] = [];

  private _subjectProperty: SubjectProperty | undefined;

  constructor(public activeModal: NgbActiveModal,
    private readonly _enumsService: EnumsService) {
    this._enumsService.getMortgageEnumerations().subscribe(enums => {
      this.propertyTypes = enums[Constants.mortgageEnumerations.propertyType]
    })
  }

  ngOnInit() {
  }

  onSaveClicked = () => {
    this.subjectPropertySummaryForm?.form.markAllAsTouched();
    if (this.addressComponent?.validate() && this.subjectPropertySummaryForm?.form.valid) {
      this._subjectProperty!.address1 = this.address.address1 ?? '';
      this._subjectProperty!.city = this.address.city ?? '';
      this._subjectProperty!.state = this.address.state ?? '';
      this._subjectProperty!.zipCode = this.address.zipCode ?? '';
      this.activeModal.close(this._subjectProperty);
    }
  }
}
