
  <form #borrowerInfoForm="ngForm" novalidate id="borrowerInfoForm" name="borrowerInfoForm">
      <div class="row mb-6">
        <label class="col-lg-4 col-form-label required fw-bold fs-6">First Name</label>
        <div class="col-lg-8 fv-row">
          <input type="text" id="firstName" name="firstName" #firstName="ngModel" required [ngClass]="{'is-invalid' : firstName && firstName.touched && firstName.invalid}"
            [(ngModel)]="borrower.firstName" class="form-control form-control-lg form-control-solid" placeholder="First Name" />
            <div class="invalid-feedback">Please enter a first name.</div>
        </div>
      </div>
      <div class="row mb-6">
        <label class="col-lg-4 col-form-label fw-bold fs-6">
          <span class="required">Last Name</span>
        </label>
        <div class="col-lg-8 fv-row">
          <input type="text" id="lastName" name="lastName" #lastName="ngModel" required [ngClass]="{'is-invalid' : lastName && lastName.touched && lastName.invalid}"
            [(ngModel)]="borrower.lastName" class="form-control form-control-lg form-control-solid" placeholder="Last Name" />
            <div class="invalid-feedback">Please enter a last name.</div>
        </div>
      </div>
      <div class="row mb-6">
        <label class="col-lg-4 col-form-label fw-bold fs-6">
          <span>Phone Number</span>
        </label>
        <div class="col-lg-8 fv-row">
          <input type="text" id="mobilePhone" name="mobilePhone" placeholder="" mask='(000) 000-0000' #mobilePhone="ngModel"
            [ngClass]="{'is-invalid' : mobilePhone && mobilePhone.touched && mobilePhone.invalid}"
            [(ngModel)]="borrower.mobilePhone" class="form-control form-control-lg form-control-solid"
            (ngModelChange)="onBorrowerPhoneChanged()"
            placeholder="Phone Number" />
            <div class="invalid-feedback">Please enter a valid phone number.</div>
        </div>
      </div>
      <div class="row mb-6">
        <label class="col-lg-4 col-form-label fw-bold fs-6">
          <span class="required">E-mail Address</span>
          <div class="fs-7 fw-bold text-muted">Will be used as your user Id</div>
        </label>
        <div class="col-lg-8 fv-row">
          <input type="email" id="emailAddress" [email]="true" trim name="emailAddress" #email="ngModel" required [ngClass]="{'is-invalid' : email && email.touched && email.invalid}"
            [(ngModel)]="borrower.primaryEmail" class="form-control form-control-lg form-control-solid" placeholder="Email Address" />
          <div class="invalid-feedback">Please enter a valid e-mail.</div>
        </div>
      </div>
      <ng-container *ngIf="doesSetPassword">
        <div class="row mb-6 position-relative">
          <label class="col-lg-4 col-form-label fw-bold fs-6">
            <span class="required">Password</span>
          </label>
          <div class="col-lg-8 fv-row">
            <input type="password" class="form-control form-control-solid" [(ngModel)]="password" autocomplete="off" required
                  placeholder="" name="password" id="password"
                  required
                  requiresDigit
                  minlength="8"
                  [caseSize]="'both'"
                  requiresNonAlphaNumeric
                  (mousedown)="op.show($event)"
                  (ngModelChange)="onPasswordChanged()"
                  (blur)="op.hide()"
                  [ngClass]="{'is-invalid' : userPassword && userPassword.touched && userPassword.invalid}" #userPassword="ngModel"
                  validateEqual="confirmedPassword"  reverse="true" />
                  <p-overlayPanel #op styleClass="password-validation-overlay">
                    <ng-template pTemplate>
                      <div>
                        <div class="d-flex password-validation-indicator" [ngClass]="{
                            'text-success': passwordIsMinimum8Characters
                            }">
                          <i class="far password-validation-indicator-icon fa-sad-tear" [ngClass]="{
                              'fa-smile': passwordIsMinimum8Characters,
                              'text-success': passwordIsMinimum8Characters
                              }"></i>
                          <div>Min. 8 characters</div>
                        </div>
                        <div class="d-flex password-validation-indicator" [ngClass]="{
                            'text-success': passwordContainsDigit
                            }">
                          <i class="far password-validation-indicator-icon fa-sad-tear" [ngClass]="{
                              'fa-smile': passwordContainsDigit,
                              'text-success': passwordContainsDigit
                              }"></i>
                          <div>Include number</div>
                        </div>
                        <div class="d-flex password-validation-indicator" [ngClass]="{
                          'text-success': passwordContainsLowercase
                          }">
                          <i class="far password-validation-indicator-icon fa-sad-tear" [ngClass]="{
                            'fa-smile': passwordContainsLowercase,
                            'text-success': passwordContainsLowercase
                            }"></i>
                          <div>Include lowercase letter</div>
                        </div>
                        <div class="d-flex password-validation-indicator" [ngClass]="{
                        'text-success': passwordContainsUppercase
                        }">
                          <i class="far password-validation-indicator-icon fa-sad-tear" [ngClass]="{
                          'fa-smile': passwordContainsUppercase,
                          'text-success': passwordContainsUppercase
                          }"></i>
                          <div>Include uppercase letter</div>
                        </div>
                        <div class="d-flex password-validation-indicator" [ngClass]="{
                          'text-success': passwordContainsSpecialCharacter
                          }">
                            <i class="far password-validation-indicator-icon fa-sad-tear" [ngClass]="{
                            'fa-smile': passwordContainsSpecialCharacter,
                            'text-success': passwordContainsSpecialCharacter
                            }"></i>
                            <div>Include a special character</div>
                          </div>
                      </div>

                    </ng-template>
                  </p-overlayPanel>
            <div class="invalid-feedback" *ngIf="userPassword.errors && userPassword.errors.required">Please enter your password.</div>
            <div class="invalid-feedback" *ngIf="userPassword.errors && !userPassword.errors.required">The password you entered does not meet the requirements.</div>
            <div class="invalid-feedback" *ngIf="userPassword.errors && userPassword.errors.validateEqual" class="invalid-feedback">Password & Confirm Password do not match.</div>
            <span class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                  data-kt-password-meter-control="visibility">
              <i *ngIf="!passwordInputState.displayed" class="bi bi-eye-slash fs-2" [ngClass]="{'mb-7': userPassword.touched && userPassword.errors && userPassword.errors?.required}"
                (click)="onPasswordStateClicked(passwordInputState)" (touchend)="onPasswordStateClicked(passwordInputState)"></i>
              <i *ngIf="passwordInputState.displayed" class="bi bi-eye fs-2" [ngClass]="{'mb-7': userPassword.touched && userPassword.errors && userPassword.errors?.required}"
                (click)="onPasswordStateClicked(passwordInputState)" (touchend)="onPasswordStateClicked(passwordInputState)"></i>
            </span>
          </div>
        </div>
        <div class="row mb-6 position-relative">
          <label class="col-lg-4 col-form-label fw-bold fs-6">
            <span class="required">Confirm Password</span>
          </label>
          <div class="col-lg-8 fv-row">
          <input type="password" class="form-control form-control-solid" [(ngModel)]="confirmedPassword" autocomplete="off" required
                  placeholder="" name="confirmedPassword" id="confirmedPassword"
                  [ngClass]="{'is-invalid' : userConfirmedPassword && userConfirmedPassword.touched && userConfirmedPassword.invalid}" #userConfirmedPassword="ngModel"
                  validateEqual="password" />
          <div class="invalid-feedback" *ngIf="userConfirmedPassword.errors && userConfirmedPassword.errors.required">Please enter your password again.</div>
          <div class="invalid-feedback" *ngIf="userConfirmedPassword.errors && userConfirmedPassword.errors.validateEqual" class="invalid-feedback">Password & Confirm Password do not match.</div>
          <span class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
          data-kt-password-meter-control="visibility">
          <i *ngIf="!confirmPasswordInputState.displayed" class="bi bi-eye-slash fs-2"
            [ngClass]="{'mb-7': userConfirmedPassword.touched && userConfirmedPassword.errors && (userConfirmedPassword.errors?.validateEqual || !confirmedPassword),
            'mb-14': password && userConfirmedPassword.touched && userConfirmedPassword.errors && userConfirmedPassword.errors.required}"
            (click)="onPasswordStateClicked(confirmPasswordInputState)" (touchend)="onPasswordStateClicked(confirmPasswordInputState)"></i>
          <i *ngIf="confirmPasswordInputState.displayed" class="bi bi-eye fs-2"
            [ngClass]="{'mb-7': userConfirmedPassword.touched && userConfirmedPassword.errors && (userConfirmedPassword.errors?.validateEqual || !confirmedPassword),
            'mb-14': password && userConfirmedPassword.touched && userConfirmedPassword.errors &&
            userConfirmedPassword.errors?.required}"
            (click)="onPasswordStateClicked(confirmPasswordInputState)" (touchend)="onPasswordStateClicked(confirmPasswordInputState)"></i>
        </span>
          </div>
        </div>
      </ng-container>
  </form>
