import { Injectable } from "@angular/core";
import { AgentPortalService } from "../agent-portal/services/agent-portal.service";
import { BorrowerPortalService } from "../borrower-portal/services/borrower-portal.service";
import { BasePortalService } from "./base-portal.service";
import { DataService } from "./data.service";
import { UtilsService } from "./utils.service";
import { PortalScope } from "../login/login.component";

@Injectable()
export class PortalServiceFactory {

  private _baseUrl: string;

  constructor(private readonly _utilsService: UtilsService,
    private readonly _dataService: DataService) {
    this._baseUrl = this._utilsService.getBaseUrl();
  }

  create(): BasePortalService {
    if (this._baseUrl.toLowerCase() === "agent-portal") {
      return new AgentPortalService(this._dataService);
    }
    return new BorrowerPortalService(this._dataService);
  }

  createByScope(scope: PortalScope): BasePortalService {
    if (scope === PortalScope.Agent) {
      return new AgentPortalService(this._dataService);
    }
    return new BorrowerPortalService(this._dataService);
  }
}
