import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Borrower } from '../../models/borrower-model';
import { NgForm } from '@angular/forms';
import { CreditInquiryDetails, CreditInquiryDetailsEntry } from '../../models/credit-inquiry-details.model';

@Component({
  selector: 'borrower-credit-inquiry',
  templateUrl: 'borrower-credit-inquiry.component.html'
})

export class BorrowerCreditInquiryComponent implements OnInit {

  @ViewChild('borrowerInfoForm') borrowerInfoForm: NgForm | undefined;

  constructor() { }

  @Input()
  borrower!: Borrower;

  @Input()
  borrowerIndex!: number;

  public get inquiry(): CreditInquiryDetails {
    if (!this.borrower.creditInquiry) {
      this.borrower.creditInquiry = new CreditInquiryDetails();
    }
    if (this.borrower.creditInquiry.entries.length === 0) {
      this.borrower.creditInquiry.entries.push(new CreditInquiryDetailsEntry());
    }
    return this.borrower.creditInquiry;
  }

  validate = () => {
    this.borrowerInfoForm?.form.markAllAsTouched();
    if (this.borrowerInfoForm?.form.valid) {
        return true;
    }
    return false;
}

  ngOnInit() { }
}
