import { Component, Injector, OnInit } from '@angular/core';
import { ReviewCreditStep } from '../../../models/wizard/review-credit-step.model';
import { WizardStepComponentBase } from '../wizard-step-base.component';
import { Liability, RealEstateOwned } from '../../../models/mortgage.model';

@Component({
  selector: 'review-credit-step',
  templateUrl: 'review-credit-step.component.html'
})
export class ReviewCreditStepComponent extends WizardStepComponentBase<ReviewCreditStep> implements OnInit {

  constructor(private readonly _injector: Injector) {
    super(_injector);
    this.saveMortgageApplicationBeforeNextStep = true;
  }

  liabilities: Liability[] = [];
  reos: RealEstateOwned[] = [];

  ngOnInit() {
    this.reos = this.mortgageApplication.realEstateOwned;
    if (this.inCoApplyFlow) {
      this.reos = this.reos.filter(r => r.owningBorrowerIds.includes(this.currentBorrower.borrowerId!));
    }
    this.liabilities = this.mortgageApplication.liabilities
      .filter(r => r.typeOfLiability == '4' || r.typeOfLiability == '8')
      .sort((a, b) => (a.liabilityId && b.liabilityId && a.liabilityId < b.liabilityId) ? 1 : -1);
    if (this.inCoApplyFlow) {
      this.liabilities = this.liabilities.filter(l => l.owningBorrowerIds.includes(this.currentBorrower.borrowerId!));
    }
  }
}
