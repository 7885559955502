import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'error-page',
  templateUrl: 'error.component.html'
})

export class ErrorComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
