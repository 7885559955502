import { Component, OnInit } from "@angular/core";
import { WizardStepComponentBase } from "../wizard-step-base.component";

@Component({
  selector: 'file-coborrower-application',
  templateUrl: 'file-coborrower-application.component.html'
})


export class FileCoborrowerApplicationComponent extends WizardStepComponentBase<any> implements OnInit {

  public willFileForCoBorrowers: boolean | undefined = undefined;

  onYesSelected = () => {
    this.willFileForCoBorrowers = true;
    super.onNextClicked();
  }

  onNoSelected = () => {
    this.willFileForCoBorrowers = false;
    super.onNextClicked();
  }

  ngOnInit() { }
}
