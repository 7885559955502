<ng-template #currentAddressHistory>
    <div class="row mb-10">
     <div class="fv-row">
       <div class="row">
        <form #addressHistoryForm="ngForm" novalidate id="addressHistoryForm" name="addressHistoryForm">
            <div class="w-100">
              <div class="row mb-10">
              <div class="col-md-6 fv-row">
                  <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                    <span>Years</span>
                  </label>
                  <div class="position-relative">
                  <input type="text" class="form-control form-control-solid" [(ngModel)]="address!.durationYears" (ngModelChange)="onDurationChanged()"  required placeholder="" name="years"/>
                  </div>
                </div>
                <div class="col-md-6 fv-row">
                  <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                    <span>Months</span>
                  </label>
                  <div class="position-relative">
                    <input type="text" class="form-control form-control-solid" [(ngModel)]="address!.durationMonths" (ngModelChange)="onDurationChanged()"  required placeholder="" name="months"/>
                  </div>
                </div>
                <div *ngIf="!hasEnoughAddressHistory">
                  <div class="text-danger">Please provide a minimum of 2 years address history.</div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="row mb-10">
          <div class="mr-2">
            <button type="button" (click)="addAddressHistory()" class="btn btn-lg btn-light-primary me-3">Add Address History</button>
          </div>
        </div>
        <div class="row mb-10">
          <div class="col-md-12 fv-row mb-5" *ngFor="let previousAddress of previousAddresses; let i = index">
            <div class="card card-flush shadow-sm">
              <div class="card-header">
                <h3 class="card-title">Address History {{i + 1}}</h3>
              </div>
              <div class="card-body py-2">
                <address-history2 #addressHistoryInfo [residencyAddress]="previousAddress"></address-history2>
              </div>
            </div>
          </div>
        </div>
       </div>
     </div>
   </ng-template>
