import { Component, Injector } from '@angular/core';
import { WizardStepComponentBase } from '../wizard-step-base.component';
import { PricingStep } from '../../../models/wizard/pricing-step.model';
import { Observer, firstValueFrom } from 'rxjs';
import * as _ from 'lodash';
import {
  PricingResponse,
  Product,
  ProductSearchResult,
  Quote,
} from '../../../models/pricing/pricing-response.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PriceOptionsDialogComponent } from './price-options-dialog/price-options-dialog.component';
import { Constants } from '../../../services/constants';
import { ProductSearchRequest } from '../../../models/pricing/product-search-request-info.model';
import { AssignPricingRequestModel } from '../../../models/pricing/assign-pricing-request.model';
import { MortgageApplication } from '../../../models/mortgage-app.model';
import { Liability } from '../../../models/mortgage.model';
import { DiffChecker } from 'projects/shared/utils/diff-checker';

@Component({
  selector: 'pricing-step',
  templateUrl: 'pricing-step.component.html',
  styleUrls: ['pricing-step.component.scss']
})
export class PricingStepComponent extends WizardStepComponentBase<PricingStep> {

  protected productSearchResults: ProductSearchResult | undefined;
  protected productSearchRequest: ProductSearchRequest | undefined;

  protected selectedPricingProduct: Product;

  protected priceOptionsForSelectedProduct: Quote[] = [];

  protected loanAmount: number = 50000;
  protected loanTerm: number = 30;

  protected maxLoanAmount: number;
  protected minLoanAmount: number = 50000;

  protected mortgage: MortgageApplication;

  protected liabilityPayOffInformationChanged: boolean = false;

  protected liabilitiesToPayOff: Liability[] = [];

  private _originalLiabilities: Liability[] = [];

  get canMoveToNextStep(): boolean {
    return !!this.selectedPricingProduct || !this.productSearchResults?.products || this.productSearchResults?.products.length === 0;
  }

  constructor(
    private readonly _injector: Injector,
    private readonly _modalService: NgbModal
  ) {
    super(_injector);
    this.saveMortgageApplicationBeforeNextStep = true;
    this.initializePayOffInfo(this.mortgageApplication);
    this.mortgage = this.mortgageApplication;
    this._originalLiabilities = _.cloneDeep(this.mortgage.liabilities);
    this.liabilitiesToPayOff = this.mortgage.liabilities.filter(l => l.payoffType === 'Full' || l.partialPayoffAmount === l.unpaidBalance);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.runPricingSearch();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.resizeLoanAmountInput();
  }

  ngOnDestroy(): void { }

  onKeyUpOnLoanAmount = () => {
    this.resizeLoanAmountInput();
  }

  onLoanAmountChanged = () => {
    if (this.loanAmount > this.maxLoanAmount) {
      this.loanAmount = this.maxLoanAmount;
    } else if (this.loanAmount < this.minLoanAmount) {
      this.loanAmount = this.minLoanAmount;
    }
    if (this.productSearchRequest) {
      this.productSearchRequest.loanInformation.baseLoanAmount = this.loanAmount;
      this.repriceLoan();
    }
  }

  onLoanTermChanged = () => {

    if (this.productSearchRequest) {
      this.productSearchRequest.loanTerms = [];
      this.productSearchRequest.loanTerms.push(this.loanTerm * 12);
      this.repriceLoan();
    }
  }

  onLiabilityChanged = (liability: Liability) => {
    const diffChecker = new DiffChecker(
      this._originalLiabilities,
      this.mortgage.liabilities,
      'liabilities',
    );
    const changes = diffChecker.calculateDiff(true)
    this.liabilityPayOffInformationChanged = !!changes;
    this.liabilitiesToPayOff = this.mortgage.liabilities.filter(l => l.payoffType === 'Full' || l.partialPayoffAmount === l.unpaidBalance);
  }

  onNextClicked(): void {
    if (this.isEditEnabled || !!!this.selectedPricingProduct) {
      super.onNextClicked();
      return;
    }
    const observerAssign: Observer<any> = {
      next: (value: any) => {
      },
      error: (err: any) => {
      },
      complete: () => {
      }
    }
    const observerGet: Observer<any> = {
      next: (value: any) => {
        this.wizardFlowService.navigateForward();
      },
      error: (err: any) => {
      },
      complete: () => {
      }
    }
    const assignPricingRequestModel: AssignPricingRequestModel = {
      request: this.productSearchRequest,
      productId: this.selectedPricingProduct.productId,
    };
    if (this.selectedPricingProduct.quotes.length > 0) {
      assignPricingRequestModel.rate = this.selectedPricingProduct.quotes[0].adjustedRate;
      assignPricingRequestModel.price = this.selectedPricingProduct.quotes[0].adjustedPrice;
      assignPricingRequestModel.lockTerm = this.selectedPricingProduct.quotes[0].lockPeriod;
    }
    this.spinner.show();
    this.mortgageApplicationService.assignProductToApplication(this.mortgageApplication.applicationId, assignPricingRequestModel).subscribe(observerAssign)
      .add(() => {
        this.mortgageApplicationService.getMortgage(this.mortgageApplication.applicationId).subscribe(observerGet).add(() => {
          this.spinner.hide();
          super.onNextClicked();
        });
      });
  }

  onRateEditClicked = async (product: any) => {
    try {
      this.spinner.show();
      const priceDetails = await firstValueFrom(
        this.mortgageApplicationService.getProductPricingDetails(
          {
            ...this.productSearchRequest, productIds: [product.productId],
            thirdPartySearchId: this.productSearchResults.searchId
          },
          this.productSearchResults.pricingVendor
        )
      );
      if (
        priceDetails &&
        priceDetails.quotes &&
        priceDetails.quotes.length > 0
      ) {
        this.priceOptionsForSelectedProduct = _.orderBy(priceDetails.quotes, ['adjustedRate', 'asc']);

        const modalRef = this._modalService.open(PriceOptionsDialogComponent, Constants.modalOptions.xlarge);
        modalRef.componentInstance.priceOptions = this.priceOptionsForSelectedProduct;

        modalRef.result.then((result) => {
          this.onPriceOptionSelected(result);
        }, (err) => {

        });
      }
    } catch (err) {
    } finally {
      this.spinner.hide();
    }
  };

  onRefreshPricingClicked = () => {
    this.repriceLoan();
  }

  onPriceOptionSelected = (quote: Quote) => {
    this.selectedPricingProduct.quotes[0] = quote;
    const index = this.productSearchResults.products.findIndex(p => p.productId === this.selectedPricingProduct.productId);
    if (index >= 0) {
      this.productSearchResults.products[index] = { ...this.selectedPricingProduct };
      this.productSearchResults = { ...this.productSearchResults };
    }
  }

  onSelectedCardChanged = (product: Product) => {
    this.selectedPricingProduct = product;
  };

  private runPricingSearch = async () => {
    const observer: Observer<PricingResponse> = {
      next: (response: PricingResponse) => {
        this.productSearchResults = response.productSearchResult;
        this.productSearchRequest = response.productSearchRequest;
        this.loanAmount = this.productSearchRequest.loanInformation.baseLoanAmount;
        this.maxLoanAmount = this.loanAmount;
        if (this.productSearchResults?.products?.length > 0) {
          this.selectedPricingProduct = this.productSearchResults.products[0];
        }
      },
      error: (err: any) => { },
      complete: () => { },
    };

    const apiToCall = !this.isEditEnabled ? this.mortgageApplicationService.searchProducts : this.mortgageApplicationService.searchProductsFake;

    this.spinner.show();
    apiToCall(this.mortgageApplication.applicationId)
      .subscribe(observer)
      .add(() => {
        this.spinner.hide();
        this.liabilityPayOffInformationChanged = false;
      });
  };

  private repriceLoan = async () => {
    const observer: Observer<PricingResponse> = {
      next: (response: PricingResponse) => {
        this.productSearchResults = response.productSearchResult;
        this.productSearchRequest = response.productSearchRequest;
        this.loanAmount = this.productSearchRequest.loanInformation.baseLoanAmount;
        if (this.productSearchResults?.products?.length > 0) {
          this.selectedPricingProduct = this.productSearchResults.products[0];
        }
      },
      error: (err: any) => { },
      complete: () => { },
    };

    this.spinner.show();
    this.mortgageApplicationService
      .repriceLoan(this.mortgageApplication.applicationId, this.productSearchRequest)
      .subscribe(observer)
      .add(() => {
        this.spinner.hide();
        this.liabilityPayOffInformationChanged = false;
        this._originalLiabilities = _.cloneDeep(this.mortgage.liabilities);
      });
  };

  private resizeLoanAmountInput = () => {
    const width = this.getTextWidth(this.loanAmount.toString());
    let loanAmountInput: any = document.querySelector("#loan-amount");
    loanAmountInput.style.width = width + 150 + 'px';

    const widthTerm = this.getTextWidth(this.loanTerm.toString());
    let loanTermInput: any = document.querySelector("#loan-term");
    loanTermInput.style.width = width + 150 + 'px';
  }

  private getTextWidth(text: string) {
    // Create a temporary span element to hold the text
    var span = document.createElement('span');
    span.textContent = text;
    span.style.visibility = 'hidden'; // Hide the span element
    span.style.position = 'absolute'; // Position it off-screen
    const loanAmountInput: any = document.querySelector("#loan-amount");
    span.style.font = loanAmountInput.style.font; // Copy the font of the input element

    // Append the span to the document body
    document.body.appendChild(span);

    // Get the width of the span element
    var width = span.getBoundingClientRect().width;

    // Remove the span element from the document body
    document.body.removeChild(span);
    return width;
  }

  private initializePayOffInfo = (mortgage: MortgageApplication) => {
    mortgage.liabilities.forEach((liability) => {
      liability['willPayOff'] = liability.payoffType === 'Full' ||
        liability.partialPayoffAmount === liability.unpaidBalance;
    });
  }
}
