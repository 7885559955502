import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { SvgIconHostDirective } from 'projects/shared/svg-icons/svg-icon-host.directive';

@Component({
  selector: 'wizard-button-group',
  templateUrl: 'wizard-button-group.component.html'
})
export class WizardButtonGroupComponent implements OnInit {

  @ViewChild(SvgIconHostDirective, { static: true }) svgIconHost!: SvgIconHostDirective;

  @Input() backEnabled: boolean = true;
  @Input() nextEnabled: boolean = true;
  @Input() backVisible: boolean = true;
  @Input() nextVisible: boolean = true;

  @Input() nextButtonText: string = "Continue";

  spinnerVisible: boolean = false;

  private _oldNextButtonText: string = "Continue";

  public startSpinner = (): void => {
    this.spinnerVisible = true;
    this._oldNextButtonText = this.nextButtonText;
    this.nextButtonText = "Please wait...";
  }

  public stopSpinner = (): void => {
    this.spinnerVisible = false;
    this.nextButtonText = this._oldNextButtonText;
  }

  @Output() nextClicked = new EventEmitter<any>();
  @Output() backClicked = new EventEmitter<any>();

  onBackClicked = () => {
    this.backClicked.emit();
  }

  onNextClicked = () => {
    this.nextClicked.emit();
  }

  constructor() { }

  ngOnInit() { }
}
