import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'step-type-card',
  templateUrl: 'step-type-card.component.html',
  styleUrls: ['step-type-card.component.scss']
})

export class StepTypeCardComponent implements OnInit {

  @Input()
  stepTypeName: string = "";

  constructor() { }

  ngOnInit() { }

}
