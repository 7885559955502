import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { DataService } from "./data.service";
import { ZipCodeLookupResult } from "../models/zipcode-lookup-result.model";

@Injectable()
export class ZipCodeService {

  constructor(private readonly _dataService: DataService) { }

  lookupZipCode = (zipCode: string, isUspsVerified: boolean): Observable<ZipCodeLookupResult[]> => {
    const url = `api/Zipcode/Lookup/${zipCode}?showOnlyUSPSRecognized=${isUspsVerified}`;
    return this._dataService.get(url);
  }
}