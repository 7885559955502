import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Borrower } from "../../models/borrower-model";
import { Utils } from "../../services/utils";
declare const $: any;

@Component({
  selector: 'borrower-info',
  templateUrl: 'borrower-info.component.html',
  styleUrls: ['./borrower-info.component.scss']
})
export class BorrowerInfoComponent implements OnInit {

  @ViewChild('borrowerInfoForm') borrowerInfoForm: NgForm | undefined;

  @Input()
  borrower!: Borrower;

  @Input()
  doesSetPassword: boolean = false;

  password: string = "";
  confirmedPassword: string = "";

  passwordInputState = {
    id: 'password',
    displayed: false,
  };
  confirmPasswordInputState = {
    id: 'confirmedPassword',
    displayed: false,
  };

  protected passwordIsMinimum8Characters: boolean = false;
  protected passwordContainsDigit: boolean = false;
  protected passwordContainsUppercase: boolean = false;
  protected passwordContainsLowercase: boolean = false;
  protected passwordContainsSpecialCharacter: boolean = false;

  validate = () => {
    this.borrowerInfoForm?.form.markAllAsTouched();
    const isValid = this.borrowerInfoForm?.form.valid;
    let firstInvalidOneId: string = null;
    if (isValid) {
      return true;
    }

    for (var key in this.borrowerInfoForm?.form.controls) {
      if (this.borrowerInfoForm?.form.controls.hasOwnProperty(key)) {
        if (this.borrowerInfoForm?.form.controls[key].status === 'INVALID') {
          firstInvalidOneId = key;
          break;
        }
      }
    }
    if (firstInvalidOneId) {
      this.scrollToElement(firstInvalidOneId);
    }
    return false;
  }

  scrollToElement = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
      }, 250);
    }
  };

  ngOnInit() { }

  onPasswordChanged = () => {
    this.passwordContainsDigit = Utils.containsDigit(this.password);
    this.passwordIsMinimum8Characters = this.password.trim().length >= 8;
    this.passwordContainsUppercase = Utils.containsUppercase(this.password);
    this.passwordContainsLowercase = Utils.containsLowercase(this.password);
    this.passwordContainsSpecialCharacter = Utils.containsSpecialCharacter(
      this.password
    );
  };

  onBorrowerPhoneChanged = () => {
    this.borrower.homePhone = this.borrower.mobilePhone;
  }

  protected onPasswordStateClicked = (state: {id: string, displayed: boolean}) => {
    state.displayed = !state.displayed;
    const passwordElement = document.getElementById(state.id);
    passwordElement.setAttribute('type', state.displayed ? 'text' : 'password');
  }
}
