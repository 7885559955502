

<div class="modal-header">

  <h2>Loan Details</h2>

  <!-- hack to fool ngbModal autofocus -->
  <input type="text" style="display:none" />

  <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" aria-label="Close"
       (click)="activeModal.dismiss()">
      <span class="svg-icon svg-icon-2x">
        <close-x-svg-icon></close-x-svg-icon>
      </span>
  </div>

</div>

<div class="modal-body py-10 px-lg-17">
  <form #loanSummaryDialogForm="ngForm" class="form" novalidate id="loanSummaryDialogForm" name="loanSummaryDialogForm">
    <div class="me-n7 pe-7">
      <div class="row mb-5">
        <div class="col-md-6 fv-row">
          <label class="required form-label mb-2">Purpose</label>
          <select class="form-select form-select-solid" data-control="select2" name="purpose"
                  [(ngModel)]="subjectProperty.purposeOfLoan" #purposeOfLoan="ngModel" required
                  [ngClass]="{'is-invalid' : purposeOfLoan && purposeOfLoan.touched && purposeOfLoan.invalid}">
            <option value="">Select one...</option>
            <option *ngFor="let loanPurposeType of loanPurposeTypes;" [ngValue]="loanPurposeType.value">
              {{loanPurposeType.name}}
            </option>
          </select>
          <div class="invalid-feedback">Please select a value.</div>
        </div>
        <div class="col-md-6 fv-row">
          <label class="required form-label mb-2">Type</label>
          <select class="form-select form-select-solid" data-control="select2" name="type"
                  [(ngModel)]="mortgageTerm.mortgageAppliedFor" #mortgageAppliedFor="ngModel" required
                  [ngClass]="{'is-invalid' : mortgageAppliedFor && mortgageAppliedFor.touched && mortgageAppliedFor.invalid}">
            <option value="">Select one...</option>
            <option *ngFor="let mortgageType of mortgageTypes;" [ngValue]="mortgageType.value">
              {{mortgageType.name}}
            </option>
          </select>
          <div class="invalid-feedback">Please select a value.</div>
        </div>
      </div>

      <div class="row mb-5" *ngIf="subjectProperty.purposeOfLoan !== 'Refinance'">
        <div class="col-md-6 fv-row" *ngIf="!isHidden('mortgage.transactionDetail.purchasePriceAmount')">
          <label class="required form-label mb-2" [ngClass]="{'required': isRequired('mortgage.transactionDetail.purchasePriceAmount')}">
            {{getLabel('mortgage.transactionDetail.purchasePriceAmount', 'Purchase Price')}}
          </label>
          <input class="form-control form-control-solid" mask="separator" thousandSeparator="," inputmode="decimal" prefix="$"
                 [ngClass]="{'is-invalid' : purchasePriceAmount &&  purchasePriceAmount.touched && purchasePriceAmount.invalid}"
                 #purchasePriceAmount="ngModel"
                 [required]="isRequired('mortgage.transactionDetail.purchasePriceAmount')"
                 [(ngModel)]="transactionDetail.purchasePriceAmount" placeholder="" name="purchasePrice" />
          <div class="invalid-feedback">Purchase Price is required.</div>
        </div>
        <div class="col-md-6 fv-row">
          <label class="required form-label mb-2">Loan Amount</label>
          <input class="form-control form-control-solid" mask="separator" thousandSeparator="," inputmode="decimal" prefix="$"
                 [ngClass]="{'is-invalid' : loanAmount &&  loanAmount.touched && loanAmount.invalid}"
                 #loanAmount="ngModel" required [(ngModel)]="mortgageTerm.amount" placeholder="" name="loanAmount" />
          <div class="invalid-feedback">Loan Amount is required.</div>
        </div>
      </div>
      <div class="row mb-5" *ngIf="subjectProperty.purposeOfLoan === 'Refinance'">
        <div class="col-md-6 fv-row">
          <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
            <span class="">Estimated Value</span>
          </label>
          <input type="text" class="form-control form-control-solid" mask="separator" thousandSeparator="," inputmode="decimal" prefix="$"
            [(ngModel)]="subjectProperty.presentValue" placeholder="" name="presentValue"
            [ngClass]="{'is-invalid' : presentValue1 && presentValue1.touched && presentValue1.invalid}"
            #presentValue1="ngModel" required />
          <div class="invalid-feedback">Estimated value is required.</div>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer flex-center">
  <button type="button" class="btn btn-white me-3" (click)="activeModal.dismiss()">Cancel</button>
  <button type="button" class="btn btn-primary" (click)="onSaveClicked()">Ok</button>
</div>




