<ng-template #submitApplication>
  <div id="submitApplication">
    <div class="alert alert-warning d-flex align-items-center p-5 mb-10" *ngIf="isSubmitted && !isSuccess">
      <span class="svg-icon svg-icon-2hx svg-icon-warning me-4">
        <duotone-warning-circle-svg-icon></duotone-warning-circle-svg-icon>
      </span>
      <div class="d-flex flex-column">
        <h5 class="mb-1">{{errorTitle}}</h5>
        <span>{{errorMessage}}</span>
      </div>
    </div>
  </div>
</ng-template>

<wizard-step-template [stepMainTemplate]="submitApplication" [step]="step" [stepComponent]="this">
</wizard-step-template>
