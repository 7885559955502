<ng-template #reviewApplication>
  <div id="submitApplication">
    <div class="alert alert-warning d-flex align-items-center p-5 mb-10" *ngIf="isSubmitted && isEditEnabled">
      <span class="svg-icon svg-icon-2hx svg-icon-warning me-4">
        <duotone-warning-circle-svg-icon></duotone-warning-circle-svg-icon>
      </span>
      <div class="d-flex flex-column">
        <h5 class="mb-1">Will not submit the application!</h5>
        <span>You're an admin editing the flow. You will not be able to submit an application.</span>
      </div>
    </div>
  </div>

  <div class="d-flex mb-5" *ngIf="inEditMode">
    <div class="col-sm-3 col-form-label fw-bold fs-6 editor-label">Allow Editing of Subject Property:</div>
    <div class="col-sm-2 form-check form-switch form-check-custom form-check-solid">
      <input class="form-check-input h-20px w-30px" type="checkbox" id="allowEditingOfSubjectProperty" name="allowEditingOfSubjectProperty"
      [ngModelOptions]="{standalone: true}" [(ngModel)]="step.allowEditingOfSubjectProperty" />
      <label class="form-check-label" for="allowEditingOfSubjectProperty">
        {{step.allowEditingOfSubjectProperty ? "Yes" : "No"}}
      </label>
    </div>
  </div>

  <div class="d-flex mb-10" *ngIf="inEditMode">
    <div class="col-sm-3 col-form-label fw-bold fs-6 editor-label">Show Subject Property Details:</div>
    <div class="col-sm-2 form-check form-switch form-check-custom form-check-solid">
      <input class="form-check-input h-20px w-30px" type="checkbox" id="showSubjectPropertyDetails" name="showSubjectPropertyDetails"
      [ngModelOptions]="{standalone: true}" [(ngModel)]="step.showSubjectPropertyDetails" />
      <label class="form-check-label" for="showSubjectPropertyDetails">
        {{step.showSubjectPropertyDetails ? "Yes" : "No"}}
      </label>
    </div>
  </div>

  <div id="reviewApplication">
    <borrower-review-application [mortgage]="mortgageApplication" [borrower]="mainBorrower" (borrowerChanged)="onBorrowerChanged($event)" [subjectProperty]="subjectProperty" [purchasePrice]="purchasePrice"></borrower-review-application>
    <ng-container *ngIf="!inCoApplyFlow">
      <div *ngFor="let borrower of coBorrowers">
        <div *ngIf="borrowerSetting(borrower)">
          <borrower-review-application [borrower]="borrower" (borrowerChanged)="onBorrowerChanged($event)" [subjectProperty]="subjectProperty" [purchasePrice]="purchasePrice"></borrower-review-application>
        </div>
      </div>
    </ng-container>
    <div class="rounded border p-5 mb-6">
      <div class="w-100 d-flex align-items-center justify-content-end" *ngIf="!inCoApplyFlow">
        <a href="javascript:void(0);" class="text-primary fw-bold fs-7 mt-n2"
          (click)="onLoanSummaryEditClicked()">
           Edit
        </a>
      </div>
      <loan-summary
        [inEditMode]="inEditMode"
        [fieldsToConfig]="step.fieldConfig"
        [step]="this.step"
        [subjectProperty]="subjectProperty"
        [mortgageTerm]="mortgageTerm"
        [transactionDetail]="transactionDetail"
        (loanDetailsClicked)="onLoanDetailsClicked()"
        class="flex-fill">
      </loan-summary>
    </div>

    <div class="rounded border p-5 mb-6" *ngIf="step.showSubjectPropertyDetails">
      <div class="w-100 d-flex align-items-center justify-content-end" *ngIf="!inCoApplyFlow && step.allowEditingOfSubjectProperty">
        <a href="javascript:void(0);" class="text-primary fw-bold fs-7 mt-n2"
          (click)="onSubjectPropertySummaryEditClicked()">
           Edit
        </a>
      </div>
      <subject-property-summary
        (subjectPropertyDetailsClicked)="onSubjectPropertyDetailsClicked();"
        [subjectProperty]="subjectProperty"
        class="flex-fill">
      </subject-property-summary>
    </div>
  </div>

</ng-template>

<wizard-step-template [stepMainTemplate]="reviewApplication" [step]="step" [stepComponent]="this">
</wizard-step-template>
