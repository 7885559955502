import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { GeneratePrequalLetterRequestModel } from 'projects/borrower-app/src/app/models/borrower-portal/generate-prequal-letter-request.model';

@Component({
  selector: 'prequal-letter-wizard-down-payment',
  templateUrl: './prequal-letter-wizard-down-payment.component.html'
})
export class PrequalLetterWizardDownPaymentComponent implements OnInit {

  @ViewChild('downPaymentForm') downPaymentForm: NgForm | undefined;

  @Input() request: GeneratePrequalLetterRequestModel;
  @Input() minDownPaymentPercent: number;
  @Input() maxDownPaymentPercent: number;

  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() next: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() back: EventEmitter<boolean> = new EventEmitter<boolean>();

  minDownPaymentValue: number = 0;
  maxDownPaymentValue: number;

  constructor() { }

  ngOnInit(): void {
    if(this.minDownPaymentPercent && this.minDownPaymentPercent > 1){
      this.minDownPaymentPercent = this.minDownPaymentPercent / 100;
    }
    if(this.maxDownPaymentPercent && this.maxDownPaymentPercent > 1){
      this.maxDownPaymentPercent = this.maxDownPaymentPercent / 100;
    }

    this.minDownPaymentValue = this.request.downPayment = (this.request.purchasePrice || 0) * (this.minDownPaymentPercent || 0.2);
    this.maxDownPaymentValue = (this.request.purchasePrice || 0) * (this.maxDownPaymentPercent || 1);
  }

  nextClicked = () => {
    this.downPaymentForm.form.markAllAsTouched();
    if (this.downPaymentForm.form.valid) {
      this.next.emit();
    }
  }

  closeClicked = () => {
    this.close.emit();
  }

  backClicked = () => {
    this.downPaymentForm.form.markAllAsTouched();
    if (this.downPaymentForm.form.valid) {
      this.back.emit();
    }
  }

}

