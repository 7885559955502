import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'duotone-building2-svg-icon',
  templateUrl: 'duotone-building2-svg-icon.component.html'
})

export class DuotoneBuilding2SvgIconComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
