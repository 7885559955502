import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EnumerationItem } from '../models/enumeration-item.model';

@Component({
  selector: 'multi-checkbox-picker',
  templateUrl: 'multi-checkbox-picker.component.html'
})

export class MultiCheckboxPickerComponent implements OnInit {
  constructor() { }

  private _selectedItems: EnumerationItem[] = [];

  @Input() possibleChoices: EnumerationItem[] = [];

  @Output() itemSelected = new EventEmitter<EnumerationItem>();
  @Output() itemDeselected = new EventEmitter<EnumerationItem>();

  @Input() get selectedItems(): EnumerationItem[] {
    return this._selectedItems;
  }

  set selectedItems(items: EnumerationItem[]) {
    const x = 10;
    setTimeout(()=> {
      this._selectedItems = items;
    })
  }

  @Input() disabledChoices: EnumerationItem[] = [];

  colWidth: number = 12;

  public selectionChanged = (target: any, choice: EnumerationItem): void => {
    setTimeout(() => {
      if (target.checked) {
        if (!this._selectedItems.find(i => i.value === choice.value)) {
          this._selectedItems.push(choice);
        }
      } else {
        const item = this._selectedItems.find(i => i.value == choice.value);
        if (item) {
          var index = this._selectedItems.indexOf(item);
          if (index >= 0) {
            this._selectedItems.splice(index, 1);
          }
        }
      }
    });
  }

  public isSelected = (choice: EnumerationItem): boolean => {
    var item = this._selectedItems.find(i => i.value === choice.value);
    return item !== undefined;
  }

  public isDisabled = (choice: EnumerationItem): boolean => {
    var item = this.disabledChoices.find(i => i.value === choice.value);
    return item !== undefined;
  }

  ngOnInit() {
    if (this.possibleChoices.length > 0) {
      this.colWidth = Math.floor(12 / this.possibleChoices.length);
      //this.lastColWidth = 12 % this.step?.question?.options.length;
    }
  }
}
