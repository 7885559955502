<header></header>
<div class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
  <div class="card-body p-10 p-lg-15 bg-white border rounded mb-8">
    <div class="mb-14">
      <div class="mb-15">
        <div [innerHtml]="privacyStatement | safeHtml"></div>
      </div>
    </div>
  </div>
  <div class="card-body p-10 p-lg-15 bg-white border rounded">
    <div class="mb-14">
      <div class="mb-15">
        <div [innerHtml]="termsOfUse | safeHtml"></div>
      </div>
    </div>
  </div>
</div>
