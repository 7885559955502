import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { WizardStepComponentBase } from '../wizard-step-base.component';
import { Liability, RealEstateOwned } from '../../../models/mortgage.model';
import { LinkLiabilitiesToReoStep } from '../../../models/wizard/link-liabilities-to-reo-step.model';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'link-liabilities-to-reo-step',
  templateUrl: 'link-liabilities-to-reo-step.component.html'
})
export class LinkLiabilitiesToReoStepComponent extends WizardStepComponentBase<LinkLiabilitiesToReoStep> implements OnInit {

  @ViewChild('linkLiabilityToReoForm')
  linkLiabilityToReoForm: NgForm;

  constructor(private readonly _injector: Injector) {
    super(_injector);
    this.saveMortgageApplicationBeforeNextStep = true;
  }

  liabilities: Liability[] = [];
  reos: RealEstateOwned[] = [];

  ngOnInit() {
    this.reos = this.mortgageApplication.realEstateOwned;
    if (this.inCoApplyFlow) {
      this.reos = this.reos.filter(r => r.owningBorrowerIds.includes(this.currentBorrower.borrowerId!));
    }
    this.liabilities = this.mortgageApplication.liabilities
      .filter(r => r.typeOfLiability == 'MortgageLoan' || r.typeOfLiability == 'HELOC')
      .sort((a, b) => (a.liabilityId && b.liabilityId && a.liabilityId < b.liabilityId) ? 1 : -1);
    if (this.inCoApplyFlow) {
      this.liabilities = this.liabilities.filter(l => l.owningBorrowerIds.includes(this.currentBorrower.borrowerId!));
    }
  }

  override onNextClicked(): void {
    if (this.linkLiabilityToReoForm) {
      this.linkLiabilityToReoForm.form.markAllAsTouched();
      if (this.linkLiabilityToReoForm.form.invalid) {
        return;
      }
    }
    super.onNextClicked();
  }
}
