<ckeditor
  [editor]="messageEditor"
  [config]="messageEditorConfig"
  #messageEditorComponent
  id="messageBody"
  name="messageBody"
  (ready)="onEditorReady($event)"
  data="{{messageToSend}}"
  [(ngModel)]="messageToSend"
  (ngModelChange)="onMessageToSendChanged()">
</ckeditor>
