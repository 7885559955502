<div class="container">
  <div class="card mt-10">
    <div class="card-header px-4 d-flex align-items-center justify-content-between border-bottom-2">
      <h4 class="mb-0 mt-4">
        Refer A Friend
        <p class="fs-8 fw-bold mt-2" *ngIf="portalContent?.referralContent"
          [innerHtml]="portalContent?.referralContent"></p>
      </h4>
    </div>
    <form #referAFriendForm="ngForm" novalidate class="form-horizontal">
      <div class="card-body p-0">
        <div class="row m-2 p-2">
          <div class="form-group mb-5 col-md-6">
            <label class="form-label" for="referralType">I would like to refer a...</label>
            <select class="form-select" [(ngModel)]="req.referralType" name="referralType" required
              #referralTypeInput="ngModel" [ngClass]="{
            'is-invalid':
              referralTypeInput &&
              referralTypeInput.touched &&
              referralTypeInput.invalid
          }">
              <option *ngFor="let r of referralTypes" [ngValue]="r">{{ r }}</option>
            </select>
            <div class="invalid-feedback">This field is required</div>
          </div>
          <div class="form-group mb-5 col-md-6">
            <label class="form-label" for="referToUserId">I am referring this {{ req.referralType }} to...</label>
            <select class="form-select" [(ngModel)]="req.referToUserId" name="referToUserId" required
              #referToUserIdInput="ngModel" [ngClass]="{
            'is-invalid':
              referToUserIdInput &&
              referToUserIdInput.touched &&
              referToUserIdInput.invalid
          }">
              <option *ngFor="let agent of agents" [ngValue]="agent.userId">
                {{ agent.firstName }} {{ agent.lastName }}
              </option>
            </select>
            <div class="invalid-feedback">This field is required</div>
          </div>
          <div class="form-group mb-5 col-md-6">
            <label class="form-label" for="firstName">First Name</label>
            <input type="text" class="form-control" [(ngModel)]="req.firstName" name="firstName" required
              #firstNameInput="ngModel" [ngClass]="{
            'is-invalid':
              firstNameInput && firstNameInput.touched && firstNameInput.invalid
          }" />
            <div class="invalid-feedback">This field is required</div>
          </div>
          <div class="form-group mb-5 col-md-6">
            <label class="form-label" for="lastName">Last Name</label>
            <input type="text" class="form-control" [(ngModel)]="req.lastName" name="lastName" />
          </div>
          <div class="form-group mb-5 col-md-6">
            <label class="form-label" for="phoneNumber">Phone Number</label>
            <input type="tel" class="form-control" [(ngModel)]="req.phone" name="phoneNumber" />
          </div>
          <div class="form-group mb-5 col-md-6">
            <label class="form-label" for="emailAddress">Email Address</label>
            <input type="email" class="form-control" [(ngModel)]="req.email" name="emailAddress" />
          </div>
          <div class="form-group mb-5 col-md-12">
            <label class="form-label" for="note">Note</label>
            <textarea class="form-control" rows="5" [(ngModel)]="req.note" name="note"></textarea>
          </div>
          <div class="d-flex justify-content-end">
            <button class="btn btn-sm bp-primary-bg-color" type="button" (click)="submit()" [disabled]="isSaving">
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
    <div class="d-flex justify-content-end m-2 px-6 mb-6" *ngIf="portalContent?.showReferralTerms">
      <a class="nav-link bp-link dashed-link fw-bold px-0" (click)="termsOpened = !termsOpened">Refer-a-Friend Terms &
        Conditions</a>
    </div>
    <div class="alert alert-primary d-flex align-items-center mx-6" *ngIf="termsOpened" >
      <div class="d-flex flex-column">
        <span class="mt-2" [innerHtml]="portalContent?.referralTermsContent"> The alert component can be used to
          highlight certain parts of your page for higher content visibility.</span>
      </div>
    </div>
  </div>
</div>
