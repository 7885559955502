<div class="modal-header">
  <h5 class="modal-title d-flex align-items-center">Attached Documents</h5>
  <button
    type="button"
    class="btn-close"
    (click)="activeModal.dismiss()"
    aria-label="Close"
  ></button>
</div>

<div class="modal-body">
  <table class="table">
    <thead>
      <tr>
        <th class="min-w-200px">File Name</th>
        <th class="min-w-200px">Note</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let document of task.linkedDocuments">
        <td class="min-w-200px">
          <span class="text-gray-800 fw-bold">{{ document.fileName }}</span>
        </td>
        <td class="min-w-200px">
          <span class="text-gray-800 fw-bold">{{
            document.borrowerNote === null
              ? "No note from the borrower."
              : document.borrowerNote
          }}</span>
        </td>
        <td><span class="text-gray-800 fw-bold"></span></td>
        <td>
          <a
            *ngIf="task.templateDocumentUrl"
            title="View"
            class="align-text-bottom"
            (click)="goToFileLink(document.documentUrl)"
          >
            <i class="far fa-eye"></i>
          </a>

          <a
            title="Download"
            class="mx-2 align-text-bottom"
            (click)="downloadLink(document.documentUrl, document.fileName)"
          >
            <i class="fas fa-download"></i>
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</div>
