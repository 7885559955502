<div class="container-fluid g-0">
  <div
    class="row d-flex flex-row flex-nowrap overflow-auto scenarios-container"
  >
    <div
      class="text-center me-1 col-sm-12 col-md-6 col-lg-4"
      *ngFor="let scenario of scenarios"
    >
      <scenario-card
        [scenario]="scenario"
        [index]="scenario['index']"
        [applicationId]="applicationId"
      >
      </scenario-card>
    </div>
  </div>
  <div *ngIf="scenarios.length == 0">
    <h5 class="text-center text-muted">
      <span> There are no saved scenarios. </span>
    </h5>
  </div>
</div>
