import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'duotone-write-svg-icon',
  templateUrl: 'duotone-write-svg-icon.component.html'
})

export class DuotoneWriteSvgIconComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
