import { Component, Input, OnInit } from '@angular/core';
import { Alert } from '../models/alert.model';


@Component({
  selector: 'alert',
  templateUrl: 'alert.component.html'
})

export class AlertComponent implements OnInit {

  @Input()
  alert: Alert | undefined;

  constructor() { }

  ngOnInit() { }
}
