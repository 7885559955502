import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as _ from 'lodash';
import { ApplicationContext } from '../models/application-context.model';
import { GlobalConfig } from '../models/global-config.model';
import { Profile } from '../models/profile.model';
import { UserData } from '../models/user';
import { UserPermissions } from '../models/user/user-permissions.model';
import { GlobalConfigService } from './global-config.service';
import { UserService } from './user.service';
import { CountryEnumerationItem, EnumsService } from './enums.service';

@Injectable({ providedIn: 'root' })
export class ApplicationContextService {

  private _applicationContext: ApplicationContext;

  private _contextSubject: Subject<ApplicationContext> =
    new Subject<ApplicationContext>();

  get context(): Observable<ApplicationContext> {
    if (this._applicationContext) {
      return of(this._applicationContext);
    }
    return this.initializeApplicationContext().pipe(map(context => {
      this._applicationContext = context;
      return context;
    }));
  }

  get changes(): Subject<ApplicationContext> {
    return this._contextSubject;
  }

  constructor(
    private readonly _userService: UserService,
    private readonly _configService: GlobalConfigService,
    private readonly _enumService: EnumsService
  ) { }

  reset = () => {
    this._applicationContext = null;
    //this._contextSubject.next(this._applicationContext);
  };

  private initializeApplicationContext = (): Observable<ApplicationContext> => {
    return this._userService.getLoggedInUserData().pipe(
      catchError((error) => of(error)),
      switchMap((user: UserData) => this._userService.getProfile(user.userCompanyGuid).pipe(
        catchError((error) => of(error)),
        switchMap((profile: Profile) => this._userService.getUserPermissions().pipe(
          catchError((error) => of(error)),
          switchMap((permissions: UserPermissions) => this._configService.getConfig(permissions.companyId).pipe(
            catchError((error) => of(error)),
            switchMap((config: any) => this._enumService.getAllCountries().pipe(
              catchError((error) => of(error)),
              switchMap((countries) => {
                return of(config).pipe(map(config => {
                  let context = this.populateContextFieldsFromApiResults(user, profile, config, permissions, countries);
                  return context;
                }))
              }
              )
            )
            )
          )
          )
        ))
      )
      ))
  };

  private populateContextFieldsFromApiResults = (
    user: UserData, profile: Profile, config: GlobalConfig, permissions: UserPermissions, countries: CountryEnumerationItem[]): ApplicationContext => {

    let context = new ApplicationContext();
    context.currentlyLoggedInUser = user;
    context.currentlyLoggedInUserProfile = profile;

    context.globalConfig = config;
    context.globalConfig.loanStatus = _.orderBy(context.globalConfig.loanStatus, ['order', 'asc']);
    context.globalConfig.leadStatus = _.orderBy(context.globalConfig.leadStatus, ['order', 'asc']);
    context.globalConfig.subStatus = _.orderBy(context.globalConfig.subStatus, ['order', 'asc']);
    context.globalConfig.keyDates = _.orderBy(context.globalConfig.keyDates, ['displayName', 'asc']);
    context.globalConfig.tasks = _.orderBy(context.globalConfig.tasks, ['taskName', 'asc']);
    context.globalConfig.roles = _.orderBy(context.globalConfig.roles, ['order', 'asc']);
    context.globalConfig.users = _.orderBy(context.globalConfig.users, [['lastName', 'firstName'], ['asc', 'asc']]);
    context.userPermissions = permissions;
    context.globalConfig.countries = countries;
    return context;
  }

  updateContext = (context: ApplicationContext) => {
    this._contextSubject.next(context);
  }
}
