import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { PortalContent } from '../../models/borrower-portal/portal-content-model-model';
import { ReferAFriendRequest, ReferralType } from '../../models/borrower-portal/refer-a-friend-request.model';
import { ReferralAgent } from '../../models/borrower-portal/referral-agent-model';
import { BasePortalService } from '../../services/base-portal.service';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'portal-refer-a-friend',
  templateUrl: './refer-a-friend.component.html',
  styleUrls: ['./refer-a-friend.component.scss']
})

export class PortalReferAFriendComponent implements OnInit {

  @ViewChild('referAFriendForm') referAFriendForm: NgForm | undefined;

  req: ReferAFriendRequest = new ReferAFriendRequest();

  agents: ReferralAgent[] = [];
  portalContent: PortalContent;

  isSaving: boolean = false;
  termsOpened: boolean = false;

  referralTypes: ReferralType[] = [
    ReferralType.Borrower,
    ReferralType.Agent
  ];

  constructor(
    private readonly _portalService: BasePortalService,
    private readonly _spinnerService: NgxSpinnerService,
    private readonly _notifyService: NotificationService
  ) { }

  ngOnInit() {
    this.req.referralType = ReferralType.Borrower;
    this.loadPortalContent();
    this.loadAgents();
  }

  loadAgents = () => {
    this._spinnerService.show();
    this._portalService.getReferralAgents()
      .subscribe(agents => {
        this.agents = agents;
        if(agents.length > 0){
          this.req.referToUserId = agents[0].userId;
        }
      })
      .add(() => {
        this._spinnerService.hide();
      })
  }

  loadPortalContent = () => {
    this._spinnerService.show();
    this._portalService.getPortalContent()
    .subscribe(content => {
      this.portalContent = content;
    })
    .add(() => {
      this._spinnerService.hide();
    })
  }

  submit = () => {
    this.referAFriendForm.form.markAllAsTouched();

    if (this.referAFriendForm.form.valid) {

      this._spinnerService.show();
      this.isSaving = true;
      this._portalService.postReferAFriend(this.req)
        .subscribe(() => {
          this._notifyService.showSuccess("Your referral has been submitted successfully.", 'Success!');
        })
        .add(() => {
          this._spinnerService.hide();
          this.isSaving = false;
        })
    }

  }
}
