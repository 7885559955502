
export enum StepType {
  SingleChoiceQuestionStep = 1,
  MyInfoStep = 2,
  AddressStep = 3,
  CreateAccount = 4,
  NumberOfBorrowersStep = 5,
  CreateCoBorrowersStep = 6,
  CurrentAddressStep = 7,
  OwnershipStatusStep = 8,
  AddressHistoryStep = 9,
  DigitalIncomeAndEmploymentStep = 10,
  ManualIncomeAndEmploymentStep = 11,
  DigitalAssetsStep = 12,
  ManualAssetsStep = 13,
  ReoStep = 14,
  VerifyCreditStep = 15,
  CreditScoresStep = 16,
  ReviewCreditStep = 17,
  CreditInquiryStep = 18,
  LoanTypeStep = 19,
  DeclarationsStep = 20,
  DemographicsStep = 21,
  EConsentStep = 22,
  ReviewApplicationStep = 23,
  SubmitApplicationStep = 24,
  SelectOriginatorStep = 25,
  MultiBorrowersAddressHistoryStep = 26,
  LoanCharacteristicsStep = 27,
  BorrowerCharacteristicsStep = 28,
  MilitaryServiceStep = 29,
  HoiStep = 34,
  PurchaseCreditsStep = 35,
  CheckListsStep = 36,
  GatherLeadSourceStep = 37,
  PricingStep = 38,
  PullExistingApplicationStep = 39,
  LinkLiabilitiesToReoStep = 40,
  HomeownershipEducationStep = 41,
}


