import { Component, Input, OnInit } from '@angular/core';
import { PaymentInformation } from 'projects/borrower-app/src/app/models/payment-information.model';
import { EnumsService } from 'projects/borrower-app/src/app/services/enums.service';
import { formViewProvider } from 'projects/borrower-app/src/app/services/form-view.provider';
import { EnumerationItem } from 'projects/shared/models/enumeration-item.model';

@Component({
  selector: 'payment-order-details',
  templateUrl: './payment-order-details.component.html',
  styleUrls: ['./payment-order-details.component.scss'],
  viewProviders: [formViewProvider],
})
export class PaymentOrderDetailsComponent implements OnInit {

  @Input() paymentInfo: PaymentInformation;
  expirationDate: Date = new Date();

  states: EnumerationItem[] = [];

  constructor(private readonly _enumsService: EnumsService) { }

  ngOnInit(): void {
    this.states = this._enumsService.stateNames;
  }

  expirationDateChanged = (date: string) => {
    this.expirationDate = new Date(date);
    this.paymentInfo.creditCardExpirationYear = this.expirationDate.getFullYear();
    this.paymentInfo.creditCardExpirationMonth = this.expirationDate.getMonth() + 1;
  }

}
