import { Component, OnDestroy, OnInit } from '@angular/core';
import { CreditService } from '../../services/credit.service';
import { Observer, Subscription, firstValueFrom, forkJoin } from 'rxjs';
import { formatDate } from '@angular/common';
import { MortgageApplicationService } from '../../services/mortgage-application.service';
import { BorrowerPortalService } from '../services/borrower-portal.service';
import { FeatureFlags } from '../../models/feature-flags.model';
import { MortgageApplication } from '../../models/mortgage-app.model';
import { NavigationService } from '../../services/navigation.service';
import { ApplicationForUser } from '../../models/borrower-portal/application-for-user-model';
import { ApplicationContextService } from '../../services/application-context.service';
import { UserPermissions } from '../../models/user';

@Component({
  selector: 'e-consent',
  templateUrl: 'e-consent.component.html',
  styleUrls: ['./e-consent.component.scss']
})
export class EConsentComponent implements OnInit, OnDestroy {

  consentToDoBusiness: boolean = true;
  consentToRunCredit: boolean = true;

  isLoading: boolean = false;

  showEConsentVerbiage: boolean;
  showCreditAuthVerbiage: boolean;

  creditCheckConsentVerbiage: string | undefined = "";
  eBusinessConsentVerbiage: string | undefined = "";

  private _mortgages: MortgageApplication[] = [];
  private _applications: ApplicationForUser[] = [];

  private _eConsentGenerationEnabled: boolean = false;
  private _creditAuthGenerationEnabled: boolean;

  private _userPermissions: UserPermissions;

  private _contextSubscription: Subscription

  constructor(
    private readonly _borrowerPortalService: BorrowerPortalService,
    private readonly _creditService: CreditService,
    private readonly _mortgageApplicationService: MortgageApplicationService,
    private readonly _navigationService: NavigationService,
    private readonly _applicationContextService: ApplicationContextService) {
  }

  ngOnInit() {
    this._contextSubscription = this._applicationContextService.context.subscribe(context => {
      this._userPermissions = context.userPermissions;
      this.initialize();
    });
  }

  ngOnDestroy(): void {
    this._contextSubscription?.unsubscribe();
  }

  onOkClicked() {

    if (!this._applications || !this._applications.length) {
      this._navigationService.navigateToPath(`/borrower-portal`);
      return;
    }

    const apiCalls: any = {};

    const today = formatDate(new Date(), 'MM/dd/yyyy', 'en-US');

    this._applications.forEach(app => {
      if (app.myDetails) {
        const contactId = app.myDetails.borrowerId;

        if (this._creditAuthGenerationEnabled) {
          const relatedMortgage = this._mortgages.find(m => m.applicationId === app.applicationId);
          if (relatedMortgage) {
            const mortgageBorrower = relatedMortgage.borrowers.find(b => b.contactId === contactId);
            if (mortgageBorrower) {
              mortgageBorrower.dateAuthorizedCreditCheck = today;
              mortgageBorrower.authorizationMethod = "Internet";
              mortgageBorrower.authorizedCreditCheck = true;
            }
            // Here we need a call to save this mortgage
            apiCalls['saveMortgage' + app.applicationId] = this._mortgageApplicationService.saveMortgage(relatedMortgage);
          }
          // Here we need a call to create the credit consent report
          apiCalls['createCreditConsentReport' + app.applicationId] = this._creditService.createConsentReport(app.applicationId, this.creditCheckConsentVerbiage);
        }

        if (this._eConsentGenerationEnabled && !app.myDetails.acceptedEConsentDate) {
          apiCalls['generateEConsentDocument' + app.applicationId] = this._mortgageApplicationService.generateEConsentDocument(app.applicationId,
            { eConsentText: this.eBusinessConsentVerbiage, borrowerId: contactId });
        }
      }
    });

    const observer: Observer<any> = {
      next: (value: any) => {
        this.isLoading = false;
        this._navigationService.navigateToPath(`/borrower-portal`);
      },
      error: (err: any) => {
        this.isLoading = false;
      },
      complete: () => {
      }
    }

    if (Object.keys(apiCalls).length) {
      this.isLoading = true;
      forkJoin(apiCalls).subscribe(observer);
    }
  }

  private initialize = async () => {
    try {
      this.isLoading = true;
      const applications = await firstValueFrom(this._borrowerPortalService.getApplicationsForUser());
      this._applications = applications.filter(app => app.companyId === this._userPermissions.companyId);
      if (this._applications && this._applications.length) {
        const configuration = await firstValueFrom(this._mortgageApplicationService.getConfig(this._applications[0].applicationId));
        this.configureUIBasedOnFeatureFlags(configuration);

        this._applications.forEach(async app => {
          const mortgage = await firstValueFrom(this._mortgageApplicationService.getMortgage(app.applicationId));
          this._mortgages.push(mortgage);
        });
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
    }
  }

  private configureUIBasedOnFeatureFlags = (config: FeatureFlags) => {
    this.creditCheckConsentVerbiage = config?.creditAuthText;
    this.eBusinessConsentVerbiage = config?.eConsentText;
    this._eConsentGenerationEnabled = config?.eConsentGenerationEnabled;
    this.showEConsentVerbiage = config?.showEConsentVerbiage;
    this.showCreditAuthVerbiage = config?.showCreditAuthVerbiage;
    this._creditAuthGenerationEnabled = config?.creditAuthGenerationEnabled;
  }
}


