import { Component, Injector, OnInit } from '@angular/core';
import { Borrower } from '../../../models/borrower-model';
import { WizardStepComponentBase } from '../wizard-step-base.component';
import { Observer } from 'rxjs';
import { BorrowerVerificationUserEnrollmentResponse } from '../../../models/borrower-verification-user-enrollment.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { AssetService } from '../../../services/asset-service';
import { DigitalAssetsStep } from '../../../models/wizard/digital-assets-step.model';

@Component({
  selector: 'digital-assets-step',
  templateUrl: 'digital-assets-step.component.html',
  styleUrls: ['digital-assets-step.component.scss'],
})
export class DigitalAssetsStepComponent extends WizardStepComponentBase<DigitalAssetsStep> implements OnInit {

  protected voaEnabled: boolean = true;
  protected borrower!: Borrower;

  protected provider: string = "FormFree";
  protected thirdPartyEnrollmentWidget: string = "";

  protected loanDocTaskId: number | null | undefined;

  constructor(private readonly _injector: Injector,
    private readonly _spinner: NgxSpinnerService,
    private readonly _assetService: AssetService
  ) {
    super(_injector);
    this.borrower = this.mortgageApplication.borrowers[0];
    if (!this.isEditEnabled) {
      this.voaEnabled = this.wizardFlowService.context.config.voaEnabled;
    }
  }

  ngOnInit() {
    this._spinner.show();
    const observer: Observer<BorrowerVerificationUserEnrollmentResponse> = {
      next: (response: any) => {
        this.thirdPartyEnrollmentWidget = response.htmlWidgetSrc;
        this.loanDocTaskId = response.loanDocTaskId;
      },
      error: (error: any) => {
        console.log(error);
      },
      complete: () => {
        console.log('complete');
      }
    }

    this._spinner.show();
    this._assetService.initializeBorrowerAssetVerification(this.borrower.contactId, this.mortgageApplication.applicationId, 
      this.step.vendorToUse).subscribe(observer)
      .add(() => {
        this._spinner.hide();
      });
  }

  onSpecifyAssetsClicked = () => {
    this.wizardFlowService.navigateToManualAssetsStep();
  }

  onNextClicked(): void {
    if (!this.voaEnabled) {
      this.wizardFlowService.navigateToManualAssetsStep();
      return;
    }
    super.onNextClicked();
  }
}
