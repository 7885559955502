import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { GeneratePrequalLetterRequestModel } from 'projects/borrower-app/src/app/models/borrower-portal/generate-prequal-letter-request.model';
import { PrequalDetailsModel } from 'projects/borrower-app/src/app/models/borrower-portal/prequal-details.model';

@Component({
  selector: 'wizard-additional-options',
  templateUrl: './wizard-additional-options.component.html',
  styleUrls: ['./wizard-additional-options.component.scss']
})
export class WizardAdditionalOptionsComponent implements OnInit {

  @ViewChild('additionalOptionsForm') additionalOptionsForm: NgForm | undefined;

  @Input() request: GeneratePrequalLetterRequestModel;
  @Input() details: PrequalDetailsModel;

  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() next: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() back: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  nextClicked = () => {
    this.additionalOptionsForm.form.markAllAsTouched();
    if (this.additionalOptionsForm.form.valid) {
      this.next.emit();
    }
  }

  closeClicked = () => {
    this.close.emit();
  }

  backClicked = () => {
    this.additionalOptionsForm.form.markAllAsTouched();
    if (this.additionalOptionsForm.form.valid) {
      this.back.emit();
    }
  }

}

