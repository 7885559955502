<div style="min-height: 500px;">
  <div class="w-100">
    <div class="pb-10 pb-lg-15">
      <h2 class="fw-bolder d-flex align-items-center text-dark">Welcome to our online application!
      <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Billing is issued based on your selected account type"></i></h2>
      <div class="text-gray-400 fw-bold fs-6">We look forward to working with you on your mortgage goals. <br />
        Let's get started...</div>
    </div>
    <div class="fv-row">
        <div class="row">

        </div>
    </div>
  </div>
</div>
