<ng-template #template>

  <div class="wrapper1 d-flex flex-column flex-row-fluid">
    <!--begin::Header-->
    <div class="header align-items-stretch">
      <!--begin::Container-->
      <div class="container-fluid d-flex align-items-stretch justify-content-between">
         <!--begin::Logo-->
      <div class="d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-lg-15">
        <a>
          <img alt="Logo" [src]="logoUrl" class="h-35px" />
        </a>
      </div>
      <!--end::Logo-->
        <!--begin::Wrapper-->
        <div class="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
          <!--begin::Navbar-->
          <div class="d-flex align-items-stretch">
            <!--begin::Menu wrapper-->
            <div class="header-menu align-items-stretch" data-kt-drawer="true" data-kt-drawer-name="header-menu"
              data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true"
              data-kt-drawer-width="{default:'200px', '300px': '250px'}" data-kt-drawer-direction="end"
              data-kt-drawer-toggle="#kt_header_menu_mobile_toggle" data-kt-swapper="true"
              data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}">
              <!--begin::Menu-->
              <div
                class="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch"
                id="#kt_header_menu" data-kt-menu="true">
                <div class="menu-item me-lg-1">
                  <a class="menu-link py-3" (click)="onGotoFlowClicked()">
                    <span class="menu-title">Goto Flow</span>
                  </a>
                </div>
                <div class="menu-item me-lg-1">
                  <a class="menu-link py-3" (click)="onFlowsClicked()">
                    <span class="menu-title">Flows</span>
                  </a>
                </div>

              </div>
              <!--end::Menu-->
            </div>
            <!--end::Menu wrapper-->
          </div>
          <!--end::Navbar-->
          <!--begin::Topbar-->
          <div class="d-flex align-items-stretch flex-shrink-0">
            <!--begin::Toolbar wrapper-->
            <div class="d-flex align-items-stretch flex-shrink-0">
            </div>
            <!--end::Toolbar wrapper-->
          </div>
          <!--end::Topbar-->
        </div>
        <!--end::Wrapper-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Header-->
    <!--begin::Content-->
    <div class="content d-flex flex-column flex-column-fluid">
      <!--begin::Toolbar-->
      <div class="toolbar">
        <!--begin::Container-->
        <div class="container-fluid d-flex flex-stack">
          <!--begin::Page title-->
          <div data-kt-swapper="true" data-kt-swapper-mode="prepend"
            data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
            class="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
            <!--begin::Title-->
            <h1 class="d-flex align-items-center text-dark fw-bolder fs-3 my-1">Editing Flow</h1>
            <!--end::Title-->
            <!--begin::Separator-->
            <span class="h-20px border-gray-200 border-start mx-4"></span>
            <!--end::Separator-->
            <!--begin::Breadcrumb-->
            <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
              <li class="breadcrumb-item text-dark fs-6">{{flowConfigs && flowGuid ? flowConfigs.title : 'Default Flow' }}</li>
              <li>
                <span class="badge badge-light-success me-auto fs-6">{{flowGuid}}</span>
              </li>
            </ul>
            <!--end::Breadcrumb-->
          </div>
          <!--end::Page title-->
          <!--begin::Actions-->
          <div class="d-flex align-items-center py-1">
            <!--begin::Wrapper-->
            <select name="flowPicker" id="flowPicker" required data-placeholder="Choose one" style="margin-right: 5px;"
              [(ngModel)]="flowType"
              (ngModelChange)="onFlowTypeChanged()"
                class="form-select form-select-solid">
                <option value="1">Register Flow</option>
                <option value="2">Apply Flow</option>
                <option value="3">Co-apply Flow</option>
            </select>
            <!--end::Wrapper-->
            <!--begin::Button-->
            <a class="btn btn-sm btn-primary" (click)="onSaveFlowClicked()">Save</a>
            <!--end::Button-->
          </div>
          <!--end::Actions-->
        </div>
        <!--end::Container-->
      </div>
      <!--end::Toolbar-->
      <!--begin::Post-->
      <div class="post d-flex flex-column-fluid">
        <form class="form">
          <!--begin::Mixed Widget 2-->
          <div class="card card-xxl-stretch" style="height: 100%;">
            <!--begin::Header-->
            <div class="card-header">
              <h3 class="card-title align-items-start flex-column">
                <span class="fw-bolder mb-2 text-dark">{{flowTypeName}}</span>
                <span class="text-muted fw-bold fs-7">{{flow && flow.steps ? flow.steps.length + ' Steps ' : ''}}</span>
              </h3>
              <div class="card-toolbar">
                <!--begin::Menu-->
                <button type="button"
                  class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
                  data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-flip="top-end">
                  <!--begin::Svg Icon | path: icons/duotune/general/gen024.svg-->
                  <span class="svg-icon svg-icon-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect x="5" y="5" width="5" height="5" rx="1" fill="#000000" />
                        <rect x="14" y="5" width="5" height="5" rx="1" fill="#000000" opacity="0.3" />
                        <rect x="5" y="14" width="5" height="5" rx="1" fill="#000000" opacity="0.3" />
                        <rect x="14" y="14" width="5" height="5" rx="1" fill="#000000" opacity="0.3" />
                      </g>
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                </button>
                <!--begin::Menu 3-->
                <div
                  class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3"
                  data-kt-menu="true">
                  <!--begin::Heading-->
                  <!--
                  <div class="menu-item px-3">
                    <div class="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">Actions</div>
                  </div>
                  -->
                  <!--end::Heading-->
                  <!--begin::Menu item-->
                  <div class="menu-item px-3">
                    <a class="menu-link px-3" (click)="onDeleteAllStepsClicked()">Delete All Steps</a>
                  </div>
                  <!--end::Menu item-->
                  <!--begin::Menu item-->
                  <div class="menu-item px-3">
                    <a href="#" class="menu-link flex-stack px-3" (click)="onCopyFromDefaultClicked()">Copy from Default</a>
                  </div>
                  <!--end::Menu item-->
                </div>
                <!--end::Menu 3-->
                <!--end::Menu-->
              </div>
            </div>
            <!--end::Header-->
            <!--begin::Body-->
            <div class="card-body p-0">
              <flow-summary
                (stepSelected)="onStepSelectedOnFlowSummary($event)">
              </flow-summary>
            </div>
            <!--end::Body-->
          </div>
          <!--end::Mixed Widget 2-->
        </form>

        <div class="flex-fill" style="margin-left: 20px;">
          <!--begin::List Widget 5-->
          <div class="card card-xxl-stretch" style="height: 100%;">
            <!--begin::Header-->
            <div class="card-header align-items-center">
              <h3 class="card-title align-items-start flex-column">
                <span class="fw-bolder mb-2 text-dark">{{sourceStep ? sourceStep.name : "Click a Node on the Left"}}</span>
              </h3>
              <div class="card-toolbar">
                <!--begin::Menu-->
                <div class="me-4 d-flex" *ngIf="stepToEdit">
                  <div class="col-sm-3 fs-6 text-dark" style="padding-top: 8px; width:80px">Edit Mode</div>
                  <div class="col-sm-2 form-check form-switch form-check-custom form-check-solid" style="margin-top: 8px;">
                    <input class="form-check-input h-20px w-30px" type="checkbox" id="editModeToggle"
                      [ngModelOptions]="{standalone: true}" [(ngModel)]="inEditMode" (ngModelChange)="onEditModeToggled()" />
                    <label class="form-check-label" for="flexSwitch20x30">
                      {{inEditMode ? "Yes" : "No"}}
                    </label>
                  </div>
                </div>
                <a href="javascript:void(0);" (click)="onCreateStepClicked()" class="btn btn-sm btn-primary" id="createStepButton">Create Step</a>
                <!--end::Menu-->
              </div>
            </div>
            <!--end::Header-->
            <!--begin::Body-->
            <div class="card-body pt-5 d-flex">
                <div class="bg-white rounded">
                  <div class="d-flex details-view" *ngIf="sourceStep">
                    <flow-step #sourceFlowStep
                      [isSourceStep]="true"
                      [addEnabled]="destinationSteps.length === 0"
                      [nextStepDeciderType]="sourceStep.nextStepDeciderConfig ? sourceStep.nextStepDeciderConfig!.deciderType : undefined"
                      [step]="sourceStep!"
                      (stepSelected)="onStepSelected($event)"
                      (addStepClicked)="onAddStepClicked($event)"
                      (editStepClicked)="onEditStepClicked($event)"
                      (deleteStepFromFlowClicked)="onStepDeleteClicked($event)">
                    </flow-step>
                    <svg id="flow-editor-svg" focusable="false" style="width:150px" (drop)="drop($event)" (dragenter)="handleDragEnter($event)" (dragleave)="handleDragLeave($event)"
                      class="drop-area"
                    (dragover)="allowDrop($event)"></svg>
                    <div class="flex-fill">
                      <flow-step
                        #flowStep *ngFor="let destinationStep of destinationSteps; let i = index"
                        [nextStepDeciderType]="sourceStep.nextStepDeciderConfig!.deciderType"
                        [step]="destinationStep"
                        [condition]="conditions[destinationStep.id]"
                        (stepSelected)="onStepSelected($event)"
                        (addStepClicked)="onAddStepClicked($event)"
                        (editStepClicked)="onEditStepClicked($event)"
                        (deleteStepFromFlowClicked)="onStepDeleteClicked($event)"
                        (deleteStepFromNextStepDestinationsClicked)="onDeleteStepFromNextStepDestinationsClicked($event)">
                      </flow-step>
                        <!-- <flow-step-placeholder></flow-step-placeholder> -->
                    </div>
                  </div>
                </div>

                <div *ngIf="stepToEdit" class="flex-fill" style="border-left: solid 1px gainsboro; padding-left: 20px;">
                  <div class="d-flex justify-content-end">
                    <label class="px-2">Zoom</label>
                    <input id="zoom" name="zoom" min="1" max="10" value='10' step="1" [(ngModel)]="previewZoomValue" (ngModelChange)="onZoomChanged()" type="range"/>
                  </div>
                  <div class="step-preview">
                    <dynamically-rendered-content
                      (wizardFlowSettingsChanged)="onWizardFlowSettingsChanged($event)"
                      #stepPreview>
                    </dynamically-rendered-content>
                  </div>
                </div>
            </div>
            <!--end: Card Body-->
          </div>
          <!--end: List Widget 5-->
        </div>
      </div>
      <!--end::Post-->
    </div>
    <!--end::Content-->
    <!--begin::Footer-->
    <div class="footer py-2 d-flex flex-lg-column">
      <!--begin::Container-->
      <div class="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
        <!--begin::Copyright-->
        <div class="text-dark order-2 order-md-1">
          <span class="text-muted fw-bold me-1">2021©</span>
          <a href="https://lodasoft.com" target="_blank" class="text-gray-800 text-hover-primary">Lodasoft</a>
        </div>
        <!--end::Copyright-->
        <!--begin::Menu-->
        <ul class="menu menu-gray-600 menu-hover-primary fw-bold order-1">
          <li class="menu-item">
            <a href="https://lodasoft.com" target="_blank" class="menu-link px-2">About</a>
          </li>
          <li class="menu-item">
            <a href="https://lodasoft.com" target="_blank" class="menu-link px-2">Support</a>
          </li>
          <li class="menu-item">
            <a href="https://lodasoft.com" target="_blank" class="menu-link px-2">Purchase</a>
          </li>
        </ul>
        <!--end::Menu-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Footer-->
  </div>
    <div class="bg-white" id="kt_drawer_example_basic" data-kt-drawer="true" data-kt-drawer-activate="true" data-kt-drawer-overlay="false"
      data-kt-drawer-toggle="#kt_drawer_example_basic_button" data-kt-drawer-close="#kt_drawer_example_basic_close" data-kt-drawer-width="850px">
    <div class="card w-100 rounded-0 border">
      <div class="card-header pe-5">
        <div class="card-title">
          <div class="d-flex justify-content-center flex-column me-3">
            <a href="#" class="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 lh-1">{{rightDrawerHeader}}</a>
          </div>
        </div>
        <div class="card-toolbar">
          <div class="btn btn-sm btn-icon btn-active-light-primary">
            <span class="svg-icon svg-icon-2" (click)="onDrawerCloseClicked()">
              <close-x-svg-icon></close-x-svg-icon>
            </span>
          </div>
        </div>
      </div>
      <div class="card-body hover-scroll-overlay-y" *ngIf="flow">
        <step-selector
          #stepSelector
          [steps]="flow.steps"
          (createStepClicked)="onCreateStepClicked()"
          *ngIf="action === 'add-step' && flow">
        </step-selector>
        <step-editor
          *ngIf="action === 'edit-step' && stepToEdit"
          #stepEditor
          [step]="stepToEdit"
          (nextDeciderTypeChanged)="onStepNextStepDeciderChanged($event)"
          (stepEditingEditingOked)="onStepEditingOked()"
          (stepEditingCancelled)="onEditStepCancelled()"
          [flow]="flow">
        </step-editor>
        <create-step
          [flow]="flow"
          #createStep
          [defaultFlow]="defaultFlow"
          (newStepCreated)="onNewStepCreated($event)"
          (newStepCreationCancelled)="onNewStepCreationCancelled()"
          *ngIf="action === 'create-step'">
        </create-step>
        <expression-next-step-decider-editor
          #expressionEditor
          *ngIf="expressionNextStepDeciderConfigToEdit && action == 'edit-expression'"
          [flow]="flow"
          (expressionEditingCancelled)="onEditStepCancelled()"
          [config]="expressionNextStepDeciderConfigToEdit">
        </expression-next-step-decider-editor>
      </div>
    </div>
  </div>
</ng-template>


