import { WizardGroupEnum } from "../../enums/wizard-group-enum";
import { WizardNavGroup } from "../wizard-nav-group.model";
import { NavigationType } from "./nav-type.enum";
import { StepConfig } from "./step-config.model";

export class WizardFlowConfig {
  steps: StepConfig[] = [];
  isDefault: boolean = false;
  stepGroups: WizardNavGroup[] = [];
}

export class WizardFlowConfigs {
  applicationFlowConfig!: WizardFlowConfig;
  coApplicationFlowConfig!: WizardFlowConfig;
  registrationFlowConfig!: WizardFlowConfig;

  title: string = "";
  comment: string = "";
  guid: string = "";
  isDefault: boolean = false;
  userCreationAutoConfirmed: boolean = false;
  navigationType: NavigationType = NavigationType.Horizontal;

  hasProgressBar: boolean = true;

  constructor(applicationFlowConfig: WizardFlowConfig, coApplicationFlowConfig: WizardFlowConfig, registrationFlowConfig: WizardFlowConfig) {
    this.applicationFlowConfig = applicationFlowConfig;
    this.coApplicationFlowConfig = coApplicationFlowConfig;
    this.registrationFlowConfig = registrationFlowConfig;

    if (!this.applicationFlowConfig.stepGroups || !this.applicationFlowConfig.stepGroups.length) {
      this.applicationFlowConfig.stepGroups = this.getDefaultStepGroups();
    }
  }

  private getDefaultStepGroups(): WizardNavGroup[] {
    // Here you will return the nav groups
    const myInfoGroup = new WizardNavGroup(WizardGroupEnum.MyInfo, "My Info", "user-svg-icon", "");
    const myFinancesGroup = new WizardNavGroup(WizardGroupEnum.MyFinances, "My Finances", "", "fa fa-money-bill-alt");
    const myPropertyAndLoanGroup = new WizardNavGroup(WizardGroupEnum.PropertyAndLoan, "Property and Loan", "", "fas fa-home");
    const myDeclarationsGroup = new WizardNavGroup(WizardGroupEnum.Declarations, "Declarations", "", "far fa-file-alt");
    const myReviewAndSubmitGroup = new WizardNavGroup(WizardGroupEnum.ReviewAndSubmit, "Review and Submit", "", "fas fa-check-square");
    let navGroups: WizardNavGroup[] = [];
    navGroups.push(myInfoGroup);
    navGroups.push(myFinancesGroup);
    navGroups.push(myPropertyAndLoanGroup);
    navGroups.push(myDeclarationsGroup);
    navGroups.push(myReviewAndSubmitGroup);

    return navGroups;
  }
}
