import { Component, Input, OnInit } from '@angular/core';
import { BorrowerDocumentModel } from '../../../models/borrower-portal/borrower-document-model';
import { ApplicationContextService } from '../../../services/application-context.service';

import { DocumentType } from '../../../models/global-config.model';
import * as _ from 'lodash';
import { LinkedDocument } from '../view-document/view-document.component';

class DocGroupByType {
  documentTypeName: string;
  documentTypeId: number;
  docs: LinkedDocument[] = [];
}

@Component({
  selector: 'borrower-portal/document-locker-table',
  templateUrl: './document-locker-table.component.html',
})
export class DocumentLockerTableComponent implements OnInit {

  @Input() documents: BorrowerDocumentModel[] = [];

  docGroups: DocGroupByType[] = [];
  documentTypes: DocumentType[] = [];

  constructor(private readonly _appContextService: ApplicationContextService) {
  }

  ngOnInit(): void {
    this._appContextService.context.subscribe(context => {
      this.documentTypes = context.globalConfig.documentType;

      this.setGroupData();
    })
  }

  private setGroupData = () => {
    let grouped_data = _.groupBy(this.documents, 'documentTypeId');
    Object.keys(grouped_data).forEach(key => {
      let group =  new DocGroupByType();
      group.documentTypeId = key == 'undefined' || key == 'null' ? null : Number(key);
      group.documentTypeName = this.documentTypes.find(t => t.documentTypeId == group.documentTypeId)?.documentTypeName;
      group.docs = grouped_data[key].map(d => {
        {
          const linkedDocument: LinkedDocument = {
            fileGuid: d.guid,
            mimeType: d.mimeType,
            note: d.note,
            fileName: d.fileName,
            dateInserted: d.dateInserted
          }
          return linkedDocument;
        }
      });
      this.docGroups.push(group);
    });

    this.docGroups = _.sortBy(this.docGroups, ['documentTypeName'], ['asc']);
  }

}
