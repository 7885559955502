import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Address } from '../../../models/address-model';

import { Asset } from '../../../models/asset.model';
import { AddressComponent } from '../../address/address.component';
import { ConfigurableFieldHostComponent } from '../../configurable-field-host.component';

@Component({
  selector: 'asset-pending-net-sale-proceeds',
  templateUrl: 'asset-pending-net-sale-proceeds.component.html'
})

export class AssetPendingNetSaleProceedsComponent extends ConfigurableFieldHostComponent implements OnInit {

  @ViewChild(AddressComponent) addressComponent: AddressComponent | undefined;

  constructor() {
    super();
  }

  @Input()
  asset!: Asset;

  @Input()
  address!: Address;

  public get isValid() {
    return this.addressComponent?.validate();
  }

  ngOnInit() { }
}
