import { Injectable } from "@angular/core";
import { rgb } from "d3";

@Injectable()
export class SvgUtilsService {

  createSVG = (): any => {
    let svg: any | null = document.getElementById("flow-editor-svg");
    if (null == svg) {
      svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
      svg.setAttribute('id', 'svg-canvas');
      //svg.setAttribute('style', 'position:absolute;top:0px;left:0px');
      //svg.setAttribute('width', 100);
      //svg.setAttribute('height', 100);
      svg.setAttribute("class", "connector");
      svg.setAttribute("preserveAspectRatio", "xMinYMin meet");
      //svg.setAttribute("class", "svg-content-responsive");
      svg.setAttributeNS("http://www.w3.org/2000/xmlns/",
        "xmlns:xlink",
        "http://www.w3.org/1999/xlink");
      document.body.appendChild(svg);
    }
    return svg;
  }

  drawCircle = (x: number, y: number, radius: number, color: string) => {
    var svg = this.createSVG();
    var shape = document.createElementNS("http://www.w3.org/2000/svg", "circle");
    shape.setAttributeNS(null, "cx", x.toString());
    shape.setAttributeNS(null, "cy", y.toString());
    shape.setAttributeNS(null, "r", radius.toString());
    shape.setAttributeNS(null, "fill", color);
    svg.appendChild(shape);
  }

  private findPosX = (obj: any) => {
    var curleft = 0;
    if (obj.offsetParent) {
        while (1) {
            curleft+=obj.offsetLeft;
            if (!obj.offsetParent) {
                break;
            }
            obj=obj.offsetParent;
        }
    } else if (obj.x) {
        curleft+=obj.x;
    }
    return curleft;
  }

private findPosY = (obj: any) => {
    var curtop = 0;
    if (obj.offsetParent) {
        while (1) {
            curtop+=obj.offsetTop;
            if (!obj.offsetParent) {
                break;
            }
            obj=obj.offsetParent;
        }
    } else if (obj.y) {
        curtop+=obj.y;
    }
    return curtop;
}

  findAbsolutePosition = (htmlElement: any) => {
    var x = this.findPosX(htmlElement);
    var y = this.findPosY(htmlElement);
    // var y = htmlElement.offsetTop;
    // for (let x = 0, y = 0, el = htmlElement;
    //   el != null;
    //   el = el.offsetParent) {
    //   x += el.offsetLeft;
    //   y += el.offsetTop;
    // }
    return {
      "x": x,
      "y": y
    };
  }

  connectDivs = (leftId: string, rightId: string, color: string, tension: number) => {
    var left = document.getElementById(leftId);
    var right = document.getElementById(rightId);

    if (left == null || right == null) {
      return;
    }

    var leftPos = this.findAbsolutePosition(left);
    var x1 = leftPos.x;
    var y1 = leftPos.y;

    x1 += left.offsetWidth;
    y1 += (left.offsetHeight / 2);

    var rightPos = this.findAbsolutePosition(right);
    var x2 = rightPos.x + 10;
    var y2 = rightPos.y;
    y2 += (right.offsetHeight / 2);

    var width = x2 - x1;
    var height = y2 - y1;

    this.drawCircle(x1, y1, 3, color);
    this.drawCircle(x2, y2, 3, color);
    this.drawCurvedLine(x1, y1, x2, y2, color, tension, rightId);
  }

  drawCurvedLine = (x1: any, y1: any, x2: any, y2: any, color: string, tension: number, id?: string, dashed?: boolean) => {
    var svg = this.createSVG();
    var shape = document.createElementNS("http://www.w3.org/2000/svg", "path"); {
      var delta = (x2 - x1) * tension;
      var hx1 = x1 + delta;
      var hy1 = y1;
      var hx2 = x2 - delta;
      var hy2 = y2;
      var path = "M " + x1 + " " + y1 +
        " C " + hx1 + " " + hy1
        + " " + hx2 + " " + hy2
        + " " + x2 + " " + y2;
      shape.setAttributeNS(null, "d", path);
      shape.setAttributeNS(null, "fill", "none");
      shape.setAttributeNS(null, "stroke", color);
      if (dashed) {
        shape.setAttribute('stroke-dasharray', '5, 5');
      }
      svg.appendChild(shape);
    }
    var shape = document.createElementNS("http://www.w3.org/2000/svg", "path"); {
      var delta = (x2 - x1) * tension;
      var hx1 = x1 + delta;
      var hy1 = y1;
      var hx2 = x2 - delta;
      var hy2 = y2;
      var path = "M " + x1 + " " + y1 +
        " C " + hx1 + " " + hy1
        + " " + hx2 + " " + hy2
        + " " + x2 + " " + y2;
      shape.setAttributeNS(null, "d", path);
      shape.setAttribute("fill", "rgb(0, 0, 0, 1.0)");
      shape.setAttribute('stroke-width', '4');
      if (id)
        shape.setAttribute("id", id);
      svg.appendChild(shape);
    }
  }
}
