import { Component, OnInit, Input } from '@angular/core';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'password-reset-email-notice',
  templateUrl: 'password-reset-email-notice.component.html'
})

export class PasswordResetEmailNoticeComponent implements OnInit {

  email: string = "";

  constructor(private readonly _utilsService: UtilsService) { }

  ngOnInit() {
    this.email = this._utilsService.getUrlParameter("email");
  }
}
