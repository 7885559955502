import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApplicationForUser } from '../../../models/borrower-portal/application-for-user-model';
import { ApplicationTaskModel } from '../../../models/borrower-portal/application-task-model';
import { Constants } from '../../../services/constants';
import { TaskAnswerQuestionDialogComponent } from '../task-answer-question-dialog/task-answer-question-dialog.component';

@Component({
  selector: 'tasks-answer-question',
  templateUrl: './tasks-answer-question.component.html',
  styleUrls: ['./tasks-answer-question.component.scss']
})
export class TasksAnswerQuestionComponent implements OnInit {

  @Input() tasks: ApplicationTaskModel[] = [];
  @Input() application: ApplicationForUser;
  @Input() isBorrowerPortal: boolean = true;

  @Output() borrowerAnsweredAQuestion: EventEmitter<ApplicationTaskModel> = new EventEmitter<ApplicationTaskModel>();

  constructor(private readonly _modalService: NgbModal) { }

  ngOnInit(): void {
  }

  onAnswerQuestionClicked = (task: ApplicationTaskModel) => {
    const modalRef = this._modalService.open(TaskAnswerQuestionDialogComponent, Constants.modalOptions.large);
      modalRef.componentInstance.task = task;
      modalRef.result.then(result => {
        this.borrowerAnsweredAQuestion.emit(task);
      }, error => {
      });
  }
}
