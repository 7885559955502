<!--begin::Header-->
<div class="header align-items-stretch">
  <!--begin::Container-->
  <div class="container d-flex align-items-stretch justify-content-between">
    <!--begin::Logo-->
    <div class="d-flex justify-content-center mb-4 mt-4">
      <logo [logoUrlsToTry]="possibleLogoUrls"></logo>
    </div>
    <!--end::Logo-->
    <!--begin::Wrapper-->
    <div class="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
      <!--begin::Navbar-->
      <div class="d-flex align-items-stretch">
        <!--begin::Menu wrapper-->
        <div class="header-menu align-items-stretch" data-kt-drawer="true" data-kt-drawer-name="header-menu" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'200px', '300px': '250px'}" data-kt-drawer-direction="end" data-kt-drawer-toggle="#kt_header_menu_mobile_toggle" data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}">

        </div>
        <!--end::Menu wrapper-->
      </div>
      <!--end::Navbar-->
      <!--begin::Topbar-->
      <div class="d-flex align-items-stretch flex-shrink-0">
        <!--begin::Toolbar wrapper-->
        <div class="d-flex align-items-stretch flex-shrink-0">

          <!--begin::User-->
          <div class="d-flex align-items-center ms-1 ms-lg-3" *ngIf="userInfo">
            <!--begin::Menu wrapper-->
            <div class="cursor-pointer symbol symbol-30px symbol-md-40px" data-kt-menu-trigger="click" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end" data-kt-menu-flip="bottom">
              <div class="text-muted fw-bold d-flex align-items-center fs-5">{{userInfo!.name}}
            </div>
            <!--begin::Menu-->
            <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px" data-kt-menu="true">
               <!--begin::Menu item-->
              <div class="menu-item px-5">
                <a (click)="redirectToBorrowerPortal()" class="menu-link px-5">Return to My Loans</a>
              </div>
              <!--end::Menu item-->

               <!--begin::Menu separator-->
               <div class="separator my-2"></div>
               <!--end::Menu separator-->

               <!--begin::Menu item-->
               <div class="menu-item px-5">
                 <a (click)="logout()" class="menu-link px-5">Sign Out</a>
               </div>
               <!--end::Menu item-->

            </div>
            <!--end::Menu-->
            <!--end::Menu wrapper-->
          </div>
          <!--end::User -->

        </div>
        <!--end::Toolbar wrapper-->
      </div>
      <!--end::Topbar-->
    </div>
    <!--end::Wrapper-->
  </div>
  <!--end::Container-->
</div>
<!--end::Header-->
