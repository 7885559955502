export class AuthenticationRequest {
  companyGuid: string | undefined = null;
  username: string = null;
  password: string = null;
  rememberMe: boolean = false;
  twoFactorCode?: string | undefined;
  scope: string = null;
  userCompanyGuid?: string | undefined = null;
  usernameValidationOnly?: boolean = false;
}
