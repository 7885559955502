import { Component, Input, OnInit } from '@angular/core';
import { WizardNavGroup } from '../../models/wizard/wizard-nav-group.model';
import { WizardFlowServiceBase } from '../../services/wizard/wizard-flow-service.base';

@Component({
  template: '',
})
export class WizardNavigationComponentBase implements OnInit {

  @Input()
  inEditMode: boolean = false;

  protected navGroups: WizardNavGroup[] = [];

  constructor(protected readonly _wizardFlowService: WizardFlowServiceBase) {}

  ngOnInit() {
    this.navGroups = this._wizardFlowService.activeFlowConfig.stepGroups;
  }

  onStepClicked = (navGroup: WizardNavGroup) => {
    this._wizardFlowService.navigateToGroup(navGroup);
  };

  public getWizardState(navGroup: WizardNavGroup): string {
    const currentStep = this._wizardFlowService.getCurrentStep();
    if (!currentStep) {
      return 'pending';
    }
    if (navGroup.id === currentStep.groupId) {
      return 'current';
    }
    if (this._wizardFlowService.isNavGroupCompleted(navGroup.id!)) {
      return 'complete';
    }
    return 'pending';
  }
}
