import { Directive, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from "@angular/forms";

export function maxTimeValidator(maxTime: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const invalid = control.value && Date.parse('1/1/2023 ' + control.value) > Date.parse('1/1/2023 ' + maxTime);
    return invalid ? { maxTime: { value: control.value } } : null;
  };
}

@Directive({
  selector: '[maxTime]',
  providers: [{provide: NG_VALIDATORS, useExisting: MaxTimeValidatorDirective, multi: true}]
})
export class MaxTimeValidatorDirective implements Validator {

  private _maxTime: string = "23:59";
  private _onChange?: () => void;

  get maxTime(): string {
    return this._maxTime;
  }

  @Input()
  set minTime(value: string) {
    this._maxTime = value ? value : "23:59";
    if (this._onChange) this._onChange();
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.maxTime != null ? maxTimeValidator(this.maxTime)(control) : null;
  }

  registerOnValidatorChange(fn: () => void): void {
    this._onChange = fn;
  }
}
