import { IDecideNextStep } from "../../services/wizard/next-step-decider/next-step-decider.interface";
import { ChecklistsStepComponent } from "../../wizard/wizard-steps/checklists-step/checklists-step.component";
import { WizardStepBase } from "./wizard-step-base.model";

export class CheckListsStep extends WizardStepBase {

  selectedChecklistIds: number[] = [];

  constructor(id: string, name: string, path: string, nextStepDecider?: IDecideNextStep) {
    super(id, name, path, nextStepDecider);
    this.component = ChecklistsStepComponent;
  }
}
