import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-e-consent-dialog',
  templateUrl: './e-consent-dialog.component.html',
  styleUrls: ['./e-consent-dialog.component.scss']
})
export class EConsentDialogComponent implements OnInit {

  @Input() loanId: number;
  @Input() eConsentText: string;

  isEconsentChecked: boolean = false;

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {

  }

}
