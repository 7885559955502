<ng-template #gatherLeadSource>
  <form class="form w-100" #leadSourceForm="ngForm" novalidate id="leadSourceForm" name="leadSourceForm">
    <div class="d-flex mb-5" *ngIf="inEditMode">
      <div class="col-sm-3 col-form-label fw-bold fs-6 editor-label">Pick Agent Referral from a List</div>
      <div class="col-sm-2 form-check form-switch form-check-custom form-check-solid">
        <input class="form-check-input h-20px w-30px" type="checkbox"
          name="pickReferralFromAList" id="pickReferralFromAList"
          [(ngModel)]="step.pickAgentReferralFromAList" />
        <label class="form-check-label" for="pickReferralFromAList">
          {{step.pickAgentReferralFromAList ? "Yes" : "No"}}
        </label>
      </div>
    </div>

    <!-- <div class="row mb-4" *ngIf="!step.pickReferralFromAList">
      <div class="col-md-12 fv-row">
        <textarea cols="3" rows="7" class="form-control form-control-lg form-control-solid" #leadSourceValue="ngModel"
          name="myLeadSource" [(ngModel)]="leadSource" required
          [ngClass]="{'is-invalid' : leadSourceValue && leadSourceValue.touched && leadSourceValue.invalid}"
          (ngModelChange)="onLeadSourceTextChanged()" (keydown)="onKeyDown($event)">
        </textarea>
        <div class="invalid-feedback">Please enter how you heard about us.</div>
        <div _ngcontent-vqr-c169="" class="text-gray-400 fw-bold fs-6 mb-lg-3">
          {{noOfCharactersLeft}} characters left
        </div>
      </div>
    </div> -->

    <div class="row mb-4">
      <div class="col-md-12 fv-row">
        <label class="form-label mb-2 required">How did you hear about us?</label>
        <select #leadCameFrom="ngModel" class="form-select form-select-solid"
          [ngClass]="{'is-invalid' : leadCameFrom && leadCameFrom.touched && leadCameFrom.invalid}"
          required
          [(ngModel)]="leadSourceType"
          (change)="onLeadSourceChangedType()"
          name="howDidYouHearAboutUs">
          <option [ngValue]="null">--Select One--</option>
          <option *ngFor="let leadSourceOption of leadSourceOptions" value="{{leadSourceOption}}">{{leadSourceOption}}</option>
        </select>
        <div class="invalid-feedback">Please select how you heard about us.</div>
      </div>
    </div>
    <div class="row mb-4" *ngIf="leadSourceType === 'Agents' && step.pickAgentReferralFromAList">
      <div class="col-md-12 fv-row">
        <label class="form-label mb-2">Who was the agent?</label>
        <ng-select
          [(ngModel)]="leadSourceAgent"
          (ngModelChange)="onAgentChanged()"
          [items]="referralAgents"
          bindLabel="name"
          bindValue="id"
          class="ng-select-solid"
          groupBy="company"
          id="lead-source-agent"
          name="leadSourceAgent"
        >
          <ng-template ng-optgroup-tmp let-item="item">
            {{item.company}}
          </ng-template>

          <ng-template ng-option-tmp let-item="item">
            {{item.name}}
          </ng-template>
        </ng-select>
        <div class="invalid-feedback">Please select an agent.</div>
      </div>
    </div>
    <div class="row mb-4" *ngIf="leadSourceType && (leadSourceType !== 'Agents' || (leadSourceType === 'Agents' &&
      (!step.pickAgentReferralFromAList || leadSourceAgent == -1)))">
      <div class="col-md-12 fv-row">
        <label class="form-label mb-2 required">{{referralSourceLabel}}</label>
        <input class="form-control form-control-lg form-control-solid" type="text" name="leadSource" required
          [ngClass]="{'is-invalid' : leadSourceValue && leadSourceValue.touched && leadSourceValue.invalid}" autocomplete="off"
          [(ngModel)]="leadSource" #leadSourceValue="ngModel" />
        <div class="invalid-feedback">Please select how you heard about us.</div>
      </div>
    </div>
  </form>
</ng-template>

<wizard-step-template [stepMainTemplate]="gatherLeadSource" [step]="step" [stepComponent]="this"></wizard-step-template>
